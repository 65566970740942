import React from "react"
import { observer } from "mobx-react-lite"
import { Outlet, useParams } from "react-router-dom"

import { useBreadCrumpsContext } from "./useBreadCrumpsContext"

import { BreadCrumpsContext } from "./index"

export interface BreadCrumpsCheckpointProps {
  label: string | ((params: any) => string)
  redirectLink: string | ((params: any) => string)
  render?: (label: string) => React.ReactNode
}

export const BreadCrumpsCheckpoint: React.FC<BreadCrumpsCheckpointProps> =
  observer(({ label, redirectLink, render, children }) => {
    const params = useParams()

    const { level, store } = useBreadCrumpsContext()

    React.useEffect(() => {
      const link =
        typeof redirectLink === "string" ? redirectLink : redirectLink(params)
      const name = typeof label === "string" ? label : label(params)

      store.setCheckpoint(level, {
        link,
        name,
        render,
      })
    }, [label, redirectLink, render])

    React.useEffect(() => () => store.unsetCheckpoint(level), [])

    return (
      <BreadCrumpsContext.Provider
        value={React.useMemo(
          () => ({ level: level + 1, store }),
          [store, level]
        )}
      >
        {children}
      </BreadCrumpsContext.Provider>
    )
  })

export const BreadCrumpsCheckpointOutlet: React.FC<BreadCrumpsCheckpointProps> =
  observer((props) => {
    return (
      <BreadCrumpsCheckpoint {...props}>
        <Outlet />
      </BreadCrumpsCheckpoint>
    )
  })
