import React from "react"

import Templates from "@components/ui/Templates"
import UserCard from "@components/ui/UserCard/UserCard"
import ChatMessages from "@components/prototypes/Chat/ChatMessages"

import styles from "./Chat.module.sass"

export type ChatMessage = {
  message: string
  fromMe: boolean
  moment: number
}

interface ChatProps {
  messages: ChatMessage[]
}

export const Chat: React.FC<ChatProps> = ({ messages }) => {
  return (
    <Templates.RollScript
      className={styles.root}
      headerSocket={
        <div className={styles.header}>
          <UserCard fullName="Chat Name" metaInfo="last message 4 hours ago" />
        </div>
      }
    >
      <ChatMessages messages={messages} />
    </Templates.RollScript>
  )
}

export default Chat
