import React, { useEffect, useMemo } from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import ScrollableContainer from "@components/ui/ScrollableContainer/ScrollableContainer"
import Button from "@components/ui/Button/Button"
import { useController, useStore } from "@store/index"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import CheckboxCard from "@components/ui/CheckboxCard/CheckboxCard"
import List from "@components/ui/List/List"
import UserCard from "@components/ui/UserCard/UserCard"
import useSelectable from "@pages/upload/connectors/Sources/useSelectable"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import Text from "@components/ui/Typography/Text"

import styles from "./AssignUserRolesModal.module.sass"

export interface AssignUserRolesModalProps {
  userId: string
}

export const AssignUserRolesModal: React.FC<AssignUserRolesModalProps> =
  observer(({ userId }) => {
    const alert = useAlert()
    const modal = useModal(ModalsTypes.ASSIGN_USER_ROLES_MODAL)

    const { rolesStore, adminUsersStore } = useStore()
    const { rolesController, adminUsersController } = useController()

    const user = adminUsersStore.getUserById(userId)

    const currentSelected = useMemo(
      () => user?.userRoles?.map((it) => it.id) ?? [],
      [user?.userRoles]
    )

    const selectable = useSelectable(rolesStore.roleIds, currentSelected)

    const handleSubmit = async () => {
      const error = await adminUsersController.changeUserRoles(
        userId,
        selectable.selected
      )
      if (error) alert.error(error)
      else {
        alert.success(
          <>Roles for user {user?.email} was successfully updated</>
        )
        modal.hideModal()
      }
    }

    const handleCancel = modal.hideModal

    useEffect(() => {
      const { roleIds, error, isLoading } = rolesStore
      if (!roleIds.length && !error && !isLoading) rolesController.init()
    }, [])

    const totalSelected = selectable.selected.length
    const total = rolesStore.roleIds.length

    const isLoading = rolesStore.isLoading || adminUsersStore.isLoading

    const hasChanges = hasDifference(selectable.selected, currentSelected)

    return (
      <BaseModal
        title={
          <ModalTitle
            titleText="Edit user roles"
            subtitleText="Select roles you'd like to assign to user"
          />
        }
        containerClassName={styles.root}
        onClose={modal.hideModal}
      >
        {user != null && (
          <UserCard
            variant="card"
            className={styles.userCard}
            fullName={`${user.firstName} ${user.lastName}`}
            metaInfo={user.email}
          />
        )}

        {total > 0 && (
          <Text className={styles.listTitle} variant="caption1">
            {totalSelected} of {total} roles{" "}
            <Text variant="inherit" color="text50Color" inline>
              selected
            </Text>
          </Text>
        )}

        <ScrollableContainer className={styles.listContainer}>
          {isLoading ? (
            <Loader size="large" primary fluid />
          ) : total ? (
            <List gutter="0">
              {rolesStore.roleIds.map((roleId) => {
                const item = rolesStore.roles[roleId]
                if (item == null) return null
                return (
                  <CheckboxCard
                    Size="medium"
                    withIcon={false}
                    label={item.name.toUpperCase()}
                    onCheck={() => selectable.select(item.id)}
                    checked={selectable.isSelected(item.id)}
                    key={item.id}
                  />
                )
              })}
            </List>
          ) : (
            <NotFound>No roles found</NotFound>
          )}
        </ScrollableContainer>

        <ModalFooterContainer className={styles.footer}>
          <Button
            onClick={handleCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={isLoading || !hasChanges}
            color="primary"
          >
            Save
          </Button>
        </ModalFooterContainer>
      </BaseModal>
    )
  })

export default AssignUserRolesModal

const hasDifference = (arr1: string[], arr2: string[]) =>
  arr1.length !== arr2.length || arr1.find((it) => !arr2.includes(it))
