import { observer } from "mobx-react-lite"
import React, { useMemo } from "react"

import { useStore } from "@store"
import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"
import Table from "@components/ui/BaseTable/Table"
import Icon from "@components/ui/Icon/Icon"
import getRecursiveProperties from "@pages/launch-solutions/SolutionTabsContainer/utils"

import styles from "./index.module.sass"

type ComparisonTableHeaderProps = {
  readOnly?: boolean
}

const ComparisonTablerHeader: React.FC<ComparisonTableHeaderProps> = observer(
  ({ readOnly = false }) => {
    const {
      productComparisonStore: {
        selectedProductsStore: { selectedProducts, removeSelectedProduct },
      },
    } = useStore()
    return (
      <Row>
        <Column as="th" key="property">
          Property
        </Column>
        {selectedProducts.map((product) => (
          <Column as="th" key={product.id} width="150px">
            <div className={styles.header}>
              {product.name}
              {!readOnly && (
                <Icon
                  name="cross"
                  color="default"
                  onClick={() => removeSelectedProduct(product)}
                  className={styles.crossButton}
                />
              )}
            </div>
          </Column>
        ))}
      </Row>
    )
  }
)

type ComparisonTableRowsProps = {
  propertyName: string
  propertyValues: React.ReactNode[]
}

const ComparisonTableRows: React.FC<ComparisonTableRowsProps> = observer(
  ({ propertyName, propertyValues }) => {
    return (
      <Row>
        <Column as="td" key="property">
          {propertyName}
        </Column>

        {propertyValues.map((val, index) => (
          <Column
            align="start"
            // eslint-disable-next-line react/no-array-index-key
            key={`${propertyName}-${index}`}
            className={styles.properties}
          >
            {val}
          </Column>
        ))}
      </Row>
    )
  }
)

type ComparisonTableProps = {
  readonly?: boolean
}

const ComparisonTable: React.FC<ComparisonTableProps> = observer(
  ({ readonly = false }) => {
    const {
      productComparisonStore: {
        selectedProductsStore: { selectedProducts },
      },
    } = useStore()

    const transformedProducts = useMemo(() => {
      const result: { property: string; values: React.ReactNode[] }[] = []

      selectedProducts.forEach((product, productIndex) => {
        product.properties.forEach((property) => {
          const existingProperty = result.find(
            (p) => p.property === property.name
          )
          if (existingProperty) {
            existingProperty.values[productIndex] =
              getRecursiveProperties(property)
          } else {
            const values = new Array(selectedProducts.length).fill(<p>None</p>)
            values[productIndex] = getRecursiveProperties(property)
            result.push({ property: property.name, values })
          }
        })
      })

      return result
    }, [selectedProducts.length])

    return (
      <Table
        header={<ComparisonTablerHeader readOnly={readonly} />}
        className={styles.root}
      >
        {transformedProducts.map((p) => (
          <ComparisonTableRows
            key={p.property}
            propertyName={p.property}
            propertyValues={p.values}
          />
        ))}
      </Table>
    )
  }
)

export default ComparisonTable
