import { makeAutoObservable } from "mobx"
import React from "react"

export type SelectionMode = "include" | "exclude"

type Options = {
  initialValue?: string[]
  initialMode?: SelectionMode
  total?: number
}

export class InfiniteSelected {
  constructor({
    initialValue = [],
    initialMode = "include",
    total = 0,
  }: Options = {}) {
    this.mode = initialMode

    this.total = total

    this.data = new Set(initialValue)

    makeAutoObservable(this)
  }

  mode: SelectionMode = "include"

  data: Set<string>

  total: number = 0

  get selectedCount() {
    return this.mode === "include"
      ? this.data.size
      : this.total - this.data.size
  }

  get isSelected() {
    const { mode, data } = this
    const isIncluding = mode === "include"
    return (itemId: string) =>
      data.has(itemId.toString()) ? isIncluding : !isIncluding
  }

  get isAnySelected() {
    const { mode, data: selectedProducts } = this
    return mode === "include"
      ? selectedProducts.size > 0
      : selectedProducts.size < this.total
  }

  get isAllSelected() {
    const { mode, data: selectedProducts } = this
    return mode === "include"
      ? selectedProducts.size >= this.total
      : selectedProducts.size === 0
  }

  selectAll = async (value: boolean = !this.isAnySelected) => {
    const newMode = value ? "exclude" : "include"
    this.mode = newMode
    this.data = new Set()
  }

  toggle = (item: string) => {
    if (this.data.has(item.toString())) this.delete(item)
    else this.add(item.toString())
  }

  delete = (...items: string[]) => {
    for (let i = 0; i < items.length; i += 1)
      this.data.delete(items[i].toString())
  }

  add = (...items: string[]) => {
    for (let i = 0; i < items.length; i += 1) this.data.add(items[i].toString())
  }

  setTotal = (total: number) => {
    this.total = total
  }
}

export const InfiniteSelectedContext =
  React.createContext<InfiniteSelected | null>(null)

export const useInfiniteSelectedContext = () => {
  const context = React.useContext(InfiniteSelectedContext)
  if (context == null) throw new Error("InfiniteSelectedContext not found")
  return context
}
