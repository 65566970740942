import React from "react"
import { useFormik } from "formik"
import { useParams } from "react-router-dom"
import * as yup from "yup"

import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import { TextInput, TextInputProps } from "@components/ui/TextInput/TextInput"
import { getDocumentIconSrc, Option } from "@framework/types/utils"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import { DataSourceName } from "@framework/types/upload"
import PasswordField from "@components/ui/PasswordField/PasswordField"
import MainBoxLayout from "@components/layout/MainBoxLayout/MainBoxLayout"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import Button from "@components/ui/Button/Button"

import styles from "./AddDataConnector.module.sass"

interface FormDataType {
  link: string
  login: string
  password: string
}

const formFields: Option<keyof FormDataType, TextInputProps>[] = [
  { name: "link", value: { placeholder: "Enter link to Sharepoint Site" } },
  { name: "login", value: { placeholder: "Login" } },
]

const DEFAULT_REQUIREMENTS = ""

const validationSchema = yup.object().shape({
  link: yup.string().required("Field is required"),
  login: yup.string().required("Field is required"),
  password: yup.string().required("Field is required"),
})

export interface AddDataConnectorPageProps {
  title?: string
  requirements?: string
}

export const AddDataConnectorPage: React.FC<AddDataConnectorPageProps> = ({
  requirements = DEFAULT_REQUIREMENTS,
}) => {
  const { dataConnector } = useParams()

  const sourceTypeDescription = getDataSourceNodeContextProps(
    dataConnector as DataSourceName
  )

  const { showModal: showSuccessModal } = useModal(
    ModalsTypes.SUCCESS_MESSAGE_MODAL
  )

  const handleSubmit = () => {
    showSuccessModal({
      message: "Sharepoint account has been successfully connected",
    })
  }

  const formik = useFormik<FormDataType>({
    initialValues: {
      link: "",
      login: "",
      password: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  const handleNext = () => {
    formik.submitForm()
  }

  return (
    <MainBoxLayout className={styles.root}>
      <img
        className={styles.icon}
        src={getDocumentIconSrc(sourceTypeDescription.icon)}
        alt={sourceTypeDescription.icon}
      />

      <div>
        <h2>{`Connect to ${sourceTypeDescription.label}`}</h2>
        {requirements && <p>{requirements}</p>}
      </div>

      <form className={styles.formContainer} onSubmit={formik.handleSubmit}>
        {formFields.map((item) => {
          const value = formik.values[item.name]
          const error = formik.errors[item.name]
          const isTouched = formik.touched[item.name]

          return (
            <TextInput
              {...item.value}
              className={styles.input}
              value={value}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              withError={!!error && isTouched}
              key={item.name}
              after={
                !!error &&
                isTouched && (
                  <div className={styles.after}>
                    <ErrorChip messagePlacement="left" message={error} />
                  </div>
                )
              }
            />
          )
        })}
        <PasswordField
          placeholder="Password"
          className={styles.input}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          after={
            !!formik.values.password &&
            formik.touched.password &&
            formik.errors.password && (
              <ErrorChip
                messagePlacement="left"
                message={formik.errors.password}
              />
            )
          }
        />
      </form>

      <ModalFooterContainer>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Connect
        </Button>
      </ModalFooterContainer>
    </MainBoxLayout>
  )
}

export default AddDataConnectorPage
