import React from "react"
import { observer } from "mobx-react-lite"

import Chip from "@components/ui/Chip/Chip"
import Avatar from "@components/ui/Avatar/Avatar"
import { SimpleUserData } from "@framework/types/user"
import UsersBubbles from "@components/ui/UsersBubbles/UsersBubbles"
import { AvatarData } from "@framework/types/avatar"
import DropDownContainer from "@components/ui/Dropdown/DropDownContainer"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import List from "@components/ui/List/List"

const AvatarBubbles: React.FC<{ avatars?: AvatarData[] }> = observer(
  ({ avatars }) => {
    return (
      <DropDownContainer
        target={() => (
          <UsersBubbles
            users={
              avatars?.slice(0, 3).map<SimpleUserData>((it) => ({
                name: it.name,
                avatarUrl: it.imageUrl,
              })) ?? []
            }
            total={avatars?.length ?? 0}
            suffix={`${avatars?.length ?? 0} Avatars`}
          />
        )}
      >
        {!!avatars?.length && (
          <TooltipContainer color="primary" margin="4px">
            <List gutter="4">
              {avatars.map((it) => (
                <Chip
                  variant="rounded"
                  color="transparent"
                  before={<Avatar src={it.imageUrl} />}
                  key={it.id}
                >
                  {it.name}
                </Chip>
              ))}
            </List>
          </TooltipContainer>
        )}
      </DropDownContainer>
    )
  }
)

export default AvatarBubbles
