import React from "react"
import clsx from "clsx"

import { ColorType } from "@framework/types/utils"

import styles from "./Box.module.scss"

export interface TooltipContainerProps extends React.HTMLProps<HTMLDivElement> {
  color?: ColorType
  className?: string
}

export const Box: React.FC<TooltipContainerProps> = ({
  color = "default",
  children,
  className,
  ...rest
}) => (
  <div
    className={clsx(styles.root, styles[`color-${color}`], className)}
    {...rest}
  >
    {children}
  </div>
)

export default Box
