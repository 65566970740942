import React from "react"
import clsx from "clsx"

import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import Button from "@components/ui/Button/Button"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import { useSearchBlock } from "@pages/search/SearchContext/SearchResultContext"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import { runTask } from "@utils/promise"

import type { ImageDetails } from "../AnswerOriginalView"
import ImagePreview from "./ImagePreview"

import styles from "./PreviewList.module.sass"

interface PreviewListProps {
  image: ImageDetails
  images: ImageDetails[]
  orientation?: "vertical" | "horizontal"
  onSelect: (imageDetails: ImageDetails) => void
}

const PreviewList: React.FC<PreviewListProps> = ({
  image,
  images = [],
  orientation = "vertical",
  onSelect,
}) => {
  const isTablet = useMediaQuery(AppMediaQueries.minTablet)

  const { answerIndex } = useSearchBlock(SearchSummaryBlockStore)

  const previewListRef = React.useRef<HTMLDivElement>(null)

  const index = Number(answerIndex || 1) - 1

  const vOrientation = orientation === "vertical"

  React.useEffect(() => {
    runTask(() => {
      if (previewListRef.current) {
        const selectedImageElement = previewListRef.current.children[index]
        if (selectedImageElement) {
          selectedImageElement.scrollIntoView({
            behavior: "auto",
            block: "nearest",
          })
        }
      }
    })
  }, [])

  const scrollPreviewList = (direction: "up" | "down") => {
    if (previewListRef.current) {
      const firstCard = previewListRef.current.children[0]
      const itemHeight =
        Math.max(
          firstCard?.clientHeight ?? 0,
          firstCard?.clientWidth ?? 0,
          100
        ) * 3

      const scrollAmount = direction === "up" ? -itemHeight : itemHeight

      previewListRef.current.scrollBy({
        [vOrientation ? "top" : "left"]: scrollAmount,
        behavior: "smooth",
      })
    }
  }

  const handleSelect =
    (imageDetails: ImageDetails) => (e?: React.MouseEvent) => {
      onSelect(imageDetails)

      e?.currentTarget.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      })
    }

  return (
    <div className={clsx(styles.root, styles[orientation])}>
      <Button
        size="small"
        variant="text"
        onClick={() => scrollPreviewList("up")}
        className={styles.scrollButton}
      >
        <Icon name="arrow-down" rotateAngle={vOrientation ? 180 : 90} />
      </Button>

      <div className={styles.previewList} ref={previewListRef}>
        {images.map((item) => (
          <button
            type="button"
            className={styles.imagePreviewContainer}
            onClick={handleSelect(item)}
            key={item.id}
          >
            <Text
              className={styles.citationHeader}
              variant={isTablet ? "h6" : "h4"}
              align="center"
            >
              Citation
              <span>{item.citationNumber}</span>
            </Text>

            <ImagePreview src={item.url} checked={image?.id === item.id} />
          </button>
        ))}
      </div>

      <Button
        size="small"
        variant="text"
        onClick={() => scrollPreviewList("down")}
        className={styles.scrollButton}
      >
        <Icon name="arrow-down" rotateAngle={vOrientation ? 0 : -90} />
      </Button>
    </div>
  )
}

export default PreviewList
