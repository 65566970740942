import React from "react"
import { useAlert } from "react-alert"

import Box from "@components/ui/Dropdown/Box"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import { Point } from "@framework/types/common"
import Text from "@components/ui/Typography/Text"
import IconButton from "@components/ui/IconButton/IconButton"
import Button from "@components/ui/Button/Button"
import { copyToClipboard } from "@utils/clipboard"
import Icon from "@components/ui/Icon/Icon"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import { pointToCode } from "../utils"

import styles from "./Tooltip.module.sass"

export const CellAllContentTooltip: React.FC<{
  cell: Point
}> = ({ cell, children }) => {
  const alert = useAlert()

  const isTablet = useMediaQuery(AppMediaQueries.minTablet)

  const longTextModal = useModal(ModalsTypes.LONG_TEXT_MODAL)

  return (
    <Box color="primary" className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>
          <Button
            variant="slim"
            size={isTablet ? "medium" : "small"}
            className={styles.inline}
            onClick={() => {
              longTextModal.showModal({
                title: pointToCode(cell),
                content: children,
              })
            }}
          >
            Read All
          </Button>

          <Text variant="caption2" color="text50Color">
            (Content is truncated)
          </Text>
        </div>

        {typeof children === "string" && (
          <IconButton
            title="Copy to clipboard"
            onClick={(e) => {
              copyToClipboard(children)
              alert.info("Link copied to clipboard")
              e.stopPropagation()
            }}
          >
            <Icon name="clipboard" />
          </IconButton>
        )}
      </div>
    </Box>
  )
}

export default CellAllContentTooltip
