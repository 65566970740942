import React from "react"
import { useAlert } from "react-alert"
import { observer } from "mobx-react-lite"
import { nanoid } from "nanoid"
import { updatedDiff } from "deep-object-diff"

import {
  ProductSolutionCategory,
  SolutionDetails,
  SolutionEntity,
  WorkFlowType,
} from "@framework/types/solution"
import { capitalizeFirstLetter } from "@utils/textUtils"
import { useController, useStore } from "@store"
import { FailResponse, SuccessResponse } from "@framework/types/common"

import BaseModal from "../components/BaseModal/BaseModal"
import ModalTitle from "../components/ModalTitle/ModalTitle"
import useModal from "../useModal"
import { ModalsTypes } from "../constants"
import BasicInfoForm, {
  FormData,
} from "./components/BasicInfoForm/BasicInfoForm"

import styles from "./EditSolutionModal.module.sass"

export type EditSolutionModalProps = {
  workflowType: WorkFlowType
  category?: ProductSolutionCategory
  solution?: SolutionEntity
  onSubmit?: (data: SolutionEntity) => void
}

export const EditSolutionModal: React.FC<EditSolutionModalProps> = observer(
  ({ workflowType, category, solution, onSubmit }) => {
    const alert = useAlert()

    const modal = useModal(ModalsTypes.EDIT_SOLUTION_MODAL)

    const { solutionsStore, userSetupStore } = useStore()
    const { solutionsController } = useController()

    const isEdit = solution != null

    const handleClose = () => modal.hideModal()

    const handleSubmit = async (data: FormData) => {
      let result: FailResponse | SuccessResponse<SolutionEntity> | null = null

      if (solution) {
        const payload: Partial<SolutionDetails> = updatedDiff(solution, data)
        result = await solutionsController.updateSolution(solution.id, payload)
      } else {
        const payload: SolutionDetails = {
          ...data,
          workflowType,
          key: nanoid(),
        }

        result = await solutionsController.createSolution(
          userSetupStore.avatarId,
          payload
        )
      }

      if (result.status === "FAILED") {
        alert.error(result)
        return
      }

      if (onSubmit?.(result.data) ?? true) modal.hideModal()
    }

    const title = `${isEdit ? "Edit" : "Create New"} ${capitalizeFirstLetter(
      workflowType
    )} Solution`

    const initialValue = {
      ...solution,
      category: solution?.category || category,
    }

    return (
      <BaseModal
        className={styles.root}
        title={<ModalTitle titleText={title} />}
        containerClassName={styles.container}
        onClose={handleClose}
      >
        <BasicInfoForm
          initialValues={initialValue}
          onSubmit={handleSubmit}
          onCancel={handleClose}
          isLoading={solutionsStore.isLoading}
          isEdit={isEdit}
        />
      </BaseModal>
    )
  }
)

export default EditSolutionModal
