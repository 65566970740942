import React from "react"
import { observer } from "mobx-react-lite"
import { Navigate, Route, Routes } from "react-router-dom"

import { useStore } from "@store/index"
import UsageAnalyticsPage from "@pages/usage-analytics/UsageAnalyticsPage"
import AvatarRequiredScope from "@pages/home/AvatarRequiredScope"
import APIUsageAnalyticsPage from "@pages/api-usage-analytics"

import MenuPage from "./Menu/Menu"

const AnalyticsRoot: React.FC = observer(() => {
  const { restrictionsStore: access } = useStore()

  return (
    <AvatarRequiredScope>
      <Routes>
        <Route index element={<MenuPage />} />

        {access.isAppUsageAnalyticsAvailable && (
          <Route
            path="/usage/*"
            element={
              <AvatarRequiredScope>
                <UsageAnalyticsPage />
              </AvatarRequiredScope>
            }
          />
        )}

        {access.isAPIUsageAnalyticsAvailable && (
          <Route path="/api-usage/*" element={<APIUsageAnalyticsPage />} />
        )}

        <Route path="/*" element={<Navigate to="." replace />} />
        <Route path="/**" element={<Navigate to="." replace />} />
      </Routes>
    </AvatarRequiredScope>
  )
})

export default AnalyticsRoot
