/* eslint-disable camelcase */
import React, { useEffect, useMemo } from "react"
import { To, useNavigate } from "react-router-dom"
import { useAlert } from "react-alert"
import { observer } from "mobx-react-lite"

import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import MainBoxLayout from "@components/layout/MainBoxLayout/MainBoxLayout"
import googleService from "@services/google.service"
import { useStore } from "@store/index"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"

import { OAuthState, useAuthCodeParams } from "./useAuthCodeParams"
import { CONNECT_GOOGLE_DRIVE_REDIRECT_URI } from "./constants"
import ConnectorOAuthForm, { FormDataType } from "./ConnectorOAuthForm"

import styles from "./AddDataConnector.module.sass"

const DEFAULT_TITLE = "Connect to Google Drive Account"
const DEFAULT_REQUIREMENTS = ""

export interface ConnectGoogleDrivePageProps {
  title?: string
  requirements?: string
  backPath: To
}

export const ConnectGoogleDrivePage: React.FC<ConnectGoogleDrivePageProps> =
  observer(
    ({
      title = DEFAULT_TITLE,
      requirements = DEFAULT_REQUIREMENTS,
      backPath,
    }) => {
      const navigate = useNavigate()
      const alert = useAlert()

      const redirectParams = useAuthCodeParams<FormDataType>(
        (form) => form.name != null
      )

      const {
        uploadSourceStore: { isDataConnectorLoading, connectGoogleDrive },
      } = useStore()

      const handleSubmit = async (form: FormDataType) => {
        window.location.href = googleService.getGoogleDriveAuthCodeLink({
          client_id: form.client_id,
          state: OAuthState.stringify(form),
          redirect_uri: CONNECT_GOOGLE_DRIVE_REDIRECT_URI,
        })
      }

      const handleBack = () => navigate(backPath)

      useEffect(() => {
        if (redirectParams) {
          const {
            form: { client_id, client_secret, name },
            code,
          } = redirectParams
          connectGoogleDrive(name, client_id, client_secret, code).then(
            ({ payload, error }) => {
              if (error != null) {
                alert.error(error)
                navigate(backPath, {
                  replace: true,
                })
                return
              }

              if (payload.id) {
                navigate(`${backPath}/${payload.id}`, { state: "FIRST_TIME" })
                return
              }

              handleBack()
            }
          )
        }
      }, [redirectParams])

      const isLoading = isDataConnectorLoading

      const initialValues = useMemo(
        () => ({
          name: redirectParams?.form.name ?? "",
          client_id: redirectParams?.form.client_id ?? "",
          client_secret: redirectParams?.form.client_secret ?? "",
        }),
        [redirectParams]
      )

      return (
        <MainBoxLayout className={styles.root} onBack={handleBack}>
          <DocumentIcon
            className={styles.icon}
            icon="img:google-drive"
            alt="google-drive"
          />

          <ModalTitle titleText={title} subtitleText={requirements} />

          <ConnectorOAuthForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </MainBoxLayout>
      )
    }
  )

export default ConnectGoogleDrivePage
