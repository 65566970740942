import React from "react"
import { observer } from "mobx-react-lite"

import useAvatarKnowledgePreviewStore from "@store/avatar-knowledge/useAvatarKnowledgePreview"
import { useController } from "@store/index"
import { DataCategoryEntity } from "@framework/types/knowledge"
import NotFound from "@components/ui/NotFound/NotFound"
import Loader from "@components/ui/Loader/BarLoader"

export type ActiveCategoryContextData = DataCategoryEntity

export const ActiveCategoryContext =
  React.createContext<ActiveCategoryContextData | null>(null)

export type Props = {
  categoryId: string
  avatarId: string
}

export const ActiveCategoryContextProvider: React.FC<Props> = observer(
  ({ children, categoryId, avatarId }) => {
    const { dataCategoriesStore } = useAvatarKnowledgePreviewStore(avatarId)

    const { avatarKnowledgePreviewController } = useController()

    React.useEffect(() => {
      if (
        avatarId &&
        !dataCategoriesStore.isLoading &&
        dataCategoriesStore.data == null
      )
        avatarKnowledgePreviewController.loadDataCategories(avatarId)
    }, [avatarId])

    const category = dataCategoriesStore.getById(categoryId)

    if (dataCategoriesStore.isLoading) return <Loader size="huge" fluid />

    if (category != null) {
      return (
        <ActiveCategoryContext.Provider value={category}>
          {children}
        </ActiveCategoryContext.Provider>
      )
    }

    return <NotFound />
  }
)

export default ActiveCategoryContextProvider
