/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react"
import { observer } from "mobx-react-lite"

import { Point } from "@framework/types/common"

import { useMatrixContext } from "./MatrixContext"
import { includes } from "./utils"

import styles from "./Spreadsheet.module.sass"

export const SelectionSquare: React.FC<{
  cell: Point
}> = observer(({ cell }) => {
  const { grid } = useMatrixContext()

  const isVisible = includes(grid.visibleGridRect, cell)
  const position = grid.getCellCoordinates(cell)

  if (!isVisible) return null
  return (
    <div
      className={styles.selected}
      role="button"
      tabIndex={0}
      style={{
        left: position.x,
        top: position.y,
        width: grid.getCellWidth(cell.x),
        height: grid.getCellHeight(cell.y),
      }}
    />
  )
})

export default SelectionSquare
