import { observer } from "mobx-react-lite"
import React from "react"
import { useAlert } from "react-alert"

import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import { UserData } from "@framework/types/user"
import UserCard from "@components/ui/UserCard/UserCard"
import Button from "@components/ui/Button/Button"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import { useController } from "@store/index"

import styles from "./ConfirmUserDeleteModal.module.sass"

export interface ConfirmUserDeleteModalProps {
  user: UserData
}

export const ConfirmUserDeleteModal: React.FC<ConfirmUserDeleteModalProps> =
  observer(({ user }) => {
    const alert = useAlert()
    const { hideModal } = useModal(ModalsTypes.CONFIRM_USER_DELETE_MODAL)
    const { adminUsersController } = useController()

    const handleSubmit = async () => {
      hideModal()
      const error = await adminUsersController.removeUser(user.id)
      if (error) {
        alert.error(
          <>
            Failed to delete user <b>{user.email}</b>
          </>
        )
      } else {
        alert.success(
          <>
            User <b>{user.email}</b> was deleted
          </>
        )
      }
    }

    return (
      <BaseModal
        title={<ModalTitle titleText="Are you sure you want to delete user?" />}
        className={styles.root}
        containerClassName={styles.container}
        onClose={hideModal}
      >
        <UserCard
          variant="card"
          fullName={`${user.firstName} ${user.lastName}`}
          metaInfo={user.email}
        />

        <ModalFooterContainer className={styles.footer}>
          <Button variant="outlined" onClick={hideModal}>
            Cancel
          </Button>
          <Button color="red" onClick={handleSubmit}>
            Delete
          </Button>
        </ModalFooterContainer>
      </BaseModal>
    )
  })

export default ConfirmUserDeleteModal
