import React, { useEffect } from "react"
import clsx from "clsx"

import useGlobalController from "@components/hooks/useGlobalScrollController"
import usePrevious from "@components/hooks/usePrevious"

import styles from "./BlurContainer.module.sass"

export interface BlurContainerProps {
  blur?: boolean
  withScroll?: boolean
  className?: string
  style?: React.CSSProperties
  hideBackgroundBlur?: boolean
}

export const BlurContainer: React.FC<BlurContainerProps> = ({
  blur = false,
  withScroll = false,
  className,
  children,
  style,
  hideBackgroundBlur,
}) => {
  const prevBlur = usePrevious(blur)

  const { switchScroll } = useGlobalController()

  useEffect(() => {
    switchScroll(!!blur && !withScroll)
  }, [blur, withScroll])

  useEffect(() => () => switchScroll(false), [])

  return (
    <div
      style={style}
      className={clsx(styles.root, className, {
        [styles.hidden]: !blur && prevBlur !== blur,
        [styles.blured]: blur,
        [styles.hideBackgroundBlur]: hideBackgroundBlur,
      })}
    >
      {children}
    </div>
  )
}

export default BlurContainer
