import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate, useParams } from "react-router-dom"
import clsx from "clsx"

import List from "@components/ui/List/List"
import useQuery from "@components/hooks/useQuery"
import { SUBTOPIC_ID_QUERY_PARAM_KEY } from "@pages/expert-insights/Topics/constants"
import NotFound from "@components/ui/NotFound/NotFound"
import { constructComplexUrlWithParams } from "@utils/textUtils"
import useSubtopicsCollection from "@pages/expert-insights/Topics/hooks/useSubtopicsCollection"
import Loader from "@components/ui/Loader/BarLoader"

import SubtopicsList from "./SubtopicsList"
import { BreadCrumpsCheckpoint } from "../FormalKnowledgeDetails/BreadCrumpsContext/BreadCrumpsCheckpoint"
import BreadCrumpsIndicator from "../FormalKnowledgeDetails/BreadCrumpsContext/BreadCrumpsIndicator"
import ArticleList from "./ArticleList"

import styles from "./Subtopics.module.sass"

export interface SubtopicsProps {
  baseURL: string
}

export const SubtopicsPage: React.FC<SubtopicsProps> = observer(
  ({ baseURL }) => {
    const navigate = useNavigate()

    const { topicId, avatarId } = useParams<{
      topicId: string
      avatarId: string
    }>()

    const queryParams = useQuery()

    if (topicId == null || avatarId == null)
      throw new Error(
        "Next params are required for the page: topicId, avatarId"
      )

    const subtopicId: string | undefined =
      queryParams.get(SUBTOPIC_ID_QUERY_PARAM_KEY) || undefined

    const collection = useSubtopicsCollection(topicId)

    const handleSelectSubtopic = (id?: string) => {
      navigate(
        constructComplexUrlWithParams(".", {
          [SUBTOPIC_ID_QUERY_PARAM_KEY]: id,
        }),
        { replace: true }
      )
    }

    React.useEffect(() => {
      if (collection.data.length === 0) handleSelectSubtopic(undefined)
      else if (subtopicId == null) handleSelectSubtopic(collection.data[0].id)
    }, [collection.data])

    return (
      <BreadCrumpsCheckpoint
        label="Subtopics"
        redirectLink={`${baseURL}/${topicId}`}
      >
        <List gutter="24" className={styles.root}>
          <BreadCrumpsIndicator className={styles.block} />

          <div className={styles.body}>
            <SubtopicsList
              className={styles.sidebarContainer}
              topicId={topicId}
              avatarId={avatarId}
              selected={subtopicId}
              onSelect={handleSelectSubtopic}
            />
            <div className={clsx(styles.contentContainer, styles.block)}>
              {subtopicId != null ? (
                <ArticleList avatarId={avatarId} subtopicId={subtopicId} />
              ) : collection.isLoading ? (
                <Loader size="large" primary fluid />
              ) : (
                <NotFound>Please select a Subtopic</NotFound>
              )}
            </div>
          </div>
        </List>
      </BreadCrumpsCheckpoint>
    )
  }
)

export default SubtopicsPage
