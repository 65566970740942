import React from "react"
import { observer } from "mobx-react-lite"

import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import useEvent from "@components/hooks/useEvent"
import { LATEST_DATA_TYPE_CHOICE_KEY } from "@framework/constants/upload"

export type OnSelectOptions = {
  autoSelect?: boolean
  invalidateDefault?: boolean
  onSelect?: (dataTypeId: string) => void
}

export interface ISelectDataTypeContext {
  dataTypeId?: string
  selectDataType: (options?: OnSelectOptions) => void
}

export interface SelectDataTypeContextProviderProps {
  children: React.ReactNode
}

export const SelectDataTypeContext =
  React.createContext<ISelectDataTypeContext | null>(null)

export const SelectDataTypeContextProvider: React.FC<SelectDataTypeContextProviderProps> =
  observer(({ children }) => {
    const [dataTypeId, setDatatypeId] = React.useState<string>(
      () => localStorage.getItem(LATEST_DATA_TYPE_CHOICE_KEY) || ""
    )

    const preselectModal = useModal(ModalsTypes.PRESELECT_DATA_TYPE_MODAL)

    const handleSelect = useEvent((options?: OnSelectOptions) => {
      if (options?.invalidateDefault) {
        localStorage.removeItem(LATEST_DATA_TYPE_CHOICE_KEY)
        setDatatypeId("")
      }

      const initialDataTypeId = options?.invalidateDefault
        ? undefined
        : dataTypeId

      const storedValue =
        localStorage.getItem(LATEST_DATA_TYPE_CHOICE_KEY) || ""

      const wasStored = !!storedValue

      preselectModal.showModal({
        initialValue: wasStored
          ? { dataTypeId: initialDataTypeId, remember: wasStored }
          : {},

        autoSelect: wasStored && options?.autoSelect,

        onSubmit: ({ dataTypeId, remember }) => {
          if (remember)
            localStorage.setItem(LATEST_DATA_TYPE_CHOICE_KEY, dataTypeId)
          else localStorage.removeItem(LATEST_DATA_TYPE_CHOICE_KEY)

          setDatatypeId(dataTypeId)

          options?.onSelect?.(dataTypeId)
        },
      })
    })

    return (
      <SelectDataTypeContext.Provider
        value={React.useMemo(
          () => ({
            selectDataType: handleSelect,
            dataTypeId,
          }),
          [handleSelect, dataTypeId]
        )}
      >
        {children}
      </SelectDataTypeContext.Provider>
    )
  })

export const useSelectDataTypeContext = () => {
  const context = React.useContext(SelectDataTypeContext)
  if (context == null) throw new Error("SelectDataTypeContext not found")
  return context
}
