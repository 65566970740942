import React, { useEffect } from "react"
import { useFormik } from "formik"
import * as yup from "yup"

import TextInput, { TextAreaInput } from "@components/ui/TextInput/TextInput"
import Button from "@components/ui/Button/Button"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import Loader from "@components/ui/Loader/BarLoader"

import styles from "./EditForm.module.sass"

export const validationSchema = yup.object({
  name: yup
    .string()
    .required()
    .label("Name")
    .min(3, "Must be at least 3 chars long")
    .max(80, "Must be at max 80 chars long")
    .default("")
    .trim(),
  description: yup
    .string()
    .label("Description")
    .max(250, "Must be 250 chars maximum")
    .default("")
    .trim(),
})

export type FormData = yup.InferType<typeof validationSchema>

interface EditUserGroupFormProps {
  isLoading?: boolean
  className?: string
  errors?: FormData
  initialValues?: FormData
  onSubmit: (form: FormData) => void
}

const EditUserGroupForm: React.FC<EditUserGroupFormProps> = ({
  errors,
  isLoading,
  initialValues = validationSchema.getDefault(),
  onSubmit,
}) => {
  const formik = useFormik<FormData>({
    initialValues,
    validationSchema,
    onSubmit: (form: FormData) => {
      onSubmit(validationSchema.cast(form))
    },
  })

  useEffect(() => {
    if (errors) formik.setErrors(errors)
  }, [errors])

  return (
    <form className={styles.root} onSubmit={formik.handleSubmit}>
      <div className={styles.form}>
        <TextInput
          autoFocus
          name="name"
          placeholder="Enter Group name..."
          value={formik.values.name}
          withError={!!(formik.touched.name && formik.errors.name)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          after={
            formik.touched.name &&
            formik.errors.name && (
              <div className={styles.after}>
                <ErrorChip
                  message={formik.errors.name}
                  messagePlacement="left"
                />
              </div>
            )
          }
        />
        <TextAreaInput
          name="description"
          placeholder="Add some description..."
          value={formik.values.description}
          withError={
            !!(formik.touched.description && formik.errors.description)
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          after={
            formik.touched.description &&
            formik.errors.description && (
              <div className={styles.after}>
                <ErrorChip
                  message={formik.errors.description}
                  messagePlacement="left"
                />
              </div>
            )
          }
        />
        <Button type="submit" color="primary" disabled={isLoading}>
          Save {isLoading && <Loader />}
        </Button>
      </div>
    </form>
  )
}

export default EditUserGroupForm
