import { observer } from "mobx-react-lite"
import moment from "moment"
import React from "react"

import DatePicker from "@components/ui/DatePicker/DatePicker"
import { YYYY_MM_DD_FORMAT } from "@framework/constants/global"
import { useController, useStore } from "@store"
import Text from "@components/ui/Typography/Text"

const ContractDates = observer(() => {
  const { subscriptionStore } = useStore()
  const { subscriptionController } = useController()
  const { isEditable, data } = subscriptionStore
  const initialDate = React.useMemo(
    () => [
      moment(data.contractStartDate || new Date(), YYYY_MM_DD_FORMAT).toDate(),
      moment(data.contractEndDate || new Date(), YYYY_MM_DD_FORMAT).toDate(),
    ],
    [data]
  )

  const handleChangePeriod = (value?: Date | Date[]) => {
    if (!value || !Array.isArray(value) || value.length !== 2) return

    const [startDate, endDate] = value
    const formattedStartDate = moment(startDate).format(YYYY_MM_DD_FORMAT)
    const formattedEndDate = moment(endDate).format(YYYY_MM_DD_FORMAT)

    subscriptionController.updateEditData(
      "contractStartDate",
      formattedStartDate
    )
    subscriptionController.updateEditData("contractEndDate", formattedEndDate)
  }

  const formattedStartDate = moment(data.contractStartDate).format("LL")
  const formattedEndDate = moment(data.contractEndDate).format("LL")

  return isEditable ? (
    <DatePicker defValue={initialDate} onChange={handleChangePeriod} />
  ) : (
    <>
      <Text variant="body2">Start Date: {formattedStartDate}</Text>
      <Text variant="body2">End Date: {formattedEndDate}</Text>
    </>
  )
})

export default ContractDates
