import { makeAutoObservable } from "mobx"

import { Point, Range } from "@framework/types/common"

import { makeRange, totalRangeCells } from "../utils"

export class RectSelection {
  constructor() {
    makeAutoObservable(this)
  }

  rangeStarted: boolean = false

  origin: Point = { x: 0, y: 0 }

  range: Range<Point> = {
    start: { x: 0, y: 0 },
    end: { x: 0, y: 0 },
  }

  setSelection = (point: Point) => {
    this.origin = { ...point }
    this.range = { start: { ...point }, end: { ...point } }
  }

  startRange = (point: Point) => {
    this.origin = { ...point }
    this.range = { start: { ...point }, end: { ...point } }
    this.rangeStarted = true
  }

  updateRange = (point: Point) => {
    if (!this.rangeStarted) return

    this.range = makeRange(point, this.origin)
  }

  endRange = () => {
    if (this.rangeStarted) this.rangeStarted = false
  }

  selectRange = (range: Range<Point>) => {
    this.range = range
    this.origin = range.start
  }

  isRowSelected = (index: number) => {
    return this.range.start.y <= index && this.range.end.y >= index
  }

  isColumnSelected = (index: number) => {
    return this.range.start.x <= index && this.range.end.x >= index
  }

  get isRange() {
    return totalRangeCells(this.range) > 1
  }
}

export default RectSelection
