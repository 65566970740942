/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import clsx from "clsx"
import React, { MouseEventHandler } from "react"

import { Icon } from "@components/ui/Icon/Icon"
import { IconName } from "@components/ui/Icon/IconName"

import styles from "./QuestionSuggestionItem.module.sass"

export interface QuestionItemProps {
  question: string
  className?: string
  isSelected?: boolean
  disabled?: boolean
  beforeIcon?: IconName
  after?: React.ReactNode
  withArrow?: boolean
  onClick?: MouseEventHandler
  onMouseDown?: MouseEventHandler
}

export const QuestionSuggestionItem: React.FC<QuestionItemProps> = ({
  question,
  className,
  isSelected = false,
  disabled = false,
  withArrow = false,
  after,
  beforeIcon,
  onClick,
  onMouseDown,
}) => (
  <li
    onClick={onClick}
    onMouseDown={onMouseDown}
    className={clsx(styles.root, className, {
      [styles.clickable]: !!onClick,
      [styles.selected]: isSelected,
      [styles.disabled]: disabled,
    })}
  >
    {beforeIcon != null && <Icon name={beforeIcon} color="secondary" />}
    <span className={styles.text}>{question}</span>
    {after}
    {withArrow && (
      <Icon name="arrow-down" rotateAngle={-90} className={styles.arrow} />
    )}
  </li>
)

export default QuestionSuggestionItem
