import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React, { useEffect, useMemo } from "react"
import { useNavigate, useParams } from "react-router-dom"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import ScrollableContainer from "@components/modals/CreateAvatarModal/components/ScrollableContainer/ScrollableContainer"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import Switch from "@components/ui/Switch/Switch"
import {
  AssignedGroupType,
  QuestionAssignityType,
} from "@framework/types/question"
import { Option } from "@framework/types/utils"
import mainRoutes from "@root/main.routes"
import { useStore } from "@store/index"

import AnsweredQuestionForm from "../components/QuestionForm/AnsweredQuestionForm"
import DeclinedQuestionForm from "../components/QuestionForm/DeclinedQuestionForm"
import OtherExpertQuestionForm from "../components/QuestionForm/OtherExpertQuestionForm"
import WaitingQuestionForm from "../components/QuestionForm/WaitingQuestionForm"
import QuestionList from "../components/QuestionList/QuestionList"
import Header from "./Header"

import styles from "./Questions.module.sass"

const tabs: Record<QuestionAssignityType, Option<QuestionAssignityType>> = {
  waiting: {
    name: "waiting",
    value: "Awaiting for answering",
  },
  answered: {
    name: "answered",
    value: "Answered",
  },
  declined: {
    name: "declined",
    value: "Declined",
  },
}

const tabOptions = Object.values(tabs)

type ParamsType = {
  tab: QuestionAssignityType
  groupBy: AssignedGroupType
  questionId?: string
}

interface QuestionsPageProps {}

const QuestionsPage: React.FC<QuestionsPageProps> = observer(() => {
  const { tab, groupBy, questionId } = useParams<ParamsType>()
  const navigate = useNavigate()

  const { knowledgeStore } = useStore()
  const {
    expertQuestions,
    isExpertQuestionsLoading,
    isUpdateExpertQuestionLoading,
    loadExpertQuestions,
    loadThreadByQuestion,
  } = knowledgeStore

  const questionsList = useMemo(() => {
    if (tab == null) return []

    const questions = expertQuestions[tab]

    if (!questions) return []

    return questions.filter(
      ({ delegatedToSelf }) => delegatedToSelf === (groupBy === "own")
    )
  }, [expertQuestions, tab, groupBy])

  const handleQuestionChange = (questionId: string) => {
    navigate(mainRoutes.question(groupBy, tab, questionId), { replace: true })
  }

  const handleTabChange = (newTab: QuestionAssignityType) => {
    navigate(mainRoutes.question(groupBy, newTab), { replace: true })
  }

  const handleGroupChange = (newGroup: AssignedGroupType) => {
    navigate(mainRoutes.question(newGroup), { replace: true })
  }

  useEffect(() => {
    loadExpertQuestions()
  }, [])

  useEffect(() => {
    if (
      !isExpertQuestionsLoading &&
      questionsList.length &&
      (!questionId || !questionsList.find(({ id }) => id === questionId))
    ) {
      handleQuestionChange(questionsList[0]?.id ?? "")
    }
  }, [questionsList, questionId, isExpertQuestionsLoading])

  const currentQuestion =
    questionsList.find(({ id }) => id === questionId) ?? null

  const questionText = currentQuestion?.question
  useEffect(() => {
    if (questionText) {
      loadThreadByQuestion(questionText, currentQuestion.id)
    }
  }, [questionText])

  return (
    <MainLayout>
      <div className={styles.root}>
        <Header
          className={styles.header}
          groupBy={groupBy}
          onGroupChange={handleGroupChange}
        />

        <div className={styles.body}>
          <div className={clsx(styles.left, styles.container)}>
            {groupBy === "own" && (
              <Switch
                items={tabOptions}
                checked={tab}
                onChange={handleTabChange}
              />
            )}

            <ScrollableContainer className={styles.questionListContainer}>
              {isExpertQuestionsLoading ? (
                <Loader size="large" primary fluid />
              ) : (
                <QuestionList
                  items={questionsList}
                  activeQuestion={questionId}
                  onSelect={handleQuestionChange}
                  emptyMessage="No questions found"
                  className={styles.questionList}
                />
              )}
            </ScrollableContainer>
          </div>

          <span className={styles.divider} />

          <div className={clsx(styles.right, styles.container)}>
            {isExpertQuestionsLoading || isUpdateExpertQuestionLoading ? (
              <Loader size="large" primary fluid />
            ) : currentQuestion ? (
              <>
                {groupBy === "expert" ? (
                  <OtherExpertQuestionForm
                    questionData={currentQuestion}
                    key={currentQuestion.id}
                  />
                ) : (
                  (tab === "waiting" && (
                    <WaitingQuestionForm
                      questionData={currentQuestion}
                      key={currentQuestion.id}
                    />
                  )) ||
                  (tab === "answered" && (
                    <AnsweredQuestionForm
                      questionData={currentQuestion}
                      key={currentQuestion.id}
                    />
                  )) ||
                  (tab === "declined" && (
                    <DeclinedQuestionForm
                      questionData={currentQuestion}
                      key={currentQuestion.id}
                    />
                  ))
                )}
              </>
            ) : (
              <NotFound>No question selected</NotFound>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  )
})

export default React.memo(QuestionsPage)
