import React from "react"
import { observer } from "mobx-react-lite"

import Templates from "@components/ui/Templates"
import SideListItem from "@components/ui/ListItem/SideListItem"
import Text from "@components/ui/Typography/Text"
import { countSuffix } from "@utils/numberUtils"
import List from "@components/ui/List/List"
import { ExpertSubtopicDTO } from "@framework/types/knowledge"

import styles from "./SubtopicPreviewCard.module.sass"

export interface SubtopicPreviewCardProps {
  data: ExpertSubtopicDTO
  className?: string
  active?: boolean
  onClick?: React.MouseEventHandler
}

export const SubtopicPreviewCard: React.FC<SubtopicPreviewCardProps> = observer(
  ({ data, active, onClick }) => {
    const totalArticles = Number(data.articleCount || 0)

    return (
      <SideListItem active={active} onClick={onClick}>
        <List gutter="8">
          <Templates.Header
            left={<Text variant="h6">{data.title}</Text>}
            right={
              <Text color="text40Color" variant="caption3" inline>
                {`${totalArticles} Article${countSuffix(totalArticles)}`}
              </Text>
            }
          />

          <Text
            variant="body3"
            color="text70Color"
            className={styles.description}
            title={data.description}
          >
            {data.description || "N/A"}
          </Text>
        </List>
      </SideListItem>
    )
  }
)

export default SubtopicPreviewCard
