import React from "react"
import { observer } from "mobx-react-lite"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"

import CommentForm, { FormData } from "./CommentForm"

import styles from "./AddCommentModal.module.sass"

const DEFAULT_TITLE = "Add Comment"

export interface AddCommentModalProps {
  title?: string
  initialValue?: Partial<FormData>
  onSubmit?: (data: any) => Promise<void> | void
  onCancel?: () => void
}

export const AddCommentModal: React.FC<AddCommentModalProps> = observer(
  ({ title = DEFAULT_TITLE, initialValue, onSubmit, onCancel }) => {
    const modal = useModal(ModalsTypes.ADD_COMMENT_MODAL)

    const [isLoading, setLoading] = React.useState(false)

    const handleSubmit = async (data: any) => {
      setLoading(true)
      const shouldClose = (await onSubmit?.(data)) ?? true
      setLoading(false)

      if (shouldClose) modal.hideModal()
    }

    const handleClose = () => {
      onCancel?.()
      modal.hideModal()
    }

    return (
      <BaseModal
        className={styles.root}
        title={<ModalTitle titleText={title} />}
        onClose={handleClose}
      >
        <CommentForm
          initialValue={{ title: "Info", ...initialValue }}
          isLoading={isLoading}
          onSubmit={handleSubmit}
        />
      </BaseModal>
    )
  }
)

export default AddCommentModal
