import React, { useMemo, useState } from "react"
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List as VirtualList,
  ListProps,
} from "react-virtualized"
import { observer } from "mobx-react-lite"
import { FieldProps } from "formik"
import without from "lodash/without"

import Dropdown from "@components/prototypes/FilterSidebar/components/Dropdown/Dropdown"
import NotFound from "@components/ui/NotFound/NotFound"
import Loader from "@components/ui/Loader/BarLoader"
import Templates from "@components/ui/Templates"
import { useController, useStore } from "@store/index"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import ListItem from "@components/ui/ListItem/ListItem"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import List from "@components/ui/List/List"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import { SolutionData } from "@framework/types/solution"

import styles from "./SolutionsSelect.module.sass"

export interface SolutionsSelectProps extends FieldProps<string[]> {
  label?: string
  valueKey?: keyof SolutionData
}

const SolutionsSelect: React.FC<SolutionsSelectProps> = observer(
  ({ label, field, form, valueKey = "id" }) => {
    const [open, setOpen] = useState(false)

    const { value = [], name } = field
    const { setFieldValue } = form

    const { solutionsStore } = useStore()
    const { solutionsController } = useController()

    const handleClick = (newValue: string) => {
      if (!value.includes(newValue)) {
        setFieldValue(name, [...value, newValue])
      } else {
        setFieldValue(name, without(value, newValue))
      }
    }

    const renderRow: ListProps["rowRenderer"] = ({
      index,
      key,
      parent,
      style,
    }) => {
      const item = solutionsStore.getByIndex(index)

      if (!item)
        return (
          <div style={style} key={key}>
            <ListItem>
              <Skeleton lineHeight={style.height} minWidth={100} />
            </ListItem>
          </div>
        )

      const active = value?.includes(item[valueKey] as string)

      return (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          rowIndex={index}
          parent={parent}
          key={`${key}-cached`}
        >
          <div style={style} key={key}>
            <ListItem
              active={active}
              onClick={() => handleClick(item[valueKey] as string)}
            >
              <List direction="row" gutter="16" align="center">
                <DocumentIcon icon="jigsaw-puzzle" />
                {item.name}
              </List>
            </ListItem>
          </div>
        </CellMeasurer>
      )
    }

    const cache = useMemo(() => {
      return new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 58,
      })
    }, [])

    const isChecked = !!value?.length
    const total = solutionsStore.solutions?.length ?? 0

    const handleUncheck = (e: React.MouseEvent) => {
      if (isChecked) setFieldValue(name, undefined)
      else setOpen(true)
      e.stopPropagation()
    }

    React.useEffect(() => {
      solutionsController.loadAllSolutions()
    }, [solutionsController.loadAllSolutions])

    return (
      <Dropdown
        title={label}
        opened={open}
        onClick={() => setOpen((value) => !value)}
        before={
          <CheckboxWithLabel
            checked={!!value?.length}
            onClick={handleUncheck}
          />
        }
      >
        <Templates.RollScript className={styles.root}>
          <div className={styles.container}>
            {total === 0 ? (
              solutionsStore.isLoading ? (
                <Loader size="large" fluid />
              ) : (
                <NotFound>No Solutions found</NotFound>
              )
            ) : (
              <AutoSizer>
                {({ width, height }) => (
                  <VirtualList
                    rowCount={total}
                    rowHeight={cache.rowHeight}
                    rowRenderer={renderRow}
                    height={height}
                    width={width}
                  />
                )}
              </AutoSizer>
            )}
          </div>
        </Templates.RollScript>
      </Dropdown>
    )
  }
)

export default SolutionsSelect
