import React from "react"
import { observer } from "mobx-react-lite"

import MenuItem from "@components/containers/Sidebar/MenuItem"
import useToggle from "@components/hooks/useToggle"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import { useStore } from "@store/index"

export type ActionName = "edit" | "delete"

interface RoleContextMenuProps {
  onAction?: (action: ActionName) => void
}

const ContextMenu: React.FC<RoleContextMenuProps> = observer(({ onAction }) => {
  const { restrictionsStore: access } = useStore()

  const { isOpened, handleToggle, setOpened } = useToggle()

  const editHandler = (actionName: ActionName) => () => {
    onAction?.(actionName)
  }

  if (
    !access.canDeleteExpertArticleInsights &&
    !access.canUpdateExpertArticleInsights
  )
    return null

  return (
    <Tooltip
      show={isOpened}
      onClose={() => setOpened(false)}
      mode="onFocus"
      justify="end"
      content={
        <TooltipContainer color="primary" placement="bottom" tip="end">
          {access.canUpdateExpertArticleInsights && (
            <MenuItem icon="edit" onClick={editHandler("edit")} key="edit">
              Edit
            </MenuItem>
          )}
          {access.canDeleteExpertArticleInsights && (
            <MenuItem
              icon="trash-can"
              color="red"
              onClick={editHandler("delete")}
              key="delete"
            >
              Delete
            </MenuItem>
          )}
        </TooltipContainer>
      }
    >
      <IconButton size="medium" active={isOpened} onClick={handleToggle}>
        <Icon name="dots" />
      </IconButton>
    </Tooltip>
  )
})

export default ContextMenu
