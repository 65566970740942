import React from "react"

const NotFoundIllustration: React.FC<{
  width?: string | number
  height?: string | number
}> = ({ width = 200, height = 200 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 188 177"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.346408 94.4302C0.274167 75.8607 -2.62688 54.7834 9.71609 40.8966C21.8807 27.2104 44.1344 32.5883 61.2107 25.9529C79.9277 18.6799 94.284 -2.9478 114.096 0.337097C133.992 3.6358 143.765 25.8032 156.158 41.6951C169.047 58.2234 185.506 73.5721 187.474 94.4302C189.524 116.169 182.475 139.656 166.839 154.917C152.029 169.372 128.901 167.663 108.547 171.464C91.2319 174.698 74.2663 179.543 57.1461 175.402C39.0118 171.016 20.8378 162.925 10.1314 147.662C-0.492854 132.517 0.418352 112.923 0.346408 94.4302Z"
        fill="var(--primary-100-color, #8854f7)"
        fillOpacity="0.1"
      />
      <g clipPath="url(#clip0)">
        <path
          d="M139.688 94.3291H138.608V136.859H139.688V94.3291Z"
          fill="var(--secondary-70-color, #312E5C)"
        />
        <path
          d="M149.657 99.749C149.726 114.621 139.229 126.725 139.229 126.725C139.229 126.725 128.62 114.718 128.552 99.8459C128.483 84.9742 138.98 72.8699 138.98 72.8699C138.98 72.8699 149.588 84.8773 149.657 99.749Z"
          fill="var(--secondary-70-color, #312E5C)"
        />
        <path
          d="M118.09 114.34H117.534V136.214H118.09V114.34Z"
          fill="var(--secondary-70-color, #312E5C)"
        />
        <path
          d="M123.217 117.128C123.252 124.777 117.853 131.002 117.853 131.002C117.853 131.002 112.397 124.826 112.362 117.178C112.326 109.529 117.725 103.303 117.725 103.303C117.725 103.303 123.181 109.479 123.217 117.128Z"
          fill="var(--secondary-70-color, #312E5C)"
        />
        <path
          d="M129.04 139.127C137.353 139.127 144.093 138.097 144.093 136.827C144.093 135.557 137.353 134.527 129.04 134.527C120.727 134.527 113.988 135.557 113.988 136.827C113.988 138.097 120.727 139.127 129.04 139.127Z"
          fill="var(--secondary-70-color, #312E5C)"
        />
        <path
          d="M130.282 83.2262C129.007 81.0784 126.484 80.9783 126.484 80.9783C126.484 80.9783 124.025 80.6649 122.448 83.9361C120.978 86.9851 118.949 89.929 122.121 90.6428L122.694 88.8651L123.049 90.7751C123.501 90.8075 123.954 90.8152 124.406 90.7982C127.804 90.6889 131.039 90.8302 130.935 89.6151C130.796 87.9998 131.509 85.2929 130.282 83.2262Z"
          fill="#0C0930"
        />
        <path
          d="M125.689 87.2709C125.689 87.2709 127.337 89.5707 126.348 91.4325C125.359 93.2943 128.655 95.2656 128.655 95.2656L131.073 90.0088C131.073 90.0088 128.216 88.147 128.985 86.3948L125.689 87.2709Z"
          fill="#FBBEBE"
        />
        <path
          d="M126.788 88.2566C128.366 88.2566 129.645 86.9818 129.645 85.4092C129.645 83.8366 128.366 82.5618 126.788 82.5618C125.21 82.5618 123.931 83.8366 123.931 85.4092C123.931 86.9818 125.21 88.2566 126.788 88.2566Z"
          fill="#FBBEBE"
        />
        <path
          d="M116.902 88.7007L119.289 85.6305C119.289 85.6305 120.13 82.4124 121.347 82.5756C122.565 82.7387 120.867 86.4349 120.867 86.4349L118.494 89.8333L116.902 88.7007Z"
          fill="#FBBEBE"
        />
        <path
          d="M128.986 129.544L129.755 133.596L131.403 134.034L130.963 129.216L128.986 129.544Z"
          fill="#FBBEBE"
        />
        <path
          d="M145.576 125.163L148.542 129.216L149.751 129.763L150.74 128.12L147.773 124.287L145.576 125.163Z"
          fill="#FBBEBE"
        />
        <path
          d="M128.325 93.5134L126.492 91.1085C126.492 91.1085 122.942 91.7612 122.612 92.0897C122.282 92.4183 123.491 98.4417 123.491 98.4417C123.491 98.4417 123.711 100.084 124.699 100.961L125.688 101.618L133.819 99.9749L134.124 96.369C134.236 95.0556 134.071 93.7335 133.64 92.4874C133.209 91.2413 132.521 90.0987 131.621 89.1328L130.207 89.3256L128.325 93.5134Z"
          fill="#575A89"
        />
        <path
          d="M123.052 92.3087L122.503 92.0897L119.976 91.8706C119.976 91.8706 119.097 91.6516 119.317 91.2135C119.536 90.7755 119.756 90.666 119.317 90.5564C118.877 90.4469 118.767 90.3374 118.877 90.0089C118.987 89.6803 119.646 89.0232 119.646 89.0232L117.778 87.49C117.778 87.49 117.677 87.5613 117.514 87.6931C116.587 88.4415 113.652 91.1417 115.801 93.8419C118.328 97.0179 121.294 98.8797 123.711 98.2226L123.052 92.3087Z"
          fill="#575A89"
        />
        <path
          d="M125.47 101.07V102.603C125.47 102.603 124.481 104.465 124.811 106.217C125.141 107.97 125.25 108.846 125.25 108.846C125.286 110.336 125.546 111.812 126.02 113.226C126.789 115.417 124.262 129.763 127.448 129.982C130.634 130.201 132.392 130.42 133.381 129.325C134.37 128.23 131.733 109.393 131.733 109.393C131.733 109.393 140.742 128.12 142.61 127.354C144.478 126.587 149.202 124.835 148.653 123.849C148.104 122.864 134.26 100.96 134.26 100.96L133.82 99.9749L125.47 101.07Z"
          fill="#0C0930"
        />
        <path
          d="M130.743 132.829C130.743 132.829 128.985 132.72 128.985 133.268C128.985 133.815 128.106 135.677 128.106 135.677C128.106 135.677 127.447 137.867 129.205 137.648C130.963 137.429 132.061 135.458 132.061 135.458L131.622 133.377L130.743 132.829Z"
          fill="#0C0930"
        />
        <path
          d="M149.091 128.996C149.091 128.996 147.553 128.011 147.663 128.668C147.773 129.325 147.883 131.077 148.652 131.187C149.421 131.296 151.728 131.953 151.838 132.172C151.948 132.391 154.585 133.267 154.585 131.844C154.585 130.42 152.937 129.325 152.937 129.325L151.069 127.901C151.069 127.901 149.86 127.792 149.641 128.558C149.421 129.325 149.091 128.996 149.091 128.996Z"
          fill="#0C0930"
        />
        <path
          d="M126.792 82.8074C127.8 82.8074 128.616 81.9933 128.616 80.989C128.616 79.9848 127.8 79.1707 126.792 79.1707C125.785 79.1707 124.968 79.9848 124.968 80.989C124.968 81.9933 125.785 82.8074 126.792 82.8074Z"
          fill="#0C0930"
        />
        <path
          d="M124.776 80.5108C124.776 80.0617 124.943 79.6285 125.245 79.2946C125.546 78.9608 125.961 78.7499 126.409 78.7026C126.345 78.6959 126.281 78.6925 126.217 78.6924C125.733 78.6924 125.269 78.884 124.927 79.225C124.585 79.566 124.392 80.0285 124.392 80.5108C124.392 80.993 124.585 81.4555 124.927 81.7965C125.269 82.1376 125.733 82.3291 126.217 82.3291C126.281 82.3291 126.345 82.3256 126.409 82.3189C125.961 82.2716 125.546 82.0607 125.245 81.7269C124.943 81.3931 124.776 80.9599 124.776 80.5108Z"
          fill="#0C0930"
        />
        <path
          d="M129.717 83.019L127.225 81.7175L123.782 82.25L123.07 85.3852L124.843 85.3172L125.338 84.1654V85.2983L126.156 85.2669L126.631 83.4331L126.928 85.3852L129.836 85.3261L129.717 83.019Z"
          fill="#0C0930"
        />
        <path
          d="M129.569 101.324L125.725 101.427C125.725 101.427 122.445 102.063 122.629 100.744C122.813 99.4241 126.013 99.8856 126.013 99.8856L129.515 99.353L129.569 101.324Z"
          fill="#FBBEBE"
        />
        <path
          d="M132.61 88.947C132.803 88.9178 133 88.9583 133.166 89.0613C133.332 89.1643 133.456 89.3229 133.515 89.5086C134.267 91.8723 136.586 99.7826 134.282 100.868C131.569 102.146 129.589 102.09 129.589 102.09L128.529 99.4891L129.494 98.5867L130.524 91.9855L131.018 89.1874L132.61 88.947Z"
          fill="#575A89"
        />
        <path
          opacity="0.4"
          d="M131.897 93.3489L131.458 97.7295L127.612 99.3723L131.897 98.3866V93.3489Z"
          fill="black"
        />
        <path
          d="M111.158 78.887C112.397 79.2141 113.468 79.99 114.163 81.063L114.523 81.6178L114.541 81.6407L120.846 83.4796C120.961 83.5134 121.059 83.5909 121.118 83.6957C121.177 83.8005 121.192 83.924 121.16 84.0398L120.671 85.8288C120.655 85.887 120.627 85.9416 120.59 85.9893C120.553 86.037 120.507 86.0769 120.454 86.1067C120.401 86.1365 120.343 86.1556 120.283 86.1629C120.223 86.1703 120.162 86.1657 120.104 86.1494L113.797 84.3924L113.797 84.3925L113.164 84.6312C111.997 85.0707 110.718 85.1141 109.524 84.7545L111.158 78.887Z"
          fill="#3F3D56"
        />
        <path
          d="M117.798 84.0754C117.858 83.872 117.596 83.6166 117.213 83.505C116.831 83.3934 116.472 83.4678 116.412 83.6712C116.353 83.8746 116.614 84.13 116.997 84.2416C117.38 84.3532 117.738 84.2788 117.798 84.0754Z"
          fill="#575A89"
        />
        <path
          d="M117.476 84.263C117.622 84.263 117.741 84.1449 117.741 83.9992C117.741 83.8535 117.622 83.7354 117.476 83.7354C117.33 83.7354 117.211 83.8535 117.211 83.9992C117.211 84.1449 117.33 84.263 117.476 84.263Z"
          fill="#3F3D56"
        />
        <path
          d="M111.686 82.2759C112.211 80.6597 112.043 79.1574 111.31 78.9204C110.577 78.6834 109.556 79.8015 109.03 81.4176C108.505 83.0338 108.673 84.5361 109.406 84.7731C110.139 85.0101 111.16 83.892 111.686 82.2759Z"
          fill="#6C63FF"
        />
        <path
          opacity="0.4"
          d="M111.489 82.2187C111.995 80.6614 111.858 79.21 111.191 78.8943C111.231 78.8993 111.271 78.908 111.309 78.9204C112.042 79.1573 112.211 80.6596 111.685 82.2758C111.159 83.892 110.138 85.01 109.405 84.7731C109.379 84.7644 109.353 84.754 109.327 84.742C110.049 84.8335 110.991 83.7497 111.489 82.2187Z"
          fill="black"
        />
        <path
          d="M111.241 78.893L96.9316 67.8291C94.4012 65.8585 91.973 63.7609 89.6565 61.5445C85.5774 57.669 80.0401 54.5704 73.5931 52.9207C57.9298 48.9127 45.5212 56.0194 39.8774 66.4545C31.2531 82.4005 24.6437 108.276 62.8838 94.5026C71.2468 91.4903 79.7122 91.1702 86.131 89.5834L109.373 84.8068L111.241 78.893Z"
          fill="var(--primary-100-color, #8854f7)"
        />
        <path
          d="M68.2813 60.2752C68.7061 60.2752 69.0504 59.9319 69.0504 59.5086C69.0504 59.0852 68.7061 58.7419 68.2813 58.7419C67.8565 58.7419 67.5122 59.0852 67.5122 59.5086C67.5122 59.9319 67.8565 60.2752 68.2813 60.2752Z"
          fill="#F2F2F2"
        />
        <path
          d="M83.2217 61.2806H83.0215V61.0811H82.9824V61.2806H82.7822V61.3196H82.9824V61.5191H83.0215V61.3196H83.2217V61.2806Z"
          fill="#F2F2F2"
        />
        <path
          d="M103.328 77.9271H103.128V77.7275H103.089V77.9271H102.888V77.9661H103.089V78.1656H103.128V77.9661H103.328V77.9271Z"
          fill="#F2F2F2"
        />
        <path
          d="M101.9 83.0743H101.7V82.8748H101.66V83.0743H101.46V83.1133H101.66V83.3128H101.7V83.1133H101.9V83.0743Z"
          fill="#F2F2F2"
        />
        <path
          d="M40.4815 87.4549H40.2813V87.2554H40.2422V87.4549H40.042V87.4939H40.2422V87.6934H40.2813V87.4939H40.4815V87.4549Z"
          fill="#F2F2F2"
        />
        <path
          d="M49.2713 63.5804H49.0711V63.3809H49.032V63.5804H48.8318V63.6194H49.032V63.8189H49.0711V63.6194H49.2713V63.5804Z"
          fill="#F2F2F2"
        />
        <path
          d="M83.4412 83.9503H83.241V83.7507H83.2019V83.9503H83.0017V83.9893H83.2019V84.1888H83.241V83.9893H83.4412V83.9503Z"
          fill="#F2F2F2"
        />
        <path
          d="M62.4558 56.5714H62.2557V56.3718H62.2165V56.5714H62.0164V56.6104H62.2165V56.8099H62.2557V56.6104H62.4558V56.5714Z"
          fill="#F2F2F2"
        />
        <path
          d="M62.5331 72.3394L62.2837 72.3777C62.3617 72.8749 62.4232 73.383 62.4683 73.8857L62.7199 73.8638C62.6748 73.3546 62.6122 72.842 62.5331 72.3394Z"
          fill="#E6E6E6"
        />
        <path
          d="M61.8513 69.345L61.6096 69.4183C61.7557 69.9002 61.8865 70.3941 61.9975 70.8859L62.2447 70.83C62.1315 70.3328 61.9986 69.8334 61.8513 69.345Z"
          fill="#E6E6E6"
        />
        <path
          d="M60.7614 66.4739L60.5317 66.579C60.7427 67.0379 60.9405 67.5088 61.1196 67.9797L61.3558 67.891C61.1745 67.4146 60.9756 66.9371 60.7614 66.4739Z"
          fill="#E6E6E6"
        />
        <path
          d="M59.2896 63.7815L59.0764 63.9162C59.3478 64.3411 59.6082 64.7814 59.8499 65.2227L60.0718 65.1023C59.8279 64.6554 59.5642 64.2108 59.2896 63.7815Z"
          fill="#E6E6E6"
        />
        <path
          d="M57.4594 61.3145L57.2671 61.4776C57.5945 61.8609 57.9131 62.2618 58.2142 62.667L58.4163 62.5169C58.1131 62.1073 57.7912 61.7021 57.4594 61.3145Z"
          fill="#E6E6E6"
        />
        <path
          d="M55.308 59.1174L55.1399 59.3047C55.5179 59.6409 55.8881 59.9947 56.2408 60.355L56.4221 60.1786C56.065 59.814 55.6903 59.4569 55.308 59.1174Z"
          fill="#E6E6E6"
        />
        <path
          d="M52.8781 57.2314L52.7363 57.4395C53.156 57.721 53.5714 58.0211 53.9702 58.331L54.1262 58.1328C53.7219 57.8195 53.3022 57.5162 52.8781 57.2314Z"
          fill="#E6E6E6"
        />
        <path
          d="M50.6663 55.9216V56.2064C50.9289 56.3433 51.1892 56.4867 51.4441 56.6335L51.5705 56.4155C51.2738 56.2447 50.9724 56.0801 50.6663 55.9216Z"
          fill="#E6E6E6"
        />
        <path
          d="M62.5496 75.4391L62.8021 75.4346C62.8068 75.6888 62.8071 75.9443 62.8032 76.1942L62.5506 76.1902C62.5545 75.9432 62.5541 75.6905 62.5496 75.4391Z"
          fill="#E6E6E6"
        />
        <path
          d="M47.7008 93.9141L47.4514 93.9524C47.5294 94.4496 47.591 94.9577 47.636 95.4604L47.8876 95.4385C47.8426 94.9293 47.7799 94.4167 47.7008 93.9141Z"
          fill="#E6E6E6"
        />
        <path
          d="M47.0186 90.9199L46.7769 90.9933C46.923 91.4752 47.0537 91.9691 47.1647 92.4608L47.4119 92.405C47.2987 91.9078 47.1658 91.4084 47.0186 90.9199Z"
          fill="#E6E6E6"
        />
        <path
          d="M45.9291 88.0483L45.6995 88.1535C45.9104 88.6123 46.1082 89.0833 46.2873 89.5542L46.5235 89.4655C46.3422 88.9891 46.1433 88.5116 45.9291 88.0483Z"
          fill="#E6E6E6"
        />
        <path
          d="M44.4566 85.3562L44.2434 85.4909C44.5148 85.9158 44.7752 86.3561 45.0169 86.7974L45.2388 86.677C44.9949 86.2301 44.7312 85.7855 44.4566 85.3562Z"
          fill="#E6E6E6"
        />
        <path
          d="M42.6271 82.8889L42.4348 83.0521C42.7622 83.4354 43.0809 83.8362 43.3819 84.2414L43.5841 84.0914C43.2808 83.6818 42.9589 83.2766 42.6271 82.8889Z"
          fill="#E6E6E6"
        />
        <path
          d="M40.475 80.6921L40.3069 80.8794C40.6848 81.2156 41.0551 81.5694 41.4078 81.9297L41.5891 81.7533C41.232 81.3887 40.8573 81.0316 40.475 80.6921Z"
          fill="#E6E6E6"
        />
        <path
          d="M38.0453 78.8062L37.9036 79.0142C38.3233 79.2957 38.7386 79.5958 39.1374 79.9057L39.2934 79.7075C38.8891 79.3942 38.4694 79.0909 38.0453 78.8062Z"
          fill="#E6E6E6"
        />
        <path
          d="M35.8333 77.4963V77.7811C36.0958 77.918 36.3562 78.0614 36.6111 78.2082L36.7375 77.9903C36.4408 77.8194 36.1394 77.6548 35.8333 77.4963Z"
          fill="#E6E6E6"
        />
        <path
          d="M47.7168 97.0139L47.9694 97.0093C47.974 97.2635 47.9743 97.519 47.9704 97.7689L47.7179 97.765C47.7217 97.5179 47.7214 97.2652 47.7168 97.0139Z"
          fill="#E6E6E6"
        />
        <path
          d="M98.4611 83.072L98.2117 83.1104C98.2897 83.6076 98.3512 84.1157 98.3963 84.6184L98.6479 84.5965C98.6028 84.0872 98.5402 83.5747 98.4611 83.072Z"
          fill="#E6E6E6"
        />
        <path
          d="M97.7791 80.0776L97.5374 80.151C97.6835 80.6329 97.8142 81.1268 97.9252 81.6185L98.1724 81.5627C98.0592 81.0655 97.9263 80.5661 97.7791 80.0776Z"
          fill="#E6E6E6"
        />
        <path
          d="M96.6896 77.2063L96.46 77.3114C96.6709 77.7703 96.8687 78.2412 97.0478 78.7121L97.284 78.6234C97.1027 78.147 96.9038 77.6695 96.6896 77.2063Z"
          fill="#E6E6E6"
        />
        <path
          d="M95.2175 74.5142L95.0044 74.6489C95.2758 75.0738 95.5362 75.514 95.7779 75.9554L95.9998 75.8349C95.7559 75.3881 95.4922 74.9435 95.2175 74.5142Z"
          fill="#E6E6E6"
        />
        <path
          d="M93.3873 72.0469L93.1951 72.2101C93.5225 72.5934 93.8411 72.9942 94.1421 73.3994L94.3443 73.2494C94.041 72.8398 93.7191 72.4346 93.3873 72.0469Z"
          fill="#E6E6E6"
        />
        <path
          d="M91.2355 69.8501L91.0674 70.0374C91.4453 70.3736 91.8156 70.7273 92.1683 71.0876L92.3496 70.9113C91.9925 70.5466 91.6178 70.1896 91.2355 69.8501Z"
          fill="#E6E6E6"
        />
        <path
          d="M88.8058 67.9641L88.6641 68.1722C89.0838 68.4536 89.4991 68.7537 89.8979 69.0637L90.0539 68.8654C89.6496 68.5522 89.2299 68.2489 88.8058 67.9641Z"
          fill="#E6E6E6"
        />
        <path
          d="M86.5942 66.6543V66.939C86.8568 67.0759 87.1172 67.2194 87.3721 67.3661L87.4985 67.1482C87.2018 66.9774 86.9004 66.8127 86.5942 66.6543Z"
          fill="#E6E6E6"
        />
        <path
          d="M98.4775 86.1718L98.7301 86.1672C98.7347 86.4214 98.7351 86.677 98.7311 86.9268L98.4786 86.9229C98.4824 86.6759 98.4821 86.4232 98.4775 86.1718Z"
          fill="#E6E6E6"
        />
        <path
          d="M49.6397 73.6777H49.4534V73.4919H49.4171V73.6777H49.2307V73.7138H49.4171V73.8996H49.4534V73.7138H49.6397V73.6777Z"
          fill="#F2F2F2"
        />
        <path
          d="M47.4927 84.8874H47.3065V84.7017H47.2701V84.8874H47.0837V84.9236H47.2701V85.1093H47.3065V84.9236H47.4927V84.8874Z"
          fill="#F2F2F2"
        />
        <path
          d="M41.1919 94.7118H41.0056V94.5261H40.9692V94.7118H40.783V94.748H40.9692V94.9337H41.0056V94.748H41.1919V94.7118Z"
          fill="#F2F2F2"
        />
        <path
          d="M58.7167 82.2514C56.9155 79.5827 56.1334 76.359 56.5123 73.1648C56.8912 69.9707 58.4059 67.0176 60.7817 64.8412C63.1576 62.6647 66.2373 61.409 69.4623 61.3017C72.6873 61.1944 75.8442 62.2427 78.3603 64.2564C80.8765 66.2701 82.5854 69.1159 83.177 72.2778C83.7687 75.4397 83.204 78.7082 81.5854 81.4907C79.9667 84.2731 77.4013 86.3853 74.3543 87.4441C71.3074 88.5029 67.9806 88.4383 64.9773 87.2619C64.7826 87.9546 64.4186 88.5884 63.9179 89.1065C63.4172 89.6246 62.7954 90.0109 62.1082 90.2307C61.421 90.4506 60.6899 90.4971 59.9802 90.3662C59.2705 90.2353 58.6045 89.931 58.0417 89.4806C57.479 89.0303 57.037 88.4478 56.7555 87.7854C56.4739 87.123 56.3616 86.4014 56.4284 85.6851C56.4953 84.9688 56.7393 84.2802 57.1387 83.681C57.538 83.0817 58.0802 82.5906 58.7167 82.2514Z"
          fill="var(--primary-contrast-100-color, #0C0930)"
        />
        <path
          d="M69.8373 67.0566C72.9306 67.0566 75.0588 68.7669 75.0588 71.2253C75.0588 72.8533 74.2669 73.9797 72.7409 74.8759C71.3056 75.7064 70.8189 76.3148 70.8189 77.3672C70.8189 77.4525 70.8021 77.537 70.7693 77.6158C70.7366 77.6946 70.6886 77.7662 70.628 77.8265C70.5675 77.8869 70.4957 77.9347 70.4166 77.9673C70.3376 78 70.2528 78.0168 70.1672 78.0168H68.5859C68.417 78.0168 68.2547 77.9514 68.1332 77.8345C68.0117 77.7175 67.9405 77.5581 67.9346 77.3899L67.9318 77.3097C67.7916 75.6159 68.3855 74.5635 69.8785 73.6919C71.2726 72.8615 71.8583 72.3353 71.8583 71.3157C71.8583 70.2962 70.8684 69.548 69.6393 69.548C68.7051 69.548 67.9658 70.0012 67.6267 70.7412C67.5076 70.9917 67.3189 71.2029 67.0831 71.3499C66.8472 71.4969 66.5741 71.5735 66.296 71.5706C65.2245 71.5706 64.5167 70.4349 65.0178 69.4909C65.8104 67.9975 67.4829 67.0566 69.8373 67.0566ZM67.6678 81.1494C67.6678 80.2039 68.4597 79.4557 69.4331 79.4557C70.4147 79.4557 71.2066 80.1957 71.2066 81.1494C71.2066 82.1032 70.4147 82.8432 69.4331 82.8432C68.4515 82.8432 67.6678 82.1032 67.6678 81.1494Z"
          fill="var(--primary-100-color, #8854f7)"
        />
      </g>
      {/* <defs>
           <clipPath id="clip0">
             <rect
               width="121.349"
               height="87.3406"
               fill="white"
               transform="translate(33.2358 51.7861)"
             />
           </clipPath>
          </defs> */}
    </svg>
  )
}

export default NotFoundIllustration
