import React from "react"

import MatrixStore from "./state/MatrixStore"

export const MatrixContext = React.createContext<MatrixStore | null>(null)

export const useMatrixContext = () => {
  const context = React.useContext(MatrixContext)
  if (context == null) throw new Error("Can not find MatrixContext")
  return context
}
