import React, { useCallback, useEffect, useState } from "react"
import clsx from "clsx"
import ReactInputMask from "react-input-mask"
import moment from "moment"

import {
  canParseDateStr,
  DATE_MASK,
  DIVIDER,
  FULL_DATE_FORMAT,
  parseDateStr,
  parsePeriodStr,
  validateDate,
  validatePeriod,
  normalizeDateRange,
} from "./utils"
import { Icon } from "../Icon/Icon"
import Calendar from "./Calendar"
import Chip from "../Chip/Chip"

import styles from "./DatePicker.module.sass"

export type DateRangePresetOption<T extends Date | [Date, Date]> = {
  name: string
  value: () => T
}

const datesToString = (value: Date | Date[]) =>
  !Array.isArray(value)
    ? moment(value).format(FULL_DATE_FORMAT)
    : value?.map((curr) => moment(curr).format(FULL_DATE_FORMAT)).join(DIVIDER)

export interface MultiDatePickerType {
  multiSelect?: true
  defValue?: Date[]
  presets?: DateRangePresetOption<[Date, Date]>[] | null
  onChange?: (date?: Date[]) => void
}

export interface SingleDatePickerType {
  multiSelect?: false
  defValue?: Date
  presets?: DateRangePresetOption<Date>[] | null
  onChange?: (date?: Date) => void
}

export type DatePickerProps = {
  multiSelect?: boolean
  minDate?: Date
  maxDate?: Date
  defaultActiveStartDate?: Date
  placeholder?: string
  className?: string
  onChange?: (date?: Date | Date[]) => void
  onBlur?: (e: any) => void
} & (MultiDatePickerType | SingleDatePickerType)

export const DatePickerCard: React.FC<DatePickerProps> = ({
  multiSelect = true,
  defValue,
  minDate,
  maxDate,
  placeholder,
  className,
  defaultActiveStartDate,
  presets,
  onChange,
  onBlur,
}) => {
  const [isFocused, setFocused] = useState(false)
  const [date, setDate] = useState<typeof defValue>()
  const [value, setValue] = useState<string>("")

  const isDateValidator = (date: Date | Date[]) =>
    multiSelect ? validatePeriod(date as Date[]) : validateDate(date as Date)

  const parser: (date: string) => Date | Date[] = multiSelect
    ? parsePeriodStr
    : parseDateStr

  const mask = multiSelect
    ? `${DATE_MASK}${DIVIDER}${DATE_MASK}`
    : `${DATE_MASK}`

  const isTouched = !!value
  const isValid = canParseDateStr(value) && date ? isDateValidator(date) : false

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setValue(value)
    if (!value || !canParseDateStr(value)) {
      if (defValue !== undefined && onChange) onChange(undefined)
      return
    }
    const newDate = parser(value)
    if (isDateValidator(newDate)) {
      setDate(newDate)
      onChange?.(newDate)
    } else {
      setDate(undefined)
      onChange?.(undefined)
    }
  }

  const handleBlur = (e: any) => {
    setFocused(false)
    onBlur?.(e)
    if (!value || !canParseDateStr(value)) return
    if (isDateValidator(parser(value)) && date) {
      const normalized = normalizeDateRange(date as Date[])
      setValue(datesToString(normalized))
      onChange?.(normalized)
      setDate(normalized)
    }
  }

  const handlePick = useCallback(
    (value: Date | Date[]) => {
      const dateStr = datesToString(value)
      setValue(dateStr)
      setDate(value)
      onChange?.(value)
    },
    [onChange]
  )

  useEffect(() => {
    if (!defValue) {
      if (!isFocused) {
        setDate(defValue)
        setValue("")
      }
      return
    }
    if (datesToString(defValue) !== value) {
      setDate(defValue)
      setValue(datesToString(defValue))
    }
  }, [defValue])

  return (
    <div className={clsx(styles.root, className)}>
      <Icon className={styles.calendarIcon} name="calendar" />

      <ReactInputMask
        placeholder={placeholder}
        type="text"
        mask={mask}
        value={value}
        className={clsx(styles.input, {
          [styles.invalid]: !isFocused && !isValid && isTouched,
        })}
        onChange={handleInputChange}
        onFocus={() => setFocused(true)}
        onBlur={handleBlur}
      />

      <div className={styles.body}>
        <div className={styles.calendarContainer}>
          <Calendar
            className={styles.calendar}
            minDate={minDate}
            maxDate={maxDate}
            selectRange={multiSelect}
            onChange={handlePick}
            defaultActiveStartDate={defaultActiveStartDate}
            value={date as Date} // FIXME
          />
        </div>

        {!!presets?.length && (
          <div className={styles.presetContainer}>
            {presets.map((preset) => (
              <Chip
                color="secondary-solid"
                variant="rounded"
                onClick={() => {
                  handlePick(preset.value())
                }}
              >
                {preset.name}
              </Chip>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default DatePickerCard
