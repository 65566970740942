import React from "react"
import { makeAutoObservable } from "mobx"

type CheckPoint = {
  name: string
  link: string
  render?: (label: string) => React.ReactNode
}
export class BreadCrumpsStore {
  constructor(root: CheckPoint) {
    this.root = root
    makeAutoObservable(this)
  }

  root: CheckPoint

  checkpoints: CheckPoint[] = []

  get records() {
    return new Map(this.checkpoints.map((it) => [it.name, it]))
  }

  get getRenderCallbackById() {
    const { records } = this
    return (name: string) => records.get(name)?.render
  }

  getCheckpoint = (index: number) => {
    if (index < this.checkpoints.length && index >= 0)
      return this.checkpoints[index]
    return this.root
  }

  setCheckpoint = (level: number, data: CheckPoint) => {
    this.checkpoints = [...this.checkpoints]
    this.checkpoints[level] = data
  }

  unsetCheckpoint = (level: number) => {
    this.checkpoints = this.checkpoints.filter((_, idx) => idx < level)
  }

  resetCheckpoint = () => {
    this.checkpoints = []
  }
}

export const BreadCrumpsContext = React.createContext<{
  level: number
  store: BreadCrumpsStore
} | null>(null)
