import React from "react"
import { useDropzone } from "react-dropzone"
import clsx from "clsx"

import { pluralize } from "@utils/textUtils"
import Text from "@components/ui/Typography/Text"
import { IconName } from "@components/ui/Icon/IconName"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { DocumentIconType } from "@framework/types/utils"

import styles from "./DocumentDropZone.module.sass"

export interface DocumentDropZoneProps {
  className?: string
  disabled?: boolean
  multiple?: boolean
  extensions?: string[]
  description?: React.ReactNode
  unit?: string
  previewImage?: string
  loading?: boolean
  icon?: IconName | DocumentIconType
  alternativeTitle?: React.ReactNode
  withError?: boolean
  onDrop?: (files: File[]) => void
}

export const DocumentDropZone: React.FC<DocumentDropZoneProps> = ({
  className,
  multiple = true,
  disabled = false,
  unit = "file",
  icon = "img:folder_full",
  previewImage,
  extensions,
  alternativeTitle,
  description,
  withError = false,
  onDrop,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    disabled,
    accept: extensions,
  })

  const containerClasses = clsx(
    styles.root,
    isDragActive ? styles.active : "",
    { [styles.disabled]: disabled },
    { [styles.withError]: withError },
    className
  )

  return (
    <div {...getRootProps()} className={containerClasses}>
      {previewImage != null ? (
        <img className={styles.illustration} src={previewImage} alt="" />
      ) : (
        <div className={styles.title}>
          {!alternativeTitle ? (
            <>
              <DocumentIcon
                className={styles.icon}
                icon={icon}
                color="primary"
                height={50}
                width={50}
              />

              <Text weight="bold" align="center">
                Drag and drop your {pluralize(unit, multiple)} here
              </Text>
            </>
          ) : (
            <>{alternativeTitle}</>
          )}

          {description != null && (
            <Text
              variant="inherit"
              color="text70Color"
              align="center"
              className={styles.description}
            >
              {description}
            </Text>
          )}
        </div>
      )}

      <input {...getInputProps()} />
    </div>
  )
}

export default DocumentDropZone
