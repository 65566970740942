import React from "react"
import { observer } from "mobx-react-lite"
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom"
import keyBy from "lodash/keyBy"
import get from "lodash/get"

import useSearch from "@components/hooks/useSearch"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import BackButton from "@components/prototypes/BackButton"
import Button from "@components/ui/Button/Button"
import Container from "@components/ui/Container/Container"
import Icon from "@components/ui/Icon/Icon"
import Switch from "@components/ui/Switch/Switch"
import TextInput from "@components/ui/TextInput/TextInput"
import { useController, useStore } from "@store/index"
import { Option } from "@framework/types/utils"
import List from "@components/ui/List/List"

import ActiveUser from "./ActiveUser"
import UserRequests from "./UserRequests"
import UserGroups from "./UserGroups"

import styles from "./Users.module.sass"

const getTabLabel = (tabs: Option[], tabName: string) =>
  tabs.find((it) => it.name === tabName)?.value

export interface UsersProps {}

export const UsersPage: React.FC<UsersProps> = observer(() => {
  const navigate = useNavigate()

  const { rolesController } = useController()
  const { rolesStore, restrictionsStore: access } = useStore()

  const inviteModal = useModal(ModalsTypes.INVITE_USER_MODAL)
  const editGroupModal = useModal(ModalsTypes.EDIT_USER_GROUP_MODAL)

  const { mode } = useParams<{ mode?: string }>()

  const [searchProps, searchContext] = useSearch()

  const handleCreateGroup = () => editGroupModal.showModal({})

  const handleInvite = () => inviteModal.showModal({})

  const tabs: Option[] = React.useMemo(() => {
    const res: Option[] = []

    res.push({ value: "Active Users", name: "all" })

    if (access.isUserGroupsAvailable)
      res.push({
        value: "User Groups",
        name: "groups",
      })

    res.push({
      value: "User Requests",
      name: "requests",
    })

    return res
  }, [access.isUserGroupsAvailable])

  React.useEffect(() => {
    const { roleIds, error, isLoading } = rolesStore
    if (!roleIds.length && !error && !isLoading) rolesController.init()
  }, [])

  React.useEffect(() => {
    if (tabs.some((it) => it.name === mode)) return
    navigate(`/admin/users/${tabs[0].name}`)
  }, [mode])

  const handleSwitchMode = (name: string) => navigate(`/admin/users/${name}`)

  return (
    <MainLayout noPadding>
      <Container>
        <div className={styles.root}>
          <EntityPageHeaderLayout
            className={styles.header}
            left={
              <BackButton onClick={() => navigate("/admin")}>
                {getTabLabel(tabs, mode ?? "all") ?? "Users"}
              </BackButton>
            }
            right={
              <List
                direction="row"
                gutter="16"
                overflow="initial"
                justify="flex-end"
              >
                {access.canCreateUserGroups && (
                  <Button
                    className={styles.inviteButton}
                    color="primary"
                    size="big"
                    onClick={handleCreateGroup}
                    before={<Icon name="user-add" />}
                  >
                    Create Group
                  </Button>
                )}

                {access.canInviteUsers && (
                  <Button
                    className={styles.inviteButton}
                    color="primary"
                    size="big"
                    onClick={handleInvite}
                    before={<Icon name="user-add" />}
                  >
                    Invite User
                  </Button>
                )}
              </List>
            }
          >
            <TextInput
              {...searchProps}
              placeholder="Search..."
              before={<Icon name="search" />}
              after={
                !!searchProps.value && (
                  <Icon
                    name="cross"
                    onClick={() => searchContext.setQuery("")}
                  />
                )
              }
            />
          </EntityPageHeaderLayout>

          <div className={styles.switchContainer}>
            <Switch items={tabs} checked={mode} onChange={handleSwitchMode} />
          </div>

          {mode === "all" && <ActiveUser query={searchProps.value} />}

          {mode === "groups" && <UserGroups query={searchProps.value} />}

          {mode === "requests" && <UserRequests query={searchProps.value} />}
        </div>
      </Container>
    </MainLayout>
  )
})

export const UsersRoot: React.FC = observer(() => (
  <Routes>
    <Route index element={<Navigate to="./all" />} />

    <Route path="/:mode/*" element={<UsersPage />} />

    <Route
      path="/requests/:requestId"
      element={<Navigate to="../requests" />}
    />
  </Routes>
))

export default UsersRoot
