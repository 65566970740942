import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Skeleton from "@components/ui/Skeleton/Skeleton"
import Text from "@components/ui/Typography/Text"
import { useController, useStore } from "@store/index"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import styles from "./SuggestedQuestions.module.sass"

type SuggestedQuestionsProps = {
  className?: string
}

const SuggestedQuestions: React.FC<SuggestedQuestionsProps> = observer(
  ({ className }) => {
    const {
      searchSuggestionStore: { isQuestionsLoading },
      solutionsStore: { solution, appliedFilters },
    } = useStore()
    const { avatar } = useActiveAvatar()
    const isMobile = useMediaQuery(AppMediaQueries.maxMobile)

    const { factFinderSolutionController } = useController()

    const handleClick = (query: string) => {
      if (solution?.id)
        factFinderSolutionController.search(
          query,
          avatar,
          solution.id,
          appliedFilters
        )
    }

    const getQuestions = () => (
      <div className={styles.questions}>
        {isQuestionsLoading && (
          <Skeleton
            count={5}
            maxWidth={1000}
            lineHeight={24}
            spacing={16}
            rounded
            className={styles.skeletons}
          />
        )}
        {solution?.suggestedQuestions?.map((question) => (
          <div
            key={question}
            className={styles.question}
            onClick={() => handleClick(question)}
            tabIndex={0}
            role="button"
            onKeyPress={() => {}}
          >
            <Text
              variant="h6"
              className={styles.questionOverflow}
              color="text70Color"
            >
              {question}
            </Text>
          </div>
        ))}
      </div>
    )

    return (
      <div className={clsx(styles.root, className)}>
        <div className={styles.questionHeader}>
          <Text variant="h3" weight="bold" align="start">
            Sample Questions
          </Text>
        </div>
        {isMobile ? (
          <div className={styles.questionsWrapper}>{getQuestions()}</div>
        ) : (
          getQuestions()
        )}
      </div>
    )
  }
)

export default SuggestedQuestions
