import React from "react"

import Chip from "@components/ui/Chip/Chip"

import styles from "../EntityCard.module.sass"

export interface ChipListProps {
  channels: string[]
}

export const ChipList: React.FC<ChipListProps> = ({ channels }) => (
  <div className={styles.list}>
    {channels?.map((avatar) => (
      <Chip color="secondary" variant="rounded" key={avatar}>
        {avatar}
      </Chip>
    ))}
  </div>
)

export default ChipList
