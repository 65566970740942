import React from "react"

import { APICallsHistoryData } from "@framework/types/analytics"
import Table from "@components/ui/BaseTable/Table"

import APICallRow from "./APICallRow"
import APICallHeader from "./APICallsHeader"

import styles from "./index.module.sass"

interface APICallsHistoryTableProps {
  rows: APICallsHistoryData[]
}

const APICallsHistoryTable: React.FC<APICallsHistoryTableProps> = React.memo(
  ({ rows }) => {
    return (
      <Table header={<APICallHeader />} className={styles.root}>
        {rows.map((data) => {
          return <APICallRow data={data} key={data.id} />
        })}
      </Table>
    )
  }
)

export default APICallsHistoryTable
