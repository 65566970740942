import React from "react"

import { DocumentIconType } from "@framework/types/utils"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { IconName } from "@components/ui/Icon/IconName"

import styles from "./StatisticCard.module.sass"

export interface StatisticCardProps {
  label?: string
  icon?: DocumentIconType | IconName
  value?: number
}

export const StatisticCard: React.FC<StatisticCardProps> = ({
  label = "",
  icon,
  value,
}) => (
  <div className={styles.root}>
    <DocumentIcon icon={icon ?? "global"} />
    <div className={styles.label}>{label}</div>
    <div className={styles.value}>{value}</div>
  </div>
)

export default StatisticCard
