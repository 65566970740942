import React, { useEffect } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import mainRoutes from "@root/main.routes"
import { useAppConfig } from "@components/theme/AppConfigProvider"
import { useStore } from "@store"

import IEEEFooter from "./IEEEFooter"
import CircleFragment from "./CircleFragment"
import NoodlesFragment from "./NoodlesFragment"

import styles from "./IntroLayout.module.sass"

export interface IntroLayoutProps {
  className?: string
}

const IntroLayout: React.FC<IntroLayoutProps> = observer(
  ({ className, children }) => {
    const navigate = useNavigate()
    const theme = useAppConfig()

    const { authStore } = useStore()

    useEffect(() => {
      if (!authStore.isAuthorized) return

      if (authStore.restrictedLocation != null) {
        const { pathname } = authStore.restrictedLocation
        navigate(pathname, { replace: true, state: { reason: "LOGIN" } })
        authStore.setRestrictedLocation(null)
        return
      }

      navigate(mainRoutes.home(), {
        replace: true,
        state: { reason: "LOGIN" },
      })
    }, [authStore.isAuthorized])

    return (
      <div className={clsx(styles.root, className)}>
        <div className={styles.body}>
          <CircleFragment className={styles.topLeftFragment} />
          <NoodlesFragment className={styles.bottomRightFragment} />

          <div className={styles.formContainer}>{children}</div>
        </div>

        {theme.copy?.footer != null && (
          <>
            {theme.copy?.footer.type === "IEEE" && (
              <IEEEFooter data={theme.copy?.footer} />
            )}
          </>
        )}
      </div>
    )
  }
)

export default IntroLayout
