import React from "react"
import { Link } from "react-router-dom"

import mainRoutes from "@root/main.routes"
import IntroLayout from "@components/layout/IntroLayout/IntroLayout"
import Text from "@components/ui/Typography/Text"
import Logo from "@components/prototypes/Logo/Logo"
import { useAppConfig } from "@components/theme/AppConfigProvider"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import SignUpForm from "./components/SignUpForm/SignUpForm"

import styles from "./SignIn.module.sass"

export interface SignUpPageProps {}

const SignUpPage: React.FC<SignUpPageProps> = () => {
  const theme = useAppConfig()

  const isTablet = useMediaQuery(AppMediaQueries.maxTablet)

  return (
    <IntroLayout>
      <div className={styles.root}>
        <div className={styles.logo}>
          <Logo
            name={theme.copy.logo}
            height={isTablet ? 36 : 48}
            withText
            primary
          />
        </div>

        <div className={styles.header}>
          <Text variant={isTablet ? "h1" : "title"} align="center">
            Create an account
          </Text>

          <Text variant={isTablet ? "h6" : "h4"}>
            Already have an account?&nbsp;
            <Link to={mainRoutes.login()}>Sign In</Link>
          </Text>
        </div>

        <div className={styles.body}>
          <SignUpForm />
        </div>

        <Text variant="h6" align="center" className={styles.footer}>
          By signing in or creating an account, you agree with our&nbsp;
          <Link to="/terms">Terms & Conditions</Link> and&nbsp;
          <Link to="/privacy">Privacy Statement</Link>
        </Text>
      </div>
    </IntroLayout>
  )
}

export default SignUpPage
