import React from "react"
import { useFormik } from "formik"
import { observer } from "mobx-react-lite"
import * as yup from "yup"
import { useAlert } from "react-alert"

import Button from "@components/ui/Button/Button"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import { TextInput, TextInputProps } from "@components/ui/TextInput/TextInput"
import { SignUpUserRequestFormType } from "@framework/types/auth"
import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/BarLoader"
import Text from "@components/ui/Typography/Text"
import List from "@components/ui/List/List"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import styles from "./SignUpFormUserRequest.module.sass"

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Must be a valid email"),
})

export interface SignUpFormProps {
  className?: string
}

const SignUpForm: React.FC<SignUpFormProps> = observer(() => {
  const isTablet = useMediaQuery(AppMediaQueries.maxTablet)

  const {
    userRequestStore: { signUp, isUserRequestsCollectionLoading: isLoading },
  } = useStore()

  const alert = useAlert()

  const onSubmit = async (formData: SignUpUserRequestFormType) => {
    const error = await signUp(formData)

    if (error) {
      alert.error(
        <List gutter="4">
          <Text variant="inherit">Request was failed</Text>
          <Text variant="caption1" color="text70Color">
            {error}
          </Text>
        </List>
      )
      return
    }
    alert.success(<>Request was successfully sent</>)
  }

  const formik = useFormik<SignUpUserRequestFormType>({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit,
  })

  const getProps = (
    name: keyof SignUpUserRequestFormType,
    after: React.ReactNode = null
  ): TextInputProps => {
    const withError = !!(formik.touched[name] && formik.errors[name])
    return {
      name,
      value: formik.values[name].toString(),
      withError,
      after: withError && (
        <div className={styles.after}>
          <ErrorChip
            message={formik.errors[name] ?? ""}
            messagePlacement="left"
          />
          {after}
        </div>
      ),
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
    }
  }

  return (
    <form className={styles.root} onSubmit={formik.handleSubmit}>
      <TextInput placeholder="Email" {...getProps("email")} />

      <Button
        size={isTablet ? "big" : "large"}
        type="submit"
        color="primary"
        disabled={isLoading}
        after={isLoading && <Loader />}
      >
        Request to Sign Up
      </Button>
    </form>
  )
})

export default SignUpForm
