import clsx from "clsx"
import React from "react"
import { observer } from "mobx-react-lite"
import toString from "lodash/toString"

import useTooltip from "@components/hooks/useTooltip"
import useResizeObserver from "@components/hooks/useResizeObserver"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import { useModalContext } from "@components/modals/ModalContext"

import { useMatrixContext } from "./MatrixContext"
import { stringifyPoint, includes, isValidHttpUrl, isFilename } from "./utils"
import Icon from "../Icon/Icon"
import MagicTextLoader from "../Loader/MagicTextLoader"
import useContextMenu from "../ContextMenu/useContextMenu"
import Popper from "../Dropdown/Popper"
import CellLinkTooltip from "./Tooltips/CellLinkTooltip"
import ErrorTooltip from "./Tooltips/ErrorTooltip"
import FileChip from "./FileChip"
import { CellFormat } from "./types"
import { DEFAULT_ALIGN, DEFAULT_TEXT_SIZE } from "./constants"
import CellAllContentTooltip from "./Tooltips/AllContentTooltip"
import CommentTooltip from "./Tooltips/CommentTooltip"

import styles from "./Cell.module.sass"

type CellProps = {
  columnIndex: number
  rowIndex: number
  height?: React.CSSProperties["height"]
  width?: React.CSSProperties["width"]
}

const Cell: React.FC<CellProps> = observer(
  ({ columnIndex, rowIndex, height, width }) => {
    const isMobile = useMediaQuery(AppMediaQueries.maxMobile)
    const anyModal = useModalContext()
    const contextMenu = useContextMenu()

    const context = useMatrixContext()

    const {
      editManager,
      selectedRange,
      spreadRange,
      contextMenuManager,
      updateSpreading,
    } = context

    const [popupNode, setPopupNode] = React.useState<HTMLElement | null>(null)
    const [rootNode, setRootNode] = React.useState<HTMLElement | null>(null)
    const contentRef = React.useRef<HTMLDivElement | null>(null)

    const currentCellPoint = { x: columnIndex, y: rowIndex }

    const handleMouseDown = (e: React.MouseEvent) => {
      if (
        editManager.isEditing &&
        editManager.activeCellState?.isRefSelectionAvailable()
      ) {
        editManager.startRefSelecting(currentCellPoint)
        e.preventDefault()
        e.stopPropagation()
        return
      }

      editManager.submitCell()

      if (e.button === 2) {
        if (!includes(selectedRange.range, currentCellPoint)) {
          selectedRange.startRange(currentCellPoint)
        }
        e.stopPropagation()
      }

      selectedRange.startRange(currentCellPoint)
    }

    const handleTouchStart = (e: React.TouchEvent) => {
      if (
        editManager.isEditing &&
        editManager.activeCellState?.isRefSelectionAvailable()
      ) {
        editManager.startRefSelecting(currentCellPoint)
        e.preventDefault()
        e.stopPropagation()
        return
      }

      editManager.submitCell()

      selectedRange.startRange(currentCellPoint)

      e.preventDefault()
    }

    const handleMouseEnter = (e: React.MouseEvent) => {
      if (selectedRange.rangeStarted) {
        selectedRange.updateRange(currentCellPoint)
      }
      if (spreadRange?.rangeStarted) {
        updateSpreading(currentCellPoint)
      }
      if (editManager.refSelectingRange) {
        editManager.updateRefSelecting(currentCellPoint)
      }
    }

    const handleMouseDoubleClick = (e: React.MouseEvent) => {
      editManager.editCell(currentCellPoint)
    }

    const cell = editManager.findCell(currentCellPoint)

    const content = cell?.value == null ? "" : toString(cell.value)

    const comment = cell?.state.comment

    const isFailed = !!cell?.state.error
    const isLink = !isFailed && isValidHttpUrl(content)

    const rect = useResizeObserver(contentRef, !content)

    const isOverflowed =
      rect != null &&
      (rect.height > Number(height) || rect.width > Number(width))

    const withTooltip = isLink || isOverflowed || isFailed || comment != null

    const tooltip = useTooltip(rootNode, popupNode, {
      placement: "bottom-start",
      disabled: !withTooltip || anyModal.isOpen || editManager.isEditing,
      delay: isMobile ? 100 : 400,
    })

    const cellFormatting = useCellFormat(cell?.format)

    const withAutocomplete =
      cell?.validationRule?.type === "OPTION" ||
      cell?.validationRule?.type === "AUTOCOMPLETE"

    const renderValue = () => {
      if (isFilename(content)) return <FileChip name={content} />

      return <>{content}</>
    }

    return (
      <>
        <div
          ref={setRootNode}
          className={clsx(
            styles.root,
            {
              [styles.withError]: isFailed,
              [styles.underlined]: isLink,
            },
            cellFormatting.classNames
          )}
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
          onMouseEnter={handleMouseEnter}
          onDoubleClick={handleMouseDoubleClick}
          onContextMenu={(e) => {
            if (e.shiftKey) return
            const menu = contextMenuManager.getContextMenu()
            if (menu) contextMenu.handleContextMenu(e, menu)
          }}
          aria-label={stringifyPoint(currentCellPoint)}
          role="button"
          tabIndex={-1}
          style={cellFormatting.style}
        >
          {cell != null && (
            <>
              <div className={clsx(styles.content)} ref={contentRef}>
                {cell.isLoading ? (
                  <MagicTextLoader>Processing...</MagicTextLoader>
                ) : (
                  renderValue()
                )}
              </div>

              {withAutocomplete && (
                <button
                  type="button"
                  className={clsx(styles.arrow)}
                  onClick={(e) => {
                    editManager.editCell(currentCellPoint)
                    e.stopPropagation()
                  }}
                >
                  <Icon name="arrow-down" />
                </button>
              )}
            </>
          )}

          {(comment != null || isFailed) && (
            <span className={styles.status}>
              {comment != null && <Icon name="info" className={styles.info} />}

              {/* <Icon name="bell" className={styles.notification} /> */}

              {isFailed && <Icon name="alert" className={styles.error} />}
            </span>
          )}
        </div>

        <Popper
          ref={setPopupNode}
          style={tooltip.style}
          isActive={tooltip.isActive}
        >
          {isLink && <CellLinkTooltip url={content} />}

          {isOverflowed && (
            <CellAllContentTooltip cell={currentCellPoint}>
              {content}
            </CellAllContentTooltip>
          )}

          {cell?.state.error && <ErrorTooltip error={cell?.state.error} />}

          {comment != null && cell != null && (
            <CommentTooltip
              point={currentCellPoint}
              cell={cell}
              comment={comment}
            />
          )}
        </Popper>
      </>
    )
  }
)

export default Cell

const useCellFormat = (format: Partial<CellFormat> = {}) => {
  const vAlign = format?.vAlignment ?? DEFAULT_ALIGN
  const hAlign = format?.hAlignment ?? DEFAULT_ALIGN
  const textSize = format?.textSize ?? DEFAULT_TEXT_SIZE
  const textDecoration = format?.textDecoration
  const backgroundColor = format?.backgroundColor
  const textColor = format?.textColor

  return {
    classNames: clsx(
      styles[`v-align-${vAlign}`],
      styles[`h-align-${hAlign}`],
      styles[`text-${textSize}`],
      ...(textDecoration?.map((it) => styles[`text-${it}`]) ?? [])
    ),
    style: { backgroundColor, color: textColor },
  }
}
