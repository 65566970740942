import React from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"
import get from "lodash/get"
import { TooltipItem } from "chart.js"

import List from "@components/ui/List/List"
import Text from "@components/ui/Typography/Text"
import { useAppColors } from "@components/theme/AppColorSchemeProvider"
import Icon from "@components/ui/Icon/Icon"
import { DD_MM_YYYY_FORMAT } from "@framework/constants/global"
import Loader from "@components/ui/Loader/BarLoader"
import AreaChart from "@components/ui/AreaChart/AreaChart"
import { options } from "@components/ui/AreaChart/config"
import { renderDatePeriodTupleToRange, renderDuration } from "@utils/date"
import NotFound from "@components/ui/NotFound/NotFound"
import { useStore } from "@store"

import { useDateRangeFilterContext } from "../DateRangeFilterContext"
import {
  ChartFractionMetaData,
  useSearchingTrendChartData,
} from "./useSearchingTrendChart"

import styles from "./SearchingTrendChart.module.sass"

export const SearchingTrendChart: React.FC = observer(() => {
  const theme = useAppColors()

  const {
    usageAnalyticsStore: { searchingTrendsStore: store },
  } = useStore()

  const { palette } = theme

  const { dateRange } = useDateRangeFilterContext()

  const period = React.useMemo(
    () => renderDatePeriodTupleToRange(dateRange, DD_MM_YYYY_FORMAT),
    [dateRange]
  )

  const whiteColor = get(palette, "main-text-100-color", "white")
  const primaryColor = get(palette, "primary-100-color", "purple")
  const goldColor = get(palette, "gold-100-color", "gold")
  const gridColor = get(palette, "secondary-80-color", "#ffffff26")

  const chartFractions: ChartFractionMetaData[] = React.useMemo(
    () => [
      {
        label: "Total Queries",
        color: whiteColor,
        name: "totalQuestions",
        yAxisID: "y1",
      },
      {
        label: "Expert Question",
        color: primaryColor,
        name: "totalExpertQuestions",
        yAxisID: "y1",
      },
      {
        label: "Average Rating",
        color: goldColor,
        name: "averageRating",
        yAxisID: "y2",
      },
    ],
    [whiteColor, primaryColor, goldColor]
  )

  const handleLoadData = useSearchingTrendChartData({
    startDate: period.start,
    endDate: period.end,
    data: store.data,
    fractions: chartFractions,
  })

  const config = React.useMemo(() => {
    return options({
      showAxises: true,
      axisColor: whiteColor,
      gridColor,
      dateFormat: DD_MM_YYYY_FORMAT,
      y1: {
        display: true,
        suggestedMinY: 0,
        suggestedMaxY: 10,
      },
      y2: {
        display: true,
        suggestedMinY: 0,
        suggestedMaxY: 5,
      },
      callbacks: {
        tooltipLabel: renderLabel,
      },
    })
  }, [whiteColor, gridColor])

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <List
          direction="row"
          justify="flex-start"
          overflow="initial"
          gutter="8"
        >
          Queries
          <Icon name="questions-chat" />
        </List>

        <List direction="row" justify="center" overflow="initial" gutter="24">
          {chartFractions.map((it) => (
            <Text
              className={styles.legend}
              style={
                {
                  "--marker-color": it.color,
                } as React.CSSProperties
              }
              variant="caption2"
              key={it.label}
            >
              {it.label}
            </Text>
          ))}
        </List>

        <List direction="row" justify="flex-end" overflow="initial" gutter="8">
          <Icon name="star-filled" color="gold" />
          Rating
        </List>
      </div>

      <div className={styles.body}>
        {store.data.length ? (
          <AreaChart onLoadData={handleLoadData} config={config} />
        ) : store.isLoading ? (
          <Loader size="large" fluid />
        ) : (
          <NotFound>No Data found</NotFound>
        )}
      </div>
    </div>
  )
})

export default SearchingTrendChart

export const renderLabel = (item: TooltipItem<"line">) => {
  if (item.dataset.label === "Time Saved")
    return ` ${renderDuration(Number(item.raw) * 60_000, "s") || "0"} Saved`

  return ` ${String(item.raw)} ${item.dataset.label}`
}
