import React from "react"
import { observer } from "mobx-react-lite"

import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import { DataSourceStatus, DataSourceNodeName } from "@framework/types/upload"
import BadgeWrapper, {
  SpinnerBadge,
} from "@components/ui/Loader/BadgeWrapper/BadgeWrapper"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import LinkCard from "@components/ui/LinkCard/LinkCard"

const defaultDescriptionRenderCallback = (data?: DataSourceStatus) =>
  `${data?.connected ?? 0} Connected | ${data?.loading ?? 0} Loading`

interface DataConnectorCardProps {
  id: DataSourceNodeName
  link: string
  data?: DataSourceStatus
  renderDescription?: (data?: DataSourceStatus) => React.ReactNode
}

export const DataConnectorLinkCard: React.FC<DataConnectorCardProps> = observer(
  ({
    id,
    link,
    data,
    renderDescription = defaultDescriptionRenderCallback,
  }) => {
    const context = getDataSourceNodeContextProps(id)
    return (
      <LinkCard
        to={link}
        disabled={context.name === "unknown"}
        label={context.label}
        description={context.description ?? renderDescription(data)}
        error={data?.error}
        icon={
          <BadgeWrapper mark={!!data?.processing && <SpinnerBadge />}>
            <DocumentIcon icon={context.icon} />
          </BadgeWrapper>
        }
      />
    )
  }
)

export default DataConnectorLinkCard
