import { AxiosProgressEvent, AxiosResponse } from "axios"

import {
  CreateContentSourceParams,
  CreateContentSourceResponse,
  DocumentsListResponse,
  GetConnectorDocumentsParams,
  GetKibanaConnectorAccountsResponse,
  GetSyncJobsResponse,
  KibanaConnectorInfo,
  UpdateDocumentContentTypeParams,
} from "@framework/types/content-manager"

import HttpService from "./http.service"

class ContentManagerAPI extends HttpService {
  getAvailableContentSources = (
    type: string
  ): Promise<AxiosResponse<GetKibanaConnectorAccountsResponse>> =>
    this.get("ts/kibana-connectors", true, { type, pageSize: 1000, pageNum: 1 })

  getKibanaConnectorsCount = (): Promise<
    AxiosResponse<KibanaConnectorInfo[]>
  > => this.get(`ts/kibana-connectors/counts`, true)

  createContentSource = (
    data: CreateContentSourceParams
  ): Promise<AxiosResponse<CreateContentSourceResponse>> =>
    this.post("ts/kibana-connectors", data, true)

  getDocumentsList = (
    params: GetConnectorDocumentsParams
  ): Promise<AxiosResponse<DocumentsListResponse>> =>
    this.get("ts/kibana-connectors/documents", true, params)

  getSyncJobs = (): Promise<AxiosResponse<GetSyncJobsResponse>> =>
    this.get("ts/kibana-connectors/sync-jobs", true, {
      pageSize: 10,
      pageNum: 1,
      status: "in_progress",
    })

  uploadDocument = (
    file: File,
    contentType?: string,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  ): Promise<AxiosResponse<any>> => {
    const formData = new FormData()
    formData.append("file", file)
    if (contentType) formData.append("contentType", contentType)

    return this.post("ts/kibana-connectors/documents", formData, true, {
      headers: {
        "Content-Type": "application/form-data",
      },
      onUploadProgress,
    })
  }

  updateContentTypeForDocument = (
    params: UpdateDocumentContentTypeParams
  ): Promise<AxiosResponse<DocumentsListResponse>> =>
    this.patch(
      `ts/kibana-connectors/${params.connectorId}/documents/${params.documentId}`,
      {
        contentType: params.contentType,
      },
      true
    )
}

export {}

export default new ContentManagerAPI()
