import { AxiosError } from "axios"

export const defaultErrorHandler = (error: any, action?: string) => {
  if (error.response?.status) {
    if (error.response.status === 403) return `Permission denied on ${action}`
  }

  return `Unexpected error occurred${action ? "" : ` while ${action}`}`
}

export const errorToText = (
  error: any,
  defaultMessage: string = "Unexpected error"
) => {
  if (error instanceof AxiosError) {
    const response = error.response?.data

    if (typeof response.error?.message === "string")
      return response.error?.message
  }

  if (error instanceof Error && error.message) return error.message

  return defaultMessage
}

export default defaultErrorHandler
