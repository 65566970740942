import merge from "lodash/merge"
import cloneDeep from "lodash/cloneDeep"
import find from "lodash/find"
import { nanoid } from "nanoid"
import { autorun, makeAutoObservable, reaction } from "mobx"
import keyBy from "lodash/keyBy"

import {
  AppUIConfig,
  AppUIConfigData,
  ColorSchemeOption,
} from "@framework/types/theme"
import { defaultAppConfig } from "@framework/constants/theme"
import AppUIConfigStore from "@store/app/app-ui-config.store"

import localThemeConfigStore from "./local-theme-config.store"
import { allAppColorSchemeOptions, defaultColorScheme } from "./constants"

export interface IColorSchemeSwitchContextState {
  activeColorSchemeName: string
  colorSchemeOptions: ColorSchemeOption[]
  setActiveColorScheme: (name: string) => void
}

class AppUIConfigManager implements IColorSchemeSwitchContextState {
  readonly themeConfigStore: AppUIConfigStore | null

  readonly localThemeStore = localThemeConfigStore

  constructor(injections?: { uiConfigStore?: AppUIConfigStore }) {
    this.themeConfigStore = injections?.uiConfigStore ?? null

    this.config = this.initConfig(this.themeConfigStore?.config)

    const schemeData = this.initColorScheme(this.themeConfigStore?.config)

    this.colorSchemeOptions = schemeData.options
    this.activeColorSchemeName = schemeData.active

    reaction(
      () => this.themeConfigStore?.config,
      (config) => {
        this.config = this.initConfig(config)

        const schemeData = this.initColorScheme(config)

        this.colorSchemeOptions = schemeData.options
        this.activeColorSchemeName = schemeData.active
      }
    )

    autorun(() => {
      if (this.isAppConfigLoaded) {
        this.localThemeStore.setActiveConfig(this.config)
        this.localThemeStore.setActiveColorScheme(this.activeColorScheme)
      }
    })

    makeAutoObservable(this)
  }

  config: AppUIConfig

  activeColorSchemeName: string

  colorSchemeOptions: ColorSchemeOption[]

  get isAppConfigLoaded(): boolean {
    return this.themeConfigStore?.config != null
  }

  initConfig = (config?: Partial<AppUIConfigData> | null) => {
    // Cached from previous seance
    if (config == null) {
      return {
        ...merge(
          cloneDeep(defaultAppConfig),
          this.localThemeStore.activeConfig ?? {}
        ),
        id: nanoid(),
      }
    }

    const { colors, colorScheme, ...restConfig } = config

    const newConfig = { ...merge(cloneDeep(defaultAppConfig), restConfig) }

    return { ...newConfig, id: nanoid() }
  }

  initColorScheme = (config?: Partial<AppUIConfigData> | null) => {
    if (config == null) {
      const cachedConfig: Partial<ColorSchemeOption> =
        this.localThemeStore.activeColorScheme ?? {}

      const defaultConfig =
        find(allAppColorSchemeOptions, (it) => it.name === cachedConfig.name) ??
        defaultColorScheme

      // Cached from previous seance
      return {
        active: cachedConfig.name ?? defaultConfig.name,
        options: [{ ...merge(cloneDeep(defaultConfig), cachedConfig) }],
      }
    }

    const { colorScheme, colors } = config

    // From configuration
    if (colorScheme != null) {
      const activeOptionsMap = keyBy(
        colorScheme?.options ?? [],
        (it) => it.name
      )

      const options = allAppColorSchemeOptions.reduce<ColorSchemeOption[]>(
        (acc, it) => {
          const candidate = activeOptionsMap[it.name]
          if (candidate != null && !candidate.disabled) {
            acc.push(merge(cloneDeep(it), candidate))
          }
          return acc
        },
        []
      )

      if (options.length > 0) {
        const defaultCandidate = colorScheme.default

        const localCandidate = this.localThemeStore.activeColorScheme?.name

        return {
          active:
            find(options, (it) => it.name === localCandidate)?.name ??
            find(options, (it) => it.name === defaultCandidate)?.name ??
            options[0]?.name,
          options,
        }
      }
    }

    // @deprecated use colorScheme instead
    // From old configuration
    if (colors != null)
      return {
        active: defaultColorScheme.name,
        options: [{ ...defaultColorScheme, ...colors }],
      }

    // Default
    return {
      active: defaultColorScheme.name,
      options: [{ ...defaultColorScheme }],
    }
  }

  setActiveColorScheme = (name: string) => {
    this.activeColorSchemeName = name
  }

  findDefaultColorScheme = (name: string) => {
    return find(this.colorSchemeOptions, (it) => it.name === name)
  }

  get activeColorScheme(): ColorSchemeOption {
    const colorSchemeName = this.activeColorSchemeName
    return merge(
      cloneDeep(defaultColorScheme),
      this.findDefaultColorScheme(colorSchemeName) ?? defaultColorScheme
    )
  }
}

export default AppUIConfigManager
