import { observer } from "mobx-react-lite"
import React from "react"

import Icon from "@components/ui/Icon/Icon"
import { useAppThemeManager } from "@components/theme/AppThemeProvider"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import useToggle from "@components/hooks/useToggle"
import ListItem from "@components/ui/ListItem/ListItem"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"

import styles from "./UserSettingsMenu.module.sass"

export type UIThemeMenuProps = {
  showText?: boolean
}

export const UIThemeMenu: React.FC<UIThemeMenuProps> = observer(
  ({ showText }) => {
    const { activeColorSchemeName, colorSchemeOptions, setActiveColorScheme } =
      useAppThemeManager()

    const { isOpened, handleToggle, setOpened } = useToggle()

    if (colorSchemeOptions.length < 2) return null

    return (
      <Tooltip
        show={isOpened}
        onClose={() => setOpened(false)}
        mode="onFocus"
        content={
          <TooltipContainer placement="right">
            {colorSchemeOptions.map((it) => (
              <ListItem
                active={activeColorSchemeName === it.name}
                onClick={() => {
                  setActiveColorScheme(it.name)
                  setOpened(false)
                }}
                key={it.name}
              >
                {it.label || it.name}
              </ListItem>
            ))}
          </TooltipContainer>
        }
      >
        <div
          className={styles.profileInfo}
          tabIndex={-1}
          onClick={handleToggle}
          onKeyPress={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleToggle()
            }
          }}
          role="button"
        >
          <Button noPadding variant="outlined" color="default" size="big">
            <Icon name="palette" />
          </Button>
          {showText && (
            <Text className={styles.label} variant="body2">
              Theme
            </Text>
          )}
        </div>
      </Tooltip>
    )
  }
)

export default UIThemeMenu
