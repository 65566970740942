import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import styles from "./Table.module.sass"

export const Row: React.FC<
  { loading?: boolean } & React.HTMLAttributes<HTMLButtonElement>
> = observer(
  ({ children, loading, className, onClick, onDoubleClick, ...rest }) => {
    return (
      <button
        type="button"
        className={clsx(
          styles.bodyRow,
          styles.row,
          {
            [styles.loading]: loading,
            [styles.clickable]: onClick != null || onDoubleClick != null,
          },
          className
        )}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        {...rest}
      >
        {children}
      </button>
    )
  }
)

export const Header: React.FC<{ className?: string }> = observer(
  ({ children, className }) => {
    return (
      <div className={clsx(styles.headerRow, styles.row, className)}>
        {children}
      </div>
    )
  }
)

export const Column: React.FC<
  { className?: string } & React.HTMLAttributes<HTMLDivElement>
> = observer(({ children, className, ...rest }) => {
  return (
    <div {...rest} className={clsx(styles.col, "col", className)}>
      {children}
    </div>
  )
})
