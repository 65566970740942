import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import QuestionsList from "./Questions/Questions"

const QuestionsRoot: React.FC = () => (
  <Routes>
    <Route path=":groupBy/:tab/:questionId" element={<QuestionsList />} />
    <Route path=":groupBy/:tab" element={<QuestionsList />} />
    <Route path="/*" element={<Navigate to="own/waiting" replace />} />
    <Route path="/**" element={<Navigate to="own/waiting" replace />} />
  </Routes>
)

export default QuestionsRoot
