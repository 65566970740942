import React from "react"
import { observer } from "mobx-react-lite"

import SidebarPopup from "@components/modals/components/SidebarPopup"
import Templates from "@components/ui/Templates"
import Text from "@components/ui/Typography/Text"
import List from "@components/ui/List/List"

import AllActiveUsersList from "./AllActiveUsersList"

import styles from "./AllActiveUsersSidebar.module.sass"

export interface AllActiveUsersSidebarProps {
  open: boolean
  onClose?: () => void
}

export const AllActiveUsersSidebar: React.FC<AllActiveUsersSidebarProps> =
  observer(({ open, onClose }) => {
    return (
      <SidebarPopup className={styles.root} onClose={onClose} open={open}>
        <Templates.RollScript
          gutter="24"
          headerSocket={
            <List gutter="4" overflow="initial">
              <Text variant="h3" color="text100Color" weight="bold">
                Top Active Users
              </Text>
              <Text variant="caption2" color="text70Color">
                Based on the number of user queries
              </Text>
            </List>
          }
        >
          <AllActiveUsersList />
        </Templates.RollScript>
      </SidebarPopup>
    )
  })

export default AllActiveUsersSidebar
