import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import { useStore } from "@store/index"

const useAvatarExpertAnswersCollection = () => {
  const { avatar } = useActiveAvatar()
  const { avatarExpertKnowledgeStore } = useStore()
  return avatarExpertKnowledgeStore.getAnswersCollection(avatar.id)
}

export default useAvatarExpertAnswersCollection
