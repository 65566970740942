import clsx from "clsx"
import React from "react"

import sizeStyles from "./IconButton-size.module.sass"
import styles from "./IconButton.module.sass"

export type IconButtonSize = "big" | "medium" | "small"

export interface ButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, "size"> {
  rounded?: boolean
  active?: boolean
  size?: IconButtonSize
}

export const IconButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { children, className, active, rounded = false, size = "small", ...rest },
    ref
  ) => (
    <button
      {...rest}
      ref={ref}
      type="button"
      className={clsx(
        styles.root,
        { [styles.active]: active, [styles.rounded]: rounded },
        sizeStyles[size],
        className
      )}
    >
      {children}
    </button>
  )
)

export default IconButton
