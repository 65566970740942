import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import { useStore } from "@store/index"
import { SolutionData } from "@framework/types/solution"
import { getCategorySolutions } from "@utils/product-solution"

import SolutionCard from "./SolutionCard"
import CreateNewSolutionCard from "./CreateNewSolutionCard"
import ContextMenu from "./SolutionContextMenu"

import styles from "./AvailableSolutions.module.sass"

type SolutionsCardContainerProps = {
  className?: string
  onLaunch?: (option: SolutionData) => void
  onCreate?: () => void
}

const SolutionsCardContainer: React.FC<SolutionsCardContainerProps> = observer(
  ({ className, onLaunch, onCreate }) => {
    const { solutionsStore } = useStore()

    const { solutions, category } = solutionsStore

    if (!category) return <></>

    const availableSolutions = getCategorySolutions(category, solutions || [])

    return (
      <div className={clsx(styles.solutionCardContainer, className)}>
        {availableSolutions?.map((item) => (
          <SolutionCard
            onClick={onLaunch}
            solution={item}
            contextMenuSlot={<ContextMenu solution={item} />}
            key={item.id}
          />
        ))}

        {onCreate != null && <CreateNewSolutionCard onClick={onCreate} />}
      </div>
    )
  }
)

export default SolutionsCardContainer
