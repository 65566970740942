import React from "react"
import { useFormik } from "formik"
import * as yup from "yup"

import TextInput, { TextAreaInput } from "@components/ui/TextInput/TextInput"
import Button from "@components/ui/Button/Button"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import Loader from "@components/ui/Loader/BarLoader"
import Label from "@components/ui/Label/Label"

import styles from "./CommentForm.module.sass"

export const validationSchema = yup.object({
  title: yup
    .string()
    .required()
    .label("Title")
    .min(3, "Must be at least 3 symbols long")
    .max(100, "Must be at most 100 symbols long")
    .default("")
    .trim(),
  message: yup
    .string()
    .required()
    .label("Message")
    .max(450, "Must be at max 450 symbols long")
    .default("")
    .trim(),
})

export type FormData = yup.InferType<typeof validationSchema>

interface CommentFormProps {
  isLoading?: boolean
  className?: string
  initialValue?: Partial<FormData>
  onSubmit: (form: FormData) => void
}

const CommentForm: React.FC<CommentFormProps> = ({
  isLoading,
  initialValue,
  onSubmit,
}) => {
  const formik = useFormik<FormData>({
    initialValues: validationSchema.cast(initialValue, { stripUnknown: true }),
    validationSchema,
    onSubmit: (form: FormData) => {
      onSubmit(validationSchema.cast(form))
    },
  })

  return (
    <form className={styles.root} onSubmit={formik.handleSubmit}>
      <div className={styles.form}>
        <Label id="title" label="Title">
          <TextInput
            name="title"
            placeholder="Enter title..."
            value={formik.values.title}
            withError={!!(formik.touched.title && formik.errors.title)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            after={
              formik.touched.title &&
              formik.errors.title && (
                <div className={styles.after}>
                  <ErrorChip
                    message={formik.errors.title}
                    messagePlacement="left"
                  />
                </div>
              )
            }
          />
        </Label>

        <Label id="message" label="Message">
          <TextAreaInput
            autoFocus
            name="message"
            placeholder="Enter message..."
            value={formik.values.message}
            withError={!!(formik.touched.message && formik.errors.message)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            after={
              formik.touched.message &&
              formik.errors.message && (
                <div className={styles.after}>
                  <ErrorChip
                    message={formik.errors.message}
                    messagePlacement="left"
                  />
                </div>
              )
            }
          />
        </Label>

        <Button type="submit" color="primary" disabled={isLoading}>
          Save {isLoading && <Loader />}
        </Button>
      </div>
    </form>
  )
}

export default CommentForm
