import { Option } from "../types/utils"

export const USER_EXPLORE_AVATAR_KEY = "USER_EXPLORE_AVATAR_KEY"

/**
 * @deprecated remove in 2025 year
 */
export const USER_ACTIVE_APP_KEY = "USER_ACTIVE_APP_KEY"
localStorage.removeItem(USER_ACTIVE_APP_KEY)
// ---

export type AnswerViewType = "Text" | "Table" | "Image"

export type ExtendedResultType = AnswerViewType | "Original"

export type ResultViewType = "SUMMARY_VIEW" | "SOURCE_VIEW"

export const resultTypeOptions: Option<AnswerViewType>[] = [
  { value: "Text", name: "Text" },
  { value: "Table", name: "Table" },
  { value: "Image", name: "Image" },
]

export const extendedResultTypeOptions: Option<ExtendedResultType>[] = [
  { value: "Original View", name: "Original" },
  ...resultTypeOptions,
]

export const resultViewOptions: Option<ResultViewType>[] = [
  { value: "Passage View", name: "SUMMARY_VIEW" },
  { value: "Source View", name: "SOURCE_VIEW" },
]

export const sortResultOptions: Option[] = [
  { value: "Relevancy", name: "RELEVANCY" },
  { value: "Newest", name: "NEWEST" },
  { value: "Oldest", name: "OLDEST" },
]

export enum QuestionFeedbackType {
  POSITIVE = "positive",
  NEUTRAL = "neutral",
  NEGATIVE = "negative",
}

/**
 * @deprecated migrated to solutions workflow types
 */
export const enum SolutionTypes {
  InsightsFinder = "insights-finder",
  PrivateWorkspace = "private-workspace",
  ApplicationImplementation = "application-implementation",
  ProductComparison = "product-comparison-and-positioning",
  ProductRecommender = "product-recommender",
  AccountResearch = "account-research",
}

export const solutionCoverImageMapper: Record<SolutionTypes, string> = {
  [SolutionTypes.ApplicationImplementation]:
    "/images/solutions/application_implementation.jpg",
  [SolutionTypes.PrivateWorkspace]: "/images/solutions/private_workspace.jpg",
  [SolutionTypes.InsightsFinder]: "/images/solutions/insight_finder.jpg",
  [SolutionTypes.ProductComparison]: "/images/solutions/product_comparison.jpg",
  [SolutionTypes.ProductRecommender]:
    "/images/solutions/product_recommender.jpg",
  [SolutionTypes.AccountResearch]: "/images/sphere_expanded.jpg",
}

export const getSolutionCoverImage = (solutionName: SolutionTypes | string) => {
  return (
    solutionCoverImageMapper[solutionName as SolutionTypes] ??
    solutionCoverImageMapper["insights-finder"]
  )
}
