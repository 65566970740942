import React from "react"
import { ChartData, TooltipItem } from "chart.js"
import get from "lodash/get"

import { DD_MM_YYYY_FORMAT } from "@framework/constants/global"
import { UserTrendPointData } from "@framework/types/analytics"

import { labelsFromPeriod, transformUserStickinessData } from "./utils"

export type ChartFractionMetaData = {
  name: string
  label: string
  color: string
  yAxisID?: string
}

export interface Props {
  startDate: string
  endDate: string
  data: UserTrendPointData[]
  fractions: ChartFractionMetaData[]
}

export const useUserStickinessChartData = (props: Props) => {
  const { fractions, startDate, endDate, data } = props

  return React.useCallback((): ChartData<"line"> => {
    const labels = labelsFromPeriod([startDate, endDate], DD_MM_YYYY_FORMAT)

    const datasetFractions = transformUserStickinessData(labels, data)

    return {
      labels,
      datasets: fractions.map((it) => ({
        type: "line",
        label: it.label,
        data: get(datasetFractions, it.name, []),
        borderColor: it.color,
        pointHoverBackgroundColor: it.color,
        borderWidth: 2,
        tension: 0.3,
        yAxisID: it.yAxisID,
      })),
    }
  }, [fractions, data, startDate, endDate])
}

export const renderLabel = (item: TooltipItem<"line">) => {
  return ` ${String(item.raw)} ${item.dataset.label}`
}

export default useUserStickinessChartData
