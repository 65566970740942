import React from "react"
import clsx from "clsx"

import Button from "@components/ui/Button/Button"

import styles from "./TruncatedText.module.sass"

export const TruncatedText: React.FC<{
  children?: React.ReactNode
  truncated?: boolean
  maxLines?: number
  className?: string
  onReadMoreClick?: React.MouseEventHandler
}> = ({
  children = "",
  maxLines,
  className,
  truncated = true,
  onReadMoreClick,
}) => {
  const [isTextOverflowed, setIsTextOverflowed] = React.useState(false)

  const contentNodeRef = React.useRef<HTMLDivElement>(null)

  React.useLayoutEffect(() => {
    const node = contentNodeRef.current

    if (node == null || !truncated) return

    setIsTextOverflowed(node.offsetHeight < node.scrollHeight)
  }, [children, truncated, maxLines])

  return (
    <>
      <div
        ref={contentNodeRef}
        className={clsx(styles.root, { [styles.clamp]: truncated }, className)}
        style={truncated ? { WebkitLineClamp: maxLines } : undefined}
      >
        {children}
      </div>

      {isTextOverflowed && (
        <Button
          className={styles.readMore}
          onClick={onReadMoreClick}
          noPadding
          variant="slim"
          size="tiny"
        >
          {truncated ? "Read More" : "Read Less"}
        </Button>
      )}
    </>
  )
}
export default TruncatedText
