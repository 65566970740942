import React from "react"
import clsx from "clsx"

import Button from "@components/ui/Button/Button"

import styles from "./Footer.module.sass"

interface Props {
  className: string
  onCancel?: () => void
}

const Footer: React.FC<Props> = ({ className, onCancel }) => {
  return (
    <div className={clsx(styles.footer, className)}>
      <Button onClick={onCancel} size="medium" variant="outlined">
        Cancel
      </Button>
      <Button type="submit" size="medium" variant="contained" color="primary">
        Apply
      </Button>
    </div>
  )
}

export default Footer
