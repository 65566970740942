import { observer } from "mobx-react-lite"
import React from "react"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"

import { useStore } from "@store/index"
import LoadingPlaceholder from "@components/security/LoadingPlaceholder"

import SubtopicArticlesPage from "./Topics/SubtopicArticlesPage"
import TopicsPage from "./Topics/Topics"
import UrlWithAvatarContextProvider from "../search/ActiveAvatarContext/UrlWithAvatarContextProvider"

const ExpertInsightsRoot: React.FC = observer(() => {
  const navigate = useNavigate()
  const {
    userSetupStore: { avatarsStore, avatarId, setAvatarId },
  } = useStore()

  const avatar = avatarsStore.getAvatarById(avatarId)

  const handleAvatarChange = (avatarId: string) => {
    setAvatarId(avatarId)
    navigate(`/expert-insights/${avatarId}`)
  }

  if (avatar == null) return <LoadingPlaceholder />

  return (
    <Routes>
      <Route
        path=":avatarId/*"
        element={
          <UrlWithAvatarContextProvider
            avatarIdPropName="avatarId"
            onAvatarChange={handleAvatarChange}
          >
            <TopicsPage />
          </UrlWithAvatarContextProvider>
        }
      />
      <Route
        path=":avatarId/:subtopicId/*"
        element={
          <UrlWithAvatarContextProvider
            avatarIdPropName="avatarId"
            onAvatarChange={handleAvatarChange}
          >
            <SubtopicArticlesPage />
          </UrlWithAvatarContextProvider>
        }
      />
      <Route path="*" element={<Navigate to={avatar.id} />} />
    </Routes>
  )
})

export default ExpertInsightsRoot
