import React, { useEffect, useState } from "react"

import DatePickerCard from "@components/ui/DatePicker/DatePickerCard"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import { defaultDateRangePresets } from "@framework/constants/common"

import Dropdown from "../Dropdown/Dropdown"

import styles from "./DatePicker.module.sass"

export interface DatePickerProps {
  label?: string
  isOpened?: boolean
  value?: Date[]
  onChange?: (value?: Date[]) => void
}

const DatePicker: React.FC<DatePickerProps> = ({
  label = "Date",
  isOpened = false,
  value,
  onChange,
}) => {
  const [open, setOpen] = useState(isOpened)

  const isChecked = !!value?.length

  const hanldeChange = (newValue?: Date | Date[]) => {
    if (!onChange) return
    if (!newValue) {
      onChange(newValue)
      return
    }
    onChange(Array.isArray(newValue) ? newValue : [newValue])
  }

  const handleUncheck = (e: React.MouseEvent) => {
    if (isChecked) onChange?.(undefined)
    else setOpen(true)
    e.stopPropagation()
  }

  useEffect(() => {
    if (isOpened !== open) setOpen(isOpened)
  }, [isOpened])

  return (
    <Dropdown
      title={label}
      before={<CheckboxWithLabel checked={isChecked} onClick={handleUncheck} />}
      opened={open}
      onClick={() => setOpen((value) => !value)}
    >
      <DatePickerCard
        className={styles.calendar}
        defValue={value}
        onChange={hanldeChange}
        placeholder="Choose date"
        presets={defaultDateRangePresets}
      />
    </Dropdown>
  )
}

export default DatePicker
