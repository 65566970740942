import { DataConnectorSourceName } from "@framework/types/upload"
import { useStore } from "@store/index"

const useAvatarDataConnectors = (
  dataCategoryId: string,
  dataConnectorType: DataConnectorSourceName | string
) => {
  const { avatarDataConnectorsStore } = useStore()
  return avatarDataConnectorsStore.getCollection(
    dataCategoryId,
    dataConnectorType
  )
}

export default useAvatarDataConnectors
