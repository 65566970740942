import { makeAutoObservable } from "mobx"

import userService from "@services/user.service"
import defaultErrorHandler from "@store/utils/error-handlers"

import UserListStore from "./user-list.store"

/**
 * @deprecated planned to be removed in scope of avatars disposal
 */
export class UserListController {
  userListStore: UserListStore

  constructor(injections: { userListStore: UserListStore }) {
    this.userListStore = injections.userListStore
    makeAutoObservable(this)
  }

  loadUsers = async (query: string = "") => {
    try {
      this.userListStore.isLoading = true
      this.userListStore.error = null

      const response = await userService.getAdminUsers({
        query,
        pageNum: 0,
        pageSize: 50000,
      })

      if (response.data.data) {
        this.userListStore.setUsers(response.data.data)
      }
    } catch (error: any) {
      this.userListStore.error = defaultErrorHandler(error, "loading users")
      return this.userListStore.error
    } finally {
      this.userListStore.isLoading = false
    }
    return null
  }
}

export default UserListController
