import React from "react"

import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import { CloudEntity } from "@framework/types/upload"

const CloudEntityStatusBadge: React.FC<{ data?: CloudEntity }> = ({ data }) => {
  return (
    <Text variant="h2">
      {data?.error ? (
        <Icon name="alert" color="red" title={data.error} />
      ) : !data?.isUploaded ? (
        <Icon color="gold" name="time" title="Processing..." />
      ) : (
        <Icon
          color="green"
          name="checkbox-circle-fill"
          title="File processed and ready to use"
        />
      )}
    </Text>
  )
}

export default CloudEntityStatusBadge
