import moment from "moment"
import keyBy from "lodash/keyBy"

import {
  SearchingTrendPointData,
  UserTrendPointData,
} from "@framework/types/analytics"
import { initArray } from "@utils/numberUtils"
import { YYYY_MM_DD_FORMAT } from "@framework/constants/global"

export const labelsFromPeriod = (
  period: [string, string],
  format: string = YYYY_MM_DD_FORMAT
) => {
  const endMoment = moment(period[1], format)
  const startMoment = moment(period[0], format)
  const points = Math.abs(endMoment.diff(startMoment, "days")) + 1
  return initArray(points, (idx) =>
    endMoment.clone().subtract(idx, "day").format(format)
  )
}

export const transformSearchingTrendData = (
  labels: string[],
  points: SearchingTrendPointData[]
) => {
  const pointsMap = keyBy(points, (it) => it.date)

  return labels.reduce<{
    totalQuestions: number[]
    totalExpertQuestions: number[]
    averageRating: number[]
  }>(
    (acc, item) => {
      const point = pointsMap[item]

      acc.totalQuestions.push(point?.totalQuestions ?? 0)
      acc.totalExpertQuestions.push(point?.totalExpertQuestions ?? 0)
      acc.averageRating.push(point?.averageRating ?? 0)
      return acc
    },
    {
      totalQuestions: [],
      totalExpertQuestions: [],
      averageRating: [],
    }
  )
}

export const transformUserStickinessData = (
  labels: string[],
  points: UserTrendPointData[]
) => {
  const pointsMap = keyBy(points, (it) => it.date)

  return labels.reduce<{
    ratio: number[]
  }>(
    (acc, item) => {
      const point = pointsMap[item]

      acc.ratio.push(point?.userStickiness ?? 0)

      return acc
    },
    {
      ratio: [],
    }
  )
}

export const transformUserTrendData = (
  labels: string[],
  points: UserTrendPointData[]
) => {
  const pointsMap = keyBy(points, (it) => it.date)

  return labels.reduce<{
    DAU: number[]
    MAU: number[]
    newUsers: number[]
  }>(
    (acc, item) => {
      const point = pointsMap[item]

      acc.DAU.push(point?.dau ?? 0)
      acc.MAU.push(point?.mau ?? 0)
      acc.newUsers.push(point?.newUsers ?? 0)

      return acc
    },
    {
      DAU: [],
      MAU: [],
      newUsers: [],
    }
  )
}
