import React from "react"
import { observer } from "mobx-react-lite"

import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import TextInput from "@components/ui/TextInput/TextInput"
import useSearch from "@components/hooks/useSearch"
import Button from "@components/ui/Button/Button"
import Templates from "@components/ui/Templates"
import List from "@components/ui/List/List"
import Icon from "@components/ui/Icon/Icon"
import { useSearchContext } from "@components/prototypes/SearchContext"
import Loader from "@components/ui/Loader/BarLoader"

import styles from "./BaseContainer.module.sass"

interface BaseContainerProps {
  totalSelected?: number
  isLoading?: boolean
  unit?: string
  editMode?: boolean
  children?: React.ReactNode
  onSubmit?: React.MouseEventHandler
  onCancel?: React.MouseEventHandler
  onEdit?: React.MouseEventHandler
}

const BaseContainer: React.FC<BaseContainerProps> = observer(
  ({
    unit = "Entity",
    isLoading,
    editMode,
    totalSelected,
    children,
    onSubmit,
    onCancel,
    onEdit,
  }) => {
    const searchContext = useSearchContext()

    const [searchProps] = useSearch({
      initialValue: searchContext.query,
      onChange: searchContext.setQuery,
    })

    return (
      <Templates.RollScript
        gutter="24"
        headerSocket={
          <List direction="row" gutter="8" className={styles.header}>
            <TextInput
              className={styles.search}
              placeholder="Search"
              before={<Icon name="search" />}
              autoFocus
              {...searchProps}
            />

            {!editMode ? (
              <Button
                size="big"
                variant="outlined"
                before={<Icon name="plus" />}
                onClick={onEdit}
                disabled={isLoading}
              >
                Add {unit}
              </Button>
            ) : null}
          </List>
        }
        footerSocket={
          editMode ? (
            <ModalFooterContainer>
              <>
                <Button
                  disabled={isLoading}
                  variant="outlined"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button disabled={isLoading} color="primary" onClick={onSubmit}>
                  {`Add ${totalSelected ? `(${totalSelected})` : ""}`}
                </Button>
              </>
            </ModalFooterContainer>
          ) : null
        }
      >
        {children}
      </Templates.RollScript>
    )
  }
)

export default BaseContainer
