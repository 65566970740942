import React from "react"
import clsx from "clsx"

import styles from "./TriggerButton.module.sass"

export interface TriggerButtonProps extends React.HTMLProps<HTMLButtonElement> {
  before?: React.ReactNode
  label?: string
  after?: React.ReactNode
  counter?: number
  active?: boolean
  shrinked?: boolean
  className?: string
}

export const TriggerButton: React.FC<TriggerButtonProps> = ({
  before,
  label,
  counter,
  after,
  active = false,
  shrinked = false,
  className,
  ...rest
}) => (
  <button
    {...rest}
    type="button"
    className={clsx(
      styles.root,
      { [styles.active]: active, [styles.shrinked]: shrinked },
      className
    )}
  >
    {before && <span className={styles.before}>{before}</span>}
    <p className={styles.label}>{label}</p>
    {after && <span className={styles.after}>{after}</span>}
    {counter != null && <div className={styles.counter}>{counter}</div>}
  </button>
)

export default TriggerButton
