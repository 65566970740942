/* eslint-disable camelcase */
import axios, { AxiosRequestConfig, Method } from "axios"

const confluenceConnectionScopeList = [
  "read:confluence-content.all",
  "read:confluence-content.summary",
  "read:confluence-space.summary",
  "read:confluence-props",
  "readonly:content.attachment:confluence",
  "read:confluence-user",
  "offline_access",
]

const confluenceDefaultConnectionScope = confluenceConnectionScopeList.join(" ")

type ConfluenceAccessRequest = {
  client_id: string
  redirect_uri: string
  state?: string
  scope?: string
}

// more info here https://developer.atlassian.com/cloud/jira/platform/oauth-2-3lo-apps/
interface AtlassianAccessRequestParams {
  audience: string
  client_id: string
  scope: string
  redirect_uri: string
  state?: string
  response_type: "code" | "token"
  prompt: "consent"
}

interface RequestProps<Data, Params> {
  url: string
  method: Method
  data?: Data
  params?: Params
}

class AtlassianAPI {
  config = {
    apiURL: "https://auth.atlassian.com",
  }

  getURL = (url: string) => `${this.config.apiURL}/${url}`

  getConfluenceAuthCodeLink = (params: ConfluenceAccessRequest) => {
    const requestParams: AtlassianAccessRequestParams = {
      scope: confluenceDefaultConnectionScope,
      audience: "api.atlassian.com",
      response_type: "code",
      prompt: "consent",
      ...params,
    }
    const searchParams = new URLSearchParams(
      Object.entries(requestParams).map(([key, value]) => [
        key,
        value.toString(),
      ])
    )
    return `${this.getURL("authorize")}?${searchParams.toString()}`
  }

  request = <Data, Params>(props: RequestProps<Data, Params>) => {
    const requestParams: AxiosRequestConfig = {
      url: this.getURL(props.url),
      method: props.method,
      data: props.data,
      params: props.params,
    }
    return axios.request(requestParams)
  }
}

export default new AtlassianAPI()
