import React from "react"

import { useStore } from "@store/index"

const useAvatarUploadedFilesKnowledge = (
  categoryId: string,
  sourceGroup: string
) => {
  const { avatarUploadedFilesKnowledgeStore } = useStore()

  const resetCollection = React.useCallback(
    () =>
      avatarUploadedFilesKnowledgeStore.resetCollection(
        avatarUploadedFilesKnowledgeStore.getCollectionKey(
          categoryId,
          sourceGroup
        )
      ),
    [categoryId, sourceGroup]
  )

  return {
    resetCollection,
    collection: avatarUploadedFilesKnowledgeStore.getCollection(
      categoryId,
      sourceGroup
    ),
  }
}

export default useAvatarUploadedFilesKnowledge
