import React from "react"
import { observer } from "mobx-react-lite"

import Text from "@components/ui/Typography/Text"
import { CategorizedTheme } from "@framework/types/company"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import { getCategoryIcon } from "@framework/constants/solutions"

import styles from "./ThemeCategory.module.sass"

const CHUNK_SIZE = 3

type ThemeCategoryProps = {
  categorizedTheme: CategorizedTheme
}

const ThemeCategory: React.FC<ThemeCategoryProps> = observer(
  ({ categorizedTheme }) => {
    const fullContentModal = useModal(ModalsTypes.PRODUCT_THEMES_MODAL)

    const total = categorizedTheme.themes.length

    const onReadMore = () => {
      fullContentModal.showModal({
        category: categorizedTheme.category,
        themes: categorizedTheme.themes,
      })
    }

    React.useEffect(() => () => fullContentModal.hideModal(), [])

    return (
      <div className={styles.root}>
        <div className={styles.header}>
          <Icon
            circle
            color="primary"
            className={styles.icon}
            name={getCategoryIcon(categorizedTheme.category)}
          />
          <Text variant="h3" weight="bold">
            {categorizedTheme.category}
          </Text>
        </div>

        <Text className={styles.body} variant="caption1" color="text70Color">
          <ul className={styles.list}>
            {categorizedTheme.themes.slice(0, CHUNK_SIZE).map((theme) => (
              <li className={styles.item} key={theme.id}>
                {theme.content}
              </li>
            ))}
          </ul>
        </Text>

        {total > CHUNK_SIZE && (
          <>
            <div className={styles.footer}>
              <Button
                variant="text"
                size="tiny"
                after={<Icon name="arrow-down" rotateAngle={-90} />}
                onClick={onReadMore}
              >
                Read More
              </Button>
            </div>
          </>
        )}
      </div>
    )
  }
)

export default ThemeCategory
