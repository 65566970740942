import React from "react"
import { observer } from "mobx-react-lite"

import Text from "@components/ui/Typography/Text"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import TextInput from "@components/ui/TextInput/TextInput"
import Icon from "@components/ui/Icon/Icon"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import { useSearchContext } from "@components/prototypes/SearchContext"
import AvatarPickerSidebar from "@components/prototypes/AvatarPickerSidebar"

interface HeaderProps {
  className?: string
}

export const Header: React.FC<HeaderProps> = observer(({ className }) => {
  const searchContext = useSearchContext()

  const { avatar, selectAvatar } = useActiveAvatar()

  const handleChangeAvatar = (avatarId: string) => {
    selectAvatar(avatarId)
  }

  return (
    <EntityPageHeaderLayout
      className={className}
      left={<Text variant="h1">Expert Insights</Text>}
      right={
        <AvatarPickerSidebar value={avatar.id} onChange={handleChangeAvatar} />
      }
    >
      <TextInput
        before={<Icon name="search" />}
        placeholder="Search"
        value={searchContext.query}
        onChange={(e) => searchContext.setQuery(e.target.value)}
        after={
          searchContext.query ? (
            <Icon name="cross" onClick={() => searchContext.setQuery("")} />
          ) : null
        }
      />
    </EntityPageHeaderLayout>
  )
})

export default Header
