import { IconName } from "@components/ui/Icon/IconName"

/**
 * @deprecated remove in 2025 year
 */
const WORKBOOK_SESSION_KEY = "WORKBOOK_SESSION"

localStorage.removeItem(WORKBOOK_SESSION_KEY)
// --------------------------------------------

export const getCategoryIcon = (category: string): IconName => {
  switch (category) {
    case "Environmental Impact and Sustainability":
      return "leaf-fill"

    case "Supply Chain and Raw Materials":
      return "truck"

    case "Mergers, Acquisitions, and Divestitures":
      return "contacts-book-upload"

    case "Product Portfolio and Innovation":
      return "box"

    case "Innovation Research and Development":
      return "flask"

    case "Financial Highlights":
      return "money-dollar-circle"

    case "Market Dynamics and Competitive Position":
      return "rhythm"

    case "Risk Factors and Management":
      return "presentation-fill"

    case "Regulatory Compliance and Safety":
      return "shield-user"

    case "Operational Efficiency and Scale":
      return "swap"

    default:
      return "box"
  }
}

export default null
