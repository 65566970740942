import React from "react"

import Chip from "@components/ui/Chip/Chip"
import { AssignedGroupType } from "@framework/types/question"
import { Option } from "@framework/types/utils"
import ContextMenu from "@pages/home/components/ContextMenu/ContextMenu"
import Templates from "@components/ui/Templates"
import Text from "@components/ui/Typography/Text"
import List from "@components/ui/List/List"

const groups: Record<AssignedGroupType, Option<AssignedGroupType>> = {
  expert: {
    name: "expert",
    value: "Assigned to other Experts",
  },
  own: {
    name: "own",
    value: "Assigned to Me",
  },
}

const groupOptions = Object.values(groups)

interface HeaderProps {
  groupBy?: AssignedGroupType
  className?: string
  onGroupChange?: (option: AssignedGroupType) => void
}

export const Header: React.FC<HeaderProps> = ({
  groupBy,
  className,
  onGroupChange,
}) => {
  const currentGroup = groupBy ? groups[groupBy] : null

  return (
    <Templates.Header
      className={className}
      left={
        <List gutter="4">
          <Text variant="h1">Questions</Text>
          {currentGroup && (
            <Chip color="blue" variant="rounded" uppercase>
              {currentGroup?.value}
            </Chip>
          )}
        </List>
      }
      right={
        <ContextMenu
          placeholder="Sort"
          prefix="Group By"
          items={groupOptions}
          value={groupBy}
          onChange={({ name }) => onGroupChange?.(name)}
        />
      }
    />
  )
}

export default Header
