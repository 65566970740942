import React from "react"
import { observer } from "mobx-react-lite"
import { Outlet, useParams } from "react-router-dom"

import { useController, useStore } from "@store/index"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"

import useActiveCategoryContext from "../ActiveDataCategoryContext/useActiveCategoryContext"
import {
  BreadCrumpsCheckpoint,
  BreadCrumpsCheckpointProps,
} from "./BreadCrumpsContext/BreadCrumpsCheckpoint"

export const DataConnectorOutlet: React.FC<BreadCrumpsCheckpointProps> =
  observer(({ ...rest }) => {
    const category = useActiveCategoryContext()
    const { groupName, dataConnectorId } = useParams<{
      groupName: string
      dataConnectorId: string
    }>()

    if (groupName == null || dataConnectorId == null)
      throw new Error("groupName and dataConnectorId are required")

    const { dataConnectorController } = useController()

    React.useEffect(() => {
      dataConnectorController.loadDataConnectorPreview(dataConnectorId)
    }, [dataConnectorId])

    const render = React.useCallback(
      () => (
        <DataConnectorBreadCrump
          groupName={groupName}
          dataConnectorId={dataConnectorId}
          dataCategoryId={category.id}
        />
      ),
      [groupName, dataConnectorId, category.id]
    )

    return (
      <BreadCrumpsCheckpoint render={render} {...rest}>
        <Outlet />
      </BreadCrumpsCheckpoint>
    )
  })

export default DataConnectorOutlet

export const DataConnectorBreadCrump: React.FC<{
  dataCategoryId?: string
  groupName: string
  dataConnectorId: string
}> = observer(({ groupName, dataConnectorId, dataCategoryId }) => {
  const { avatarDataConnectorsStore, dataConnectorPreviewsStore } = useStore()

  const previewData =
    dataConnectorPreviewsStore.getById(dataConnectorId) ??
    (dataCategoryId
      ? avatarDataConnectorsStore
          .getCollection(dataCategoryId, groupName)
          .getById(dataConnectorId)
      : undefined)

  return (
    <>
      <DocumentIcon height={20} width={20} icon="img:store" />{" "}
      {previewData?.name ?? dataConnectorId.split("-").at(-1)}
    </>
  )
})
