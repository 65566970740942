import React from "react"
import { Form, FormikProvider, useFormik } from "formik"
import * as yup from "yup"
import { observer } from "mobx-react-lite"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import Templates from "@components/ui/Templates"
import { useController, useStore } from "@store"
import { QueryFilterData } from "@framework/types/query-filter"

import Footer from "./components/Footer"
import FiltersSubForm, {
  validationSchema as filtersValidationSchema,
} from "./components/FilterSubForm"
import useModal from "../useModal"
import { ModalsTypes } from "../constants"

import styles from "./SearchFilterModal.module.sass"

export type SearchFilterModalProps = {}

const validationSchema = filtersValidationSchema

type FormData = yup.InferType<typeof validationSchema>

const SearchFilterModal: React.FC<SearchFilterModalProps> = observer(() => {
  const currentModal = useModal(ModalsTypes.SEARCH_FILTERS_MODAL)
  const confirmModal = useModal(ModalsTypes.CONFIRM_MODAL)

  const { solutionsController } = useController()
  const {
    solutionsStore: { appliedFilters },
    factFinderSolutionStore: { searchFlowStore },
  } = useStore()

  const applyFilter = (filters: QueryFilterData[]) => {
    solutionsController.applyFilters(filters)
    currentModal.hideModal()
  }

  const handleSubmit = (form: FormData) => {
    if (searchFlowStore?.flowSequence?.length) {
      confirmModal.showModal({
        message: (
          <>
            Modifying filters will reset your current conversation.
            <br />
            Are you sure you want to proceed?
          </>
        ),
        confirm: "positive",
        onConfirm: () => applyFilter(form.filters),
        confirmButtonText: "Proceed",
      })
    } else {
      applyFilter(form.filters)
    }
  }

  const formik = useFormik<FormData>({
    validationSchema,
    initialValues: validationSchema.cast(
      { filters: appliedFilters },
      {
        stripUnknown: true,
      }
    ),
    onSubmit: handleSubmit,
  })

  return (
    <FormikProvider value={formik}>
      <BaseModal
        className={styles.root}
        containerClassName={styles.rootContainer}
      >
        <Form className={styles.body}>
          <Templates.RollScript
            className={styles.formContainer}
            bodyClassName={styles.container}
            gutter="24"
            footerSocket={
              <Footer
                className={styles.container}
                onCancel={() => currentModal.hideModal()}
              />
            }
          >
            <FiltersSubForm />
          </Templates.RollScript>
        </Form>
      </BaseModal>
    </FormikProvider>
  )
})

export default SearchFilterModal
