import React from "react"

import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import { FolderStatus } from "@framework/types/upload"

const FolderStatusBadge: React.FC<{ status?: FolderStatus }> = ({ status }) => {
  return (
    <Text variant="h2">
      {status == null ? null : status === "failed" ? (
        <Icon name="alert" color="red" title="Processing failed" />
      ) : status === "loading" ? (
        <Icon color="gold" name="time" title="Processing..." />
      ) : (
        <Icon
          color="green"
          name="checkbox-circle-fill"
          title="File processed and ready to use"
        />
      )}
    </Text>
  )
}

export default FolderStatusBadge
