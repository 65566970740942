import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React from "react"

import Header from "@components/containers/Header/Header"
import MobileSideMenu from "@components/containers/MobileSideMenu/MobileSideMenu"
import Sidebar from "@components/containers/Sidebar/Sidebar"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import NavigationScopeContextProvider from "../NavigationScopeContext"

import styles from "./MainLayout.module.sass"

export interface MainLayoutProps {
  className?: string
  /**
   * temporary param for container refactoring
   */
  noPadding?: boolean
}

export const MainLayout: React.FC<MainLayoutProps> = observer(
  ({ className, children, noPadding = false }) => {
    const isTablet = useMediaQuery(AppMediaQueries.minTablet)

    return (
      <NavigationScopeContextProvider>
        <div className={clsx(styles.root, { [styles.noPadding]: noPadding })}>
          {isTablet ? (
            <Header
              renderMenu={(isOpened, close) => (
                <MobileSideMenu isOpened={isOpened} close={close} />
              )}
            />
          ) : (
            <div className={styles.sidebar}>
              <Sidebar />
            </div>
          )}
          <div className={clsx(styles.content, className)}>{children}</div>
        </div>
      </NavigationScopeContextProvider>
    )
  }
)

export default MainLayout
