import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import clsx from "clsx"

import Templates from "@components/ui/Templates"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import ScrollableContainer from "@components/modals/CreateAvatarModal/components/ScrollableContainer/ScrollableContainer"
import List from "@components/ui/List/List"
import NotFound from "@components/ui/NotFound/NotFound"
import Loader from "@components/ui/Loader/BarLoader"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import { useController } from "@store/index"
import { ExpertTopicDTO } from "@framework/types/knowledge"
import IconButton from "@components/ui/IconButton/IconButton"
import { useSearchContext } from "@components/prototypes/SearchContext"
import EntityItem from "@pages/search/components/ItemsCollection/EntityItem"

import TopicCard from "./TopicCard/TopicCard"
import useTopicsCollection from "./hooks/useTopicsCollection"

import styles from "./index.module.sass"

interface TopicsSectionProps {
  avatarId: string
  selected?: string | null
  onSelect?: (value: string) => void
}

export const TopicsSection: React.FC<TopicsSectionProps> = observer(
  ({ avatarId, selected, onSelect }) => {
    const alert = useAlert()

    const searchContext = useSearchContext()

    const { expertInsightsController } = useController()

    const topicModal = useModal(ModalsTypes.EDIT_EXPERT_TOPIC_MODAL)

    const collection = useTopicsCollection(avatarId)

    const handleAddTopic = () => {
      topicModal.showModal({
        onSubmit: async (data: any) => {
          const response = await expertInsightsController.createTopic(
            avatarId,
            data
          )
          if (response.status === "FAILED") {
            alert.error(response.message)
            return
          }

          expertInsightsController.loadTopicList(avatarId)

          if (response.data) onSelect?.(response.data.id)

          alert.success("New topic was successfully created")

          topicModal.hideModal()
        },
      })
    }

    const handleEditTopic = (topic: ExpertTopicDTO) => {
      topicModal.showModal({
        initialValue: topic,
        onSubmit: async (data: any) => {
          const response = await expertInsightsController.updateTopic(
            avatarId,
            topic.id,
            data
          )

          if (response.status === "FAILED") {
            alert.error(response.message)
            return
          }

          expertInsightsController.loadTopicList(avatarId)

          alert.success("Topic was successfully updated")

          topicModal.hideModal()
        },
      })
    }

    const getTopicEditHandler =
      (topic: ExpertTopicDTO) => (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        handleEditTopic(topic)
      }

    return (
      <Templates.RollScript
        gutter="24"
        headerSocket={
          <Templates.Header
            className={styles.block}
            left={
              <Text variant="h2" weight="bold">
                Topics
              </Text>
            }
            right={
              <Button
                variant="outlined"
                size="small"
                before={<Icon name="plus" />}
                onClick={handleAddTopic}
              >
                Add Topic
              </Button>
            }
          />
        }
      >
        {collection.data.length === 0 ? (
          collection.isLoading ? (
            <Loader primary size="huge" fluid />
          ) : (
            <NotFound>
              {collection.error ? (
                <>Failed to load topics</>
              ) : searchContext.query ? (
                <>
                  No Topics found by query{" "}
                  <strong>&quot;{searchContext.query}&quot;</strong>
                </>
              ) : (
                <>
                  No topics found! <br />
                  Add one to see here
                </>
              )}
            </NotFound>
          )
        ) : (
          <ScrollableContainer className={styles.sidebarList}>
            <List gutter="8" overflow="initial">
              {collection.data.map((topic) => (
                <EntityItem
                  className={clsx(styles.listItem, styles.showControlOnHover)}
                  active={selected === topic.id}
                  onClick={onSelect ? () => onSelect?.(topic.id) : undefined}
                  key={topic.id}
                >
                  <TopicCard
                    data={topic}
                    controlSlot={
                      <IconButton
                        className={styles.listItemControl}
                        onClick={getTopicEditHandler(topic)}
                      >
                        <Icon name="edit" />
                      </IconButton>
                    }
                  />
                </EntityItem>
              ))}
            </List>
          </ScrollableContainer>
        )}
      </Templates.RollScript>
    )
  }
)

export default TopicsSection
