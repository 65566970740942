import React, { useContext } from "react"

import ProductListStore from "@store/product/product-list.store"
import ProductsViewStore from "@store/product/products.store"
import RecommendedProductListStore from "@store/product/recommended-product-list.store"

export type IProductsContext = {
  recommendedProductsStore: RecommendedProductListStore
  productListStore: ProductListStore
  productsViewStore: ProductsViewStore
}

export const ProductsContext = React.createContext<IProductsContext | null>(
  null
)

export const useProductsContext = () => {
  const context = useContext(ProductsContext)
  if (context == null) throw new Error("Can't ProductsContext Table context")
  return context
}
