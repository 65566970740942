/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { MouseEvent, useState } from "react"

import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import { Option } from "@framework/types/utils"

import styles from "../EntityCard.module.sass"

export interface ContextMenuProps {
  menuOptions: Option[]
  onSelect?: (option: string) => void
}

export const ContextMenu: React.FC<ContextMenuProps> = React.memo(
  ({ menuOptions, onSelect }) => {
    const [menuOpened, setMenuOpened] = useState(false)

    const getContextMenuHandler = (option: string) => (e: MouseEvent) => {
      e.stopPropagation()
      if (onSelect) onSelect(option)
      setMenuOpened(false)
    }

    return (
      <Tooltip
        show={menuOpened}
        onClose={() => setMenuOpened(false)}
        justify="end"
        content={
          <TooltipContainer
            className={styles.menuContainer}
            color="primary"
            placement="bottom"
            tip="end"
          >
            <ul>
              {menuOptions?.map((option) => (
                <li
                  key={option.name}
                  onClick={getContextMenuHandler(option.name)}
                >
                  <Icon name={option.icon} />
                  {option.value}
                </li>
              ))}
            </ul>
          </TooltipContainer>
        }
        mode="onFocus"
      >
        <IconButton size="big" active={menuOpened}>
          <Icon name="dots" onClick={() => setMenuOpened((value) => !value)} />
        </IconButton>
      </Tooltip>
    )
  }
)

export default ContextMenu
