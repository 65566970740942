import React, { useEffect } from "react"
import { useFormikContext } from "formik"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Radio from "@components/ui/Checkbox/Radio"

import { subPageGroupOptions, SubPagesDepthFormType } from "./types"

import styles from "./ConnectWebsite.module.sass"

export interface SubPageGroupsFormProps {
  className?: string
}

export const SubPageGroupsForm: React.FC<SubPageGroupsFormProps> = observer(
  ({ className }) => {
    const formik = useFormikContext<SubPagesDepthFormType>()

    useEffect(() => {
      formik.resetForm({
        values: { name: formik.values.name, crawlingDepth: undefined },
      })
    }, [])

    return (
      <div className={clsx(styles.form, className)}>
        {formik.touched.crawlingDepth && formik.errors.crawlingDepth && (
          <div className={styles.errorMessage}>
            {formik.errors.crawlingDepth}
          </div>
        )}
        <div className={styles.radioList}>
          {subPageGroupOptions.map((groupProps) => (
            <label
              htmlFor={groupProps.name.toString()}
              className={styles.label}
              key={groupProps.name}
            >
              <Radio
                id={groupProps.name.toString()}
                checked={formik.values.crawlingDepth === groupProps.name}
                onClick={() =>
                  formik.setFieldValue("crawlingDepth", groupProps.name)
                }
              />
              {groupProps.value}
            </label>
          ))}
        </div>
      </div>
    )
  }
)

export default SubPageGroupsForm
