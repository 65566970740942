import { makeAutoObservable } from "mobx"

import { MatrixSnapshot } from "@components/ui/Spreadsheet/types"

/**
 * list of session IDs to be able to cleanup localStore in future
 */
const WORKBOOK_SESSION_IDS_KEY = "WORKBOOK_SESSIONS"
const WORKBOOK_SESSION_KEY_PREFIX = "WORKBOOK_SESSION_"

export default class MatrixSnapshotStore {
  sessionIds: Set<string>

  constructor() {
    const sessionsText = localStorage.getItem(WORKBOOK_SESSION_IDS_KEY)

    this.sessionIds = new Set(
      sessionsText ? (JSON.parse(sessionsText) as string[]) : []
    )

    makeAutoObservable(this)
  }

  hasSnapshot = (solutionID: string) => {
    const key = this.getLocalStoreKey(solutionID)
    return !!localStorage.getItem(key)
  }

  getSnapshot = (solutionID: string): MatrixSnapshot | null => {
    const key = this.getLocalStoreKey(solutionID)
    const snapshot = localStorage.getItem(key)

    try {
      if (!snapshot) return null

      return JSON.parse(snapshot)
    } catch (e) {
      if (snapshot != null) this.dropSnapshot(solutionID)
    }
    return null
  }

  getLocalStoreKey = (solutionID: string) =>
    `${WORKBOOK_SESSION_KEY_PREFIX}${solutionID}`

  saveSnapshot = (solutionID: string, snapshot: MatrixSnapshot) => {
    const key = this.getLocalStoreKey(solutionID)
    localStorage.setItem(key, JSON.stringify(snapshot))

    this.sessionIds.add(solutionID)

    localStorage.setItem(
      WORKBOOK_SESSION_IDS_KEY,
      JSON.stringify(Array.from(this.sessionIds.values()))
    )
  }

  dropSnapshot = (solutionID: string) => {
    const key = this.getLocalStoreKey(solutionID)
    localStorage.removeItem(key)

    this.sessionIds.delete(solutionID)

    localStorage.setItem(
      WORKBOOK_SESSION_IDS_KEY,
      JSON.stringify(Array.from(this.sessionIds.values()))
    )
  }
}
