import React from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"
import clsx from "clsx"

import { useStore } from "@store/index"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import Chip from "@components/ui/Chip/Chip"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import UserChip from "@components/prototypes/UserChip"
import AvatarBubbles from "@components/prototypes/AvatarBubbles"
import {
  CloudEntity,
  DataConnectorContentGroupName,
  DataConnectorSourceName,
} from "@framework/types/upload"
import { getExtension } from "@utils/textUtils"
import { DocumentIconType, isDocumentIconName } from "@framework/types/utils"

import { Column, Row } from "./Table"
import ContextMenu from "../components/DocumentUploadCard/ContextMenu"
import useCloudFolderEntityContextMenu from "../connectors/Sources/hooks/useCloudFolderEntityContextMenu"
import CloudEntityStatusBadge from "./CloudEntityStatusBadge"

import styles from "./CloudFolderRow.module.sass"

export const CloudFileRow: React.FC<{
  data: CloudEntity
  // TODO move to context
  dataConnectorId: string
  connectorSourceName: DataConnectorSourceName
  sourceName: DataConnectorContentGroupName
}> = observer(({ data, connectorSourceName, sourceName, dataConnectorId }) => {
  const {
    allDatatypesStore,
    restrictionsStore: access,
    uploadStore,
    allUserStore,
  } = useStore()

  const store = uploadStore.getCloudEntitiesStore(
    connectorSourceName,
    dataConnectorId
  )

  const isReadonly = !access.canEditDataSource

  // const itemTypeDescription = getDataSourceNodeContextProps(sourceName)

  const dataType = allDatatypesStore.getById(data?.knowledgeDataTypeId)

  const contextMenuProps = useCloudFolderEntityContextMenu(
    connectorSourceName,
    dataConnectorId,
    dataType?.id,
    data,
    isReadonly,
    store.refresh
  )

  if (data == null) return <Skeleton rounded lineHeight={56} minWidth={100} />

  const isFailed = !!data.error
  const isRunning = !data.isUploaded && !isFailed

  const handleOpen = () => contextMenuProps.onContext(data.id, "open")

  const handleAssignDataType =
    isReadonly || isFailed
      ? undefined
      : (e: React.MouseEvent) => {
          e.stopPropagation()
          contextMenuProps.onContext(data.id, "edit_data_type")
        }

  const user = allUserStore.getById(data.createdBy ?? null)

  return (
    <Row
      className={clsx(styles.row)}
      loading={isRunning}
      onDoubleClick={handleOpen}
    >
      <Column>
        <DocumentIcon icon={getIcon(data.name)} height={28} width={28} />
      </Column>

      <Column title={data.name}>
        <Text variant="inherit" className={styles.text}>
          {data.name}
        </Text>
      </Column>

      <Column>
        {/* {totalFiles}{" "}
        {capitalizeFirstLetter(itemTypeDescription.contentItemName ?? "file")}
        {countSuffix(totalFiles)} */}
      </Column>

      <Column>
        <CloudEntityStatusBadge data={data} />
      </Column>

      <Column>
        <Chip
          color="secondary"
          variant="rounded"
          before={
            dataType != null && <Icon name={dataType.iconUrl ?? "global"} />
          }
          onClick={handleAssignDataType}
          title={dataType != null ? dataType.name : "Not Associated "}
        >
          {dataType != null ? dataType.name : "Not Associated "}
        </Chip>
      </Column>

      <Column>
        <AvatarBubbles avatars={dataType?.channels} />
      </Column>

      <Column>{moment(data.createdAt).format("MMM DD, YYYY")}</Column>

      <Column>
        <UserChip data={user} isLoading={allUserStore.state.isLoading} />
      </Column>

      <Column>
        {!!contextMenuProps.options.length && (
          <ContextMenu id={data.id} {...contextMenuProps} />
        )}
      </Column>
    </Row>
  )
})

export default CloudFileRow

const getIcon = (fileName: string): DocumentIconType => {
  const icon = `img:${getExtension(fileName)}`
  return isDocumentIconName(icon) ? icon : "img:file"
}
