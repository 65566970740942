import React from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"

import ModalFooterContainer from "../components/ControlFooter/ModalFooterContainer"

import styles from "./LongTextModal.module.sass"

export interface LongTextModalProps {
  title?: string
  content?: React.ReactNode
}

export const LongTextModal: React.FC<LongTextModalProps> = observer(
  ({ title = "Details", content }) => {
    const { hideModal } = useModal(ModalsTypes.LONG_TEXT_MODAL)

    return (
      <BaseModal
        className={styles.root}
        title={<ModalTitle titleText={title} />}
        onClose={hideModal}
      >
        <Templates.RollScript
          gutter="24"
          footerSocket={
            <ModalFooterContainer>
              <Button variant="outlined" onClick={hideModal}>
                Ok
              </Button>
            </ModalFooterContainer>
          }
        >
          <Text className={styles.body} variant="caption1" color="text70Color">
            {content}
          </Text>
        </Templates.RollScript>
      </BaseModal>
    )
  }
)

export default LongTextModal
