import clsx from "clsx"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import Button from "@components/ui/Button/Button"
import Editor from "@components/ui/Editor/Editor"
import { isEditorTextEmpty } from "@components/ui/Editor/utils"
import { AnswerStatus, ExpertQuestion } from "@framework/types/question"
import { SimpleUserData } from "@framework/types/user"
import mainRoutes from "@root/main.routes"
import { useStore } from "@store/index"
import Templates from "@components/ui/Templates"
import Label from "@components/ui/Label/Label"

import QuestionCard from "../QuestionCard/QuestionCard"

import styles from "./QuestionForm.module.sass"

const ALERT_MESSAGE_MOCK = "Answer this question and get 12 points"

interface WaitingQuestionFormProps {
  className?: string
  questionData: ExpertQuestion
}

const WaitingQuestionForm: React.FC<WaitingQuestionFormProps> = ({
  className,
  questionData,
}) => {
  const [answer, setAnswer] = useState<string>("")
  const valid = !isEditorTextEmpty(answer)

  const navigate = useNavigate()
  const {
    knowledgeStore: {
      lastAssignedQuestionId,
      updateExpertQuestion,
      cancelLastAssignation,
      loadExpertQuestions,
    },
    userStore: { user },
  } = useStore()

  const handleDecline = async () => {
    const success = await updateExpertQuestion(
      questionData.id,
      AnswerStatus.DECLINED
    )
    if (success) {
      loadExpertQuestions()
      navigate(mainRoutes.question("own", "declined", questionData.id))
    }
  }

  const handleSubmit = async () => {
    if (!valid) return
    const success = await updateExpertQuestion(
      questionData.id,
      AnswerStatus.ANSWERED,
      answer
    )
    if (success) {
      loadExpertQuestions()
      navigate(mainRoutes.question("own", "answered", questionData.id))
    }
  }

  const handleCancel = async () => {
    const success = await cancelLastAssignation(questionData.id)
    if (success) {
      loadExpertQuestions()
      navigate(mainRoutes.question("expert", "waiting", questionData.id))
    }
  }

  useEffect(() => {
    if (questionData.id && user?.id) {
      setAnswer(
        Object.values(questionData.thread).find(
          (assignee: { author: SimpleUserData; answer: string | null }) =>
            assignee.author.id === user.id
        )?.answer ?? ""
      )
    }
  }, [questionData.thread, questionData.id])

  return (
    <div className={clsx(styles.root, className)}>
      <QuestionCard data={questionData} alertMessage={ALERT_MESSAGE_MOCK}>
        <div className={styles.control}>
          <Button
            onClick={handleDecline}
            variant="outlined"
            color="red"
            size="medium"
          >
            Decline
          </Button>
        </div>
      </QuestionCard>

      <div className={styles.editor}>
        <Label id="answer" label="ANSWER">
          <Editor onChange={setAnswer} defaultValue={answer} />
        </Label>

        <Templates.Header
          left={
            lastAssignedQuestionId === questionData.id && (
              <Button variant="outlined" color="default" onClick={handleCancel}>
                Cancel
              </Button>
            )
          }
          right={
            <Button
              variant="contained"
              color="primary"
              disabled={!valid}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          }
        />
      </div>
    </div>
  )
}

export default WaitingQuestionForm
