import { useContext } from "react"

import MembershipContext from "./MembershipContext"

const useMembershipContext = () => {
  const context = useContext(MembershipContext)
  if (context != null) return context
  throw new Error("MembershipContext not found")
}

export default useMembershipContext
