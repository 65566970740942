import React from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/BarLoader"
import MainLayout from "@components/layout/MainLayout/MainLayout"

export const AvatarRequiredScope: React.FC = observer(({ children }) => {
  const {
    avatarsStore,
    userSetupStore: { avatarId },
  } = useStore()

  const avatar = avatarsStore.getAvatarById(avatarId)

  if (avatar == null)
    return (
      <MainLayout>
        <Loader size="huge" fluid />
      </MainLayout>
    )
  return <>{children}</>
})

export default AvatarRequiredScope
