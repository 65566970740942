import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import MenuItem from "@components/containers/Sidebar/MenuItem"
import useToggle from "@components/hooks/useToggle"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import { UserGroup } from "@framework/types/user"
import { useController, useStore } from "@store/index"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"

interface ContextMenuProps {
  group: UserGroup
}

const ContextMenu: React.FC<ContextMenuProps> = observer(({ group }) => {
  const alert = useAlert()

  const { restrictionsStore: access } = useStore()
  const { userGroupsController } = useController()

  const editModal = useModal(ModalsTypes.EDIT_USER_GROUP_MODAL)
  const userManagementModal = useModal(
    ModalsTypes.EDIT_USER_GROUP_MEMBERSHIP_MODAL
  )
  const confirmModal = useModal(ModalsTypes.CONFIRM_MODAL)

  const handleEdit = () => {
    editModal.showModal({ group })
  }

  const handleUserManagement = () => {
    userManagementModal.showModal({ group })
  }

  const handleDelete = () => {
    const handleSubmit = async () => {
      const error = await userGroupsController.removeUserGroup(group.id)

      if (error != null) alert.error(error)
      else alert.success("Group was removed successfully")

      userGroupsController.reload()
    }

    confirmModal.showModal({
      message: (
        <>
          Are you sure you want to remove group &quot;<b>{group.name}</b>&quot;?
        </>
      ),
      confirm: "negative",
      onConfirm: handleSubmit,
    })
  }

  const { isOpened, handleToggle, setOpened } = useToggle()

  return (
    <Tooltip
      show={isOpened}
      onClose={() => setOpened(false)}
      mode="onFocus"
      content={
        <TooltipContainer color="primary" placement="bottom">
          {access.canEditUserGroups && (
            <MenuItem icon="edit" onClick={handleEdit} key="reset">
              Edit
            </MenuItem>
          )}

          {access.canManageUserGroupsMembership && (
            <MenuItem icon="users" key="edit" onClick={handleUserManagement}>
              Manage Users
            </MenuItem>
          )}

          {access.canDeleteUserGroups && (
            <MenuItem
              color="red"
              icon="trash-can"
              key="logout"
              onClick={handleDelete}
            >
              Delete
            </MenuItem>
          )}
        </TooltipContainer>
      }
    >
      <IconButton size="medium" active={isOpened} onClick={handleToggle}>
        <Icon name="dots" />
      </IconButton>
    </Tooltip>
  )
})

export default ContextMenu
