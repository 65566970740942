import React from "react"
import moment from "moment"
import { observer } from "mobx-react-lite"

import Avatar from "@components/ui/Avatar/Avatar"
import { ExpertQuestion } from "@framework/types/question"
import Text from "@components/ui/Typography/Text"

import styles from "./QuestionItem.module.sass"

// const renderAssigned = (assignedType: string) => (
//   <Chip
//     variant="rounded"
//     color={assignedType === "CURRENT_USER" ? "yellow" : "gold"}
//   >
//     {assignedType === "CURRENT_USER" ? "Assigned to you" : "Delegate to expert"}
//   </Chip>
// )

interface QuestionItemProps {
  data: ExpertQuestion
}

const QuestionItem: React.FC<QuestionItemProps> = observer(({ data }) => (
  <div className={styles.root}>
    <Avatar name={data.author.name} src={data.author.avatarUrl ?? ""} />

    <div className={styles.body}>
      <Text variant="h5" weight="bold">
        {data.author.name ?? "Unknown"}
      </Text>

      <Text variant="body3">{`“${data.question}“`}</Text>

      <Text variant="caption2" color="text50Color">
        {moment(data.lastUpdated).fromNow()}
      </Text>
    </div>
  </div>
))

export default QuestionItem
