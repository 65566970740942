import React from "react"
import { observer } from "mobx-react-lite"

import Text from "@components/ui/Typography/Text"
import SimpleOptionCard from "@components/ui/SimpleOptionCard/SimpleOptionCard"
import Icon from "@components/ui/Icon/Icon"
import { Option } from "@framework/types/utils"
import { WorkFlowType } from "@framework/types/solution"

import BaseModal from "../components/BaseModal/BaseModal"
import ModalTitle from "../components/ModalTitle/ModalTitle"
import useModal from "../useModal"
import { ModalsTypes } from "../constants"

import styles from "./SelectSolutionTypeModal.module.sass"

export interface SelectSolutionTypeModalProps {
  onSubmit?: (value: WorkFlowType) => void
}

export const SelectSolutionTypeModal: React.FC<SelectSolutionTypeModalProps> =
  observer(({ onSubmit }) => {
    const modal = useModal(ModalsTypes.SELECT_SOLUTION_TYPE_MODAL)

    const handleClose = () => modal.hideModal()

    const handleSelect = (value: WorkFlowType) => {
      onSubmit?.(value)
      modal.hideModal()
    }

    const title = "Create New Solution"

    return (
      <BaseModal
        className={styles.root}
        title={<ModalTitle titleText={title} />}
        containerClassName={styles.container}
        onClose={handleClose}
      >
        <div className={styles.cards}>
          {options.map((item) => (
            <SimpleOptionCard
              onClick={() => handleSelect(item.name)}
              iconSlot={<Icon name={item.icon} />}
              label={item.value}
              description={item.description}
              key={item.name}
            />
          ))}
        </div>

        <Text variant="caption1" color="text50Color" align="center">
          Select the type of solution you want to create. You can customize it
          further in the next step.
        </Text>
      </BaseModal>
    )
  })

export default SelectSolutionTypeModal

const options: Option<WorkFlowType>[] = [
  {
    name: WorkFlowType.SEARCH,
    value: "Search Solution",
    description:
      "Create a solution with suggested questions and filters for targeted searches.",
    icon: "search",
  },
  {
    name: WorkFlowType.WORKBOOK,
    value: "Workbook Solution",
    description: "Create a solution with spreadsheet template.",
    icon: "grid-layout",
  },
]
