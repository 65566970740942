import React from "react"

import SecondaryLayout from "@components/layout/SecondaryLayout/SecondaryLayout"
import ScrollableContainer from "@components/modals/CreateAvatarModal/components/ScrollableContainer/ScrollableContainer"
import HTMLText from "@components/ui/Typography/HTMLText"

import PRIVACY_POLICY from "./privacy"

import styles from "./PrivacyPolicy.module.sass"

interface ServiceTermsProps {}

const PrivacyPolicyPage: React.FC<ServiceTermsProps> = () => (
  <SecondaryLayout className={styles.root}>
    <div className={styles.container}>
      <ScrollableContainer className={styles.list}>
        <HTMLText>{PRIVACY_POLICY}</HTMLText>
      </ScrollableContainer>
    </div>
  </SecondaryLayout>
)

export default PrivacyPolicyPage
