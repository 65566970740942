import { makeAutoObservable } from "mobx"
import groupBy from "lodash/groupBy"

import {
  SubscriptionOptionList,
  SubscriptionUsage,
  SubscriptionsData,
} from "@framework/types/subscription"

const defaultSubscriptionData = {
  apiQuota: 0,
  apiQuotaConsumed: 0,
  connectors: [],
  dataQuota: 0,
  dataQuotaConsumed: 0,
  environments: [],
  numberOfUsers: 0,
  platformHosting: [],
  support: [],
  contractStartDate: "",
  contractEndDate: "",
}

export class SubscriptionStore {
  constructor() {
    makeAutoObservable(this)
  }

  data: SubscriptionsData = defaultSubscriptionData

  error: string | null = null

  isLoading = false

  availableOption: SubscriptionOptionList = {}

  isEditable: boolean = false

  editedSubscriptionData: SubscriptionsData = defaultSubscriptionData

  subscriptionUsage: SubscriptionUsage | null = null

  errorLoadingData: boolean = false

  setEditable = (value: boolean) => {
    this.isEditable = value
  }

  setLoading = (value = true) => {
    this.isLoading = value
  }

  setError = (error: string | null = null) => {
    this.error = error
  }

  storeData = (data: SubscriptionsData) => {
    const subscription = data

    const groupedConnectors = groupBy(subscription.connectors, "groupName")
    subscription.connectorsBasic = groupedConnectors.connectorsBasic || []
    subscription.connectorsCustom = groupedConnectors.connectorsCustom || []
    subscription.connectorsPremium = groupedConnectors.connectorsPremium || []
    this.data = data
  }

  storeAvailableOptions = (options: SubscriptionOptionList) => {
    this.availableOption = options
  }
}

export default SubscriptionStore
