import React from "react"
import { observer } from "mobx-react-lite"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"

import styles from "./TechnicalSpecsTable.module.sass"

export const TechnicalSpecsTableHeader: React.FC = observer(() => {
  return (
    <Row>
      <Column as="th" key="property" width="auto" className={styles.header}>
        Property
      </Column>
      <Column
        as="th"
        key="value"
        width="auto"
        justify="center"
        className={styles.header}
      >
        Value
      </Column>
    </Row>
  )
})

export default TechnicalSpecsTableHeader
