import { SolutionCategory } from "@framework/types/solution"

export const solutionCategoryOptions = [
  "general",
  "prospect",
  "sell",
  "learn",
] as const

export const SOLUTION_CATEGORY: SolutionCategory[] = [
  {
    category: "general",
    heading: "Not sure where to start? Start here.",
    description:
      "This workspace is your place for questions about your company's products, processes, compliance, and more.",
  },
  {
    category: "prospect",
    heading: "Ready to build your pipeline? Start here.",
    description:
      "This workspace helps you start the sales process by identifying the right companies and contacts for your products.",
  },
  {
    category: "sell",
    heading: "Boost your pipeline velocity. Start here.",
    description:
      "This workspace provides insights on customers' buying journeys to personalize conversations and speed up deal cycles.",
  },
  {
    category: "learn",
    heading: "Develop your skills or competencies? Start here.",
    description:
      "Stay updated on training materials, skills, and competencies to meet your team's goals — onboard or train here.",
  },
]

export default SOLUTION_CATEGORY
