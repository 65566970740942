import React from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"
import get from "lodash/get"
import { TooltipItem } from "chart.js"

import List from "@components/ui/List/List"
import Text from "@components/ui/Typography/Text"
import { useAppColors } from "@components/theme/AppColorSchemeProvider"
import { DD_MM_YYYY_FORMAT } from "@framework/constants/global"
import Loader from "@components/ui/Loader/BarLoader"
import AreaChart from "@components/ui/AreaChart/AreaChart"
import { options } from "@components/ui/AreaChart/config"
import NotFound from "@components/ui/NotFound/NotFound"
import { useStore } from "@store"

import { useDateRangeFilterContext } from "../DateRangeFilterContext"
import { ChartFractionMetaData } from "./useSearchingTrendChart"
import useUserTrendChartData from "./useUserTrendChart"

import styles from "./SearchingTrendChart.module.sass"

export const UserTrendChart: React.FC = observer(() => {
  const theme = useAppColors()

  const {
    usageAnalyticsStore: { userTrendsStore: store },
  } = useStore()

  const { palette } = theme

  const { dateRange } = useDateRangeFilterContext()

  const period = React.useMemo(
    () => ({
      start: moment(dateRange[0]).format(DD_MM_YYYY_FORMAT),
      end: moment(dateRange[1]).format(DD_MM_YYYY_FORMAT),
    }),
    [dateRange]
  )

  const whiteColor = get(palette, "main-text-100-color", "white")
  const primaryColor = get(palette, "primary-100-color", "gold")
  const greenColor = get(palette, "green-100-color", "gold")
  const gridColor = get(palette, "secondary-80-color", "#ffffff26")

  const chartFractions: ChartFractionMetaData[] = React.useMemo(
    () => [
      {
        label: "DAU",
        color: whiteColor,
        name: "DAU",
        yAxisID: "y1",
      },
      {
        label: "MAU",
        color: primaryColor,
        name: "MAU",
        yAxisID: "y1",
      },
      {
        label: "New Users",
        color: greenColor,
        name: "newUsers",
        yAxisID: "y2",
      },
    ],
    [whiteColor, primaryColor, greenColor]
  )

  const handleLoadData = useUserTrendChartData({
    startDate: period.start,
    endDate: period.end,
    data: store.data,
    fractions: chartFractions,
  })

  const config = React.useMemo(() => {
    return options({
      showAxises: true,
      axisColor: whiteColor,
      gridColor,
      dateFormat: DD_MM_YYYY_FORMAT,
      y1: {
        display: true,
        suggestedMinY: 0,
        suggestedMaxY: 10,
      },
      y2: {
        display: true,
        suggestedMinY: 0,
        suggestedMaxY: 10,
      },
      callbacks: {
        tooltipLabel: renderLabel,
      },
    })
  }, [whiteColor, gridColor])

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Text variant="inherit" color="text100Color" align="start">
          DAU, MAU
        </Text>

        <List direction="row" justify="center" overflow="initial" gutter="24">
          {chartFractions.map((it) => (
            <Text
              className={styles.legend}
              style={
                {
                  "--marker-color": it.color,
                } as React.CSSProperties
              }
              variant="caption2"
              key={it.label}
            >
              {it.label}
            </Text>
          ))}
        </List>

        <Text variant="inherit" color="text100Color" align="end">
          New Users
        </Text>
      </div>

      <div className={styles.body}>
        {store.data.length ? (
          <AreaChart onLoadData={handleLoadData} config={config} />
        ) : store.isLoading ? (
          <Loader size="large" fluid />
        ) : (
          <NotFound>No Data found</NotFound>
        )}
      </div>
    </div>
  )
})

export default UserTrendChart

export const renderLabel = (item: TooltipItem<"line">) => {
  return ` ${String(item.raw)} ${item.dataset.label}`
}
