import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate, useParams } from "react-router-dom"

import { useController } from "@store/index"
import {
  DataConnectorContentGroupName,
  DataConnectorSourceName,
  DataSourceNodeName,
} from "@framework/types/upload"
import useAvatarDataConnectors from "@store/avatar-knowledge/useAvatarDataConnectors"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import DataConnectorCard from "@pages/upload/components/DocumentUploadCard/DataConnectorCard"

import useActiveCategoryContext from "../ActiveDataCategoryContext/useActiveCategoryContext"
import ItemsGrid from "../components/ItemsGrid/ItemsGrid"
import BreadCrumpsIndicator from "./BreadCrumpsContext/BreadCrumpsIndicator"

import styles from "./index.module.sass"

interface Props {}

export const DataConnectors: React.FC<Props> = observer(() => {
  const { groupName } = useParams<{ groupName: string }>()
  const navigate = useNavigate()

  if (groupName == null)
    throw new Error("DataConnectors Pages requires groupName param")

  const category = useActiveCategoryContext()

  const staticDescription = getDataSourceNodeContextProps(
    groupName as DataSourceNodeName
  )

  const collection = useAvatarDataConnectors(category.id, groupName)

  const items = React.useMemo(() => {
    return collection.data.filter(
      (it) => it.connectedFolders != null && it.connectedFolders > 0
    )
  }, [collection.data])

  const { avatarKnowledgeController } = useController()

  React.useEffect(() => {
    avatarKnowledgeController.loadDataConnectorsList(
      category.id,
      groupName as DataConnectorSourceName
    )
  }, [category.id])

  return (
    <>
      <div className={styles.navigation}>
        <BreadCrumpsIndicator />
      </div>

      <ItemsGrid
        total={items.length}
        loading={collection.isLoading}
        unit="connector"
        rowHeight={161}
        isRowLoaded={({ index }) => items[index] != null}
        loadMoreRows={async () => {}}
        renderCard={(idx) => {
          const data = items[idx]
          if (!data) return null
          return (
            <DataConnectorCard
              onClick={() => navigate(data.id)}
              item={data}
              contentItemName={
                staticDescription.contentItemName as DataConnectorContentGroupName
              }
              defaultIcon={staticDescription.icon}
              key={data.id}
            />
          )
        }}
      />
    </>
  )
})

export default DataConnectors
