import React from "react"
import { observer } from "mobx-react-lite"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/SimpleTable/Row"

const APICallHeader: React.FC = observer(() => {
  return (
    <Row>
      <Column as="th" key="user" width="100px">
        Status
      </Column>
      <Column as="th" key="user" width="auto">
        Path
      </Column>
      <Column as="th" key="question" align="start" width="150px">
        Date & Time
      </Column>
      <Column as="th" key="user" width="200px">
        Duration
      </Column>
      <Column as="th" key="dateTime" width="300px">
        API Key
      </Column>
    </Row>
  )
})

export default APICallHeader
