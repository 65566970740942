import React from "react"

const CircleFragment: React.FC<{ className?: string }> = React.memo(
  ({ className }) => (
    <svg
      width="100"
      height="100"
      viewBox="0 0 2820 2910"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g opacity="0.4">
        <g filter="url(#filter0_b_7404_11889)">
          <ellipse
            cx="1136.94"
            cy="908.925"
            rx="1136.94"
            ry="908.925"
            transform="matrix(0.6014 -0.798948 -0.798948 -0.6014 1452.37 2909.97)"
            fill="url(#paint0_linear_7404_11889)"
            fillOpacity="0.08"
          />
        </g>
        <g filter="url(#filter1_b_7404_11889)">
          <ellipse
            cx="825.91"
            cy="660.743"
            rx="825.91"
            ry="660.743"
            transform="matrix(0.6014 -0.798948 -0.798948 -0.6014 1438.56 2510.28)"
            fill="url(#paint1_linear_7404_11889)"
            fillOpacity="0.24"
          />
        </g>
        <g filter="url(#filter2_b_7404_11889)">
          <ellipse
            cx="522.086"
            cy="414.272"
            rx="522.086"
            ry="414.272"
            transform="matrix(0.6014 -0.798948 -0.798948 -0.6014 1449.29 2138.07)"
            fill="url(#paint2_linear_7404_11889)"
            fillOpacity="0.19"
          />
        </g>
        <g filter="url(#filter3_bd_7404_11889)">
          <ellipse
            cx="354.953"
            cy="284.426"
            rx="354.953"
            ry="284.426"
            transform="matrix(0.6014 -0.798948 -0.798948 -0.6014 1461.71 1940.53)"
            fill="url(#paint3_linear_7404_11889)"
            fillOpacity="0.36"
            shapeRendering="crispEdges"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_b_7404_11889"
          x="99.8197"
          y="81.9892"
          width="2620.24"
          height="2745.99"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="156.344" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_7404_11889"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_7404_11889"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_7404_11889"
          x="369.829"
          y="369.971"
          width="2075.06"
          height="2166.15"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="156.344" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_7404_11889"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_7404_11889"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_b_7404_11889"
          x="663.381"
          y="673.182"
          width="1537.81"
          height="1597.25"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="156.344" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_7404_11889"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_7404_11889"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_bd_7404_11889"
          x="823.466"
          y="841.971"
          width="1248.94"
          height="1287.84"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="156.344" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_7404_11889"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="7.5457" />
          <feGaussianBlur stdDeviation="16.0723" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.17582 0 0 0 0 0 0 0 0 0 0.993766 0 0 0 0.29 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_7404_11889"
            result="effect2_dropShadow_7404_11889"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_7404_11889"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_7404_11889"
          x1="-32.1966"
          y1="1929.15"
          x2="2483.04"
          y2="1382.63"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor=" var(--primary-105-color, #956bf0)" />
          <stop
            offset="1"
            stopColor="var(--primary-50-color, #502ba0)"
            stopOpacity="0.73"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7404_11889"
          x1="-288.961"
          y1="1533.99"
          x2="1833.56"
          y2="1110.42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor=" var(--primary-105-color, #956bf0)" />
          <stop
            offset="1"
            stopColor="var(--primary-50-color, #502ba0)"
            stopOpacity="0.55"
          />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7404_11889"
          x1="232.669"
          y1="828.544"
          x2="1175.34"
          y2="559.115"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor=" var(--primary-105-color, #956bf0)" />
          <stop
            offset="1"
            stopColor="var(--primary-50-color, #502ba0)"
            stopOpacity="0.55"
          />
        </linearGradient>
        <linearGradient
          id="paint3_linear_7404_11889"
          x1="158.186"
          y1="568.851"
          x2="800.025"
          y2="387.193"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor=" var(--primary-105-color, #956bf0)" />
          <stop
            offset="1"
            stopColor="var(--primary-50-color, #502ba0)"
            stopOpacity="0.55"
          />
        </linearGradient>
      </defs>
    </svg>
  )
)

export default CircleFragment
