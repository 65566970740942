/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx"
import React from "react"

import { Option } from "@framework/types/utils"

import { Icon } from "../Icon/Icon"

import styles from "./SelectItem.module.sass"

export interface SelectItemProps {
  item: Option
  selected?: boolean
  className?: string
  onSelect?: (item: Option) => void
}

export const SelectItem: React.FC<SelectItemProps> = ({
  item,
  selected = false,
  onSelect,
  className,
}) => (
  <div
    role="button"
    className={clsx(styles.root, className)}
    onMouseDown={onSelect ? () => onSelect(item) : undefined}
  >
    {item.value}
    <Icon name="done" color={selected ? "primary" : "transparent"} />
  </div>
)

export default SelectItem
