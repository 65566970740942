import clsx from "clsx"
import React from "react"

import useId from "@components/hooks/useId"

import styles from "./RichTextarea.module.sass"

interface RichTextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  children?: React.ReactNode
}

const RichTextarea = React.forwardRef<HTMLTextAreaElement, RichTextareaProps>(
  ({ style, children, className, ...props }, ref) => {
    const keyWordsRef = React.useRef<HTMLDivElement | null>()

    const [height, setHeight] = React.useState(0)
    const [width, setWidth] = React.useState(0)

    const id = useId()

    return (
      <label
        htmlFor={id}
        className={clsx(styles.root, className)}
        style={style}
      >
        <div className={styles.container}>
          <div
            ref={(e) => {
              keyWordsRef.current = e
              const rect = e?.getBoundingClientRect()
              setWidth(rect?.width || 0)
              setHeight(rect?.height || 0)
            }}
            className={styles.textContainer}
          >
            {children}
          </div>

          <textarea
            {...props}
            id={id}
            ref={ref}
            className={styles.textarea}
            style={{ height, width }}
          />
        </div>
      </label>
    )
  }
)

export default RichTextarea
