import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import Templates from "@components/ui/Templates"
import BackButton from "@components/prototypes/BackButton"
import { useController } from "@store/index"
import useQuery from "@components/hooks/useQuery"
import { constructComplexUrlWithParams } from "@utils/textUtils"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"

import ArticleSection from "./ArticleSection"
import ArticleListSection from "./ArticleListSection"
import useArticlesCollection from "./hooks/useArticlesCollection"
import { ARTICLE_ID_QUERY_PARAM_KEY } from "./constants"
import useSubtopic from "./hooks/useSubtopic"

import styles from "./index.module.sass"

const SubtopicArticlesPage: React.FC = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()

  const { subtopicId } = useParams<{ subtopicId: string }>()

  const articleId = useQuery().get(ARTICLE_ID_QUERY_PARAM_KEY)

  const { avatarId } = useActiveAvatar()

  if (subtopicId == null)
    throw new Error("Articles page requires subtopicId URL param")

  const { expertInsightsController } = useController()

  const articleCollection = useArticlesCollection(subtopicId)
  const subtopic = useSubtopic(subtopicId)

  const totalArticles = articleCollection.data.length

  const handleSelectArticle = (articleId?: string) =>
    navigate(
      constructComplexUrlWithParams(".", {
        [ARTICLE_ID_QUERY_PARAM_KEY]: articleId,
      }),
      {
        state: location.state,
        replace: true,
      }
    )

  const article = articleId ? articleCollection.getById(articleId) : null

  React.useEffect(() => {
    expertInsightsController.loadSubtopic(avatarId, subtopicId)
    expertInsightsController.loadArticlesList(avatarId, subtopicId)
  }, [avatarId, subtopicId])

  React.useEffect(() => {
    if (totalArticles === 0) handleSelectArticle(undefined)
    else if (articleId == null)
      handleSelectArticle(articleCollection.data[0].id)
  }, [articleCollection.data])

  return (
    <MainLayout>
      <div className={styles.root}>
        <Templates.Header
          className={styles.header}
          left={<BackButton>Subtopic</BackButton>}
        />

        <div className={clsx(styles.body, styles.box)}>
          {subtopic.data == null ? (
            subtopic.isLoading ? (
              <Loader size="large" primary fluid />
            ) : (
              <NotFound>{subtopic.error}</NotFound>
            )
          ) : (
            <>
              <div className={clsx(styles.left, styles.container)}>
                <ArticleListSection
                  avatarId={avatarId}
                  subtopic={subtopic.data}
                  selected={articleId}
                  onSelect={handleSelectArticle}
                />
              </div>

              <div className={clsx(styles.right, styles.container)}>
                {articleId != null && article ? (
                  <ArticleSection
                    avatarId={avatarId}
                    article={article}
                    className={styles.block}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>
    </MainLayout>
  )
})

export default SubtopicArticlesPage
