import React from "react"
import moment from "moment"

import Templates from "@components/ui/Templates"
import UserCard from "@components/ui/UserCard/UserCard"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import { Option } from "@framework/types/utils"
import List from "@components/ui/List/List"
import { BEAUTY_DATE_FORMAT } from "@framework/constants/global"

import styles from "./Meeting.module.sass"

interface MeetingProps {}

export const Meeting: React.FC<MeetingProps> = () => {
  return (
    <Templates.RollScript
      className={styles.root}
      headerSocket={
        <div className={styles.header}>
          <UserCard fullName="Meeting Title" />
        </div>
      }
    >
      <List gutter="16" className={styles.body}>
        <div className={styles.section}>
          <Text variant="h6" weight="bold">
            Meeting Details:
          </Text>
          {infoPoints.map((it) => (
            <div className={styles.infoItem} key={it.name}>
              <Icon name={it.icon} />
              <Text variant="body3">{it.value}</Text>
            </div>
          ))}
        </div>

        <div className={styles.section}>
          <Text variant="h6" weight="bold">
            Discussion Summary:
          </Text>
          <Text variant="body3" color="text70Color">
            {summaryPoints}
          </Text>
        </div>

        <div className={styles.section}>
          <Text variant="h6" weight="bold">
            Decisions Made:
          </Text>
          <Text variant="body3" color="text70Color">
            {decisionPoints}
          </Text>
        </div>
      </List>
    </Templates.RollScript>
  )
}

export default Meeting

const meetingUsers = ["John Smith", "Jane Doe", "Alex Johnson", "Emily Brown"]

const infoPoints: Option[] = [
  {
    icon: "calendar",
    value: moment().format(BEAUTY_DATE_FORMAT),
    name: "date",
  },
  {
    icon: "timer-fill",
    value: `${moment(Date.now()).format("LT")} - ${moment(
      Date.now() + 3600000
    ).format("LT")}`,
    name: "time",
  },
  { icon: "map-pin-fill", value: "Conference Room A", name: "location" },
  { icon: "group-fill", value: meetingUsers.join(", "), name: "members" },
]

const summaryPoints = (
  <ul>
    <li>
      <marker>Introduction and Welcome:</marker> Discussed the purpose of the
      meeting and introduced new team members.
    </li>
    <li>
      <marker>Project Update:</marker> Provided updates on the project status,
      milestones achieved, and upcoming tasks.
    </li>
    <li>
      <marker>Budget Discussion:</marker> Analyzed the project budget, reviewed
      expenses, and discussed cost-saving measures.
    </li>
    <li>
      <marker>Client Feedback Review:</marker> Reviewed client feedback received
      and identified action points for improvement.
    </li>
  </ul>
)

const decisionPoints = (
  <ul>
    <li>Approved the revised project timeline.</li>
    <li>
      Agreed to allocate additional resources for a specific project phase.
    </li>
    <li>Approved the proposed changes based on client feedback.</li>
  </ul>
)
