import React from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"

import Text from "@components/ui/Typography/Text"
import { countSuffix } from "@utils/numberUtils"
import { ExpertTopicDTO } from "@framework/types/knowledge"

import styles from "./index.module.sass"

interface TopicMetaProps {
  data: ExpertTopicDTO
}

export const TopicMeta: React.FC<TopicMetaProps> = observer(({ data }) => {
  const totalSubtopics = Number(data.subTopicCount || 0)

  const bullets = [
    <Text variant="caption2" inline key="subtopics">
      {totalSubtopics} Subtopic{countSuffix(totalSubtopics)}
    </Text>,
  ]

  if (data.lastUpdatedTime != null)
    bullets.push(
      <Text variant="caption2" inline key="lastUpdate">
        {moment(data.lastUpdatedTime).fromNow()}
      </Text>
    )

  return (
    <div className={styles.meta}>
      {bullets.map((it, idx) => (
        <>
          {idx !== 0 ? " • " : null} {it}
        </>
      ))}
    </div>
  )
})

export default TopicMeta
