import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import {
  AutoSizer,
  InfiniteLoader,
  List,
  ListRowProps,
} from "react-virtualized"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import { WebsiteSourceName } from "@framework/types/upload"
import { useStore } from "@store/index"
import BackButton from "@components/prototypes/BackButton"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import Templates from "@components/ui/Templates"
import NotFound from "@components/ui/NotFound/NotFound"
import Loader from "@components/ui/Loader/BarLoader"

import { SelectDataTypeContextProvider } from "../PreselectDataTypeContext"
import ConnectWebsitesButton from "../DataSourceConnections/ConnectWebsitesButton"
import WebsiteRow from "./WebsiteRow"
import WebsitesTableHeader from "./WebsitesTableHeader"
import WebsitesCollectionControlBar from "./WebsitesCollectionControlBar"

import styles from "./UploadedFilesPage.module.sass"

const BATCH_SIZE = 24

export interface WebsitesPageProps {
  sourceName: WebsiteSourceName
  backPath: string
}

const WebsitesPage: React.FC<WebsitesPageProps> = observer(
  ({ sourceName, backPath }) => {
    const navigate = useNavigate()

    const {
      restrictionsStore: access,
      uploadStore: { connectedWebsitesCollections: store },
    } = useStore()

    const sourceTypeDescription = getDataSourceNodeContextProps(sourceName)

    const renderRow = ({ index, key, style }: ListRowProps) => {
      return (
        <div style={{ ...style, padding: 2 }} key={key}>
          <WebsiteRow index={index} />
        </div>
      )
    }

    const isReadonly = !access.canEditDataSource

    const collection = store.state

    const { itemName } = sourceTypeDescription

    return (
      <MainLayout>
        <div className={styles.root}>
          <section className={styles.header}>
            <Templates.Header
              left={
                <BackButton onClick={() => navigate(backPath)}>
                  {sourceTypeDescription.label}
                </BackButton>
              }
              right={
                isReadonly ? null : (
                  <SelectDataTypeContextProvider>
                    <ConnectWebsitesButton sourceName={sourceName} />
                  </SelectDataTypeContextProvider>
                )
              }
            />
          </section>

          <section className={styles.control}>
            <WebsitesCollectionControlBar />
          </section>

          <section className={styles.body}>
            <WebsitesTableHeader />

            <div className={styles.tableBody}>
              {collection.total ? (
                <InfiniteLoader
                  isRowLoaded={(it) => store.getByIndex(it.index) != null}
                  loadMoreRows={store.load}
                  rowCount={collection.total}
                  minimumBatchSize={BATCH_SIZE}
                  threshold={BATCH_SIZE}
                >
                  {({ onRowsRendered, registerChild }) => (
                    <AutoSizer>
                      {({ width, height }) => (
                        <List
                          rowCount={collection.total}
                          ref={registerChild}
                          onRowsRendered={onRowsRendered}
                          rowHeight={60}
                          rowRenderer={renderRow}
                          height={height}
                          width={width}
                        />
                      )}
                    </AutoSizer>
                  )}
                </InfiniteLoader>
              ) : collection.isLoading ? (
                <Loader size="huge" fluid />
              ) : (
                <NotFound>
                  {store.state.errorMessage || `No ${itemName}s found`}
                </NotFound>
              )}
            </div>
          </section>
        </div>
      </MainLayout>
    )
  }
)

export default WebsitesPage
