import React from "react"
import { useLocalStore } from "mobx-react-lite"

import { BreadCrumpsStore, BreadCrumpsContext } from "./index"

export interface BreadCrumpsRootProps {
  name?: string
  defaultLabel?: string
  link: string
  render?: () => React.ReactNode
}

export const BreadCrumpsRoot: React.FC<BreadCrumpsRootProps> = ({
  children,
  ...rest
}) => {
  const context = useLocalStore(() => ({
    level: 0,
    store: new BreadCrumpsStore({
      name: "root",
      ...rest,
    }),
  }))

  return (
    <BreadCrumpsContext.Provider value={context}>
      {children}
    </BreadCrumpsContext.Provider>
  )
}

export default BreadCrumpsRoot
