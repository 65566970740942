import { makeAutoObservable } from "mobx"

import {
  CreateContentSourceParams,
  KibanaConnector,
  ConnectorFilter,
  KibanaConnectorInfo,
  ConnectorDocument,
  SyncJobInfo,
  ManualUploadFileInfo,
} from "@framework/types/content-manager"
import { QueryFilterData } from "@framework/types/query-filter"
import { PaginationListMeta } from "@framework/types/utils"

class ContentManagerStore {
  filters: Record<string, ConnectorFilter[]> = {}

  contentTypes: QueryFilterData[] = []

  contentSources: QueryFilterData[] = []

  kibanaConnectorAccounts: KibanaConnector[] = []

  kibanaConnectorsCount: KibanaConnectorInfo[] | null = null

  createdContentSource: CreateContentSourceParams | null = null

  documentsList: ConnectorDocument[] = []

  syncJobs: SyncJobInfo[] = []

  documentsMeta: PaginationListMeta | null = null

  loadingKibanaConnectorAccounts = false

  loadingKibanaConnectorsCount = false

  loadingCreatedContentSource = false

  loadingDocumentsList = false

  loadingSyncJobs = false

  loadingUpdateContentTypes = false

  error: string | null = null

  uploads: Record<string, ManualUploadFileInfo> = {}

  constructor() {
    makeAutoObservable(this)
  }

  addFilter = (key: string, value: ConnectorFilter) => {
    if (!this.filters[key]) {
      this.filters[key] = []
    }
    this.filters[key].push(value)
  }

  removeFilter = (key: string, option: ConnectorFilter) => {
    if (this.filters[key]) {
      this.filters[key] = this.filters[key].filter(
        (item) => item.value !== option.value
      )
      if (this.filters[key].length === 0) {
        delete this.filters[key]
      }
    }
  }

  clearFilters = () => {
    this.filters = {}
  }

  getFilter = (key: string): ConnectorFilter[] => {
    return this.filters[key] || []
  }

  getAvailableFilterOptions = (): ConnectorFilter[] => {
    const data: ConnectorFilter[] = [
      {
        label: "Content Source",
        value: "content-source",
        options: this.contentSources?.map(
          (item) =>
            ({
              label: item.name,
              value: item.id,
            } as ConnectorFilter)
        ),
      },
      {
        label: "Content Types",
        value: "content-types",
        options: this.contentTypes?.map(
          (item) =>
            ({
              label: item.name,
              value: item.id,
            } as ConnectorFilter)
        ),
      },
    ]

    return data
  }

  addUpload = (id: string, uploadInfo: ManualUploadFileInfo) => {
    this.uploads[id] = uploadInfo
  }

  updateUpload = (id: string, updateInfo: ManualUploadFileInfo) => {
    if (this.uploads[id]) {
      this.uploads[id] = { ...this.uploads[id], ...updateInfo }
    }
  }
}

export default ContentManagerStore
