import React from "react"
import clsx from "clsx"

import styles from "./Pair.module.sass"

export interface PairProps extends React.HTMLProps<HTMLDivElement> {}

export const Pair: React.FC<PairProps> = ({ children, className, ...rest }) => {
  const [left, right] = React.Children.toArray(children)
  return (
    <div className={clsx(styles.root, className)} {...rest}>
      {left} {right}
    </div>
  )
}

export default Pair
