import React from "react"
import { observer } from "mobx-react-lite"
import { useLocation, useNavigate } from "react-router-dom"

import { useStore } from "@store/index"
import { CollapsibleContextProvider } from "@components/ui/Collapsable/CollapsibleContext"
import mainRoutes from "@root/main.routes"

import SearchRoot from "../search/SearchRoot"

const HomeRoot: React.FC = observer(() => {
  const location = useLocation()
  const navigate = useNavigate()
  const { restrictionsStore: access } = useStore()

  const isAfterLogin = location.state?.reason === "LOGIN"

  React.useEffect(() => {
    if (!isAfterLogin || !access.shouldOpenSolutionOnLogin) return

    navigate(mainRoutes.home(), {
      replace: true,
      state: { action: "OPEN_SINGLE_SOLUTION" },
    })
  }, [])

  return (
    <CollapsibleContextProvider>
      <SearchRoot />
    </CollapsibleContextProvider>
  )
})

export default HomeRoot
