import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAlert } from "react-alert"

import { useController, useStore } from "@store/index"
import { Option } from "@framework/types/utils"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import { DataSourceName } from "@framework/types/upload"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import { capitalizeFirstLetter } from "@utils/textUtils"
import useEvent from "@components/hooks/useEvent"
import mainRoutes from "@root/main.routes"

import styles from "./index.module.sass"

const useCloudFoldersContextMenu = (
  connectorSourceName: DataSourceName,
  dataConnectorId: string,
  dataTypeId: string | undefined,
  sourceName: DataSourceName,
  isReadonly = false,
  reloadCallback?: () => void
) => {
  const alert = useAlert()
  const navigate = useNavigate()
  const location = useLocation()

  const reassignModal = useModal(ModalsTypes.REASSIGN_FILE_TO_DATA_TYPE_MODAL)

  const { restrictionsStore: access } = useStore()

  const { dataConnectorController } = useController()

  const sourceTypeDescription = getDataSourceNodeContextProps(sourceName)

  const handleOpenFolder = (folderId: string) => {
    navigate(
      mainRoutes.upload([
        connectorSourceName,
        dataConnectorId,
        "files",
        folderId,
      ]),
      {
        state: { from: location.pathname },
      }
    )
  }

  const handleDeleteFolder = async (folderId: string) => {
    const error = await dataConnectorController.deleteDataConnectorFolder(
      folderId
    )

    if (error) {
      alert.error(`Failed to remove ${sourceTypeDescription.itemName}`)
      return
    }

    alert.success(
      `${capitalizeFirstLetter(
        sourceTypeDescription.itemName
      )} was successfully removed`
    )

    reloadCallback?.()
  }

  const handleEditAvatars = (id: string) => {
    reassignModal.showModal({
      sourceId: id,
      sourceName,
      initialDataTypeId: dataTypeId,
      title: "Change Date Type",
      onSubmit: reloadCallback,
    })
  }

  const handleContext = useEvent((id: string, name: string) => {
    if (name === "open") handleOpenFolder(id)
    if (name === "edit_data_type") handleEditAvatars(id)
    if (name === "remove") handleDeleteFolder(id)
  })

  const options: Option[] = React.useMemo(() => {
    const res: Option[] = [
      {
        name: "open",
        value: "Open",
        icon: "format_list_bulleted",
      },
    ]

    if (isReadonly) return res

    if (access.canAssignDataToAvatar)
      res.push({
        name: "edit_data_type",
        value: "Add or Change Date Type",
        icon: "edit",
      })

    if (access.canDeleteDataSource)
      res.push({
        name: "remove",
        value: "Remove",
        icon: "trash-can",
        className: styles.redMenuItem,
      })

    return res
  }, [access.canDeleteDataSource, access.canAssignDataToAvatar, isReadonly])

  return React.useMemo(
    () => ({
      options,
      onContext: handleContext,
    }),
    [options, handleContext]
  )
}

export default useCloudFoldersContextMenu
