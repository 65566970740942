import React from "react"
import SpeechRecognition from "react-speech-recognition"

const speechRecognizer = SpeechRecognition.getRecognition()

export const useSpeechRecognizer = ({
  onChange,
  onResult,
}: {
  onChange?: (newValue: string) => void
  onResult?: () => void
}) => {
  const [inProgress, setInProgress] = React.useState<boolean>(false)
  const [currentValue, setCurrentValue] = React.useState<string>("")

  const startRecording = () => {
    if (speechRecognizer == null) return

    speechRecognizer.continuous = false
    speechRecognizer.lang = "en"
    speechRecognizer.start()
  }

  const stopRecording = () => {
    if (speechRecognizer == null) return

    speechRecognizer.stop()
  }

  const toggle = (value: boolean = !inProgress) => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition) return
    if (speechRecognizer == null) return

    if (value === inProgress) return

    if (value) startRecording()
    else stopRecording()
  }

  React.useEffect(() => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) return undefined
    if (speechRecognizer == null) return undefined

    // handlers

    const handleResult = (e: SpeechRecognitionEvent) => {
      const value = [...e.results].map((it) => it[0].transcript).join()
      setCurrentValue(value)
      onChange?.(value)
    }

    const handleStart = () => {
      setInProgress(true)
      setCurrentValue("")
      onChange?.("")
    }

    const handleEnd = () => {
      setInProgress(false)
      onResult?.()
      speechRecognizer.abort()
    }

    // subscriptions

    speechRecognizer.addEventListener("start", handleStart)
    speechRecognizer.addEventListener("result", handleResult)
    speechRecognizer.addEventListener("end", handleEnd)

    return () => {
      speechRecognizer.removeEventListener("start", handleStart)
      speechRecognizer.removeEventListener("result", handleResult)
      speechRecognizer.removeEventListener("end", handleEnd)

      stopRecording()
    }
  }, [])

  return {
    toggle,
    listening: inProgress,
    transcript: currentValue,
  }
}

export default useSpeechRecognizer
