import React from "react"
import { useAlert } from "react-alert"

import { useController, useStore } from "@store/index"
import { Option } from "@framework/types/utils"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import { CloudEntity, DataConnectorSourceName } from "@framework/types/upload"
import useEvent from "@components/hooks/useEvent"

import styles from "./index.module.sass"

const useCloudFolderEntityContextMenu = (
  connectorSourceName: DataConnectorSourceName,
  dataConnectorId: string,
  dataTypeId: string | undefined,
  entity: CloudEntity | null,
  isReadonly = false,
  reloadCallback?: () => void
) => {
  const alert = useAlert()

  const reassignModal = useModal(ModalsTypes.REASSIGN_FILE_TO_DATA_TYPE_MODAL)

  const { restrictionsStore: access, uploadStore } = useStore()

  const store = uploadStore.getCloudEntitiesStore(
    connectorSourceName,
    dataConnectorId
  )

  const { dataConnectorController } = useController()

  const handleOpenEntity = () => {
    if (entity?.type === "dir") store.openDirectory(entity.name)
  }

  const handleDeleteEntity = async () => {
    if (!entity?.id) return

    const error = await dataConnectorController.deleteCloudEntity(entity?.id)

    if (error) {
      alert.error(`Failed to remove`)
      return
    }

    alert.success(`Removed successfully`)

    reloadCallback?.()
  }

  const handleEditAvatars = (id: string) => {
    reassignModal.showModal({
      sourceId: id,
      connectorSourceName,
      sourceName: entity?.type === "dir" ? "folders" : "file",
      initialDataTypeId: dataTypeId,
      assignOnly: true,
      title: "Change Date Type",
      onSubmit: reloadCallback,
    })
  }

  const handleContext = useEvent((id: string, name: string) => {
    if (name === "open") handleOpenEntity()
    if (name === "edit_data_type") handleEditAvatars(id)
    if (name === "remove") handleDeleteEntity()
  })

  const options: Option[] = React.useMemo(() => {
    const res: Option[] = []

    if (entity == null) return res

    if (entity?.type === "dir") {
      res.push({
        name: "open",
        value: "Open",
        icon: "format_list_bulleted",
      })
    }

    if (isReadonly) return res

    if (access.canAssignDataToAvatar)
      res.push({
        name: "edit_data_type",
        value: "Add or Change Date Type",
        icon: "edit",
      })

    if (access.canDeleteDataSource)
      res.push({
        name: "remove",
        value: "Remove",
        icon: "trash-can",
        className: styles.redMenuItem,
      })

    return res
  }, [access.canDeleteDataSource, access.canAssignDataToAvatar, isReadonly])

  return React.useMemo(
    () => ({
      options,
      onContext: handleContext,
    }),
    [options, handleContext]
  )
}

export default useCloudFolderEntityContextMenu
