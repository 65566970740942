import React, { useCallback, useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import throttle from "lodash/throttle"
import isEqual from "lodash/isEqual"
import moment from "moment"

import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import usePagination from "@components/ui/Pagination/usePagination"
import FilterSidebar from "@components/prototypes/FilterSidebar"
import Pagination from "@components/ui/Pagination/Pagination"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import { ModalsTypes } from "@components/modals/constants"
import NotFound from "@components/ui/NotFound/NotFound"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import useModal from "@components/modals/useModal"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"
import Icon from "@components/ui/Icon/Icon"
import { QueryHistoryFilters } from "@framework/types/analytics"
import { KnowledgeQuestion } from "@framework/types/question"
import { PaginationParams } from "@framework/types/utils"
import { useController, useStore } from "@store/index"
import FilterButton from "@components/ui/Button/FilterButton"
import { countActiveFilters } from "@utils/filters"
import { DD_MM_YYYY_FORMAT } from "@framework/constants/global"

import QueryHistoryTable from "../components/QueryHistoryTable/QueryHistoryTable"
import FilterFormBody from "../components/FilterForm"
import { useDateRangeFilterContext } from "../components/DateRangeFilterContext"

import styles from "./QueryHistoryWidget.module.sass"

export interface QueryHistoryWidgetProps {}

const QueryHistoryWidget: React.FC<QueryHistoryWidgetProps> = observer(() => {
  const alert = useAlert()

  const {
    knowledgeStore: { assignQuestionToExpertDirectly },
    usageAnalyticsStore: { queryHistoryStore: store },
  } = useStore()

  const { showModal, hideModal } = useModal(
    ModalsTypes.ADD_EXPERTS_TO_QUESTIONS_MODAL
  )

  const { dateRange } = useDateRangeFilterContext()

  const [filters, setFilters] = useState<QueryHistoryFilters>({})

  const {
    analyticsController,
    solutionsController: { loadAllSolutions },
  } = useController()

  const period = React.useMemo(
    () => ({
      start: moment(dateRange[0]).format(DD_MM_YYYY_FORMAT),
      end: moment(dateRange[1]).format(DD_MM_YYYY_FORMAT),
    }),
    dateRange
  )

  const loadHistory = useCallback(
    throttle(
      (meta?: PaginationParams, filters?: QueryHistoryFilters) =>
        analyticsController.loadQueryHistoryData(
          { ...filters, date: period },
          meta
        ),
      1_000
    ),
    [filters, period]
  )

  const { pageSize } = store.meta

  const paginationProps = usePagination({
    pageSize: store.meta.pageSize,
    totalRows: store.meta.total,
  })

  const pageNum = paginationProps.forcePage ?? 0

  useEffect(() => {
    loadHistory({ pageNum, pageSize }, filters)
  }, [loadHistory, filters, pageNum])

  useEffect(() => {
    loadAllSolutions()
  }, [])

  const handleAssignQuestion = ({
    question,
    avatarName,
    avatarId,
  }: KnowledgeQuestion) => {
    showModal({
      avatarId,
      questions: [question],
      onSubmit: async (expertIds: string[]) => {
        const error = await assignQuestionToExpertDirectly(
          avatarName,
          avatarId,
          question,
          expertIds
        )

        if (error) {
          alert.error(error)
          return
        }

        const totalExperts = expertIds.length

        alert.success(
          `Question 1 was successfully assigned to ${
            totalExperts > 1 ? `${totalExperts} experts` : "expert"
          }`
        )
        loadHistory({ pageNum, pageSize }, filters)
        hideModal()
      },
    })
  }

  const handleSearchFiltersChange = (newFilters: QueryHistoryFilters) => {
    const isSame = isEqual(newFilters, filters)
    if (isSame) return
    setFilters(newFilters)
  }

  const appliedFiltersLength = useMemo(
    () => countActiveFilters(filters),
    [filters]
  )

  return (
    <section>
      <Templates.Header
        offsetBottom="middle"
        left={
          <Tooltip
            content={
              <TooltipContainer
                margin="8px"
                color="primary"
                placement="right"
                width="250px"
              >
                <Text variant="inherit">
                  List of all the queries by users. Filter query data as per
                  requirement.
                </Text>
              </TooltipContainer>
            }
            mode="onHover"
          >
            <Text>
              Query History <Icon name="info" inline />
            </Text>
          </Tooltip>
        }
        right={
          <>
            <FilterSidebar<QueryHistoryFilters>
              initialValue={filters}
              onChange={handleSearchFiltersChange}
              targetSlot={({ onToggle, onReset }) => (
                <FilterButton
                  size="big"
                  color="secondary"
                  counter={appliedFiltersLength}
                  onOpen={onToggle}
                  onClean={onReset}
                >
                  Filter
                </FilterButton>
              )}
            >
              <FilterFormBody />
            </FilterSidebar>
          </>
        }
      />

      <LoadingCover isLoading={store.isLoading} className={styles.body}>
        {!store.data.length && !store.isLoading ? (
          <NotFound className={styles.notFound}>No queries found</NotFound>
        ) : (
          <QueryHistoryTable
            rows={store.data}
            onAssignQuestionClick={handleAssignQuestion}
          />
        )}
      </LoadingCover>

      <EntityPageHeaderLayout
        className={styles.pagination}
        right={<Pagination {...paginationProps} />}
      />
    </section>
  )
})

export default QueryHistoryWidget
