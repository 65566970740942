import React from "react"
import { useAlert } from "react-alert"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import Icon from "@components/ui/Icon/Icon"
import QueryAttachment from "@store/search/query-attachment"

import styles from "./FileUploadButton.module.sass"

const AddAttachmentExtensions = [".pdf", ".doc", ".docx", ".ppt", ".pptx"]

type Props = {
  limit: string
  onUpload: (attachment: QueryAttachment) => string | null
}

const FileUploadButton: React.FC<Props> = observer(({ limit, onUpload }) => {
  const alert = useAlert()

  const dialog = useModal(ModalsTypes.UPLOAD_FILES_MODAL)

  return (
    <Button
      size="tiny"
      color="default"
      width="fit-content"
      className={styles.root}
      variant="text"
      before={
        dialog.isOpen ? (
          <Icon name="cross" className={styles.icon} />
        ) : (
          <Icon name="link" className={styles.icon} />
        )
      }
      onClick={() => {
        dialog.showModal({
          title: "Attachment",
          text: `File should be $1. Total file size cannot exceed ${limit}. File cannot have more than 500 pages.`,
          extensions: AddAttachmentExtensions,

          showOnSuccessModal: false,
          onUpload: async (files: File[]) => {
            const attachments = files.map((file) => {
              return new QueryAttachment(file)
            })

            const error = onUpload(attachments[0])

            if (error) alert.error(error)

            return true
          },
        })
      }}
    >
      Attach Optional Requirement Document
    </Button>
  )
})
export default FileUploadButton
