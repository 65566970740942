import React from "react"
import moment from "moment"

import Avatar from "@components/ui/Avatar/Avatar"
import Text from "@components/ui/Typography/Text"

import styles from "./UserCard.module.sass"

export interface UserCardProps {
  name: string
  avatarUrl?: string | null
  prefix?: string | null
  lastUpdated: string
  highlighted?: boolean
  controlSocket?: React.ReactNode
}

export const UserCard: React.FC<UserCardProps> = ({
  name,
  avatarUrl,
  prefix,
  lastUpdated,
  highlighted = false,
  controlSocket,
}) => (
  <div className={styles.root}>
    <Avatar className={styles.avatar} name={name} src={avatarUrl ?? ""} />
    <div className={styles.meta}>
      <Text variant="caption2" className={styles.title}>
        {prefix != null && (
          <Text
            color={highlighted ? "greenColor" : "text50Color"}
            variant="inherit"
            inline
          >
            {prefix}
          </Text>
        )}{" "}
        {name}
      </Text>
      <Text variant="caption3" color="text50Color">
        {moment(lastUpdated).fromNow()}
      </Text>
    </div>

    {controlSocket != null && (
      <div className={styles.control}>{controlSocket}</div>
    )}
  </div>
)

export default UserCard
