import moment from "moment"
import groupBy from "lodash/groupBy"
import keyBy from "lodash/keyBy"

import { YYYY_MM_DD_FORMAT } from "@framework/constants/global"
import { APICallsAmountPointData } from "@framework/types/analytics"
import { initArray } from "@utils/numberUtils"

export const labelsFromPeriod = (
  period: [string, string],
  format: string = YYYY_MM_DD_FORMAT
) => {
  const endMoment = moment(period[1], format)
  const startMoment = moment(period[0], format)
  const points = Math.abs(endMoment.diff(startMoment, "days")) + 1

  return initArray(points, (idx) =>
    endMoment.clone().subtract(idx, "day").format(format)
  )
}

export const transformSearchingTrendData = (
  labels: string[],
  points: APICallsAmountPointData[]
) => {
  const { success: successGroup = [], failure: failureGroup = [] } = groupBy(
    points,
    (it) => it.status
  )

  const successMap = keyBy(successGroup, (it) => it.date)

  const failureMap = keyBy(failureGroup, (it) => it.date)

  return labels.reduce<{
    failure: number[]
    success: number[]
  }>(
    (acc, item) => {
      acc.success.push(successMap[item]?.count ?? 0)
      acc.failure.push(failureMap[item]?.count ?? 0)
      return acc
    },
    {
      failure: [],
      success: [],
    }
  )
}

export type ChartFractionMetaData = {
  name: string
  label: string
  color: string
  borderColor: string
  yAxisID?: string
}
