import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate, useParams } from "react-router-dom"
import {
  AutoSizer,
  InfiniteLoader,
  List,
  ListRowProps,
} from "react-virtualized"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import {
  DataConnectorContentGroupName,
  DataConnectorSourceName,
} from "@framework/types/upload"
import { useStore } from "@store/index"
import BackButton from "@components/prototypes/BackButton"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import NotFound from "@components/ui/NotFound/NotFound"
import Loader from "@components/ui/Loader/BarLoader"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"

import { SelectDataTypeContextProvider } from "../PreselectDataTypeContext"
import ConnectFoldersButton from "../DataSourceConnections/ConnectFoldersButton"
import CloudFolder from "./CloudFolderRow"
import CloudFoldersControlBar from "./CloudFoldersControlBar"
import CloudFoldersTableHeader from "./CloudFoldersTableHeader"
import DataConnectorPickerButton from "./DataConnectorPickerButton"

import styles from "./CloudFoldersPage.module.sass"

const BATCH_SIZE = 24

export interface CloudFoldersPageProps {
  sourceName: DataConnectorContentGroupName
  connectorSourceName: DataConnectorSourceName
  backPath: string
}

const CloudFoldersPage: React.FC<CloudFoldersPageProps> = observer(
  ({ sourceName, backPath, connectorSourceName }) => {
    const navigate = useNavigate()

    const { dataConnectorId } = useParams()

    if (dataConnectorId == null) throw new Error("dataConnectorId is required")

    const { restrictionsStore: access, uploadStore } = useStore()

    const sourceTypeDescription =
      getDataSourceNodeContextProps(connectorSourceName)

    const contentTypeDescription = getDataSourceNodeContextProps(sourceName)

    const store = uploadStore.getCloudFoldersStore(
      connectorSourceName,
      dataConnectorId
    )

    const renderRow = ({ index, key, style }: ListRowProps) => {
      return (
        <div style={{ ...style, padding: 2 }} key={key}>
          <CloudFolder
            index={index}
            dataConnectorId={dataConnectorId}
            connectorSourceName={connectorSourceName}
            sourceName={sourceName}
          />
        </div>
      )
    }

    const isReadonly = !access.canEditDataSource

    const collection = store.state

    return (
      <MainLayout>
        <div className={styles.root}>
          <section className={styles.header}>
            <EntityPageHeaderLayout
              left={
                <BackButton onClick={() => navigate(backPath)}>
                  {sourceTypeDescription.label}
                </BackButton>
              }
              right={
                isReadonly ? null : (
                  <SelectDataTypeContextProvider>
                    <ConnectFoldersButton
                      dataConnectorId={dataConnectorId}
                      sourceName={sourceName}
                      connectorSourceName={connectorSourceName}
                    />
                  </SelectDataTypeContextProvider>
                )
              }
            >
              <DataConnectorPickerButton
                connectorName={connectorSourceName}
                dataConnectorId={dataConnectorId}
              />
            </EntityPageHeaderLayout>
          </section>

          <section className={styles.control}>
            <CloudFoldersControlBar
              connectorSourceName={connectorSourceName}
              dataConnectorId={dataConnectorId}
            />
          </section>

          <section className={styles.body}>
            <CloudFoldersTableHeader />

            <div className={styles.tableBody}>
              {collection.total ? (
                <InfiniteLoader
                  isRowLoaded={(it) => store.getByIndex(it.index) != null}
                  loadMoreRows={store.load}
                  rowCount={collection.total}
                  minimumBatchSize={BATCH_SIZE}
                  threshold={BATCH_SIZE}
                >
                  {({ onRowsRendered, registerChild }) => (
                    <AutoSizer>
                      {({ width, height }) => (
                        <List
                          rowCount={collection.total}
                          ref={registerChild}
                          onRowsRendered={onRowsRendered}
                          rowHeight={60}
                          rowRenderer={renderRow}
                          height={height}
                          width={width}
                        />
                      )}
                    </AutoSizer>
                  )}
                </InfiniteLoader>
              ) : collection.isLoading ? (
                <Loader size="huge" fluid />
              ) : (
                <NotFound>
                  {store.state.errorMessage ||
                    `No ${contentTypeDescription.label}s found`}
                </NotFound>
              )}
            </div>
          </section>
        </div>
      </MainLayout>
    )
  }
)

export default CloudFoldersPage
