import React from "react"

import MenuItem from "@components/containers/Sidebar/MenuItem"
import useToggle from "@components/hooks/useToggle"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"

type ActionName = "edit"

interface RoleContextMenuProps {
  onAction?: (action: ActionName) => void
}

const ContextMenu = React.memo<RoleContextMenuProps>(({ onAction }) => {
  const { isOpened, handleToggle, setOpened } = useToggle()

  const editHandler = (actionName: ActionName) => () => {
    onAction?.(actionName)
  }

  return (
    <Tooltip
      show={isOpened}
      onClose={() => setOpened(false)}
      mode="onFocus"
      justify="end"
      content={
        <TooltipContainer color="primary" placement="bottom" tip="end">
          <MenuItem icon="edit" onClick={editHandler("edit")} key="reset">
            Edit
          </MenuItem>
        </TooltipContainer>
      }
    >
      <IconButton size="medium" active={isOpened} onClick={handleToggle}>
        <Icon name="dots" />
      </IconButton>
    </Tooltip>
  )
})

export default ContextMenu
