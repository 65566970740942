import React, { useEffect } from "react"
import clsx from "clsx"
import { FormikContextType, FormikProps, useFormikContext } from "formik"

import TextInput from "@components/ui/TextInput/TextInput"
import { DigestSubscriptionOption, UserData } from "@framework/types/user"
import { Option } from "@framework/types/utils"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import { UserProfileForm } from "@pages/profile/Profile"
import Text from "@components/ui/Typography/Text"

import styles from "./UserInfoForm.module.sass"

type FormKey = keyof UserData
type FormOption = Option<FormKey>

const fields: FormOption[] = [
  { name: "firstName", value: "First Name" },
  { name: "lastName", value: "Last Name" },
  { name: "jobTitle", value: "Job title" },
  { name: "businessUnit", value: "Business unit" },
  { name: "organization", value: "Organization" },
]

const isFieldEditable = <T extends object>(
  name: string | symbol | number,
  formik: FormikContextType<T>
): name is keyof T => name in formik.values

interface UserInfoFormProps {
  isEdit?: boolean
  user: UserData
  digestSubscriptionOptions: DigestSubscriptionOption[]
  onFormReset?: () => void
}

const UserInfoForm: React.FC<UserInfoFormProps> = ({
  user,
  isEdit = false,
  digestSubscriptionOptions,
  onFormReset,
}) => {
  const formik = useFormikContext<UserProfileForm>()

  useEffect(() => {
    if (!isEdit && onFormReset) onFormReset()
  }, [isEdit])

  const renderField = (
    { name, value: label }: FormOption,
    formik: FormikProps<UserProfileForm>
  ) => (
    <Label label={label} editMode={isEdit}>
      {isFieldEditable(name, formik) && isEdit ? (
        <TextInput
          name={name}
          defaultValue={formik.values[name] as string}
          onChange={formik.handleChange}
          withError={!!formik.errors[name]}
          after={
            formik.errors[name] != null && (
              <ErrorChip
                messagePlacement="left"
                message={formik.errors[name] as string}
              />
            )
          }
        />
      ) : (
        user[name]
      )}
    </Label>
  )

  return (
    <form className={styles.root}>
      {fields.map((field) => renderField(field, formik))}

      <Label label="Digest Subscription" editMode={isEdit}>
        {isEdit && digestSubscriptionOptions.length
          ? digestSubscriptionOptions.map((it) => (
              <CheckboxWithLabel
                disabled={!isEdit}
                label={it.name}
                checked={formik.values.digestSubscriptionFrequencyIds?.includes(
                  it.id
                )}
                onCheck={() =>
                  formik.setFieldValue("digestSubscriptionFrequencyIds", [
                    it.id,
                  ])
                }
                key={it.id}
              />
            ))
          : user.digestSubscriptions
              ?.map((it) => it.digestSubscriptionFrequency?.name ?? "Unknown")
              ?.join(", ")}
      </Label>
    </form>
  )
}

interface LabelProps {
  label: string
  editMode?: boolean
}

const Label: React.FC<LabelProps> = ({ label, children, editMode = false }) => (
  <div className={clsx(styles.card, { [styles.infoCard]: !editMode })}>
    <Text variant="h6" color="text50Color">
      {label}
    </Text>
    <Text variant="body1">{children || "-"}</Text>
  </div>
)

export default UserInfoForm
