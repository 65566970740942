import React from "react"
import { observer } from "mobx-react-lite"
import { Navigate, RouteProps, Outlet } from "react-router-dom"

import { useStore } from "@store/index"
import mainRoutes from "@root/main.routes"
import { DataSourceName } from "@framework/types/upload"

export type SecureDataSourceOutletProps = RouteProps & {
  source?: DataSourceName | DataSourceName[]
}

export const SecureDataSourceOutlet: React.FC<SecureDataSourceOutletProps> =
  observer(({ source }) => {
    const {
      restrictionsStore: { testDataSourceAccess },
    } = useStore()

    if (source && !testDataSourceAccess(source))
      return <Navigate to={mainRoutes.upload()} replace />

    return <Outlet />
  })

export default SecureDataSourceOutlet
