import React from "react"
import { observer } from "mobx-react-lite"
import { useLocation } from "react-router-dom"

import Templates from "@components/ui/Templates"
import SidebarPopup from "@components/modals/components/SidebarPopup"

import DataSourceHeader from "../KnowledgeSource/DataSourceHeader"
import ActiveAvatarContextProvider from "../ActiveAvatarContext"
import Chat, { ChatMessage } from "./Chat"
import ChatsCollection from "./ChatsCollection"

import styles from "./Sidebar.module.sass"

export interface ChatsSidebarProps {
  open: boolean
  onClose?: () => void
}

export const ChatsSidebar: React.FC<ChatsSidebarProps> = observer(
  ({ open = false, onClose }) => {
    const location = useLocation()

    return (
      <ActiveAvatarContextProvider avatarId={location.state?.avatar}>
        <SidebarPopup open={open} onClose={onClose}>
          <Templates.RollScript
            className={styles.root}
            headerSocket={
              <DataSourceHeader title="Chats" chips={defaultChips} />
            }
          >
            <div className={styles.body}>
              <ChatsCollection />
              <Chat messages={chatMock} />
            </div>
          </Templates.RollScript>
        </SidebarPopup>
      </ActiveAvatarContextProvider>
    )
  }
)

export default ChatsSidebar

const defaultChips = ["Informal Knowledge"]

const chatMock: ChatMessage[] = [
  {
    message:
      "Hey, have you heard back from the client regarding the proposal we sent last week? Im eager to know their response and discuss next steps. Let me know if you have any updates. Thanks!",
    fromMe: false,
    moment: Date.now(),
  },
  {
    message:
      "I just checked my inbox, and unfortunately, we haven't received a response from the client yet.",
    fromMe: true,
    moment: Date.now(),
  },
  {
    message:
      "Oh, that's disappointing. I was hoping to hear back by now. Do you think we should follow up with them again?",
    fromMe: false,
    moment: Date.now(),
  },
  {
    message:
      "I think it would be a good idea to follow up with the client. I'll draft a polite email and send it to them today. I'll keep you updated on any progress or response we receive.",
    fromMe: true,
    moment: Date.now(),
  },
  {
    message: "ok",
    fromMe: false,
    moment: Date.now(),
  },

  {
    message:
      "Hey, have you heard back from the client regarding the proposal we sent last week? Im eager to know their response and discuss next steps. Let me know if you have any updates. Thanks!",
    fromMe: false,
    moment: Date.now() + 86400000,
  },
  {
    message:
      "I just checked my inbox, and unfortunately, we haven't received a response from the client yet.",
    fromMe: true,
    moment: Date.now() + 86400000,
  },
  {
    message:
      "Oh, that's disappointing. I was hoping to hear back by now. Do you think we should follow up with them again?",
    fromMe: false,
    moment: Date.now() + 86400000,
  },
  {
    message:
      "I think it would be a good idea to follow up with the client. I'll draft a polite email and send it to them today. I'll keep you updated on any progress or response we receive.",
    fromMe: true,
    moment: Date.now() + 86400000,
  },
  {
    message: "ok",
    fromMe: false,
    moment: Date.now() + 86400000,
  },

  {
    message:
      "Hey, have you heard back from the client regarding the proposal we sent last week? Im eager to know their response and discuss next steps. Let me know if you have any updates. Thanks!",
    fromMe: false,
    moment: Date.now() + 86400000 * 2,
  },
  {
    message:
      "I just checked my inbox, and unfortunately, we haven't received a response from the client yet.",
    fromMe: true,
    moment: Date.now() + 86400000 * 2,
  },
  {
    message:
      "Oh, that's disappointing. I was hoping to hear back by now. Do you think we should follow up with them again?",
    fromMe: false,
    moment: Date.now() + 86400000 * 2,
  },
  {
    message:
      "I think it would be a good idea to follow up with the client. I'll draft a polite email and send it to them today. I'll keep you updated on any progress or response we receive.",
    fromMe: true,
    moment: Date.now() + 86400000 * 2,
  },
  {
    message: "ok",
    fromMe: false,
    moment: Date.now() + 86400000 * 2,
  },
]
