import clsx from "clsx"
import React from "react"

import NotFoundIllustration from "./NotFoundIllustration"

import styles from "./NotFound.module.sass"

const DEFAULT_MESSAGE = "Not found"

export interface NotFoundProps {
  children?: React.ReactNode
  retryButton?: React.ReactNode
  className?: string
  fluid?: boolean
}

export const NotFound: React.FC<NotFoundProps> = ({
  children = DEFAULT_MESSAGE,
  retryButton,
  className,
  fluid = false,
}) => (
  <div className={clsx(styles.root, { [styles.fluid]: fluid }, className)}>
    <NotFoundIllustration />

    <div>{children}</div>

    {retryButton != null && <div>{retryButton}</div>}
  </div>
)

export default NotFound
