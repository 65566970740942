import React, { useMemo, useState } from "react"
import { observer } from "mobx-react-lite"
import { Formik } from "formik"
import * as yup from "yup"
import { useAlert } from "react-alert"

import { Option } from "@framework/types/utils"
import { useStore } from "@store/index"
import TextInput from "@components/ui/TextInput/TextInput"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import Switch from "@components/ui/Switch/Switch"
import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import Templates from "@components/ui/Templates"
import DataTypeLink from "@components/prototypes/DataType/DataTypeLink"

import SitemapForm from "./SitemapForm"
import SubPageGroupsForm from "./SubpageGroupsFrom"
import WebsiteListForm from "./WebsiteListForm"
import {
  baseFormValidationShape,
  ConnectWebsiteFormType,
  getValidationScheme,
  initialBaseForm,
  initialForm,
  WebsiteConnectWayType,
} from "./types"
import { useSelectDataTypeContext } from "../PreselectDataTypeContext"

import styles from "./ConnectWebsite.module.sass"

export const formOptions: Option<WebsiteConnectWayType>[] = [
  { name: "sitemap", value: "Sitemaps" },
  { name: "subPages", value: "Include all Subpages" },
  { name: "custom", value: "Include Specific Subpages" },
]

export interface ConnectWebsiteFormProps {
  title: string
  dataTypeId?: string
  onSubmit?: () => void
}

export const ConnectWebsiteForm: React.FC<ConnectWebsiteFormProps> = observer(
  ({ title, onSubmit }) => {
    const alert = useAlert()

    const {
      uploadSourceStore: { isWebsiteConnecting, connectWebsite },
    } = useStore()

    const { dataTypeId } = useSelectDataTypeContext()

    const loading = false

    const [subFormName, setSubFormName] = useState(formOptions[0].name)

    const handleSubmit = async (form: ConnectWebsiteFormType) => {
      const errorMessage = await connectWebsite({
        ...initialForm,
        ...form,
        knowledgeDataTypeId: dataTypeId,
      })

      if (errorMessage) {
        alert.error(errorMessage)
        return
      }

      onSubmit?.()
    }

    const validationSchema = useMemo(
      () =>
        yup.object().shape({
          ...baseFormValidationShape,
          ...getValidationScheme(subFormName),
        }),
      [subFormName]
    )

    return (
      <Formik
        initialValues={initialBaseForm}
        validationSchema={validationSchema}
        validateOnBlur
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form className={styles.root} onSubmit={formik.handleSubmit}>
            <ModalTitle titleText={title} />

            <Templates.RollScript headerSocket={<DataTypeLink />}>
              <div className={styles.container}>
                <TextInput
                  className={styles.input}
                  name="name"
                  placeholder="Paste your website link"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  withError={!!formik.touched.name && !!formik.errors.name}
                  after={
                    formik.touched.name && formik.errors.name ? (
                      <div className={styles.after}>
                        <ErrorChip
                          messagePlacement="left"
                          message={formik.errors.name}
                        />
                      </div>
                    ) : undefined
                  }
                />

                <div className={styles.body}>
                  <Switch
                    asTabs
                    checked={subFormName}
                    items={formOptions}
                    onChange={setSubFormName}
                  />

                  <div className={styles.formContainer}>
                    {subFormName === "sitemap" && <SitemapForm />}
                    {subFormName === "subPages" && <SubPageGroupsForm />}
                    {subFormName === "custom" && <WebsiteListForm />}

                    <Button
                      type="submit"
                      color="primary"
                      disabled={loading || isWebsiteConnecting}
                    >
                      Save {(loading || isWebsiteConnecting) && <Loader />}
                    </Button>
                  </div>
                </div>
              </div>
            </Templates.RollScript>
          </form>
        )}
      </Formik>
    )
  }
)

export default ConnectWebsiteForm
