import clsx from "clsx"
import React from "react"

import styles from "./SideListItem.module.sass"

interface SideListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  className?: string
  active?: boolean
}

const SideListItem: React.FC<SideListItemProps> = ({
  active,
  className,
  children,
  ...rest
}) => (
  <li
    className={clsx(
      styles.root,
      {
        [styles.active]: active,
      },
      className
    )}
    {...rest}
  >
    {children}
  </li>
)

export default SideListItem
