import React from "react"

import { ContextMenuContext } from "./ContextMenuContext"

const useContextMenu = () => {
  const context = React.useContext(ContextMenuContext)

  if (context == null) throw new Error("Can't find ContextMenu context")

  return context
}

export default useContextMenu
