import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"

import ProgressBar from "@components/ui/ProgressBar/ProgressBar"
import { DocumentIconType, isDocumentIconName } from "@framework/types/utils"
import { DataSourceFile } from "@framework/types/upload"
import { getExtension } from "@utils/textUtils"
import BaseDataSourceCard from "@components/prototypes/BaseDataSourceCard/BaseDataSourceCard"
import ErrorFooter from "@components/prototypes/BaseDataSourceCard/ErrorFooter"
import { IconName } from "@components/ui/Icon/IconName"
import Text from "@components/ui/Typography/Text"

interface UploadingDataSourceCardProps {
  item: DataSourceFile
  defaultIcon?: DocumentIconType | IconName
  control?: React.ReactNode
  onClick?: (id: string, e: React.MouseEvent) => void
  onRetry?: () => void
}

const DataSourceFileCard: React.FC<UploadingDataSourceCardProps> = observer(
  ({ item, defaultIcon = "global", control, onRetry, onClick }) => {
    const { id, name, completePercentage, error, lastUpdatedAt, status } = item

    const handleClick = useMemo(
      () => (onClick ? (e: React.MouseEvent) => onClick(id, e) : undefined),
      [id, onClick]
    )
    const ext = `img:${getExtension(name)}` ?? ""
    const icon = isDocumentIconName(ext) ? ext : defaultIcon

    const renderContent = () => {
      if (status === "failed")
        return (
          <ErrorFooter message={error || "Unknown error"} onRetry={onRetry} />
        )

      if (status === "running") {
        const progress =
          completePercentage != null ? Number(completePercentage) : 0
        return <ProgressBar value={progress} isDone={progress >= 100} />
      }

      return (
        <Text variant="caption3" color="text50Color">
          Last updates: {moment(lastUpdatedAt).format("YYYY-MM-DD")}
        </Text>
      )
    }

    return (
      <BaseDataSourceCard
        title={name}
        icon={icon}
        content={renderContent()}
        onClick={handleClick}
        control={control}
        key={id}
      />
    )
  }
)

export default DataSourceFileCard
