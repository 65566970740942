import React from "react"
import { observer, useLocalObservable } from "mobx-react-lite"
import throttle from "lodash/throttle"
import clsx from "clsx"

import TextInput from "@components/ui/TextInput/TextInput"
import Icon from "@components/ui/Icon/Icon"
import Templates from "@components/ui/Templates"
import Text from "@components/ui/Typography/Text"
import { countSuffix } from "@utils/numberUtils"
import useSubtopicsCollection from "@pages/expert-insights/Topics/hooks/useSubtopicsCollection"
import { useController } from "@store/index"
import { SearchContextStore } from "@components/prototypes/SearchContext"
import List from "@components/ui/List/List"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"

import SubtopicPreviewCard from "./SubtopicPreviewCard"

import styles from "./SubtopicsList.module.sass"

export interface SubtopicListProps {
  avatarId: string
  topicId: string
  className?: string
  selected?: string
  onSelect?: (id: string) => void
}

const SubtopicsList: React.FC<SubtopicListProps> = observer(
  ({ avatarId, topicId, selected, className, onSelect }) => {
    const collection = useSubtopicsCollection(topicId)

    const { expertInsightsController } = useController()

    const searchContext = useLocalObservable(() => new SearchContextStore())

    const loadSubtopics = React.useCallback(
      throttle((query: string) => {
        expertInsightsController.loadSubtopicList(
          avatarId,
          topicId,
          query,
          "view"
        )
      }, 1_000),
      [avatarId, topicId]
    )

    React.useEffect(() => {
      loadSubtopics(searchContext.query)
    }, [searchContext.query, loadSubtopics])

    const totalSubtopics = collection.data.length

    return (
      <Templates.RollScript
        className={clsx(styles.root, className)}
        gutter="16"
        headerSocket={
          <TextInput
            className={styles.block}
            placeholder="Search"
            before={<Icon name="search" />}
            value={searchContext.query}
            onChange={(e) => searchContext.setQuery(e.target.value)}
            after={
              searchContext.query ? (
                <Icon name="cross" onClick={() => searchContext.setQuery("")} />
              ) : null
            }
          />
        }
      >
        <Templates.RollScript
          className={styles.root}
          headerSocket={
            <Templates.Header
              offsetBottom="medium"
              left={
                <Text variant="h6" className={styles.block}>
                  {totalSubtopics} {`subtopic${countSuffix(totalSubtopics)}`}
                </Text>
              }
            />
          }
        >
          <div className={styles.collection}>
            {!collection.data.length ? (
              collection.isLoading ? (
                <Loader primary size="large" fluid />
              ) : (
                <NotFound>No Subtopics found</NotFound>
              )
            ) : (
              <List gutter="8" overflow="initial">
                {collection.data.map((subtopic) => {
                  const isActive = selected === subtopic.id
                  return (
                    <SubtopicPreviewCard
                      active={isActive}
                      onClick={() => onSelect?.(subtopic.id)}
                      data={subtopic}
                      key={subtopic.id}
                    />
                  )
                })}
              </List>
            )}
          </div>
        </Templates.RollScript>
      </Templates.RollScript>
    )
  }
)

export default SubtopicsList
