import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx"
import { toStream } from "mobx-utils"
import {
  from,
  map,
  ObservableInput,
  retry,
  Subscription,
  switchMap,
  tap,
  timer,
} from "rxjs"
import moment from "moment"

import {
  APICallsHistoryData,
  APICallsHistoryFilters,
} from "@framework/types/analytics"
import analyticsService from "@services/analytics.service"
import { PaginationParams } from "@framework/types/utils"
import { DD_MM_YYYY_FORMAT } from "@framework/constants/global"
import { FailResponse, SuccessResponse } from "@framework/types/common"

type Filter = APICallsHistoryFilters

const PULLING_DELAY = 120_000
const RETRY_DELAY = 30_000
const DEFAULT_CHUNK_SIZE = 10

type State = {
  isLoading: boolean

  error: string | null

  query: string

  filter: Filter

  data: APICallsHistoryData[]

  requestedFrame: PaginationParams

  meta: PaginationParams & {
    total: number
  }

  reloadTrigger: boolean
}

export default class ApiUsageHistoryStore {
  state: State

  private loadingStream$?: Subscription

  constructor() {
    this.state = {
      isLoading: false,

      error: null,

      query: "",

      filter: {},

      data: [],

      requestedFrame: { pageNum: 0, pageSize: DEFAULT_CHUNK_SIZE },

      meta: { pageNum: 0, pageSize: DEFAULT_CHUNK_SIZE, total: 0 },

      reloadTrigger: false,
    }

    makeAutoObservable(this)

    onBecomeObserved(this.state, "data", () => {
      this.loadingStream$ = this.initStream()
    })

    onBecomeUnobserved(this.state, "data", () => {
      this.loadingStream$?.unsubscribe()
    })
  }

  get validQuery() {
    return this.state.query.trim()
  }

  initStream = () => {
    runInAction(() => {
      this.state.query = ""
      this.state.filter = {}
      this.state.requestedFrame.pageNum = 0
    })

    return from(
      toStream(
        () => [
          this.validQuery,
          this.state.filter,
          this.state.requestedFrame,
          this.state.reloadTrigger,
        ],
        true
      ) as ObservableInput<[string, Filter, PaginationParams, boolean]>
    )
      .pipe(
        tap(() =>
          runInAction(() => {
            this.state.isLoading = true
            this.state.error = null
          })
        ),

        switchMap(([query, filter, pagination]) => {
          const { date, ...restFilter } = filter
          return analyticsService
            .loadAPIUsageHistory$(
              query,
              {
                pageNum: pagination.pageNum + 1,
                pageSize: pagination.pageSize,
              },
              {
                startDate:
                  date != null
                    ? moment(date?.start, DD_MM_YYYY_FORMAT)
                        .startOf("day")
                        .toString()
                    : undefined,
                endDate:
                  date != null
                    ? moment(date?.end, DD_MM_YYYY_FORMAT)
                        .endOf("day")
                        .toString()
                    : undefined,
                ...restFilter,
              }
            )
            .pipe(
              map((response) => ({
                response: response.data,
                filter,
                pagination,
              }))
            )
        }),

        tap(({ response, pagination }) =>
          runInAction(() => {
            this.state.data = response.data

            this.state.meta = {
              ...pagination,
              total: Number(response.meta.total),
              pageNum: Number(response.meta.pageNum) - 1,
              pageSize: Number(response.meta.pageSize),
            }

            this.state.isLoading = false
          })
        ),

        retry({
          delay: () => {
            runInAction(() => {
              this.state.error = "Loading Failed"
              this.state.isLoading = false
              this.state.data = []
            })
            return timer(RETRY_DELAY)
          },
        }),

        switchMap(() => timer(PULLING_DELAY)),

        tap(() => this.refresh())
      )
      .subscribe()
  }

  search = (query: string) => {
    this.state.query = query
  }

  setPage = (pageNum: number) => {
    this.state.requestedFrame = { ...this.state.requestedFrame, pageNum }
  }

  setFilter = (period: Filter) => {
    this.state.filter = { ...period }
  }

  refresh = () => {
    this.state.reloadTrigger = !this.state.reloadTrigger
  }

  getAPIHistoryDataAsBlob = async (): Promise<
    SuccessResponse<Blob> | FailResponse
  > => {
    try {
      const { date, ...restFilter } = this.state.filter
      const response = await analyticsService.downloadAPIUsageHistory(
        this.validQuery,
        {
          startDate:
            date != null
              ? moment(date.start, DD_MM_YYYY_FORMAT).format(DD_MM_YYYY_FORMAT)
              : undefined,
          endDate:
            date != null
              ? moment(date.end, DD_MM_YYYY_FORMAT).format(DD_MM_YYYY_FORMAT)
              : undefined,
          ...restFilter,
        }
      )

      return {
        data: response.data,
        status: "SUCCESS",
      }
    } catch (e) {
      return {
        message: "Unexpected Error while generating file",
        status: "FAILED",
      }
    }
  }
}
