import React from "react"
import { observer } from "mobx-react-lite"
import debounce from "lodash/debounce"

import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import usePagination from "@components/ui/Pagination/usePagination"
import FilterSidebar from "@components/prototypes/FilterSidebar"
import Pagination from "@components/ui/Pagination/Pagination"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import NotFound from "@components/ui/NotFound/NotFound"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"
import Icon from "@components/ui/Icon/Icon"
import { APICallsHistoryFilters } from "@framework/types/analytics"
import { useStore } from "@store/index"
import FilterButton from "@components/ui/Button/FilterButton"
import TextInput from "@components/ui/TextInput/TextInput"
import useSearch from "@components/hooks/useSearch"
import { countActiveFilters } from "@utils/filters"

import APICallsHistoryTable from "./APICallsHistoryTable"
import FilterFormBody from "./APIHistoryFilterForm"
import ExportAPILogsDataButton from "./ExportAPILogsDataButton"

import styles from "./APICallsHistory.module.sass"

const TITLE = "API Logs"
const DESCRIPTION =
  "List of all the API calls. Filter or export the API log data as per requirement."

export interface APICallsHistoryProps {}

const APICallsHistory: React.FC<APICallsHistoryProps> = observer(() => {
  const { apiUsageAnalyticsStore } = useStore()

  const store = apiUsageAnalyticsStore.usageHistoryStore

  const paginationProps = usePagination({
    pageSize: store.state.meta.pageSize,
    totalRows: store.state.meta.total,
    onPageChange: store.setPage,
  })

  const handleSearch = React.useCallback(debounce(store.search, 500), [])

  const [searchProps] = useSearch({
    onChange: handleSearch,
    value: store.state.query,
  })

  const appliedFiltersLength = React.useMemo(
    () => countActiveFilters(store.state.filter),
    [store.state.filter]
  )

  return (
    <section>
      <Templates.Header
        offsetBottom="middle"
        left={
          <Tooltip
            content={
              <TooltipContainer
                margin="8px"
                color="primary"
                placement="right"
                width="250px"
              >
                <Text variant="inherit">{DESCRIPTION}</Text>
              </TooltipContainer>
            }
            mode="onHover"
          >
            <Text>
              {TITLE}&nbsp;
              <Icon name="info" inline />
            </Text>
          </Tooltip>
        }
        right={
          <>
            <TextInput
              before={<Icon name="search" />}
              after={
                !!searchProps.value && (
                  <Icon name="cross" onClick={() => store.search("")} />
                )
              }
              placeholder="Search by path..."
              className={styles.searchInput}
              {...searchProps}
            />

            <FilterSidebar<APICallsHistoryFilters>
              initialValue={store.state.filter}
              onChange={store.setFilter}
              targetSlot={({ onToggle, onReset }) => (
                <FilterButton
                  size="big"
                  color="secondary"
                  counter={appliedFiltersLength}
                  onOpen={onToggle}
                  onClean={onReset}
                >
                  Filter
                </FilterButton>
              )}
            >
              <FilterFormBody />
            </FilterSidebar>

            <ExportAPILogsDataButton />
          </>
        }
      />

      <LoadingCover isLoading={store.state.isLoading} className={styles.body}>
        {!store.state.data.length && !store.state.isLoading ? (
          <NotFound className={styles.notFound}>No logs found</NotFound>
        ) : (
          <APICallsHistoryTable rows={store.state.data} />
        )}
      </LoadingCover>

      <EntityPageHeaderLayout
        className={styles.pagination}
        right={<Pagination {...paginationProps} />}
      />
    </section>
  )
})

export default APICallsHistory
