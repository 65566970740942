import without from "lodash/without"
import { makeAutoObservable, reaction } from "mobx"

export class LocalFavoriteList {
  constructor(options: { localStoreKey: string }) {
    if (!options.localStoreKey)
      throw new Error("Invalid localStoreKey provided")

    this.localStoreKey = options.localStoreKey

    this.restore()

    reaction(() => this.favorites, this.store)

    makeAutoObservable(this)
  }

  localStoreKey: string

  favorites: string[] = []

  get isFavorite() {
    const { favorites } = this
    return (id: string) => favorites.includes(id)
  }

  store = () => {
    localStorage.setItem(this.localStoreKey, JSON.stringify(this.favorites))
  }

  restore = () => {
    try {
      const localStr = localStorage.getItem(this.localStoreKey)

      if (!localStr) throw new Error("Not local record found")

      const res = JSON.parse(localStr)

      if (!Array.isArray(res))
        throw new Error("Incorrect format of local record")

      this.favorites = res
    } catch (error) {
      this.favorites = []
    }
  }

  toggleFavorite = (id: string) => {
    this.favorites = this.isFavorite(id)
      ? without(this.favorites, id)
      : [...this.favorites, id]
  }
}

export default LocalFavoriteList
