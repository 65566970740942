import moment from "moment"
import { ChartOptions, TooltipItem } from "chart.js"

import { YYYY_MM_DD_FORMAT } from "@framework/constants/global"

import "chartjs-adapter-moment"

type Props = {
  showAxises?: boolean
  axisColor?: string
  gridColor?: string
  dateFormat?: string
}

export const options = (props: Props = {}): ChartOptions<"bar"> => {
  const { dateFormat = YYYY_MM_DD_FORMAT } = props
  return {
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 5,
        right: 0,
        top: 5,
        bottom: 0,
      },
    },
    elements: {
      point: {
        radius: 0,
        hitRadius: 0,
      },
    },
    interaction: {
      intersect: false,
      axis: "x",
      mode: "nearest",
    },
    scales: {
      x: {
        stacked: true,

        type: "time",
        time: {
          unit: "day",
          tooltipFormat: dateFormat,
          parser: dateFormat,
        },
        ticks: {
          display: props?.showAxises,
          color: props?.axisColor ?? "white",
          maxRotation: 0,
          maxTicksLimit: 15,
          padding: 10,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y1: {
        stacked: true,

        ticks: {
          display: props.showAxises,
          color: props.axisColor ?? "white",
          backdropColor: props.axisColor ?? "white",
          maxTicksLimit: 8,
          padding: 15,
          crossAlign: "far",
          font: {
            size: 14,
          },
        },

        grid: {
          display: true,
          drawTicks: false,
          drawBorder: false,
          borderDash: [8, 10],
          color: props?.gridColor,
        },
      },
    },
    plugins: {
      tooltip: {
        axis: "x",
        mode: "nearest",
        backgroundColor: "white",
        usePointStyle: true,
        titleColor: "#2E2C4D99",
        footerColor: "#2E2C4D99",
        callbacks: {
          title: (it: TooltipItem<"bar">[]) => {
            return moment(it[0]?.label, dateFormat).format("MMM DD, YYYY")
          },
          labelColor: (e) => {
            const { borderColor = "red" } = e.dataset

            const labelColor =
              typeof borderColor === "string" ? borderColor : "#2E2C4D99"

            return {
              backgroundColor: labelColor,
              borderColor: "#2E2C4D99",
              borderWidth: 1,
            }
          },
          labelPointStyle: () => ({ pointStyle: "circle", rotation: 0 }),
          labelTextColor: () => "black",
        },
      },
    },
  }
}

export default { options }
