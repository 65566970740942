import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import { useStore } from "@store/index"
import LoadingPlaceholder from "@components/security/LoadingPlaceholder"
import mainRoutes from "@root/main.routes"

const GuestLogin: React.FC = observer(() => {
  const navigate = useNavigate()
  const { authStore } = useStore()

  useEffect(() => {
    const guestLogin = async () => {
      await authStore.guestLogin(document.referrer)
      navigate(mainRoutes.loginConfirm())
    }

    guestLogin()
  }, [])

  return <LoadingPlaceholder />
})

export default GuestLogin
