import React from "react"
import throttle from "lodash/throttle"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import List from "@components/ui/List/List"
import TextInput from "@components/ui/TextInput/TextInput"
import useSearch from "@components/hooks/useSearch"
import Icon from "@components/ui/Icon/Icon"
import Breadcrumbs from "@components/ui/Breadcrumbs/Breadcrumbs"

export const CloudEntitiesListControlHeader: React.FC<{
  dataConnectorId: string
}> = observer(({ dataConnectorId }) => {
  const { uploadStore } = useStore()

  const store = uploadStore.externalEntitiesStores.getStore(dataConnectorId)

  const handleSearch = React.useCallback(throttle(store.search, 500), [
    store.search,
  ])

  const [searchProps, searchHelpers] = useSearch({
    value: store.state.query,
    onChange: handleSearch,
  })

  const handleClickPath = (path: string[]) => {
    store.setPath(path.join("/"))
  }

  const breadCrumps = store.state.path.split("/")

  return (
    <List gutter="24">
      <TextInput
        {...searchProps}
        before={<Icon name="search" />}
        after={
          !!searchProps.value && (
            <Icon name="cross" onClick={() => searchHelpers.setQuery("")} />
          )
        }
        placeholder="Search"
      />

      <Breadcrumbs items={breadCrumps} onClick={handleClickPath} />
    </List>
  )
})

export default CloudEntitiesListControlHeader
