import React from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import Breadcrumbs from "@components/ui/Breadcrumbs/Breadcrumbs"
import { DataConnectorSourceName } from "@framework/types/upload"

export const CloudEntitiesExplorerControlHeader: React.FC<{
  dataConnectorId: string
  connectorSourceName: DataConnectorSourceName
}> = observer(({ dataConnectorId, connectorSourceName }) => {
  const { uploadStore } = useStore()

  const store = uploadStore.getCloudEntitiesStore(
    connectorSourceName,
    dataConnectorId
  )

  const handleClickPath = (path: string[]) => {
    store.setPath(path.join("/"))
  }

  const breadCrumps = store.state.path.split("/")

  return <Breadcrumbs items={breadCrumps} onClick={handleClickPath} />
})

export default CloudEntitiesExplorerControlHeader
