import React from "react"

import Chip, { ChipColorType } from "@components/ui/Chip/Chip"
import { Option } from "@framework/types/utils"
import { ServiceStatus, ServiceStatusType } from "@framework/types/app"
import { capitalizeFirstLetter } from "@utils/textUtils"

import styles from "./StatusCard.module.sass"

const statusToColor = (
  status: ServiceStatusType
): Option<string, ChipColorType> => {
  if (status === "up") return { name: "Online", value: "green" }
  if (status === "down") return { name: "Offline", value: "red" }
  return { name: "Unknown", value: "gold" }
}

interface StatusCardProps {
  item: ServiceStatus
}

const StatusCard: React.FC<StatusCardProps> = ({ item }) => {
  const { name, status, error, uptime } = item

  const statusMapper = statusToColor(status)
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h3 className={styles.title}>{capitalizeFirstLetter(name)}</h3>
        <Chip variant="rounded" color={statusMapper.value}>
          {statusMapper.name}
        </Chip>
      </div>
      <div className={styles.body}>
        {error ? (
          <ul className={styles.issuesList}>
            <li>
              <span className={styles.issue}>Error: </span> {error}
            </li>
          </ul>
        ) : (
          <span className={styles.issue}>no issues</span>
        )}
        <span className={styles.uptime}>uptime: {uptime}</span>
      </div>
    </div>
  )
}

export default StatusCard
