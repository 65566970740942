import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { observer } from "mobx-react-lite"

import CollapsibleContextProvider from "@components/ui/Collapsable/CollapsibleContext"
import { useController, useStore } from "@store/index"
import ActiveAvatarContextProvider from "@pages/search/ActiveAvatarContext"
import AvatarScopeContextProvider from "@pages/search/AvatarScopeContext"
import mainRoutes from "@root/main.routes"
import { WorkFlowType } from "@framework/types/solution"
import PresetEditor from "@pages/launch-solutions/SolutionTabsContainer/components/Tabs/UnifiedMatrix/PresetEditor"

import LaunchSolutions from "../launch-solutions/LaunchSolutions"
import ProductPage from "../product/Product"

const ProductSolutionsRoot: React.FC = observer(() => {
  const {
    userSetupStore: { avatarId, setAvatarId },
  } = useStore()

  const { documentChatSolutionController, factFinderSolutionController } =
    useController()

  React.useEffect(() => {
    // TODO think over this logic
    documentChatSolutionController.reset()
    factFinderSolutionController.reset()
  }, [avatarId])

  return (
    <ActiveAvatarContextProvider avatarId={avatarId}>
      <AvatarScopeContextProvider avatarId={avatarId} onChange={setAvatarId}>
        <CollapsibleContextProvider>
          <Routes>
            <Route
              path="/"
              element={<Navigate to={mainRoutes.home()} replace />}
            />

            <Route path="products/:id" element={<ProductPage />} />

            <Route
              path={`${WorkFlowType.WORKBOOK}/edit/:solutionId`}
              element={<PresetEditor />}
            />

            <Route path=":solutionType/*" element={<LaunchSolutions />} />
          </Routes>
        </CollapsibleContextProvider>
      </AvatarScopeContextProvider>
    </ActiveAvatarContextProvider>
  )
})

export default ProductSolutionsRoot
