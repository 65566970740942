import React from "react"

import { KnowledgeQuestion } from "@framework/types/question"
import { QueryHistoryData } from "@framework/types/analytics"
import Table from "@components/ui/BaseTable/Table"

import QueryHistoryTableHeader from "./QueryHistoryTableHeader"
import QueryHistoryTableRow from "./QueryHistoryTableRow"

import styles from "./QueryHistoryTable.module.sass"

interface QueryHistoryProps {
  rows: QueryHistoryData[]
  onAssignQuestionClick: (
    question: KnowledgeQuestion & { avatarName: string }
  ) => void
}

const QueryHistoryTable: React.FC<QueryHistoryProps> = React.memo(
  ({ rows, onAssignQuestionClick }) => {
    return (
      <Table header={<QueryHistoryTableHeader />} className={styles.root}>
        {rows.map((query) => {
          const avatarName = query.channelName
          return (
            <QueryHistoryTableRow
              key={query.id}
              queryInfo={query}
              onAssignQuestionClick={
                avatarName
                  ? () =>
                      onAssignQuestionClick({
                        id: query.id,
                        question: query.question,
                        avatarName,
                        avatarId: query.avatarId,
                      })
                  : undefined
              }
            />
          )
        })}
      </Table>
    )
  }
)

export default QueryHistoryTable
