import React, { MouseEventHandler, useMemo, useState } from "react"
import clsx from "clsx"

import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import CheckboxCard from "@components/ui/CheckboxCard/CheckboxCard"
import { Option } from "@framework/types/utils"

import Dropdown from "../Dropdown/Dropdown"

import styles from "./OptionGroup.module.sass"

export interface OptionGroupProps {
  label?: string
  options: Option[]
  value?: string[] | string
  className?: string
  containerClassName?: string
  onChange?: (value?: string[] | string) => void
  multiple?: boolean
}

export const OptionGroup: React.FC<OptionGroupProps> = ({
  label = "Options",
  options,
  className,
  containerClassName,
  onChange,
  multiple = false,
  value = [],
}) => {
  const [open, setOpen] = useState(false)

  const fieldValue = useMemo(
    () => (Array.isArray(value) ? [...value] : [value]),
    [value]
  )

  const handleChange = (checkedValue: string) => {
    if (!onChange) return
    if (multiple) {
      if (!Array.isArray(value)) return
      const foundIdx = fieldValue.findIndex((value) => value === checkedValue)
      if (foundIdx === -1) {
        onChange([...fieldValue, checkedValue])
        return
      }

      fieldValue.splice(foundIdx, 1)
      onChange([...fieldValue])
    } else {
      onChange(checkedValue === value ? undefined : checkedValue)
    }
  }

  const checkedCount = options.filter((option) => !option.isDisabled)?.length

  const isAnySelected = fieldValue.length > 0
  const isPartSelected = isAnySelected && fieldValue.length !== checkedCount

  const handleSelectAll: MouseEventHandler = (e) => {
    e.stopPropagation()

    if (onChange) {
      if (multiple) {
        const allValues = options
          .filter((option) => !option.isDisabled)
          .map(({ name }) => name)

        if (fieldValue.length === allValues.length) {
          onChange([])
          return
        }
        if (!fieldValue.length) {
          onChange(allValues)
        }
      }

      if (isAnySelected) {
        onChange(undefined)
      } else {
        setOpen(true)
      }
    }
  }

  return (
    <Dropdown
      title={label}
      className={className}
      containerClassName={clsx(styles.checkboxList, containerClassName)}
      before={
        <CheckboxWithLabel
          checked={isAnySelected}
          partial={multiple ? isPartSelected : false}
          onClick={handleSelectAll}
        />
      }
      opened={open}
      onClick={() => setOpen((value) => !value)}
    >
      <>
        {options.map((item) => {
          const isItemChecked = fieldValue.includes(item.name)
          return (
            <CheckboxCard
              label={item.value}
              className={styles.checkbox}
              onCheck={() => handleChange(item.name)}
              checked={isItemChecked || item.isDisabled}
              disabled={item.isDisabled}
              withIcon={false}
              key={item.name}
            />
          )
        })}
      </>
    </Dropdown>
  )
}

export default OptionGroup
