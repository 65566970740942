import React from "react"

const ColorconLogo: React.FC<{
  height?: number
  width?: number
  className?: string
}> = ({ width, height, className }) => (
  <svg
    width={width}
    height={height}
    className={className}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 2197.8 576.6"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1706.3,69.5c-206.6,0-374.7,45.4-374.7,102.4c0,54.1,168,99.5,374.7,99.5c207.6,0,374.7-45.4,374.7-99.5C2080.9,114.9,1913.9,69.5,1706.3,69.5L1706.3,69.5z"
    />
    <path
      strokeWidth={2.8015}
      strokeMiterlimit={33.89}
      fill="#CC2229"
      stroke="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1705.4,83v87.8h-324.5C1382.9,122.1,1526.5,83.2,1705.4,83z"
    />
    <path
      strokeWidth={2.8015}
      strokeMiterlimit={33.89}
      fill="#FFF22D"
      stroke="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1705.4,83v87.8h327.2c-2-48.8-146.1-87.8-326.3-87.8H1705.4z"
    />
    <path
      strokeWidth={2.8015}
      strokeMiterlimit={33.89}
      fill="#00ACEC"
      stroke="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1705.4,257.8v-87h-324.5c0,0.3,0,0.7-0.1,1C1380.9,220.1,1525.2,257.7,1705.4,257.8z"
    />
    <path
      strokeWidth={2.8015}
      strokeMiterlimit={33.89}
      fill="#33A457"
      stroke="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1705.4,257.8v-87h327.2c0,0.3,0.1,0.7,0.1,1c0,48.3-144.8,85.9-326.4,85.9L1705.4,257.8z"
    />
    <path
      fill="currentColor"
      d="M1711,0v165.7h486.7v9.5H1711v159.9h-9.5V175.2h-475.2v-9.5h475.2V0H1711L1711,0z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M264.6,358.2c-7.7-9.6-36.7-66.6-104.3-64.7c-63.7,0-114.9,53.1-114.9,118.8c0,68.6,53.1,126.5,113,120.7c31.9-1.9,68.6-14.5,88.8-41.5l24.1,41.5c-35.7,28-65.7,43.4-113,43.4C72.4,576.5,0,501.2,0,412.3C0,322.5,74.4,253,158.4,253c25.1,0,60.8,4.8,94.6,29.9c28,20.3,35.7,33.8,43.4,48.3L264.6,358.2L264.6,358.2z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M484.7,448c0,45.4-37.7,86.9-86.9,86.9c-51.2,0-85-41.5-85-86.9c0-42.5,33.8-84,85-84S484.7,405.6,484.7,448L484.7,448z M529.2,448c0-69.5-57.9-125.5-131.3-125.5c-75.3,0-130.4,59.9-130.4,125.5c0,60.8,49.2,128.4,130.4,128.4C480.9,576.5,529.2,506.9,529.2,448z M544.6,247.2h47.3v323.5h-47.3V247.2z M823.7,448c0,45.4-37.7,86.9-86.9,86.9c-51.2,0-85-41.5-85-86.9c0-42.5,33.8-84,85-84C789.9,364,823.7,405.6,823.7,448L823.7,448z M869.1,448c0-69.5-58.9-125.5-132.3-125.5c-75.3,0-130.4,59.9-130.4,125.5c0,60.8,49.2,128.4,130.4,128.4C819.8,576.5,869.1,506.9,869.1,448zM885.5,435.5c-1.9-62.8,26.1-102.4,26.1-102.4c9.7-16.4,23.2-31.9,39.6-42.5c11.6-7.7,30.9-19.3,45.4-21.2h3.9v49.3c-39.6,12.5-69.5,54.1-67.6,135.2v116.9h-47.3L885.5,435.5L885.5,435.5z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1154.9,406.5c-7.7-14.5-35.7-42.5-79.2-42.5c-57.9,0-79.2,48.3-79.2,84c0,43.5,26.1,86.9,77.2,86.9c33.8,0,71.5-18.3,83-45.4l20.3,41.5c-31.9,31.9-50.2,43.4-103.3,45.4c-79.2,3.9-124.6-63.7-124.6-128.4c0-65.7,49.2-125.5,126.5-125.5c53.1,0,75.3,16.4,101.4,45.4L1154.9,406.5L1154.9,406.5z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1386.6,448c0,45.4-35.7,86.9-85,86.9c-51.2,0-85-41.5-85-86.9c0-42.5,33.8-84,85-84C1352.8,364,1386.6,405.6,1386.6,448L1386.6,448z M1434,448c0-69.5-58.9-125.5-132.3-125.5c-75.3,0-130.4,59.9-130.4,125.5c0,60.8,49.2,128.4,130.4,128.4C1384.7,576.5,1434,506.9,1434,448L1434,448z M1618.4,570.7V423.9c0-18.4,0-75.3-60.8-75.3c-37.7,0-63.7,26.1-63.7,75.3v146.8h-45.4V314.8h41.5v24.1h1.9c24.1-31.9,59.9-31.9,69.5-31.9c37.7,0,69.5,17.4,86.9,43.5c9.6,17.4,14.5,35.7,14.5,61.8v158.4L1618.4,570.7L1618.4,570.7L1618.4,570.7z M2122.8,87.5c4.9,0,9.6,1.2,14.2,3.7c4.6,2.5,8.3,6.1,10.8,10.7c5.2,8.9,5.2,20,0.1,28.9c-2.5,4.5-6.2,8.3-10.7,10.7c-8.9,5.1-19.9,5.1-28.8,0c-4.5-2.5-8.3-6.2-10.8-10.7c-5.1-9-5.1-20,0.1-28.9c2.6-4.7,6.2-8.2,10.8-10.7C2112.9,88.8,2117.8,87.5,2122.8,87.5z M2122.8,92.3c-4.1,0-8.1,1.1-11.9,3.1c-3.9,2.1-6.9,5.1-9,8.9c-2.2,3.9-3.3,7.9-3.3,12.1c0,4.2,1.1,8.2,3.2,12c2.2,3.8,5.1,6.8,9,9c3.8,2.1,7.8,3.2,12,3.2c4.2,0,8.2-1.1,12-3.2c3.9-2.2,6.8-5.1,8.9-9c4.3-7.5,4.2-16.7-0.1-24.1c-2.2-3.9-5.2-6.9-9-8.9C2131,93.4,2127,92.3,2122.8,92.3L2122.8,92.3z M2110.1,132.5v-31.2h10.7c3.7,0,6.3,0.3,7.9,0.8c3.2,1.2,5.4,4.2,5.4,7.6c0,2.2-0.9,4.4-2.4,5.9c-1.7,1.7-4,2.7-6.5,2.8c1,0.4,1.9,0.9,2.7,1.7c1.2,1.2,2.8,3.3,4.6,6.2l3.8,6.1h-6.2l-2.8-4.9c-2.2-3.9-3.9-6.3-5.2-7.3c-1.2-0.8-2.6-1.2-4-1.1h-3v13.2L2110.1,132.5L2110.1,132.5z M2115.1,115h6.1c2.9,0,4.9-0.4,6-1.3c1.1-0.8,1.6-2.1,1.6-3.5c0-0.9-0.3-1.7-0.8-2.5c-0.5-0.7-1.3-1.3-2.1-1.6c-0.9-0.4-2.6-0.5-5.1-0.5h-5.7L2115.1,115L2115.1,115z"
    />
  </svg>
)

export default ColorconLogo
