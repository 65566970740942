import React from "react"
import { observer } from "mobx-react-lite"

import SidebarPopup from "@components/modals/components/SidebarPopup"
import { useController, useStore } from "@store/index"

import ConnectWebsiteForm from "./ConnectWebsite"

import styles from "./ConnectWebsitesSidebar.module.sass"

export interface ConnectWebsitesSidebarProps {
  open: boolean
  onClose?: () => void
}

export const ConnectWebsitesSidebar: React.FC<ConnectWebsitesSidebarProps> =
  observer(({ open, onClose }) => {
    const { fileUploadingStore } = useStore()
    const { fileUploadingController } = useController()

    React.useEffect(() => {
      if (!open && !fileUploadingStore.isAnyInProgress)
        fileUploadingController.flush()
    }, [open])

    return (
      <SidebarPopup
        keepMounted={false}
        className={styles.root}
        onClose={onClose}
        open={open}
      >
        <ConnectWebsiteForm title="Add new website" onSubmit={onClose} />
      </SidebarPopup>
    )
  })

export default ConnectWebsitesSidebar
