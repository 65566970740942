import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"
import { useController, useStore } from "@store"
import Text from "@components/ui/Typography/Text"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import DataTypes from "./DataTypes"
import useAvatarScope from "../AvatarScopeContext/useAvatarScope"
import ActiveAvatarContextProvider from "../ActiveAvatarContext"
import DataSources from "./DataSources"
import CategoryInfo from "./CategoryInfo"

import styles from "./HomeInfo.module.sass"

const HomeInfo: React.FC = observer(() => {
  const { solutionsStore, restrictionsStore: access } = useStore()
  const { avatar } = useAvatarScope()
  const { solutionsController } = useController()
  const isTablet = useMediaQuery(AppMediaQueries.maxTablet)
  const isMobile = useMediaQuery(AppMediaQueries.maxMobile)

  const { category, availableFilters, isFiltersLoading } = solutionsStore

  useEffect(() => {
    solutionsController.loadFilters()
  }, [])

  const productsCount = availableFilters?.product?.products?.totalCount
  const competitorProductsCount = 0 // update this when we have a competitors product count

  return (
    <div className={styles.sidebar}>
      {!isMobile && category != null && (
        <CategoryInfo
          category={category.category}
          description={category.description}
          heading={category.heading}
        />
      )}
      {(!!productsCount || !!competitorProductsCount) && (
        <div className={styles.section}>
          <Text variant={isTablet ? "h5" : "h3"}>Products</Text>
          <div className={styles.sectionList1}>
            {isFiltersLoading ? (
              <>
                <Skeleton lineHeight={30} count={2} minWidth={100} />
              </>
            ) : (
              <>
                {!!productsCount && (
                  <div className={styles.product}>
                    <div>
                      <Icon className={styles.productIcon} name="box" />
                      <span>My Products</span>
                    </div>
                    <span>{productsCount}</span>
                  </div>
                )}
                {!!competitorProductsCount && (
                  <div className={styles.product}>
                    <div>
                      <Icon className={styles.productIcon} name="storage" />
                      <span>Competitor Products</span>
                    </div>
                    <span>{competitorProductsCount}</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <div className={styles.section}>
        {avatar?.id !== null && (
          <ActiveAvatarContextProvider
            loaderNode={<Skeleton lineHeight={30} count={5} minWidth={100} />}
            avatarId={avatar?.id}
          >
            <DataTypes />
          </ActiveAvatarContextProvider>
        )}
      </div>
      <div className={styles.section}>
        <DataSources />
      </div>
    </div>
  )
})

export default HomeInfo
