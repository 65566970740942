import React from "react"
import ReactCalendar, {
  CalendarProps as ReactCalendarProps,
} from "react-calendar"
import moment from "moment"

import "./Calendar.scss"

export interface CalendarProps extends ReactCalendarProps {}

export const Calendar: React.FC<ReactCalendarProps> = (props) => (
  <ReactCalendar
    className="react-calendar"
    formatMonthYear={(_, date) => moment(date).format("MMMM, yyyy")}
    formatMonth={(_, date) => moment(date).format("MMMM")}
    formatShortWeekday={(_, date) => moment(date).format("dd")}
    {...props}
  />
)

export default Calendar
