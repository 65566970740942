import React from "react"

import Box from "@components/ui/Dropdown/Box"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import List from "@components/ui/List/List"

import FormulaValidationError from "../errors/FormulaValidationError"
import FormulaMarker from "../parser/FormulaMarker"

import styles from "./Tooltip.module.sass"

export const ErrorTooltip: React.FC<{ error: string | Error }> = ({
  error,
}) => {
  const isTablet = useMediaQuery(AppMediaQueries.minTablet)

  return (
    <Box color="primary" className={styles.root}>
      <Text color="redColor" variant={isTablet ? "h4" : "h5"}>
        <Icon name="alert" inline /> Error
      </Text>
      <Text variant="body2" className={styles.message}>
        {typeof error === "string" ? error : <ErrorMessage error={error} />}
      </Text>
    </Box>
  )
}

export default ErrorTooltip

const ErrorMessage: React.FC<{ error: Error }> = ({ error }) => {
  if (error instanceof FormulaValidationError) {
    return (
      <List gutter="8">
        <Text variant="inherit" inline>
          {error.message}
        </Text>
        <List gutter="8" className={styles.section}>
          <Text variant="inherit" inline>
            Function:{" "}
            <FormulaMarker type="func-name">{error.functionName}</FormulaMarker>
          </Text>
          <div className={styles.list}>
            {error.argumentErrors.map((it) => {
              if (!it.error) return null
              return (
                <Text variant="inherit" key={it.name} inline>
                  <FormulaMarker type="argument">
                    [{1 + it.index}] {it.name}
                  </FormulaMarker>
                  {" - "}
                  {it.error}
                </Text>
              )
            })}
          </div>
        </List>
      </List>
    )
  }

  return <>{error.message || "Unexpected Error"}</>
}
