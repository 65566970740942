import React from "react"
import { observer } from "mobx-react-lite"

import List from "@components/ui/List/List"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"
import Icon from "@components/ui/Icon/Icon"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"

import TopActiveUsers from "../components/ActiveUsers/TopActiveUsers"
import SearchingTrendChart from "../components/Chart/SearchingTrendChart"

export interface FeedbackTrendWidgetProps {}

export const FeedbackTrendWidget: React.FC<FeedbackTrendWidgetProps> = observer(
  () => {
    return (
      <section>
        <Templates.Header
          offsetBottom="middle"
          left={
            <Tooltip
              content={
                <TooltipContainer
                  margin="8px"
                  color="primary"
                  placement="right"
                  width="250px"
                >
                  <Text variant="inherit">
                    Day wise trend of number of queries, feedback rating and
                    questions posted to experts.
                  </Text>
                </TooltipContainer>
              }
              mode="onHover"
            >
              <Text>
                Usage Trend <Icon name="info" inline />
              </Text>
            </Tooltip>
          }
        />

        <List direction="row" gutter="24" align="stretch" overflow="initial">
          <SearchingTrendChart />

          <TopActiveUsers />
        </List>
      </section>
    )
  }
)

export default FeedbackTrendWidget
