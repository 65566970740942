import React from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import useSearch from "@components/hooks/useSearch"
import Icon from "@components/ui/Icon/Icon"
import TextInput from "@components/ui/TextInput/TextInput"
import FilterSidebar from "@components/prototypes/FilterSidebar"
import FilterButton from "@components/ui/Button/FilterButton"
import { countActiveFilters } from "@utils/filters"
import useColumns from "@components/hooks/useColumns"
import usePagination from "@components/ui/Pagination/usePagination"
import Templates from "@components/ui/Templates"
import List from "@components/ui/List/List"
import { useMultiStep } from "@components/ui/MultiStep/MultiStepContext"
import { SearchContext } from "@components/prototypes/SearchContext"
import LoadingInfo from "@pages/launch-solutions/SolutionTabsContainer/LoadingInfo"
import { NumberedMultiStepControl } from "@pages/launch-solutions/SolutionTabsContainer/components/Tabs/ProductComparison/NumberedMultiStep"

import ComparisonTable from "../ComparisonTable"
import ProductFilterForm from "./ProductFilterForm"
import { ProductsContext } from "../ProductList/ProductsContext"
import RecommendedProductsList from "../ProductList/RecommendedProductsList"
import OtherProducts from "../ProductList/OtherProductsList"
import SolutionLoader from "../SolutionLoader"

import styles from "./index.module.sass"

type ProductSelectionProps = {}

const ProductSelection: React.FC<ProductSelectionProps> = observer(() => {
  const formStepper = useMultiStep()

  const {
    productComparisonStore: {
      productFilterStore: { applyFilter, selectedFilters },
      selectedProductsStore: { selectedProducts },
      targetProductsStore: productListStore,
      targetProductsViewStore: productsViewStore,
      targetRecommendedProductsStore: recommendedProductsStore,
    },
  } = useStore()

  const products = productListStore.state
  const recommendedProducts = recommendedProductsStore.state

  const [gridRef, columns] = useColumns<HTMLDivElement>({
    minColumnWidth: 250,
    gutter: 24,
    initialValue: products.pagination.pageSize / 2,
  })

  const pageSize = columns > 1 ? columns * 2 : 10

  const pagination = usePagination({
    totalRows: products.total,
    pageSize,
  })

  const [search, searchControl] = useSearch({
    value: products.query,
    onChange: productListStore.search,
  })

  const handleApplyFilter = (data: Record<string, string[]>) => {
    applyFilter(data)
    productListStore.update({
      filters: data,
    })
  }

  const appliedFiltersLength = React.useMemo(() => {
    return countActiveFilters(selectedFilters)
  }, [selectedFilters])

  React.useEffect(() => {
    if (
      products.pagination.pageNum === pagination.forcePage &&
      products.pagination.pageSize === pageSize &&
      products.query === search.value
    )
      return

    productListStore.update({
      query: search.value,
      pagination: {
        pageNum: pagination.forcePage,
        pageSize,
      },
    })
  }, [pagination.forcePage, pageSize])

  const searchContext = React.useMemo(
    () => ({
      query: search.value,
      setQuery: searchControl.setQuery,
    }),
    [search.value]
  )

  const productsContext = React.useMemo(
    () => ({
      productListStore,
      recommendedProductsStore,
      productsViewStore,
    }),
    []
  )

  const handleSubmit = formStepper.next

  const handleBack = formStepper.back

  if (!recommendedProducts.data.length && recommendedProducts.isLoading) {
    return (
      <SolutionLoader title="Competitor Products">
        <LoadingInfo />
      </SolutionLoader>
    )
  }

  return (
    <ProductsContext.Provider value={productsContext}>
      <SearchContext.Provider value={searchContext}>
        <div className={styles.root}>
          <div className={styles.header}>
            <Button onClick={handleBack} variant="outlined" size="medium">
              Back
            </Button>

            <NumberedMultiStepControl />

            <Button onClick={handleSubmit} color="primary" size="medium">
              Next
            </Button>
          </div>

          <div className={styles.productsContainer}>
            <Templates.Header
              left={
                <List gutter="0" overflow="initial">
                  <Text variant="h4">Competitor Products</Text>
                  <Text variant="h5" color="text50Color">
                    Pick few products to start or search for a product
                  </Text>
                </List>
              }
              right={
                <>
                  <TextInput
                    placeholder="Search products"
                    before={<Icon name="search" />}
                    className={styles.filterInput}
                    {...search}
                  />
                  <FilterSidebar<any>
                    initialValue={products.filters}
                    onChange={handleApplyFilter}
                    targetSlot={({ onToggle, onReset }) => (
                      <FilterButton
                        size="big"
                        variant="outlined"
                        onOpen={onToggle}
                        onClean={onReset}
                        counter={appliedFiltersLength}
                      >
                        Filter
                      </FilterButton>
                    )}
                  >
                    <ProductFilterForm />
                  </FilterSidebar>
                </>
              }
            />

            <div className={styles.body} ref={gridRef}>
              {productsViewStore.withRecommended ? (
                <>
                  <RecommendedProductsList />

                  <span className={styles.divider} />
                </>
              ) : null}

              <OtherProducts pageSize={pageSize} />
            </div>
          </div>

          {selectedProducts.length > 0 && (
            <div className={styles.tableContainer}>
              <div className={styles.horizontalScroll}>
                <ComparisonTable />
              </div>
            </div>
          )}
        </div>
      </SearchContext.Provider>
    </ProductsContext.Provider>
  )
})

export default ProductSelection
