import React from "react"

import { BreadCrumpsContext } from "./index"

export const useBreadCrumpsContext = () => {
  const context = React.useContext(BreadCrumpsContext)
  if (context == null) throw new Error("BreadCrumps context not found")
  return context
}

export default useBreadCrumpsContext
