/* eslint-disable react/no-unstable-nested-components */
import { Field, FieldProps } from "formik"
import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store"
import { Option } from "@framework/types/utils"
import OptionsGroup from "@components/prototypes/FilterSidebar/components/OptionGroup/OptionGroup"

const ProductFilterForm: React.FC = observer(() => {
  const {
    productComparisonStore: {
      productFilterStore: { filterTree, getFiltersList },
      targetCompaniesStore: { baseCompany },
    },
  } = useStore()

  const filtersList = useMemo(() => getFiltersList(), [filterTree])

  if (!filtersList) return null

  return (
    <>
      {filtersList.map((filter) => {
        return (
          <Field
            key={filter.id}
            name={filter.id}
            label={filter.name}
            valueKey="id"
          >
            {({ field, form }: FieldProps) => {
              const filterOptions: Option[] = (filter?.values ?? [])
                .filter((val) => val !== baseCompany.name)
                .map((val) => ({
                  id: val,
                  name: val,
                  value: val,
                }))
              return (
                <OptionsGroup
                  label={filter.name}
                  options={filterOptions}
                  value={field.value}
                  onChange={(values) => form.setFieldValue(field.name, values)}
                  multiple
                />
              )
            }}
          </Field>
        )
      })}
    </>
  )
})

export default ProductFilterForm
