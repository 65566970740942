import React from "react"

import { ActiveCategoryContext } from "."

export const useActiveCategoryContext = () => {
  const context = React.useContext(ActiveCategoryContext)
  if (context == null)
    throw new Error("Can't find any ActiveCategory Context Provider")
  return context
}

export default useActiveCategoryContext
