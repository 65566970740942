import React from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import { AvatarData } from "@framework/types/avatar"

export interface AvatarScopeContextData {
  avatar: AvatarData | null
  setAvatar?: (avatarId: string) => void
}

export const AvatarScopeContext =
  React.createContext<AvatarScopeContextData | null>(null)

export type Props = {
  avatarId?: string | null
  onChange?: (avatarId: string) => void
}

export const AvatarScopeContextProvider: React.FC<Props> = observer(
  ({ avatarId, onChange, children }) => {
    const { avatarsStore } = useStore()

    const avatar = avatarId ? avatarsStore.getAvatarById(avatarId) : null

    const context: AvatarScopeContextData = {
      avatar,
      setAvatar: onChange,
    }

    return (
      <AvatarScopeContext.Provider value={context}>
        {children}
      </AvatarScopeContext.Provider>
    )
  }
)

export default AvatarScopeContextProvider
