/* eslint-disable camelcase */
import React, { useEffect, useMemo } from "react"
import { To, useNavigate } from "react-router-dom"
import { useAlert } from "react-alert"
import { observer } from "mobx-react-lite"

import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import MainBoxLayout from "@components/layout/MainBoxLayout/MainBoxLayout"
import { useStore } from "@store/index"
import atlassianService from "@services/atlassian.service"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"

import { OAuthState, useAuthCodeParams } from "./useAuthCodeParams"
import { CONNECT_CONFLUENCE_REDIRECT_URI } from "./constants"
import ConnectorConfluenceForm, {
  FormDataType,
} from "./ConnectorConfluenceForm"

import styles from "./AddDataConnector.module.sass"

const DEFAULT_TITLE = "Connect to Confluence Cloud"
const DEFAULT_REQUIREMENTS = ""

export interface ConnectConfluencePageProps {
  title?: string
  requirements?: string
  backPath: To
}

export const ConnectConfluencePage: React.FC<ConnectConfluencePageProps> =
  observer(
    ({
      title = DEFAULT_TITLE,
      requirements = DEFAULT_REQUIREMENTS,
      backPath,
    }) => {
      const navigate = useNavigate()
      const alert = useAlert()

      const redirectParams = useAuthCodeParams<FormDataType>(
        (form) => form.name != null
      )

      const {
        uploadSourceStore: { isDataConnectorLoading, connectConfluence },
      } = useStore()

      const handleInitConnection = async (form: FormDataType) => {
        window.location.href = atlassianService.getConfluenceAuthCodeLink({
          client_id: form.client_id,
          state: OAuthState.stringify(form),
          redirect_uri: CONNECT_CONFLUENCE_REDIRECT_URI,
        })
      }

      const handleBack = () => navigate(backPath)

      const handleFinalizeConnection = async (redirectParams: any) => {
        const {
          form: { name, client_id, client_secret },
          code,
        } = redirectParams

        const result = await connectConfluence({
          name,
          clientId: client_id,
          clientSecret: client_secret,
          code,
        })

        if (result.error != null) {
          alert.error(result.error)
          navigate(backPath, {
            replace: true,
          })
          return
        }

        if (result.payload?.id) {
          navigate(`${backPath}/${result.payload.id}`, { state: "FIRST_TIME" })
          return
        }

        handleBack()
      }

      useEffect(() => {
        if (redirectParams) handleFinalizeConnection(redirectParams)
      }, [redirectParams])

      const isLoading = isDataConnectorLoading

      const initialValues = useMemo(
        () => ({
          name: redirectParams?.form.name ?? "",
          client_id: redirectParams?.form.client_id ?? "",
          client_secret: redirectParams?.form.client_secret ?? "",
        }),
        [redirectParams]
      )

      return (
        <MainBoxLayout className={styles.root} onBack={handleBack}>
          <DocumentIcon
            className={styles.icon}
            icon="img:confluence"
            alt="confluence"
          />

          <ModalTitle titleText={title} subtitleText={requirements} />

          <ConnectorConfluenceForm
            initialValues={initialValues}
            onSubmit={handleInitConnection}
            isLoading={isLoading}
          />
        </MainBoxLayout>
      )
    }
  )

export default ConnectConfluencePage
