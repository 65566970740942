import { makeAutoObservable } from "mobx"

export default class QueryAttachment {
  readonly file: File

  id: string

  isLoading = false

  sessionId?: string

  constructor(file: File) {
    makeAutoObservable(this)

    this.file = file
    this.id = this.getTempId()
  }

  setLoading(value: boolean) {
    this.isLoading = value
  }

  setId(value: string) {
    this.id = value
  }

  setSessionId(value: string) {
    this.sessionId = value
  }

  getTempId(): string {
    const { file } = this

    // sadly we got limited options here to generate file id
    return [file.name, file.size, file.lastModified].join("-")
  }
}
