import React from "react"

import { TextInputProps } from "@components/ui/TextInput/TextInput"
import Icon from "@components/ui/Icon/Icon"

export const useSearchTextInput = (options?: {
  onChange?: (query: string) => void
}): { query: string; props: TextInputProps } => {
  const [query, setQuery] = React.useState("")

  const handleSearch = (query: string) => {
    setQuery(query)
    options?.onChange?.(query)
  }

  return {
    query,
    props: {
      placeholder: "Search",
      value: query,
      onChange: (e) => handleSearch(e.currentTarget.value),
      before: <Icon name="search" />,
      after: !!query && <Icon name="cross" onClick={() => handleSearch("")} />,
    },
  }
}

export default useSearchTextInput
