import React from "react"
import { observer } from "mobx-react-lite"

import LinkCard from "@components/ui/LinkCard/LinkCard"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import { useController } from "@store/index"
import useAvatarUploadedFilesGroups from "@store/avatar-knowledge/useAvatarUploadedFilesGroups"
import NotFound from "@components/ui/NotFound/NotFound"
import Loader from "@components/ui/Loader/BarLoader"

import BreadCrumpsIndicator from "./BreadCrumpsContext/BreadCrumpsIndicator"
import useActiveCategoryContext from "../ActiveDataCategoryContext/useActiveCategoryContext"

import styles from "./index.module.sass"

export const DataSourceFileGroups: React.FC = observer(() => {
  const category = useActiveCategoryContext()

  const { avatarKnowledgeController } = useController()

  const collection = useAvatarUploadedFilesGroups(category.id)

  const items = React.useMemo(() => {
    return collection.data.filter(
      (it) => it.totalFiles != null && it.totalFiles > 0
    )
  }, [collection.data])

  React.useEffect(() => {
    avatarKnowledgeController.loadUploadedFilesGroups(category.id)
  }, [category.id])

  return (
    <>
      <div className={styles.navigation}>
        <BreadCrumpsIndicator />
      </div>

      {items.length === 0 ? (
        collection.isLoading ? (
          <Loader size="huge" fluid />
        ) : (
          <NotFound />
        )
      ) : (
        <div className={styles.grid}>
          {items.map((it) => {
            const context = getDataSourceNodeContextProps(it.name)
            return (
              <LinkCard
                to={it.name}
                disabled={context.name === "unknown"}
                label={context.viewOnlyForm?.label ?? context.label}
                icon={<DocumentIcon icon={context.icon} />}
                description={
                  context.viewOnlyForm?.description ?? context.description
                }
                key={it.name}
              />
            )
          })}
        </div>
      )}
    </>
  )
})

export default DataSourceFileGroups
