import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import clsx from "clsx"

import { ExpertArticleDTO } from "@framework/types/knowledge"
import List from "@components/ui/List/List"
import Text from "@components/ui/Typography/Text"
import { useController, useStore } from "@store/index"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import Avatar from "@components/ui/Avatar/Avatar"
import { BaseUserData } from "@framework/types/user"

import InsightForm from "./InsightForm"
import InsightsThread from "./InsightsThread"
import useInsightsCollection from "../hooks/useInsightsCollection"

import styles from "./index.module.sass"

export const NEW_INSIGHT_BONUS_MESSAGE = "Add insight and get 5 points"

export interface ArticleSectionProps {
  avatarId: string
  article: ExpertArticleDTO
  className?: string
}

const ArticleSection: React.FC<ArticleSectionProps> = observer(
  ({ avatarId, article, className }) => {
    const alert = useAlert()

    const insights = useInsightsCollection(article.id)

    const { authStore, restrictionsStore: access } = useStore()

    const { expertInsightsController } = useController()

    const deleteInsightModal = useModal(ModalsTypes.CONFIRM_DELETE_MODAL)

    const [editingInsightId, setEditingInsightId] = React.useState<
      string | null
    >(null)

    const editingInsight =
      editingInsightId != null ? insights.getById(editingInsightId) : null

    const startInsightEditing = setEditingInsightId

    const startInsightAdding = () => setEditingInsightId("new")

    const stopInsightEditing = () => setEditingInsightId(null)

    const reloadInsights = async () => {
      return expertInsightsController.loadArticleInsightList(
        avatarId,
        article.id
      )
    }

    const addInsight = async (data: any) => {
      const error = await expertInsightsController.createArticleInsight(
        avatarId,
        article.id,
        data
      )

      if (error) alert.error(error)
      else alert.success("Your insight was successfully added")

      await reloadInsights()

      stopInsightEditing()
    }

    const updateInsight = async (insightId: string, data: any) => {
      const error = await expertInsightsController.updateArticleInsight(
        avatarId,
        article.id,
        insightId,
        data
      )

      if (error) alert.error(error)
      else alert.success("Your insight was successfully updated")

      await reloadInsights()

      stopInsightEditing()
    }

    const handleSubmit = async (data: any) => {
      if (editingInsightId == null) return

      if (editingInsightId === "new") await addInsight(data)
      else await updateInsight(editingInsightId, data)
    }

    const handleDeleteInsight = async (insightId: string) => {
      const error = await expertInsightsController.deleteArticleInsight(
        avatarId,
        article.id,
        insightId
      )

      if (error) alert.error(error)
      else alert.success("Your insight was successfully updated")

      await reloadInsights()
    }

    const getDeleteInsightHandler = async (insightId: string) => {
      deleteInsightModal.showModal({
        message: "Are you sure you want to delete your insight?",
        onSubmit: async () => {
          await handleDeleteInsight(insightId)
          deleteInsightModal.hideModal()
        },
      })
    }

    const currentUser = authStore.userProfile

    const totalCurrentUserInsights = React.useMemo(
      () =>
        insights.data.filter((it) => it.createdBy === currentUser?.id).length,
      [insights.data, currentUser]
    )

    return (
      <List gutter="24" className={clsx(styles.root, className)}>
        <div className={styles.articleContainer}>
          <div className={styles.alert}>{NEW_INSIGHT_BONUS_MESSAGE}</div>

          <div className={styles.body}>
            <Text variant="h4" align="center">
              {article.title}
            </Text>

            {editingInsightId == null && (
              <InsightsThread
                editable
                avatarId={avatarId}
                articleId={article.id}
                hideInsight={editingInsight?.id}
                onEdit={startInsightEditing}
                onDelete={getDeleteInsightHandler}
              />
            )}
          </div>
        </div>

        {editingInsightId != null ? (
          <InsightForm
            defaultValue={editingInsight?.insight}
            onSubmit={handleSubmit}
            onCancel={stopInsightEditing}
            key={editingInsightId}
          />
        ) : (
          access.canCreateExpertArticleInsights &&
          totalCurrentUserInsights === 0 &&
          !insights.isLoading && (
            <EditFormPlaceholder
              userData={currentUser}
              onClick={startInsightAdding}
            />
          )
        )}
      </List>
    )
  }
)

export default ArticleSection

const EditFormPlaceholder: React.FC<
  React.HTMLAttributes<HTMLButtonElement> & {
    userData?: BaseUserData | null
  }
> = ({ userData, className, ...props }) => {
  return (
    <button
      className={clsx(styles.inputPlaceholderCard, className)}
      type="button"
      {...props}
    >
      {userData != null && (
        <Avatar
          name={`${userData.firstName} ${userData.lastName}`}
          src={userData.avatarURL}
        />
      )}
      <Text variant="body2" color="text50Color" inline>
        Add your valuable insights
      </Text>
    </button>
  )
}
