/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import clsx from "clsx"

import List from "@components/ui/List/List"

import styles from "../EntityCard.module.sass"

export interface CardBoxProps {
  contextMenu?: React.ReactNode
  className?: React.ReactNode
  onClick?: React.MouseEventHandler
}

export const CardBox: React.FC<CardBoxProps> = ({
  onClick,
  contextMenu,
  className,
  children,
}) => (
  <div
    className={clsx(styles.root, { [styles.clickable]: !!onClick }, className)}
    onClick={onClick}
  >
    <List gutter="0" overflow="initial">
      {React.Children.toArray(children).map((child, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <section className={styles.section} key={idx}>
          {child}
        </section>
      ))}
    </List>

    {contextMenu && <div className={styles.menu}>{contextMenu}</div>}
  </div>
)

export default CardBox
