import { observer } from "mobx-react-lite"
import React from "react"

import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import { useSearchBlock } from "@pages/search/SearchContext/SearchResultContext"

import styles from "./AnswerSummary.module.sass"

const LoadingMessages: React.FC = observer(() => {
  const { searchEntityBlock } = useSearchBlock(SearchSummaryBlockStore)
  return (
    <div className={styles.loading}>
      <img
        src="/images/nesh_icon.svg"
        alt="Logo icon"
        className={styles.neshIcon}
      />
      <span className={styles.loader}>
        {searchEntityBlock.messages?.slice(-1)?.[0]}
      </span>
    </div>
  )
})

export default LoadingMessages
