import React from "react"
import { observer } from "mobx-react-lite"
import { Navigate, Route, Routes } from "react-router-dom"

import Templates from "@components/ui/Templates"

import useActiveAvatar from "../ActiveAvatarContext/useActiveAvatar"
import TopicsPage from "./Topics"
import BreadCrumpsRoot from "../FormalKnowledgeDetails/BreadCrumpsContext/BreadCrumpsRoot"
import DataSourceHeader from "../KnowledgeSource/DataSourceHeader"
import SubtopicsPage from "./Subtopics"

const chips = ["Expert Knowledge"]

export interface KnowledgeSourceExplorerProps {
  className?: string
}

export const ExpertInsightsExplorer: React.FC<KnowledgeSourceExplorerProps> =
  observer(({ className }) => {
    const { avatar } = useActiveAvatar()

    const baseLink = `/home/expert-insights/${avatar.id}`

    return (
      <Templates.RollScript
        gutter="24"
        className={className}
        headerSocket={
          <DataSourceHeader title="Expert Insights" chips={chips} />
        }
      >
        <BreadCrumpsRoot link={baseLink}>
          <Routes>
            <Route index element={<TopicsPage />} />

            <Route
              path=":topicId"
              element={<SubtopicsPage baseURL={baseLink} />}
            />

            <Route index element={<Navigate to="/home" replace />} />

            <Route path="**" element={<Navigate to="/home" replace />} />
          </Routes>
        </BreadCrumpsRoot>
      </Templates.RollScript>
    )
  })

export default ExpertInsightsExplorer
