import clsx from "clsx"
import React from "react"

import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"

import styles from "./ImagePreview.module.sass"

interface PDFViewerProps {
  src: string
  checked?: boolean
}

const ImagePreview: React.FC<PDFViewerProps> = ({ src, checked = false }) => {
  const [hasError, setHasError] = React.useState(false)

  return (
    <div className={clsx(styles.root, { [styles.active]: checked })}>
      {src && !hasError ? (
        <img src={src} alt={src} onError={() => setHasError(true)} />
      ) : (
        <div className={styles.noImage}>
          <Icon name="landscape" />
          <Text variant="h5" align="center">
            Image Unavailable
          </Text>
        </div>
      )}
    </div>
  )
}

export default ImagePreview
