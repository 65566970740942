import { observer } from "mobx-react-lite"
import React from "react"
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import { useAlert } from "react-alert"

import SecureDataSourceOutlet from "@components/security/SecureDataSourceOutlet"
import ConnectConfluenceDataCenter from "@pages/upload/AddDataConnector/ConnectConfluenceDataCenter"
import LoadingPlaceholder from "@components/security/LoadingPlaceholder"
import MaintenancePage from "@pages/maintenance/MaintenancePage"

import AddDataConnectorPage from "./AddDataConnector/AddDataConnector"
import ConnectGoogleDrivePage from "./AddDataConnector/ConnectGoogleDrive"
import ConnectSharepointPage from "./AddDataConnector/ConnectSharepoint"
import DataSourcesRootPage from "./DataSourcesRoot/DataSourcesRoot"
import ConnectConfluencePage from "./AddDataConnector/ConnectConfluence"
import UploadedFilesPage from "./UploadedFiles/UploadedFilesPage"
import WebsitesPage from "./UploadedFiles/WebsitesPage"
import CloudFoldersPage from "./UploadedFiles/CloudFoldersPage"
import CloudFilesPage from "./UploadedFiles/CloudFilesPage"
import ConnectEC2SMBPage from "./AddDataConnector/ConnectEC2SMB"
import DataConnectorExplorerPage from "./UploadedFiles/DataConnectorExplorerPage"
import ConnectAmazonS3Page from "./AddDataConnector/ConnectAmazonS3"

const UploadRoot: React.FC = observer(() => {
  const rootPath = "/upload"

  return (
    <Routes>
      <Route path="*">
        <Route index element={<DataSourcesRootPage />} />
        <Route path="*" element={<Navigate to="/upload" replace />} />

        {/* Files */}
        <Route
          path="manual/*"
          element={<SecureDataSourceOutlet source="manual" />}
        >
          <Route path="*" element={<Navigate to="/upload" replace />} />
          <Route
            index
            element={
              <UploadedFilesPage sourceName="manual" backPath={rootPath} />
            }
          />
        </Route>

        {/* Website */}
        <Route
          path="website/*"
          element={<SecureDataSourceOutlet source="website" />}
        >
          <Route path="*" element={<Navigate to="/upload" replace />} />
          <Route
            index
            element={<WebsitesPage sourceName="website" backPath={rootPath} />}
          />
        </Route>

        {/* Data Connectors */}
        <Route
          path="data-connector/:dataConnector/add"
          element={
            <ConnectDataConnectorRedirect
              rootPath={rootPath}
              dataConnectorPropName="dataConnector"
            />
          }
        />

        <Route path=":dataConnector/add" element={<AddDataConnectorPage />} />

        <Route path="smb/*" element={<SecureDataSourceOutlet source="smb" />}>
          <Route path="*" element={<Navigate to="/upload" replace />} />

          <Route
            path=":dataConnectorId"
            element={
              <DataConnectorExplorerPage
                sourceName="folders"
                connectorSourceName="smb"
                backPath={`${rootPath}/smb`}
              />
            }
          />

          <Route
            path="add"
            element={<ConnectEC2SMBPage backPath={`${rootPath}/smb`} />}
          />
        </Route>

        <Route path="network-drive" element={<MaintenancePage />} />

        <Route path="box" element={<MaintenancePage />} />

        <Route path="amazon-aws-s3">
          <Route path="*" element={<Navigate to="/upload" replace />} />
          <Route
            path=":dataConnectorId"
            element={
              <DataConnectorExplorerPage
                sourceName="folders"
                connectorSourceName="amazon-aws-s3"
                backPath={`${rootPath}/amazon-aws-s3`}
              />
            }
          />
          <Route
            path="add"
            element={
              <ConnectAmazonS3Page backPath={`${rootPath}/amazon-aws-s3`} />
            }
          />
        </Route>

        <Route path="sharepoint/*">
          <Route path="*" element={<Navigate to="/upload" replace />} />
          <Route
            path=":dataConnectorId"
            element={
              <CloudFoldersPage
                sourceName="folders"
                connectorSourceName="sharepoint"
                backPath={`${rootPath}/sharepoint`}
              />
            }
          />
          <Route
            path=":dataConnectorId/files/:folderId"
            element={
              <CloudFilesPage
                sourceName="file"
                connectorSourceName="sharepoint"
              />
            }
          />
          <Route
            path="add"
            element={
              <ConnectSharepointPage backPath={`${rootPath}/sharepoint`} />
            }
          />
        </Route>

        <Route path="blobstorage" element={<MaintenancePage />} />

        <Route path="microsoft-teams" element={<MaintenancePage />} />

        <Route path="microsoft-one-note" element={<MaintenancePage />} />

        <Route path="microsoft-one-drive" element={<MaintenancePage />} />

        <Route path="slack" element={<MaintenancePage />} />

        <Route
          path="google-drive/*"
          element={<SecureDataSourceOutlet source="google-drive" />}
        >
          <Route path="*" element={<Navigate to="/upload" replace />} />

          <Route
            path=":dataConnectorId"
            element={
              <CloudFoldersPage
                sourceName="folders"
                connectorSourceName="google-drive"
                backPath={`${rootPath}/google-drive`}
              />
            }
          />
          <Route
            path=":dataConnectorId/files/:folderId"
            element={
              <CloudFilesPage
                sourceName="file"
                connectorSourceName="google-drive"
              />
            }
          />
          <Route
            path="add"
            element={
              <ConnectGoogleDrivePage backPath={`${rootPath}/google-drive`} />
            }
          />
        </Route>

        <Route path="salesforce" element={<MaintenancePage />} />

        <Route path="atlassian-confluence" element={<MaintenancePage />} />

        <Route
          path="atlassian-confluence/*"
          element={<SecureDataSourceOutlet source="atlassian-confluence" />}
        >
          <Route path="*" element={<Navigate to="/upload" replace />} />

          <Route
            path=":dataConnectorId"
            element={
              <CloudFoldersPage
                sourceName="spaces"
                connectorSourceName="atlassian-confluence"
                backPath={`${rootPath}/atlassian-confluence`}
              />
            }
          />
          <Route
            path=":dataConnectorId/files/:folderId"
            element={
              <CloudFilesPage
                sourceName="page"
                connectorSourceName="atlassian-confluence"
              />
            }
          />
          <Route
            path="add"
            element={
              <ConnectConfluencePage
                backPath={`${rootPath}/atlassian-confluence`}
              />
            }
          />
        </Route>

        <Route
          path="atlassian-confluence-data-center/*"
          element={
            <SecureDataSourceOutlet source="atlassian-confluence-data-center" />
          }
        >
          <Route path="*" element={<Navigate to="/upload" replace />} />

          <Route
            path=":dataConnectorId"
            element={
              <CloudFoldersPage
                sourceName="spaces"
                connectorSourceName="atlassian-confluence-data-center"
                backPath={`${rootPath}/atlassian-confluence-data-center`}
              />
            }
          />
          <Route
            path=":dataConnectorId/files/:folderId"
            element={
              <CloudFilesPage
                sourceName="page"
                connectorSourceName="atlassian-confluence-data-center"
              />
            }
          />
          <Route
            path="add"
            element={
              <ConnectConfluenceDataCenter
                backPath={`${rootPath}/atlassian-confluence-data-center`}
              />
            }
          />
        </Route>

        <Route path="atlassian-jira" element={<MaintenancePage />} />

        <Route path="zendesk" element={<MaintenancePage />} />
      </Route>

      <Route path="*" element={<Navigate to="/upload" replace />} />
    </Routes>
  )
})

export default UploadRoot

interface DataConnectorRedirectProps {
  rootPath: string
  dataConnectorPropName: string
}

const ConnectDataConnectorRedirect: React.FC<DataConnectorRedirectProps> = ({
  dataConnectorPropName,
  rootPath,
}) => {
  const alert = useAlert()
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const dataConnectorName = params[dataConnectorPropName]

  React.useEffect(() => {
    if (dataConnectorName == null) {
      alert.error("Redirection failed")
      navigate(rootPath)
    }

    navigate(`${rootPath}/${dataConnectorName}/add${location.search}`, {
      state: location.state,
    })
  }, [rootPath, location.search, dataConnectorName])

  return <LoadingPlaceholder />
}
