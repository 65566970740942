import React from "react"
import throttle from "lodash/throttle"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import TextInput from "@components/ui/TextInput/TextInput"
import useSearchTextInput from "@components/ui/TextInput/useSearchTextInput"
import { useController } from "@store/index"
import useTopicsCollection from "@pages/expert-insights/Topics/hooks/useTopicsCollection"
import List from "@components/ui/List/List"

import useActiveAvatar from "../ActiveAvatarContext/useActiveAvatar"
import EntityList from "../components/ItemsCollection/EntityList"
import { TopicCardEntity } from "./TopicCard"
import BreadCrumpsIndicator from "../FormalKnowledgeDetails/BreadCrumpsContext/BreadCrumpsIndicator"

import styles from "./Topics.module.sass"

export const TopicsPage: React.FC = observer(() => {
  const navigate = useNavigate()
  const { avatar } = useActiveAvatar()

  const collection = useTopicsCollection(avatar.id)

  const { expertInsightsController } = useController()

  const handleLoad = React.useCallback(
    throttle((query?: string) => {
      expertInsightsController.loadTopicList(avatar.id, query, false, "view")
    }, 500),
    []
  )

  const search = useSearchTextInput({ onChange: handleLoad })

  React.useEffect(() => {
    handleLoad()
  }, [])

  return (
    <List className={styles.root} gutter="24">
      <BreadCrumpsIndicator />

      <div className={styles.filter}>
        <TextInput {...search.props} />
      </div>

      {collection.data.length === 0 ? (
        collection.isLoading ? (
          <Loader size="huge" fluid />
        ) : (
          <NotFound>No topics found</NotFound>
        )
      ) : (
        <EntityList
          total={collection.total}
          unit="topic"
          renderCard={(idx) => {
            const topic = collection.getByIndex(idx)
            if (topic == null) return null
            return (
              <TopicCardEntity
                topic={topic}
                onClick={() => navigate(topic.id)}
                key={topic.id}
              />
            )
          }}
        />
      )}
    </List>
  )
})

export default TopicsPage
