import { observer } from "mobx-react-lite"
import React from "react"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import IconChip from "@components/ui/Chip/IconChip"
import Container from "@components/ui/Container/Container"
import Icon from "@components/ui/Icon/Icon"
import LinkCard from "@components/ui/LinkCard/LinkCard"
import { useStore } from "@store/index"
import mainRoutes from "@root/main.routes"

import styles from "./Menu.module.sass"

export interface AdminPanelMenuProps {}

export const AdminPanelMenuPage: React.FC<AdminPanelMenuProps> = observer(
  () => {
    const { restrictionsStore: access } = useStore()

    return (
      <MainLayout noPadding>
        <Container>
          <div className={styles.root}>
            <div className={styles.header}>
              <h2>Analytics</h2>
            </div>
            <div className={styles.body}>
              {access.isAppUsageAnalyticsAvailable && (
                <LinkCard
                  to={mainRoutes.usageAnalytics()}
                  label="Usage Analytics"
                  description="System usage analysis dashboard"
                  icon={
                    <IconChip color="primary">
                      <Icon name="line-chart" />
                    </IconChip>
                  }
                />
              )}

              {access.isAPIUsageAnalyticsAvailable && (
                <LinkCard
                  to={mainRoutes.apiUsageAnalytics()}
                  label="API Usage Analytics"
                  description="API usage analysis dashboard"
                  icon={
                    <IconChip color="primary">
                      <Icon name="file-chart" />
                    </IconChip>
                  }
                />
              )}
            </div>
          </div>
        </Container>
      </MainLayout>
    )
  }
)

export default AdminPanelMenuPage
