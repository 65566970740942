import { makeAutoObservable } from "mobx"

import { AppUIConfig, ColorSchemeOption } from "@framework/types/theme"

/**
 * @deprecated use name filed inside APP_UI_ACTIVE_COLOR_SCHEME_KEY object. Remove next two lines in 2025 year
 */

const APP_UI_ACTIVE_COLOR_SCHEME_NAME_KEY = "APP_UI_ACTIVE_COLOR_SCHEME_NAME"
localStorage.removeItem(APP_UI_ACTIVE_COLOR_SCHEME_NAME_KEY)

//-------------------------------------------------------------------------------------------------------------

const APP_UI_THEME_KEY = "APP_UI_THEME"

const APP_UI_ACTIVE_COLOR_SCHEME_KEY = "APP_UI_ACTIVE_COLOR_SCHEME"

class LocalThemeConfigStore {
  constructor() {
    this.activeConfig = this.initConfig()

    this.activeColorScheme = this.initColorScheme()

    makeAutoObservable(this)
  }

  activeConfig: AppUIConfig | null = null

  activeColorScheme: ColorSchemeOption | null = null

  initConfig = (): AppUIConfig | null => {
    try {
      return getLocalConfig(APP_UI_THEME_KEY)
    } catch (e) {
      return null
    }
  }

  initColorScheme = (): ColorSchemeOption | null => {
    try {
      return getLocalConfig(APP_UI_ACTIVE_COLOR_SCHEME_KEY)
    } catch (e) {
      return null
    }
  }

  setActiveColorScheme = (colorScheme: ColorSchemeOption) => {
    this.activeColorScheme = colorScheme

    storeLocalConfig(APP_UI_ACTIVE_COLOR_SCHEME_KEY, colorScheme)
  }

  setActiveConfig = (config: AppUIConfig) => {
    this.activeConfig = config

    storeLocalConfig(APP_UI_THEME_KEY, config)
  }
}

const getLocalConfig = (key: string) => {
  try {
    const configStr = localStorage.getItem(key)

    if (!configStr) return null

    return JSON.parse(configStr)
  } catch (e) {
    return null
  }
}

const storeLocalConfig = (key: string, config: object | null) => {
  if (config === null) localStorage.removeItem(key)
  else localStorage.setItem(key, JSON.stringify(config))
}

export default new LocalThemeConfigStore()
