import React from "react"
import sortBy from "lodash/sortBy"
import clsx from "clsx"

import { Option } from "@framework/types/utils"
import { RankedExpertData } from "@framework/types/user"

import ContextMenu from "./components/ContextMenu"
import CardBox from "./components/CardBox"
import BusinessCard from "./components/BusinessCard"
import { ChipList } from "./components/ChipList"
import Avatar from "../Avatar/Avatar"
import Pair from "../Pair/Pair"
import List from "../List/List"
import ScoreBar from "./components/ScoreBar/ScoreBar"
import RatingFrame from "../Avatar/RatingFrame"

import styles from "./ExpertCard.module.sass"

export interface ExpertCardProps {
  data: RankedExpertData
  topScore?: number
  menuOptions?: Option[]
  onMenuClick?: (option: string) => void
  onClick?: React.MouseEventHandler
}

export const ExpertCard: React.FC<ExpertCardProps> = ({
  data,
  topScore,
  menuOptions,
  onMenuClick,
  onClick,
}) => {
  const { firstName, lastName } = data

  const fullName = `${firstName} ${lastName}`

  return (
    <CardBox
      onClick={onClick}
      className={clsx({ [styles.primary]: data.rank > 0 && data.rank <= 3 })}
      contextMenu={
        menuOptions?.length ? (
          <ContextMenu menuOptions={menuOptions} onSelect={onMenuClick} />
        ) : null
      }
    >
      <BusinessCard
        title={fullName}
        avatar={
          <RatingFrame className={styles.avatar} rating={data.rank}>
            <Avatar src={data.avatarURL} name={fullName} />
          </RatingFrame>
        }
      >
        <ScoreBar currentScore={data.points} topScore={topScore} />
      </BusinessCard>

      <List overflow="hidden">
        <Pair>
          <h5>Business unit</h5>
          <h5>{data.businessUnit || " - "}</h5>
        </Pair>
        <Pair>
          <h5>Job title</h5>
          <h5>{data.jobTitle || " - "}</h5>
        </Pair>
      </List>

      <ChipList channels={sortBy(data.channels)} />
    </CardBox>
  )
}

export default ExpertCard
