import React, { useEffect, useMemo } from "react"
import { To, useNavigate } from "react-router-dom"
import { useAlert } from "react-alert"
import { observer } from "mobx-react-lite"

import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import MainBoxLayout from "@components/layout/MainBoxLayout/MainBoxLayout"
import { useStore } from "@store/index"
import atlassianDataCenterService from "@services/atlassian-data-center.service"
import {
  ConnectorConfluenceDataCenterForm,
  ConnectorConfluenceDataCenterFormType,
} from "@pages/upload/AddDataConnector/ConnectorConfluenceDataCenterForm"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"

import { OAuthState, useAuthCodeParams } from "./useAuthCodeParams"
import { CONNECT_CONFLUENCE_DATA_CENTER_REDIRECT_URI } from "./constants"

import styles from "./AddDataConnector.module.sass"

export interface ConnectConfluenceServerProps {
  backPath: To
}

export const ConnectConfluenceDataCenter: React.FC<ConnectConfluenceServerProps> =
  observer(({ backPath }) => {
    const navigate = useNavigate()
    const alert = useAlert()

    const redirectParams =
      useAuthCodeParams<ConnectorConfluenceDataCenterFormType>(
        (form) => form.name != null
      )

    const {
      uploadSourceStore: { isDataConnectorLoading, connectConfluence },
    } = useStore()

    const handleOnSubmit = (form: ConnectorConfluenceDataCenterFormType) => {
      const state = OAuthState.stringify(form)

      window.location.href = atlassianDataCenterService.getAuthorizationLink({
        instanceUrl: form.baseUrl,
        clientId: form.clientId,
        redirectUri: CONNECT_CONFLUENCE_DATA_CENTER_REDIRECT_URI,
        state,
      })
    }

    const handleBack = () => navigate(backPath)

    const handleRedirect = async (params: {
      form?: ConnectorConfluenceDataCenterFormType
      code?: string
    }) => {
      const { form, code } = params

      if (form && code) {
        const result = await connectConfluence({
          code,
          baseUrl: form.baseUrl,
          name: form.name,
          clientId: form.clientId,
          clientSecret: form.clientSecret,
          connectorType: "atlassian-confluence-data-center",
          redirectUrl: CONNECT_CONFLUENCE_DATA_CENTER_REDIRECT_URI,
        })

        if (result.error != null) {
          alert.error(result.error)
          navigate(backPath, {
            replace: true,
          })
          return
        }

        if (result.payload?.id) {
          navigate(`${backPath}/${result.payload.id}`, { state: "FIRST_TIME" })
          return
        }

        handleBack()
      }
    }

    useEffect(() => {
      if (redirectParams) handleRedirect(redirectParams)
    }, [redirectParams])

    const initialValues = useMemo(
      () => ({
        name: redirectParams?.form.name ?? "",
        baseUrl: redirectParams?.form.baseUrl ?? "",
        clientId: redirectParams?.form.clientId ?? "",
        clientSecret: redirectParams?.form.clientSecret ?? "",
      }),
      [redirectParams]
    )

    return (
      <MainBoxLayout className={styles.root} onBack={handleBack}>
        <DocumentIcon
          className={styles.icon}
          icon="img:confluence"
          alt="confluence"
        />

        <ModalTitle titleText="Connect to Confluence Server" />

        <ConnectorConfluenceDataCenterForm
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          isLoading={isDataConnectorLoading}
        />
      </MainBoxLayout>
    )
  })

export default ConnectConfluenceDataCenter
