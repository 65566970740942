import React from "react"
import clsx from "clsx"
import ReactDOM from "react-dom"

import Box from "../Dropdown/Box"

import styles from "./ContextMenu.module.scss"

export type ContextMenuBoxProps = {
  open: boolean
  className?: string
  contentClassName?: string
  style?: React.CSSProperties
  children?: React.ReactNode
}

export const ContextMenuBox = React.forwardRef<
  HTMLDivElement,
  ContextMenuBoxProps
>(({ open, children, className, style }, ref) => {
  return ReactDOM.createPortal(
    <div ref={ref} className={clsx(styles.root, className)} style={style}>
      {open ? (
        <Box color="primary" className={styles.container}>
          {children}
        </Box>
      ) : null}
    </div>,
    document.body
  )
})

export default ContextMenuBox
