import React from "react"

import { useStore } from "@store/index"

const useAvatarWebsitesKnowledge = (dataCategoryId: string) => {
  const { avatarWebsiteKnowledgeStore } = useStore()
  const resetCollection = React.useCallback(
    () => avatarWebsiteKnowledgeStore.resetCollection(dataCategoryId),
    [dataCategoryId]
  )

  return {
    resetCollection,
    collection: avatarWebsiteKnowledgeStore.getCollection(dataCategoryId),
  }
}

export default useAvatarWebsitesKnowledge
