import clsx from "clsx"
import React from "react"
import { NavLink, useLocation } from "react-router-dom"

import styles from "./MarkdownNavLink.module.sass"

const MarkdownNavLink: React.FC<React.AllHTMLAttributes<HTMLAnchorElement>> = ({
  href = "/",
  className,
  children,
  ...restProps
}) => {
  const location = useLocation()

  return (
    <NavLink
      to={href}
      {...restProps}
      className={clsx(className, {
        [styles.citation]: isCitation(children),
      })}
      state={{ backTo: location.pathname }}
    >
      {children}
    </NavLink>
  )
}

export default MarkdownNavLink

const isCitation = (children: React.ReactNode) => {
  return (
    Array.isArray(children) &&
    children.length === 1 &&
    typeof children[0] === "string" &&
    !Number.isNaN(Number(children[0]))
  )
}
