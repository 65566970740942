import clsx from "clsx"
import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import Editor from "@components/ui/Editor/Editor"
import { isEditorTextEmpty } from "@components/ui/Editor/utils"
import Loader from "@components/ui/Loader/BarLoader"
import { AnswerStatus, ExpertQuestion } from "@framework/types/question"
import { useStore } from "@store/index"
import Templates from "@components/ui/Templates"
import Label from "@components/ui/Label/Label"

import QuestionCard from "../QuestionCard/QuestionCard"

import styles from "./QuestionForm.module.sass"

interface AnsweredQuestionFormProps {
  className?: string
  questionData: ExpertQuestion
}

const AnsweredQuestionForm: React.FC<AnsweredQuestionFormProps> = ({
  className,
  questionData,
}) => {
  const [answer, setAnswer] = useState<string>("")
  const valid = !isEditorTextEmpty(answer)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const {
    knowledgeStore: {
      updateExpertQuestion,
      loadExpertQuestions,
      isUpdateExpertQuestionLoading,
    },
    userStore: { user },
  } = useStore()

  const answerOfCurrentUser = questionData.answer ?? ""

  const handleSubmit = async () => {
    if (valid) {
      const success = await updateExpertQuestion(
        questionData.id,
        AnswerStatus.ANSWERED,
        answer
      )
      if (success) loadExpertQuestions()
      setIsEditing(false)
    }
  }

  const handleStartEditing = () => setIsEditing(true)
  const handleCancel = () => setIsEditing(false)

  useEffect(() => {
    if (user?.id) setAnswer(answerOfCurrentUser)
  }, [answerOfCurrentUser])

  return (
    <>
      {isUpdateExpertQuestionLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <div className={clsx(styles.root, className)}>
          <QuestionCard
            data={questionData}
            isEditing={isEditing}
            onEdit={handleStartEditing}
          />

          {isEditing && (
            <>
              <div className={styles.editor}>
                <Label id="answer" label="ANSWER">
                  <Editor
                    onChange={setAnswer}
                    defaultValue={answerOfCurrentUser}
                  />
                </Label>

                <Templates.Header
                  left={
                    <Button
                      color="default"
                      variant="outlined"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  }
                  right={
                    <Button
                      color="primary"
                      disabled={!valid}
                      onClick={handleSubmit}
                    >
                      Save Changes
                    </Button>
                  }
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default observer(AnsweredQuestionForm)
