import React from "react"
import clsx from "clsx"

import { Option } from "@framework/types/utils"

import ListItem from "../ListItem/ListItem"
import Icon from "../Icon/Icon"
import Text from "../Typography/Text"
import { Menu } from "./types"

import styles from "./OptionList.module.scss"

export type ContextMenuBoxProps = {
  onClose: () => void
} & Menu

export const OptionList: React.FC<ContextMenuBoxProps> = ({
  groups,
  handler,
  onClose,
}) => {
  const getClickHandler = (item: Option) => () => {
    try {
      if (!handler(item)) {
        console.warn(`Missing handler for Context menu option: ${item.name}`)
      }
    } catch (error) {
      console.error(error)
    }

    onClose()
  }

  return (
    <>
      {groups.map((group, gIndex) => (
        <React.Fragment key={group.name}>
          {gIndex !== 0 && <hr className={styles.divider} />}
          {group.options.map((item) => (
            <ListItem onClick={getClickHandler(item)} key={item.name}>
              <OptionItem option={item} />
            </ListItem>
          ))}
        </React.Fragment>
      ))}
    </>
  )
}

export const OptionItem: React.FC<{ option: Option }> = ({ option }) => {
  const name = option.value
  const hasChildren = false
  return (
    <div className={styles.item} title={name}>
      <div className={styles.icon}>
        {option.icon != null && <Icon name={option.icon} />}
      </div>

      <Text variant="body2" className={styles.text}>
        {name}
      </Text>

      {hasChildren && (
        <div className={styles.arrow}>
          <Icon name="arrow-down" rotateAngle={-90} />
        </div>
      )}
    </div>
  )
}

export default OptionList
