export type ArgumentValidationReport = {
  name: string
  error: string | null
  index: number
}

export class FormulaValidationError extends Error {
  functionName: string

  argumentErrors: ArgumentValidationReport[]

  constructor(
    functionName: string,
    argumentErrors: ArgumentValidationReport[],
    message: string = "Validation Failed"
  ) {
    super(message)
    this.functionName = functionName
    this.argumentErrors = argumentErrors
  }
}

export default FormulaValidationError
