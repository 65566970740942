import debounce from "lodash/debounce"
import { observer } from "mobx-react-lite"
import React from "react"

import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import TextInput from "@components/ui/TextInput/TextInput"
import useSearchTextInput from "@components/ui/TextInput/useSearchTextInput"
import useAvatarExpertAnswersCollection from "@store/avatar-knowledge/useAvatarExpertAnswersCollection"
import { useController } from "@store/index"

import useActiveAvatar from "../ActiveAvatarContext/useActiveAvatar"
import EntityList from "../components/ItemsCollection/EntityList"
import { ExpertPassageCard } from "./ExpertPassageCard"

import styles from "./index.module.sass"

export const ExpertPassagesList: React.FC = observer(() => {
  const { avatar } = useActiveAvatar()
  const collection = useAvatarExpertAnswersCollection()

  const { avatarKnowledgeController } = useController()

  const handleLoad = React.useCallback(
    debounce((query: string) => {
      avatarKnowledgeController.loadExpertAnswersList(avatar.id, {
        pageNum: 0,
        pageSize: 50000,
        query,
      })
    }, 500),
    []
  )

  const search = useSearchTextInput({ onChange: handleLoad })

  React.useEffect(() => {
    avatarKnowledgeController.loadExpertAnswersList(avatar.id, {
      pageNum: 0,
      pageSize: 50000,
    })
  }, [])

  return (
    <>
      <div className={styles.filter}>
        <TextInput {...search.props} />
        <Button
          size="big"
          color="secondary"
          before={<Icon name="top-down-arrows" />}
          after={<Icon name="arrow-down" />}
        >
          Sort
        </Button>
      </div>

      {collection.data.length === 0 ? (
        collection.isLoading ? (
          <Loader size="huge" fluid />
        ) : (
          <NotFound>No answers found</NotFound>
        )
      ) : (
        <EntityList
          total={collection.meta.total}
          unit="answer"
          renderCard={(idx) => {
            const answer = collection.getAnswerByIndex(idx)
            if (answer == null) return null
            return <ExpertPassageCard answer={answer} key={answer.id} />
          }}
        />
      )}
    </>
  )
})

export default ExpertPassagesList
