import React from "react"
import { observer } from "mobx-react-lite"
import get from "lodash/get"

import { useStore } from "@store/index"
import Icon from "@components/ui/Icon/Icon"
import TextInput from "@components/ui/TextInput/TextInput"
import useSearch from "@components/hooks/useSearch"
import FilterButton from "@components/ui/Button/FilterButton"
import List from "@components/ui/List/List"
import FilterSidebar from "@components/prototypes/FilterSidebar"
import { countActiveFilters } from "@utils/filters"
import Switch from "@components/ui/Switch/Switch"
import { Option } from "@framework/types/utils"
import { FractionName } from "@store/upload/cloud-files-virtual-collection.store"
import { DataConnectorSourceName } from "@framework/types/upload"

import CollectionControlBar from "./CollectionControlBar"
import CloudFilesFilterForm, { FilterForm } from "./CloudFilesFilterForm"

import styles from "./CloudFilesCollectionControlBar.module.sass"

const tabs: Option<FractionName>[] = [
  { name: "total", value: "All" },
  { name: "uploaded", value: "Connected" },
  { name: "running", value: "Processing" },
  { name: "failed", value: "Failed" },
]

const CloudFilesCollectionControlBar: React.FC<{
  connectorSourceName: DataConnectorSourceName
  dataConnectorId: string
  folderId: string
}> = observer(({ connectorSourceName, dataConnectorId, folderId }) => {
  const { uploadStore } = useStore()

  const store = uploadStore.getCloudFilesStore(
    connectorSourceName,
    dataConnectorId,
    folderId
  )

  const collection = store.state

  const [searchProps, searchMeta] = useSearch({ onChange: store.search })

  const appliedFiltersLength = React.useMemo(() => {
    return countActiveFilters(store.state.filter)
  }, [store.state.filter])

  const handleApplyFilter = (data: FilterForm) => {
    store.applyFilter({ ...data })
  }

  return (
    <CollectionControlBar
      left={
        <Switch
          items={tabs}
          checked={store.state.fraction}
          onChange={store.changeFraction}
          onRenderLabel={(item, label) =>
            `${label} (${get(collection.distribution, item, 0)})`
          }
        />
      }
      right={
        <List direction="row" gutter="16" justify="flex-end">
          <TextInput
            {...searchProps}
            className={styles.searchInput}
            before={<Icon name="search" />}
            after={
              !!searchProps.value && (
                <Icon name="cross" onClick={() => searchMeta.setQuery("")} />
              )
            }
            placeholder="Search"
          />

          <FilterSidebar<FilterForm>
            onChange={handleApplyFilter}
            targetSlot={({ onToggle, onReset }) => (
              <FilterButton
                size="big"
                variant="outlined"
                counter={appliedFiltersLength}
                onOpen={onToggle}
                onClean={onReset}
              >
                Filter
              </FilterButton>
            )}
          >
            <CloudFilesFilterForm />
          </FilterSidebar>
        </List>
      }
    />
  )
})

export default CloudFilesCollectionControlBar
