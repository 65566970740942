import React from "react"

import Box from "@components/ui/Dropdown/Box"
import Text from "@components/ui/Typography/Text"
import IconButton from "@components/ui/IconButton/IconButton"
import Icon from "@components/ui/Icon/Icon"
import useToggle from "@components/hooks/useToggle"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import { Point } from "@framework/types/common"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import { pointToCode } from "../utils"
import { CellComment } from "../types"
import CellManager from "../state/CellManager"

import styles from "./Tooltip.module.sass"

export const CommentTooltip: React.FC<{
  point: Point
  cell: CellManager
  comment: CellComment
}> = ({ point, cell, comment }) => {
  const isTablet = useMediaQuery(AppMediaQueries.minTablet)

  const deleteModal = useModal(ModalsTypes.CONFIRM_DELETE_MODAL)
  const editModal = useModal(ModalsTypes.ADD_COMMENT_MODAL)

  const toggle = useToggle()

  const cellCode = pointToCode(point)

  const handleDelete = () => {
    deleteModal.showModal({
      message: `Delete comment at ${cellCode}?`,
      onSubmit: () => cell.setComment(null),
    })
  }

  const handleEdit = () => {
    editModal.showModal({
      title: `Edit Comment for ${cellCode}`,
      initialValue: comment,
      onSubmit: cell.setComment,
    })
  }

  const buttonSize = "small"

  return (
    <Box
      color="primary"
      className={styles.root}
      onWheelCapture={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <div className={styles.header}>
        <Text
          variant={isTablet ? "h4" : "h5"}
          color="text50Color"
          onDoubleClick={toggle.handleToggle}
        >
          <Icon name="info" inline /> {comment.title ?? "Info"}
        </Text>

        <div className={styles.control}>
          <IconButton title="Delete" size={buttonSize} onClick={handleDelete}>
            <Icon name="trash-can" />
          </IconButton>

          <IconButton title="Edit" size={buttonSize} onClick={handleEdit}>
            <Icon name="edit" />
          </IconButton>
        </div>
      </div>

      <div className={styles.message}>
        <Text
          variant="body2"
          color="text100Color"
          onDoubleClick={toggle.handleToggle}
        >
          {comment.message}
        </Text>
      </div>
    </Box>
  )
}

export default React.memo(CommentTooltip)
