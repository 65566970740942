import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React from "react"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"
import { useStore } from "@store/index"

import ContextMenu from "./UserContextMenu"

import styles from "./index.module.sass"

interface UserRowProps {
  id: string | null
}

export const UserGroupsTableRow: React.FC<UserRowProps> = observer(({ id }) => {
  const { userGroupsStore } = useStore()

  const group = userGroupsStore.getById(id)

  if (!group) return null
  return (
    <Row className={clsx(styles.row)}>
      <Column key="name" align="center">
        {group.name}
      </Column>

      <Column key="roles" align="center">
        <ContextMenu group={group} />
      </Column>

      <Column key="actions" align="center">
        {group.description || "-"}
      </Column>

      <Column key="roles" align="center">
        {group.userCount || 0} Users
      </Column>
    </Row>
  )
})

export default UserGroupsTableRow
