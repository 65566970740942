import React, { useMemo, useState } from "react"
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List as VirtualList,
  ListProps,
} from "react-virtualized"
import { observer } from "mobx-react-lite"
import { FieldProps } from "formik"

import Dropdown from "@components/prototypes/FilterSidebar/components/Dropdown/Dropdown"
import NotFound from "@components/ui/NotFound/NotFound"
import Loader from "@components/ui/Loader/BarLoader"
import Templates from "@components/ui/Templates"
import { useController, useStore } from "@store/index"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import ListItem from "@components/ui/ListItem/ListItem"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import { ApiKey } from "@framework/types/api-keys"
import CheckboxCard from "@components/ui/CheckboxCard/CheckboxCard"

import styles from "./APIKeySelect.module.sass"

export interface APIKeySelectProps extends FieldProps<string> {
  label?: string
  valueKey?: keyof ApiKey
}

const APIKeySelect: React.FC<APIKeySelectProps> = observer(
  ({ label, field, form, valueKey = "id" }) => {
    const [open, setOpen] = useState(false)

    const { value = [], name } = field
    const { setFieldValue } = form

    const { apiKeysStore } = useStore()
    const { apiKeysController } = useController()

    const handleClick = (newValue: string) => {
      if (value === newValue) {
        setFieldValue(name, undefined)
      } else {
        setFieldValue(name, newValue)
      }
    }

    const renderRow: ListProps["rowRenderer"] = ({
      index,
      key,
      parent,
      style,
    }) => {
      const item = apiKeysStore.getByIndex(index)

      if (!item)
        return (
          <div style={style} key={key}>
            <ListItem>
              <Skeleton lineHeight={style.height} minWidth={100} />
            </ListItem>
          </div>
        )

      const active = value === (item[valueKey] as string)

      return (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          rowIndex={index}
          parent={parent}
          key={`${key}-cached`}
        >
          <div style={style} key={key}>
            <CheckboxCard
              label={item.name}
              onCheck={() => handleClick(item[valueKey] as string)}
              checked={active}
              withIcon={false}
              key={item.name}
            />
          </div>
        </CellMeasurer>
      )
    }

    const cache = useMemo(() => {
      return new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 58,
      })
    }, [])

    const isChecked = !!value?.length
    const total = apiKeysStore.apiKeys?.length ?? 0

    const handleUncheck = (e: React.MouseEvent) => {
      if (isChecked) setFieldValue(name, undefined)
      else setOpen(true)
      e.stopPropagation()
    }

    React.useEffect(() => {
      apiKeysController.init()
    }, [apiKeysController.init])

    return (
      <Dropdown
        title={label}
        opened={open}
        onClick={() => setOpen((value) => !value)}
        before={
          <CheckboxWithLabel
            checked={!!value?.length}
            onClick={handleUncheck}
          />
        }
      >
        <Templates.RollScript className={styles.root}>
          <div className={styles.container}>
            {total === 0 ? (
              apiKeysStore.isLoading ? (
                <Loader size="large" fluid />
              ) : (
                <NotFound>No API Keys found</NotFound>
              )
            ) : (
              <AutoSizer>
                {({ width, height }) => (
                  <VirtualList
                    rowCount={total}
                    rowHeight={cache.rowHeight}
                    rowRenderer={renderRow}
                    height={height}
                    width={width}
                  />
                )}
              </AutoSizer>
            )}
          </div>
        </Templates.RollScript>
      </Dropdown>
    )
  }
)

export default APIKeySelect
