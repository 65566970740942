import React from "react"
import { observer } from "mobx-react-lite"

import List from "@components/ui/List/List"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"
import Icon from "@components/ui/Icon/Icon"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"

import UserTrendChart from "../components/Chart/UserTrendChart"

export const UserTrendWidget: React.FC = observer(() => {
  return (
    <section>
      <Templates.Header
        offsetBottom="middle"
        left={
          <Tooltip
            content={
              <TooltipContainer
                margin="8px"
                color="primary"
                placement="right"
                width="250px"
              >
                <Text variant="inherit">
                  Trend of DAU, MAU and New Users over chosen time period
                </Text>
              </TooltipContainer>
            }
            mode="onHover"
          >
            <Text>
              User Trend <Icon name="info" inline />
            </Text>
          </Tooltip>
        }
      />

      <List direction="row" gutter="24" overflow="initial">
        <UserTrendChart />
      </List>
    </section>
  )
})

export default UserTrendWidget
