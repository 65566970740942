import { observer } from "mobx-react-lite"
import React from "react"

import AlertBanner from "@components/ui/AlertBanner/AlertBanner"
import { useUnstructuredSearchEntity } from "@pages/search/SearchContext/SearchResultContext"

import AnswerSummary from "./AnswerSummary"

import styles from "./AnswerSummary.module.sass"

type AnswerSectionProps = {
  defaultQuery?: boolean // TODO get rid of prop drilling
}

export const AnswerSection: React.FC<AnswerSectionProps> = observer(
  ({ defaultQuery }) => {
    const { searchEntity } = useUnstructuredSearchEntity()

    return (
      <div className={styles.answerSection}>
        <AnswerSummary defaultQuery={defaultQuery} />

        {searchEntity.error ? (
          <AlertBanner type="error">{searchEntity.error}</AlertBanner>
        ) : null}
      </div>
    )
  }
)

export default AnswerSection
