/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import { AvatarData } from "@framework/types/avatar"
import Avatar from "@components/ui/Avatar/Avatar"
import List from "@components/ui/List/List"
import Chip from "@components/ui/Chip/Chip"
import { capitalizeFirstLetter } from "@utils/textUtils"

import styles from "./AvatarItem.module.sass"

export interface AvatarItemProps {
  data: AvatarData
  active?: boolean
  control?: React.ReactNode
  onClick?: React.MouseEventHandler
}

export const AvatarItem: React.FC<AvatarItemProps> = observer(
  ({ data, active = false, onClick }) => {
    // const totalSolutions = data.productSolutions?.length ?? 0

    const isPrivate = data.accessType === "private"

    return (
      <button
        type="button"
        className={clsx(styles.root, { [styles.active]: active })}
        onClick={onClick}
      >
        <Avatar src={data.imageUrl} width={27} height={27} />

        <div>
          <div className={styles.header}>
            <Text variant="h3">{data.name}</Text>

            <Icon name="done" className={styles.checkMark} />
          </div>

          <div className={styles.description}>
            <Text variant="body2" color="text50Color">
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: data.description }} />
            </Text>
          </div>

          <div className={styles.footer}>
            <List direction="row" gutter="8" justify="flex-end">
              <Chip
                title="Private avatar"
                color={isPrivate ? "gold" : "green"}
                variant="rounded"
                before={<Icon name={isPrivate ? "lock" : "lock-unlock"} />}
              >
                {capitalizeFirstLetter(data.accessType)}
              </Chip>
              {/* <Chip
                title={`${totalSolutions} Solutions`}
                color="secondary"
                variant="rounded"
                before={<Icon name="jigsaw-puzzle" />}
              >
                {renderShortNumber(totalSolutions)}
              </Chip> */}
            </List>
          </div>
        </div>
      </button>
    )
  }
)

export default AvatarItem
