import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import styles from "./BlockCard.module.sass"

export interface BlockCardProps {
  className?: string
  runAnimation?: boolean
}

export const BlockCard: React.FC<BlockCardProps> = observer(
  ({ className, runAnimation = false, children }) => {
    return (
      <section
        className={clsx(
          styles.root,
          { [styles.appear]: runAnimation },
          className
        )}
      >
        {children}
      </section>
    )
  }
)

export default BlockCard
