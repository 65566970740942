import React from "react"
import { observer } from "mobx-react-lite"

import { useController } from "@store/index"
import useAvatarDataConnectorsGroup from "@store/avatar-knowledge/useAvatarDataConnectorsGroup"
import { DataSourceName, DataSourceStatus } from "@framework/types/upload"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import DataConnectorLinkCard from "@pages/upload/components/DataSourceGroup/DataConnectorLinkCard"

import useActiveCategoryContext from "../ActiveDataCategoryContext/useActiveCategoryContext"
import BreadCrumpsIndicator from "./BreadCrumpsContext/BreadCrumpsIndicator"

import styles from "./index.module.sass"

const descriptionRenderCallback = (data?: DataSourceStatus) =>
  `${data?.total ?? 0} connectors`

export const DataConnectorsGroups: React.FC = observer(() => {
  const category = useActiveCategoryContext()

  const { avatarKnowledgeController } = useController()
  const collection = useAvatarDataConnectorsGroup(category.id)

  const items = React.useMemo(() => {
    return collection.data.filter(
      (it) => it.totalFolders != null && it.totalFolders > 0
    )
  }, [collection.data])

  React.useEffect(() => {
    avatarKnowledgeController.loadDataConnectorsGroupsList(category.id)
  }, [category.id])

  return (
    <>
      <div className={styles.navigation}>
        <BreadCrumpsIndicator />
      </div>

      {items.length === 0 ? (
        collection.isLoading ? (
          <Loader size="huge" fluid />
        ) : (
          <NotFound />
        )
      ) : (
        <div className={styles.grid}>
          {items.map((data) => {
            return (
              <DataConnectorLinkCard
                id={data.id as DataSourceName}
                data={data}
                link={data.name}
                renderDescription={descriptionRenderCallback}
                key={data.id}
              />
            )
          })}
        </div>
      )}
    </>
  )
})

export default DataConnectorsGroups
