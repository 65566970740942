/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import clsx from "clsx"
import identity from "lodash/identity"

import Icon from "../Icon/Icon"

import styles from "./Breadcrumbs.module.scss"

export type BreadcrumbsProps = {
  items?: string[]
  className?: string
  renderItem?: (name: string) => React.ReactNode
  renderRoot?: (name: string) => React.ReactNode
  onClick?: (value: string[]) => void
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  items = [],
  className,
  renderItem = identity,
  renderRoot = () => <Icon name="home" />,
  onClick,
}) => {
  const handleSelect = (idx: number) => {
    if (onClick) onClick(items.slice(0, idx))
  }

  return (
    <div className={clsx(styles.root, className)}>
      <React.Fragment key="root_item">
        <div
          className={clsx(styles.item, styles.home, styles.clickable)}
          onClick={() => handleSelect(0)}
        >
          {renderRoot("root")}
        </div>
        <Divider />
      </React.Fragment>

      {items.map((item, idx) => {
        const isLastChild = idx === items.length - 1
        const handleClick = !isLastChild
          ? () => handleSelect(idx + 1)
          : undefined
        return (
          <React.Fragment key={item}>
            <div
              className={clsx(styles.item, {
                [styles.clickable]: handleSelect != null,
              })}
              onClick={handleClick}
            >
              {renderItem(item)}
            </div>
            {isLastChild ? null : <Divider />}
          </React.Fragment>
        )
      })}
    </div>
  )
}

const Divider: React.FC = () => <span>&nbsp;/&nbsp;</span>

export default React.memo(Breadcrumbs)
