import React from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"
import clsx from "clsx"

import { useStore } from "@store/index"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import Chip from "@components/ui/Chip/Chip"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import UserChip from "@components/prototypes/UserChip"
import AvatarBubbles from "@components/prototypes/AvatarBubbles"

import useAssociatedDocsContextMenu from "../connectors/Sources/hooks/useAssociatedDocsContextMenu"
import { Column, Row } from "./Table"
import ContextMenu from "../components/DocumentUploadCard/ContextMenu"
import FileStatusBadge from "./FileStatusBadge"

import styles from "./WebsiteRow.module.sass"

export const WebsiteRow: React.FC<{
  index: number
}> = observer(({ index }) => {
  const {
    allDatatypesStore,
    restrictionsStore: access,
    uploadStore: { connectedWebsitesCollections: store },
    allUserStore,
  } = useStore()

  const item = store.getByIndex(index)

  const isReadonly = !access.canEditDataSource

  const dataType = allDatatypesStore.getById(
    item?.knowledgedatatypes?.[0]?.id ?? null
  )

  const contextMenuProps = useAssociatedDocsContextMenu(
    "website",
    dataType?.id,
    isReadonly,
    store.refresh
  )

  if (item == null) return <Skeleton rounded lineHeight={56} minWidth={100} />

  const isRunning = item.status === "running"
  const isFailed = item.status === "failed"

  const handleOpen =
    item.name != null ? () => window.open(item.name, "_blank") : undefined

  const handleAssignDataType =
    isReadonly || isFailed
      ? undefined
      : (e: React.MouseEvent) => {
          e.stopPropagation()
          contextMenuProps.onContext(item.id, "edit_data_type")
        }

  return (
    <Row
      className={clsx(styles.row)}
      loading={isRunning}
      onDoubleClick={handleOpen}
    >
      <Column>
        <Text variant="h1">
          <Icon name="global" color="primary" />
        </Text>
      </Column>

      <Column title={item.name}>
        <Text variant="inherit" className={styles.text}>
          {item.name}
        </Text>
      </Column>

      <Column>
        <FileStatusBadge
          status={item.status}
          progress={item.completePercentage}
          error={item.error}
        />
      </Column>

      <Column>
        <Chip
          color={dataType != null ? "blue" : "secondary"}
          variant="rounded"
          before={
            dataType != null && <Icon name={dataType.iconUrl ?? "global"} />
          }
          onClick={handleAssignDataType}
          title={dataType != null ? dataType.name : "Not Associated "}
        >
          {dataType != null ? dataType.name : "Not Associated "}
        </Chip>
      </Column>

      <Column>
        <AvatarBubbles avatars={dataType?.channels} />
      </Column>

      <Column>{moment(item.createdAt).format("MMM DD, YYYY")}</Column>

      <Column>
        <UserChip
          data={allUserStore.getById(item.userId ?? null)}
          isLoading={allUserStore.state.isLoading}
        />
      </Column>

      <Column>
        {!!contextMenuProps.options.length && (
          <ContextMenu id={item.id} {...contextMenuProps} />
        )}
      </Column>
    </Row>
  )
})

export default WebsiteRow
