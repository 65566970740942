import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import { useController } from "@store/index"
import { UserGroup } from "@framework/types/user"

import EditUserGroupForm, {
  FormData,
  validationSchema,
} from "./EditUserGroupForm"

import styles from "./EditUserGroupModal.module.sass"

export interface EditUserGroupModalProps {
  group?: UserGroup
}

export const EditUserGroupModal: React.FC<EditUserGroupModalProps> = observer(
  ({ group }) => {
    const [groupId] = React.useState(group?.id)
    const isEdit = groupId != null

    const alert = useAlert()

    const { userGroupsController } = useController()

    const modal = useModal(ModalsTypes.EDIT_USER_GROUP_MODAL)

    const handleSubmit = async (formData: FormData) => {
      modal.hideModal()

      const error = await (isEdit
        ? userGroupsController.updateUserGroup(groupId, formData)
        : userGroupsController.createUserGroup(formData))

      userGroupsController.reload()

      if (error) {
        alert.error(error)
        return
      }

      alert.success(
        <>
          User Group &quot;<b>{formData.name}</b>&quot; was successfully
          {isEdit ? " updated" : " added"}
        </>
      )
    }

    const initial = validationSchema.cast(group, { stripUnknown: true })

    return (
      <BaseModal
        title={
          <ModalTitle
            titleText={isEdit ? "Edit User Group" : "Create new User Group"}
          />
        }
        className={styles.root}
        containerClassName={styles.container}
        onClose={modal.hideModal}
      >
        <EditUserGroupForm initialValues={initial} onSubmit={handleSubmit} />
      </BaseModal>
    )
  }
)

export default EditUserGroupModal
