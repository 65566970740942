import React from "react"
import { observer } from "mobx-react-lite"

import { WebsiteSourceName } from "@framework/types/upload"
import { useStore } from "@store/index"
import useToggle from "@components/hooks/useToggle"
import useEvent from "@components/hooks/useEvent"

import ConnectDataSourceButton from "../components/UploadHeader/ConnectDataSourceButton"
import { useSelectDataTypeContext } from "../PreselectDataTypeContext"
import ConnectWebsitesSidebar from "../ConnectWebsite/ConnectWebsitesSidebar"

export const ConnectWebsitesButton: React.FC<{
  sourceName: WebsiteSourceName
}> = observer(({ sourceName }) => {
  const connectSidebar = useToggle()

  const {
    uploadStore: { connectedWebsitesCollections: store },
  } = useStore()

  const handleUpload = useEvent(() => {
    connectSidebar.setOpened(true)
  })

  const { dataTypeId, selectDataType } = useSelectDataTypeContext()

  return (
    <>
      <ConnectDataSourceButton
        sourceName={sourceName}
        onClick={() =>
          selectDataType({
            autoSelect: true,
            onSelect: handleUpload,
          })
        }
      />

      <ConnectWebsitesSidebar
        open={connectSidebar.isOpened && !!dataTypeId}
        onClose={() => {
          connectSidebar.setOpened(false)
          store.refresh()
        }}
      />
    </>
  )
})

export default ConnectWebsitesButton
