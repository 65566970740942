import React from "react"
import { observer } from "mobx-react-lite"

import ActiveAvatarContextProvider from "@pages/search/ActiveAvatarContext"
import { useStore } from "@store/index"
import LoadingPlaceholder from "@components/security/LoadingPlaceholder"

interface AvatarProofPageLayoutProps {
  avatarIdPropName?: string
  onAvatarChange?: (avatarId: string) => void
}

export const UrlWithAvatarContextProvider: React.FC<AvatarProofPageLayoutProps> =
  observer(({ onAvatarChange, avatarIdPropName = "avatarId", children }) => {
    const {
      userSetupStore: { avatarsStore, avatarId },
    } = useStore()

    if (avatarId == null)
      throw new Error(
        `Avatar Id was not found in URL params by name: ${avatarIdPropName}`
      )

    const avatar = avatarsStore.getAvatarById(avatarId)

    if (avatar == null) return <LoadingPlaceholder />
    return (
      <ActiveAvatarContextProvider
        avatarId={avatarId}
        onChange={onAvatarChange}
      >
        {children}
      </ActiveAvatarContextProvider>
    )
  })

export default UrlWithAvatarContextProvider
