import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React from "react"

import styles from "./AxisCell.module.sass"

type AxisCellProps = {
  height?: React.CSSProperties["height"]
  width?: React.CSSProperties["width"]
  children?: null
  className?: string
}

const EmptyAxisCell: React.FC<AxisCellProps> = observer(
  ({ height = "100%", width = "100%" }) => {
    return (
      <div
        className={clsx(styles.root)}
        style={{ height, width, overflow: "hidden" }}
      />
    )
  }
)

export default EmptyAxisCell
