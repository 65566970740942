import React from "react"

import { capitalizeFirstLetter } from "@utils/textUtils"

import styles from "./HomeInfo.module.sass"

type CategoryInfoProps = {
  category: string
  heading?: string
  description?: string
}

const CategoryInfo: React.FC<CategoryInfoProps> = ({
  category,
  heading,
  description,
}) => {
  return (
    <div className={styles.infoSection}>
      <div className={styles.tag}>{capitalizeFirstLetter(category)}</div>
      <div className={styles.heading}>{heading}</div>
      <p>{description}</p>
    </div>
  )
}

export default CategoryInfo
