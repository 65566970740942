import React from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"
import clsx from "clsx"

import { useController, useStore } from "@store/index"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import { DocumentIconType, isDocumentIconName } from "@framework/types/utils"
import { IconName } from "@components/ui/Icon/IconName"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { getExtension } from "@utils/textUtils"
import Chip from "@components/ui/Chip/Chip"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import UserChip from "@components/prototypes/UserChip"
import AvatarBubbles from "@components/prototypes/AvatarBubbles"

import useAssociatedDocsContextMenu from "../connectors/Sources/hooks/useAssociatedDocsContextMenu"
import { Column, Row } from "./Table"
import ContextMenu from "../components/DocumentUploadCard/ContextMenu"
import FileStatusBadge from "./FileStatusBadge"

import styles from "./FileRow.module.sass"

export const FileRow: React.FC<{
  index: number
  defaultFileIcon?: DocumentIconType | IconName
}> = observer(({ index, defaultFileIcon = "img:file" }) => {
  const {
    allDatatypesStore,
    restrictionsStore: access,
    uploadStore,
    allUserStore,
  } = useStore()

  const { dataConnectorController } = useController()

  const { uploadedFilesCollections: store } = uploadStore

  const item = store.getByIndex(index)

  const isReadonly = !access.canEditDataSource

  const dataType = allDatatypesStore.getById(
    item?.knowledgedatatypes?.[0]?.id ?? null
  )

  const contextMenuProps = useAssociatedDocsContextMenu(
    "manual",
    dataType?.id,
    isReadonly,
    store.refresh
  )

  if (item == null) return <Skeleton rounded lineHeight={56} minWidth={100} />

  const ext = getExtension(item.name) ?? ""
  const candidateIcon = `img:${ext}`
  const icon = isDocumentIconName(candidateIcon)
    ? candidateIcon
    : defaultFileIcon

  const isRunning = item.status === "running"
  const isFailed = item.status === "failed"

  const getOpenFileHandler = (sourceUrl: string) => async () => {
    const res = await dataConnectorController.getSignedDataURL(sourceUrl)

    if (res.status === "SUCCESS") window.open(res.data, "_blank")
  }

  const handleOpenFile =
    item.url != null ? getOpenFileHandler(item.url) : undefined

  const handleAssignDataType =
    isReadonly || isFailed
      ? undefined
      : (e: React.MouseEvent) => {
          e.stopPropagation()
          contextMenuProps.onContext(item.id, "edit_data_type")
        }

  return (
    <Row
      className={clsx(styles.row)}
      loading={isRunning}
      onDoubleClick={handleOpenFile}
    >
      <Column>
        <DocumentIcon icon={icon} height={28} width={28} />
      </Column>

      <Column title={item.name}>
        <Text variant="inherit" className={styles.text}>
          {item.name}
        </Text>
      </Column>

      <Column>
        <FileStatusBadge
          status={item.status}
          progress={item.completePercentage}
          error={item.error}
        />
      </Column>

      <Column>
        <Chip
          color="secondary"
          variant="rounded"
          before={
            dataType != null && <Icon name={dataType.iconUrl ?? "global"} />
          }
          onClick={handleAssignDataType}
          title={dataType != null ? dataType.name : "Not Associated "}
        >
          {dataType != null ? dataType.name : "Not Associated "}
        </Chip>
      </Column>

      <Column>
        <AvatarBubbles avatars={dataType?.channels} />
      </Column>

      <Column>{moment(item.createdAt).format("MMM DD, YYYY")}</Column>

      <Column>
        <UserChip
          data={allUserStore.getById(item.userId ?? null)}
          isLoading={allUserStore.state.isLoading}
        />
      </Column>

      <Column>
        {!!contextMenuProps.options.length && (
          <ContextMenu id={item.id} {...contextMenuProps} />
        )}
      </Column>
    </Row>
  )
})

export default FileRow
