import React from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import useSearch from "@components/hooks/useSearch"
import Icon from "@components/ui/Icon/Icon"
import TextInput from "@components/ui/TextInput/TextInput"
import List from "@components/ui/List/List"
import Templates from "@components/ui/Templates"
import useColumns from "@components/hooks/useColumns"
import { useMultiStep } from "@components/ui/MultiStep/MultiStepContext"
import LoadingInfo from "@pages/launch-solutions/SolutionTabsContainer/LoadingInfo"
import { SearchContext } from "@components/prototypes/SearchContext"
import { NumberedMultiStepControl } from "@pages/launch-solutions/SolutionTabsContainer/components/Tabs/ProductComparison/NumberedMultiStep"

import ComparisonTable from "../ComparisonTable"
import SolutionLoader from "../SolutionLoader"
import RecommendedProductsList from "../ProductList/RecommendedProductsList"
import OtherProducts from "../ProductList/OtherProductsList"
import { ProductsContext } from "../ProductList/ProductsContext"

import styles from "./index.module.sass"

type MyProductsProps = {}

const MyProducts: React.FC<MyProductsProps> = observer(() => {
  const formStepper = useMultiStep()

  const {
    productComparisonStore: {
      selectedProductsStore: { selectedProducts },
      baseProductsViewStore: productsViewStore,
      baseProductsStore: productListStore,
      baseRecommendedProductsStore: recommendedProductsStore,
    },
  } = useStore()

  const products = productListStore.state
  const recommendedProducts = recommendedProductsStore.state

  const [gridRef, columns] = useColumns<HTMLDivElement>({
    minColumnWidth: 250,
    gutter: 24,
    initialValue: products.pagination.pageSize / 2,
  })

  const pageSize = columns > 1 ? columns * 2 : 10

  const [search, searchControl] = useSearch({
    value: products.query,
    onChange: productListStore.search,
  })

  const handleSubmit = formStepper.next

  const handleBack = formStepper.back

  const searchContext = React.useMemo(
    () => ({
      query: search.value,
      setQuery: searchControl.setQuery,
    }),
    [search.value]
  )

  const productsContext = React.useMemo(
    () => ({
      productListStore,
      recommendedProductsStore,
      productsViewStore,
    }),
    []
  )

  if (!recommendedProducts.data.length && recommendedProducts.isLoading) {
    return (
      <SolutionLoader title="My Products">
        <LoadingInfo />
      </SolutionLoader>
    )
  }

  return (
    <ProductsContext.Provider value={productsContext}>
      <SearchContext.Provider value={searchContext}>
        <div className={styles.root}>
          <div className={styles.header}>
            <Button onClick={handleBack} variant="outlined" size="medium">
              Back
            </Button>

            <NumberedMultiStepControl />

            <Button onClick={handleSubmit} color="primary" size="medium">
              Next
            </Button>
          </div>

          <div className={styles.productsContainer}>
            <Templates.Header
              left={
                <List gutter="0" overflow="initial">
                  <Text variant="h4">My Products</Text>
                  <Text variant="h5" color="text50Color">
                    Pick few products to start or search for a product
                  </Text>
                </List>
              }
              right={
                <TextInput
                  placeholder="Search products"
                  before={<Icon name="search" />}
                  className={styles.filterInput}
                  {...search}
                />
              }
            />

            <div className={styles.body} ref={gridRef}>
              {productsViewStore.withRecommended ? (
                <>
                  <RecommendedProductsList />

                  <span className={styles.divider} />
                </>
              ) : null}

              <OtherProducts pageSize={pageSize} />
            </div>
          </div>

          {selectedProducts.length > 0 && (
            <div className={styles.tableContainer}>
              <div className={styles.horizontalScroll}>
                <ComparisonTable />
              </div>
            </div>
          )}
        </div>
      </SearchContext.Provider>
    </ProductsContext.Provider>
  )
})

export default MyProducts
