import React, { MouseEventHandler } from "react"
import moment from "moment"

import UserCard from "@components/ui/UserCard/UserCard"
import Text from "@components/ui/Typography/Text"
import { BEAUTY_DATE_FORMAT } from "@framework/constants/global"

import EntityItem from "../components/ItemsCollection/EntityItem"

interface MeetingEntityItemProps {
  title: string
  subtitle?: string
  timestamp?: number
  active?: boolean
  onClick?: MouseEventHandler
}

const MeetingEntityItem: React.FC<MeetingEntityItemProps> = ({
  title,
  subtitle,
  active,
  timestamp,
  onClick,
}) => {
  return (
    <EntityItem active={active} onClick={onClick}>
      <UserCard
        fullName={title}
        metaInfo={
          <Text variant="h6" color="text50Color">
            {subtitle}
          </Text>
        }
      />
      <Text variant="caption3" color="text40Color">
        {moment(timestamp).format(BEAUTY_DATE_FORMAT)}
      </Text>
    </EntityItem>
  )
}

export default MeetingEntityItem
