import React from "react"

import { SimpleBaseUserData } from "@framework/types/user"
import Avatar from "@components/ui/Avatar/Avatar"
import Text from "@components/ui/Typography/Text"

import styles from "./UserRatingStrip.module.sass"

type UserRatingStripProps = {
  user: SimpleBaseUserData
  rank: number
  rating?: number
}

const UserRatingStrip: React.FC<UserRatingStripProps> = ({
  user,
  rank,
  rating = 0,
}) => {
  const fullName = user.name ?? `${user.firstName} ${user.lastName}`

  return (
    <div className={styles.root}>
      <div className={styles.rank}>{rank}</div>

      <Avatar width={45} height={45} src={user.avatarURL} name={fullName} />

      <div className={styles.details}>
        <Text variant="h5">{fullName}</Text>

        <Text variant="h5">{rating}</Text>
      </div>
    </div>
  )
}

export default UserRatingStrip
