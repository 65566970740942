import React from "react"
import throttle from "lodash/throttle"
import { observer } from "mobx-react-lite"

import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import NotFound from "@components/ui/NotFound/NotFound"
import Pagination from "@components/ui/Pagination/Pagination"
import usePagination from "@components/ui/Pagination/usePagination"
import { useController, useStore } from "@store/index"

import UserGroupsTable from "../components/UsersGroupsTable"

import styles from "./Users.module.sass"

export const UserGroups: React.FC<{ query?: string }> = observer(
  ({ query }) => {
    const { userGroupsController } = useController()
    const { userGroupsStore } = useStore()

    const paginationProps = usePagination({
      pageSize: userGroupsStore.meta.pageSize,
      totalRows: userGroupsStore.meta.total,
      totalPages: userGroupsStore.meta.totalPages,
    })

    const pageNum = paginationProps.forcePage ?? 0
    const { pageSize } = userGroupsStore.meta

    const handleLoadUsers = React.useCallback(
      throttle(userGroupsController.loadUserGroupsPage, 300),
      []
    )

    const needReload = userGroupsStore.pages.size === 0

    React.useEffect(() => {
      handleLoadUsers({
        pageNum,
        pageSize,
        query,
      })
    }, [query, pageNum, pageSize, needReload])

    const userIds = userGroupsStore.getPage(pageNum)
    const nothingIsFound = userIds.length === 0

    return (
      <>
        <LoadingCover
          isLoading={userGroupsStore.isLoading}
          className={styles.body}
        >
          {nothingIsFound && !userGroupsStore.isLoading ? (
            <NotFound>
              No User Groups found
              {query ? (
                <>
                  &nbsp;by query <b>&ldquo;{query}&rdquo;</b>
                </>
              ) : null}
            </NotFound>
          ) : (
            <UserGroupsTable rows={userIds} />
          )}
        </LoadingCover>

        <EntityPageHeaderLayout
          className={styles.footer}
          right={<Pagination {...paginationProps} />}
        />
      </>
    )
  }
)

export default UserGroups
