import React from "react"
import { ChartData } from "chart.js"
import get from "lodash/get"
import { observer } from "mobx-react-lite"

import { useAppColors } from "@components/theme/AppColorSchemeProvider"
import { options } from "@components/ui/StackBarChart/config"
import { YYYY_MM_DD_FORMAT } from "@framework/constants/global"
import { APICallsAmountPointData } from "@framework/types/analytics"
import StackBarChart from "@components/ui/StackBarChart/StackBarChart"

import {
  ChartFractionMetaData,
  labelsFromPeriod,
  transformSearchingTrendData,
} from "./utils"

export interface SearchingTrendChartProps {
  startDate: string
  endDate: string
  data: APICallsAmountPointData[]
  fractions: ChartFractionMetaData[]
}

export const APIUsageTrendChart: React.FC<SearchingTrendChartProps> = observer(
  ({ fractions, startDate, endDate, data }) => {
    const theme = useAppColors()

    const { palette } = theme

    const whiteColor = get(palette, "main-text-100-color", "white")
    const gridColor = get(palette, "secondary-80-color", "#ffffff26")

    const prepareChartData = React.useCallback((): ChartData<"bar"> => {
      const labels = labelsFromPeriod([startDate, endDate], YYYY_MM_DD_FORMAT)

      const datasetFractions = transformSearchingTrendData(labels, data)

      return {
        labels,
        datasets: fractions.map((it) => ({
          type: "bar",
          label: it.label,
          data: get(datasetFractions, it.name, []),
          borderColor: it.borderColor,
          backgroundColor: it.color,
          borderWidth: 1,
          borderRadius: 4,
          barPercentage: 0.5,
          yAxisID: it.yAxisID,
        })),
      }
    }, [fractions, data, startDate, endDate])

    const chartConfig = React.useMemo(() => {
      return options({
        showAxises: true,
        axisColor: whiteColor,
        gridColor,
        dateFormat: YYYY_MM_DD_FORMAT,
      })
    }, [whiteColor])

    return <StackBarChart onLoadData={prepareChartData} config={chartConfig} />
  }
)

export default APIUsageTrendChart
