import React from "react"
import { useNavigate } from "react-router-dom"

import TextInput from "@components/ui/TextInput/TextInput"
import Button from "@components/ui/Button/Button"

import MaintenanceImage from "./Image"

import styles from "./MaintenancePage.module.sass"

const MaintenanceForm: React.FC = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.container}>
      <MaintenanceImage />

      <h2>Under Maintenance</h2>
      <p>
        The page you’re looking for is currently under maintenance and will be
        back soon. Enter your email and we will notify you when come back
      </p>

      <TextInput
        className={styles.input}
        placeholder="Email"
        after={
          <Button
            className={styles.inputButton}
            onClick={() => navigate(-1)}
            color="primary"
          >
            Notify me
          </Button>
        }
      />
    </div>
  )
}

export default MaintenanceForm
