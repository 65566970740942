import { RenderedSection } from "react-virtualized/dist/es/Grid"

import { Point, Range } from "@framework/types/common"

import { RefPoint } from "./types"

export const maxPoint = (p1: RefPoint, p2: RefPoint): RefPoint => {
  const x = Math.max(p1.x, p2.x)
  const y = Math.max(p1.y, p2.y)
  return {
    x,
    y,
    fixedX: x === p1.x ? p1.fixedX : p2.fixedX,
    fixedY: y === p1.y ? p1.fixedY : p2.fixedY,
  }
}

export const minPoint = (p1: RefPoint, p2: RefPoint): RefPoint => {
  const x = Math.min(p1.x, p2.x)
  const y = Math.min(p1.y, p2.y)
  const fixedX = x === p1.x ? p1.fixedX : p2.fixedX
  const fixedY = y === p1.y ? p1.fixedY : p2.fixedY

  return { x, y, fixedX, fixedY }
}

export const indexToAlphabetCode = (index: number): string => {
  if (index < 0) return ""
  return (
    indexToAlphabetCode(Math.floor(index / 26) - 1) +
    String.fromCharCode(65 + (index % 26))
  )
}

export const pointToCode = (range: RefPoint) => {
  return `${range.fixedX ? "$" : ""}${indexToAlphabetCode(range.x)}${
    range.fixedY ? "$" : ""
  }${range.y + 1}`
}

export const rangeToCode = (range: Range<Point>) => {
  if (totalRangeCells(range) > 1)
    return `${pointToCode(range.start)}:${pointToCode(range.end)}`

  return pointToCode(range.start)
}

export const shiftRef = (ref: string, shift: Point) => {
  const refRange = refToRange(ref)
  return rangeToCode(
    makeRange(
      shiftPoint(refRange.start, shift),
      shiftPoint(refRange.end, shift)
    )
  )
}

export const shiftPoint = (ref: RefPoint, shift: Point) => {
  const x = ref.fixedX ? ref.x : ref.x + shift.x
  const y = ref.fixedY ? ref.y : ref.y + shift.y

  if (x < 0 || y < 0) throw new Error("Reference is out of boundary")

  return { ...ref, x, y }
}

/**
 * @returns true if two ranges are equal
 */
export const equalRanges = (r1: Range<Point>, r2: Range<Point>) => {
  return (
    r1.start.x === r2.start.x &&
    r1.end.x === r2.end.x &&
    r1.start.y === r2.start.y &&
    r1.end.y === r2.end.y
  )
}

/**
 * @returns new point on the finalRect corresponding to the sourceIndex from sourceRect
 */
export const translateIndexPoint = (
  sourceRect: Range<Point>,
  finalRect: Range<Point>,
  sourceIndex: Point
): Point => {
  const finalXSize = finalRect.end.x - finalRect.start.x + 1
  const finalYSize = finalRect.end.y - finalRect.start.y + 1

  const sourceLocalXIndex = sourceIndex.x - sourceRect.start.x
  const sourceLocalYIndex = sourceIndex.y - sourceRect.start.y

  return {
    x: finalRect.start.x + (sourceLocalXIndex % finalXSize),
    y: finalRect.start.y + (sourceLocalYIndex % finalYSize),
  }
}

/**
 * @returns new range which is difference between ranges passed as an arguments
 */
export const intersection = (
  range1: Range<RefPoint>,
  range2: Range<RefPoint>
): Range<RefPoint> => {
  return {
    start: maxPoint(range1.start, range2.start),
    end: minPoint(range1.end, range2.end),
  }
}

export const merge = (
  range1: Range<RefPoint>,
  range2: Range<RefPoint>
): Range<RefPoint> => {
  return {
    start: minPoint(range1.start, range2.start),
    end: maxPoint(range1.end, range2.end),
  }
}

/**
 * @returns true when the point belongs to the the range
 */
export const includes = (range: Range<Point>, point: Point): boolean => {
  return (
    range.start.x <= point.x &&
    range.end.x >= point.x &&
    range.start.y <= point.y &&
    range.end.y >= point.y
  )
}

export const intersects = (
  range1: Range<Point>,
  range2: Range<Point>
): boolean => {
  return (
    includes(range1, range2.start) ||
    includes(range1, range2.end) ||
    includes(range2, range1.start) ||
    includes(range2, range1.end) ||
    (range1.start.x < range2.start.x &&
      range1.end.x > range2.end.x &&
      range1.start.y > range2.start.y &&
      range1.end.y < range2.end.y) ||
    (range1.start.x > range2.start.x &&
      range1.end.x < range2.end.x &&
      range1.start.y < range2.start.y &&
      range1.end.y > range2.end.y)
  )
}

export const contains = (
  range1: Range<Point>,
  range2: Range<Point>
): boolean => {
  return includes(range1, range2.start) && includes(range1, range2.end)
}

/**
 * @returns new valid range made of two passed points
 */
export const makeRange = (p1: RefPoint, p2: RefPoint): Range<RefPoint> => {
  return {
    start: minPoint(p1, p2),
    end: maxPoint(p1, p2),
  }
}

/**
 * @returns return number of points inside a passed range
 */
export const totalRangeCells = (range: Range<Point>): number => {
  return (range.end.x - range.start.x + 1) * (range.end.y - range.start.y + 1)
}

export const rangeSize = (range: Range<Point>) => {
  return {
    x: range.end.x - range.start.x + 1,
    y: range.end.y - range.start.y + 1,
  }
}

export const stringifyPoint = (cell: Point) => {
  return `${cell.x}-${cell.y}`
}

export const parsePointString = (cellId: string) => {
  const [x, y] = cellId.split("-")
  return { x: Number(x), y: Number(y) }
}

export const letterCodeToNumber = (letterCode: string) => {
  let n = 0

  for (let i = 0; i < letterCode.length; i += 1) {
    n = letterCode.charCodeAt(i) - 64 + n * 26
  }

  return n
}

export const refToPoint = (ref: string): RefPoint => {
  const tokens = ref.match(/(^\$?[a-zA-Z]+)|(\$?[0-9]+$)/g)

  if (tokens == null || tokens.length === 0)
    throw new Error("Incorrect ref format")

  const x = letterCodeToNumber(tokens[0].replaceAll("$", "")) - 1
  const y = Number(tokens[1].replaceAll("$", "")) - 1

  if (x < 0 || y < 0) throw new Error("Reference is out of boundary")

  return {
    fixedX: tokens[0].startsWith("$"),
    fixedY: tokens[1].startsWith("$"),
    x,
    y,
  }
}

export const refToRange = (ref: string): Range<RefPoint> => {
  if (ref.includes(":")) {
    const parts = ref.split(":")
    return makeRange(refToPoint(parts[0]), refToPoint(parts[1]))
  }
  return { start: refToPoint(ref), end: refToPoint(ref) }
}

export const renderedSectionToRange = (section: RenderedSection) => {
  return {
    start: {
      x: section.columnStartIndex,
      y: section.rowStartIndex,
    },
    end: {
      x: section.columnStopIndex,
      y: section.rowStopIndex,
    },
  }
}

export const forEachOfRange = (
  range: Range<Point>,
  callback: (index: Point) => void
) => {
  const totalCols = range.end.x - range.start.x + 1
  const totalRows = range.end.y - range.start.y + 1

  for (let xi = 0; xi < totalCols; xi += 1) {
    for (let yi = 0; yi < totalRows; yi += 1) {
      callback({ x: range.start.x + xi, y: range.start.y + yi })
    }
  }
}

export const validateOperand = (arg: unknown) => {
  const candidate = arg || 0
  if (typeof candidate === "object") throw new Error("Invalid operand value")
  return Number(candidate)
}

export const isValidHttpUrl = (value: any) => {
  try {
    return typeof value === "string" && !!value && !!new URL(value)
  } catch (_) {
    return false
  }
}

export const isFilename = (value: any) => {
  try {
    return (
      typeof value === "string" &&
      !!value &&
      value.match(/^[\w\-. ]+(\.[a-zA-Z]{1,10})+$/g)
    )
  } catch (_) {
    return false
  }
}
