export const useStepper = (options: {
  stepIndex: number
  totalSteps: number
  setStepIndex: (index: number) => void
  onComplete?: () => void
  onExit?: () => void
}) => {
  if (options.totalSteps < 1)
    throw new Error("stepper should at least 1 step long")

  const isFirstStep = options.stepIndex === 0

  const isLastStep = options.stepIndex === options.totalSteps - 1

  const next = () => {
    const nextIndex = options.stepIndex + 1
    if (isLastStep) {
      options.onComplete?.()
      return
    }

    if (nextIndex <= options.totalSteps) options.setStepIndex(nextIndex)
  }

  const back = () => {
    const prevIndex = options.stepIndex - 1

    if (isFirstStep) {
      options.onExit?.()
      return
    }

    if (prevIndex >= 0) options.setStepIndex(prevIndex)
  }

  return { isLastStep, isFirstStep, index: options.stepIndex, next, back }
}

export default useStepper
