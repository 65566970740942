import React from "react"
import { observer } from "mobx-react-lite"
import { Outlet, useParams } from "react-router-dom"

import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import { DataSourceNodeName } from "@framework/types/upload"

import {
  BreadCrumpsCheckpoint,
  BreadCrumpsCheckpointProps,
} from "./BreadCrumpsContext/BreadCrumpsCheckpoint"

export const DataConnectorGroupOutlet: React.FC<BreadCrumpsCheckpointProps> =
  observer(({ ...rest }) => {
    const { groupName } = useParams<{
      groupName: string
    }>()

    if (groupName == null) throw new Error("groupName and required")

    const render = React.useCallback(
      () => (
        <DataConnectorBreadCrump
          dataConnectorType={groupName as DataSourceNodeName}
        />
      ),
      [groupName]
    )

    return (
      <BreadCrumpsCheckpoint render={render} {...rest}>
        <Outlet />
      </BreadCrumpsCheckpoint>
    )
  })

export default DataConnectorGroupOutlet

export const DataConnectorBreadCrump: React.FC<{
  dataConnectorType: DataSourceNodeName
}> = observer(({ dataConnectorType }) => {
  const description = getDataSourceNodeContextProps(dataConnectorType)

  return (
    <>
      <DocumentIcon height={20} width={20} icon={description.icon} />{" "}
      {description.label}
    </>
  )
})
