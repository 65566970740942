import React from "react"
import { Field, FieldProps } from "formik"

import OptionsGroup from "@components/prototypes/FilterSidebar/components/OptionGroup/OptionGroup"
import { DD_MM_YYYY_FORMAT } from "@framework/constants/global"
import { apiCallStatusOptions } from "@framework/constants/analytics"
import DateFormField from "@components/prototypes/form-elements/DateFormField"
import APIKeySelect from "@components/prototypes/FilterSidebar/components/APIKeySelect/APIKeySelect"

const FilterFormBody: React.FC = () => (
  <>
    <Field
      name="date"
      label="Date & Time"
      format={DD_MM_YYYY_FORMAT}
      component={DateFormField}
    />

    <Field
      name="apiKeyName"
      label="API Key"
      valueKey="name"
      component={APIKeySelect}
    />

    <Field name="status">
      {({ field, form }: FieldProps) => (
        <OptionsGroup
          label="Status"
          options={apiCallStatusOptions}
          value={field.value}
          onChange={(value) => form.setFieldValue(field.name, value)}
        />
      )}
    </Field>
  </>
)

export default FilterFormBody
