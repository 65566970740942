import React from "react"
import clsx from "clsx"
import { AutoSizer } from "react-virtualized"

import useToggle from "@components/hooks/useToggle"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import HTMLText from "@components/ui/Typography/HTMLText"
import TextResult from "@pages/launch-solutions/SolutionTabsContainer/components/Tabs/FactFinder/components/SourceView/components/TextResult/TextResult"
import Button from "@components/ui/Button/Button"
import { AnswerData } from "@framework/types/search"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import PreviewList from "./components/PreviewList"
import AnswerViewer from "./components/AnswerViewer"
import Title from "./components/Title"

import styles from "./AnswerOriginalView.module.sass"

export type ImageDetails = {
  id: string
  url: string
  citationNumber: number
  description?: string
  isExpertAnswer?: boolean
  details: AnswerData
}

interface PDFViewerProps {
  image: ImageDetails
  images: ImageDetails[]
  onSelect: (imageDetails: ImageDetails) => void
}

const AnswerOriginalView: React.FC<PDFViewerProps> = ({
  image,
  images = [],
  onSelect,
}) => {
  const isTablet = useMediaQuery(AppMediaQueries.minTablet)

  const { isOpened, handleToggle, setOpened } = useToggle()
  const [showText, setShowText] = React.useState(false)

  const handleImageChange = (newImage: ImageDetails) => {
    onSelect(newImage)
  }

  const toggleTextView = () => {
    setShowText((p) => !p)
  }

  React.useEffect(() => {
    if (isOpened && !image?.url) setOpened(false)
  }, [image])

  return (
    <div className={clsx(styles.root, { [styles.fullscreen]: isOpened })}>
      {!isOpened && (
        <div className={styles.header}>
          <Text
            variant={isTablet ? "h5" : "h2"}
            align="center"
            className={styles.citationHeader}
          >
            Citation
            <span>{image.citationNumber}</span>
          </Text>

          {image.details.source_name && <Title answer={image.details} />}
        </div>
      )}

      <div className={styles.view}>
        <AnswerViewer
          image={image}
          fullscreen={isOpened}
          onFullScreenToggle={handleToggle}
        />
      </div>

      <div className={styles.sidebar}>
        <AutoSizer>
          {(size) => (
            <div style={{ ...size, overflow: "hidden" }}>
              <PreviewList
                image={image}
                images={images}
                onSelect={handleImageChange}
                orientation={isTablet ? "horizontal" : "vertical"}
              />
            </div>
          )}
        </AutoSizer>
      </div>

      {!isOpened && !!image?.url && (
        <div className={styles.footer}>
          {!image?.isExpertAnswer && (
            <Button
              color="secondary"
              size="small"
              className={styles.textViewToggleButton}
              onClick={toggleTextView}
              after={
                <Icon name="line-arrow-down" rotateAngle={showText ? 180 : 0} />
              }
            >
              Extracted Text
            </Button>
          )}

          {showText &&
            (image?.isExpertAnswer ? (
              <HTMLText className={styles.textContent}>
                {image?.description}
              </HTMLText>
            ) : (
              <TextResult
                className={styles.textContent}
                content={image?.description}
              />
            ))}
        </div>
      )}
    </div>
  )
}

export default AnswerOriginalView
