import React from "react"

export const calcPosition = (
  contRect: DOMRect,
  popRect: DOMRect,
  placement = "bottom",
  alignment = "start",
  margin = 4
) => {
  const viewPortHeight = document.documentElement.clientHeight
  const viewPortWidth = document.documentElement.clientWidth

  const params: React.CSSProperties = {
    maxHeight: viewPortHeight / 2,
  }

  const isPlacementAvailable = canDrawRect(contRect, popRect, placement)

  if (placement === "top" || placement === "bottom") {
    // horizontal
    if (isPlacementAvailable ? placement === "top" : placement === "bottom") {
      params.top = contRect.top - popRect.height
      params.paddingBottom = margin
    } else {
      params.top = contRect.bottom
      params.paddingTop = margin
    }

    // vertical
    if (alignment === "start") {
      params.left = contRect.left
      params.minWidth = contRect.width
      params.width = "min-content"
    } else {
      params.right = viewPortWidth - contRect.right
      params.minWidth = contRect.width
      params.width = "min-content"
    }
  } else if (placement === "right" || placement === "left") {
    // vertical
    if (isPlacementAvailable ? placement === "right" : placement === "left") {
      params.paddingLeft = margin
      params.left = contRect.left + contRect.width
    } else {
      params.paddingRight = margin
      params.left = contRect.left - popRect.width
    }

    // horizontal
    if (alignment === "start") {
      params.top = contRect.top
    } else {
      params.top = contRect.top + contRect.height - popRect.height
    }
  }

  return params
}

export const canDrawRect = (
  contRect: DOMRect,
  popRect: DOMRect,
  placement = "bottom"
) => {
  const viewPortHeight = document.documentElement.clientHeight
  const viewPortWidth = document.documentElement.clientWidth

  if (placement === "top") return contRect.top - popRect.height > 0
  if (placement === "bottom")
    return contRect.bottom + popRect.height < viewPortHeight
  if (placement === "left") return contRect.left - popRect.width > 0
  if (placement === "right")
    return contRect.left + contRect.width + popRect.width < viewPortWidth

  return true
}
