import clsx from "clsx"
import React from "react"
import { useNavigate } from "react-router-dom"

import Button from "@components/ui/Button/Button"
import { AnswerStatus, ExpertQuestion } from "@framework/types/question"
import mainRoutes from "@root/main.routes"
import { useStore } from "@store/index"

import QuestionCard from "../QuestionCard/QuestionCard"

import styles from "./QuestionForm.module.sass"

interface DeclinedQuestionFormProps {
  questionData: ExpertQuestion
  className?: string
}

const DeclinedQuestionForm: React.FC<DeclinedQuestionFormProps> = ({
  className,
  questionData,
}) => {
  const navigate = useNavigate()
  const { knowledgeStore } = useStore()
  const { updateExpertQuestion, loadExpertQuestions } = knowledgeStore

  const handleWaiting = async () => {
    const success = await updateExpertQuestion(
      questionData.id,
      AnswerStatus.WAITING
    )
    if (success) {
      loadExpertQuestions()
      navigate(mainRoutes.question("own", "waiting", questionData.id))
    }
  }

  return (
    <div className={clsx(styles.root, className)}>
      <QuestionCard data={questionData}>
        <div className={styles.control}>
          <Button
            onClick={handleWaiting}
            size="medium"
            variant="contained"
            color="primary"
          >
            Move to Awaiting for answering
          </Button>
        </div>
      </QuestionCard>
    </div>
  )
}

export default DeclinedQuestionForm
