import { nanoid } from "nanoid"
import reduce from "lodash/reduce"
import set from "lodash/set"
import isEmpty from "lodash/isEmpty"
import groupBy from "lodash/groupBy"

import SearchEntityStore from "@store/search/search-entity.store"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import SearchMessageBlockStore from "@store/search/search-message-block.store"
import QueryAttachmentsStore from "@store/search/query-attachments.store"
import QueryAttachment from "@store/search/query-attachment"
import chatService from "@services/chat.service"
import SearchSourcesStore from "@store/search/search-sources.store"
import SearchAnswersDataStore from "@store/search/search-answers-data.store"
import SearchPassagesStore from "@store/search/search-passages.store"
import RestrictionsStore from "@store/restrictions/restrictions.store"
import { extractSources } from "@store/search/dataTransformers"
import searchService, { AnswerResponseData } from "@services/search.service"
import { ChatSource } from "@framework/types/chat"
import {
  AnswerData,
  AnswerItem,
  AnswerSourceMetaData,
} from "@framework/types/search"
import { AvatarData } from "@framework/types/avatar"
import { AnswerViewType } from "@framework/constants/search-results"
import { QueryFilterData } from "@framework/types/query-filter"

import SearchFlowStore from "../search-flow.store"
import FilterEntityStore from "../filter-entity.store"
import DocumentChatSolutionStore from "./document-chat-solution.store"

export class DocumentChatSolutionController {
  readonly documentChatSolutionStore: DocumentChatSolutionStore

  restrictionsStore: RestrictionsStore

  get searchFlow(): SearchFlowStore {
    return this.documentChatSolutionStore.searchFlowStore
  }

  get queryAttachments(): QueryAttachmentsStore {
    return this.documentChatSolutionStore.queryAttachments
  }

  constructor(injections: {
    restrictionsStore: RestrictionsStore
    documentChatSolutionStore: DocumentChatSolutionStore
  }) {
    this.restrictionsStore = injections.restrictionsStore
    this.documentChatSolutionStore = injections.documentChatSolutionStore
  }

  reset = () => {
    this.searchFlow.reset()
    this.queryAttachments.reset()
  }

  search = async (
    query: string,
    avatar: AvatarData,
    solutionId: string,
    appliedFilters: QueryFilterData[]
  ) => {
    try {
      const filter = new FilterEntityStore({
        query,
        avatarName: avatar.name,
        productSolutionId: solutionId,
        avatarId: avatar.id,
      })

      const searchInstance = new SearchEntityStore({
        id: nanoid(),
        filter,
      })

      this.searchFlow.pushSearchInstance(searchInstance)

      await this.handleSearch(avatar.name, searchInstance, solutionId)
    } catch (error: any) {
      // TODO: Add support for error handling
      console.log(error)
    }
  }

  handleSearch = async (
    avatar: string,
    searchEntity: SearchEntityStore,
    solutionId: string
  ) => {
    const searchSessionId = nanoid() // to keep track of blocks

    searchEntity.setLoading(true)
    const handleSessionError = async () => {
      // session was probably expired, re-upload attachments
      // and perform the search again
      await this.createAttachments(this.queryAttachments.attachments)
      await this.handleSearch(avatar, searchEntity, solutionId)
    }

    try {
      await chatService.search(
        {
          avatar,
          query: searchEntity.filter.searchQuery,
          sessionId: this.queryAttachments.sessionId,
          productSolutionId: solutionId,
        },
        {
          onopen: async () => {
            searchEntity.setLoading(true)
          },
          onmessage: (msg) => {
            this.handleMessage(
              searchEntity,
              searchSessionId,
              msg,
              (err: any) => {
                if (err.message === "SESSION_NOT_FOUND") {
                  handleSessionError()
                  return true
                }
                if (err.message === "Too Many Requests") {
                  searchEntity.setError(
                    "Sorry, rate limit is exceeded. Please try again after some time"
                  )
                  return true
                }

                return false
              }
            )
          },
          onclose: () => {
            console.log("closed")
            searchEntity.setLoading(false)
          },
          onerror: (err) => {
            console.error(err)
            searchEntity.setError(err.message)
            searchEntity.setLoading(false)
          },
        }
      )
    } catch (err: any) {
      searchEntity.setError(err.message)
    } finally {
      searchEntity.setLoading(false)
    }
  }

  createAttachment = async (
    attachment: QueryAttachment,
    sessionId?: string
  ) => {
    this.queryAttachments.addAttachment(attachment)
    attachment.setLoading(true)

    try {
      const response = await chatService.createAttachment({
        attachment,
        sessionId,
      })

      attachment.setId(response.data.id)
      attachment.setSessionId(response.data.sessionId)
    } catch (err: any) {
      this.queryAttachments.removeAttachment(attachment)
      throw err
    } finally {
      attachment.setLoading(false)
    }
  }

  createAttachments = async (attachments: QueryAttachment[]) => {
    // for bulk upload, we need a common session id
    const sessionId = nanoid()

    return Promise.all(
      attachments.map((attachment) => {
        return this.createAttachment(attachment, sessionId)
      })
    )
  }

  deleteAttachment = async (attachment: QueryAttachment) => {
    attachment.setLoading(true)

    if (attachment.sessionId) {
      try {
        await chatService.deleteAttachment({
          id: attachment.id,
          sessionId: attachment.sessionId,
        })
      } finally {
        attachment.setLoading(false)
      }
    }

    this.queryAttachments.removeAttachment(attachment)
  }

  deleteAttachments = async () => {
    const { attachments } = this.queryAttachments

    await Promise.all(
      attachments.map((attachment) => {
        return this.deleteAttachment(attachment)
      })
    )
  }

  private handleMessage = (
    search: SearchEntityStore,
    searchSessionId: string,
    msg: any,
    onError?: (err: any) => boolean
  ) => {
    const { event, data } = this.parseMessage(msg)

    switch (event) {
      case "progress": {
        switch (data.type) {
          case "attachments_parse_start": {
            const attachmentsMessageBlock = search.getOrCreateBlockById(
              SearchMessageBlockStore,
              `${searchSessionId}-attachments`
            )
            attachmentsMessageBlock.addMessage(
              `Parsing attachments - 0/${data.attachmentsCount}`
            )
            attachmentsMessageBlock.setLoading(true)
            break
          }
          case "attachment_parsed": {
            const attachmentsMessageBlock = search.getOrCreateBlockById(
              SearchMessageBlockStore,
              `${searchSessionId}-attachments`
            )
            attachmentsMessageBlock.replaceMessage(
              0,
              `Parsing attachments - ${data.attachmentsParsedCount}/${data.attachmentsCount}`
            )
            break
          }
          case "query_start": {
            if (search.hasBlock(`${searchSessionId}-attachments`)) {
              const attachmentsMessageBlock = search.getBlockById(
                `${searchSessionId}-attachments`
              ) as SearchMessageBlockStore

              attachmentsMessageBlock.setLoading(false)
            }

            const searchBlock = search.getOrCreateBlockById(
              SearchSummaryBlockStore,
              `${searchSessionId}-summary`
            )
            searchBlock.addMessage(`Loading summary...`)
            break
          }
          default:
            break
        }
        break
      }
      case "finish": {
        const searchBlock = search.getOrCreateBlockById(
          SearchSummaryBlockStore,
          `${searchSessionId}-summary`
        )

        searchBlock.searchSummary.summary =
          data.summary || "Search returned no results"
        searchBlock.searchSummary.showAttachmentTruncationWarning =
          data.truncated === true

        if (!isEmpty(data.sources)) {
          const answer: AnswerResponseData = this.getAnswersDataFromSources(
            data.sources
          )

          const answersDataStore = new SearchAnswersDataStore({
            rawAnswer: answer,
          })

          const passagesStore = new SearchPassagesStore({
            restrictionsStore: this.restrictionsStore,
            searchAnswers: answersDataStore,
            filter: search.filter,
          })

          const sourcesList = extractSources(answer)

          const sourcesStore = new SearchSourcesStore({
            sourcesList,
            restrictionsStore: this.restrictionsStore,
            searchPassages: passagesStore,
            filter: search.filter,
          })

          searchBlock.setAnswersDataStore(answersDataStore)
          searchBlock.setPassagesStore(passagesStore)
          searchBlock.setSourcesStore(sourcesStore)
        }
        break
      }
      case "error":
        if (!onError || !onError(data)) {
          search.setError(data.message || "Unexpected Error")
        }
        break
      default:
        search.setError("Unexpected Error")
    }
  }

  private parseMessage = (
    msg: any
  ): {
    data: {
      type?: "attachments_parse_start" | "attachment_parsed" | "query_start"
      message?: string
      summary?: string
      truncated?: boolean
      sources?: ChatSource[]
      attachmentsCount?: number
      attachmentIndex?: number
      attachmentName?: string
      attachmentsParsedCount?: number
    }
    event: "progress" | "finish" | "error"
  } => {
    return {
      data: JSON.parse(msg.data),
      event: msg.event,
    }
  }

  private getAnswersDataFromSources(
    sources: ChatSource[] = []
  ): AnswerResponseData {
    return {
      answer: {
        answers: reduce(
          sources,
          (list: AnswerData[], item) => {
            item.passages.forEach((passage) => {
              list.push({
                metadata: { filename: item.name },
                screenshots: [],
                data_type: "summaries",
                source_name: item.name,
                display_source: `Page: ${passage.page}`,
                value: passage.text,
                highlight_list: [],
              })
            })

            return list
          },
          []
        ),
      },
      filesWithHit: reduce(
        sources,
        (obj: Record<string, AnswerSourceMetaData>, item) => {
          set(obj, item.name, {
            hits: item.passages.length,
            source_name: item.name,
          })

          return obj
        },
        {}
      ),
      filesInfo: sources.map((item) => {
        return {
          hits: item.passages.length,
          display_source: item.name,
          source_name: item.name,
        }
      }),
    }
  }

  updateAnswerUpVote = async (
    searchId: string,
    passage: AnswerItem,
    voted: boolean,
    answerType: AnswerViewType,
    avatarId: string
  ): Promise<string | null> => {
    const searchInstance = this.searchFlow.getById(searchId)
    const answer = passage
    try {
      if (!(searchInstance instanceof SearchEntityStore))
        throw new Error(
          "This answers data store does not support voting feature yet"
        )

      if (answer == null) throw new Error("passageId is expired")

      const newVote = voted ? 1 : 0
      const requestAnswer = { ...answer.value, current_user_vote: newVote }

      await searchService.upVoteAnswer(
        searchInstance.filter.searchQuery,
        requestAnswer,
        answerType,
        searchInstance.filter.searchAvatar,
        avatarId
      )

      // eslint-disable-next-line eqeqeq
      answer.value.current_user_vote = newVote
    } catch (error) {
      return `Failed to ${voted ? "upvote" : "downvote"} passage`
    }
    return null
  }
}

export default DocumentChatSolutionController
