import React, { useEffect } from "react"
import ReactDOM from "react-dom"

import { ModalsFactoryProps } from "@components/modals/ModalsFactory"
import { HIDE_BLUR_MODALS, ModalsTypes } from "@components/modals/constants"
import useGlobalController from "@components/hooks/useGlobalScrollController"

import { Component } from "./ModalComponent"
import BlurContainer from "../BlurContainer/BlurContainer"

import styles from "./ModalOverlay.module.sass"

export interface ModalOverlayProps {
  modalId: ModalsTypes | null
  modal: ModalsFactoryProps[ModalsTypes]
  onClose: () => void
}

export const ModalOverlay: React.FC<ModalOverlayProps> = ({
  modalId,
  modal,
  onClose,
}) => {
  const { switchScroll } = useGlobalController()

  useEffect(() => {
    switchScroll(true)
    return () => switchScroll(false)
  }, [])

  return ReactDOM.createPortal(
    <BlurContainer
      blur={!!modalId}
      className={styles.root}
      hideBackgroundBlur={!!(modalId && HIDE_BLUR_MODALS.includes(modalId))}
    >
      <Component id={modalId} props={{ ...modal }} />
    </BlurContainer>,
    document.body
  )
}

export default ModalOverlay
