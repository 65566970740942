import React from "react"
import { useFormik } from "formik"
import * as yup from "yup"

import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import { TextInput, TextInputProps } from "@components/ui/TextInput/TextInput"
import { Option } from "@framework/types/utils"
import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import List from "@components/ui/List/List"

import styles from "./AddDataConnector.module.sass"

export type ConnectorConfluenceDataCenterFormType = {
  name: string
  baseUrl: string
  clientId: string
  clientSecret: string
}

export type ConnectorConfluenceDataCenterFormProps = {
  initialValues?: Partial<ConnectorConfluenceDataCenterFormType>
  isLoading?: boolean
  onSubmit: (form: ConnectorConfluenceDataCenterFormType) => void
}

const formFields: Option<
  keyof ConnectorConfluenceDataCenterFormType,
  TextInputProps
>[] = [
  { name: "name", value: { placeholder: "Connector name" } },
  { name: "baseUrl", value: { placeholder: "Instance URL" } },
  { name: "clientId", value: { placeholder: "Client ID" } },
  {
    name: "clientSecret",
    value: { placeholder: "Client Secret" },
  },
]

const validationSchema = yup.object().shape({
  name: yup.string().required("Field is required"),
  baseUrl: yup.string().required("Field is required"),
  clientId: yup.string().required("Field is required"),
  clientSecret: yup.string().required("Field is required"),
})

const defaultInitialValues: ConnectorConfluenceDataCenterFormType = {
  name: "",
  baseUrl: "",
  clientId: "",
  clientSecret: "",
}

export const ConnectorConfluenceDataCenterForm: React.FC<
  ConnectorConfluenceDataCenterFormProps
> = ({ isLoading, initialValues = null, onSubmit }) => {
  const formik = useFormik<ConnectorConfluenceDataCenterFormType>({
    initialValues: { ...defaultInitialValues, ...initialValues },
    validationSchema,
    onSubmit,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <List gutter="24">
        {formFields.map((item) => {
          const value = formik.values[item.name]
          const error = formik.errors[item.name]
          const isTouched = formik.touched[item.name]
          const hasError = !!error && isTouched
          return (
            <TextInput
              {...item.value}
              id={item.name}
              name={item.name}
              defaultValue={value}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              withError={!!error && isTouched}
              disabled={isLoading}
              after={
                hasError && (
                  <div className={styles.after}>
                    <ErrorChip messagePlacement="left" message={error} />
                  </div>
                )
              }
              key={item.name}
            />
          )
        })}

        <Button
          disabled={isLoading}
          color="primary"
          type="submit"
          after={isLoading && <Loader />}
        >
          Connect
        </Button>
      </List>
    </form>
  )
}

export default ConnectorConfluenceDataCenterForm
