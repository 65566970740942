import { observer } from "mobx-react-lite"
import React from "react"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import IconChip from "@components/ui/Chip/IconChip"
import Container from "@components/ui/Container/Container"
import Icon from "@components/ui/Icon/Icon"
import LinkCard from "@components/ui/LinkCard/LinkCard"
import { useStore } from "@store/index"

import styles from "./Menu.module.sass"

export interface AdminPanelMenuProps {}

export const AdminPanelMenuPage: React.FC<AdminPanelMenuProps> = observer(
  () => {
    const { restrictionsStore: access } = useStore()

    return (
      <MainLayout noPadding>
        <Container>
          <div className={styles.root}>
            <div className={styles.header}>
              <h2>Admin panel</h2>
            </div>
            <div className={styles.body}>
              {access.isUsersListAvailable && (
                <LinkCard
                  to="/admin/users/all"
                  label="User Management"
                  description="Invite new users, reset user password, grant access"
                  icon={
                    <IconChip color="primary">
                      <Icon name="user-settings" />
                    </IconChip>
                  }
                />
              )}

              {access.onlySuperAdminAccess && (
                <LinkCard
                  to="/admin/roles"
                  label="Roles"
                  description="Create or edit user roles"
                  icon={
                    <IconChip color="primary">
                      <Icon name="key-2" />
                    </IconChip>
                  }
                />
              )}

              {access.onlySuperAdminAccess && (
                <LinkCard
                  to="/admin/features"
                  label="Features"
                  description="Add or edit feature flags"
                  icon={
                    <IconChip color="primary">
                      <Icon name="list-check-3" />
                    </IconChip>
                  }
                />
              )}

              {access.isGlossaryAvailable && (
                <LinkCard
                  to="/admin/glossary"
                  label="Glossary"
                  description="Add or edit words in your glossary"
                  icon={
                    <IconChip color="primary">
                      <Icon name="glossary" />
                    </IconChip>
                  }
                />
              )}

              {access.onlySuperAdminAccess && (
                <LinkCard
                  to="/admin/config"
                  label="Instance configuration"
                  description="Configuration for current App instance"
                  icon={
                    <IconChip color="primary">
                      <Icon name="instance-line" />
                    </IconChip>
                  }
                />
              )}

              {access.isAvatarsAvailable && (
                <LinkCard
                  to="/admin/avatars"
                  label="Avatars"
                  description="Manage Avatars"
                  icon={
                    <IconChip color="primary">
                      <Icon name="bubble-chart-fill" />
                    </IconChip>
                  }
                />
              )}

              <LinkCard
                to="/admin/datatypes"
                label="Content Types"
                description="Manage Content Types"
                icon={
                  <IconChip color="primary">
                    <Icon name="storage" />
                  </IconChip>
                }
              />

              {access.canAccessAPIKeys && (
                <LinkCard
                  to="/admin/api-keys"
                  label="API Key Management"
                  description="Manage API Keys"
                  icon={
                    <IconChip color="primary">
                      <Icon name="apps-2-fill" />
                    </IconChip>
                  }
                />
              )}
              {access.isSubscriptionPageAvailable && (
                <LinkCard
                  to="/admin/subscription"
                  label="Subscription Management"
                  description="Manage your subscription"
                  icon={
                    <IconChip color="primary">
                      <Icon name="instance-line" />
                    </IconChip>
                  }
                />
              )}
            </div>
          </div>
        </Container>
      </MainLayout>
    )
  }
)

export default AdminPanelMenuPage
