import sumBy from "lodash/sumBy"
import { makeAutoObservable } from "mobx"
import filter from "lodash/filter"

import { convertToBytes, formatBytes } from "@utils/file"
import QueryAttachment from "@store/search/query-attachment"

export class RequirementsStore {
  attachments: QueryAttachment[]

  requirementText: string

  attachmentsSizeLimit = convertToBytes(20, "MB")

  constructor(options?: {
    requirementText?: string
    attachments?: QueryAttachment[]
  }) {
    this.requirementText = options?.requirementText ?? ""
    this.attachments = [...(options?.attachments ?? [])]

    makeAutoObservable(this)
  }

  get attachmentsSizeLimitText(): string {
    return formatBytes(this.attachmentsSizeLimit)
  }

  checkIfAttachmentsSizeUnderLimit = (
    attachments: QueryAttachment[]
  ): boolean => {
    return sumBy(attachments, "file.size") <= this.attachmentsSizeLimit
  }

  setRequirementText = (text: string) => {
    this.requirementText = text
  }

  addAttachment = (attachment: QueryAttachment) => {
    try {
      const attachments = [...this.attachments, attachment]

      if (!this.checkIfAttachmentsSizeUnderLimit(attachments)) {
        throw new Error(
          `Total size exceed the acceptable limit of - ${this.attachmentsSizeLimitText}`
        )
      }

      this.attachments = attachments
    } catch (e: any) {
      return e?.message ?? "Could not add attachment, please try again later."
    }
    return null
  }

  removeAttachment = (attachment: QueryAttachment) => {
    this.attachments = filter(this.attachments, (att) => {
      return att.id !== attachment.id
    })
  }

  reset = () => {
    this.requirementText = ""
    this.attachments = []
  }
}

export default RequirementsStore
