import { APICallStatus, AssignStatus } from "@framework/types/analytics"
import { Option } from "@framework/types/utils"

export const assignStatusOptions: Option<AssignStatus>[] = [
  { name: "assigned", value: "Assigned" },
  { name: "not-assigned", value: "Not Assigned" },
]

export const apiCallStatusOptions: Option<APICallStatus>[] = [
  { name: "success", value: "Success" },
  { name: "failure", value: "Failure" },
]

export default {}
