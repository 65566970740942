import React from "react"
import { observer } from "mobx-react-lite"
import { useParams } from "react-router-dom"

import SidebarPopup from "@components/modals/components/SidebarPopup"

import ActiveAvatarContextProvider from "../ActiveAvatarContext"
import ExpertInsightsExplorer from "./ExpertInsightsExplorer"

import styles from "./index.module.sass"

type RouterParams = {
  categoryId: string
  avatarId: string
}

export interface ExtendedFilterProps {
  open: boolean
  onClose?: () => void
}

export const ExpertInsightsSidebar: React.FC<ExtendedFilterProps> = observer(
  ({ open = false, onClose }) => {
    const { avatarId } = useParams<RouterParams>()

    if (avatarId == null)
      throw Error("FormalKnowledgeSidebar has required router params: avatarId")

    return (
      <SidebarPopup className={styles.root} open={open} onClose={onClose}>
        <ActiveAvatarContextProvider avatarId={avatarId}>
          <ExpertInsightsExplorer />
        </ActiveAvatarContextProvider>
      </SidebarPopup>
    )
  }
)

export default ExpertInsightsSidebar
