import { DocumentIconType, isDocumentIconName } from "@framework/types/utils"

import { getExtension } from "./textUtils"

export const sizeUnits = [
  "Bytes",
  "KB",
  "MB",
  "GB",
  "TB",
  "PB",
  "EB",
  "ZB",
  "YB",
]
const k = 1024

export const convertToBytes = (value: number, unit: string): number => {
  return value * k ** sizeUnits.indexOf(unit)
}

export const convertBytesToUnit = (size: number, unit: string): number => {
  return size / k ** sizeUnits.indexOf(unit)
}

export const getFileIcon = (file: File) => {
  const extension = getExtension(file.name)
  return (
    isDocumentIconName(`img:${extension}`) ? `img:${extension}` : "img:file"
  ) as DocumentIconType
}

export const getUniqueNameForFile = (file: File): string =>
  `${file.name}-${file.lastModified}`

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (!+bytes) return "0 Bytes"

  const dm = decimals < 0 ? 0 : decimals
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizeUnits[i]}`
}

export const autoDownloadBlob = (file: Blob, filename: string) => {
  const href = URL.createObjectURL(file)

  const link = document.createElement("a")
  link.href = href
  link.setAttribute("download", filename)
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}

export const toBase64 = (file: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })
