import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAlert } from "react-alert"

import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import BackButton from "@components/prototypes/BackButton"
import Button from "@components/ui/Button/Button"
import Container from "@components/ui/Container/Container"
import Icon from "@components/ui/Icon/Icon"
import List from "@components/ui/List/List"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import { useController, useStore } from "@store/index"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"

import SubscriptionsList from "../components/SubscriptionsList"

import styles from "./Subscription.module.sass"

export interface RolesProps {}

export const SubscriptionPage: React.FC<RolesProps> = observer(() => {
  const navigate = useNavigate()
  const alert = useAlert()
  const modal = useModal(ModalsTypes.CONFIRM_MODAL)
  const subscriptionUpgradeModal = useModal(
    ModalsTypes.SUBSCRIPTION_UPGRADE_MODAL
  )
  const { subscriptionStore, restrictionsStore: access } = useStore()
  const { subscriptionController } = useController()
  const { isLoading, isEditable, editedSubscriptionData } = subscriptionStore

  useEffect(() => {
    subscriptionController.init()
  }, [])

  useEffect(() => {
    if (subscriptionStore.error) {
      alert.error(subscriptionStore.error)
    }
  }, [subscriptionStore.error])

  const toggleEdit = async () => {
    if (!access.isSubscriptionUpdateAvailable) return
    if (isEditable) {
      const res = await subscriptionController.updateSubscription(
        editedSubscriptionData
      )
      if ("data" in res && res.data) {
        alert.success(<>Subscription successfully updated</>)
      }
    }
    subscriptionController.setEditable(!isEditable)
  }

  const goBack = () => {
    subscriptionController.setEditable(false)
    navigate("../")
  }

  const handleBack = () => {
    if (isEditable) {
      modal.showModal({
        message: (
          <>
            Edited changes would be lost. <br /> Do you want to continue?
          </>
        ),
        confirm: "negative",
        onConfirm: goBack,
      })
    } else {
      goBack()
    }
  }
  const handleUpgradeClick = () => subscriptionUpgradeModal.showModal({})

  return (
    <MainLayout noPadding>
      <Container>
        <div className={styles.root}>
          <EntityPageHeaderLayout
            className={styles.header}
            left={
              <BackButton onClick={handleBack}>
                Subscription Management
              </BackButton>
            }
            right={
              <List direction="row" justify="flex-end" gutter="16">
                {access.isSubscriptionUpdateAvailable &&
                  !subscriptionStore.errorLoadingData && (
                    <Button
                      disabled={isLoading}
                      className={styles.actionButton}
                      color="primary"
                      size="big"
                      onClick={toggleEdit}
                      before={<Icon name={isEditable ? "done" : "edit"} />}
                    >
                      {isEditable ? "Save" : "Edit"}
                    </Button>
                  )}
                <Button
                  disabled={isLoading}
                  className={styles.actionButton}
                  color="primary"
                  size="big"
                  onClick={handleUpgradeClick}
                  before={<Icon name="rocket" />}
                >
                  Upgrade
                </Button>
              </List>
            }
          />

          <LoadingCover
            isLoading={isLoading}
            className={isEditable ? "" : styles.body}
          >
            {!subscriptionStore.errorLoadingData && (
              <SubscriptionsList className={styles.table} />
            )}
          </LoadingCover>
        </div>
      </Container>
    </MainLayout>
  )
})

export default SubscriptionPage
