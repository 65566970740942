import React from "react"
import { Field } from "formik"

import UserSelect from "@components/prototypes/FilterSidebar/components/UserSelect/UserSelect"
import { DD_MM_YYYY_FORMAT } from "@framework/constants/global"
import DateFormField from "@components/prototypes/form-elements/DateFormField"
import DataTypeSelect from "@components/prototypes/FilterSidebar/components/DataTypeSelect/DataTypeSelect"
import { DateRange } from "@framework/types/common"

export type FilterForm = {
  dataTypes?: string[]
  createdByUserIds?: string[]
  updatedByUserIds?: string[]
  createdAtRange?: DateRange
  lastUpdatedAtRange?: DateRange
}

const CloudFilesFilterForm: React.FC = () => (
  <>
    <Field name="dataTypes" label="Content Type" component={DataTypeSelect} />

    <Field
      name="createdByUserIds"
      label="Created By"
      valueKey="id"
      component={UserSelect}
    />

    <Field
      name="updatedByUserIds"
      label="Updated By"
      valueKey="id"
      component={UserSelect}
    />

    <Field
      name="createdAtRange"
      label="Created At"
      format={DD_MM_YYYY_FORMAT}
      component={DateFormField}
    />

    <Field
      name="lastUpdatedAtRange"
      label="Last Update At"
      format={DD_MM_YYYY_FORMAT}
      component={DateFormField}
    />
  </>
)

export default CloudFilesFilterForm
