import { observer } from "mobx-react-lite"
import React from "react"

import styles from "./MagicTextLoader.module.sass"

const MagicTextLoader: React.FC = observer(({ children }) => {
  return <span className={styles.loader}>{children}</span>
})

export default MagicTextLoader
