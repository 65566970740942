import { observer } from "mobx-react-lite"
import React from "react"

import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"

import styles from "./ConfirmModal.module.sass"

type CallbackType = () => Promise<boolean | void> | boolean | void

export interface ConfirmModalProps {
  message: React.ReactNode
  confirm?: "negative" | "positive"
  confirmButtonText?: string
  rejectButtonText?: string
  onClose?: () => boolean | void
  onConfirm?: CallbackType
  onReject?: CallbackType
}

export const ConfirmModal: React.FC<ConfirmModalProps> = observer(
  ({
    onConfirm,
    onReject,
    message,
    confirmButtonText = "Confirm",
    rejectButtonText = "Cancel",
  }) => {
    const [loading, setLoading] = React.useState(false)

    const { hideModal } = useModal(ModalsTypes.CONFIRM_MODAL)

    const handleReject = async () => {
      setLoading(true)
      if ((await onReject?.()) ?? true) hideModal()
      setLoading(false)
    }

    const handleConfirm = async () => {
      setLoading(true)
      if ((await onConfirm?.()) ?? true) hideModal()
      setLoading(false)
    }

    return (
      <BaseModal className={styles.root} containerClassName={styles.container}>
        <div className={styles.body}>
          <Icon color="gold" name="alert-fill" />
          <Text variant="h1">Warning</Text>
          <Text variant="body1">{message}</Text>
        </div>
        <div className={styles.footer}>
          <Button variant="outlined" disabled={loading} onClick={handleReject}>
            {rejectButtonText}
          </Button>
          <Button color="primary" disabled={loading} onClick={handleConfirm}>
            {confirmButtonText}
          </Button>
        </div>
      </BaseModal>
    )
  }
)

export default ConfirmModal
