import React from "react"
import { observer } from "mobx-react-lite"
import { useParams } from "react-router-dom"
import type { IndexRange } from "react-virtualized"
import debounce from "lodash/debounce"

import { useController } from "@store/index"
import TextInput from "@components/ui/TextInput/TextInput"
import useSearchTextInput from "@components/ui/TextInput/useSearchTextInput"
import useAvatarDataConnectorsFiles from "@store/avatar-knowledge/useAvatarDataConnectorsFiles"
import DataSourceFileCard from "@pages/upload/components/DocumentUploadCard/UploadingDataSourceCard"
import { getDataConnectorsFilesContextByDataConnectorName } from "@framework/constants/upload"

import ItemsGrid from "../components/ItemsGrid/ItemsGrid"
import BreadCrumpsIndicator from "./BreadCrumpsContext/BreadCrumpsIndicator"

import styles from "./index.module.sass"

const COLUMNS = 4

export const DataConnectorFiles: React.FC = observer(() => {
  const { groupName, dataConnectorId, folderId } = useParams<{
    dataConnectorId: string
    groupName: string
    folderId: string
  }>()

  if (dataConnectorId == null || groupName == null || folderId == null)
    throw new Error(
      "DataConnectors Pages requires dataConnectorId, dataConnectorType and folderId params"
    )

  const { collection, resetCollection } = useAvatarDataConnectorsFiles(folderId)

  const { avatarKnowledgeController, dataConnectorController } = useController()

  const sourceTypeDescription =
    getDataConnectorsFilesContextByDataConnectorName(groupName)

  const handleSearch = React.useCallback(debounce(resetCollection, 500), [])

  const search = useSearchTextInput({
    onChange: handleSearch,
  })

  const handleLoad = async ({ startIndex, stopIndex }: IndexRange) => {
    const pageSize = 2 * (stopIndex - startIndex)
    await avatarKnowledgeController.loadDataConnectorFilesList(folderId, {
      pageNum: Math.floor(startIndex / pageSize),
      pageSize,
      query: search.query,
    })
  }

  const getOpenFileHandler = (sourceUrl: string) => async () => {
    const res = await dataConnectorController.getSignedDataURL(sourceUrl)

    if (res.status === "SUCCESS") window.open(res.data, "_blank")
  }

  return (
    <>
      <div className={styles.navigation}>
        <BreadCrumpsIndicator />
      </div>

      <div className={styles.filter}>
        <TextInput {...search.props} />
      </div>

      <ItemsGrid
        key={collection.id}
        total={collection.total}
        totalLoaded={collection.totalLoaded}
        loading={collection.isLoading}
        unit={sourceTypeDescription.itemName ?? "file"}
        rowHeight={161}
        isRowLoaded={({ index }) => collection.getByIndex(index) != null}
        loadMoreRows={handleLoad}
        columnCount={COLUMNS}
        renderCard={(idx) => {
          const data = collection.getByIndex(idx)
          if (!data) return null
          return (
            <DataSourceFileCard
              item={data}
              defaultIcon="img:file"
              onClick={data.url ? getOpenFileHandler(data.url) : undefined}
              key={data.id}
            />
          )
        }}
      />
    </>
  )
})

export default DataConnectorFiles
