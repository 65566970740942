import React from "react"
import { observer } from "mobx-react-lite"
import { useLocation, useNavigate } from "react-router-dom"

import { useSearchBlock } from "@pages/search/SearchContext/SearchResultContext"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import BackButton from "@components/prototypes/BackButton"
import NotFound from "@components/ui/NotFound/NotFound"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import AnswerOriginalView, {
  ImageDetails,
} from "./components/ImageResult/AnswerOriginalView/AnswerOriginalView"

import styles from "./SourceView.module.sass"

export interface SourceViewProps {}

export const SourceView: React.FC<SourceViewProps> = observer(() => {
  const location = useLocation()
  const navigate = useNavigate()

  const isTablet = useMediaQuery(AppMediaQueries.minTablet)

  const { searchEntityBlock, searchBlockId, searchEntity, answerIndex } =
    useSearchBlock(SearchSummaryBlockStore)

  const { allAnswers } = searchEntityBlock.searchPassages || {}

  const citation = Number(answerIndex || 1)

  const containerRef = React.useRef<HTMLDivElement | null>(null)

  const handleCitationChange = (citation: number = 1) => {
    navigate(`../${searchEntity.id}/${searchBlockId}/${citation}`, {
      state: location.state,
      replace: true,
    })
  }

  const imageUrls =
    allAnswers?.map<ImageDetails>((item, index) => ({
      url: Array.isArray(item.value.screenshots)
        ? item.value.screenshots.join(", ")
        : item.value.screenshots ?? "",
      id: item.name,
      citationNumber: index + 1,
      description: (item.value as any).text ?? (item.value as any).value,
      isExpertAnswer: item.value?.source === "expert answer",
      details: item.value,
    })) || []

  const handleImageChange = (imageDetails: ImageDetails) => {
    const newAnswer = allAnswers?.find((item) => item.name === imageDetails?.id)
    if (newAnswer) handleCitationChange(imageDetails.citationNumber)
  }

  const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (containerRef.current === e.target) navigate(-1)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === "Escape") {
      navigate(-1)
    }
  }

  const answer: ImageDetails | undefined = imageUrls?.[citation - 1]

  return (
    <div
      className={styles.root}
      ref={containerRef}
      {...(!isTablet
        ? {
            onClick: handleContainerClick,
            onKeyDown: handleKeyDown,
            role: "button",
            tabIndex: -1,
          }
        : null)}
    >
      <div className={styles.header}>
        <BackButton size="small">Back</BackButton>
      </div>

      <div className={styles.body}>
        {answer != null ? (
          <AnswerOriginalView
            image={answer}
            images={imageUrls}
            onSelect={handleImageChange}
          />
        ) : (
          <NotFound />
        )}
      </div>
    </div>
  )
})

export default SourceView
