import React, { useMemo } from "react"
import moment from "moment"
import { observer } from "mobx-react-lite"

import { DocumentIconType } from "@framework/types/utils"
import {
  ConnectedCloudFolder,
  DataConnectorContentGroupName,
  DataConnectorContentName,
} from "@framework/types/upload"
import { countSuffix } from "@utils/numberUtils"
import BaseDataSourceCard from "@components/prototypes/BaseDataSourceCard/BaseDataSourceCard"
import ErrorFooter from "@components/prototypes/BaseDataSourceCard/ErrorFooter"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import Text from "@components/ui/Typography/Text"

interface UploadingDataSourceCardProps {
  item: ConnectedCloudFolder
  defaultIcon?: DocumentIconType
  control?: React.ReactNode
  contentItemName?: DataConnectorContentGroupName | DataConnectorContentName
  onClick?: (id: string, e: React.MouseEvent) => void
  onRetry?: () => void
}

const DataConnectorFolderCard: React.FC<UploadingDataSourceCardProps> =
  observer(
    ({
      item,
      defaultIcon = "img:folder_full",
      control,
      contentItemName = "file",
      onRetry,
      onClick,
    }) => {
      const { id, error, lastUpdatedAt, status, files } = item
      const icon = defaultIcon

      const sourceTypeDescription =
        getDataSourceNodeContextProps(contentItemName)

      const handleClick = useMemo(
        () => (onClick ? (e: React.MouseEvent) => onClick(id, e) : undefined),
        [id, onClick]
      )

      const renderContent = () => {
        if (status === "failed")
          return (
            <ErrorFooter message={error || "Unknown error"} onRetry={onRetry} />
          )

        if (files) {
          const { loading = 0, connected = 0 } = files

          return (
            <Text variant="caption3" color="text50Color">
              {connected} {sourceTypeDescription.itemName}
              {countSuffix(connected)} connected | {loading}{" "}
              {sourceTypeDescription.itemName}
              {countSuffix(loading)} connecting
            </Text>
          )
        }

        return (
          <Text variant="caption3" color="text50Color">
            Last updates: {moment(lastUpdatedAt).format("YYYY-MM-DD")}
          </Text>
        )
      }

      return (
        <BaseDataSourceCard
          title={item.name}
          icon={icon}
          content={renderContent()}
          onClick={handleClick}
          control={control}
          key={item.id}
        />
      )
    }
  )

export default DataConnectorFolderCard
