import { makeAutoObservable, reaction } from "mobx"

import { AnswerItem } from "@framework/types/search"
import RestrictionsStore from "@store/restrictions/restrictions.store"
import { randomNumber } from "@utils/numberUtils"

import SearchAnswersDataStore from "./search-answers-data.store"
import { getAnswersSortingHandler } from "./utils"
import FilterEntityStore from "./filter-entity.store"

const TOP_RATED_COUNT = 5

export class SearchPassagesStore {
  // injections

  filter: FilterEntityStore

  restrictionsStore: RestrictionsStore

  searchAnswers: SearchAnswersDataStore

  // constructor

  constructor(config: {
    filter: FilterEntityStore
    searchAnswers: SearchAnswersDataStore
    restrictionsStore: RestrictionsStore
  }) {
    makeAutoObservable(this)

    this.searchAnswers = config.searchAnswers
    this.filter = config.filter
    this.restrictionsStore = config.restrictionsStore // FIXME

    this.updateAnswers(this.searchAnswers.allAnswers)

    reaction(
      () => ({
        allAnswers: this.searchAnswers.allAnswers,
        orderBy: this.filter.orderBy,
      }),
      (data) => {
        const { allAnswers, orderBy } = data
        const sortedAnswers = !orderBy
          ? allAnswers
          : this.answersSortHandler(allAnswers, orderBy)

        this.updateAnswers(sortedAnswers)
      },
      {
        fireImmediately: true,
      }
    )
  }

  // state

  isMoreAnswersShown: boolean = false

  isLoadingMore: boolean = false

  loaded: number = 0

  topAnswers: AnswerItem[] = []

  leftoverAnswers: AnswerItem[] = []

  error: string | null = null

  get otherAnswers() {
    return this.leftoverAnswers.slice(0, this.loaded)
  }

  get totalOther() {
    return this.leftoverAnswers.length
  }

  get leftToLoad() {
    return this.totalOther - this.loaded
  }

  get allAnswers() {
    return [...this.topAnswers, ...this.leftoverAnswers]
  }

  // actions

  showAllAnswers = (show: boolean) => {
    this.isMoreAnswersShown = show
  }

  get answersSortHandler() {
    const access = this.restrictionsStore
    return getAnswersSortingHandler(
      access.isAnswersSortingByModifiedDate
        ? { dateFieldName: "value.metadata.created_date" }
        : { dateFieldName: "value.source_date" }
    )
  }

  updateAnswers = (answers: AnswerItem[]) => {
    this.isMoreAnswersShown = false

    this.topAnswers = answers.slice(0, TOP_RATED_COUNT)

    this.leftoverAnswers = answers.slice(TOP_RATED_COUNT, answers.length)

    if (this.leftoverAnswers.length < this.loaded)
      this.loaded = this.leftoverAnswers.length
  }

  loadMore = async (batch: number) => {
    this.isLoadingMore = true
    try {
      this.loaded += await new Promise<number>((resolve) =>
        setTimeout(() => {
          const countToLoad = this.leftToLoad > batch ? batch : this.leftToLoad
          resolve(countToLoad)
        }, randomNumber(100, 300))
      )
    } catch (e) {
      this.error = "Failed to load more answers"
    } finally {
      this.isLoadingMore = false
    }
  }
}

export default SearchPassagesStore
