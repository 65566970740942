import React from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"

import TechnicalSpecsTable from "./TechnicalSpecsTable"
import RelatedDocuments from "./RelatedDocuments"

import styles from "./ProductDetailsContainer.module.sass"

const ProductDetailsContainer: React.FC<{
  selectableProduct?: boolean
}> = observer(({ selectableProduct = false }) => {
  const {
    productComparisonStore: {
      productStore: { product, isLoading, error },
      selectedProductsStore: {
        selectedProducts,
        addSelectedProduct,
        removeSelectedProduct,
      },
    },
  } = useStore()

  const isProductSelected = selectedProducts.find(
    (selectedProduct) => selectedProduct.id === product?.id
  )

  if (isLoading) {
    return <Loader size="large" fluid />
  }

  if (!product) {
    return <NotFound>{error}</NotFound>
  }

  const handleProductSelection = () => {
    if (isProductSelected) {
      removeSelectedProduct(product)
    } else {
      addSelectedProduct(product)
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.leftContainer}>
        <div className={styles.titleContainer}>
          <Text variant="h2" weight="bold">
            {product.name}
          </Text>
          {selectableProduct && (
            <Button
              variant={isProductSelected ? "outlined" : "contained"}
              color={isProductSelected ? "default" : "primary"}
              size="medium"
              onClick={handleProductSelection}
            >
              {isProductSelected ? "Remove" : "Add to Compare"}
            </Button>
          )}
        </div>

        <div className={styles.summaryContainer}>
          <Text variant="h3">Summary</Text>
          <Text variant="caption1">{product.summary}</Text>
        </div>
        <div className={styles.technicalSpecsContainer}>
          <Text variant="h3">Technical Specs</Text>
          <div className={styles.tableContainer}>
            <TechnicalSpecsTable />
          </div>
        </div>
      </div>
      <RelatedDocuments documents={product.documents} />
    </div>
  )
})

export default ProductDetailsContainer
