import React, { useEffect, useRef, useState } from "react"
import { Bar, ChartProps } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  ChartData,
  Filler,
  LinearScale,
  BarElement,
  Tooltip,
  TimeScale,
  ChartOptions,
} from "chart.js"

export const NULL = null as unknown as undefined

ChartJS.register(
  LinearScale,
  TimeScale,
  CategoryScale,
  BarElement,
  Tooltip,
  Filler
)

export type AreaChartProps = {
  onLoadData?: (chart: ChartJS | null) => ChartData<"bar">
  width?: ChartProps["width"]
  height?: ChartProps["height"]
  config?: ChartOptions<"bar">
}

const StackBarChart: React.FC<AreaChartProps> = ({
  onLoadData,
  config,
  height = "100%",
  width = "100%",
}) => {
  const chartRef = useRef<ChartJS<"bar">>(null)

  const [chartData, setChartData] = useState<ChartData<"bar">>({
    datasets: [],
  })

  useEffect(() => {
    setChartData(onLoadData ? onLoadData(chartRef.current) : { datasets: [] })
  }, [onLoadData])

  return (
    <Bar
      ref={chartRef}
      data={chartData}
      options={config}
      height={height}
      width={width}
    />
  )
}

export default StackBarChart
