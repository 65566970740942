import React from "react"
import { observer } from "mobx-react-lite"
import { FieldProps } from "formik"

import Dropdown from "@components/prototypes/FilterSidebar/components/Dropdown/Dropdown"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import { SolutionData } from "@framework/types/solution"
import Rating from "@components/ui/Rating/Rating"

import styles from "./RatingSelect.module.sass"

const SCALE_SIZE = 5

export interface RatingSelectProps extends FieldProps<number[]> {
  label?: string
  valueKey?: keyof SolutionData
}

const RatingSelect: React.FC<RatingSelectProps> = observer(
  ({ label, field, form }) => {
    const [open, setOpen] = React.useState(false)

    const { value = [], name } = field
    const { setFieldValue } = form

    const handleClick = (newValue: number) => {
      setFieldValue(name, [newValue])
    }

    const isChecked = !!value?.length

    const handleUncheck = (e: React.MouseEvent) => {
      if (isChecked) setFieldValue(name, undefined)
      else setOpen(true)
      e.stopPropagation()
    }

    return (
      <Dropdown
        title={label}
        opened={open}
        onClick={() => setOpen((value) => !value)}
        before={
          <CheckboxWithLabel
            checked={!!value?.length}
            onClick={handleUncheck}
          />
        }
      >
        <Rating
          count={SCALE_SIZE}
          value={value[0]}
          onChange={handleClick}
          className={styles.root}
        />
      </Dropdown>
    )
  }
)

export default RatingSelect
