import React from "react"

import { useStore } from "@store/index"

const useAvatarDataConnectorsFiles = (folderId: string) => {
  const { avatarDataConnectorFilesStore } = useStore()

  const resetCollection = React.useCallback(
    () => avatarDataConnectorFilesStore.resetCollection(folderId),
    [folderId]
  )
  return {
    resetCollection,
    collection: avatarDataConnectorFilesStore.getCollection(folderId),
  }
}

export default useAvatarDataConnectorsFiles
