import React from "react"
import { observer } from "mobx-react-lite"

import { useController, useStore } from "@store"
import NotFound from "@components/ui/NotFound/NotFound"
import { Steps } from "@store/product-comparison/product-comparison.store"
import Loader from "@components/ui/Loader/BarLoader"
import { SolutionData } from "@framework/types/solution"

import MyProducts from "./components/MyProducts"
import PitchGeneration from "./components/PitchGeneration"
import ProductSelection from "./components/ProductSelection"
import Requirement from "./components/Requirement"
import TargetCompany from "./components/TargetCompany"
import Themes from "./components/Themes"
import NumberedMultiStep from "./NumberedMultiStep"

import styles from "./index.module.sass"

interface Props {
  data: SolutionData
}

const ProductComparison: React.FC<Props> = observer(({ data }) => {
  const {
    productComparisonStore,
    appStore: { instanceConfig },
  } = useStore()

  const { productComparisonController } = useController()

  React.useEffect(() => {
    const company = instanceConfig.config?.find(
      (config) => config.key === "product-comparison"
    )?.value

    if (
      company &&
      company.baseCompanyId !==
        productComparisonStore.targetCompaniesStore.baseCompany.id
    )
      productComparisonController.reset({
        baseCompany: {
          id: company?.baseCompanyId,
          name: company?.baseCompanyName,
        },
      })
  }, [instanceConfig.config])

  React.useEffect(() => {
    productComparisonStore.summaryStore.subscribeSummary()
    productComparisonStore.baseProductsStore.subscribe()
    productComparisonStore.targetProductsStore.subscribe()

    return () => {
      productComparisonStore.summaryStore.unsubscribeSummary()
      productComparisonStore.baseProductsStore.unsubscribe()
      productComparisonStore.targetProductsStore.unsubscribe()
    }
  }, [])

  return (
    <div className={styles.root}>
      {productComparisonStore.isBaseCompanyValid ? (
        <NumberedMultiStep>
          {(step) => {
            if (step.name === Steps.TargetCompany)
              return <TargetCompany data={data} />

            if (step.name === Steps.Themes) return <Themes />

            if (step.name === Steps.Requirement) return <Requirement />

            if (step.name === Steps.MyProducts) return <MyProducts />

            if (step.name === Steps.CompetitorProducts)
              return <ProductSelection />

            if (step.name === Steps.Summary) return <PitchGeneration />

            throw new Error(`Missing multiform step "${step.name}" handler`)
          }}
        </NumberedMultiStep>
      ) : instanceConfig.isLoading ? (
        <Loader size="large" fluid />
      ) : (
        <NotFound>
          {instanceConfig.error
            ? "Failed to load solution configuration"
            : "Solution configuration was not found"}
        </NotFound>
      )}
    </div>
  )
})

export default ProductComparison
