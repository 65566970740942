import React from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"

const DEFAULT_MESSAGE = "Files uploading will be canceled"

const FileUploadPrompt: React.FC = observer(({ children }) => {
  const { fileUploadingStore } = useStore()

  const totalFiles = fileUploadingStore.tasks.length

  React.useEffect(() => {
    if (totalFiles === 0) return undefined

    const handleBeforeUnload = (e: any) => {
      e.preventDefault()

      e.returnValue = DEFAULT_MESSAGE
      return DEFAULT_MESSAGE
    }

    window.addEventListener("beforeunload", handleBeforeUnload, {
      capture: true,
    })

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload, {
        capture: true,
      })
    }
  }, [fileUploadingStore.isAnyInProgress, totalFiles > 0])

  return <>{children}</>
})

export default FileUploadPrompt
