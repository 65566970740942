import { CellTextAlign, CellTextSize } from "./types"

export const DEFAULT_TEXT_SIZE: CellTextSize = "normal"

export const DEFAULT_ALIGN: CellTextAlign = "start"

export const colorPalette8x7 = [
  "#000000",
  "#5c5c5c",
  "#787878",
  "#949494",
  "#b0b0b0",
  "#cccccc",
  "#ffffff",

  "#4C0000",
  "#660000",
  "#990000",
  "#E60000",
  "#FF3333",
  "#FF6666",
  "#FF9999",

  "#4C2A00",
  "#663800",
  "#995500",
  "#E68000",
  "#FF9933",
  "#FFB266",
  "#FFCC99",

  "#4C4C00",
  "#666600",
  "#999900",
  "#E6E600",
  "#FFFF33",
  "#FFFF66",
  "#FFFF99",

  "#002600",
  "#003300",
  "#004C00",
  "#007300",
  "#339933",
  "#66B266",
  "#99CC99",

  "#00004C",
  "#000066",
  "#000099",
  "#0000E6",
  "#3333FF",
  "#6666FF",
  "#9999FF",

  "#2E004C",
  "#350059",
  "#440073",
  "#6D66B2",
  "#7F80CC",
  "#9980CC",
  "#B299E5",

  "#732673",
  "#993399",
  "#CC4CCC",
  "#F47AF4",
  "#F99BF9",
  "#FDBCFD",
  "#FDD5FD",
]
