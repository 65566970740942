import React from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/BarLoader"
import authService from "@services/auth.service"
import { useAppConfig } from "@components/theme/AppConfigProvider"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

export type LoginSSOFormProps = {
  primary?: boolean
  variant: "SAML" | "OIDC"
}

export const LoginSSOForm: React.FC<LoginSSOFormProps> = observer(
  ({ primary, variant }) => {
    const theme = useAppConfig()
    const isTablet = useMediaQuery(AppMediaQueries.maxTablet)

    const { authStore } = useStore()
    const { isSignInSSOLoading, isLoading } = authStore

    const handleLogin = () => {
      switch (variant) {
        case "SAML":
          window.location.replace(authService.loginSAML())
          break
        case "OIDC":
          window.location.replace(authService.loginOIDC())
          break
        default:
          throw new Error(`Unrecognized SSO variant - ${variant}`)
      }
    }

    const btnLabel = theme.authorization.login.SSO.buttonText

    return (
      <Button
        size={isTablet ? "big" : "large"}
        variant={primary ? "contained" : "outlined"}
        color="primary"
        disabled={isLoading}
        onClick={handleLogin}
        after={isSignInSSOLoading && <Loader />}
      >
        {btnLabel}
      </Button>
    )
  }
)

export default LoginSSOForm
