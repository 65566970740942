import React from "react"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"
import Templates from "@components/ui/Templates"
import Text from "@components/ui/Typography/Text"
import { ExpertArticleDTO } from "@framework/types/knowledge"
import Collapsable from "@components/ui/Collapsable/Collapsable"
import useCollapsible from "@components/ui/Collapsable/useCollapsible"
import IconButton from "@components/ui/IconButton/IconButton"
import InsightsThread from "@pages/expert-insights/Topics/ArticleSection/InsightsThread"

import styles from "./ArticleItem.module.sass"

export interface ArticleItemProps {
  avatarId: string
  data: ExpertArticleDTO
}

const ArticleItem: React.FC<ArticleItemProps> = observer(
  ({ avatarId, data }) => {
    const collapsibleContext = useCollapsible()

    const isOpened = collapsibleContext.isActive(data.id)
    const handleToggle = () => collapsibleContext.toggle(data.id)

    const hasInsights = Number(data.insightCount || 0) > 0

    return (
      <div className={styles.root}>
        <button className={styles.header} onClick={handleToggle} type="button">
          <Templates.Header
            left={
              <div className={styles.headerContent}>
                <Text variant="h4">{data.title}</Text>
                <Text color="text50Color" variant="caption1">
                  {data.insightCount} Contributions
                </Text>
              </div>
            }
            right={
              hasInsights && (
                <IconButton>
                  <Icon name="arrow-down" rotateAngle={isOpened ? 180 : 0} />
                </IconButton>
              )
            }
          />
        </button>

        {hasInsights && (
          <Collapsable open={isOpened}>
            <div className={styles.body}>
              <InsightsThread avatarId={avatarId} articleId={data.id} />
            </div>
          </Collapsable>
        )}
      </div>
    )
  }
)

export default ArticleItem
