import React from "react"
import { observer } from "mobx-react-lite"
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
  List as VirtualList,
  ListProps,
} from "react-virtualized"

import Skeleton from "@components/ui/Skeleton/Skeleton"
import { useStore } from "@store/index"
import NotFound from "@components/ui/NotFound/NotFound"
import UserRatingStrip from "@components/ui/UserCard/UserRatingStrip"
import Loader from "@components/ui/Loader/BarLoader"

import styles from "./AllActiveUsersList.module.sass"

export const AllActiveUsersList: React.FC = observer(() => {
  const {
    usageAnalyticsStore: { activeUsersStore },
  } = useStore()

  const store = activeUsersStore.state

  const cache = React.useMemo(() => {
    return new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 60,
    })
  }, [])

  const renderRow: ListProps["rowRenderer"] = ({
    index,
    key,
    parent,
    style,
  }) => {
    const item = activeUsersStore.getByIndex(index)

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        rowIndex={index}
        parent={parent}
        key={`${key}-cached`}
      >
        <div style={style} key={key}>
          {index !== 0 && <hr className={styles.divider} />}
          {item == null ? (
            <Skeleton count={1} lineHeight={60} minWidth={100} rounded />
          ) : (
            <UserRatingStrip
              rank={index + 1}
              rating={item.totalQueries}
              user={item}
              key={item.id}
            />
          )}
        </div>
      </CellMeasurer>
    )
  }

  return (
    <>
      {store.total === 0 ? (
        store.isLoading ? (
          <Loader size="large" fluid />
        ) : (
          <NotFound>No Experts in this Avatar</NotFound>
        )
      ) : (
        <InfiniteLoader
          isRowLoaded={({ index }) =>
            activeUsersStore.getByIndex(index) != null
          }
          loadMoreRows={activeUsersStore.load}
          rowCount={store.total}
          minimumBatchSize={30}
          threshold={30}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ width, height }) => (
                <VirtualList
                  rowCount={store.total}
                  ref={registerChild}
                  onRowsRendered={onRowsRendered}
                  rowHeight={cache.rowHeight}
                  rowRenderer={renderRow}
                  height={height}
                  width={width}
                />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      )}
    </>
  )
})

export default AllActiveUsersList
