import React from "react"
import clsx from "clsx"
import moment from "moment"

import ChatBubble from "@components/ui/ComicBubble/ChatBubble"
import List from "@components/ui/List/List"
import Chip from "@components/ui/Chip/Chip"

import styles from "./ChatMessages.module.sass"

export type ChatMessage = {
  message: string
  fromMe: boolean
  moment: number
}

interface ChatMessagesProps {
  className?: string
  messages: ChatMessage[]
}

export const ChatMessages: React.FC<ChatMessagesProps> = ({
  messages,
  className,
}) => {
  let prevDate: null | number = null

  return (
    <List gutter="16" className={clsx(styles.root, className)}>
      {messages.map((it) => {
        const day = moment(it.moment).dayOfYear()

        const dateNode =
          prevDate !== day ? (
            <Chip
              color="secondary-solid"
              variant="rounded"
              className={styles.date}
            >
              {moment(it.moment).format("MMMM DD")}
            </Chip>
          ) : null

        prevDate = day

        return (
          <>
            {dateNode}
            <ChatBubble
              from={it.fromMe ? "me" : "another"}
              unixTime={it.moment}
              key={it.message}
            >
              {it.message}
            </ChatBubble>
          </>
        )
      })}
    </List>
  )
}

export default ChatMessages
