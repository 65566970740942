import { useRef } from "react"

import useResizeObserver from "@components/hooks/useResizeObserver"

const useColumns = <T extends Element>(options: {
  minColumnWidth: number
  gutter?: number
  initialValue?: number
}): [React.RefObject<T>, number] => {
  const gridRef = useRef<T>(null)
  const rect = useResizeObserver(gridRef)

  const initialValue = Math.max(options.initialValue ?? 0, 1)

  const containerWidth = rect?.width
  const gutter = options.gutter ?? 0
  const colCount =
    (containerWidth != null
      ? Math.floor(
          (containerWidth + gutter) / (options.minColumnWidth + gutter)
        )
      : initialValue) || 1

  return [gridRef, colCount]
}

export default useColumns
