/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import { Grid, GridCellProps } from "react-virtualized"
import { observer } from "mobx-react-lite"

import AxisCell from "./AxisCell"
import { ResizerHandle, ResizeScale } from "./ResizeScale"
import { AxisName } from "./types"
import { useMatrixContext } from "./MatrixContext"

import styles from "./Axis.module.sass"

interface AxisProps {
  axis: AxisName
  width: number
  height: number
  scrollTop?: number
  scrollLeft?: number
}
export const Axis = observer(
  React.forwardRef<Grid, AxisProps>((props, ref) => {
    const { axis, width, height, scrollTop, scrollLeft } = props

    const { grid, selectedRange } = useMatrixContext()

    const isColumn = axis === "x"

    const renderCell = ({
      rowIndex,
      columnIndex,
      key,
      style,
    }: GridCellProps) => {
      const { height, width, ...rest } = style

      const index = isColumn ? columnIndex : rowIndex

      return (
        <div
          key={key}
          style={rest}
          className={styles.cell}
          onMouseDown={() => {
            selectedRange.startRange({
              x: isColumn ? columnIndex : 0,
              y: isColumn ? 0 : rowIndex,
            })
            selectedRange.updateRange({
              x: isColumn ? columnIndex : grid.totalColumns - 1,
              y: isColumn ? grid.totalRows - 1 : rowIndex,
            })
          }}
          onMouseEnter={() => {
            selectedRange.updateRange({
              x: isColumn ? columnIndex : grid.totalColumns - 1,
              y: isColumn ? grid.totalRows - 1 : rowIndex,
            })
          }}
        >
          <AxisCell axis={axis} index={index} height={height} width={width} />

          <ResizerHandle
            axis={axis}
            index={index}
            minSize={isColumn ? Number(width) - 80 : Number(height) - 50}
          />
        </div>
      )
    }

    const getCellWidth = isColumn ? grid.getCellWidth : () => width
    const getCellHeight = isColumn ? () => height : grid.getCellHeight

    return (
      <>
        <Grid
          ref={ref}
          className={styles.axis}
          cellRenderer={renderCell}
          columnCount={isColumn ? grid.totalColumns : 1}
          rowCount={isColumn ? 1 : grid.totalRows}
          width={width}
          height={height}
          columnWidth={({ index }) => getCellWidth(index)}
          rowHeight={({ index }) => getCellHeight(index)}
          scrollTop={scrollTop}
          scrollLeft={scrollLeft}
          overscanRowCount={grid.overscanCount}
          tabIndex={-1}
        />

        <ResizeScale axis={axis} height={height} width={width} />
      </>
    )
  })
)

export default Axis
