import React from "react"
import { observer } from "mobx-react-lite"

import MultiStepContextProvider, {
  MultiStepContextData,
} from "@components/ui/MultiStep/MultiStepContext"
import { Option } from "@framework/types/utils"
import { useStore } from "@store"
import NumberedStep from "@components/ui/NumberedMultiStep/NumberedStep"
import useStepper from "@components/ui/MultiStep/useStepper"

export type Step = Option

export type NumberedMultiStepProps = {
  children: (step: Step, context: MultiStepContextData) => React.ReactNode
}

export const NumberedMultiStep: React.FC<NumberedMultiStepProps> = observer(
  ({ children }) => {
    const {
      productComparisonStore: { multiformStore },
    } = useStore()

    const handleSetStep = (index: number) => {
      multiformStore.setStepIndex(index)
      multiformStore.setCompletedStepIndex(index - 1)
    }

    const onComplete = () => handleSetStep(0)

    const stepper = useStepper({
      stepIndex: multiformStore.currentStepIndex,
      totalSteps: multiformStore.steps.length,
      setStepIndex: handleSetStep,
      onComplete,
    })

    const step = multiformStore.steps[multiformStore.currentStepIndex]

    const rootRef = React.useRef<HTMLSpanElement>(null)

    React.useEffect(() => {
      rootRef.current?.scrollIntoView()
    }, [stepper.index])

    return (
      <MultiStepContextProvider {...stepper}>
        <span ref={rootRef} />

        {step != null && children(step, stepper)}
      </MultiStepContextProvider>
    )
  }
)

export const NumberedMultiStepControl: React.FC = observer(() => {
  const {
    productComparisonStore: { multiformStore },
  } = useStore()

  const handleJump = (_: string, idx: number) => {
    multiformStore.setStepIndex(idx)
  }

  return (
    <NumberedStep
      items={multiformStore.steps}
      checkedIdx={multiformStore.currentStepIndex}
      lastCompletedStepIndex={multiformStore.completedStepIndex}
      onChange={handleJump}
    />
  )
})

export default NumberedMultiStep
