import React from "react"
import { observer } from "mobx-react-lite"
import { AutoSizer, Grid, InfiniteLoader } from "react-virtualized"
import type { InfiniteLoaderProps } from "react-virtualized"
import type { GridProps } from "react-virtualized/dist/es/Grid"

import { countSuffix } from "@utils/numberUtils"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"

import styles from "./ItemsGrid.module.sass"

export interface ItemsGridProps {
  columnCount?: GridProps["columnCount"]
  totalLoaded?: number
  total: number
  minimumBatchSize?: InfiniteLoaderProps["minimumBatchSize"]
  rowHeight?: GridProps["rowHeight"]
  unit?: string
  loading?: boolean
  isRowLoaded: InfiniteLoaderProps["isRowLoaded"]
  loadMoreRows: InfiniteLoaderProps["loadMoreRows"]
  renderCard: (idx: number) => React.ReactNode
}

export const ItemsGrid: React.FC<ItemsGridProps> = observer(
  ({
    total = 0,
    totalLoaded = total,
    rowHeight = 150,
    loading,
    unit = "item",
    columnCount = 4,
    minimumBatchSize = 40,
    isRowLoaded,
    loadMoreRows,
    renderCard,
  }) => {
    React.useEffect(() => {
      loadMoreRows({ startIndex: 0, stopIndex: minimumBatchSize })
    }, [])

    return (
      <Templates.RollScript
        className={styles.root}
        headerSocket={
          <Templates.Header
            offsetBottom="medium"
            left={
              <Text variant="h5">
                {total} {`${unit}${countSuffix(total)}`}
              </Text>
            }
          />
        }
      >
        {totalLoaded === 0 ? (
          loading ? (
            <Loader size="huge" fluid />
          ) : (
            <NotFound>No {unit}s found</NotFound>
          )
        ) : (
          <div className={styles.container}>
            <InfiniteLoader
              isRowLoaded={isRowLoaded}
              loadMoreRows={loadMoreRows}
              rowCount={total}
              threshold={columnCount * 10}
              minimumBatchSize={minimumBatchSize}
            >
              {({ onRowsRendered, registerChild }) => {
                return (
                  <AutoSizer>
                    {({ height, width }) => (
                      <Grid
                        ref={(grid) => registerChild(grid)}
                        rowCount={Math.ceil(total / columnCount)}
                        overscanRowCount={20}
                        overscanColumnCount={columnCount}
                        rowHeight={rowHeight}
                        columnCount={columnCount}
                        columnWidth={width / 4 - 1}
                        height={height}
                        width={width}
                        cellRenderer={({ rowIndex, columnIndex, style }) => {
                          const idx = rowIndex * columnCount + columnIndex
                          if (idx > total - 1) return null
                          return (
                            <div
                              className={styles.cell}
                              style={style}
                              key={idx}
                            >
                              {renderCard(idx)}
                            </div>
                          )
                        }}
                        onSectionRendered={({
                          columnStartIndex,
                          columnStopIndex,
                          rowStartIndex,
                          rowStopIndex,
                        }) =>
                          onRowsRendered({
                            startIndex:
                              rowStartIndex * columnCount + columnStartIndex,
                            stopIndex:
                              rowStopIndex * columnCount + columnStopIndex,
                          })
                        }
                      />
                    )}
                  </AutoSizer>
                )
              }}
            </InfiniteLoader>
          </div>
        )}
      </Templates.RollScript>
    )
  }
)

export default ItemsGrid
