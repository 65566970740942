import React from "react"
import { observer } from "mobx-react-lite"
import { useLocation } from "react-router-dom"

import Templates from "@components/ui/Templates"
import SidebarPopup from "@components/modals/components/SidebarPopup"

import DataSourceHeader from "../KnowledgeSource/DataSourceHeader"
import ActiveAvatarContextProvider from "../ActiveAvatarContext"
import MeetingsCollection from "./MeetingsCollection"
import Meeting from "./Meeting"

import styles from "./Sidebar.module.sass"

export interface MeetingsSidebarProps {
  open: boolean
  onClose?: () => void
}

export const MeetingsSidebar: React.FC<MeetingsSidebarProps> = observer(
  ({ open = false, onClose }) => {
    const location = useLocation()

    return (
      <ActiveAvatarContextProvider avatarId={location.state?.avatar}>
        <SidebarPopup open={open} onClose={onClose}>
          <Templates.RollScript
            className={styles.root}
            headerSocket={
              <DataSourceHeader title="Meetings" chips={defaultChips} />
            }
          >
            <div className={styles.body}>
              <MeetingsCollection />
              <Meeting />
            </div>
          </Templates.RollScript>
        </SidebarPopup>
      </ActiveAvatarContextProvider>
    )
  }
)

export default MeetingsSidebar

const defaultChips = ["Informal Knowledge"]
