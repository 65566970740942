import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useNavigate, useLocation, Link, matchPath } from "react-router-dom"
import clsx from "clsx"

import mainRoutes from "@root/main.routes"
import Avatar from "@components/ui/Avatar/Avatar"
import { useStore } from "@store/index"
import BurgerButton from "@components/ui/BurgerButton/BurgerButton"
import useToggle from "@components/hooks/useToggle"
import { useAppConfig } from "@components/theme/AppConfigProvider"

import styles from "./Header.module.sass"

export interface HeaderProps {
  renderMenu: (isOpened: boolean, close: () => void) => React.ReactNode
}

export const Header: React.FC<HeaderProps> = observer(({ renderMenu }) => {
  const theme = useAppConfig()

  const navigate = useNavigate()
  const location = useLocation()

  const { isOpened, handleToggle } = useToggle()

  const {
    userStore: { user, fullName },
  } = useStore()

  useEffect(() => {
    if (isOpened) handleToggle()
  }, [location])

  const isAvatarActive =
    matchPath(mainRoutes.profile(), location.pathname) != null

  return (
    <div className={styles.root}>
      <BurgerButton
        className={clsx({ [styles.closeBurger]: isOpened })}
        isOpened={isOpened}
        onClick={handleToggle}
      />

      <Link to="/home" className={styles.logo}>
        {theme.copy.logo}
      </Link>

      <Avatar
        name={fullName}
        active={isAvatarActive}
        src={user?.avatarURL ?? ""}
        onClick={() => navigate(mainRoutes.profile())}
      />

      {renderMenu(isOpened, () => handleToggle())}
    </div>
  )
})

export default Header
