import React from "react"
import { observer } from "mobx-react-lite"

import Table from "@components/ui/BaseTable/Table"

import UserGroupsTableRow from "./TableRow"
import UserGroupsTableHeader from "./TableHeader"

import styles from "./index.module.sass"

export interface UsersTableProps {
  rows?: string[]
}

export const UserGroupsTable: React.FC<UsersTableProps> = observer(
  ({ rows = [] }) => {
    return (
      <div className={styles.root}>
        <Table header={<UserGroupsTableHeader />}>
          {rows.map((id) => (
            <UserGroupsTableRow id={id} key={id} />
          ))}
        </Table>
      </div>
    )
  }
)

export default UserGroupsTable
