import React from "react"
import { observer } from "mobx-react-lite"

import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import { RootDataSourceGroupName } from "@framework/types/upload"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import LinkCard from "@components/ui/LinkCard/LinkCard"
import NotFound from "@components/ui/NotFound/NotFound"
import useAvatarUploadedFilesGroups from "@store/avatar-knowledge/useAvatarUploadedFilesGroups"
import { useController } from "@store/index"

import BreadCrumpsIndicator from "./BreadCrumpsContext/BreadCrumpsIndicator"
import useActiveCategoryContext from "../ActiveDataCategoryContext/useActiveCategoryContext"

import styles from "./index.module.sass"

export interface DataSourcesRootProps {}

const DataSourcesRootPage: React.FC<DataSourcesRootProps> = observer(() => {
  const category = useActiveCategoryContext()

  const { avatarKnowledgeController } = useController()

  const collection = useAvatarUploadedFilesGroups(category.id)

  React.useEffect(() => {
    avatarKnowledgeController.loadUploadedFilesGroups(category.id)
  }, [category.id])

  const links = React.useMemo(() => {
    const res: RootDataSourceGroupName[] = []

    if (
      category.totalFiles &&
      collection.data.find((it) => !!it.totalFiles) != null
    )
      res.push("file")

    if (category.totalWebsites) res.push("website")

    if (category.totalFolders) res.push("data-connector")

    return res
  }, [collection.data])

  return (
    <>
      <div className={styles.navigation}>
        <BreadCrumpsIndicator />
      </div>

      {!links.length ? (
        <NotFound>The are no assigned sources</NotFound>
      ) : (
        <div className={styles.grid}>
          {links.map((dataTypeName) => {
            const context = getDataSourceNodeContextProps(dataTypeName)
            return (
              <LinkCard
                to={dataTypeName}
                disabled={context.name === "unknown"}
                label={context.viewOnlyForm?.label ?? context.label}
                icon={<DocumentIcon icon={context.icon} />}
                description={
                  context.viewOnlyForm?.description ?? context.description
                }
                key={dataTypeName}
              />
            )
          })}
        </div>
      )}
    </>
  )
})

export default DataSourcesRootPage
