import React, { useEffect } from "react"

import { useStore } from "@store"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import ScrollableContainer from "@components/modals/CreateAvatarModal/components/ScrollableContainer/ScrollableContainer"
import Loader from "@components/ui/Loader/BarLoader"

import UpdateCard from "./components/UpdateCard/UpdateCard"

import styles from "./Updates.module.sass"

// const updateMock: AppPatchNote = {
//   version: "2.13.03",
//   date: new Date().toString(),
//   description:
//     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam lectus arcu placerat facilisis dapibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam lectus arcu placerat facilisis dapibus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam lectus arcu placerat facilisis dapibus.",
//   changeList: [
//     "Lorem ipsum dolor sit amet",
//     "Lorem ipsum dolor sit amet",
//     "Lorem ipsum dolor sit amet",
//     "Lorem ipsum dolor sit amet",
//     "Lorem ipsum dolor sit amet",
//   ],
// }

// const updatesListMock = [
//   updateMock,
//   updateMock,
//   updateMock,
//   updateMock,
//   updateMock,
// ]

const UpdatesPage: React.FC = () => {
  const {
    updateStore: { changeLog, isChangeLogLoading, loadChangeLog },
  } = useStore()

  useEffect(() => {
    if (!changeLog && !isChangeLogLoading) loadChangeLog()
  }, [])

  return (
    <MainLayout>
      <div className={styles.root}>
        <div className={styles.container}>
          <h2>Updates</h2>

          <ScrollableContainer className={styles.list}>
            {changeLog?.length ? (
              changeLog.map((update) => (
                <UpdateCard {...update} key={update.version} />
              ))
            ) : isChangeLogLoading ? (
              <Loader size="large" primary className={styles.loader} />
            ) : (
              <h3>No changes found</h3>
            )}
          </ScrollableContainer>
        </div>
      </div>
    </MainLayout>
  )
}

export default UpdatesPage
