import React from "react"

type ClickOutsideParams = {
  element: React.RefObject<HTMLElement | null>
  disabled: boolean
  options?: boolean | AddEventListenerOptions
  callBack: (e: MouseEvent) => void
}

const useClickOutside = ({
  element,
  disabled,
  options,
  callBack,
}: ClickOutsideParams) => {
  React.useEffect(() => {
    if (disabled) return undefined

    const maybeHandler = (event: MouseEvent) => {
      if (!element?.current?.contains(event.target as Node)) {
        callBack(event)
      }
    }

    document.addEventListener("mousedown", maybeHandler, options)

    return () => {
      document.removeEventListener("mousedown", maybeHandler, options)
    }
  }, [element, disabled, options, callBack])
}

export default useClickOutside
