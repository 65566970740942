import { Formula } from "../parser/types"
import { ParseResult } from "../parser"
import EditManager from "./EditManager"
import { validateOperand } from "../utils"
import { validateArguments, validateArgumentsNumber } from "../validation"
import FormulaValidationError from "../errors/FormulaValidationError"

class FormulaExecutor {
  // injections

  manager: EditManager

  // state

  id: string

  readonly formula: ParseResult

  status: "initial" | "inProgress" | "failed" | "completed" | "canceled"

  error: Error | null = null

  refValues: Record<string, any> | null

  result: any | null = null

  abortController: AbortController

  activeSignal: AbortSignal | null = null

  constructor(options: {
    cellID: string
    manager: EditManager
    formula: ParseResult
  }) {
    this.id = options.cellID
    this.manager = options.manager
    this.formula = options.formula
    this.status = "initial"
    this.refValues = null
    this.abortController = new AbortController()
  }

  run = async () => {
    try {
      this.status = "inProgress"
      this.activeSignal = this.abortController.signal

      if (!this.formula.isValid) throw new Error("Formula parsing error")

      this.refValues = await this.manager.getRefValuesAndSubscribe(
        this.id,
        this.formula.refs
      )

      this.result = await this.evaluate(this.formula.matched, this.refValues)
      this.status = "completed"
    } catch (error: any) {
      this.error = error
      this.status = "failed"
    }

    if (this.activeSignal?.aborted) this.status = "canceled"
  }

  evaluate = async (
    formula: Formula,
    values: Record<string, any>
  ): Promise<any> => {
    this.activeSignal?.throwIfAborted()

    if (formula.type === "unknown") throw new Error("Formula parsing error")

    switch (formula.type) {
      case "const": {
        if (typeof formula.value === "string")
          return formula.value?.replace(/^"+|"+$/g, "")
        return formula.value
      }
      case "ref": {
        return values[formula.name]
      }
      case "un-exp": {
        return this.evaluateUnaryExpression(
          formula.operation,
          await this.evaluate(formula.value, values)
        )
      }
      case "bin-exp": {
        return this.evaluateBinaryExpression(
          formula.operation,
          await this.evaluate(formula.left, values),
          await this.evaluate(formula.right, values)
        )
      }
      case "func": {
        return this.evaluateFunction(
          formula.name,
          await Promise.all(
            formula.arguments.map((it) => this.evaluate(it, values))
          )
        )
      }

      default:
        return 0
    }
  }

  protected evaluateUnaryExpression = (operation: "+" | "-", arg: any) => {
    const value = validateOperand(arg)

    if (operation === "-") return -value
    return value
  }

  protected evaluateBinaryExpression = (
    operation: "+" | "-" | "*" | "/",
    arg1: any,
    arg2: any
  ) => {
    const leftValue = validateOperand(arg1)
    const rightValue = validateOperand(arg2)

    if (operation === "-") return leftValue - rightValue
    if (operation === "+") return leftValue + rightValue
    if (operation === "/") return leftValue / rightValue
    return leftValue * rightValue
  }

  protected evaluateFunction = async (
    funcName: string,
    args: any[]
  ): Promise<any> => {
    this.activeSignal?.throwIfAborted()

    const funcDescription = this.manager.functionManager.getFunction(funcName)

    const argumentDescriptions = funcDescription.arguments ?? []
    const requiredArgumentsNum = funcDescription.requiredArgumentsNum ?? 0
    const maxArgumentsNum =
      funcDescription.maxArgumentsNum ?? argumentDescriptions.length

    const error = validateArgumentsNumber(
      args.length,
      requiredArgumentsNum,
      maxArgumentsNum
    )

    const argErrors = validateArguments(
      args,
      argumentDescriptions,
      requiredArgumentsNum,
      maxArgumentsNum
    )

    if (argErrors.length || error) {
      throw new FormulaValidationError(
        funcDescription.name,
        argErrors,
        error ?? "Incorrect arguments passed"
      )
    }

    const res = await funcDescription.handler(
      ...funcDescription.transformArgs(args)
    )

    return res
  }

  cancel = () => {
    this.abortController.abort()
  }
}

export default FormulaExecutor
