import React from "react"

import useEvent from "./useEvent"

interface UseEventListener {
  type: keyof WindowEventMap
  listener: EventListener
  element?:
    | React.RefObject<Element>
    | Document
    | Window
    | null
    | VisualViewport
    | HTMLElement
  options?: boolean | AddEventListenerOptions
  disabled?: boolean
}

export const useEventListener = ({
  type,
  listener,
  element = window,
  options,
  disabled,
}: UseEventListener): void => {
  const handleEventListener = useEvent(listener)

  React.useEffect(() => {
    if (disabled) return undefined

    const target = getRefElement(element)

    target?.addEventListener(type, handleEventListener, options)

    return () => target?.removeEventListener(type, handleEventListener, options)
  }, [type, element, options, disabled, handleEventListener])
}

export const getRefElement = <T>(
  element?: React.RefObject<Element> | T
): Element | T | undefined | null => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (element && "current" in element) {
    return element.current
  }

  return element
}

export default useEventListener
