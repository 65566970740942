import React from "react"
import { observer } from "mobx-react-lite"

import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import IconButton from "@components/ui/IconButton/IconButton"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import useToggle from "@components/hooks/useToggle"
import Icon from "@components/ui/Icon/Icon"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import MenuItem from "@components/containers/Sidebar/MenuItem"
import { useStore } from "@store/index"
import { ContextOption } from "@framework/types/common"

interface ContextMenuProps {
  avatarId: string
  isPublic?: boolean
}

export enum AvatarAction {
  EDIT = "edit",
  DELETE = "delete",
  MANAGE_USERS = "users",
  MANAGE_SOLUTIONS = "solutions",
  MANAGE_DATATYPES = "datatypes",
}

const ContextMenu: React.FC<ContextMenuProps> = observer(
  ({ avatarId, isPublic }) => {
    const {
      restrictionsStore: { canEditAvatar },
    } = useStore()
    const { isOpened, handleToggle, setOpened } = useToggle()

    const contextOptions: ContextOption<AvatarAction>[] = [
      {
        label: "Edit",
        type: AvatarAction.EDIT,
        icon: "edit",
        hidden: !canEditAvatar,
      },
      {
        label: "Manage Users",
        type: AvatarAction.MANAGE_USERS,
        icon: "users",
        hidden: isPublic,
      },
      {
        label: "Manage Solutions",
        type: AvatarAction.MANAGE_SOLUTIONS,
        icon: "jigsaw-puzzle",
      },
      {
        label: "Manage Content Types",
        type: AvatarAction.MANAGE_DATATYPES,
        icon: "storage",
      },
      {
        label: "Remove Avatar",
        type: AvatarAction.DELETE,
        icon: "trash-can",
        color: "red",
        hidden: !canEditAvatar,
      },
    ]

    const deleteModal = useModal(ModalsTypes.DELETE_AVATAR_MODAL)
    const editModal = useModal(ModalsTypes.CREATE_AVATAR_MODAL)
    const manageAvatar = useModal(ModalsTypes.EDIT_AVATAR_MEMBERSHIP_MODAL)

    const handleContextAction = (action: AvatarAction) => {
      switch (action) {
        case AvatarAction.EDIT:
          editModal.showModal({ avatarId })
          break
        case AvatarAction.DELETE:
          deleteModal.showModal({ avatarId })
          break
        case AvatarAction.MANAGE_DATATYPES:
        case AvatarAction.MANAGE_SOLUTIONS:
        case AvatarAction.MANAGE_USERS:
          manageAvatar.showModal({ avatarId, type: action })
          break
        default:
          break
      }
    }

    return (
      <Tooltip
        show={isOpened}
        onClose={() => setOpened(false)}
        mode="onFocus"
        content={
          <TooltipContainer color="primary" placement="bottom">
            {contextOptions.map(({ label, type, ...rest }) => (
              <MenuItem
                key={type}
                onClick={() => handleContextAction(type)}
                {...rest}
              >
                {label}
              </MenuItem>
            ))}
          </TooltipContainer>
        }
      >
        <IconButton size="medium" active={isOpened} onClick={handleToggle}>
          <Icon name="dots" />
        </IconButton>
      </Tooltip>
    )
  }
)

export default ContextMenu
