import React, { useState } from "react"
import { observer } from "mobx-react-lite"

import { ConnectorFilter } from "@framework/types/content-manager"
import IconButton from "@components/ui/IconButton/IconButton"
import Icon from "@components/ui/Icon/Icon"
import Checkbox from "@components/ui/Checkbox/Checkbox"
import Text from "@components/ui/Typography/Text"
import { useStore } from "@store"
import { capitalizeFirstLetter, removeHyphens } from "@utils/textUtils"

import styles from "./FilterOption.module.sass"

export type FilterOptionProps = {
  option: ConnectorFilter
}
const FilterOption: React.FC<FilterOptionProps> = observer(({ option }) => {
  const [showOption, setShowOption] = useState(false)
  const { contentManagerStore } = useStore()

  const isChecked = (key: string, value?: ConnectorFilter) => {
    const data = contentManagerStore.getFilter(key)

    if (value) {
      return data.some((filter) => filter.value === value.value)
    }
    return !!data.length
  }

  const handleCheckboxChange = (key: string, value: ConnectorFilter) => {
    if (isChecked(key, value)) {
      contentManagerStore.removeFilter(key, value)
    } else {
      contentManagerStore.addFilter(key, value)
    }
  }

  return (
    <div className={styles.root}>
      <div
        className={styles.header}
        onClick={() => setShowOption(!showOption)}
        role="button"
        tabIndex={0}
        onKeyPress={() => {}}
      >
        <div>
          <Checkbox checked={isChecked(option.label)} size="tiny" />
          {option.label}
        </div>
        <IconButton>
          <Icon name="arrow-down" rotateAngle={showOption ? 180 : 0} />
        </IconButton>
      </div>
      {showOption && (
        <div className={styles.subOptionContainer}>
          {option.options?.map((item) => (
            <div className={styles.subOption} key={item.value}>
              <Checkbox
                checked={isChecked(option.label, item)}
                onClick={() => handleCheckboxChange(option.label, item)}
                size="tiny"
              />
              {item.icon}
              <Text variant="body2">
                {capitalizeFirstLetter(removeHyphens(item.label))}
              </Text>
            </div>
          ))}
        </div>
      )}
    </div>
  )
})

export default FilterOption
