import React, { useMemo, useCallback } from "react"
import { observer } from "mobx-react-lite"
import sortBy from "lodash/sortBy"
import { useNavigate } from "react-router-dom"

import { useStore } from "@store"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import {
  fileSourcesNames,
  dataConnectorsNames,
  webSideNames,
} from "@framework/types/upload"
import { getDataSourceDetails } from "@utils/product-solution"
import mainRoutes from "@root/main.routes"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import Text from "@components/ui/Typography/Text"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import styles from "./HomeInfo.module.sass"

const dataSourceOptions = sortBy(
  [...dataConnectorsNames, ...fileSourcesNames, ...webSideNames].map(
    getDataSourceNodeContextProps
  ),
  (it) => it.label
)

const DataSources = observer(() => {
  const {
    uploadStore: { dataSourceDetails },
    restrictionsStore: access,
  } = useStore()
  const navigate = useNavigate()
  const isTablet = useMediaQuery(AppMediaQueries.maxTablet)

  const { isLoading, data } = dataSourceDetails.state

  const validOptions = useMemo(
    () =>
      dataSourceOptions.filter((it) => access.testDataSourceAccess(it.name)),
    [dataSourceOptions, access]
  )

  const dataSourceDetailsMemo = useMemo(() => {
    return new Map(
      dataSourceOptions.map((source) => [
        source.name,
        getDataSourceDetails(dataSourceDetails.dataMap.get(source.name)),
      ])
    )
  }, [dataSourceDetails.dataMap, dataSourceOptions])

  const filteredValidOptions = useMemo(() => {
    return validOptions
      .filter(
        (source) =>
          ((dataSourceDetailsMemo.get(source.name)?.totalFiles ||
            dataSourceDetailsMemo.get(source.name)?.count) ??
            0) > 0
      )
      .sort((a, b) => {
        const countA =
          (dataSourceDetailsMemo.get(a.name)?.totalFiles ||
            dataSourceDetailsMemo.get(a.name)?.count) ??
          0
        const countB =
          (dataSourceDetailsMemo.get(b.name)?.totalFiles ||
            dataSourceDetailsMemo.get(b.name)?.count) ??
          0
        return countB - countA
      })
  }, [validOptions, dataSourceDetailsMemo])

  const navigateToDataSource = useCallback(
    () => navigate(mainRoutes.upload()),
    [navigate]
  )

  if (isLoading) {
    return <Skeleton count={5} lineHeight={30} minWidth={100} />
  }

  if (data.length === 0) {
    return null
  }

  return (
    <>
      <Text variant={isTablet ? "h5" : "h3"}>Content Sources</Text>

      <div className={styles.sectionList2}>
        <div className={styles.listWrapper2}>
          {filteredValidOptions.map((source) => (
            <div
              onClick={navigateToDataSource}
              onKeyPress={(e) => {
                if (e.key === "Enter" || e.key === " ") navigateToDataSource()
              }}
              role="button"
              tabIndex={0}
              key={source.name}
              className={styles.product}
            >
              <div className={styles.iconWrapper}>
                <DocumentIcon
                  className={styles.productIcon}
                  icon={source.icon}
                />
                <span>{source.label}</span>
              </div>
              <span>
                {dataSourceDetailsMemo.get(source.name)?.totalFiles ||
                  dataSourceDetailsMemo.get(source.name)?.count}
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  )
})

export default DataSources
