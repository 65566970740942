import React from "react"
import clsx from "clsx"

import styles from "./RatingFrame.module.sass"

export interface RatingFrameProps {
  className?: string
  rating: number // 1 - inf
}

const getMedalName = (rating: number) => {
  if (rating === 1) return "gold"
  if (rating === 2) return "silver"
  if (rating === 3) return "bronze"
  return undefined
}

export const RatingFrame: React.FC<RatingFrameProps> = ({
  rating = 1,
  className,
  children,
}) => {
  const rate = rating < 1 ? 1 : rating
  const rateMedal = getMedalName(rate)
  const badge = rateMedal ? (
    <img
      className={styles.medal}
      src={`/images/medals/${rateMedal}-medal.svg`}
      alt={`/images/medals/${rateMedal}-medal.svg`}
    />
  ) : null

  return (
    <div className={clsx(styles.root, styles[rateMedal ?? ""], className)}>
      {children}
      <span className={styles.badge}>
        {rate}
        {badge}
      </span>
    </div>
  )
}

export default RatingFrame
