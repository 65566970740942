import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx"
import { from, ObservableInput, Subscription, timer } from "rxjs"
import { delay, switchMap, tap, retry } from "rxjs/operators"
import { toStream } from "mobx-utils"

import uploadService from "@services/upload.service"
import { DataSourceName, DataSourceStatusReport } from "@framework/types/upload"

const PULLING_DELAY = 15_000

type State = {
  isLoading: boolean
  data: DataSourceStatusReport[]
  errorMessage: string
  reloadTrigger: boolean
}

export class DataSourcesDetailsStore {
  state: State = {
    isLoading: false,

    data: [],

    errorMessage: "",

    reloadTrigger: true,
  }

  private loadingStream$?: Subscription

  constructor() {
    makeAutoObservable(this)

    onBecomeObserved(this.state, "data", () => {
      this.loadingStream$ = this.initLoadingStream()
    })

    onBecomeUnobserved(this.state, "data", () => {
      this.loadingStream$?.unsubscribe()
    })
  }

  private initLoadingStream = () => {
    const { state } = this

    return from(
      toStream(() => [state.reloadTrigger], true) as ObservableInput<[boolean]>
    )
      .pipe(
        tap(() =>
          runInAction(() => {
            state.isLoading = true
            state.errorMessage = ""
          })
        ),
        switchMap(() => uploadService.getDataSourceStatus$()),
        tap((response) =>
          runInAction(() => {
            state.data = response.data.data ?? []
            state.isLoading = false
          })
        ),
        retry({
          delay: () => {
            runInAction(() => {
              state.errorMessage = "Loading Failed"
            })
            return timer(PULLING_DELAY)
          },
        })
      )
      .subscribe()
  }

  refresh = () => {
    this.state.reloadTrigger = !this.state.reloadTrigger
  }

  get dataMap(): Map<DataSourceName | string, DataSourceStatusReport> {
    return new Map(this.state.data.map((it) => [it.name, it]))
  }
}

export default DataSourcesDetailsStore
