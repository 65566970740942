/* eslint-disable camelcase */
import React from "react"
import { To, useNavigate } from "react-router-dom"
import { useAlert } from "react-alert"
import { observer } from "mobx-react-lite"

import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import MainBoxLayout from "@components/layout/MainBoxLayout/MainBoxLayout"
import { useStore } from "@store/index"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"

import ConnectAmazonS3Form, { FormDataType } from "./ConnectAmazonS3Form"

import styles from "./AddDataConnector.module.sass"

const DEFAULT_TITLE = "Connect to S3 Cloud bucket"
const DEFAULT_REQUIREMENTS = ""

export interface ConnectConfluencePageProps {
  title?: string
  requirements?: string
  backPath: To
}

export const ConnectAmazonS3Page: React.FC<ConnectConfluencePageProps> =
  observer(
    ({
      title = DEFAULT_TITLE,
      requirements = DEFAULT_REQUIREMENTS,
      backPath,
    }) => {
      const navigate = useNavigate()
      const alert = useAlert()

      const {
        uploadSourceStore: { isDataConnectorLoading, connectAmazonS3 },
      } = useStore()

      const handleBack = () => navigate(backPath)

      const handleSubmit = async (form: FormDataType) => {
        const result = await connectAmazonS3(form)

        if (result.status === "FAILED") {
          alert.error(result.message)
          navigate(backPath, {
            replace: true,
          })
          return
        }

        if (result.status === "SUCCESS" && result.data.id) {
          navigate(`${backPath}/${result.data.id}`, { state: "FIRST_TIME" })
          return
        }
        handleBack()
      }

      const isLoading = isDataConnectorLoading

      return (
        <MainBoxLayout className={styles.root} onBack={handleBack}>
          <DocumentIcon
            className={styles.icon}
            icon="img:aws_s3"
            alt="confluence"
          />

          <ModalTitle titleText={title} subtitleText={requirements} />

          <ConnectAmazonS3Form onSubmit={handleSubmit} isLoading={isLoading} />
        </MainBoxLayout>
      )
    }
  )

export default ConnectAmazonS3Page
