import React from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"
import clsx from "clsx"

import { useController, useStore } from "@store/index"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import { DocumentIconType, isDocumentIconName } from "@framework/types/utils"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { getExtension } from "@utils/textUtils"
import Chip from "@components/ui/Chip/Chip"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import UserChip from "@components/prototypes/UserChip"
import AvatarBubbles from "@components/prototypes/AvatarBubbles"
import { DataConnectorSourceName } from "@framework/types/upload"

import { Column, Row } from "./Table"
import FileStatusBadge from "./FileStatusBadge"

import styles from "./FileRow.module.sass"

export const CloudFileRow: React.FC<{
  folderId: string
  dataConnectorId: string
  index: number
  // TODO move to context
  connectorSourceName: DataConnectorSourceName
  defaultFileIcon?: DocumentIconType
}> = observer(
  ({
    index,
    connectorSourceName,
    dataConnectorId,
    folderId,
    defaultFileIcon = "img:file",
  }) => {
    const { allDatatypesStore, uploadStore } = useStore()

    const { dataConnectorController } = useController()

    const store = uploadStore.getCloudFilesStore(
      connectorSourceName,
      dataConnectorId,
      folderId
    )

    const item = store.getByIndex(index)

    if (item == null) return <Skeleton rounded lineHeight={56} minWidth={100} />

    const ext = getExtension(item.name) ?? ""
    const candidateIcon = `img:${ext}`
    const icon = isDocumentIconName(candidateIcon)
      ? candidateIcon
      : defaultFileIcon

    const dataType = allDatatypesStore.getById(
      item.knowledgedatatypes?.[0]?.id ?? null
    )

    const isRunning = item.status === "running"

    const getOpenFileHandler = (sourceUrl: string) => async () => {
      const res = await dataConnectorController.getSignedDataURL(sourceUrl)

      if (res.status === "SUCCESS") window.open(res.data, "_blank")
    }

    const handleOpenFile =
      item.url != null ? getOpenFileHandler(item.url) : undefined

    return (
      <Row
        className={clsx(styles.row)}
        loading={isRunning}
        onDoubleClick={handleOpenFile}
      >
        <Column>
          <DocumentIcon icon={icon} height={28} width={28} />
        </Column>

        <Column title={item.name}>
          <Text variant="inherit" className={styles.text}>
            {item.name}
          </Text>
        </Column>

        <Column>
          <FileStatusBadge
            status={item.status}
            progress={item.completePercentage}
            error={item.error}
          />
        </Column>

        <Column>
          <Chip
            color={dataType != null ? "blue" : "secondary"}
            variant="rounded"
            before={
              dataType != null && <Icon name={dataType.iconUrl ?? "global"} />
            }
            title={dataType != null ? dataType.name : "Not Associated "}
          >
            {dataType != null ? dataType.name : "Not Associated "}
          </Chip>
        </Column>

        <Column>
          <AvatarBubbles avatars={dataType?.channels} />
        </Column>

        <Column>{moment(item.createdAt).format("MMM DD, YYYY")}</Column>

        <Column>
          <UserChip data={item.user} />
        </Column>

        <Column />
      </Row>
    )
  }
)

export default CloudFileRow
