import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"

import styles from "./SolutionCard.module.sass"

const MAIN_MESSAGE = "Can't find the solution you need?"
const CTA_MESSAGE = "Quickly customize your own"

const CreateNewSolutionCard: React.FC<{ onClick?: React.MouseEventHandler }> =
  observer(({ onClick }) => {
    return (
      <button className={styles.root} type="button" onClick={onClick}>
        <div className={clsx(styles.illustrationContainer, styles.dashBorder)}>
          <Icon name="image-add" />
        </div>

        <div className={styles.body}>
          <div className={styles.title}>
            <Text variant="h4" color="text100Color" weight="bold">
              {MAIN_MESSAGE}
            </Text>
          </div>

          <div className={styles.description}>{CTA_MESSAGE}</div>

          <Text variant="body1" className={styles.cta}>
            Create Solution <Icon name="arrow-up" rotateAngle={90} />
          </Text>
        </div>
      </button>
    )
  })

export default CreateNewSolutionCard
