import React from "react"
import clsx from "clsx"

import styles from "./AnswerSection.module.sass"

export interface AnswerTitleProps {
  title: string
  variant?: "title" | "subtitle"
  onClick?: React.MouseEventHandler
}

const AnswerTitle: React.FC<AnswerTitleProps> = ({
  title,
  variant = "title",
  children,
  onClick,
}) => {
  const Component = variant === "title" ? "h4" : "h5"
  return (
    <Component
      title={title}
      onClick={onClick}
      className={clsx(styles.title, {
        [styles.clickable]: onClick,
      })}
    >
      {children ?? title}
    </Component>
  )
}

export default AnswerTitle
