import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import { useStore } from "@store"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import Checkbox from "@components/ui/Checkbox/Checkbox"
import { Product } from "@framework/types/product"
import { SolutionTypes } from "@framework/constants/search-results"

import styles from "./ProductCardSelectable.module.sass"

type ProductCardSelectableProps = {
  product: Product
  company?: string
  variant?: "default" | "recommended"
}

const ProductCardSelectable: React.FC<ProductCardSelectableProps> = observer(
  ({ product, company, variant }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const {
      productComparisonStore: {
        selectedProductsStore: {
          addSelectedProduct,
          removeSelectedProduct,
          selectedProducts,
        },
      },
    } = useStore()
    const [checked, setChecked] = useState(false)

    const handleCheckboxClick = () => {
      if (checked) {
        removeSelectedProduct(product)
        setChecked(false)
      }
      if (!checked) {
        addSelectedProduct(product)
        setChecked(true)
      }
    }

    useEffect(() => {
      if (selectedProducts.find((p) => p.id === product.id)) {
        setChecked(true)
      } else {
        setChecked(false)
      }
    }, [selectedProducts])

    return (
      <div className={clsx(styles.root, styles[`variant-${variant}`])}>
        <div className={styles.titleContainer}>
          <Text variant="h4" weight="bold">
            {product.name}
          </Text>
          <Text variant="caption2" color="text70Color">
            {company}
          </Text>
        </div>
        <div className={styles.viewProduct}>
          <Button
            size="tiny"
            variant="text"
            after={<Icon name="arrow-down" rotateAngle={-90} />}
            onClick={() =>
              navigate(`/solutions/products/${product.id}`, {
                state: {
                  from: location.pathname,
                  solution: SolutionTypes.ProductComparison,
                },
              })
            }
          >
            View
          </Button>
          <Checkbox checked={checked} onClick={handleCheckboxClick} />
        </div>
      </div>
    )
  }
)

export default ProductCardSelectable
