import React from "react"

import { getExtension } from "@utils/textUtils"
import { isDocumentIconName } from "@framework/types/utils"

import DocumentIcon from "../Icon/DocumentIcon"

import styles from "./FileChip.module.sass"

const FileChip: React.FC<{ name: string }> = React.memo(({ name: text }) => {
  const ext = `img:${getExtension(text)}` ?? ""
  const icon = isDocumentIconName(ext) ? ext : "img:file"

  return (
    <span className={styles.root} title={text}>
      <span className={styles.icon}>
        00
        <DocumentIcon icon={icon} />
      </span>
      {text}&nbsp;
    </span>
  )
})

export default FileChip
