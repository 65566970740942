/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import { AxisName } from "./types"
import { useMatrixContext } from "./MatrixContext"

import styles from "./ResizeScale.module.sass"

export const ResizeScale: React.FC<{
  height: any
  width: any
  axis: AxisName
}> = observer(({ axis, height, width }) => {
  const { resizer } = useMatrixContext()

  if (!resizer.active || resizer.axis !== axis) return null

  const isColumn = axis === "x"

  return (
    <div
      className={clsx(
        styles.resizer,
        isColumn ? styles.horizontal : styles.vertical
      )}
      onMouseMove={(e) => resizer.update(isColumn ? e.pageX : e.pageY)}
      style={{
        top: 0,
        left: 0,
        height,
        width,
        position: "absolute",
      }}
    />
  )
})

export const ResizerHandle: React.FC<{
  axis: AxisName
  index: number
  minSize: any
}> = observer(({ index, minSize = Infinity, axis }) => {
  const { resizer, selectedRange } = useMatrixContext()

  const isActive =
    resizer.active && resizer.axis === axis && resizer.cellIndex === index

  const isColumn = axis === "x"

  const style = isActive
    ? {
        transform: `translate${axis.toUpperCase()}(${resizer.shift}px)`,
      }
    : {}

  if (selectedRange.rangeStarted) return null
  return (
    <span
      className={clsx(
        styles.handle,
        isColumn ? styles.horizontal : styles.vertical,
        {
          [styles.active]: isActive,
        }
      )}
      style={style}
      onMouseDown={(e) => {
        resizer.start(
          axis,
          index,
          isColumn ? e.pageX : e.pageY,
          minSize,
          Infinity
        )
        e.preventDefault()
        e.stopPropagation()
      }}
    />
  )
})
