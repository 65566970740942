import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import sortBy from "lodash/sortBy"

import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Button, {
  ButtonColor,
  ButtonVariant,
} from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import { SummaryQueryType } from "@store/search/types"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import { useStore } from "@store/index"
import { countSuffix } from "@utils/numberUtils"

import { useUnstructuredSearchEntity } from "../SearchContext/SearchResultContext"

type ExpertProps = {
  color?: ButtonColor
  variant?: ButtonVariant
}

export const Expert: React.FC<ExpertProps> = observer(
  ({ color = "primary", variant = "outlined" }) => {
    const alert = useAlert()

    const { searchEntity } = useUnstructuredSearchEntity()

    const {
      knowledgeStore: { assignQuestionToExpertDirectly },
    } = useStore()

    const { showModal: showDirectAssignModal, hideModal } = useModal(
      ModalsTypes.ASSIGN_EXPERTS_TO_QUESTIONS_MODAL
    )

    const rawAnswer = searchEntity.allRawData

    const handleAssign = () => {
      showDirectAssignModal({
        title: "Post question",
        avatarId: searchEntity.filter.searchAvatarId,
        questions: [searchEntity.filter.searchQuery],
        onSubmit: async (expertIds, questionNote) => {
          if (!rawAnswer) return

          const summaries: string[] = []

          sortBy(searchEntity.blocks, (block) => {
            if (block instanceof SearchSummaryBlockStore) {
              if (block.queryType === "PRODUCT_FINDER") return 1
              if (block.queryType === "TABLE") return 1
              if (block.queryType === "BASIC") return 2
              if (block.queryType === "ADVANCED") return 3
            }
            return 0
          }).forEach((block) => {
            if (block instanceof SearchSummaryBlockStore) {
              const title = getSummaryTitle(block.queryType)
              summaries.push(
                `<h3>${title}</h3><p>${block.searchSummary.summary}</p><br/>`
              )
            }
          })

          const summary = summaries.join("")

          const error = await assignQuestionToExpertDirectly(
            searchEntity.filter.searchAvatar,
            searchEntity.filter.searchAvatarId,
            searchEntity.filter.searchQuery,
            expertIds,
            rawAnswer,
            questionNote,
            summary
          )

          if (error) {
            alert.error(error)
            return
          }

          const totalExperts = expertIds.length
          alert.success(
            `Question successfully assigned to expert${countSuffix(
              totalExperts
            )}`
          )

          hideModal()
        },
      })
    }

    if (rawAnswer == null) return null

    return (
      <Button
        color={color}
        variant={variant}
        size="small"
        before={<Icon name="users" />}
        onClick={handleAssign}
      >
        Post to expert
      </Button>
    )
  }
)

export default Expert

const getSummaryTitle = (queryType: SummaryQueryType) => {
  switch (queryType) {
    case "ADVANCED":
      return "Revised Summary"
    case "TABLE":
      return "Table Summary"
    case "PRODUCT_FINDER":
      return "Product Recomendation"
    default:
      return "Summary"
  }
}
