import React from "react"

import Avatar from "@components/ui/Avatar/Avatar"
import Text from "@components/ui/Typography/Text"

import styles from "./ExpertBadge.module.sass"

interface ExpertBadgeProps {
  title: string
  subtitle?: string
  src?: string
}

export const ExpertBadge: React.FC<ExpertBadgeProps> = ({
  title,
  subtitle,
  src,
}) => {
  return (
    <div className={styles.root}>
      <Avatar active={false} name={title} src={src} />
      <div className={styles.content}>
        <Text variant="h6">{title}</Text>
        {subtitle != null && (
          <Text variant="caption3" color="text50Color">
            {subtitle}
          </Text>
        )}
      </div>
    </div>
  )
}

export default ExpertBadge
