import React from "react"

import { ActiveAvatarContext } from "."

export const useActiveAvatar = () => {
  const context = React.useContext(ActiveAvatarContext)
  if (context == null) throw new Error("Can't find any ActiveAvatar")
  return context
}

export default useActiveAvatar
