export const unsecuredCopyToClipboard = (text: string) => {
  const textArea = document.createElement("textarea")
  textArea.value = text
  textArea.setAttribute("style", "position: fixed; visibility: hidden")
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  try {
    document.execCommand("copy")
  } catch (err) {
    console.error("Unable to copy to clipboard", err)
  }
  document.body.removeChild(textArea)
  textArea.remove()
}

export const copyToClipboard = (text: string) => {
  if (window.isSecureContext) navigator.clipboard.writeText(text)
  unsecuredCopyToClipboard(text)
}

export const readClipboard = () => {
  return navigator.clipboard.readText()
}
