import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import { useAlert } from "react-alert"

import { useStore } from "@store"
import useQueryParams from "@components/hooks/useQueryParams"
import mainRoutes from "@root/main.routes"

const SSOAuthorize: React.FC = observer(() => {
  const { authStore } = useStore()
  const params = useQueryParams()
  const navigate = useNavigate()
  const alert = useAlert()

  const authorizeSSO = async () => {
    try {
      await authStore.authorizeSSO(params.code)
      navigate(mainRoutes.home(), { state: { reason: "LOGIN" } })
    } catch (err: any) {
      alert.error(err.message)
      navigate(mainRoutes.login())
    }
  }

  React.useEffect(() => {
    authorizeSSO()
  }, [])

  return <></>
})

export default SSOAuthorize
