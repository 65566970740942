import React from "react"
import { observer } from "mobx-react-lite"

import Text from "@components/ui/Typography/Text"
import Chip from "@components/ui/Chip/Chip"
import Avatar from "@components/ui/Avatar/Avatar"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import useActiveCategoryContext from "@pages/search/ActiveDataCategoryContext/useActiveCategoryContext"

import styles from "./index.module.sass"

export interface DataSourceHeaderProps {}

export const DataSourceHeader: React.FC<DataSourceHeaderProps> = observer(
  () => {
    const { avatar } = useActiveAvatar()
    const category = useActiveCategoryContext()

    return (
      <div className={styles.root}>
        <Text variant="h2">
          {category ? category.name : <Skeleton count={1} />}
        </Text>

        <div className={styles.chips}>
          <Chip variant="rounded" color="secondary-solid">
            Formal Knowledge
          </Chip>

          {avatar != null && (
            <Chip
              before={<Avatar src={avatar.imageUrl} />}
              variant="rounded"
              color="secondary-solid"
            >
              {avatar.name}
            </Chip>
          )}
        </div>
      </div>
    )
  }
)

export default DataSourceHeader
