import { AxiosResponse } from "axios"
import { autorun, makeAutoObservable, toJS } from "mobx"
import { Location } from "react-router-dom"

import config from "@root/config"
import { LOGIN_MS_SSO_REDIRECT_URI } from "@pages/upload/AddDataConnector/constants"
import {
  EmailFormType,
  RecoverPasswordFormType,
  SignInFormType,
  SignUpFormType,
  VerifyOtpFormType,
} from "@framework/types/auth"
import authService, {
  AuthResponse,
  ChangePasswordErrorResponse,
  LoginErrorResponse,
  RecoverPasswordErrorResponse,
  SignUpErrorResponse,
  VerifyOtpErrorResponse,
} from "@services/auth.service"
import { capitalizeFirstLetter } from "@utils/textUtils"

import RootStore from "../RootStore"
import accessTokenStore from "./access-token.store"

export const initialVerifyOtpErrors: VerifyOtpFormType = {
  email: "",
  otp: "",
}

export const initialLoginErrors: SignInFormType = {
  email: "",
  password: "",
}

export const initialSignUpErrors: { [key in keyof SignUpFormType]: string } = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  inviteCode: "",
  jobTitle: "",
  businessUnit: "",
}

export const initRecoveryErrors: EmailFormType = {
  email: "",
}

export const initPassChangeErrors: RecoverPasswordFormType = {
  recoveryCode: "",
  password: "",
}

const isFullAccess = config.FULL_ACCESS_MODE

export class AuthStore {
  rootStore: RootStore

  isTokenChecked: boolean = false

  confirmation2FAEmail: string | null = null

  authExpirationDate: Date | null = null // deprecated

  errorMessage: string | null = null

  successMessage: string | null = null

  verifyOtpErrors = initialVerifyOtpErrors

  loginErrors = initialLoginErrors

  signUpErrors = initialSignUpErrors

  recoveryErrors = initRecoveryErrors

  passwordChangeErrors = initPassChangeErrors

  isSessionValidationLoading: boolean = false

  isSignInLoading: boolean = false

  isSignInSSOLoading: boolean = false

  isSignUpLoading: boolean = false

  isPasswordRestoreLoading: boolean = false

  isAccessRequestLoading: boolean = false

  isLogoutLoading: boolean = false

  restrictedLocation: Location | null = null

  get isLoading() {
    return (
      this.isSignInLoading ||
      this.isSignInSSOLoading ||
      this.isSignUpLoading ||
      this.isLogoutLoading ||
      this.isPasswordRestoreLoading ||
      this.isSessionValidationLoading ||
      this.isAccessRequestLoading
    )
  }

  get userStore() {
    return this.rootStore.userStore
  }

  get userProfile() {
    return this.userStore.user
  }

  get isAuthorized() {
    return Boolean(this.userProfile != null && this.isTokenChecked)
  }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore

    if (isFullAccess)
      autorun(() => {
        console.log("AUTH STORE: ", toJS(this))
      })
  }

  verifyOTPCode = async (login: string, verificationCode: string) => {
    this.isSignInLoading = true
    this.verifyOtpErrors = initialVerifyOtpErrors
    try {
      const response = await authService.verifyOtp(login, verificationCode)

      if (response.data.data) {
        this.successLogin(response)
        this.confirmation2FAEmail = null // TODO find out it it needs to be here
        return
      }
    } catch (error: any) {
      const { response } = error

      if (!response) {
        this.errorMessage = "Login failed"
        return
      }

      const responseData: VerifyOtpErrorResponse = response.data

      if (responseData.message === "INCORRECT_OTP") {
        this.verifyOtpErrors.otp = "Incorrect value"
        return
      }

      if (responseData.message === "USER_DOES_NOT_EXIST") {
        this.verifyOtpErrors.email = "User account was deleted"
        return
      }

      if (responseData.message === "VALIDATION_FAILED") {
        this.errorMessage = "Unexpected error"
        return
      }
    } finally {
      this.isSignInLoading = false
    }
  }

  login = async (login: string, pass: string) => {
    this.isSignInLoading = true
    this.loginErrors = initialLoginErrors
    this.confirmation2FAEmail = null
    try {
      const response = await authService.login(login, pass)

      if (response.data.data.access.requires2FA) {
        this.confirmation2FAEmail = login
        return
      }

      this.successLogin(response)
    } catch (error: any) {
      const { response } = error

      if (!response) {
        this.errorMessage = "Login failed"
        return
      }

      const responseData: LoginErrorResponse = response.data

      if (responseData.message === "INVALID_EMAIL") {
        this.loginErrors.email = "Unavailable value"
        return
      }
      if (responseData.message === "USER_DOES_NOT_EXIST") {
        this.loginErrors.email =
          "User with such email doesn't exist. Please sign-up"
        return
      }
      if (responseData.message === "PASSWORD_MISSING") {
        this.loginErrors.password = "Password is required"
        return
      }
      if (responseData.message === "INCORRECT_PASSWORD") {
        this.loginErrors.password = "Unavailable value"
        return
      }
      if (responseData.message === "VALIDATION_FAILED") {
        this.errorMessage = "Unexpected error"
        return
      }
    } finally {
      this.isSignInLoading = false
    }
  }

  guestLogin = async (referrer: string) => {
    this.isSignInLoading = true
    this.loginErrors = initialLoginErrors
    this.confirmation2FAEmail = null
    try {
      const response = await authService.guestLogin(referrer)

      if (response.data.data.access.requires2FA) {
        this.confirmation2FAEmail = "guest"
        return
      }

      this.successLogin(response)
    } catch (error: any) {
      const { response } = error

      if (!response) {
        this.errorMessage = "Login failed"
        return
      }
    } finally {
      this.isSignInLoading = false
    }
  }

  loginWithSSOCode = async (code: string) => {
    this.isSignInSSOLoading = true
    this.loginErrors = initialLoginErrors
    try {
      const response = await authService.loginSSO(
        code,
        LOGIN_MS_SSO_REDIRECT_URI
      )

      this.successLogin(response)
    } catch (error: any) {
      this.errorMessage = "Login failed"
    } finally {
      this.isSignInSSOLoading = false
    }
    return this.errorMessage
  }

  authorizeSSO = async (code: string) => {
    this.isSignInLoading = true
    this.loginErrors = initialLoginErrors

    try {
      const response = await authService.authorizeSSO(code)
      this.successLogin(response)
    } finally {
      this.isSignInLoading = false
    }
  }

  private successLogin = (response: AxiosResponse<AuthResponse>) => {
    const { access } = response.data.data
    if (!access.accessToken || !access.refreshToken) {
      console.error(`invalid access data on login - ${access}`)
      return
    }

    accessTokenStore.accessToken = access.accessToken
    accessTokenStore.refreshToken = access.refreshToken

    const { user } = response.data.data
    this.userStore.setUserData({ ...user })
    this.isTokenChecked = true
  }

  cancelConfirmationData = async () => {
    this.confirmation2FAEmail = null
    this.verifyOtpErrors = initialVerifyOtpErrors
    this.errorMessage = null
  }

  signUp = async (form: SignUpFormType) => {
    this.isSignUpLoading = true
    this.errorMessage = null
    this.signUpErrors = initialSignUpErrors
    try {
      const response = await authService.signUp(form)

      if (response.data.data.access.requires2FA) {
        this.confirmation2FAEmail = form.email
        return
      }

      this.successLogin(response)
    } catch (error: any) {
      const { response } = error

      if (!response) {
        this.errorMessage = "Sign up failed"
        return
      }

      const responseData: SignUpErrorResponse = response.data

      if (responseData.message === "USER_ALREADY_EXIST_WITH_EMAIL") {
        this.signUpErrors.email =
          "User with such email is already exists. Please log in"
        return
      }
      if (responseData.message === "INVALID_EMAIL") {
        this.signUpErrors.email = "Unavailable value"
        return
      }
      if (responseData.message === "INVALID_INVITE_EMAIL") {
        this.signUpErrors.email = "Incorrect email for the invitation code"
        return
      }
      if (responseData.message === "VALIDATION_FAILED") {
        this.errorMessage = "Unexpected error"
        return
      }
    } finally {
      this.isSignUpLoading = false
    }
  }

  passwordRecoveryError: string | null = null

  requestPasswordRecovery = async (form: EmailFormType) => {
    this.isPasswordRestoreLoading = true
    this.passwordRecoveryError = null
    this.recoveryErrors = initRecoveryErrors
    try {
      const response = await authService.recoverPassword(form)

      if (response.data) {
        this.successMessage = `We sent recover link to you email ${form.email}. This page can be safely closed`
        return
      }
    } catch (error: any) {
      const { response } = error

      if (!response) {
        this.passwordRecoveryError = "Can't recover password"
        return
      }

      const responseData: RecoverPasswordErrorResponse = response.data

      if (responseData.status === "USER_NOT_FOUND") {
        this.recoveryErrors.email = "User not found"
        return
      }
    } finally {
      this.isPasswordRestoreLoading = false
    }
  }

  resetPasswordRecovery = async () => {
    this.successMessage = null
    this.passwordRecoveryError = null
  }

  changePassword = async (recoveryCode: string, newPassword: string) => {
    this.isPasswordRestoreLoading = true
    this.passwordRecoveryError = null
    this.passwordChangeErrors = initPassChangeErrors
    this.successMessage = null
    try {
      const response = await authService.changePassword({
        recoveryCode,
        password: newPassword,
      })

      if (response.data) {
        this.successMessage = response.data.message
          ? capitalizeFirstLetter(response.data.message)
          : "Password successfully changed"
        return
      }
    } catch (error: any) {
      const { response } = error

      if (!response) {
        this.passwordRecoveryError = "Can't recover password"
        return
      }

      const responseData: ChangePasswordErrorResponse = response.data

      if (responseData.status === "BAD_PASSWORD") {
        this.passwordChangeErrors.password = responseData.message
          ? capitalizeFirstLetter(responseData.message)
          : "Please use a stronger password"
        return
      }

      if (responseData.status === "AUTHENTICATION_FAILED") {
        this.passwordRecoveryError = "Invalid recovery link"
        return
      }
    } finally {
      this.isPasswordRestoreLoading = false
    }
  }

  checkAuthority = async () => {
    this.isSessionValidationLoading = true
    try {
      const { refreshToken } = accessTokenStore
      if (!refreshToken) throw new Error("No refresh token found")

      const response = await authService.refreshToken(refreshToken)
      accessTokenStore.accessToken = response.data.accessToken

      const error = await this.userStore.loadUserData()
      if (error) throw new Error(error)
    } catch (error) {
      this.cleanSession()
    } finally {
      this.isSessionValidationLoading = false
      this.isTokenChecked = true
    }
  }

  cleanSession = () => {
    accessTokenStore.accessToken = null
    this.userStore.setUserData(null)
    this.userStore.setUserActions(null)
  }

  logout = async () => {
    try {
      await authService.logout()
    } finally {
      window.location.replace("/")
    }
  }

  sessionExpiredError = async () => {
    if (this.isAuthorized) {
      this.errorMessage = "Session has been expired"
    }
    this.cleanSession()
  }

  setRestrictedLocation = (location: Location | null) => {
    this.restrictedLocation = location
  }
}

export default AuthStore
