import { observer } from "mobx-react-lite"
import React from "react"

import { useStore } from "@store"
import Table from "@components/ui/BaseTable/Table"
import getRecursiveProperties from "@pages/launch-solutions/SolutionTabsContainer/utils"

import TechnicalSpecsRow from "./TechnicalSpecsRow"
import TechnicalSpecsTableHeader from "./TechnicalSpecsTableHeader"

import styles from "./TechnicalSpecsTable.module.sass"

const TechnicalSpecsTable: React.FC = observer(() => {
  const {
    productComparisonStore: {
      productStore: { product },
    },
  } = useStore()

  if (!product) {
    return null
  }

  const rows = product.properties.map((property) => ({
    id: property.id,
    property: property.name,
    value: getRecursiveProperties(property),
  }))

  return (
    <Table header={<TechnicalSpecsTableHeader />} className={styles.root}>
      {rows.map((userRequest) => (
        <TechnicalSpecsRow key={userRequest.id} technicalSpecs={userRequest} />
      ))}
    </Table>
  )
})

export default TechnicalSpecsTable
