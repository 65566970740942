import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import { ExpertSubtopicDTO } from "@framework/types/knowledge"

import SubtopicMeta from "./SubtopicMeta"

import styles from "./index.module.sass"

export interface SubtopicCardProps {
  data: ExpertSubtopicDTO
  className?: string
  controlSlot?: React.ReactNode
}

export const SubtopicCard: React.FC<SubtopicCardProps> = observer(
  ({ data, controlSlot, className }) => {
    return (
      <div className={clsx(styles.root, className)}>
        <div className={styles.header}>
          <div className={styles.withControl}>
            <Text variant="h5" className={styles.title}>
              {data.title}
            </Text>
            <div className={styles.control}>
              {/* <Chip color="red" variant="rounded">
                NEW
              </Chip> */}
              {controlSlot}
            </div>
          </div>
          <SubtopicMeta data={data} />
        </div>

        <div className={styles.body}>
          <div className={styles.withControl}>
            <Text
              variant="body3"
              color="text70Color"
              className={styles.description}
              title={data.description}
            >
              {data.description || "N/A"}
            </Text>
            <Icon
              className={styles.arrow}
              name="arrow-down"
              rotateAngle={-90}
            />
          </div>
        </div>
      </div>
    )
  }
)

export default SubtopicCard
