import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Text from "@components/ui/Typography/Text"
import { ExpertTopicDTO } from "@framework/types/knowledge"

import TopicMeta from "./TopicMeta"

import styles from "./index.module.sass"

export interface TopicCardProps {
  data: ExpertTopicDTO
  className?: string
  controlSlot?: React.ReactNode
}

export const TopicCard: React.FC<TopicCardProps> = observer(
  ({ data, className, controlSlot }) => {
    return (
      <div className={clsx(styles.root, className)}>
        <div className={styles.withControl}>
          <Text variant="h4" className={styles.title}>
            {data.title}
          </Text>

          <div className={styles.control}>
            {/* <Chip color="red" variant="rounded">
              NEW
            </Chip> */}

            {controlSlot}
          </div>
        </div>

        <Text
          variant="body3"
          color="text70Color"
          className={styles.description}
          title={data.description}
        >
          {data.description || "N/A"}
        </Text>

        <TopicMeta data={data} />
      </div>
    )
  }
)

export default TopicCard
