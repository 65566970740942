import React from "react"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"
import Button from "@components/ui/Button/Button"
import { useController } from "@store/index"
import Text from "@components/ui/Typography/Text"
import Chip from "@components/ui/Chip/Chip"
import { AnswerData } from "@framework/types/search"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import styles from "./Title.module.sass"

const Title: React.FC<{ answer: AnswerData }> = observer(({ answer }) => {
  const isTablet = useMediaQuery(AppMediaQueries.minTablet)

  const { dataConnectorController } = useController()

  const isExpertKnowledge = answer?.source === "expert answer"

  const handleSourceClick = async () => {
    const sourceUrl = answer?.source
    if (!sourceUrl) return
    const res = await dataConnectorController.getSignedDataURL(sourceUrl)
    if (res.status === "SUCCESS") window.open(res.data, "_blank")
  }

  return (
    <div className={styles.titleContainer}>
      {!isExpertKnowledge && (
        <Chip color="green-solid" variant="rounded">
          New
        </Chip>
      )}
      <div className={styles.title}>
        <Icon
          color={isExpertKnowledge ? "green" : "gold"}
          name={isExpertKnowledge ? "user-insight" : "global"}
        />

        <Text
          variant="inherit"
          className={styles.titleText}
          title={answer?.source_name}
        >
          {isExpertKnowledge ? "Expert Insights" : answer?.source_name}
        </Text>

        {!!answer?.source && !isExpertKnowledge && (
          <Button
            onClick={handleSourceClick}
            className={styles.source}
            color="secondary"
            size={isTablet ? "tiny" : "small"}
            before={<Icon name="external-link" />}
          >
            Open file
          </Button>
        )}
      </div>
    </div>
  )
})

export default Title
