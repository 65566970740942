import React from "react"
import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"
import zip from "lodash/zip"

import List from "@components/ui/List/List"
import mainRoutes from "@root/main.routes"
import { useStore } from "@store/index"
import IntroLayout from "@components/layout/IntroLayout/IntroLayout"
import { useAppConfig } from "@components/theme/AppConfigProvider"
import Text from "@components/ui/Typography/Text"
import LoginSSOForm from "@pages/auth/LoginSSOForm"
import LoginMSSSOForm from "@pages/auth/LoginMSSSOForm"
import Logo from "@components/prototypes/Logo/Logo"
import { initArray } from "@utils/numberUtils"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import ExternalSignForm from "./ExternalSignUpForm"
import SignInForm from "./components/SignInForm/SignInForm"

import styles from "./SignIn.module.sass"

export interface SignInPageProps {}

const SignInUsingSSOForm: React.FC<{ primary?: boolean }> = observer(
  ({ primary }) => {
    const theme = useAppConfig()

    if (theme.authorization.login.SSO.variant === "SAML") {
      return <LoginSSOForm variant="SAML" primary={primary} />
    }

    if (theme.authorization.login.SSO.variant === "OIDC") {
      return <LoginSSOForm variant="OIDC" primary={primary} />
    }

    return <LoginMSSSOForm primary={primary} />
  }
)

const SignInPage: React.FC<SignInPageProps> = observer(() => {
  const theme = useAppConfig()

  const { restrictionsStore: access } = useStore()

  const isTablet = useMediaQuery(AppMediaQueries.maxTablet)

  const isSSOAvailable = !theme.authorization.login.SSO.disabled

  const getActionNodes = () => {
    const actionNodes: React.ReactNode[] = []

    let wasPrimaryRender = false

    const addAction = (callback: (isPrimary: boolean) => React.ReactNode) => {
      actionNodes.push(callback(!wasPrimaryRender))
      wasPrimaryRender = true
    }

    // maintained auth actions

    if (!theme.authorization.login.classic.disabled)
      addAction((isPrimary) => <SignInForm primary={isPrimary} />)

    if (isSSOAvailable)
      addAction((isPrimary) => <SignInUsingSSOForm primary={isPrimary} />)

    if (!theme.authorization.signUp.external.disabled)
      addAction((isPrimary) => (
        <List gutter="32" overflow="initial">
          <Text
            variant={isTablet ? "h6" : "h4"}
            color="text50Color"
            align="center"
          >
            Don&lsquo;t have a Personal Account?
          </Text>

          <ExternalSignForm primary={isPrimary} />
        </List>
      ))

    //

    const totalDividers = Math.max(actionNodes.length - 1, 0)

    return zip(
      actionNodes,
      initArray(totalDividers, () => <span className={styles.divider}>or</span>)
    )
  }

  return (
    <IntroLayout>
      <div className={styles.root}>
        <div className={styles.logo}>
          <Logo
            name={theme.copy.logo}
            height={isTablet ? 36 : 48}
            withText
            primary
          />
        </div>

        <div className={styles.header}>
          <Text variant={isTablet ? "h1" : "title"} align="center">
            Sign In
          </Text>

          {!theme.authorization.signUp.classic.disabled && (
            <Text variant={isTablet ? "h6" : "h4"}>
              Don&lsquo;t have an account?&nbsp;
              <Link to={mainRoutes.signUp()}>Sign Up</Link>
            </Text>
          )}
        </div>

        <div className={styles.body}>{getActionNodes()}</div>

        {access.isAppAgreementsStampAvailable && (
          <Text variant="h6" align="center" className={styles.footer}>
            By signing in or creating an account, you agree with our&nbsp;
            <Link to="/terms">Terms & Conditions</Link> and&nbsp;
            <Link to="/privacy">Privacy Statement</Link>
          </Text>
        )}
      </div>
    </IntroLayout>
  )
})

export default SignInPage
