import * as yup from "yup"

import { Option } from "@framework/types/utils"

const urlRegEx =
  /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i

export type SubPageGroupType = 2 | 3

export const subPageGroupOptions: Option<SubPageGroupType>[] = [
  { name: 2, value: "2 Levels - Home and Subpages" },
  { name: 3, value: "3 Levels - Home, Subpages, and other Links" },
]

export type WebsiteConnectWayType = "sitemap" | "subPages" | "custom"

export interface BaseFormType {
  name: string
  knowledgeDataTypeId?: string
}

export const initialBaseForm: BaseFormType = {
  name: "",
}

export interface SitemapFormType extends BaseFormType {
  sitemapUrls?: string[]
}

export interface SubPagesDepthFormType extends BaseFormType {
  crawlingDepth?: 2 | 3
}

export interface SubPagesListFormType extends BaseFormType {
  crawlingUrls?: string[]
}

export const initialForm: BaseFormType & ConnectWebsiteFormType = {
  ...initialBaseForm,
  crawlingDepth: 2,
  sitemapUrls: [],
  crawlingUrls: [],
}

export type ConnectWebsiteFormType =
  | SitemapFormType
  | SubPagesDepthFormType
  | SubPagesListFormType

export const baseFormValidationShape = {
  name: yup
    .string()
    .required("Field is required")
    .matches(urlRegEx, "Not a valid URL"),
}

export const sitemapFormValidationShape = {
  sitemapUrls: yup
    .array()
    .of(
      yup
        .string()
        .required("Field is required")
        .matches(urlRegEx, "Not a valid URL")
    ),
}

export const subPagesDepthFormValidationShape = {
  crawlingDepth: yup
    .string()
    .required("One should be picked")
    .oneOf(["2", "3"]),
}

export const subPagesFormValidationShape = {
  crawlingUrls: yup
    .array()
    .of(
      yup
        .string()
        .required("Field is required")
        .matches(urlRegEx, "Not a valid URL")
    ),
}

export const getValidationScheme = (subFormName: WebsiteConnectWayType) => {
  if (subFormName === "sitemap") return sitemapFormValidationShape
  if (subFormName === "subPages") return subPagesDepthFormValidationShape
  if (subFormName === "custom") return subPagesFormValidationShape
  return {}
}
