import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import AdminLayout from "@components/layout/AdminLayout/AdminLayout"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import FilterSidebar from "@components/prototypes/FilterSidebar"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import BackButton from "@components/prototypes/BackButton"
import TextInput from "@components/ui/TextInput/TextInput"
import { ModalsTypes } from "@components/modals/constants"
import NotFound from "@components/ui/NotFound/NotFound"
import useSearch from "@components/hooks/useSearch"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import Templates from "@components/ui/Templates"
import Icon from "@components/ui/Icon/Icon"
import { useController, useStore } from "@store/index"
import { KnowledgeDatatypeFilters } from "@framework/types/knowledge"
import FilterButton from "@components/ui/Button/FilterButton"

import DatatypesTable from "./components/Table"
import FilterForm from "./components/FilterForm"

import styles from "./Datatypes.module.sass"

const Datatypes = observer(() => {
  const navigate = useNavigate()

  const [filters, setFilters] = React.useState<KnowledgeDatatypeFilters>({})

  const assignModal = useModal(ModalsTypes.DATATYPE_ASSIGN_AVATAR_MODAL)
  const createModal = useModal(ModalsTypes.DATATYPE_CREATE_MODAL)

  const {
    datatypesStore: { data, isLoading },
  } = useStore()

  const {
    datatypesController: { loadAllDataTypes },
  } = useController()

  const [searchProps, searchContext] = useSearch()

  const handleBackClick = () => navigate("/admin")

  React.useEffect(() => {
    loadAllDataTypes(searchProps.value, filters)
  }, [searchProps.value, filters])

  const appliedFiltersLength = Object.keys(filters).filter(
    (key) => !!filters[key as keyof KnowledgeDatatypeFilters]
  ).length

  return (
    <AdminLayout>
      <EntityPageHeaderLayout
        className={styles.header}
        left={<BackButton onClick={handleBackClick}>Content Types</BackButton>}
        right={
          <Button
            color="primary"
            before={<Icon name="plus" />}
            onClick={() => createModal.showModal({})}
          >
            Create Content Type
          </Button>
        }
      >
        <TextInput
          {...searchProps}
          placeholder="Search..."
          before={<Icon name="search" />}
          after={
            !!searchProps.value && (
              <Icon name="cross" onClick={() => searchContext.setQuery("")} />
            )
          }
        />
      </EntityPageHeaderLayout>
      <Templates.Header
        offsetBottom="middle"
        right={
          <FilterSidebar
            initialValue={filters}
            onChange={(newValue) => setFilters(newValue)}
            targetSlot={({ onToggle, onReset }) => (
              <FilterButton
                size="big"
                color="secondary"
                counter={appliedFiltersLength}
                onOpen={onToggle}
                onClean={onReset}
              >
                Filter
              </FilterButton>
            )}
          >
            <FilterForm />
          </FilterSidebar>
        }
      />
      <LoadingCover isLoading={isLoading}>
        {data?.length ? (
          <DatatypesTable
            items={data}
            onAssignClick={(id) =>
              assignModal.showModal({ datatypeId: id, editModeByDefault: true })
            }
          />
        ) : (
          <NotFound>No content types found</NotFound>
        )}
      </LoadingCover>
    </AdminLayout>
  )
})

export default Datatypes
