import React, { useCallback, useState } from "react"
import clsx from "clsx"
import debounce from "lodash/debounce"
import { observer } from "mobx-react-lite"
import { useFormikContext } from "formik"

import TextInput from "@components/ui/TextInput/TextInput"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import { useController, useStore } from "@store"
import { capitalizeFirstLetter, removeHyphens } from "@utils/textUtils"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import { QueryFilterData } from "@framework/types/query-filter"

import { FormData } from "./FilterSubForm"

import styles from "./FiltersSearchSection.module.sass"

const FiltersSearchSection: React.FC = observer(() => {
  const [search, setSearch] = useState<string>("")

  const { solutionsController } = useController()
  const { solutionsStore } = useStore()

  const { getSearchingFiltersSuggestions } = solutionsController

  const { searchLoading, searchedFilters } = solutionsStore

  const formik = useFormikContext<FormData>()

  const activeFilters = formik.values.filters ?? []

  const addFilter = (filter: QueryFilterData) => {
    const found = activeFilters.find((curFilter) => curFilter.id === filter.id)

    if (found) return

    formik.setFieldValue("filters", [...activeFilters, filter])
  }

  const debouncedSearch = useCallback(
    debounce((query) => {
      getSearchingFiltersSuggestions(query)
    }, 300),
    []
  )

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSearch(value)
    debouncedSearch(value)
  }

  return (
    <div className={styles.root}>
      <TextInput
        className={styles.searchInput}
        placeholder="What are you looking for..."
        before={<Icon name="search" />}
        autoFocus
        value={search}
        after={
          search ? <Icon onClick={() => setSearch("")} name="cross" /> : null
        }
        onChange={handleSearchChange}
      />
      <div
        className={clsx(styles.filtersContainer, { [styles.hidden]: !search })}
      >
        <div className={styles.filters}>
          {!!search &&
            (searchLoading ? (
              <Skeleton count={3} lineHeight={30} minWidth={100} />
            ) : (
              <>
                {!searchedFilters?.length && (
                  <Text variant="body2" color="text50Color">
                    No search result
                  </Text>
                )}
                {searchedFilters.map((item) => (
                  <CheckboxWithLabel
                    className={clsx(styles.checkboxFilterName)}
                    onCheck={() => addFilter(item)}
                    label={capitalizeFirstLetter(removeHyphens(item.name))}
                    checked={
                      !!activeFilters.find(
                        (curFilter) => curFilter.id === item.id
                      )
                    }
                  />
                ))}
              </>
            ))}
        </div>
      </div>
    </div>
  )
})
export default FiltersSearchSection
