import { makeAutoObservable } from "mobx"

import { FeedbackDTO } from "@framework/types/feedback"
import feedbackService from "@services/feedback.service"

/**
 * @deprecated
 */
export class FeedbackController {
  constructor() {
    makeAutoObservable(this)
  }

  getPrompt = async () => {
    try {
      const response = await feedbackService.getPrompt()

      return response.data.data
    } catch (error) {
      //
    }
    return false
  }

  sendFeedback = async (payload: FeedbackDTO) => {
    let error: string | null = null
    try {
      await feedbackService.sendFeedback(payload)
    } catch (e) {
      error = "Unable to save feedback"
    }
    return error
  }

  ignore = async () => {
    let error: string | null = null
    try {
      await feedbackService.ignore()
    } catch (e) {
      error = "Service temporary unavailable"
    }

    return error
  }
}

export default FeedbackController
