import React from "react"

import SecondaryLayout from "@components/layout/SecondaryLayout/SecondaryLayout"

import MaintenanceForm from "./MaintenanceForm"

import styles from "./MaintenancePage.module.sass"

const MaintenancePage: React.FC = () => (
  <SecondaryLayout className={styles.root}>
    <MaintenanceForm />
  </SecondaryLayout>
)

export default MaintenancePage
