/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React from "react"
import clsx from "clsx"
import { useNavigate } from "react-router-dom"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import SecondaryLayout from "@components/layout/SecondaryLayout/SecondaryLayout"

import styles from "./MainBoxLayout.module.sass"

export interface MainBoxLayoutProps {
  className?: string
  backOnBgClick?: boolean
  onBack?: React.MouseEventHandler
}

export const MainBoxLayout: React.FC<MainBoxLayoutProps> = ({
  className,
  children,
  backOnBgClick = false,
  onBack,
}) => {
  const navigate = useNavigate()

  const handleBack: React.MouseEventHandler | undefined =
    onBack ??
    ((e) => {
      navigate(-1)
      e.stopPropagation()
    })

  const handleBgBack: React.MouseEventHandler | undefined = backOnBgClick
    ? handleBack
    : undefined

  return (
    <MainLayout className={styles.root}>
      <SecondaryLayout
        className={clsx(styles.body, { [styles.clickable]: backOnBgClick })}
        onClick={handleBgBack}
        onBack={handleBack}
      >
        <div
          className={clsx(styles.container, className)}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </SecondaryLayout>
    </MainLayout>
  )
}

export default MainBoxLayout
