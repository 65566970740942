import { SimpleUserData } from "./user"

export type QuestionAssignityType = "waiting" | "answered" | "declined"

export type AssignedGroupType = "expert" | "own"

export interface KnowledgeQuestion {
  id: string
  question: string
  avatarName: string
  avatarId: string
  isAssigned?: boolean
}

export interface ExpertQuestion extends KnowledgeQuestion {
  answer: string | null
  author: SimpleUserData
  thread: Record<
    string,
    {
      author: SimpleUserData
      answer: string | null
      lastUpdated?: string
    }
  >
  channel: string // ==> avatar
  lastUpdated: string
  status: AnswerStatus
  delegatedToSelf: boolean
  userNote: string
  summary?: string
}

export interface ExpertQuestionDTO extends KnowledgeQuestion {
  answer: string | null
  author: SimpleUserData
  assignee: SimpleUserData
  userNote: string
  channel: string // ==> avatar
  lastUpdated: string
  status: AnswerStatus
  delegatedToSelf: boolean
}

export type ExpertQuestionThreadDTO = {
  id: string
  answer: string
  lastUpdated: string
  author: SimpleUserData
}

export type ExpertQuestionsDataDTO = Record<
  QuestionAssignityType,
  ExpertQuestionDTO[] | undefined
>

export type ExpertQuestionsData = Record<
  QuestionAssignityType,
  ExpertQuestion[] | undefined
>

export type ExpertQuestionsThreadData = ExpertQuestionThreadDTO[]

export enum AnswerStatus {
  UNASSIGNED = "unassigned",
  WAITING = "waiting",
  ANSWERED = "answered",
  DECLINED = "declined",
}
