import { reaction, makeAutoObservable } from "mobx"
import _groupBy from "lodash/groupBy"
import _map from "lodash/map"

import { sortResultOptions } from "@framework/constants/search-results"
import { SearchFilterType } from "@framework/types/search"
import { Option } from "@framework/types/utils"

export class FilterEntityStore {
  // injections

  // constructor

  constructor(config: {
    query: string
    avatarName: string
    avatarId: string
    productSolutionId?: string
    oldThreadId?: string
    categoryIds?: string[]
    dataSources?: string[]
    dataTypes?: string[]
    productIds?: string[]
  }) {
    this.searchAvatar = config.avatarName
    this.searchAvatarId = config.avatarId
    this.searchQuery = config.query
    this.productSolutionId = config.productSolutionId ?? ""
    this.oldThreadId = config.oldThreadId
    this.dataTypes = config.dataTypes
    this.categoryIds = config.categoryIds
    this.dataSources = config.dataSources
    this.productIds = config.productIds

    makeAutoObservable(this)

    reaction(
      () => this.orderByOptions,
      (newOrderByOptions) => this.setOrderBy(newOrderByOptions[0] ?? "")
    )
  }

  // state

  searchQuery: string = ""

  orderBy: string = sortResultOptions[0].name

  orderByOptions: Option[] = sortResultOptions

  searchAvatar: string

  searchAvatarId: string

  productSolutionId: string

  oldThreadId?: string

  categoryIds?: string[]

  dataSources?: string[]

  dataTypes?: string[]

  productIds?: string[]

  toJson = (): SearchFilterType => {
    return {
      query: this.searchQuery,
      channel: this.searchAvatar,
      avatarId: this.searchAvatarId,
      productSolutionId: this.productSolutionId,
      oldThreadId: this.oldThreadId,
      dataTypes: this.dataTypes,
      categoryIds: this.categoryIds,
      dataSources: this.dataSources,
      productIds: this.productIds,
    }
  }

  // actions

  setOrderBy = (orderBy: Option<any>) => {
    this.orderBy = orderBy.name
  }

  setOrderByOptions = (orderByOptions: Option[]) => {
    this.orderByOptions = orderByOptions
  }

  // utils
}

export default FilterEntityStore
