/* eslint-disable no-redeclare */
/* eslint-disable prettier/prettier */

import moment from "moment"

export const DATE_MASK = "99.99.9999"
export const DATE_MASK_REGEX = /^(\d{2}\.\d{2}\.\d{4})$/
export const FULL_DATE_FORMAT = "MM.DD.YYYY"
export const DIVIDER = " - "

export const canParseDateStr = (
  dateStr: string,
): boolean => !dateStr.includes('_')

export const parseDateString = (dateStr: string): Date | null => {
  if (!dateStr.match(DATE_MASK_REGEX)) return null
  return moment(dateStr, FULL_DATE_FORMAT).toDate()
}

export const parseDateStr = (
  dateStr: string,
): Date =>
  moment(dateStr, FULL_DATE_FORMAT).toDate()

export const parsePeriodStr = (
  dateStr: string,
): Date[] => {
  const arr = dateStr.split(DIVIDER)
  return arr.map(parseDateStr)
}

export const validateDate = (date: Date) => !Number.isNaN(date.valueOf())

export const validatePeriod = (date: Date[]): boolean =>
  date.length === 2 && date.map(validateDate).every((v: boolean) => v)

export const normalizeDateRange = (range: Date[]) =>
  range.sort((d1, d2) => moment(d1).unix() - moment(d2).unix())

export const formatDate = (date: Date | null): string => {
  if (date == null || Number.isNaN(date.valueOf())) return "__.__.____"
  return moment(date).format(FULL_DATE_FORMAT)
}

export const inputToPeriod = (input: string): (Date | null)[] =>
  input.split(DIVIDER).map(parseDateString)
