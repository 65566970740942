import React from "react"

import Icon from "@components/ui/Icon/Icon"
import { SolutionData } from "@framework/types/solution"
import Text from "@components/ui/Typography/Text"
import { getSolutionCoverImage } from "@framework/constants/search-results"
import { capitalizeFirstLetter } from "@utils/textUtils"

import styles from "./SolutionCard.module.sass"

type SolutionCardProps = {
  solution: SolutionData
  contextMenuSlot?: React.ReactNode
  onClick?: (value: SolutionData) => void
}

const SolutionCard: React.FC<SolutionCardProps> = ({
  solution,
  contextMenuSlot,
  onClick,
}) => {
  const name = capitalizeFirstLetter(solution.name)
  const description = capitalizeFirstLetter(solution.description)
  const coverImage = getSolutionCoverImage(solution.key)

  return (
    <button
      className={styles.root}
      type="button"
      onClick={onClick ? () => onClick(solution) : undefined}
    >
      <div className={styles.illustrationContainer}>
        <img
          className={styles.illustration}
          src={solution.image || coverImage}
          alt={name}
        />
      </div>

      <div className={styles.body}>
        <div className={styles.title}>
          <Text variant="h4" color="text100Color" weight="bold" title={name}>
            {name}
          </Text>

          {contextMenuSlot}
        </div>

        <div className={styles.description}>{description}</div>

        <Text variant="body1" className={styles.cta}>
          {solution.workflowType} <Icon name="arrow-up" rotateAngle={90} />
        </Text>
      </div>
    </button>
  )
}

export default SolutionCard
