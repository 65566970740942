import React from "react"
import { observer, useLocalObservable } from "mobx-react-lite"
import { useAlert } from "react-alert"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import { useController, useStore } from "@store/index"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import { UserGroup } from "@framework/types/user"
import {
  SearchContext,
  SearchContextStore,
} from "@components/prototypes/SearchContext"
import { pluralize } from "@utils/textUtils"

import MembershipContext from "./components/MembershipContext/MembershipContext"
import Users from "./components/Users"
import UserGroupMembershipStore from "./avatar-membership.store"

import styles from "./index.module.sass"

export interface EditUserGroupMembershipModalProps {
  group: UserGroup
}

const EditUserGroupMembershipModal: React.FC<EditUserGroupMembershipModalProps> =
  observer(({ group }) => {
    const alert = useAlert()

    const { adminUserCollection } = useStore()

    const { userGroupsController } = useController()

    const modal = useModal(ModalsTypes.EDIT_USER_GROUP_MEMBERSHIP_MODAL)

    const searchContext = useLocalObservable(() => new SearchContextStore())

    const context = useLocalObservable(() => new UserGroupMembershipStore())

    React.useEffect(() => {
      adminUserCollection.search(searchContext.query)
    }, [searchContext.query])

    React.useEffect(() => {
      adminUserCollection.reset({
        groupId: group.id,
        excludeMode: context.isEditing,
      })
    }, [context.isEditing, group.id])

    const handleClose = () => {
      userGroupsController.reload()
      modal.hideModal()
    }

    const handleAdd = async () => {
      const userIds = context.selectedUsers
      const error = await userGroupsController.assignUserToGroup(
        group.id,
        userIds
      )

      if (error) {
        alert.error(error)
        return
      }

      alert.success(
        `${userIds.length} ${pluralize(
          "User",
          userIds.length > 1
        )} was successfully assigned to group`
      )

      context.handleEdit(false)
      context.resetSelected()
    }

    const handleRemove = async (userId: string) => {
      const error = await userGroupsController.unassignUserToGroup(group.id, [
        userId,
      ])

      adminUserCollection.deleteUserById(userId)

      if (error) alert.error(error)
      else alert.success(`User was successfully unassigned from group`)
      adminUserCollection.refresh()
    }

    return (
      <BaseModal
        title={
          <ModalTitle
            titleText={`${getTitlePrefix(context.isEditing)} Users`}
          />
        }
        onClose={handleClose}
        className={styles.root}
        containerClassName={styles.container}
      >
        <SearchContext.Provider value={searchContext}>
          <MembershipContext.Provider value={context}>
            <Users
              isLoading={userGroupsController.usersStore.isLoading}
              onAdd={handleAdd}
              onRemove={handleRemove}
            />
          </MembershipContext.Provider>
        </SearchContext.Provider>
      </BaseModal>
    )
  })

export default EditUserGroupMembershipModal

const getTitlePrefix = (isEditing: boolean) => {
  if (isEditing) return "Add"
  return "Assigned"
}
