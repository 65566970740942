/* eslint-disable camelcase */
import { AxiosResponse } from "axios"

import {
  AccessType,
  AvatarData,
  AvatarDetailsData,
} from "@framework/types/avatar"

import { DefaultSuccessResponse } from "./common/types"
import HttpService from "./http.service"

export interface GetAvatarsResponse extends DefaultSuccessResponse {
  data: AvatarData[]
}

export interface GetAvatarAdminResponse extends DefaultSuccessResponse {
  data: AvatarDetailsData[]
}

export interface GetAvatarDetailsResponse extends DefaultSuccessResponse {
  data: AvatarDetailsData
}
export interface UploadAvatarCoverImageResponse extends AvatarDetailsData {}

class AvatarAPI extends HttpService {
  getAvatars = (): Promise<AxiosResponse<GetAvatarsResponse>> =>
    this.get("ts/user/avatars")

  getAdminAvatars = (): Promise<AxiosResponse<GetAvatarAdminResponse>> =>
    this.get("ts/admin/avatar/all")

  getAvatar = (
    avatarId: string
  ): Promise<AxiosResponse<GetAvatarDetailsResponse>> =>
    this.get("ts/avatar", true, { avatarId })

  createAvatar = (form: {
    name: string
    description: string
    accessType: AccessType
    userIds?: string[]
    dataTypeIdsToAdd?: string[]
    dataTypeIdsToDelete?: string[]
  }): Promise<AxiosResponse<any>> => this.post("ts/avatar", form)

  updateAvatarCoverImage = (
    avatarId: string,
    file: FormData
  ): Promise<AxiosResponse<UploadAvatarCoverImageResponse>> =>
    this.patch(`ts/avatar/image`, file, true, { avatarId })

  removeAvatar = (avatarId: string): Promise<AxiosResponse<any>> =>
    this.delete("ts/avatar", {}, true, { avatarId })

  editAvatar = (
    avatarId: string,
    form: {
      name?: string
      description?: string
      userIds?: string[]
      dataTypeIdsToAdd?: string[]
      dataTypeIdsToDelete?: string[]
      productSolutionIdsToAdd?: string[]
      productSolutionIdsToDelete?: string[]
    }
  ): Promise<AxiosResponse<any>> =>
    this.patch("ts/avatar", form, true, { avatarId })
}

export default new AvatarAPI()
