import React from "react"

import TextInput from "@components/ui/TextInput/TextInput"
import Icon from "@components/ui/Icon/Icon"
import Templates from "@components/ui/Templates"

import EntityList from "../components/ItemsCollection/EntityList"
import MeetingEntityItem from "./MeetingItem"

export interface MeetingsCollectionProps {}

const MeetingsCollection: React.FC<MeetingsCollectionProps> = () => {
  const [activeIdx, setActiveIdx] = React.useState<number | undefined>(0)

  return (
    <Templates.RollScript
      gutter="16"
      headerSocket={
        <TextInput
          placeholder="Search"
          before={<Icon name="search" />}
          after={
            <>
              <Icon name="sliders" />
              <Icon name="top-down-arrows" />
            </>
          }
        />
      }
    >
      <EntityList
        total={25}
        renderCard={(idx) => {
          const isActive = activeIdx === idx
          return (
            <MeetingEntityItem
              active={isActive}
              onClick={() => setActiveIdx(isActive ? undefined : idx)}
              title="Meeting Title"
              subtitle="2:00 PM - 3:30 PM"
              key={idx}
            />
          )
        }}
        unit="chat"
      />
    </Templates.RollScript>
  )
}

export default MeetingsCollection
