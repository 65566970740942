import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import SignUpPage from "./SignUp"
import SignUpUserRequestPage from "./SignUpUserRequest"

export enum SignUpMode {
  confirmation = "confirmation",
  userRequest = "userRequest",
}

export interface SignUpPageSwitcherProps {}

const SignUpPageSwitcher: React.FC<SignUpPageSwitcherProps> = () => {
  const location = useLocation()
  const [mode, setMode] = useState<SignUpMode>(SignUpMode.userRequest)

  useEffect(() => {
    const invitationCode =
      new URLSearchParams(location.search).get("x") ?? undefined
    setMode(invitationCode ? SignUpMode.confirmation : SignUpMode.userRequest)
  }, [location])

  return (
    <>
      {mode === SignUpMode.confirmation && <SignUpPage />}
      {mode === SignUpMode.userRequest && <SignUpUserRequestPage />}
    </>
  )
}

export default SignUpPageSwitcher
