import React from "react"
import { useFormikContext } from "formik"
import * as yup from "yup"

import Text from "@components/ui/Typography/Text"
import Button from "@components/ui/Button/Button"
import { useController, useStore } from "@store"
import Templates from "@components/ui/Templates"

import ProductsSection from "./ProductsSection"
import FiltersSection from "./FiltersSection"
import FiltersSearchSection from "./FiltersSearchSection"

import styles from "./FilterSubForm.module.sass"

export const validationSchema = yup.object({
  filters: yup.array().required().default([]),
})

export type FormData = yup.InferType<typeof validationSchema>

export type FiltersSubFormProps = {}

const FiltersSubForm: React.FC<FiltersSubFormProps> = () => {
  const formik = useFormikContext<FormData>()

  const cleanupFilters = () => {
    formik.setFieldValue("filters", [])
  }

  return (
    <div className={styles.root}>
      <Templates.Header
        offsetBottom="medium"
        left={<Text variant="h4">Filters</Text>}
        right={
          <Button size="tiny" variant="slim" onClick={cleanupFilters}>
            Clear All
          </Button>
        }
      />

      <FiltersSearchSection />

      <div className={styles.body}>
        <div className={styles.sidebarContainer}>
          <ProductsSection />
        </div>

        <div className={styles.filtersContainer}>
          <FiltersSection />
        </div>
      </div>
    </div>
  )
}

export default FiltersSubForm
