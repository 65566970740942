import React, { useMemo } from "react"

import { AppPatchNote } from "@framework/types/app"
import { arrayToReactList } from "@utils/textUtils"

import styles from "./UpdateCard.module.sass"

interface UpdateCardProps extends AppPatchNote {}

const UpdateCard: React.FC<UpdateCardProps> = ({
  version,
  description,
  changeList,
}) => {
  const changes = useMemo(() => arrayToReactList(changeList), [changeList])

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h3>Version: {version}</h3>
      </div>
      <div className={styles.body}>
        <div className={styles.description}>
          <p>{description}</p>
        </div>
        <ul className={styles.fixes}>
          {changes.map(({ name, value }) => (
            <li key={name}>{value}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default UpdateCard
