import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import { Point, Range } from "@framework/types/common"

import { useMatrixContext } from "./MatrixContext"
import { intersection, intersects } from "./utils"

import styles from "./Spreadsheet.module.sass"

export const RangeSquare: React.FC<{
  range: Range<Point>
  bgColor?: string
  color?: string
  solid?: boolean
  blurred?: boolean
  withKnob?: boolean
  onKnobMouseDown?: React.MouseEventHandler
}> = observer(
  ({
    range,
    solid = false,
    blurred = false,
    color,
    bgColor,
    withKnob = false,
    onKnobMouseDown,
  }) => {
    const { grid } = useMatrixContext()

    if (!intersects(grid.visibleGridRect, range)) return null

    const rect = intersection(grid.visibleGridRect, range)

    const position = grid.getCellCoordinates(rect.start)

    return (
      <div
        className={clsx(styles.selectedRange)}
        style={{
          left: position.x,
          top: position.y,
          width: grid.getRangeWidth(rect),
          height: grid.getRangeHeight(rect),
          borderStyle: solid ? "solid" : "dashed",
          borderColor: color,
          backgroundColor: blurred ? bgColor : "transparent",
        }}
      >
        {!!withKnob && (
          <span
            role="button"
            tabIndex={0}
            aria-label="spread"
            className={styles.knob}
            onMouseDown={onKnobMouseDown}
            style={{ background: color }}
          />
        )}
      </div>
    )
  }
)

export default RangeSquare
