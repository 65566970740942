/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx"
import React from "react"

import { ExpertQuestion } from "@framework/types/question"
import SideListItem from "@components/ui/ListItem/SideListItem"

import QuestionItem from "../QuestionItem/QuestionItem"

import styles from "./QuestionList.module.sass"

interface QuestionListProps {
  items: ExpertQuestion[]
  className?: string
  activeQuestion?: string
  emptyMessage?: string
  onSelect?: (id: string) => void
}

const QuestionList: React.FC<QuestionListProps> = ({
  items = [],
  activeQuestion,
  emptyMessage,
  className,
  onSelect,
}) => (
  <div className={clsx(styles.root, className)}>
    {items.length ? (
      items.map((itemProps) => (
        <SideListItem
          active={itemProps.id === activeQuestion}
          onClick={() => onSelect?.(itemProps.id)}
          key={itemProps.id}
        >
          <QuestionItem data={itemProps} />
        </SideListItem>
      ))
    ) : (
      <div className={styles.message}>{emptyMessage}</div>
    )}
  </div>
)

export default QuestionList
