import { AxiosResponse } from "axios"

import { UserGroup } from "@framework/types/user"
import { PaginationListMeta, PaginationParams } from "@framework/types/utils"

import { DefaultSuccessResponse } from "./common/types"
import HttpService from "./http.service"

export type UserGroupData = {
  name: string
  description?: string
}

export type GetAllUserGroupsResponse = {
  data: UserGroup[]
  meta: PaginationListMeta
}

class UserGroupsAPI extends HttpService {
  getUserGroups = (
    options: PaginationParams
  ): Promise<AxiosResponse<GetAllUserGroupsResponse>> =>
    this.get("ts/groups", true, options)

  assignUsersToGroup = (
    groupId: string,
    userIds: string[]
  ): Promise<AxiosResponse<DefaultSuccessResponse>> =>
    this.post(`ts/groups/${groupId}/users`, { userIds })

  unassignUsersFromGroup = (
    groupId: string,
    userIds: string[]
  ): Promise<AxiosResponse<DefaultSuccessResponse>> =>
    this.delete(`ts/groups/${groupId}/users`, { userIds })

  createUserGroup = (
    payload: UserGroupData
  ): Promise<AxiosResponse<DefaultSuccessResponse>> =>
    this.post(`ts/groups`, payload)

  updateUserGroup = (
    groupId: string,
    payload: Partial<UserGroupData>
  ): Promise<AxiosResponse<DefaultSuccessResponse>> =>
    this.patch(`ts/groups/${groupId}`, payload)

  deleteUserGroup = (
    groupId: string
  ): Promise<AxiosResponse<DefaultSuccessResponse>> =>
    this.delete(`ts/groups/${groupId}`)
}

export default new UserGroupsAPI()
