//  @important - redirect URI must be compatible with one set in google cloud console
//  https://console.cloud.google.com/apis/credentials
export const CONNECT_GOOGLE_DRIVE_REDIRECT_URI = `${window.location.origin}/upload/data-connector/google-drive/add`
export const CONNECT_SHAREPOINT_REDIRECT_URI = `${window.location.origin}/upload/data-connector/sharepoint/add`
export const CONNECT_CONFLUENCE_REDIRECT_URI = `${window.location.origin}/upload/data-connector/atlassian-confluence/add`
export const CONNECT_EC2_SMB_REDIRECT_URI = `${window.location.origin}/upload/data-connector/smb/add`
export const CONNECT_AMAZON_S3_REDIRECT_URI = `${window.location.origin}/upload/data-connector/amazon-aws-s3/add`
export const CONNECT_CONFLUENCE_DATA_CENTER_REDIRECT_URI = `${window.location.origin}/upload/data-connector/atlassian-confluence-data-center/add`

export const LOGIN_MS_SSO_REDIRECT_URI = `${window.location.origin}/login`
