import React from "react"
import { observer } from "mobx-react-lite"

import SidebarPopup from "@components/modals/components/SidebarPopup"
import { useController, useStore } from "@store/index"
import { validExtensions } from "@framework/constants/upload"

import ManualFilesUploadForm from "./ManualFilesUploadForm"

import styles from "./ManualFilesUploadSidebar.module.sass"

export interface ManualFilesUploadModalProps {
  open: boolean
  onClose?: () => void
}

export const ManualFilesUploadSidebar: React.FC<ManualFilesUploadModalProps> =
  observer(({ open, onClose }) => {
    const { fileUploadingStore } = useStore()
    const { fileUploadingController } = useController()

    React.useEffect(() => {
      if (!open && !fileUploadingStore.isAnyInProgress)
        fileUploadingController.flush()
    }, [open])

    return (
      <SidebarPopup
        className={styles.root}
        onClose={onClose}
        open={open}
        keepMounted={false}
      >
        <ManualFilesUploadForm
          title="Upload your files"
          description={`File should be ${validExtensions.join(", ")}`}
          validExtensions={validExtensions}
          onSubmit={onClose}
        />
      </SidebarPopup>
    )
  })

export default ManualFilesUploadSidebar
