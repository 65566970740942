import React from "react"
import { observer } from "mobx-react-lite"
import { useParams } from "react-router-dom"
import debounce from "lodash/debounce"
import type { IndexRange } from "react-virtualized"

import TextInput from "@components/ui/TextInput/TextInput"
import { useController } from "@store/index"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import useSearchTextInput from "@components/ui/TextInput/useSearchTextInput"
import useAvatarUploadedFilesKnowledge from "@store/avatar-knowledge/useAvatarUploadedFilesKnowledge"
import DataSourceFileCard from "@pages/upload/components/DocumentUploadCard/UploadingDataSourceCard"
import { DocumentIconType } from "@framework/types/utils"
import { DataSourceName } from "@framework/types/upload"

import useActiveCategoryContext from "../ActiveDataCategoryContext/useActiveCategoryContext"
import BreadCrumpsIndicator from "./BreadCrumpsContext/BreadCrumpsIndicator"
import ItemsGrid from "../components/ItemsGrid/ItemsGrid"

import styles from "./index.module.sass"

const COLUMNS = 4

interface Props {
  defaultIcon?: DocumentIconType
}

export const DataSourceFiles: React.FC<Props> = observer(({ defaultIcon }) => {
  const { groupName } = useParams<{ groupName: string }>()

  if (groupName == null)
    throw new Error("DataSourceFiles Pages requires groupName param")

  const category = useActiveCategoryContext()

  const { collection, resetCollection } = useAvatarUploadedFilesKnowledge(
    category.id,
    groupName
  )

  const { avatarKnowledgeController, dataConnectorController } = useController()

  const defaultFileIcon =
    defaultIcon ??
    getDataSourceNodeContextProps(groupName as DataSourceName).icon

  const handleSearch = React.useCallback(debounce(resetCollection, 500), [])

  const search = useSearchTextInput({
    onChange: handleSearch,
  })

  const handleLoad = async ({ startIndex, stopIndex }: IndexRange) => {
    const pageSize = 2 * (stopIndex - startIndex)
    await avatarKnowledgeController.loadUploadedFilesList(
      category.id,
      groupName,
      {
        pageNum: Math.floor(startIndex / pageSize),
        pageSize,
        query: search.query,
      }
    )
  }

  const getOpenFileHandler = (sourceUrl: string) => async () => {
    const res = await dataConnectorController.getSignedDataURL(sourceUrl)

    if (res.status === "SUCCESS") window.open(res.data, "_blank")
  }

  return (
    <>
      <div className={styles.navigation}>
        <BreadCrumpsIndicator />
      </div>

      <div className={styles.filter}>
        <TextInput {...search.props} />
      </div>

      <ItemsGrid
        key={collection.id}
        total={collection.total}
        totalLoaded={collection.totalLoaded}
        loading={collection.isLoading}
        unit="file"
        rowHeight={161}
        isRowLoaded={({ index }) => collection.getByIndex(index) != null}
        loadMoreRows={handleLoad}
        columnCount={COLUMNS}
        renderCard={(idx) => {
          const data = collection.getByIndex(idx)
          if (!data) return null
          return (
            <DataSourceFileCard
              item={data}
              defaultIcon={defaultFileIcon}
              onClick={data.url ? getOpenFileHandler(data.url) : undefined}
              key={data.id}
            />
          )
        }}
      />
    </>
  )
})

export default DataSourceFiles
