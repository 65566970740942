import React from "react"
import { observer } from "mobx-react-lite"

import { ExpertTopicDTO } from "@framework/types/knowledge"
import TopicCard from "@pages/expert-insights/Topics/TopicCard/TopicCard"

import EntityItem from "../components/ItemsCollection/EntityItem"

export interface TextPassageCardProps {
  topic: ExpertTopicDTO
  onClick?: React.MouseEventHandler
}

export const TopicCardEntity: React.FC<TextPassageCardProps> = observer(
  ({ topic, onClick }) => {
    return (
      <EntityItem onClick={onClick}>
        <TopicCard data={topic} />
      </EntityItem>
    )
  }
)

export default TopicCardEntity
