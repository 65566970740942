import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import ScrollableContainer from "@components/modals/CreateAvatarModal/components/ScrollableContainer/ScrollableContainer"
import Chip from "@components/ui/Chip/Chip"
import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/BarLoader"
import { ServiceStatus } from "@framework/types/app"

import StatusCard from "./components/UpdateCard/StatusCard"

import styles from "./ServiceStatus.module.sass"

const calcAvailability = (list: ServiceStatus[]) => {
  const availableServices = list.reduce<number>((acc, { status }) => {
    if (status === "up") return acc + 1
    return acc
  }, 0)
  return (availableServices / list.length) * 100
}

const ServiceStatusPage: React.FC = observer(() => {
  const {
    appStore: {
      serviceStatus: list,
      isServiceStatusLoading,
      loadServiceStatus,
    },
  } = useStore()

  useEffect(() => {
    loadServiceStatus()
  }, [])

  const availability = calcAvailability(list)
  const availabilityColor =
    availability > 90 ? "green" : availability < 40 ? "red" : "gold"

  return (
    <MainLayout>
      <div className={styles.root}>
        <div className={styles.container}>
          <h2>System Status</h2>

          {isServiceStatusLoading ? (
            <Loader size="huge" primary fluid />
          ) : (
            <>
              <Chip
                className={styles.badge}
                color={availabilityColor}
                variant="rounded"
              >
                Availability: {availability}%
              </Chip>

              <ScrollableContainer className={styles.list}>
                {list.map((item) => (
                  <StatusCard item={item} key={item.name} />
                ))}
              </ScrollableContainer>
            </>
          )}
        </div>
      </div>
    </MainLayout>
  )
})

export default ServiceStatusPage
