import { useMemo } from "react"

import { useStore } from "@store/index"
import { Option } from "@framework/types/utils"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import { DataSourceName } from "@framework/types/upload"
import useEvent from "@components/hooks/useEvent"

import styles from "./index.module.sass"

export const defaultContextOptions: Option[] = []

export const adminContextOptions: Option[] = [
  {
    name: "edit_data_type",
    value: "Add or Change Date Type",
    icon: "edit",
  },
  {
    name: "delete",
    value: "Remove",
    icon: "trash-can",
    className: styles.redMenuItem,
  },
]

const useAssociatedDocsContextMenu = (
  sourceName: DataSourceName,
  dataTypeId: string | undefined,
  isReadonly = false,
  reloadCallback?: () => void
) => {
  const { showModal } = useModal(ModalsTypes.REASSIGN_FILE_TO_DATA_TYPE_MODAL)

  const {
    restrictionsStore: access,
    uploadSourceStore: { deleteSourceUpload },
  } = useStore()

  const options: Option[] = useMemo(() => {
    const defaultOptions = defaultContextOptions

    const adminOptions = adminContextOptions.filter(
      ({ name }) =>
        !(name === "edit_data_type" && !access.canAssignDataToAvatar)
    )

    if (isReadonly) return defaultOptions
    return [...defaultOptions, ...adminOptions]
  }, [access.canAssignDataToAvatar, isReadonly, sourceName])

  const handleEditAvatars = (id: string) => {
    showModal({
      sourceId: id,
      sourceName,
      initialDataTypeId: dataTypeId,
      title: "Change Date Type",
      onSubmit: reloadCallback,
    })
  }

  const handleDelete = async (sourceName: DataSourceName, id: string) => {
    await deleteSourceUpload(sourceName, id)
    reloadCallback?.()
  }

  const handleContext = useEvent((id: string, name: string) => {
    if (name === "edit_data_type") handleEditAvatars(id)
    if (name === "delete") handleDelete(sourceName, id)
  })

  return useMemo(
    () => ({
      options,
      onContext: handleContext,
    }),
    [options, handleContext]
  )
}

export default useAssociatedDocsContextMenu
