import React from "react"
import { observer } from "mobx-react-lite"
import { useParams } from "react-router-dom"

import SidebarPopup from "@components/modals/components/SidebarPopup"

import KnowledgeSourceExplorer from "./KnowledgeSourceExplorer"
import ActiveAvatarContextProvider from "../ActiveAvatarContext"
import ActiveCategoryContextProvider from "../ActiveDataCategoryContext"

import styles from "./index.module.sass"

type RouterParams = {
  categoryId: string
  avatarId: string
}

export interface FormalKnowledgeSidebarProps {
  open: boolean
  onClose?: () => void
}

export const FormalKnowledgeSidebar: React.FC<FormalKnowledgeSidebarProps> =
  observer(({ open = false, onClose }) => {
    const { avatarId, categoryId } = useParams<RouterParams>()

    if (avatarId == null || categoryId == null)
      throw Error(
        "FormalKnowledgeSidebar has required router params: avatarId, categoryId "
      )

    return (
      <SidebarPopup open={open} onClose={onClose}>
        <ActiveAvatarContextProvider avatarId={avatarId}>
          <ActiveCategoryContextProvider
            categoryId={categoryId}
            avatarId={avatarId}
          >
            <KnowledgeSourceExplorer className={styles.root} />
          </ActiveCategoryContextProvider>
        </ActiveAvatarContextProvider>
      </SidebarPopup>
    )
  })

export default FormalKnowledgeSidebar
