import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import { searchBy } from "@utils/optionsUtils"
import { useController, useStore } from "@store/index"
import { DataSourceName } from "@framework/types/upload"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import useModal from "@components/modals/useModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import List from "@components/ui/List/List"
import Templates from "@components/ui/Templates"
import Button from "@components/ui/Button/Button"
import DataTypeListItem from "@components/ui/DataTypeCard/DataTypeListItem"
import TextInput from "@components/ui/TextInput/TextInput"
import Icon from "@components/ui/Icon/Icon"
import useSearch from "@components/hooks/useSearch"

import ModalFooterContainer from "../components/ControlFooter/ModalFooterContainer"

import styles from "./index.module.sass"

const DEFAULT_TITLE = "Add to Content Type"

export interface ReassignFilesToDataTypeModalProps {
  title?: string
  initialDataTypeId?: string
  connectorSourceName?: DataSourceName
  sourceName: DataSourceName
  sourceId: string
  assignOnly?: boolean
  onSubmit?: () => void
}

export const ReassignFilesToDataTypeModal: React.FC<ReassignFilesToDataTypeModalProps> =
  observer(
    ({
      title = DEFAULT_TITLE,
      initialDataTypeId,
      sourceId,
      sourceName,
      connectorSourceName = sourceName,
      assignOnly = false,
      onSubmit,
    }) => {
      const alert = useAlert()
      const { hideModal } = useModal(
        ModalsTypes.REASSIGN_FILE_TO_DATA_TYPE_MODAL
      )

      const { allDatatypesStore } = useStore()

      const collection = allDatatypesStore.state

      const { datatypesController: controller } = useController()

      const [selected, setSelected] = React.useState<string | undefined>(
        initialDataTypeId
      )

      const desc = getDataSourceNodeContextProps(sourceName)

      const [searchProps, searchHelpers] = useSearch()

      const assignDataType = async (newDataTypeId: string) => {
        const error = await controller.reassignDataTypes(
          connectorSourceName,
          [sourceId],
          [newDataTypeId],
          initialDataTypeId && !assignOnly ? [initialDataTypeId] : []
        )

        if (error != null) {
          alert.error(
            `Failed to reassign selected ${desc.itemName} to Content Type`
          )
          return
        }

        alert.success(
          `Selected ${desc.itemName} successfully assigned to new Content Type`
        )

        onSubmit?.()
        hideModal()
      }

      const handleSubmit = (e?: React.FormEvent) => {
        e?.preventDefault()
        if (selected) assignDataType(selected)
      }

      const isSelected = (id: string) => selected === id

      const handleSelect = (id?: string) => setSelected(id)

      const handleClose = () => hideModal()

      const isValid = () => {
        return !!selected && selected !== initialDataTypeId
      }

      const sortedList = React.useMemo(() => {
        return searchBy(collection.data, searchProps.value, (it) => it.name)
      }, [collection.data, searchProps.value])

      React.useEffect(() => {
        allDatatypesStore.refresh()
      }, [])

      const submittable = isValid()

      return (
        <BaseModal
          className={styles.root}
          containerClassName={styles.container}
          title={
            <ModalTitle
              titleText={title}
              subtitleText={`Select Content Type you want to reassign selected ${desc.itemName} to`}
            />
          }
          onClose={handleClose}
        >
          <form className={styles.form} onSubmit={handleSubmit}>
            <Templates.RollScript
              gutter="24"
              headerSocket={
                <div className={styles.control}>
                  <TextInput
                    {...searchProps}
                    before={<Icon name="search" />}
                    after={
                      !!searchProps.value && (
                        <Icon
                          name="cross"
                          onClick={() => searchHelpers.setQuery("")}
                        />
                      )
                    }
                    placeholder="Search..."
                  />
                </div>
              }
              footerSocket={
                <ModalFooterContainer>
                  <Button variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button color="primary" type="submit" disabled={!submittable}>
                    Select
                  </Button>
                </ModalFooterContainer>
              }
            >
              {sortedList.length === 0 ? (
                collection.isLoading ? (
                  <Loader size="large" primary fluid />
                ) : (
                  <NotFound>No Content Types Found</NotFound>
                )
              ) : (
                <List gutter="8" className={styles.list}>
                  {sortedList.map((it) => {
                    return (
                      <DataTypeListItem
                        data={it}
                        active={isSelected(it.id)}
                        onClick={() => handleSelect(it.id)}
                        onDoubleClick={() => handleSubmit()}
                        key={it.id}
                      />
                    )
                  })}
                </List>
              )}
            </Templates.RollScript>
          </form>
        </BaseModal>
      )
    }
  )

export default ReassignFilesToDataTypeModal
