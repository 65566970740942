import { observer } from "mobx-react-lite"
import React from "react"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"

import styles from "./ConfirmDeleteModal.module.sass"

export interface ConfirmDeleteModalProps {
  message: React.ReactNode
  onSubmit?: () => Promise<void | boolean> | void | boolean
  onCancel?: () => void
  onClose?: () => void
}

export const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = observer(
  ({
    onClose,
    onSubmit,
    onCancel,
    message = "Are you sure you want to delete item?",
  }) => {
    const { hideModal } = useModal(ModalsTypes.CONFIRM_DELETE_MODAL)

    const [isLoading, setLoading] = React.useState(false)

    const handleSubmit = async () => {
      setLoading(true)
      if ((await onSubmit?.()) ?? true) hideModal()
      setLoading(false)
    }

    const handleClose = () => {
      onClose?.()
      hideModal()
    }

    return (
      <BaseModal
        title={<ModalTitle titleText={message} />}
        className={styles.root}
        containerClassName={styles.container}
        onClose={handleClose}
      >
        <ModalFooterContainer>
          <Button
            variant="outlined"
            disabled={isLoading}
            onClick={onCancel ?? handleClose}
          >
            Cancel
          </Button>
          <Button
            color="red"
            disabled={isLoading}
            onClick={handleSubmit}
            after={isLoading ? <Loader /> : null}
          >
            Delete
          </Button>
        </ModalFooterContainer>
      </BaseModal>
    )
  }
)

export default ConfirmDeleteModal
