import React from "react"

import styles from "./FormulaMarker.module.sass"

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  type?: "func-name" | "ref" | "const" | "argument" | "symbol" | "unknown"
}

export const FormulaMarker: React.FC<Props> = React.memo(
  ({ type = "symbol", children, ...rest }) => {
    return (
      <span className={styles[`marker_${type}`]} {...rest}>
        {children}
      </span>
    )
  }
)

export default FormulaMarker
