import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import Icon from "@components/ui/Icon/Icon"
import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import { useController, useStore } from "@store"
import { autoDownloadBlob } from "@utils/file"
import { DateRange } from "@framework/types/common"

export interface ExportReportButtonProps {
  period: DateRange
}

export const ExportReportButton: React.FC<ExportReportButtonProps> = observer(
  ({ period }) => {
    const alert = useAlert()

    const {
      usageAnalyticsStore: { reportStore: store },
    } = useStore()

    const { analyticsController } = useController()

    const handleDownload = async () => {
      const res = await analyticsController.getAnalyticsReportDataAsBlob(period)

      if (res.status === "SUCCESS") {
        autoDownloadBlob(
          res.data,
          `usage_analytics_report_${period.start}_to_${period.end}.xlsx`
        )
      }

      if (res.status === "FAILED") {
        alert.error(res.message)
      }
    }

    const { isLoading } = store

    return (
      <Button
        onClick={handleDownload}
        size="big"
        color="secondary"
        before={<Icon name="download" />}
        after={isLoading && <Loader />}
        disabled={isLoading}
      >
        Export
      </Button>
    )
  }
)

export default ExportReportButton
