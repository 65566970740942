/* eslint-disable react/no-array-index-key */
import React, { useEffect } from "react"
import { useFormikContext } from "formik"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import TextInput from "@components/ui/TextInput/TextInput"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import Icon from "@components/ui/Icon/Icon"
import Button from "@components/ui/Button/Button"

import { SubPagesListFormType } from "./types"

import styles from "./ConnectWebsite.module.sass"

export interface ConnectWebsiteFormProps {
  className?: string
}

export const ConnectWebsiteForm: React.FC<ConnectWebsiteFormProps> = observer(
  ({ className }) => {
    const formik = useFormikContext<SubPagesListFormType>()

    useEffect(() => {
      formik.resetForm({
        values: { name: formik.values.name, crawlingUrls: [""] },
      })
    }, [])

    const handleAdd = () => {
      const subpages = formik.values.crawlingUrls ?? []
      formik.setValues({
        ...formik.values,
        crawlingUrls: [...subpages, ""],
      })
    }

    const handleRemove = (idx: number) => {
      const newList =
        formik.values.crawlingUrls?.filter((_, index) => idx !== index) ?? []
      formik.setValues({ ...formik.values, crawlingUrls: newList })
    }

    if (!formik.values.crawlingUrls) return null

    const canDelete = formik.values.crawlingUrls.length > 1

    return (
      <div className={clsx(styles.form, className)}>
        <div className={styles.list}>
          {formik.values.crawlingUrls?.map((item, idx) => {
            const isTouched = !!(formik.touched.crawlingUrls as any)?.[idx]
            const error = formik.errors.crawlingUrls?.[idx]
            const hasError = isTouched && !!error

            return (
              <TextInput
                id={`${idx}`}
                className={styles.input}
                placeholder="Paste website subpage link"
                value={item}
                name={`crawlingUrls[${idx}]`}
                withError={hasError}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                key={idx}
                after={
                  ((isTouched && !!error) || canDelete) && (
                    <div className={styles.after}>
                      {isTouched && !!error ? (
                        <ErrorChip messagePlacement="left" message={error} />
                      ) : null}
                      {canDelete ? (
                        <Icon
                          name="cross"
                          className={styles.closeBtn}
                          onClick={() => handleRemove(idx)}
                        />
                      ) : null}
                    </div>
                  )
                }
              />
            )
          })}
        </div>

        <Button
          size="medium"
          variant="outlined"
          className={styles.addButton}
          before={<Icon name="plus" />}
          onClick={handleAdd}
        >
          Add Link
        </Button>
      </div>
    )
  }
)

export default ConnectWebsiteForm
