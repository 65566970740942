import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx"
import { from, ObservableInput, Subscription, timer } from "rxjs"
import { switchMap, tap, retry, distinctUntilChanged } from "rxjs/operators"
import { toStream } from "mobx-utils"

import { VirtualListChunk } from "@framework/types/utils"
import analyticsService from "@services/analytics.service"
import { ActiveUsers } from "@framework/types/user"
import { DateRange } from "@framework/types/common"
import { getLastNDaysRange, renderDatePeriodTupleToRange } from "@utils/date"
import { DAY_14_NUMBER, DD_MM_YYYY_FORMAT } from "@framework/constants/global"

const PULLING_DELAY = 20_000
const RETRY_DELAY = 3 * 60_000
const CHUNK_SIZE = 90
const TOP_LIMIT = 5

type Filter = {
  period: DateRange // DD-MM-YYYY
}

const getDefaultFilter = (): Filter => {
  return {
    period: renderDatePeriodTupleToRange(
      getLastNDaysRange(DAY_14_NUMBER),
      DD_MM_YYYY_FORMAT
    ),
  }
}

type State = {
  isLoading: boolean

  data: ActiveUsers[]

  top: ActiveUsers[]

  filter: Filter

  total: number

  errorMessage: string

  reloadTimestamp: number

  requestedFrame: VirtualListChunk

  actualFrame: VirtualListChunk
}

/**
 * @class
 */
export class ActiveUsersVirtualCollectionStore {
  state: State

  private loadingStream$?: Subscription

  // constructor

  constructor() {
    this.state = {
      isLoading: false,

      data: [],

      top: [],

      filter: getDefaultFilter(),

      total: 0,

      errorMessage: "",

      reloadTimestamp: Date.now(),

      requestedFrame: { offset: 0, limit: CHUNK_SIZE },

      actualFrame: { offset: 0, limit: CHUNK_SIZE },
    }

    makeAutoObservable(this)

    onBecomeObserved(this.state, "total", () => {
      this.loadingStream$ = this.initStream()
    })

    onBecomeUnobserved(this.state, "total", () => {
      this.loadingStream$?.unsubscribe()
    })
  }

  initStream = () => {
    this.resetList()
    return from(
      toStream(
        () => [
          this.state.filter,
          this.state.requestedFrame,
          this.state.reloadTimestamp,
        ],
        true
      ) as ObservableInput<[Filter, VirtualListChunk, number]>
    )
      .pipe(
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        ),
        tap(() =>
          runInAction(() => {
            this.state.isLoading = true
            this.state.errorMessage = ""
          })
        ),

        switchMap(([filter, frame]) =>
          analyticsService.loadActiveUsers$(filter, {
            offset: frame.offset,
            limit: frame.limit,
          })
        ),

        tap((response) =>
          runInAction(() => {
            const { meta, data = [] } = response.data

            this.state.data = [...data]

            this.state.actualFrame = { ...meta }

            this.state.total = meta?.total ?? CHUNK_SIZE

            if (meta.offset === 0) {
              this.state.top = data.slice(0, TOP_LIMIT)
            }
          })
        ),

        tap(() =>
          runInAction(() => {
            this.state.isLoading = false
          })
        ),

        retry({
          delay: () => {
            runInAction(() => {
              this.state.errorMessage = "Loading Failed"
              this.state.isLoading = false
              this.state.data = []
            })
            return timer(RETRY_DELAY)
          },
        })
      )
      .subscribe()
  }

  get getByIndex() {
    const { data } = this.state
    return (index: number): ActiveUsers | null => {
      const { offset } = this.state.actualFrame
      return data[index - offset] ?? null
    }
  }

  load = async ({ startIndex }: { startIndex: number; stopIndex: number }) => {
    const chunkIndex = Math.floor(startIndex / CHUNK_SIZE) - 1

    const newOffset = Math.max(0, chunkIndex * CHUNK_SIZE)

    this.state.requestedFrame = {
      offset: newOffset,
      limit: CHUNK_SIZE * 3,
    }
  }

  resetList = (filter?: Filter) => {
    runInAction(() => {
      this.state.data = []
      this.state.top = []
      this.state.requestedFrame = { offset: 0, limit: CHUNK_SIZE }
      this.state.actualFrame = { offset: 0, limit: CHUNK_SIZE }
      this.state.total = 0
      this.state.filter = filter ?? getDefaultFilter()
    })
  }

  refresh = () => {
    this.state.reloadTimestamp = Date.now()
  }
}

export default ActiveUsersVirtualCollectionStore
