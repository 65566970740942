import { Point } from "@framework/types/common"

export const calcContextMenuPosition = (
  position: Point,
  container: Element
) => {
  const bottom = position.y + container.clientHeight
  const right = position.x + container.clientWidth

  const styles: React.CSSProperties = {}

  if (bottom < document.body.clientHeight) {
    styles.top = position.y
  } else if (position.y - container.clientHeight > 0) {
    styles.top = position.y - container.clientHeight
  } else {
    styles.top = document.body.clientHeight - container.clientHeight
  }

  if (right < document.body.clientWidth) {
    styles.left = position.x
  } else if (position.x - container.clientWidth > 0) {
    styles.left = position.x - container.clientWidth
  } else {
    styles.left = document.body.clientWidth - container.clientWidth
  }

  return styles
}

export default null
