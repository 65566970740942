import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { Option } from "@framework/types/utils"

import styles from "./NumberedStep.module.sass"

export interface NumberedStepProps<T extends string | number> {
  items: Option<T>[]
  checkedIdx: number
  lastCompletedStepIndex?: number
  disabled?: boolean
  className?: string
  size?: "big" | "medium" | "small"
  onChange?: (name: T, idx: number) => void
  itemLabel?: (item: Option<T>) => React.ReactNode
}

const NumberedStep = observer(
  <T extends string | number>({
    className = "",
    items,
    checkedIdx,
    lastCompletedStepIndex = -1,
    disabled = false,
    size = "big",
    onChange,
    itemLabel: renderItemLabel,
  }: NumberedStepProps<T>) => {
    const rootRef = React.useRef<HTMLDivElement | null>(null)

    const rootClassName = clsx(styles.root, styles[`size-${size}`], className)

    return (
      <fieldset disabled={disabled} className={rootClassName}>
        <div ref={rootRef} className={styles.container}>
          {items.map((item, idx) => {
            const handlerClick =
              onChange && idx !== checkedIdx
                ? () => onChange(item.name, idx)
                : undefined

            const isActive =
              idx <= Math.max(checkedIdx, lastCompletedStepIndex + 1)

            return (
              <button
                type="button"
                className={clsx(styles.item, {
                  [styles.checked]: isActive,
                })}
                disabled={!handlerClick || !isActive}
                onClick={handlerClick}
                key={item.name}
              >
                <span
                  className={clsx(styles.number, {
                    [styles.currentActive]: idx === checkedIdx,
                  })}
                >
                  <span
                    className={clsx({
                      [styles.active]: isActive,
                    })}
                  >
                    {idx + 1}
                  </span>
                </span>
                {renderItemLabel ? renderItemLabel(item) : item.value}
              </button>
            )
          })}
        </div>
      </fieldset>
    )
  }
)

export default NumberedStep
