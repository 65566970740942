import React from "react"
import get from "lodash/get"
import { observer } from "mobx-react-lite"

import { AppUsageSummaryData } from "@framework/types/analytics"
import { useStore } from "@store/index"
import Templates from "@components/ui/Templates"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import List from "@components/ui/List/List"
import { renderDuration } from "@utils/date"
import TrendCard from "@components/ui/TrendCard/TrendCard"

import styles from "./OverallReport.module.sass"

const trendWidgetOptions: {
  title: string
  name: string
  description: string
  valueFieldName: keyof AppUsageSummaryData
}[] = [
  {
    name: "timeSaved",
    title: "Time Saved",
    valueFieldName: "timeSaved",
    description: "Total time saved for the users",
  },
  {
    name: "totalQuestions",
    title: "Total Queries",
    valueFieldName: "totalQuestions",
    description: "Total number of queries by the users",
  },
  {
    name: "retentionRatio",
    title: "Retention Rate",
    valueFieldName: "retentionRatio",
    description:
      "Percentage of users who continue using the platform over chosen time period",
  },
  {
    name: "averageRating",
    title: "Average Rating",
    valueFieldName: "averageRating",
    description: "Overall feedback rating by users",
  },
]

export const OverallReport: React.FC = observer(() => {
  const {
    usageAnalyticsStore: { summaryStore: store },
  } = useStore()

  return (
    <section>
      <Templates.Header offsetBottom="middle" left={<Text>Overview</Text>} />
      <div className={styles.content}>
        {trendWidgetOptions.map((it) => (
          <TrendCard
            title={it.title}
            description={it.description}
            totalAmount={get(store.data, it.valueFieldName)}
            loading={store.isLoading}
            renderAmountValue={(value) => {
              if (it.name === "retentionRatio")
                return `${Number(value).toFixed(2)}%`
              if (it.name === "averageRating") return renderRating(value)
              if (it.name === "timeSaved")
                return renderDuration(value * 60_000, "s") || "0"
              return value
            }}
            key={it.title}
          />
        ))}
      </div>
    </section>
  )
})

export default OverallReport

const renderRating = (value: number) => (
  <List direction="row" gutter="8">
    {value} <Icon name="star-filled" color="gold" />
  </List>
)
