import { Formik } from "formik"
import { observer } from "mobx-react-lite"
import React, { useMemo, useEffect } from "react"
import { useAlert } from "react-alert"
import * as yup from "yup"

import { IconName } from "@components/ui/Icon/IconName"
import Icon from "@components/ui/Icon/Icon"
import Button from "@components/ui/Button/Button"
import useToggle from "@components/hooks/useToggle"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import { UserStatType } from "@framework/types/user"
import { Option } from "@framework/types/utils"
import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/BarLoader"
import Text from "@components/ui/Typography/Text"

import AvatarsList from "./components/AvatarsList/AvatarsList"
import StatisticCard from "./components/StatisticCard/StatisticCard"
import UserAvatar from "./components/UserAvatar/UserAvatar"
import UserInfoForm from "./components/UserInfoForm/UserInfoForm"

import styles from "./Profile.module.sass"

const availableImageType = ["image/png", "image/jpeg"]

const metricsMapper: Option<UserStatType, string, IconName>[] = [
  {
    name: "helpedNesh",
    value: "Number of times user has helped AI get better",
    icon: "sand-time",
  },
  {
    name: "thisMonth",
    value: "Questions asked for this month",
    icon: "calendar",
  },
  {
    name: "allTime",
    value: "Questions asked over all time",
    icon: "time",
  },
]

const nullToUndefined = <T,>(value: T) => (value === null ? undefined : value)

const validationSchema = yup.object({
  firstName: yup
    .string()
    .label("First Name")
    .transform(nullToUndefined)
    .required("Field is required")
    .default(""),
  lastName: yup
    .string()
    .label("Last Name")
    .transform(nullToUndefined)
    .required("Field is required")
    .default(""),
  jobTitle: yup
    .string()
    .transform(nullToUndefined)
    .label("Job Title")
    .default(""),
  businessUnit: yup
    .string()
    .transform(nullToUndefined)
    .label("Business Unit")
    .default(""),
  digestSubscriptionFrequencyIds: yup
    .array(
      yup
        .string()
        .label("Digest Subscription")
        .transform(nullToUndefined)
        .required()
        .default("")
    )
    .default([]),
})

export type UserProfileForm = yup.InferType<typeof validationSchema>

const ProfilePage: React.FC = observer(() => {
  const { error } = useAlert()

  const { userStore } = useStore()
  const {
    user,
    isUserLoading,
    uploadAvatarError,
    isUserProfileUpdating,
    profileUpdateError,
    isSubscriptionOptionsLoading,
    subscriptionOptions,
    loadUserData,
    uploadAvatar,
    uploadProfile,
    loadSubscriptionOptions,
    setUploadAvatarError,
    setProfileUpdateError,
  } = userStore

  const fullName = useMemo(
    () => (user ? `${user.firstName} ${user.lastName}` : "User Name"),
    [user]
  )

  const { isOpened: isEdit, handleToggle } = useToggle()

  const handleUpdateAvatar = (file: File) => {
    if (!file.type || !availableImageType.includes(file.type)) {
      error("Invalid image format")
      return
    }
    uploadAvatar(file)
  }

  const handleSubmit = async (form: UserProfileForm) => {
    const success = await uploadProfile(form)
    if (success) handleToggle()
  }

  const defaultValue: UserProfileForm = useMemo(
    () =>
      validationSchema.cast(
        {
          ...user,
          digestSubscriptionFrequencyIds: user?.digestSubscriptions?.map(
            (it) => it.digestSubscriptionFrequencyId
          ),
        },
        { stripUnknown: true }
      ),
    [user]
  )

  const init = () => {
    if (!isSubscriptionOptionsLoading) loadSubscriptionOptions()
    if (!isUserLoading) loadUserData()
  }

  useEffect(() => {
    if (uploadAvatarError) {
      error(uploadAvatarError)
      setUploadAvatarError()
    }
  }, [uploadAvatarError])

  useEffect(() => {
    if (profileUpdateError) {
      error(profileUpdateError)
      setProfileUpdateError()
    }
  }, [profileUpdateError])

  useEffect(init, [])

  return (
    <MainLayout>
      <Formik
        initialValues={defaultValue}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <div className={styles.root}>
            <Text variant="h1">Profile settings</Text>

            <div className={styles.body}>
              <div className={styles.profile}>
                <UserAvatar
                  name={fullName}
                  src={user?.avatarURL}
                  onAvatarChange={handleUpdateAvatar}
                />

                {user && (
                  <UserInfoForm
                    user={user}
                    isEdit={isEdit}
                    digestSubscriptionOptions={subscriptionOptions}
                    onFormReset={() =>
                      formik.resetForm({ values: defaultValue })
                    }
                  />
                )}

                <AvatarsList chips={user?.avatars} />

                <div className={styles.footer}>
                  {isEdit ? (
                    <div className={styles.control}>
                      <Button
                        disabled={isUserProfileUpdating}
                        variant="outlined"
                        onClick={handleToggle}
                        size="big"
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={!formik.dirty || isUserProfileUpdating}
                        color="primary"
                        onClick={formik.submitForm}
                        after={isUserProfileUpdating && <Loader />}
                        size="big"
                      >
                        Save
                      </Button>
                    </div>
                  ) : (
                    <Button
                      before={<Icon name="edit" />}
                      variant="outlined"
                      onClick={handleToggle}
                      size="big"
                    >
                      Edit profile
                    </Button>
                  )}
                </div>
              </div>

              <div className={styles.statistic}>
                {user?.questionStats
                  ? metricsMapper.map((item) => {
                      const value = user.questionStats?.[item.name] ?? 0
                      return (
                        <StatisticCard
                          icon={item.icon}
                          label={item.value}
                          value={value}
                          key={item.name}
                        />
                      )
                    })
                  : null}
              </div>
            </div>
          </div>
        )}
      </Formik>
    </MainLayout>
  )
})

export default ProfilePage
