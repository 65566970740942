import React from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"

import ExpertBadge from "@components/prototypes/ResultsCard/ExpertBadge/ExpertBadge"
import ResultsCard from "@components/prototypes/ResultsCard/ResultsCard"
import { TextAnswerSection } from "@components/prototypes/ResultsCard/AnswerSection/AnswerSection"
import { ExpertAnswerData } from "@framework/types/knowledge"
import { YYYY_MM_DD_FORMAT } from "@framework/constants/global"

export interface TextPassageCardProps {
  answer: ExpertAnswerData
  onTitleClick?: React.MouseEventHandler
}

export const ExpertPassageCard: React.FC<TextPassageCardProps> = observer(
  ({ answer, onTitleClick }) => {
    const chips = [moment(answer.createdAt).format(YYYY_MM_DD_FORMAT)]

    return (
      <ResultsCard
        answerSocket={
          <TextAnswerSection
            title={answer.question}
            content={answer.answer ?? ""}
            isExpertAnswer
            onTitleClick={onTitleClick}
          />
        }
        expertSocket={
          <ExpertBadge
            title={`${answer.expertFirstName} ${answer.expertLastName}`}
            src={answer.expertAvatarURL}
          />
        }
        chips={chips}
      />
    )
  }
)

export default ExpertPassageCard
