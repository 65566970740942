import React from "react"
import { observer } from "mobx-react-lite"

import { FileDataSourceName } from "@framework/types/upload"
import useToggle from "@components/hooks/useToggle"
import useEvent from "@components/hooks/useEvent"
import Icon from "@components/ui/Icon/Icon"

import ConnectDataSourceButton from "../components/UploadHeader/ConnectDataSourceButton"
import ManualFilesUploadSidebar from "../UploadFiles/ManualFilesUploadSidebar"
import { useSelectDataTypeContext } from "../PreselectDataTypeContext"

export const ManualUploadButton: React.FC<{ sourceName: FileDataSourceName }> =
  observer(({ sourceName }) => {
    const uploadSidebar = useToggle()

    const handleUpload = useEvent(() => {
      uploadSidebar.setOpened(true)
    })

    const { dataTypeId, selectDataType } = useSelectDataTypeContext()

    return (
      <>
        <ConnectDataSourceButton
          before={<Icon name="upload" />}
          sourceName={sourceName}
          onClick={() =>
            selectDataType({
              autoSelect: true,
              onSelect: handleUpload,
            })
          }
        />

        <ManualFilesUploadSidebar
          open={uploadSidebar.isOpened && !!dataTypeId}
          onClose={() => {
            uploadSidebar.setOpened(false)
          }}
        />
      </>
    )
  })

export default ManualUploadButton
