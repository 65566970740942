import { makeAutoObservable } from "mobx"

import { SolutionCategory, SolutionData } from "@framework/types/solution"
import { SOLUTION_STORE_STORAGE_KEY } from "@framework/constants/auth"
import { QueryFilter, QueryFilterData } from "@framework/types/query-filter"

export class SolutionsStore {
  constructor() {
    makeAutoObservable(this)
    this.loadFromLocalStorage()
  }

  isLoading: boolean = false

  isFiltersLoading: boolean = false

  searchLoading: boolean = false

  isPaginationLoading: boolean = false

  allSolutionsLoading: boolean = false

  showRecentActivity: boolean = false

  errorMessage: string = ""

  solutions: SolutionData[] | null = null

  allSolutions: SolutionData[] | null = null

  solution: SolutionData | null = null

  category: SolutionCategory | null = null

  appliedFilters: QueryFilterData[] = []

  availableFilters: QueryFilter | null = null

  searchedFilters: QueryFilterData[] = []

  setSolutions = (solutions: SolutionData[]) => {
    this.solutions = solutions
    this.saveToLocalStorage()
  }

  setSolution = (solution: SolutionData) => {
    this.solution = solution
    this.saveToLocalStorage()
  }

  setAllSolutions = (solutions: SolutionData[]) => {
    this.allSolutions = solutions
    this.saveToLocalStorage()
  }

  get getByIndex() {
    const data = this.solutions ?? []
    return (index: number) => {
      return data[index]
    }
  }

  get getById() {
    const data = this.solutions ?? []
    return (solutionId: string) => {
      return data.find((it) => it.id === solutionId)
    }
  }

  saveToLocalStorage() {
    const data = {
      solutions: this.solutions,
      allSolutions: this.allSolutions,
      solution: this.solution,
      category: this.category,
    }
    localStorage.setItem(SOLUTION_STORE_STORAGE_KEY, JSON.stringify(data))
  }

  loadFromLocalStorage() {
    const data = localStorage.getItem(SOLUTION_STORE_STORAGE_KEY)
    if (data) {
      const parsedData = JSON.parse(data)
      this.solutions = parsedData.solutions
      this.allSolutions = parsedData.allSolutions
      this.solution = parsedData.solution
      this.category = parsedData.category
    }
  }
}

export default SolutionsStore
