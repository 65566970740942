import React from "react"
import clsx from "clsx"

import { randomNumbersArray } from "@utils/numberUtils"

import styles from "./Skeleton.module.sass"

export type SkeletonProps = {
  count?: number
  rounded?: boolean
  lineHeight?: string | number
  maxWidth?: number // %
  minWidth?: number // %
  className?: string
  lineClassName?: string
  spacing?: number
}

export const Skeleton: React.FC<SkeletonProps> = React.memo(
  ({
    count = 1,
    rounded = false,
    lineHeight = "1em",
    maxWidth = 100,
    minWidth = 80,
    spacing = 5,
    className,
    lineClassName,
  }) => {
    const lines = randomNumbersArray(count, maxWidth, minWidth)

    return (
      <div
        className={clsx(styles.root, { [styles.rounded]: rounded }, className)}
        style={{ gap: spacing }}
      >
        {lines.map((lineWidth, idx) => (
          <p
            className={clsx(styles.line, lineClassName)}
            style={{ width: `${lineWidth}%`, height: lineHeight }}
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
          />
        ))}
      </div>
    )
  }
)

export default Skeleton
