import React from "react"
import { observer, useLocalStore } from "mobx-react-lite"
import { makeAutoObservable } from "mobx"

import {
  resultViewOptions,
  ResultViewType,
} from "@framework/constants/search-results"
import { Option } from "@framework/types/utils"

class SearchDetailsContextStore {
  constructor() {
    this.resultView = resultViewOptions[0].name
    this.resultViewOptions = resultViewOptions

    makeAutoObservable(this)
  }

  resultView: ResultViewType

  resultViewOptions: Option<ResultViewType>[]

  setResultView = (newResultView: ResultViewType) => {
    this.resultView = newResultView
  }
}

export const SearchDetailsContext =
  React.createContext<SearchDetailsContextStore | null>(null)

export const SearchDetailsContextProvider: React.FC = observer(
  ({ children }) => {
    const context = useLocalStore(() => new SearchDetailsContextStore())

    return (
      <SearchDetailsContext.Provider value={context}>
        {children}
      </SearchDetailsContext.Provider>
    )
  }
)

export default SearchDetailsContext
