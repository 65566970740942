import React from "react"
import { observer } from "mobx-react-lite"
import { Outlet, useParams } from "react-router-dom"

import { useController, useStore } from "@store/index"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"

import useActiveCategoryContext from "../ActiveDataCategoryContext/useActiveCategoryContext"
import {
  BreadCrumpsCheckpoint,
  BreadCrumpsCheckpointProps,
} from "./BreadCrumpsContext/BreadCrumpsCheckpoint"

export const CloudFolderOutlet: React.FC<BreadCrumpsCheckpointProps> = observer(
  ({ ...rest }) => {
    const category = useActiveCategoryContext()
    const { dataConnectorId, folderId } = useParams<{
      dataConnectorId: string
      folderId: string
    }>()

    if (folderId == null) throw new Error("folderId is required")

    const { dataConnectorController } = useController()

    React.useEffect(() => {
      dataConnectorController.loadFolderPreview(folderId)
    }, [folderId])
    const render = React.useCallback(
      () => (
        <CloudFolderBreadCrump
          folderId={folderId}
          dataConnectorId={dataConnectorId}
          dataCategoryId={category.id}
        />
      ),
      [category.id, folderId]
    )

    return (
      <BreadCrumpsCheckpoint render={render} {...rest}>
        <Outlet />
      </BreadCrumpsCheckpoint>
    )
  }
)

export default CloudFolderOutlet

export const CloudFolderBreadCrump: React.FC<{
  dataCategoryId?: string
  dataConnectorId?: string
  folderId: string
}> = observer(({ dataCategoryId, dataConnectorId, folderId }) => {
  const { avatarDataConnectorFoldersStore, cloudFolderPreviews } = useStore()

  const previewData =
    cloudFolderPreviews.getById(folderId) ??
    (dataCategoryId && dataConnectorId
      ? avatarDataConnectorFoldersStore
          .getCollection(dataCategoryId, dataConnectorId)
          .getById(folderId)
      : undefined)

  return (
    <>
      <DocumentIcon height={18} width={18} icon="img:folder_empty" />{" "}
      {previewData?.name ?? folderId.split("-").at(-1)}
    </>
  )
})
