import React from "react"
import { observer } from "mobx-react-lite"
import { useParams } from "react-router-dom"

import Templates from "@components/ui/Templates"
import SidebarPopup from "@components/modals/components/SidebarPopup"

import DataSourceHeader from "../KnowledgeSource/DataSourceHeader"
import ActiveAvatarContextProvider from "../ActiveAvatarContext"
import ExpertPassagesList from "./ExpertPassagesList"

import styles from "./index.module.sass"

const chips = ["Expert Knowledge"]

type RouterParams = {
  categoryId: string
  avatarId: string
}

export interface ExtendedFilterProps {
  open: boolean
  onClose?: () => void
}

export const ExpertKnowledgeSidebar: React.FC<ExtendedFilterProps> = observer(
  ({ open = false, onClose }) => {
    const { avatarId } = useParams<RouterParams>()

    if (avatarId == null)
      throw Error("FormalKnowledgeSidebar has required router params: avatarId")

    return (
      <ActiveAvatarContextProvider avatarId={avatarId}>
        <SidebarPopup open={open} onClose={onClose}>
          <Templates.RollScript
            className={styles.root}
            headerSocket={
              <DataSourceHeader title="Questions and Answers" chips={chips} />
            }
          >
            <ExpertPassagesList />
          </Templates.RollScript>
        </SidebarPopup>
      </ActiveAvatarContextProvider>
    )
  }
)

export default ExpertKnowledgeSidebar
