import { makeAutoObservable } from "mobx"

import { UserGroup } from "@framework/types/user"
import { PaginationListMeta } from "@framework/types/utils"

const PAGE_SIZE = 10

export class UserGroupsStore {
  constructor() {
    makeAutoObservable(this)
  }

  isLoading: boolean = false

  error: string | null = null

  data: Map<string, UserGroup> = new Map()

  pages: Map<number, string[]> = new Map()

  meta: PaginationListMeta = {
    query: "",
    pageNum: 0,
    pageSize: PAGE_SIZE,
    total: PAGE_SIZE,
    totalPages: 1,
  }

  get getPage() {
    return (pageNum: number) => this.pages.get(pageNum) ?? []
  }

  get getById() {
    return (groupId: string | null) =>
      groupId == null ? null : this.data.get(groupId) ?? null
  }

  resetPages = () => {
    this.pages = new Map()
  }

  setLoading = (loading = true) => {
    this.isLoading = loading
  }

  setError = (error: string | null = null) => {
    this.error = error
  }

  update = (group: UserGroup[], meta: PaginationListMeta) => {
    this.pages.set(
      meta.pageNum,
      group.map((it) => {
        this.data.set(it.id, it)
        return it.id
      })
    )

    this.meta = meta
  }
}

export default UserGroupsStore
