import React, { useEffect, useRef, useState } from "react"
import { Line, ChartProps } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LineElement,
  ChartData,
  Filler,
  LinearScale,
  PointElement,
  Tooltip,
  TimeScale,
  ChartOptions,
} from "chart.js"

export const NULL = null as unknown as undefined

ChartJS.register(
  LinearScale,
  TimeScale,
  CategoryScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler
)

export type AreaChartProps = {
  onLoadData?: (chart: ChartJS | null) => ChartData<"line">
  width?: ChartProps["width"]
  height?: ChartProps["height"]
  config?: ChartOptions<"line">
}

const AreaChart: React.FC<AreaChartProps> = ({
  onLoadData,
  config,
  height = "100%",
  width = "100%",
}) => {
  const chartRef = useRef<ChartJS<"line">>(null)

  const [chartData, setChartData] = useState<ChartData<"line">>({
    datasets: [],
  })

  useEffect(() => {
    setChartData(onLoadData ? onLoadData(chartRef.current) : { datasets: [] })
  }, [onLoadData])

  return (
    <Line
      ref={chartRef}
      data={chartData}
      options={config}
      height={height}
      width={width}
    />
  )
}

export default AreaChart
