/* eslint-disable camelcase */
import React, { useEffect, useMemo } from "react"
import { To, useNavigate } from "react-router-dom"
import { useAlert } from "react-alert"
import { observer } from "mobx-react-lite"

import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import MainBoxLayout from "@components/layout/MainBoxLayout/MainBoxLayout"
import { useController, useStore } from "@store/index"
import { sharepointAccessScope } from "@services/microsoft.service"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"

import { OAuthState, useAuthCodeParams } from "./useAuthCodeParams"
import ConnectorOAuthForm, { FormDataType } from "./ConnectorOAuthForm"
import { CONNECT_SHAREPOINT_REDIRECT_URI } from "./constants"

import styles from "./AddDataConnector.module.sass"

const DEFAULT_TITLE = "Connect to Sharepoint Account"
const DEFAULT_REQUIREMENTS = ""

export interface ConnectSharepointPageProps {
  title?: string
  requirements?: string
  backPath: To
}

export const ConnectSharepointPage: React.FC<ConnectSharepointPageProps> =
  observer(
    ({
      title = DEFAULT_TITLE,
      requirements = DEFAULT_REQUIREMENTS,
      backPath,
    }) => {
      const navigate = useNavigate()
      const alert = useAlert()

      const { dataConnectorController } = useController()

      const redirectParams = useAuthCodeParams<FormDataType>(
        (form) => form.name != null
      )

      const {
        uploadSourceStore: { isDataConnectorLoading, connectSharepoint },
      } = useStore()

      const handleSubmit = async (form: FormDataType) => {
        window.location.href = dataConnectorController.getMicrosoftOAuthCodeURL(
          {
            scope: sharepointAccessScope,
            client_id: form.client_id,
            state: OAuthState.stringify(form),
            redirect_uri: CONNECT_SHAREPOINT_REDIRECT_URI,
          }
        )
      }

      const handleBack = () => navigate(backPath)

      const handleConnect = async (form: FormDataType, code: string) => {
        const { client_id, client_secret, name } = form

        const res = await connectSharepoint(
          name,
          client_id,
          client_secret,
          code
        )

        const { payload, error } = res

        if (error != null) {
          alert.error(error)
          navigate(backPath, {
            replace: true,
          })
          return
        }

        if (payload.id) {
          navigate(`${backPath}/${payload.id}`, { state: "FIRST_TIME" })
          return
        }

        handleBack()
      }

      useEffect(() => {
        if (redirectParams) {
          handleConnect(redirectParams.form, redirectParams.code)
        }
      }, [redirectParams])

      const isLoading = isDataConnectorLoading

      const initialValues = useMemo(
        () => ({
          name: redirectParams?.form.name ?? "",
          client_id: redirectParams?.form.client_id ?? "",
          client_secret: redirectParams?.form.client_secret ?? "",
        }),
        [redirectParams]
      )

      return (
        <MainBoxLayout className={styles.root} onBack={handleBack}>
          <DocumentIcon
            className={styles.icon}
            icon="img:microsoft-sharepoint"
            alt="microsoft-sharepoint"
          />

          <ModalTitle titleText={title} subtitleText={requirements} />

          <ConnectorOAuthForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </MainBoxLayout>
      )
    }
  )

export default ConnectSharepointPage
