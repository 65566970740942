import React from "react"

import { AvatarScopeContext } from "."

export const useAvatarScope = () => {
  const context = React.useContext(AvatarScopeContext)
  if (context == null) throw new Error("Can't find any ActiveAvatar")
  return context
}

export default useAvatarScope
