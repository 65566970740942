import React from "react"
import { useAlert } from "react-alert"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import Icon from "@components/ui/Icon/Icon"
import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import { autoDownloadBlob } from "@utils/file"

export interface ExportAPILogsDataButtonProps {}

export const ExportAPILogsDataButton: React.FC<ExportAPILogsDataButtonProps> =
  observer(() => {
    const alert = useAlert()

    const { apiUsageAnalyticsStore } = useStore()

    const store = apiUsageAnalyticsStore.usageHistoryStore

    const [isLoading, seLoading] = React.useState(false)

    const handleDownload = async () => {
      try {
        seLoading(true)

        const response = await store.getAPIHistoryDataAsBlob()

        if (response.status === "SUCCESS") {
          autoDownloadBlob(response.data, `api_logs_report.xlsx`)
        }

        if (response.status === "FAILED") {
          alert.error(response.message)
        }
      } catch (error) {
        alert.error("Unexpected error while downloading file")
      } finally {
        seLoading(false)
      }
    }

    return (
      <Button
        onClick={handleDownload}
        size="big"
        color="secondary"
        before={<Icon name="download" />}
        after={isLoading && <Loader />}
        disabled={isLoading}
      >
        Export
      </Button>
    )
  })

export default ExportAPILogsDataButton
