import React from "react"

import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import Icon from "@components/ui/Icon/Icon"

import styles from "./ErrorFooter.module.sass"

export interface ErrorFooterProps {
  message: string
  onRetry?: () => void
}

export const ErrorFooter: React.FC<ErrorFooterProps> = ({
  message,
  onRetry,
}) => (
  <div className={styles.root}>
    <ErrorChip message={message} />
    {onRetry && (
      <Icon
        name="retry"
        color="primary"
        className={styles.retryButton}
        onClick={onRetry}
      />
    )}
  </div>
)

export default ErrorFooter
