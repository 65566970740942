import React from "react"
import { useAlert } from "react-alert"
import { observer } from "mobx-react-lite"
import { useLocation } from "react-router-dom"

import Templates from "@components/ui/Templates"
import Text from "@components/ui/Typography/Text"
import ScrollableContainer from "@components/ui/ScrollableContainer/ScrollableContainer"
import List from "@components/ui/List/List"
import SideListItem from "@components/ui/ListItem/SideListItem"
import NotFound from "@components/ui/NotFound/NotFound"
import Loader from "@components/ui/Loader/BarLoader"
import { ExpertSubtopicDTO } from "@framework/types/knowledge"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import { useController, useStore } from "@store/index"
import Icon from "@components/ui/Icon/Icon"
import Button from "@components/ui/Button/Button"

import ArticleCard from "./TopicCard/ArticleCard"
import useArticlesCollection from "./hooks/useArticlesCollection"

import styles from "./index.module.sass"

interface ArticleListSectionProps {
  avatarId: string
  subtopic: ExpertSubtopicDTO
  selected?: string | null
  onSelect?: (value: string) => void
}

export const ArticleListSection: React.FC<ArticleListSectionProps> = observer(
  ({ avatarId, subtopic, selected, onSelect }) => {
    const alert = useAlert()
    const location = useLocation()

    const { restrictionsStore: access } = useStore()

    const subtopicId = subtopic.id
    const collection = useArticlesCollection(subtopicId)

    const { expertInsightsController } = useController()

    const articleModal = useModal(ModalsTypes.EDIT_EXPERT_ARTICLE_MODAL)

    const handleAddArticle = () => {
      articleModal.showModal({
        onSubmit: async (data) => {
          const response = await expertInsightsController.createArticles(
            avatarId,
            subtopicId,
            data.articles.map((it) => it.value)
          )

          if (response.status === "FAILED") {
            alert.error(response.message)
            return
          }

          expertInsightsController.loadArticlesList(avatarId, subtopicId)

          const totalToAdd = response.data?.length ?? 0
          const totalSucceeded = response.data?.filter(
            (it) => it.status === "SUCCESS"
          ).length

          if (totalSucceeded === totalToAdd)
            alert.success("New Articles was successfully created")
          else
            alert.info(
              `${totalSucceeded}/${totalToAdd} New Articles was successfully created`
            )

          articleModal.hideModal()
        },
      })
    }

    React.useEffect(() => {
      if (location.state?.autoAction === "ADD") handleAddArticle()
    }, [])

    const addArticleButtonNode = access.canCreateExpertArticle ? (
      <Button
        onClick={handleAddArticle}
        size="small"
        variant="outlined"
        before={<Icon name="plus" />}
      >
        Add Article
      </Button>
    ) : null

    return (
      <Templates.RollScript
        gutter="24"
        headerSocket={
          <Templates.Header
            className={styles.block}
            left={
              <Text variant="h1" weight="bold">
                {subtopic.title}
              </Text>
            }
          />
        }
        footerSocket={
          collection.data.length > 0 ? (
            <List align="center">{addArticleButtonNode}</List>
          ) : null
        }
      >
        {collection.data.length === 0 ? (
          collection.isLoading ? (
            <Loader primary size="huge" fluid />
          ) : (
            <NotFound>
              {collection.error ? (
                <>Failed to load Articles</>
              ) : (
                <List>
                  <span>
                    No Articles found!
                    <br />
                    Add one to see here
                  </span>

                  {addArticleButtonNode}
                </List>
              )}
            </NotFound>
          )
        ) : (
          <ScrollableContainer className={styles.sidebarList}>
            <List gutter="8" overflow="initial">
              {collection.data.map((article) => (
                <SideListItem
                  onClick={() => onSelect?.(article.id)}
                  active={selected === article.id}
                  tabIndex={0}
                  key={article.id}
                >
                  <ArticleCard data={article} key={article.id} />
                </SideListItem>
              ))}
            </List>
          </ScrollableContainer>
        )}
      </Templates.RollScript>
    )
  }
)

export default ArticleListSection
