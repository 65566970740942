import React from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import { AvatarData } from "@framework/types/avatar"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import useEvent from "@components/hooks/useEvent"

export interface ActiveAvatarContextType {
  avatarId: string
  avatarName: string
  avatar: AvatarData
  selectAvatar: (avatarId: string) => void
}

export const ActiveAvatarContext =
  React.createContext<ActiveAvatarContextType | null>(null)

export type Props = {
  avatarId?: string | null
  loaderNode?: React.ReactNode
  errorNode?: React.ReactNode
  onChange?: (avatarId: string) => void
}

export const ActiveAvatarContextProvider: React.FC<Props> = observer(
  ({ avatarId, children, loaderNode, errorNode, onChange }) => {
    const { avatarsStore } = useStore()

    const avatar = avatarId ? avatarsStore.getAvatarById(avatarId) : null

    const handleChange = useEvent((avatarId: string) => {
      onChange?.(avatarId)
    })

    if (avatar != null) {
      const context: ActiveAvatarContextType = {
        avatarId: avatar.id,
        avatarName: avatar.name,
        avatar,
        selectAvatar: handleChange,
      }

      return (
        <ActiveAvatarContext.Provider value={context}>
          {children}
        </ActiveAvatarContext.Provider>
      )
    }

    if (avatarsStore.isAvatarsLoading)
      return <>{loaderNode ?? <Loader size="huge" fluid />}</>

    return <>{errorNode ?? <NotFound>Avatar not found</NotFound>}</>
  }
)

export default ActiveAvatarContextProvider
