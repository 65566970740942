import React from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"

import Text from "@components/ui/Typography/Text"
import Chip from "@components/ui/Chip/Chip"
import Avatar from "@components/ui/Avatar/Avatar"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import { YYYY_MM_DD_FORMAT } from "@framework/constants/global"

import styles from "./index.module.sass"

export interface DataSourceHeaderProps {
  title: string
  updatedAt?: string | Date
  chips?: string[]
}

export const DataSourceHeader: React.FC<DataSourceHeaderProps> = observer(
  ({ title, updatedAt, chips }) => {
    const { avatar } = useActiveAvatar()

    return (
      <div className={styles.root}>
        <Text variant="h2">{title}</Text>
        {updatedAt != null && (
          <Text variant="h5" color="text70Color">
            Last updated: {moment(updatedAt).format(YYYY_MM_DD_FORMAT)}
          </Text>
        )}
        <div className={styles.chips}>
          {chips?.map((chinText) => (
            <Chip variant="rounded" color="secondary-solid" key={chinText}>
              {chinText}
            </Chip>
          ))}
          {avatar != null && (
            <Chip
              before={<Avatar src={avatar.imageUrl} />}
              variant="rounded"
              color="secondary-solid"
            >
              {avatar.name}
            </Chip>
          )}
        </div>
      </div>
    )
  }
)

export default DataSourceHeader
