import React from "react"
import clsx from "clsx"

import Chip from "@components/ui/Chip/Chip"

import styles from "./ResultsCard.module.sass"

export interface ResultsCardProps {
  answerSocket: React.ReactNode
  expertSocket?: React.ReactNode
  id?: string
  chips?: string[]
  type?: "vertical" | "horizontal"
  controllers?: React.ReactNode
  answerControl?: React.ReactChild
  color?: "primary" | "transparent"
  className?: string
}

export const ResultsCard: React.FC<ResultsCardProps> = ({
  answerSocket,
  expertSocket,
  chips,
  type = "horizontal",
  controllers,
  answerControl,
  color = "primary",
  className,
  id,
}) => (
  <div
    id={id}
    className={clsx(
      styles.root,
      styles[`variant-${type}`],
      styles[`color-${color}`],
      className
    )}
  >
    <div className={styles.result}>
      <div className={styles.body}>
        <div className={styles.answerContainer}>
          {answerSocket}
          {expertSocket}
        </div>
      </div>

      {chips?.length || answerControl ? (
        <div className={styles.footer}>
          <div className={styles.chips}>
            {chips?.map((item) => (
              <Chip color="secondary" key={item}>
                {item}
              </Chip>
            ))}
          </div>
          <div className={styles.options}>{answerControl}</div>
        </div>
      ) : null}
    </div>

    {controllers != null && (
      <div className={styles.control}>
        <div className={styles.wrapRow}>{controllers}</div>
      </div>
    )}
  </div>
)

export default ResultsCard
