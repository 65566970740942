import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import {
  AutoSizer,
  InfiniteLoader,
  List,
  ListRowProps,
} from "react-virtualized"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import { FileDataSourceName } from "@framework/types/upload"
import { useStore } from "@store/index"
import { IconName } from "@components/ui/Icon/IconName"
import { DocumentIconType } from "@framework/types/utils"
import BackButton from "@components/prototypes/BackButton"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import Templates from "@components/ui/Templates"
import NotFound from "@components/ui/NotFound/NotFound"
import Loader from "@components/ui/Loader/BarLoader"

import FilesCollectionControlBar from "./FilesCollectionControlBar"
import FileRow from "./FileRow"
import FilesTableHeader from "./FilesTableHeader"
import { SelectDataTypeContextProvider } from "../PreselectDataTypeContext"
import ManualUploadButton from "../DataSourceConnections/ManualUploadButton"

import styles from "./UploadedFilesPage.module.sass"

const BATCH_SIZE = 24

export interface ManualUploadedFilesCollectionsProps {
  sourceName: FileDataSourceName
  backPath: string
  defaultFileIcon?: DocumentIconType | IconName
}

const UploadedFilesPage: React.FC<ManualUploadedFilesCollectionsProps> =
  observer(({ sourceName, backPath, defaultFileIcon = "img:file" }) => {
    const navigate = useNavigate()

    const {
      restrictionsStore: access,
      uploadStore: { uploadedFilesCollections: store },
    } = useStore()

    const sourceTypeDescription = getDataSourceNodeContextProps(sourceName)

    const renderRow = ({ index, key, style }: ListRowProps) => {
      return (
        <div style={{ ...style, padding: 2 }} key={key}>
          <FileRow index={index} defaultFileIcon={defaultFileIcon} />
        </div>
      )
    }

    const isReadonly = !access.canEditDataSource

    const collection = store.state

    const { itemName } = sourceTypeDescription

    return (
      <MainLayout>
        <div className={styles.root}>
          <section className={styles.header}>
            <Templates.Header
              left={
                <BackButton onClick={() => navigate(backPath)}>
                  {sourceTypeDescription.label}
                </BackButton>
              }
              right={
                isReadonly ? null : (
                  <SelectDataTypeContextProvider>
                    <ManualUploadButton sourceName={sourceName} />
                  </SelectDataTypeContextProvider>
                )
              }
            />
          </section>

          <section className={styles.control}>
            <FilesCollectionControlBar />
          </section>

          <section className={styles.body}>
            <FilesTableHeader />

            <div className={styles.tableBody}>
              {collection.total ? (
                <InfiniteLoader
                  isRowLoaded={(it) => store.getByIndex(it.index) != null}
                  loadMoreRows={store.load}
                  rowCount={collection.total}
                  minimumBatchSize={BATCH_SIZE}
                  threshold={BATCH_SIZE}
                >
                  {({ onRowsRendered, registerChild }) => (
                    <AutoSizer>
                      {({ width, height }) => (
                        <List
                          rowCount={collection.total}
                          ref={registerChild}
                          onRowsRendered={onRowsRendered}
                          rowHeight={60}
                          rowRenderer={renderRow}
                          height={height}
                          width={width}
                        />
                      )}
                    </AutoSizer>
                  )}
                </InfiniteLoader>
              ) : collection.isLoading ? (
                <Loader size="huge" fluid />
              ) : (
                <NotFound>
                  {store.state.errorMessage || `No ${itemName}s found`}
                </NotFound>
              )}
            </div>
          </section>
        </div>
      </MainLayout>
    )
  })

export default UploadedFilesPage
