import { observer } from "mobx-react-lite"
import React from "react"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"

import styles from "./TechnicalSpecsTable.module.sass"

interface TechnicalSpecsRowProps {
  technicalSpecs: { id: string; property: string; value: React.ReactNode }
}

export const TechnicalSpecsRow: React.FC<TechnicalSpecsRowProps> = observer(
  ({ technicalSpecs }) => {
    const { property, value } = technicalSpecs

    return (
      <Row className={styles.row}>
        <Column
          key="property"
          align="center"
          justify="start"
          className={styles.cell}
        >
          {property}
        </Column>

        <Column
          key="value"
          align="center"
          justify="start"
          className={styles.cell}
        >
          {value}
        </Column>
      </Row>
    )
  }
)

export default TechnicalSpecsRow
