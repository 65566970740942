import React from "react"
import { observer } from "mobx-react-lite"

export interface DateRangeFilterContextData {
  initialDateRange: [Date, Date]
  dateRange: [Date, Date]
  setDateRange: (value: [Date, Date]) => void
}

export const DateRangeFilterContext =
  React.createContext<DateRangeFilterContextData | null>(null)

export type Props = {
  value: [Date, Date]
  getDefaultDateRange: () => [Date, Date]
  onChange: (value: [Date, Date]) => void
}

export const DateRangeFilterContextProvider: React.FC<Props> = observer(
  ({ value: period, onChange: setPeriod, getDefaultDateRange, children }) => {
    const initialDateRange = React.useMemo(getDefaultDateRange, [])

    const context: DateRangeFilterContextData = React.useMemo(
      () => ({
        initialDateRange,
        dateRange: period,
        setDateRange: setPeriod,
      }),
      [initialDateRange, period, setPeriod]
    )

    return (
      <DateRangeFilterContext.Provider value={context}>
        {children}
      </DateRangeFilterContext.Provider>
    )
  }
)

export const useDateRangeFilterContext = () => {
  const context = React.useContext(DateRangeFilterContext)
  if (context == null) throw new Error("Can't find DateRangeFilterContext")
  return context
}
