import React from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"

import { useStore } from "@store"
import { QueryHistoryData } from "@framework/types/analytics"
import { LOCAL_TIME_FORMAT, US_DATE_FORMAT } from "@framework/constants/global"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import UsersBubbles from "@components/ui/UsersBubbles/UsersBubbles"
import UserCard from "@components/ui/UserCard/UserCard"
import Column from "@components/ui/BaseTable/Column"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import Row from "@components/ui/BaseTable/Row"
import Icon from "@components/ui/Icon/Icon"
import Chip from "@components/ui/Chip/Chip"
import Rating from "@components/ui/Rating/Rating"
import { SolutionTypes } from "@framework/constants/search-results"

import styles from "./QueryHistoryTable.module.sass"

interface QueryHistoryTableRowProps {
  queryInfo: QueryHistoryData
  onAssignQuestionClick?: React.MouseEventHandler
}

const QueryHistoryTableRow: React.FC<QueryHistoryTableRowProps> = observer(
  ({ queryInfo, onAssignQuestionClick }) => {
    const {
      id,
      question,
      user,
      createdAt,
      rating,
      experts,
      comment,
      totalAnswered,
      totalAwaiting,
      channelName,
      productSolution,
    } = queryInfo
    const {
      solutionsStore: { allSolutions },
    } = useStore()

    const renderExperts = () => {
      if (experts?.length) {
        const isAnswered = Number(totalAnswered) > 0
        const isSingle = experts.length === 1
        return (
          <div>
            <UsersBubbles
              size="small"
              users={experts}
              suffix={isSingle ? experts[0].name : `${experts.length} Experts`}
              className={styles.userCard}
            />
            {isSingle ? (
              <Chip
                color={experts[0]?.status === "assigned" ? "green" : "gold"}
                variant="rounded"
                uppercase
              >
                {experts[0]?.status}
              </Chip>
            ) : (
              <Chip
                color={isAnswered ? "green" : "gold"}
                variant="rounded"
                uppercase
              >
                {isAnswered
                  ? `${totalAnswered} Answered`
                  : `${totalAwaiting} Waiting`}
              </Chip>
            )}
          </div>
        )
      }

      if (!onAssignQuestionClick) return "-"

      if (
        allSolutions?.find(
          (solution) => solution.key === SolutionTypes.PrivateWorkspace
        )?.id === productSolution.id
      ) {
        return "-"
      }

      return (
        <Button
          before={<Icon name="plus" />}
          onClick={onAssignQuestionClick}
          size="small"
          variant="outlined"
        >
          Assign
        </Button>
      )
    }

    return (
      <Row>
        <Column key={`${id}-question`}>{question}</Column>

        <Column key={id}>
          <UserCard
            metaInfo={user.email}
            fullName={user.name}
            avatarSrc={user.avatarUrl ?? ""}
          />
        </Column>

        <Column key={`${id}-avatar`}>{channelName}</Column>

        <Column key={`${id}-solution`}>{productSolution.name}</Column>

        <Column key={`${id}-date`}>
          <div className={styles.centeredTableRow}>
            <Text variant="inherit">
              {moment(createdAt).format(US_DATE_FORMAT)}
              <br />
              {moment(createdAt).format(LOCAL_TIME_FORMAT)}
            </Text>
          </div>
        </Column>

        <Column key={`${id}-feedback`}>
          <div className={styles.centeredTableRow}>
            <Tooltip
              // disabled={!comment}
              content={
                <TooltipContainer
                  margin="8px"
                  color="primary"
                  placement="top"
                  width="250px"
                >
                  <Text variant="inherit">{comment || "No comment"}</Text>
                </TooltipContainer>
              }
              mode="onHover"
            >
              <Rating value={rating ?? 0} className={styles.ratingWidget} />
            </Tooltip>
          </div>
        </Column>

        <Column key={`${id}-experts`}>{renderExperts()}</Column>
      </Row>
    )
  }
)

export default QueryHistoryTableRow
