import clsx from "clsx"
import React from "react"

import styles from "./MarkdownTable.module.sass"

const MarkdownTable: React.FC<React.AllHTMLAttributes<HTMLTableElement>> = ({
  children,
  className,
  ...restProps
}) => {
  return (
    <table
      {...restProps}
      className={clsx(className, styles.root, styles.withBorder, styles.offset)}
    >
      {children}
    </table>
  )
}

export default MarkdownTable
