export type AtlassianDataCenterInstanceParams = {
  instanceUrl: string
}

export type AtlassianDataCenterScope =
  | "READ"
  | "WRITE"
  | "ADMIN"
  | "SYSTEM_ADMIN"

export type AtlassianDataCenterGetAuthLinkParams =
  AtlassianDataCenterInstanceParams & {
    clientId: string
    redirectUri: string
    scope?: AtlassianDataCenterScope
    state?: string
  }

class AtlassianDataCenterService {
  // @see - https://developer.atlassian.com/server/confluence/confluence-oauth2-provider-api/#authorization-code
  getAuthorizationLink(params: AtlassianDataCenterGetAuthLinkParams): string {
    const query = new URLSearchParams({
      client_id: params.clientId,
      redirect_uri: params.redirectUri,
      scope: params.scope || "READ",
      response_type: "code",
    })
    if (params.state) {
      query.append("state", params.state)
    }

    return `${
      params.instanceUrl
    }/rest/oauth2/latest/authorize?${query.toString()}`
  }
}

export default new AtlassianDataCenterService()
