import sortBy from "lodash/sortBy"
import { observer } from "mobx-react-lite"
import React from "react"
import { useAlert } from "react-alert"
import { useNavigate } from "react-router-dom"

import useSearch from "@components/hooks/useSearch"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import BackButton from "@components/prototypes/BackButton"
import Button from "@components/ui/Button/Button"
import Container from "@components/ui/Container/Container"
import Icon from "@components/ui/Icon/Icon"
import List from "@components/ui/List/List"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import NotFound from "@components/ui/NotFound/NotFound"
import TextInput from "@components/ui/TextInput/TextInput"
import { useController, useStore } from "@store/index"

import { FeatureFlagCard } from "./FeatureFlagCard"

import styles from "./Features.module.sass"

export const FeaturesPage: React.FC = observer(() => {
  const navigate = useNavigate()
  const alert = useAlert()

  const [search] = useSearch()

  const addModal = useModal(ModalsTypes.EDIT_FEATURE_FLAG_MODAL)
  const deleteModal = useModal(ModalsTypes.DELETE_FEATURE_FLAG_MODAL)

  const handleCreateRole = () => {
    addModal.showModal({})
  }

  const { featuresStore } = useStore()
  const { appConfigController } = useController()

  React.useEffect(() => {
    appConfigController.loadFeatureFlags()
  }, [])

  const handleDelete = (flagId: string) => () => {
    deleteModal.showModal({ flagId })
  }

  const handleToggleFlag = (flagId: string, newValue: boolean) => async () => {
    const error = await appConfigController.updateFeatureFlag(flagId, newValue)
    if (error) alert.error(error)
  }

  const flags = React.useMemo(() => {
    const filteredFlags = featuresStore.features.filter((it) =>
      it.key.toLowerCase().includes(search.value.toLowerCase())
    )
    return sortBy(filteredFlags, (it) => it.key)
  }, [featuresStore.features, search.value])

  const { isLoading } = featuresStore

  return (
    <MainLayout noPadding>
      <Container>
        <div className={styles.root}>
          <EntityPageHeaderLayout
            className={styles.header}
            left={
              <BackButton onClick={() => navigate("../")}>
                <span>Features</span>
              </BackButton>
            }
            right={
              <List direction="row" justify="flex-end" gutter="16">
                <Button
                  onClick={handleCreateRole}
                  disabled={isLoading}
                  className={styles.inviteButton}
                  color="primary"
                  size="big"
                  before={<Icon name="flag-line" />}
                >
                  Add Feature Flag
                </Button>
              </List>
            }
          >
            <TextInput
              {...search}
              placeholder="Search..."
              before={<Icon name="search" />}
            />
          </EntityPageHeaderLayout>

          <LoadingCover isLoading={isLoading}>
            {!flags.length ? (
              <NotFound>No features flags found</NotFound>
            ) : (
              <div className={styles.grid}>
                {flags.map((flag) => {
                  return (
                    <FeatureFlagCard
                      id={flag.id}
                      checked={flag.value}
                      label={flag.key}
                      title={flag.key}
                      onCheck={handleToggleFlag(flag.id, !flag.value)}
                      onDelete={handleDelete(flag.id)}
                      key={flag.id}
                    />
                  )
                })}
              </div>
            )}
          </LoadingCover>
        </div>
      </Container>
    </MainLayout>
  )
})

export default FeaturesPage
