import React from "react"

import Icon from "@components/ui/Icon/Icon"
import HTMLText from "@components/ui/Typography/HTMLText"
import TextResult from "@pages/launch-solutions/SolutionTabsContainer/components/Tabs/FactFinder/components/SourceView/components/TextResult/TextResult"
import Text from "@components/ui/Typography/Text"

import type { ImageDetails } from "../AnswerOriginalView"
import ExpertAnswer from "./ExpertAnswer"

import styles from "./AnswerViewer.module.sass"

const DEFAULT_SCALE = 90

const AnswerViewer: React.FC<{
  image: ImageDetails
  fullscreen: boolean
  onFullScreenToggle: (show: boolean) => void
}> = ({ image, fullscreen, onFullScreenToggle }) => {
  const [scale, setScale] = React.useState<number>(DEFAULT_SCALE)

  const handleScaleUp = () => {
    setScale((currScale) => (currScale < 170 ? currScale + 10 : currScale))
  }

  const handleScaleDown = () => {
    setScale((currScale) => (currScale > 20 ? currScale - 10 : currScale))
  }

  React.useEffect(() => {
    setScale(DEFAULT_SCALE)
  }, [image])

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        {image?.url ? (
          <div className={styles.imageContainer}>
            <img
              style={{
                width: fullscreen ? `${scale}%` : "100%",
              }}
              src={image?.url}
              alt={image?.url}
            />

            <div className={styles.control}>
              <Icon
                onClick={() => onFullScreenToggle(!fullscreen)}
                className={styles.controlButton}
                name={fullscreen ? "minimize" : "maximize"}
              />
              {fullscreen && (
                <>
                  <Icon
                    onClick={handleScaleUp}
                    className={styles.controlButton}
                    name="zoom-in"
                  />
                  <Icon
                    onClick={handleScaleDown}
                    className={styles.controlButton}
                    name="zoom-out"
                  />
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            {image?.isExpertAnswer ? (
              <>
                <ExpertAnswer details={image?.details} />
              </>
            ) : (
              <Text className={styles.extractedTextHeading} variant="body1">
                Extracted Text
              </Text>
            )}

            {image?.isExpertAnswer ? (
              <HTMLText className={styles.textContent}>
                {image?.description}
              </HTMLText>
            ) : (
              <TextResult
                className={styles.textContent}
                content={image?.description}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default AnswerViewer
