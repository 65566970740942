import React from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { MANUAL_UPLOAD_FILE_STATUS } from "@framework/types/content-manager"
import ProgressBar from "@components/ui/ProgressBar/ProgressBar"
import { getFileIcon, getUniqueNameForFile } from "@utils/file"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"

import styles from "./ContentManagerUploadFormModal.module.sass"

type SelectedFileProps = {
  file: File
  contentType?: string
}

const SelectedFile: React.FC<SelectedFileProps> = observer(
  ({ file, contentType }) => {
    const { contentManagerStore } = useStore()
    const upload = contentManagerStore.uploads[getUniqueNameForFile(file)]
    const success = upload?.status === MANUAL_UPLOAD_FILE_STATUS.COMPLETED
    const failed = upload?.status === MANUAL_UPLOAD_FILE_STATUS.FAILED

    return (
      <div className={styles.selectedFile} key={file.webkitRelativePath}>
        <DocumentIcon icon={getFileIcon(file)} />
        <ProgressBar
          variant="candy"
          value={101}
          label={`${file.name} ${contentType ? `(${contentType})` : ""}`}
          isDone={success}
          color="primary"
          progress={
            failed ? (
              <ErrorChip message="Failed" messagePlacement="left" />
            ) : null
          }
        />
      </div>
    )
  }
)

export default SelectedFile
