import React from "react"
import { observer } from "mobx-react-lite"

import SidebarPopup from "@components/modals/components/SidebarPopup"
import { useController, useStore } from "@store/index"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import {
  DataConnectorSourceName,
  DataSourceNodeName,
} from "@framework/types/upload"

import ConnectCloudFoldersForm from "./ConnectCloudFoldersForm"
import ConnectCloudEntitiesForm from "./ConnectCloudEntitiesForm"

import styles from "./ConnectCloudFoldersSidebar.module.sass"

export interface ConnectCloudFoldersSidebarProps {
  open: boolean
  sourceName: DataSourceNodeName
  connectorSourceName: DataConnectorSourceName
  dataConnectorId: string
  nested?: boolean
  onClose?: () => void
}

export const ConnectCloudFoldersSidebar: React.FC<ConnectCloudFoldersSidebarProps> =
  observer(
    ({
      open,
      sourceName,
      connectorSourceName,
      dataConnectorId,
      nested = false,
      onClose,
    }) => {
      const { fileUploadingStore } = useStore()
      const { fileUploadingController } = useController()

      const sourceTypeDescription = getDataSourceNodeContextProps(sourceName)

      React.useEffect(() => {
        if (!open && !fileUploadingStore.isAnyInProgress)
          fileUploadingController.flush()
      }, [open])

      const FormComponent = nested
        ? ConnectCloudEntitiesForm
        : ConnectCloudFoldersForm

      return (
        <SidebarPopup
          keepMounted={false}
          className={styles.root}
          onClose={onClose}
          open={open}
        >
          <FormComponent
            title={`Connect ${nested ? "Data" : sourceTypeDescription.label}s`}
            description={`Select ${sourceTypeDescription.itemName}s you'd like to share`}
            dataConnectorId={dataConnectorId}
            sourceName={sourceName}
            connectorSourceName={connectorSourceName}
            onSubmit={onClose}
          />
        </SidebarPopup>
      )
    }
  )

export default ConnectCloudFoldersSidebar
