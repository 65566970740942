import React from "react"

const DKSHLogo: React.FC<{
  height?: number
  width?: number
  className?: string
}> = ({ width, height, className }) => (
  <svg
    width={width}
    height={height}
    className={className}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1523 470"
  >
    <title>logo_dksh_white-svg</title>
    <g id="Ebene_2">
      <g id="DKHS_NEG_White">
        <g id="DKSH_NEG_White">
          <g id="DKSH-logotype_NEG">
            <g id="H">
              <path
                id="Layer"
                fill="currentColor"
                d="M818.7,238c0,42.8-12.2,75-36.6,96.7c-24.4,21.7-58.4,32.5-102,32.5h-71.8V113.7h79.6
						c5.9-0.1,11.9,0.1,17.8,0.6c5.9,0.5,11.8,1.4,17.6,2.6c5.8,1.2,11.5,2.7,17.2,4.5c5.6,1.9,11.1,4,16.5,6.5
						c4.7,2.3,9.3,4.9,13.7,7.8c4.3,2.9,8.4,6.2,12.3,9.7c3.8,3.5,7.4,7.3,10.7,11.4c3.3,4.1,6.2,8.5,8.8,13
						C813.3,188.3,818.7,211,818.7,238z M762.9,239.4c0-27.9-6.2-48.5-18.5-61.8c-12.3-13.3-30.2-19.9-53.6-19.9H662v165.1h23.1
						C737,322.8,762.9,295,762.9,239.4z M1073.2,367.2h-61.1l-66.4-106.9l-22.8,16.3v90.6h-53.6V113.7h53.6v116.1
						c3.6-5,7.2-10,10.7-14.9c3.5-5,7.1-10,10.6-14.9l68.6-86.3h59.7L984,225.9L1073.2,367.2z M1101.2,307.2
						c11.1,4.5,22.6,8.7,34.5,12.6c3.2,1.1,6.5,2,9.8,2.8c3.3,0.8,6.6,1.5,10,2.1c3.3,0.5,6.7,0.9,10.1,1.2
						c3.4,0.3,6.8,0.4,10.2,0.5c13.8,0,23.6-2.6,29.3-7.7c1.4-1.2,2.7-2.5,3.8-4c1.1-1.5,2-3,2.8-4.7c0.7-1.7,1.3-3.4,1.6-5.2
						c0.4-1.8,0.6-3.6,0.5-5.4c0.1-1.5-0.1-3.1-0.4-4.6c-0.3-1.5-0.7-2.9-1.3-4.3c-0.6-1.4-1.3-2.7-2.2-4c-0.9-1.3-1.8-2.4-2.9-3.5
						c-1.3-1.3-2.7-2.5-4.2-3.6c-1.5-1.2-3-2.3-4.5-3.4c-1.5-1-3.1-2-4.7-2.9c-1.6-0.9-3.2-1.8-4.9-2.7c-7.7-3.9-16.4-8.1-26.2-12.6
						c-6.2-2.8-12.9-6.2-20-10.1c-1.8-1-3.6-2.1-5.4-3.2c-1.8-1.1-3.6-2.3-5.3-3.5c-1.7-1.2-3.3-2.5-5-3.8c-1.6-1.3-3.2-2.6-4.8-4
						c-1.7-1.5-3.3-3.1-4.8-4.7c-1.5-1.7-2.9-3.4-4.2-5.1c-1.4-1.8-2.7-3.6-3.8-5.5c-1.2-1.9-2.3-3.9-3.3-5.9
						c-1.1-2.3-2-4.6-2.8-7.1c-0.9-2.4-1.6-4.9-2.1-7.4c-0.5-2.5-0.9-5.1-1.1-7.6c-0.3-2.5-0.4-5.1-0.3-7.7c0-22.7,8-40.4,24.1-53.1
						c16-12.7,37.8-19,65.4-19c3.3,0,6.6,0.1,9.9,0.3c3.3,0.2,6.5,0.5,9.8,0.8c3.3,0.4,6.5,0.9,9.8,1.5c3.2,0.6,6.4,1.3,9.6,2.1
						c6.6,1.6,13.4,3.6,20.3,6v48.7c-2.6-1.1-5.2-2.1-7.8-3c-2.6-0.9-5.2-1.9-7.9-2.8c-2.7-0.9-5.3-1.7-8-2.6
						c-2.7-0.8-5.3-1.6-8-2.3c-2.4-0.7-4.8-1.2-7.3-1.7c-2.5-0.5-4.9-1-7.4-1.3c-2.5-0.3-4.9-0.5-7.4-0.7c-2.5-0.1-5-0.2-7.5-0.2
						c-2.1-0.1-4.3-0.1-6.4,0.1c-2.2,0.3-4.3,0.7-6.4,1.3c-2.1,0.6-4.1,1.4-6,2.3c-1.9,1-3.8,2.1-5.6,3.4c-1.3,1.1-2.5,2.3-3.6,3.7
						c-1.1,1.4-2.1,2.9-2.8,4.5c-0.7,1.6-1.3,3.3-1.6,5c-0.3,1.7-0.5,3.5-0.5,5.2c0,2.2,0.3,4.4,0.9,6.5c0.5,2.1,1.4,4,2.5,5.9
						c1.1,1.9,2.4,3.6,4,5.1c1.5,1.5,3.3,2.8,5.2,3.8c3,1.8,6,3.5,9.1,5.2c3.1,1.7,6.2,3.4,9.3,5c3.1,1.6,6.2,3.1,9.4,4.6
						c3.1,1.5,6.3,3,9.5,4.4c3,1.3,6,2.7,9,4.2c2.9,1.5,5.8,3,8.7,4.6c2.9,1.6,5.8,3.3,8.6,5c2.8,1.7,5.6,3.5,8.3,5.4
						c2.2,1.5,4.3,3.2,6.4,4.9c2.1,1.7,4,3.6,5.9,5.6c1.8,1.9,3.5,4,5.2,6.2c1.6,2.1,3.1,4.4,4.5,6.7c1.3,2.5,2.5,5.2,3.5,7.9
						c1.1,2.7,1.9,5.5,2.5,8.3c0.7,2.8,1.1,5.6,1.4,8.5c0.3,2.9,0.4,5.8,0.3,8.7c0.2,5.1-0.2,10.3-1.3,15.3c-1,5.1-2.6,10-4.8,14.6
						c-2.2,4.7-5,9.1-8.2,13.1s-6.9,7.6-11,10.8c-16.9,13.3-40.8,20-71.9,20c-6,0.1-12-0.1-17.9-0.6c-6-0.5-11.9-1.3-17.8-2.4
						c-5.9-1.1-11.8-2.6-17.5-4.3c-5.7-1.7-11.4-3.7-16.9-6L1101.2,307.2z M1522.1,113.7v253.5h-53.7V257.8h-100.6v109.4h-53.6
						V113.7h53.6v99.4h100.6v-99.4L1522.1,113.7z"
              />
            </g>
          </g>
          <g id="DKSH-fantree-symbol_NEG">
            <path
              fill="currentColor"
              id="Layer_1_"
              d="M404.2,139.8c19.5,2.6,29,15.3,31.1,24.2c0.7,2.8,0.6,3.8-2.3,3.8c-2.5,0-5,0.1-7.5,0.3s-5,0.5-7.5,0.8
					c-2.5,0.3-5,0.8-7.5,1.3s-4.9,1.1-7.3,1.8c-1.2,0.4-1.1,0.4-2.1-0.5c-9.3-7.7-17.7-12.6-26.5-12.6c-0.9,0-1.8,0.6-1.6,1.4
					c3.9,6.7,7.4,10.9,10.7,16c1.3,2,1.1,2.3-1,3.4c-2.3,1.1-4.5,2.2-6.7,3.3c-2.3,1.2-4.5,2.4-6.6,3.7c-2.2,1.2-4.3,2.5-6.4,3.9
					c-2.1,1.3-4.2,2.7-6.3,4.1c-1.3,0.9-1.2,0.9-2.6,0.1c-1.7-1.2-3.4-2.2-5.3-3.1c-1.8-0.9-3.7-1.7-5.6-2.3c-2-0.7-4-1.2-6-1.5
					c-2-0.3-4-0.5-6.1-0.6c-0.8,0.1-1.6,0.9-1.2,1.6c3.4,5.2,7,9.7,10.8,15c1.3,1.7,1.2,1.9-0.4,3.2c-4.5,3.7-8.9,7.5-13.2,11.4
					c-4.3,3.9-8.6,7.9-12.8,11.9c-4.1,4.1-8.2,8.2-12.3,12.3c-4.1,4.2-8.1,8.4-12,12.7c-5.2,5.7-9.8,4.2-16.6,3.1
					c0.1-19.8,0.1-18.5,0.1-22.1C300.6,190.4,351.9,132.7,404.2,139.8L404.2,139.8z M273.2,222.8c-0.7-25.7-3.1-52.4-13.7-78
					c12.7-19.2,31-41.7,49.4-49.6c23.2-9.9,37.2-3,40.6,2.4c1.5,2.5,2.3,3.1-0.1,4.5C303.3,128.6,282.8,193.8,273.2,222.8z
					 M470.8,256.9c-1.9-1.1-3.7-2-5.6-2.9c-1.9-0.9-3.9-1.6-5.9-2.2c-2-0.7-4-1.2-6-1.7c-2.1-0.4-4.1-0.7-6.2-1
					c-1.4-0.2-1.3-0.1-1.8-1.3c-5.2-10.8-11.1-18.3-16.3-20.5c-0.9-0.3-1.8-0.3-2,0.5c0,5.9,0.4,11.6,0.7,18.2
					c0.1,2.1-0.1,2.3-2.2,2.4c-2.4,0.1-4.8,0.3-7.2,0.6c-2.3,0.3-4.7,0.6-7.1,0.9c-2.3,0.3-4.7,0.7-7,1.1c-2.3,0.5-4.7,0.9-7,1.4
					c-1.6,0.4-1.6,0.4-2.5-1c-5.6-8.7-10.9-13.4-15.9-15.1c-0.8-0.3-1.7,0.1-1.7,0.8c0.2,3.5,1,10.5,1.8,18.1
					c0.2,1.8,0.1,1.9-1.7,2.4c-4.5,1.4-8.9,2.9-13.3,4.4c-4.5,1.5-8.9,3.1-13.2,4.8c-4.4,1.7-8.7,3.4-13,5.3
					c-4.3,1.8-8.6,3.7-12.9,5.7c-0.7,0.4-1.5,0.7-2.4,0.9c-0.8,0.2-1.6,0.3-2.5,0.3c-0.9-0.1-1.7-0.2-2.5-0.5
					c-0.8-0.2-1.6-0.5-2.3-1c-13.1-9.2-33.6,7.9-45.8,31.8c-0.6,1.1-1.2,2.1-1.7,3.2c-0.5,1.1-1,2.2-1.5,3.3
					c-0.5,1.1-0.9,2.3-1.4,3.4c-0.4,1.2-0.8,2.4-1.1,3.5c74-67.4,194.9-48.5,207.7-10.7c1,2.5,1.7,5,2,7.6c0.3,2.6,0.2,5.2-0.1,7.8
					c-0.3,2.6-1,5.1-1.9,7.5c-1,2.5-2.3,4.8-3.8,6.9c-1.5,2.4-2.8,3-4.6,0.8c-1.1-1.3-2.2-2.5-3.5-3.7c-1.2-1.2-2.4-2.4-3.7-3.5
					c-1.3-1.1-2.7-2.1-4.1-3.1c-1.4-0.9-2.8-1.8-4.3-2.7c-1.1-0.7-1-0.6-1.2-1.8c-0.1-2.5-0.5-5-1.1-7.5c-0.6-2.4-1.4-4.8-2.4-7.1
					c-0.9-2.3-2.1-4.6-3.4-6.7c-1.3-2.1-2.8-4.2-4.5-6.1c-0.6-0.6-1.8-0.7-2.1,0c-1.3,6.9-2.3,12.6-3.5,18.3c-0.5,2.3-0.8,2.4-3,1.8
					c-1.7-0.5-3.5-1-5.2-1.5s-3.5-0.9-5.3-1.3c-1.7-0.4-3.5-0.7-5.3-1c-1.8-0.3-3.6-0.6-5.4-0.9c-1.5-0.2-1.5-0.2-1.9-1.7
					c-2.9-11-7.3-19.2-11.7-22.6c-0.6-0.5-1.8-0.5-1.9,0.4c-0.8,5.6-1.4,12.3-2.4,19.9c-0.3,2-0.4,2.1-2.4,2
					c-5.1-0.1-10.1-0.2-15.2-0.2c-5.1,0.1-10.1,0.2-15.1,0.5c-5.1,0.3-10.1,0.7-15.1,1.2c-5.1,0.5-10.1,1.2-15.1,1.9
					c-0.9,0.1-1.9,0-2.8-0.1c-0.9-0.1-1.9-0.4-2.8-0.7c-0.9-0.3-1.7-0.8-2.5-1.3s-1.5-1.2-2.2-1.9c-8.7-8.8-27.7-1.2-45.7,11
					c-5.3,3.8-8.7,7.3-8.6,11.1v132.3c0,2.2-0.2,2.4-2.4,2.4c-16.5,0-33-1.6-49.2-4.5c-16.3-3-32.2-7.5-47.6-13.4
					c-15.5-5.8-30.4-13.1-44.5-21.6c-14.1-8.6-27.4-18.4-39.8-29.3C27.3,357.1,0,297.3,0,235S27.3,112.9,76,68.8
					c12.4-10.9,25.7-20.7,39.8-29.3c14.1-8.5,29-15.8,44.5-21.7c15.4-5.8,31.3-10.3,47.6-13.3c16.2-3,32.7-4.5,49.2-4.5
					c2.2,0,2.4,0.2,2.4,2.4v142.4c-2.1-5.5-4.7-10.7-7.8-15.7c-3-5-6.5-9.7-10.4-14.1c-3.9-4.3-8.2-8.3-12.8-11.9
					c-4.7-3.6-9.6-6.7-14.8-9.4c-18.8-9.7-35-5.3-44.7,3.7c-2.1,2-2.2,2.8,0.1,4.4c2.3,1.5,4.5,3.1,6.6,4.7c2.2,1.7,4.3,3.3,6.4,5
					c2.1,1.7,4.2,3.4,6.2,5.2c2.1,1.8,4.1,3.6,6,5.5c1.2,1,1.1,1,2.5,0.4c1.8-0.8,3.6-1.5,5.5-2.1c1.8-0.7,3.7-1.2,5.6-1.5
					c1.9-0.4,3.9-0.7,5.8-0.8s3.9-0.2,5.8-0.1c0.9,0.1,1.6,1.1,1.1,1.7c-5.1,5-9.5,8.1-13.8,11.7c-1.7,1.4-1.8,1.7-0.3,3.3
					c1.4,1.6,2.7,3.2,4,4.8c1.3,1.7,2.6,3.3,3.9,5c1.3,1.7,2.5,3.4,3.7,5.1c1.2,1.7,2.4,3.4,3.5,5.2c1,1.5,1.1,1.5,2.8,0.9
					c1.9-0.8,3.8-1.5,5.7-2c1.9-0.5,3.9-1,5.9-1.3c2-0.3,4-0.5,6.1-0.6c2-0.1,4,0,6,0.1c0.8,0.2,1.3,1.3,0.7,1.9
					c-5.4,4.7-11.2,8.5-17.6,13.7c-1.4,1.2-1.5,1.2-0.6,2.9c2.1,4.3,4.1,8.6,5.9,13.1c1.8,4.4,3.5,8.9,5,13.4
					c1.5,4.5,2.8,9.1,3.9,13.8c1.1,4.7,2.1,9.4,2.9,14.1c0.1,0.7,0.2,1.3,0.5,1.9c0.3,0.6,0.7,1.2,1.2,1.7c0.4,0.5,0.9,0.9,1.5,1.3
					c0.6,0.3,1.2,0.6,1.9,0.7c12.6,3.9,18.8,32.3,6.1,60.4C240.8,188.7,175.8,129.1,115,137.6c-3.7,0.3-7.3,1.1-10.6,2.5
					c-3.4,1.4-6.6,3.3-9.4,5.6c-2.8,2.4-5.2,5.2-7.1,8.3s-3.4,6.4-4.3,10c-0.7,2.8-0.4,3.3,2.3,3.8c73.8,13.1,101.8,37.8,129.9,74.4
					c0.5,0.7,1,1.2,1.5,1.7c0.6,0.5,1.3,0.9,2,1.2c0.7,0.3,1.5,0.4,2.2,0.5s1.5,0,2.3-0.1c21.4-4.3,28.3,44.7,33.9,72.8
					C198.2,215.6,93.5,190.8,53.5,224c-2.5,1.8-4.8,4-6.6,6.6c-1.9,2.5-3.4,5.3-4.3,8.3c-1,2.9-1.5,6-1.5,9.2c0,3.1,0.5,6.2,1.5,9.2
					c0.8,2.7,0.8,3.4,3.5,2.5c2.6-1,5.2-1.9,7.9-2.6c2.7-0.8,5.3-1.5,8-2.2c2.7-0.6,5.5-1.1,8.2-1.6c2.7-0.5,5.5-0.8,8.2-1.1
					c1.5-0.2,1.5-0.2,2-1.5c4.5-11.9,9-21.1,14.3-23.9c0.7-0.4,1.8-0.3,1.9,0.4c0,6.8-0.7,13.6-1.1,21.6c-0.1,2.3,0.1,2.5,2.3,2.5
					c2.6,0.1,5.2,0.2,7.8,0.4c2.6,0.1,5.2,0.3,7.7,0.6c2.6,0.3,5.2,0.7,7.7,1.1c2.6,0.3,5.2,0.8,7.7,1.3c1.7,0.4,1.7,0.3,2.6-1.2
					c5.1-9.9,9.2-16.1,14-18.3c0.7-0.3,1.8,0.1,1.7,0.9c-0.2,3.8-1.3,12-2.3,20.4c-0.2,1.9-0.2,2,1.7,2.6c3.8,1.1,7.5,2.3,11.2,3.6
					c3.7,1.3,7.4,2.7,11.1,4.2c3.7,1.5,7.3,3,10.8,4.7c3.6,1.7,7.1,3.5,10.6,5.3c0.7,0.4,1.5,0.7,2.4,0.9c0.8,0.2,1.6,0.3,2.5,0.2
					c0.9,0,1.7-0.1,2.5-0.4c0.8-0.2,1.6-0.5,2.3-0.9c18.3-9.6,66.2,55.4,55.7,47.1c-92.3-73.5-191.8-53.2-207.9-11
					c-0.9,2.4-1.5,4.9-1.9,7.5c-0.3,2.6-0.4,5.2-0.1,7.8s0.8,5.1,1.7,7.6c0.9,2.5,2,4.8,3.4,7.1c1.5,2.4,2.5,2.8,4.5,0.8
					c1.9-1.9,3.9-3.7,6-5.4c2-1.7,4.1-3.3,6.3-4.8c2.2-1.5,4.5-2.9,6.8-4.2c2.3-1.3,4.7-2.5,7.2-3.6c1.2-0.6,1.2-0.6,1.3-2
					c0.1-2.5,0.4-5.1,0.8-7.6c0.4-2.5,0.9-5,1.6-7.5c0.7-2.5,1.5-5,2.5-7.3c0.9-2.4,2-4.7,3.3-7c0.1-0.1,0.2-0.3,0.4-0.4
					c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.1,0.1,0.3,0.3,0.4,0.4c1.6,8.6,2.6,15.4,4,22.8c0.4,2.2,0.7,2.4,3,1.8
					c2.4-0.7,4.8-1.3,7.3-1.9c2.5-0.5,4.9-1,7.4-1.5c2.5-0.4,4.9-0.8,7.4-1.1c2.5-0.3,5-0.6,7.5-0.9c1.7-0.1,1.7-0.1,2.1-1.8
					c0.4-2,0.9-4,1.5-6.1c0.5-2,1.2-4,2-5.9c0.8-1.9,1.7-3.8,2.6-5.7c0.9-1.8,2-3.6,3.1-5.4c0.5-0.6,1.8-0.4,1.9,0.4
					c0.8,5.9,1.3,13.2,2.2,21.5c0.2,2,0.3,2.1,2.4,2.1c3.5-0.1,7-0.1,10.5,0c3.5,0.1,7,0.2,10.5,0.4c3.5,0.2,6.9,0.4,10.4,0.7
					c3.5,0.3,7,0.7,10.5,1.1c0.9,0,1.9-0.1,2.8-0.3c0.9-0.1,1.8-0.4,2.7-0.7c0.9-0.3,1.7-0.8,2.5-1.3c0.8-0.5,1.6-1,2.3-1.6
					c18.6-10.3,47.4,12.3,58,19.7c0.5-2.5,1.2-5,1.9-7.5c0.7-2.5,1.4-5,2.3-7.4c0.9-2.5,1.8-4.9,2.8-7.3c1-2.4,2-4.8,3.1-7.1
					c50-90.9,164.4-109.4,195.5-79c2.1,2,4,4.3,5.7,6.8c1.6,2.5,2.9,5.1,3.9,7.9c1,2.8,1.7,5.7,2,8.6c0.3,3,0.2,6-0.2,8.9
					C474.1,257.3,473.2,258.4,470.8,256.9L470.8,256.9z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default DKSHLogo
