import React from "react"

const GraceLogo: React.FC<{
  height?: number
  width?: number
  className?: string
}> = ({ width, height, className }) => (
  <svg
    width={width}
    height={height}
    className={className}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 168.2 43.6"
  >
    <g>
      <g>
        <path
          fill="currentColor"
          d="M93.2,24.9H80.9L87.1,9L93.2,24.9z M134.6,11.6h7.1c-0.8-2.5-3.5-5.5-6.2-7.4c-3.6-2.4-7.9-4.2-12.2-4.2
			c-6,0-11.5,2.1-15.8,6.4c-3.9,3.7-6.4,9.9-6.4,15.4c0,12.2,10.3,21.8,22.2,21.8c11,0,19.7-9.7,20.7-18.8h-6.2
			c-1.2,7.2-8,12.6-14.5,12.6c-8.6,0-15.9-6.6-15.9-15.5c0-4,2.1-8.9,5.1-11.6s6.9-4,10.7-4C128.1,6.3,131.5,8.2,134.6,11.6z 
			M144.4,1.4V43h23.8v-5.7h-17.9V25.2h17.9v-5.7h-17.9V7h17.9V1.3L144.4,1.4L144.4,1.4z M33.3,11.6h7.2c-0.8-2.5-3.5-5.5-6.2-7.4
			c-3.5-2.4-7.8-3.6-12.1-3.6c-6,0-11.5,2.1-15.8,6.4C2.5,10.7,0,16.4,0,21.8C0,34,10.3,43.6,22.2,43.6c11,0,20.3-8.1,21.1-20.4
			c0-0.3,0-0.7,0-1H16.4v6.2H36c-1.4,5.1-7.4,8.9-14,8.9c-8.7,0-16-6.6-16-15.5c0-4,2.1-8.3,5.1-11c2.9-2.7,6.8-4,10.7-4
			C27,6.9,30.3,8.2,33.3,11.6z M68.8,39.3l-8.7-12.2c5.2,0,11.5-5.3,11.5-12.1c0-4.9-2.2-9-6.5-11.5c-3.5-2-7.8-2.1-11.8-2.1h-9.5
			V43H50V6.7h4c2.9,0,5.7,0,8,1.6c2,1.4,3.3,3.8,3.3,6.2c0,3.2-1.7,5.5-4.6,6.8c-2.3,1.1-5.2,1-7.6,1h-2.9l13.5,20.6h10.5l4.7-12.3
			h16.4l4.7,12.3h6.9l-17-41.6h-5.6L68.8,39.3z"
        />
      </g>
      <g>
        <path
          fill="currentColor"
          d="M93.2,24.9H80.9L87.1,9L93.2,24.9z M134.6,11.6h7.1c-0.8-2.5-3.5-5.5-6.2-7.4c-3.6-2.4-7.9-4.2-12.2-4.2
			c-6,0-11.5,2.1-15.8,6.4c-3.9,3.7-6.4,9.9-6.4,15.4c0,12.2,10.3,21.8,22.2,21.8c11,0,19.7-9.7,20.7-18.8h-6.2
			c-1.2,7.2-8,12.6-14.5,12.6c-8.6,0-15.9-6.6-15.9-15.5c0-4,2.1-8.9,5.1-11.6s6.9-4,10.7-4C128.1,6.3,131.5,8.2,134.6,11.6z 
      M144.4,1.4V43h23.8v-5.7h-17.9V25.2h17.9v-5.7h-17.9V7h17.9V1.3L144.4,1.4L144.4,1.4z M33.3,11.6h7.2c-0.8-2.5-3.5-5.5-6.2-7.4
			c-3.5-2.4-7.8-3.6-12.1-3.6c-6,0-11.5,2.1-15.8,6.4C2.5,10.7,0,16.4,0,21.8C0,34,10.3,43.6,22.2,43.6c11,0,20.3-8.1,21.1-20.4
			c0-0.3,0-0.7,0-1H16.4v6.2H36c-1.4,5.1-7.4,8.9-14,8.9c-8.7,0-16-6.6-16-15.5c0-4,2.1-8.3,5.1-11c2.9-2.7,6.8-4,10.7-4
			C27,6.9,30.3,8.2,33.3,11.6z M68.8,39.3l-8.7-12.2c5.2,0,11.5-5.3,11.5-12.1c0-4.9-2.2-9-6.5-11.5c-3.5-2-7.8-2.1-11.8-2.1h-9.5
			V43H50V6.7h4c2.9,0,5.7,0,8,1.6c2,1.4,3.3,3.8,3.3,6.2c0,3.2-1.7,5.5-4.6,6.8c-2.3,1.1-5.2,1-7.6,1h-2.9l13.5,20.6h10.5l4.7-12.3
			h16.4l4.7,12.3h6.9l-17-41.6h-5.6L68.8,39.3z"
        />
      </g>
    </g>
  </svg>
)

export default GraceLogo
