import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useNavigate, useParams } from "react-router-dom"

import mainRoutes from "@root/main.routes"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import Switch from "@components/ui/Switch/Switch"
import { Option } from "@framework/types/utils"
import Container from "@components/ui/Container/Container"
import Templates from "@components/ui/Templates"
import TextInput from "@components/ui/TextInput/TextInput"
import Icon from "@components/ui/Icon/Icon"
import QuestionSuggestionItem from "@pages/home/SearchResults/components/QuestionSuggestionItem/QuestionSuggestionItem"
import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import BackButton from "@components/prototypes/BackButton"

import styles from "./index.module.sass"

type TabName = "popular" | "recent"

const tabs: Option<TabName>[] = [
  {
    name: "popular",
    value: "Popular questions",
  },
  {
    name: "recent",
    value: "Recent questions",
  },
]

type ParamsType = {
  tab: TabName
}

interface QuestionsPageProps {}

const QuestionsPage: React.FC<QuestionsPageProps> = observer(() => {
  const { tab } = useParams<ParamsType>()
  const navigate = useNavigate()

  const {
    userSetupStore: { avatarId },
    searchSuggestionStore: {
      favoriteQuestions,
      trendingQuestions,
      isQuestionsLoading,
      loadQuestions,
    },
    solutionsStore: { solution },
  } = useStore()

  const questions = tab === "popular" ? trendingQuestions : favoriteQuestions

  const handleChangeTab = (newTab: string) => {
    navigate(mainRoutes.homeQuestions(newTab), { replace: true })
  }

  useEffect(() => {
    if (!tabs.find((it) => it.name === tab)) navigate(mainRoutes.home())
  }, [tab])

  useEffect(() => {
    if (avatarId && solution?.id) loadQuestions(avatarId, solution.id)
  }, [avatarId, solution?.id])

  return (
    <MainLayout noPadding>
      <Container>
        <div className={styles.root}>
          <Templates.Header
            offsetBottom="big"
            left={<BackButton>Questions</BackButton>}
          />
          <Templates.Header
            offsetBottom="big"
            left={
              <Switch items={tabs} checked={tab} onChange={handleChangeTab} />
            }
            right={
              <TextInput
                className={styles.searchInput}
                placeholder="Search"
                before={<Icon name="search" />}
              />
            }
          />

          {questions.length === 0 ? (
            isQuestionsLoading ? (
              <Loader size="huge" fluid />
            ) : (
              <NotFound />
            )
          ) : (
            <div className={styles.grid}>
              {questions.map((it) => (
                <QuestionSuggestionItem
                  question={it.value}
                  isSelected={false}
                  className={styles.listItem}
                  onClick={() => {}}
                  withArrow
                  key={it.name}
                />
              ))}
            </div>
          )}
        </div>
      </Container>
    </MainLayout>
  )
})

export default QuestionsPage
