import React from "react"
import { observer } from "mobx-react-lite"

import Label from "@components/ui/Label/Label"
import Editor from "@components/ui/Editor/Editor"
import Templates from "@components/ui/Templates"
import Button from "@components/ui/Button/Button"
import { isEditorTextEmpty } from "@components/ui/Editor/utils"
import Loader from "@components/ui/Loader/BarLoader"
import List from "@components/ui/List/List"

import styles from "./index.module.sass"

interface InsightFormProps {
  defaultValue?: string
  onSubmit?: (data: { insight: string }) => Promise<void>
  onCancel?: () => void
}

export const InsightForm: React.FC<InsightFormProps> = observer(
  ({ defaultValue = "", onSubmit, onCancel }) => {
    const [answer, setAnswer] = React.useState<string>("")
    const [isLoading, setLoading] = React.useState(false)

    const valid = !isEditorTextEmpty(answer)

    const handleSubmit = async () => {
      setLoading(true)
      await onSubmit?.({ insight: answer })
      setLoading(false)
    }

    return (
      <List className={styles.insightForm}>
        <Label id="insight" label="Insight" className={styles.reachTextEditor}>
          <Editor
            onChange={setAnswer}
            defaultValue={defaultValue}
            className={styles.reachTextEditor}
            placeholder="Add your valuable insights"
          />
        </Label>

        <Templates.Header
          left={
            <Button
              color="default"
              variant="outlined"
              disabled={isLoading}
              onClick={onCancel}
            >
              Cancel
            </Button>
          }
          right={
            <Button
              color="primary"
              disabled={!valid || isLoading}
              onClick={handleSubmit}
              after={isLoading && <Loader />}
            >
              Save
            </Button>
          }
        />
      </List>
    )
  }
)

export default InsightForm
