import React, { useMemo } from "react"
import moment from "moment"
import { observer } from "mobx-react-lite"

import { DocumentIconType } from "@framework/types/utils"
import {
  DataConnectorContentGroupName,
  DataSourceFile,
} from "@framework/types/upload"
import BaseDataSourceCard from "@components/prototypes/BaseDataSourceCard/BaseDataSourceCard"
import ErrorFooter from "@components/prototypes/BaseDataSourceCard/ErrorFooter"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import { countSuffix } from "@utils/numberUtils"
import { IconName } from "@components/ui/Icon/IconName"
import Text from "@components/ui/Typography/Text"

interface DataConnectorCardProps {
  item: DataSourceFile
  defaultIcon?: DocumentIconType | IconName
  control?: React.ReactNode
  contentItemName?: DataConnectorContentGroupName
  onClick?: (id: string, e: React.MouseEvent) => void
  onRetry?: () => void
}

const DataConnectorCard: React.FC<DataConnectorCardProps> = observer(
  ({
    item,
    defaultIcon = "global",
    contentItemName = "folders",
    control,
    onRetry,
    onClick,
  }) => {
    const { id, error, lastUpdatedAt, status, connectedFolders } = item
    const icon = defaultIcon

    const sourceTypeDescription = getDataSourceNodeContextProps(contentItemName)

    const handleClick = useMemo(
      () => (onClick ? (e: React.MouseEvent) => onClick(id, e) : undefined),
      [id, onClick]
    )

    const renderContent = () => {
      if (status === "failed")
        return (
          <ErrorFooter message={error || "Unknown error"} onRetry={onRetry} />
        )

      if (connectedFolders != null) {
        return (
          <Text variant="caption3" color="text50Color">
            {connectedFolders} {sourceTypeDescription.itemName}
            {countSuffix(connectedFolders)} connected
          </Text>
        )
      }

      return (
        <Text variant="caption3" color="text50Color">
          Last updates: {moment(lastUpdatedAt).format("YYYY-MM-DD")}
        </Text>
      )
    }

    return (
      <BaseDataSourceCard
        title={item.name}
        icon={icon}
        content={renderContent()}
        onClick={handleClick}
        control={control}
        key={item.id}
      />
    )
  }
)

export default DataConnectorCard
