import React, { MouseEventHandler } from "react"
import moment from "moment"

import UserCard from "@components/ui/UserCard/UserCard"
import Text from "@components/ui/Typography/Text"

import EntityItem from "../components/ItemsCollection/EntityItem"

interface ChatEntityItemProps {
  title: string
  messagePreview?: string
  active?: boolean
  timestamp?: number
  onClick?: MouseEventHandler
}

const ChatEntityItem: React.FC<ChatEntityItemProps> = ({
  title,
  messagePreview,
  active,
  timestamp,
  onClick,
}) => {
  return (
    <EntityItem active={active} onClick={onClick}>
      <UserCard
        fullName={title}
        metaInfo={
          <Text variant="h6" color="text50Color">
            {messagePreview}
          </Text>
        }
      />
      <Text variant="caption3" color="text40Color">
        {moment(timestamp).format("LT")}
      </Text>
    </EntityItem>
  )
}

export default ChatEntityItem
