import React from "react"
import { observer } from "mobx-react-lite"

import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import { DataConnectorSourceName } from "@framework/types/upload"
import { useStore } from "@store/index"
import Text from "@components/ui/Typography/Text"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import { capitalizeFirstLetter } from "@utils/textUtils"

import styles from "./DataConnectorPickerButton.module.sass"

const DataConnectorPickerButton: React.FC<{
  connectorName: DataConnectorSourceName
  dataConnectorId?: string
}> = observer(({ connectorName, dataConnectorId }) => {
  const selectDCModal = useModal(
    ModalsTypes.SELECT_DATA_CONNECTOR_ACCOUNT_MODAL
  )

  const { uploadStore } = useStore()

  const connectorStore =
    uploadStore.getDataConnectorAccountsStoreByName(connectorName)

  const contentTypeDescription = getDataSourceNodeContextProps(connectorName)

  const dataConnector = connectorStore.getById(dataConnectorId)

  const accountName =
    dataConnector?.name ??
    (connectorStore.state.isLoading ? "Loading..." : "Unknown")

  const handleClick = () => {
    selectDCModal.showModal({ sourceName: connectorName })
  }

  return (
    <button type="button" className={styles.root} onClick={handleClick}>
      <DocumentIcon icon={contentTypeDescription.icon} width={25} height={25} />

      <div className={styles.label}>
        <Text variant="caption2" color="text50Color">
          {capitalizeFirstLetter(contentTypeDescription.itemName)}
        </Text>
        <Text variant="h4" className={styles.name}>
          {accountName}
        </Text>
      </div>
    </button>
  )
})

export default DataConnectorPickerButton
