import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import clsx from "clsx"
import { useNavigate } from "react-router-dom"

import Templates from "@components/ui/Templates"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import ScrollableContainer from "@components/modals/CreateAvatarModal/components/ScrollableContainer/ScrollableContainer"
import List from "@components/ui/List/List"
import ListItem from "@components/ui/ListItem/ListItem"
import NotFound from "@components/ui/NotFound/NotFound"
import Loader from "@components/ui/Loader/BarLoader"
import { ExpertSubtopicDTO } from "@framework/types/knowledge"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import { useController } from "@store/index"
import IconButton from "@components/ui/IconButton/IconButton"

import SubtopicCard from "./TopicCard/SubtopicCard"
import useSubtopicsCollection from "./hooks/useSubtopicsCollection"
import useTopicsCollection from "./hooks/useTopicsCollection"

import styles from "./index.module.sass"

interface SubtopicsSectionProps {
  avatarId: string
  topicId: string
  onSelect?: (value: string) => void
}

export const SubtopicsSection: React.FC<SubtopicsSectionProps> = observer(
  ({ avatarId, topicId, onSelect }) => {
    const alert = useAlert()
    const navigate = useNavigate()

    const collection = useSubtopicsCollection(topicId)
    const topicCollection = useTopicsCollection(avatarId)

    const { expertInsightsController } = useController()

    const subtopicModal = useModal(ModalsTypes.EDIT_EXPERT_SUBTOPIC_MODAL)

    const handleAddSubtopic = () => {
      const topic = topicCollection.getById(topicId)

      if (topic == null) return

      subtopicModal.showModal({
        topic,
        onSubmit: async (data: any) => {
          const response = await expertInsightsController.createSubtopic(
            avatarId,
            topicId,
            data
          )

          if (response.status === "FAILED") {
            alert.error(response.message)
            return
          }

          if (response.data)
            navigate(`./${response.data.id}`, { state: { autoAction: "ADD" } })

          alert.success("New Subtopic was successfully created")

          subtopicModal.hideModal()
        },
      })
    }

    const handleEditSubtopic = (subtopic: ExpertSubtopicDTO) => {
      const topic = topicCollection.getById(topicId)

      if (topic == null) return

      subtopicModal.showModal({
        initialValue: subtopic,
        topic,
        onSubmit: async (data) => {
          const response = await expertInsightsController.updateSubtopic(
            avatarId,
            subtopic.expertTopicId,
            subtopic.id,
            data
          )

          if (response.status === "FAILED") {
            alert.error(response.message)
            return
          }

          expertInsightsController.loadSubtopicList(avatarId, topicId)

          alert.success("Subtopic was successfully updated")

          subtopicModal.hideModal()
        },
      })
    }

    const getTopicEditHandler =
      (subtopic: ExpertSubtopicDTO) => (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        handleEditSubtopic(subtopic)
      }

    return (
      <Templates.RollScript
        gutter="24"
        headerSocket={
          <Templates.Header
            className={styles.block}
            left={<Text variant="h2">Subtopics</Text>}
            right={
              <Button
                variant="outlined"
                size="small"
                before={<Icon name="plus" />}
                onClick={handleAddSubtopic}
              >
                Add Subtopic
              </Button>
            }
          />
        }
      >
        {collection.data.length === 0 ? (
          collection.isLoading ? (
            <Loader primary size="huge" fluid />
          ) : (
            <NotFound>
              {collection.error ? (
                <>Failed to load Subtopics</>
              ) : (
                <>
                  No Subtopics found! <br />
                  Add one to see here
                </>
              )}
            </NotFound>
          )
        ) : (
          <ScrollableContainer className={styles.mainList}>
            <List gutter="16" overflow="initial" align="stretch">
              {collection.data.map((subtopic, idx) => (
                <>
                  {idx !== 0 && <hr className={styles.divider} />}
                  <ListItem
                    className={clsx(styles.listItem, styles.showControlOnHover)}
                    color="secondary"
                    onClick={onSelect ? () => onSelect(subtopic.id) : undefined}
                    tabIndex={0}
                    key={subtopic.id}
                  >
                    <SubtopicCard
                      data={subtopic}
                      controlSlot={
                        <IconButton
                          className={styles.listItemControl}
                          onClick={getTopicEditHandler(subtopic)}
                        >
                          <Icon name="edit" />
                        </IconButton>
                      }
                    />
                  </ListItem>
                </>
              ))}
            </List>
          </ScrollableContainer>
        )}
      </Templates.RollScript>
    )
  }
)

export default SubtopicsSection
