/* eslint-disable camelcase */
import React from "react"
import { FormikProvider, useFormik } from "formik"
import * as yup from "yup"

import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import List from "@components/ui/List/List"
import FormTextInput from "@components/prototypes/form-elements/FormTextInput"

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .label("Connector Name")
    .required("Field is required")
    .min(3)
    .max(64)
    .trim()
    .default(""),

  bucketName: yup
    .string()
    .label("Bucket Name")
    .required("Field is required")
    .trim()
    .default(""),

  clientId: yup
    .string()
    .label("Client Id")
    .required("Field is required")
    .trim()
    .default(""),

  clientSecret: yup
    .string()
    .label("Client Secret")
    .required("Field is required")
    .trim()
    .default(""),

  region: yup
    .string()
    .label("Region")
    .required("Field is required")
    .trim()
    .default(""),
})

export type FormDataType = yup.InferType<typeof validationSchema>

export interface ConnectAmazonS3FormProps {
  initialValues?: Partial<FormDataType>
  isLoading?: boolean
  onSubmit: (form: FormDataType) => void
}

const ConnectAmazonS3Form: React.FC<ConnectAmazonS3FormProps> = ({
  isLoading,
  initialValues = {},
  onSubmit,
}) => {
  const formik = useFormik<FormDataType>({
    initialValues: validationSchema.cast(initialValues, { stripUnknown: true }),
    validationSchema,
    onSubmit,
  })

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <List gutter="24">
          <FormTextInput
            name="name"
            placeholder="Connector name"
            disabled={isLoading}
            key="name"
          />

          <FormTextInput
            name="bucketName"
            placeholder="Bucket name"
            disabled={isLoading}
            key="bucket_name"
          />

          <FormTextInput
            name="clientId"
            placeholder="Client Id"
            disabled={isLoading}
            key="client_id"
          />

          <FormTextInput
            name="clientSecret"
            placeholder="Client Secret"
            disabled={isLoading}
            key="client_secret"
          />

          <FormTextInput
            name="region"
            placeholder="Region"
            disabled={isLoading}
            key="region"
          />

          <Button
            disabled={isLoading}
            color="primary"
            type="submit"
            after={isLoading && <Loader />}
          >
            Connect
          </Button>
        </List>
      </form>
    </FormikProvider>
  )
}

export default ConnectAmazonS3Form
