import React from "react"
import { observer } from "mobx-react-lite"

import AppProvider from "./AppProvider"
import AppRouter from "./AppRouter"

import "./theme/color-palette.scss"
import "./theme/global.scss"

const App: React.FC = () => (
  <AppProvider>
    <AppRouter />
  </AppProvider>
)

export default observer(App)
