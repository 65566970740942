import React, { useMemo } from "react"
import { FieldProps } from "formik"
import moment from "moment"

import DatePicker, {
  DatePickerProps,
} from "@components/prototypes/FilterSidebar/components/DatePicker/DatePicker"
import { DD_MM_YYYY_FORMAT } from "@framework/constants/global"
import { sortDatePeriodTuple } from "@utils/date"

interface DateFormFieldProps
  extends Pick<DatePickerProps, "isOpened" | "label">,
    FieldProps {
  format?: string
}

const DateFormField: React.FC<DateFormFieldProps> = ({
  form,
  field,
  format = DD_MM_YYYY_FORMAT,
  ...rest
}) => {
  const { value, name } = field
  const { setFieldValue } = form

  const date = useMemo(
    () =>
      value?.start && value?.end
        ? [
            moment(value?.start, format).toDate(),
            moment(value?.end, format).toDate(),
          ]
        : value,
    [value]
  )

  const handleChange = (newValue?: Date | Date[]) => {
    if (!newValue || !Array.isArray(newValue) || newValue.length !== 2) {
      setFieldValue(name, null)
      return
    }
    const normalized = sortDatePeriodTuple(newValue)
    setFieldValue(name, {
      start: moment(normalized[0]).format(format),
      end: moment(normalized[1]).format(format),
    })
  }

  return <DatePicker {...rest} value={date} onChange={handleChange} />
}

export default DateFormField
