import React from "react"
import { observer } from "mobx-react-lite"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import Templates from "@components/ui/Templates"
import BackButton from "@components/prototypes/BackButton"

import OverallReport from "./OverallReport"
import APIUsageTrends from "./APIUsageTrend"
import APICallsHistory from "./APICallsHistory"

import styles from "./index.module.sass"

export const APIUsageAnalyticsPage: React.FC = observer(() => {
  return (
    <MainLayout>
      <div className={styles.root}>
        <Templates.Header
          className={styles.header}
          left={<BackButton>API Usage Analytics</BackButton>}
        />

        <div className={styles.body}>
          <OverallReport />

          <APIUsageTrends />

          <APICallsHistory />
        </div>
      </div>
    </MainLayout>
  )
})

export default APIUsageAnalyticsPage
