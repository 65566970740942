import React from "react"
import { observer } from "mobx-react-lite"
import * as yup from "yup"
import { useFormik } from "formik"

import { useController, useStore } from "@store"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import { useMultiStep } from "@components/ui/MultiStep/MultiStepContext"
import { NumberedMultiStepControl } from "@pages/launch-solutions/SolutionTabsContainer/components/Tabs/ProductComparison/NumberedMultiStep"
import SelectOption, { Unit } from "@components/ui/Dropdown/SelectOption"
import Label from "@components/ui/Label/Label"
import { SolutionData } from "@framework/types/solution"

import styles from "./index.module.sass"

const validationSchema = yup.object({
  companyId: yup.string().required().label("Company").default(undefined),
  businessUnit: yup.string().label("Business Unit").default(undefined),
})

type TargetCompanyForm = yup.InferType<typeof validationSchema>

interface Props {
  data: SolutionData
}

const TargetCompany: React.FC<Props> = observer(({ data }) => {
  const formStepper = useMultiStep()

  const {
    productComparisonStore: { targetCompaniesStore, companyThemesStore },
  } = useStore()

  const { productComparisonController } = useController()

  const formik = useFormik<TargetCompanyForm>({
    validationSchema,
    validateOnChange: false,
    initialValues: {
      companyId: targetCompaniesStore.selectedCompanyId ?? "",
      businessUnit: targetCompaniesStore.businessUnit ?? "",
    },
    onSubmit: (data) => {
      targetCompaniesStore.selectCompany(data.companyId)
      targetCompaniesStore.selectBusinessUnit(data.businessUnit ?? null)
      companyThemesStore.setThemes(null)
      formStepper.next()
    },
  })

  React.useEffect(() => {
    productComparisonController.fetchTargetCompanies()
  }, [])

  React.useEffect(() => {
    productComparisonController.fetchBusinessUnits(formik.values.companyId)
  }, [formik.values.companyId])

  return (
    <form className={styles.root} onSubmit={formik.handleSubmit}>
      <div className={styles.header}>
        <NumberedMultiStepControl />
      </div>

      <div className={styles.body}>
        <div className={styles.copy}>
          <Text variant="h2" weight="bold" align="center">
            {data.name}
          </Text>
          <Text variant="caption1" color="text70Color" align="center">
            {data.description}
          </Text>
        </div>

        <Label label="Select the target Company" uppercase id="companyId">
          <SelectOption
            options={targetCompaniesStore.companyOptions}
            isLoading={targetCompaniesStore.isLoading}
            label={(id) => targetCompaniesStore.findCompanyById(id)?.name}
            value={formik.values.companyId}
            onChange={(value) => {
              formik.setFieldValue("companyId", value)
              formik.setFieldValue("businessUnit", undefined)
            }}
            unit={companyUnit}
            error={formik.errors.companyId}
          />
        </Label>

        <Label label="Select business unit" uppercase id="business_unit">
          <SelectOption
            options={targetCompaniesStore.businessUnitList}
            isLoading={targetCompaniesStore.isLoading}
            disabled={!formik.values.companyId}
            value={formik.values.businessUnit}
            onChange={(value) => {
              formik.setFieldValue("businessUnit", value)
            }}
            unit={businessUnitUnit}
            error={formik.errors.businessUnit}
          />
        </Label>

        <Button color="primary" size="large" type="submit">
          Start
        </Button>
      </div>
    </form>
  )
})

const companyUnit: Unit = ["company", "companies"]

const businessUnitUnit: Unit = "business unit"

export default TargetCompany
