import clsx from "clsx"
import React from "react"

import styles from "./Table.module.sass"

export interface RowProps {
  onClick?: React.MouseEventHandler
  className?: string
}

export const Row: React.FC<RowProps> = ({ className, children, onClick }) => (
  <tr className={clsx(styles.row, className)} onClick={onClick}>
    {children}
  </tr>
)

export default Row
