import React from "react"

import {
  AnswerViewType,
  ExtendedResultType,
} from "@framework/constants/search-results"

interface SearchPassageContextStore {
  upVotePassage: (
    passageId: string,
    voted: boolean,
    answerType: AnswerViewType,
    avatarId: string
  ) => Promise<string | null>

  openExtendedView: (passageId: string, defaultTab?: ExtendedResultType) => void
}

export const SearchPassageContext =
  React.createContext<SearchPassageContextStore | null>(null)

export const useSearchFlowPassageContext = (): SearchPassageContextStore => {
  const context = React.useContext(SearchPassageContext)
  if (context == null) throw new Error("Can't find SearchPassageContext")
  return context
}
