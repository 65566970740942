import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate, useParams } from "react-router-dom"
import debounce from "lodash/debounce"

import { useController } from "@store/index"
import useAvatarDataConnectorFolders from "@store/avatar-knowledge/useAvatarDataConnectorFolders"
import DataConnectorFolderCard from "@pages/upload/components/DocumentUploadCard/DataConnectorFolderCard"
import TextInput from "@components/ui/TextInput/TextInput"
import useSearchTextInput from "@components/ui/TextInput/useSearchTextInput"
import { getDataConnectorsFolderContextByDataConnectorName } from "@framework/constants/upload"

import useActiveCategoryContext from "../ActiveDataCategoryContext/useActiveCategoryContext"
import ItemsGrid from "../components/ItemsGrid/ItemsGrid"
import BreadCrumpsIndicator from "./BreadCrumpsContext/BreadCrumpsIndicator"

import styles from "./index.module.sass"

const COLUMNS = 4

export const DataConnectorFolders: React.FC = observer(() => {
  const { groupName, dataConnectorId } = useParams<{
    dataConnectorId: string
    groupName: string
  }>()
  const navigate = useNavigate()

  if (dataConnectorId == null || groupName == null)
    throw new Error(
      "DataConnectorFolders Pages requires dataConnectorId and, groupName params"
    )

  const sourceTypeDescription =
    getDataConnectorsFolderContextByDataConnectorName(groupName)

  const category = useActiveCategoryContext()

  const { collection, resetCollection } = useAvatarDataConnectorFolders(
    category.id,
    dataConnectorId
  )

  const items = React.useMemo(() => {
    return collection.data.filter(
      (it) => it.totalFiles != null && it.totalFiles > 0
    )
  }, [collection.data])

  const { avatarKnowledgeController } = useController()

  const handleSearch = React.useCallback(debounce(resetCollection, 500), [])

  const search = useSearchTextInput({
    onChange: handleSearch,
  })

  const handleLoad = async () => {
    await avatarKnowledgeController.loadDataConnectorFoldersList(
      category.id,
      dataConnectorId
    )
  }

  return (
    <>
      <div className={styles.navigation}>
        <BreadCrumpsIndicator />
      </div>

      <div className={styles.filter}>
        <TextInput {...search.props} />
      </div>

      <ItemsGrid
        key={collection.id}
        total={items.length}
        totalLoaded={items.length}
        loading={collection.isLoading}
        unit={sourceTypeDescription.contentItemName ?? "folder"}
        rowHeight={161}
        isRowLoaded={({ index }) => items[index] != null}
        loadMoreRows={handleLoad}
        columnCount={COLUMNS}
        renderCard={(idx) => {
          const data = items[idx]
          if (!data) return null
          return (
            <DataConnectorFolderCard
              onClick={() => navigate(data.id)}
              item={data}
              key={data.id}
            />
          )
        }}
      />
    </>
  )
})

export default DataConnectorFolders
