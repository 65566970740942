import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import { countSuffix, initArrayByLength } from "@utils/numberUtils"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"

import styles from "./EntityList.module.sass"

export interface EntityListProps {
  className?: string
  renderCard: (idx: number) => React.ReactNode
  total: number
  unit?: string
}

export const EntityList: React.FC<EntityListProps> = observer(
  ({ total = 0, unit = "item", className, renderCard }) => {
    const list = initArrayByLength(total)

    return (
      <Templates.RollScript
        className={clsx(styles.root, className)}
        headerSocket={
          <Templates.Header
            offsetBottom="medium"
            left={
              <Text variant="h5">
                {total} {`${unit}${countSuffix(total)}`}
              </Text>
            }
          />
        }
      >
        <div className={styles.container}>{list.map(renderCard)}</div>
      </Templates.RollScript>
    )
  }
)

export default EntityList
