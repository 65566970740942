import { rangeToCode, totalRangeCells } from "@components/ui/Spreadsheet/utils"
import { IContextMenuBuilder } from "@components/ui/Spreadsheet/state/ContextMenuManager"

export const customContextMenuBuilder: IContextMenuBuilder = (context) => ({
  groups: [
    {
      name: "FORMAT",
      options: [
        {
          name: "DROPDOWN_FILE",
          value: "Filename",
          icon: "dropdown-list",
        },
        {
          name: "DROPDOWN_PRODUCT",
          value: "Products",
          icon: "dropdown-list",
        },
        // ...other options
      ],
    },
  ],
  handler: (it) => {
    switch (it.name) {
      case "DROPDOWN_FILE": {
        context.applyValidationRule(
          rangeToCode(context.selectedRange.range),
          "DROPDOWN_FILE"
        )
        if (totalRangeCells(context.selectedRange.range) === 1)
          context.editManager.editCell(context.selectedRange.range.start)
        break
      }

      case "DROPDOWN_PRODUCT": {
        context.applyValidationRule(
          rangeToCode(context.selectedRange.range),
          "DROPDOWN_PRODUCT"
        )
        if (totalRangeCells(context.selectedRange.range) === 1)
          context.editManager.editCell(context.selectedRange.range.start)
        break
      }

      // ...other option handler cases
      default:
        return false
    }

    return true
  },
})

export default null
