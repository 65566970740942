import React from "react"
import { observer } from "mobx-react-lite"

import Text from "@components/ui/Typography/Text"
import List from "@components/ui/List/List"
import { initArrayByLength } from "@utils/numberUtils"

import { FunctionDescription } from "../types"
import FormulaMarker from "../parser/FormulaMarker"

import styles from "./FunctionListItem.module.sass"

const FunctionListItem: React.FC<{
  item: FunctionDescription
}> = observer(({ item }) => {
  const renderArguments = () => {
    const requireArgsNum =
      item.requiredArgumentsNum ?? item.arguments?.length ?? 0

    const maxArgsNum = item.maxArgumentsNum ?? requireArgsNum

    const optionalArgsNum = maxArgsNum - requireArgsNum

    return (
      <>
        {initArrayByLength(requireArgsNum).map((index) => {
          const arg = item.arguments?.at(
            Math.min(item.arguments.length - 1, index)
          )
          return (
            <>
              {index !== 0 ? " , " : ""}
              <FormulaMarker type="argument" key={arg?.displayName}>
                {arg?.displayName ?? "Unknown"}
              </FormulaMarker>
            </>
          )
        })}

        {optionalArgsNum > 0 ? (
          <>
            {requireArgsNum > 0 ? " , " : null}
            <FormulaMarker type="argument" key="optional">
              [{optionalArgsNum > 1 ? "..." : ""}
              {item.arguments?.at(-1)?.displayName}
              {optionalArgsNum > 1 ? " N" : ""}]
            </FormulaMarker>
          </>
        ) : null}
      </>
    )
  }

  return (
    <List gutter="4">
      <Text variant="body2" className={styles.straightLine}>
        <FormulaMarker type="func-name">{item.name}</FormulaMarker>(
        {renderArguments()})
      </Text>
      <Text variant="caption2" color="text50Color">
        {item.description}
      </Text>
    </List>
  )
})

export default FunctionListItem
