import clsx from "clsx"
import React from "react"
import { observer } from "mobx-react-lite"

import Text from "../Typography/Text"

import styles from "./SimpleOptionCard.module.sass"

export interface SimpleOptionCardProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  iconSlot?: React.ReactNode
  label: React.ReactNode
  description?: React.ReactNode
  disabled?: boolean
  active?: boolean
}

export const SimpleOptionCard: React.FC<SimpleOptionCardProps> = observer(
  ({
    iconSlot,
    label,
    description,
    children,
    disabled = false,
    active = false,
    className,
    onClick,
    ...rest
  }) => {
    return (
      <button
        type="button"
        disabled={disabled}
        onClick={onClick}
        className={clsx(
          styles.root,
          {
            [styles.clickable]: onClick,
            [styles.active]: active,
          },
          className
        )}
        {...rest}
      >
        {iconSlot != null && <div className={styles.icon}>{iconSlot}</div>}

        <Text className={styles.title} variant="h4" align="center">
          {label}
        </Text>

        <Text variant="caption2" color="text70Color" align="center">
          {description}
        </Text>
      </button>
    )
  }
)

export default SimpleOptionCard
