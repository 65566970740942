import clsx from "clsx"
import React, { useState } from "react"

import { Option } from "@framework/types/utils"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import SelectItem from "@components/ui/SelectItem/SelectItem"
import Icon from "@components/ui/Icon/Icon"

import TriggerButton from "../TriggerButton/TriggerButton"

import styles from "./ContextMenu.module.sass"

export interface ContextMenuProps<T extends string> {
  disabled?: boolean
  shrinked?: boolean
  placeholder?: string
  prefix?: React.ReactNode
  value?: Option<T> | string
  items: Option<T>[]
  className?: string
  triggerClassName?: string
  justifyMenu?: "start" | "center" | "end"
  onChange?: (item: Option<T>) => void
}

export const ContextMenu = <T extends string>({
  disabled = false,
  placeholder = "",
  prefix,
  value,
  items,
  className,
  triggerClassName,
  shrinked,
  justifyMenu = "end",
  onChange,
}: ContextMenuProps<T>) => {
  const [open, setOpen] = useState(false)

  const valueOption =
    typeof value === "string" ? items.find(({ name }) => name === value) : value

  const handleChange = (item: Option<T>) => {
    setOpen(false)
    if (onChange && item.name !== value) onChange(item)
  }

  return (
    <Tooltip
      disabled={disabled}
      className={clsx(styles.root, className)}
      mode="onFocus"
      justify={justifyMenu}
      show={open}
      onClose={() => setOpen(false)}
      content={
        <TooltipContainer
          placement="bottom"
          tip={justifyMenu}
          className={styles.list}
          color="primary"
        >
          {items.map((item) => (
            <SelectItem
              item={item}
              selected={item.name === valueOption?.name}
              onSelect={() => handleChange(item)}
              key={item.name}
            />
          ))}
        </TooltipContainer>
      }
    >
      <TriggerButton
        shrinked={shrinked}
        disabled={disabled}
        before={prefix}
        label={valueOption?.value ?? placeholder}
        after={
          <Icon
            name="arrow-down"
            color="secondary"
            rotateAngle={open ? -180 : 0}
          />
        }
        active={open}
        onClick={() => setOpen((value) => !value)}
        className={clsx(styles.trigger, triggerClassName)}
      />
    </Tooltip>
  )
}

export default ContextMenu
