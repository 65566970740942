import React, { useCallback, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import Loader from "@components/ui/Loader/BarLoader"
import { useController, useStore } from "@store/index"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import { AvatarDetailsData } from "@framework/types/avatar"

import EditAvatarMembershipContainer from "./EditAvatarMembershipContainer"

import styles from "./index.module.sass"

export interface EditAvatarMembershipModalProps {
  avatarId: string
  type?: Tabs
}

export type Tabs = "users" | "solutions" | "datatypes"

const EditAvatarMembershipModal: React.FC<EditAvatarMembershipModalProps> =
  observer(({ avatarId, type = "solutions" }) => {
    const [tab, setTab] = useState<Tabs>(type)
    const [isEditing, setIsEditing] = useState<boolean>(false)

    const {
      avatarDetailsStore: { isLoading },
    } = useStore()

    const {
      avatarController: { loadAvatarDetails, editAvatar },
      avatarAdminController: { loadAvatars },
    } = useController()

    const { hideModal } = useModal(ModalsTypes.EDIT_AVATAR_MEMBERSHIP_MODAL)

    const alert = useAlert()

    const initAvatar = useCallback(async () => {
      const error = await loadAvatarDetails(avatarId)
      if (error) alert.error(error)
    }, [avatarId])

    useEffect(() => {
      if (avatarId) initAvatar()
    }, [avatarId])

    const handleClose = () => {
      loadAvatars()
      hideModal()
    }

    const handleSubmit = async (payload: Partial<AvatarDetailsData>) => {
      const error = await editAvatar(avatarId, payload)
      if (error) alert.error(error)
      await initAvatar()
    }

    return (
      <BaseModal
        title={
          <ModalTitle
            titleText={`${getTitlePrefix(isEditing)} ${getTitleItem(tab)}`}
          />
        }
        onClose={handleClose}
        className={styles.root}
        containerClassName={styles.container}
      >
        {isLoading ? (
          <Loader size="large" fluid />
        ) : (
          <EditAvatarMembershipContainer
            tab={tab}
            onTabChange={(newTab) => setTab(newTab)}
            onSubmit={handleSubmit}
            onEditChange={setIsEditing}
          />
        )}
      </BaseModal>
    )
  })

export default EditAvatarMembershipModal

const getTitleItem = (tab: Tabs) => {
  if (tab === "users") return "Users"
  if (tab === "datatypes") return "Content Types"
  if (tab === "solutions") return "Solutions"
  return "Items"
}

const getTitlePrefix = (isEditing: boolean) => {
  if (isEditing) return "Add"
  return "Assigned"
}
