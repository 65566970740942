import React from "react"

import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"

import MatrixStore from "./state/MatrixStore"
import { pointToCode } from "./utils"
import { IContextMenuBuilder } from "./state/ContextMenuManager"

const useDefaultContextMenu = (matrix: MatrixStore) => {
  const addCommentModal = useModal(ModalsTypes.ADD_COMMENT_MODAL)

  React.useEffect(() => {
    const customContextMenuBuilder: IContextMenuBuilder = (context) => ({
      groups: [
        {
          name: "COMMENT",
          options: [
            {
              name: "ADD_COMMENT",
              value: "Add Comment",
              icon: "chat-new",
            },
          ],
        },
      ],
      handler: (it) => {
        switch (it.name) {
          case "ADD_COMMENT": {
            const cell = context.editManager.getCellAtPoint(
              context.selectedRange.origin
            )

            addCommentModal.showModal({
              onSubmit: cell.setComment,
              title: `Add comment at ${pointToCode(
                context.selectedRange.origin
              )}`,
            })

            break
          }

          default:
            return false
        }
        return true
      },
    })

    matrix.addCellContextMenuBuilder(utilsCellContextMenuBuilder)
    matrix.addCellContextMenuBuilder(defaultCellContextMenuBuilder)
    matrix.addCellContextMenuBuilder(customContextMenuBuilder)
    return () => {
      matrix.removeCellContextMenuBuilder(utilsCellContextMenuBuilder)
      matrix.removeCellContextMenuBuilder(defaultCellContextMenuBuilder)
      matrix.removeCellContextMenuBuilder(customContextMenuBuilder)
    }
  }, [])
}

export default useDefaultContextMenu

const utilsCellContextMenuBuilder: IContextMenuBuilder = (context) => ({
  handler: (it) => {
    switch (it.name) {
      case "CLEAN_UP":
        context.editManager.reset()
        break

      default:
        return false
    }

    return true
  },
  groups: [
    {
      name: "UTILS",
      options: [
        {
          name: "CLEAN_UP",
          value: "Clean Up",
          icon: "trash-can",
        },
      ],
    },
  ],
})

const defaultCellContextMenuBuilder: IContextMenuBuilder = (context) => ({
  handler: (it) => {
    switch (it.name) {
      case "CUT":
        context.editManager.cut()
        break

      case "COPY":
        context.editManager.copy()
        break

      case "PASTE":
        context.editManager.paste()
        break

      default:
        return false
    }

    return true
  },
  groups: [
    {
      name: "DEFAULT",
      options: [
        { name: "CUT", value: "Cut", icon: "scissors" },
        { name: "COPY", value: "Copy", icon: "checkbox-multiple-blank" },
        { name: "PASTE", value: "Paste", icon: "clipboard" },
      ],
    },
  ],
})
