import { makeAutoObservable } from "mobx"

import MatrixStore from "./MatrixStore"
import { CellValidationRule } from "../types"

export class CellValidationManager {
  // injections
  private context: MatrixStore

  // state

  rules: Map<string, CellValidationRule>

  constructor(config: { context: MatrixStore }) {
    this.context = config.context

    this.rules = new Map()

    makeAutoObservable(this)
  }

  getRuleByName = (ruleId: string) => {
    const rule = this.rules.get(ruleId)
    if (rule == null) return null
    return rule
  }

  hasRule = (ruleId: string) => {
    return this.rules.has(ruleId)
  }

  registerValidationRule = (ruleId: string, rule: CellValidationRule) => {
    if (this.rules.has(ruleId))
      throw new Error(`Validation rule ${ruleId} already registered`)

    this.rules.set(ruleId, rule)
  }

  removeValidationRule = (name: string) => {
    this.rules.delete(name)
  }

  serialize = () => {
    return Object.fromEntries([...this.rules.entries()])
  }
}

export default CellValidationManager
