import React from "react"
import clsx from "clsx"

import styles from "./EntityItem.module.sass"

interface EntityItemProps extends React.ComponentProps<"button"> {
  active?: boolean
}

const EntityItem: React.FC<EntityItemProps> = ({
  active,
  children,
  className,
  ...rest
}) => {
  return (
    <button
      className={clsx(styles.root, { [styles.active]: active }, className)}
      type="button"
      {...rest}
    >
      {children}
    </button>
  )
}

export default EntityItem
