import React from "react"
import clsx from "clsx"

import DocumentIcon from "../Icon/DocumentIcon"
import Loader from "../Loader/BarLoader"

import sizeStyles from "./FileCard-size.module.sass"
import styles from "./index.module.sass"

interface FileCardProps {
  name?: string
  onRemove?: React.MouseEventHandler
  loading?: boolean
  size?: "big" | "medium" | "small"
  disabled?: boolean
}

const FileCard: React.FC<FileCardProps> = ({
  name,
  onRemove,
  loading = false,
  size = "medium",
  disabled,
}) => {
  return (
    <div
      className={clsx(styles.root, sizeStyles[size], {
        [styles.disabled]: disabled,
      })}
    >
      {loading && <Loader size="small" />}
      {name}
      <DocumentIcon
        icon="cross"
        color="secondary"
        onClick={onRemove}
        className={styles.remove}
      />
    </div>
  )
}

export default FileCard
