import React from "react"
import { observer, useLocalObservable } from "mobx-react-lite"

import { useController, useStore } from "@store"
import Text from "@components/ui/Typography/Text"
import { TextAreaInput } from "@components/ui/TextInput/TextInput"
import Label from "@components/ui/Label/Label"
import FileCard from "@components/ui/FileCard"
import Button from "@components/ui/Button/Button"
import { useMultiStep } from "@components/ui/MultiStep/MultiStepContext"
import { NumberedMultiStepControl } from "@pages/launch-solutions/SolutionTabsContainer/components/Tabs/ProductComparison/NumberedMultiStep"

import FileUploadButton from "../Themes/FileUploadButton"
import RequirementsStore from "./requirements.store"

import styles from "./index.module.sass"

type RequirementProps = {}

const Requirement: React.FC<RequirementProps> = observer(() => {
  const formStepper = useMultiStep()

  const {
    productComparisonStore: { targetCompaniesStore, requirementsStore },
  } = useStore()

  const { productComparisonController } = useController()

  const form = useLocalObservable(
    () => new RequirementsStore(requirementsStore)
  )

  const {
    productComparisonController: {
      setRequirements,
      resetSelectedProducts,
      fetchProductFilters,
    },
  } = useController()

  const handleSubmit = () => {
    setRequirements(form)

    resetSelectedProducts()

    fetchProductFilters()

    productComparisonController.requestBaseProducts()
    productComparisonController.requestTargetProducts()

    formStepper.next()
  }

  const handleBack = formStepper.back

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Button onClick={handleBack} variant="outlined" size="medium">
          Back
        </Button>

        <NumberedMultiStepControl />

        <Button onClick={handleSubmit} color="primary" size="medium">
          Next
        </Button>
      </div>

      <div className={styles.body}>
        <Text variant="h2" weight="bold" align="center">
          Product Requirement
        </Text>
        <div className={styles.inputContainer}>
          <Label
            id="requirement"
            label="Write the product requirement"
            uppercase
          >
            <TextAreaInput
              placeholder="Enter product requirement"
              className={styles.input}
              defaultValue={form.requirementText}
              after={
                form.attachments.length ? (
                  <div className={styles.fileCard}>
                    <FileCard
                      name={form.attachments[0].file.name}
                      size="small"
                      onRemove={() =>
                        form.removeAttachment(form.attachments[0])
                      }
                    />
                  </div>
                ) : (
                  <FileUploadButton
                    onUpload={form.addAttachment}
                    limit={form.attachmentsSizeLimitText}
                  />
                )
              }
              onInput={(e) => form.setRequirementText(e.currentTarget.value)}
            />
          </Label>
        </div>
      </div>
    </div>
  )
})

export default Requirement
