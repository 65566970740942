import React from "react"
import clsx from "clsx"
import { observer, useLocalObservable } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import throttle from "lodash/throttle"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import { useController } from "@store/index"
import { constructComplexUrlWithParams } from "@utils/textUtils"
import useQuery from "@components/hooks/useQuery"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import {
  SearchContext,
  SearchContextStore,
} from "@components/prototypes/SearchContext"

import SubtopicsSection from "./SubtopicsSection"
import TopicsSection from "./TopicsSection"
import Header from "./TopicsHeader"
import useTopicsCollection from "./hooks/useTopicsCollection"
import { TOPIC_ID_QUERY_PARAM_KEY } from "./constants"

import styles from "./index.module.sass"

const TopicsPage: React.FC = observer(() => {
  const navigate = useNavigate()

  const searchContext = useLocalObservable(() => new SearchContextStore())

  const topicId = useQuery().get(TOPIC_ID_QUERY_PARAM_KEY)

  const { avatarId } = useActiveAvatar()

  const { expertInsightsController } = useController()

  const topicsCollection = useTopicsCollection(avatarId)

  const totalTopics = topicsCollection.data.length

  const handleSelectTopic = (topicId?: string) =>
    navigate(
      constructComplexUrlWithParams(".", {
        [TOPIC_ID_QUERY_PARAM_KEY]: topicId,
      }),
      {
        replace: true,
      }
    )

  const handleSelectSubtopic = (subtopicId: string) => navigate(subtopicId)

  const loadTopics = React.useCallback(
    throttle((searchQuery: string) => {
      expertInsightsController.loadTopicList(avatarId, searchQuery, true)
    }, 1_000),
    [avatarId]
  )

  const loadSubtopics = React.useCallback(
    throttle((topicId: string) => {
      expertInsightsController.loadSubtopicList(avatarId, topicId)
    }, 1_000),
    [avatarId, topicId]
  )

  React.useEffect(() => {
    loadTopics(searchContext.query)
  }, [searchContext.query, loadSubtopics])

  React.useEffect(() => {
    if (topicId) loadSubtopics(topicId)
  }, [searchContext.query, topicId, loadSubtopics])

  React.useEffect(() => {
    if (totalTopics === 0) handleSelectTopic(undefined)
    else if (topicId == null) handleSelectTopic(topicsCollection.data[0].id)
  }, [topicsCollection.data])

  const selectedTopicWasFound = Boolean(
    topicId && topicsCollection.getById(topicId)
  )

  return (
    <SearchContext.Provider value={searchContext}>
      <MainLayout>
        <div className={styles.root}>
          <Header className={styles.header} />

          <div className={styles.body}>
            <div className={clsx(styles.left, styles.container)}>
              <TopicsSection
                avatarId={avatarId}
                selected={topicId}
                onSelect={handleSelectTopic}
              />
            </div>

            {totalTopics ? (
              <div className={clsx(styles.right, styles.box, styles.container)}>
                {topicId && selectedTopicWasFound ? (
                  <SubtopicsSection
                    avatarId={avatarId}
                    topicId={topicId}
                    onSelect={handleSelectSubtopic}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </MainLayout>
    </SearchContext.Provider>
  )
})

export default TopicsPage
