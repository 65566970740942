import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import moment from "moment"

import ChatBubbleFin from "./ChartBubbleFin"
import Text from "../Typography/Text"

import styles from "./ChatBubble.module.scss"

export type ChatBubbleProps = {
  from?: "me" | "another"
  rootClassName?: string
  unixTime?: number
  className?: string
}

export const ChatBubble: React.FC<ChatBubbleProps> = observer(
  ({ from = "me", unixTime, children, className }) => {
    const timeStr = unixTime != null ? moment(unixTime).format("LT") : null
    return (
      <div className={clsx(styles.root, styles[`from-${from}`], className)}>
        <div className={styles.bubble}>
          <Text variant="body2" className={styles.content}>
            {children}
          </Text>
          <Text variant="caption3" className={styles.footer} align="end">
            {timeStr}
          </Text>
        </div>
        <ChatBubbleFin className={styles.arrow} />
      </div>
    )
  }
)

export default ChatBubble
