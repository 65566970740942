import React from "react"

import { DataConnectorSourceName } from "@framework/types/upload"
import { useStore } from "@store/index"

const useAvatarDataConnectorFolders = (
  dataCategoryId: string,
  dataConnectorId: DataConnectorSourceName | string
) => {
  const { avatarDataConnectorFoldersStore } = useStore()

  const resetCollection = React.useCallback(
    () =>
      avatarDataConnectorFoldersStore.resetCollection(
        avatarDataConnectorFoldersStore.getCollectionKey(
          dataCategoryId,
          dataConnectorId
        )
      ),
    [dataCategoryId, dataConnectorId]
  )

  return {
    collection: avatarDataConnectorFoldersStore.getCollection(
      dataCategoryId,
      dataConnectorId
    ),
    resetCollection,
  }
}

export default useAvatarDataConnectorFolders
