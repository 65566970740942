import clsx from "clsx"
import React from "react"

import Icon from "../Icon/Icon"

import styles from "./ProgressBar.module.sass"

export interface ProgressBarProps {
  value: number // 0 - 100
  label?: string
  color?: "primary" | "default"
  variant?: "candy" | "default"
  isDone?: boolean
  progress?: React.ReactNode
  className?: string
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  label,
  color = "default",
  variant = "default",
  isDone = false,
  progress = 0,
  className,
}) => (
  <div
    className={clsx(
      styles.root,
      styles[`color-${color}`],
      { [styles[`variant-${variant}`]]: !isDone },
      className
    )}
  >
    <div className={styles.header}>
      {label && (
        <div className={styles.label} title={label}>
          {label}
        </div>
      )}
      {progress ||
        (isDone ? (
          <Icon name="done" className={styles.doneIcon} />
        ) : (
          value < 100 && <div className={styles.value}>{value}%</div>
        ))}
    </div>
    <div className={styles.gutter}>
      <div className={styles.bar} style={{ width: `${value}%` }} />
    </div>
  </div>
)

export default ProgressBar
