import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import Breadcrumbs from "@components/ui/Breadcrumbs/Breadcrumbs"

import { useBreadCrumpsContext } from "./useBreadCrumpsContext"

export const BreadCrumpsIndicator: React.FC<{ className?: string }> = observer(
  ({ className }) => {
    const navigate = useNavigate()
    const { store } = useBreadCrumpsContext()

    const breadCrumps = React.useMemo(
      () => store.checkpoints.map((it) => it.name),
      [store.checkpoints]
    )

    const handleClick = (path: string[]) => {
      const point = store.getCheckpoint(path.length - 1)
      navigate(point.link, { replace: true })
    }

    const handleRender = (name: string) => {
      const item = store.records.get(name)
      if (item == null) return name
      return item.render?.(item.name) ?? item.name
    }

    return (
      <Breadcrumbs
        className={className}
        items={breadCrumps}
        onClick={handleClick}
        renderItem={handleRender}
      />
    )
  }
)

export default BreadCrumpsIndicator
