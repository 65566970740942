import React from "react"
import { observer } from "mobx-react-lite"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/SimpleTable/Row"

const QueryHistoryTableHeader: React.FC = observer(() => {
  return (
    <Row>
      <Column as="th" key="question" width="auto">
        Query
      </Column>
      <Column as="th" key="user" width="300px">
        User
      </Column>
      <Column as="th" key="user" width="170px">
        Avatar
      </Column>
      <Column as="th" key="user" width="170px">
        Solution
      </Column>
      <Column as="th" key="dateTime" justify="center" width="100px">
        Date & Time
      </Column>
      <Column as="th" key="userFeedback" justify="center" width="220px">
        Rating
      </Column>
      <Column as="th" key="status" width="200px">
        Assign Status
      </Column>
    </Row>
  )
})

export default QueryHistoryTableHeader
