/* eslint-disable camelcase */
import React from "react"
import { To, useNavigate } from "react-router-dom"
import { useAlert } from "react-alert"
import { observer } from "mobx-react-lite"

import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import MainBoxLayout from "@components/layout/MainBoxLayout/MainBoxLayout"
import { useStore } from "@store/index"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"

import ConnectorEC2SMBForm, { FormDataType } from "./ConnectorEC2SMBForm"

import styles from "./AddDataConnector.module.sass"

const DEFAULT_TITLE = "Connect to EC2 SMB"
const DEFAULT_REQUIREMENTS = ""

export interface ConnectConfluencePageProps {
  title?: string
  requirements?: string
  backPath: To
}

export const ConnectEC2SMBPage: React.FC<ConnectConfluencePageProps> = observer(
  ({
    title = DEFAULT_TITLE,
    requirements = DEFAULT_REQUIREMENTS,
    backPath,
  }) => {
    const navigate = useNavigate()
    const alert = useAlert()

    const {
      uploadSourceStore: { isDataConnectorLoading, connectEC2SMB },
    } = useStore()

    const handleBack = () => navigate(backPath)

    const handleSubmit = async (form: FormDataType) => {
      const result = await connectEC2SMB(form)

      if (result.status === "FAILED") {
        alert.error(result.message)
        navigate(backPath, {
          replace: true,
        })
        return
      }

      if (result.status === "SUCCESS" && result.data.id) {
        navigate(`${backPath}/${result.data.id}`, { state: "FIRST_TIME" })
        return
      }
      handleBack()
    }

    const isLoading = isDataConnectorLoading

    return (
      <MainBoxLayout className={styles.root} onBack={handleBack}>
        <DocumentIcon
          className={styles.icon}
          icon="img:aws-ec2"
          alt="confluence"
        />

        <ModalTitle titleText={title} subtitleText={requirements} />

        <ConnectorEC2SMBForm onSubmit={handleSubmit} isLoading={isLoading} />
      </MainBoxLayout>
    )
  }
)

export default ConnectEC2SMBPage
