import React from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"
import clsx from "clsx"

import { useStore } from "@store/index"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import Text from "@components/ui/Typography/Text"
import UserChip from "@components/prototypes/UserChip"
import {
  CloudEntity,
  DataConnectorContentGroupName,
  DataConnectorSourceName,
} from "@framework/types/upload"

import { Column, Row } from "./Table"
import ContextMenu from "../components/DocumentUploadCard/ContextMenu"
import useCloudFolderEntityContextMenu from "../connectors/Sources/hooks/useCloudFolderEntityContextMenu"

import styles from "./CloudFolderRow.module.sass"

export const CloudFolderRow: React.FC<{
  data: CloudEntity
  // TODO move to context
  dataConnectorId: string
  connectorSourceName: DataConnectorSourceName
  sourceName: DataConnectorContentGroupName
}> = observer(({ data, connectorSourceName, sourceName, dataConnectorId }) => {
  const {
    allDatatypesStore,
    restrictionsStore: access,
    uploadStore,
    allUserStore,
  } = useStore()

  const store = uploadStore.getCloudEntitiesStore(
    connectorSourceName,
    dataConnectorId
  )

  const isReadonly = !access.canEditDataSource

  // const itemTypeDescription = getDataSourceNodeContextProps(sourceName)

  const dataType = allDatatypesStore.getById(data?.knowledgeDataTypeId)

  const contextMenuProps = useCloudFolderEntityContextMenu(
    connectorSourceName,
    dataConnectorId,
    dataType?.id,
    data,
    isReadonly,
    store.refresh
  )

  if (data == null) return <Skeleton rounded lineHeight={56} minWidth={100} />

  const isRunning = false
  // const isFailed = !!data.error

  const handleOpen = () => contextMenuProps.onContext(data.id, "open")

  // const handleAssignDataType =
  //   isReadonly || isFailed
  //     ? undefined
  //     : (e: React.MouseEvent) => {
  //         e.stopPropagation()
  //         contextMenuProps.onContext(data.id, "edit_data_type")
  //       }

  return (
    <Row
      className={clsx(styles.row)}
      loading={isRunning}
      onDoubleClick={handleOpen}
    >
      <Column>
        <DocumentIcon icon="img:folder_full" height={28} width={28} />
      </Column>

      <Column title={data.name}>
        <Text variant="inherit" className={styles.text}>
          {data.name}
        </Text>
      </Column>

      <Column>
        {/* uncomment once we have totalFiles available from backend  */}
        {/* {totalFiles}{" "}
        {capitalizeFirstLetter(itemTypeDescription.contentItemName ?? "file")}
        {countSuffix(totalFiles)} */}
      </Column>

      {/* uncomment once we have proper status supported by backend */}
      <Column>{/* <CloudEntityStatusBadge data={data} /> */}</Column>

      <Column>
        {/* hidden for now as it's not providing information of all nested file data
        types */}
        {/* <Chip
          color="secondary"
          variant="rounded"
          before={
            dataType != null && <Icon name={dataType.iconUrl ?? "global"} />
          }
          onClick={handleAssignDataType}
          title={dataType != null ? dataType.name : "Not Associated "}
        >
          {dataType != null ? dataType.name : "Not Associated "}
        </Chip> */}
      </Column>

      <Column>
        {/* hidden for now as it's not providing information of all nested file data
        types */}
        {/* <AvatarBubbles avatars={dataType?.channels} /> */}
      </Column>

      <Column>{moment(data.createdAt).format("MMM DD, YYYY")}</Column>

      <Column>
        <UserChip
          data={allUserStore.getById(data.createdBy)}
          isLoading={allUserStore.state.isLoading}
        />
      </Column>

      <Column>
        {!!contextMenuProps.options.length && (
          <ContextMenu id={data.id} {...contextMenuProps} />
        )}
      </Column>
    </Row>
  )
})

export default CloudFolderRow
