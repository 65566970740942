import React from "react"
import { observer } from "mobx-react-lite"
import { Navigate, Route, Routes } from "react-router-dom"

import Templates from "@components/ui/Templates"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"

import DataSourceHeader from "../KnowledgeSource/DataSourceHeader/DataCategoryHeader"
import DataSourcesRootPage from "./DataSourcesRoot"
import DataSourceFileGroups from "./DataSourceFileGroups"
import DataSourceWebsites from "./DataSourceWebsites"
import DataSourceFiles from "./DataSourceFiles"
import DataConnectorsGroups from "./DataConnectorsGroups"
import DataConnectors from "./DataConnectors"
import DataConnectorFolders from "./DataConnectorFolders"
import DataConnectorFiles from "./DataConnectorFiles"
import useActiveAvatar from "../ActiveAvatarContext/useActiveAvatar"
import useActiveCategoryContext from "../ActiveDataCategoryContext/useActiveCategoryContext"
import { BreadCrumpsCheckpointOutlet } from "./BreadCrumpsContext/BreadCrumpsCheckpoint"
import BreadCrumpsRoot from "./BreadCrumpsContext/BreadCrumpsRoot"
import { DataConnectorOutlet } from "./DataConnectorOutlet"
import CloudFolderOutlet from "./CloudFolderOutlet"
import DataConnectorGroupOutlet from "./DataConnectorGroupOutlet"

export interface KnowledgeSourceExplorerProps {
  className?: string
}

export const KnowledgeSourceExplorer: React.FC<KnowledgeSourceExplorerProps> =
  observer(({ className }) => {
    const { avatar } = useActiveAvatar()
    const category = useActiveCategoryContext()

    const baseLink = `/home/data-source/${avatar.id}/${category.id}`

    return (
      <Templates.RollScript
        className={className}
        headerSocket={<DataSourceHeader />}
      >
        <BreadCrumpsRoot link={baseLink}>
          <Routes>
            <Route index element={<DataSourcesRootPage />} />

            <Route
              path="website"
              element={
                <BreadCrumpsCheckpointOutlet
                  label={getDataSourceNodeContextProps("website").label}
                  redirectLink={`${baseLink}/website`}
                />
              }
            >
              <Route index element={<DataSourceWebsites />} />
            </Route>

            <Route
              path="file"
              element={
                <BreadCrumpsCheckpointOutlet
                  label="Files"
                  redirectLink={`${baseLink}/file`}
                />
              }
            >
              <Route index element={<DataSourceFileGroups />} />

              <Route
                path=":groupName"
                element={
                  <BreadCrumpsCheckpointOutlet
                    redirectLink={({ filesGroup }) =>
                      `${baseLink}/file/${filesGroup}`
                    }
                    label={({ groupName }) =>
                      getDataSourceNodeContextProps(groupName).label
                    }
                  />
                }
              >
                <Route
                  index
                  element={<DataSourceFiles defaultIcon="img:file" />}
                />
              </Route>
            </Route>

            <Route
              path="data-connector"
              element={
                <BreadCrumpsCheckpointOutlet
                  label={getDataSourceNodeContextProps("data-connector").label}
                  redirectLink={`${baseLink}/data-connector`}
                />
              }
            >
              <Route index element={<DataConnectorsGroups />} />

              <Route
                path=":groupName"
                element={
                  <DataConnectorGroupOutlet
                    label={({ groupName }) => groupName}
                    redirectLink={({ groupName }) =>
                      `${baseLink}/data-connector/${groupName}`
                    }
                  />
                }
              >
                <Route index element={<DataConnectors />} />

                <Route
                  path=":dataConnectorId"
                  element={
                    <DataConnectorOutlet
                      redirectLink={({ groupName, dataConnectorId }) =>
                        `${baseLink}/data-connector/${groupName}/${dataConnectorId}`
                      }
                      label={({ dataConnectorId }) => dataConnectorId}
                    />
                  }
                >
                  <Route index element={<DataConnectorFolders />} />

                  <Route
                    path=":folderId"
                    element={
                      <CloudFolderOutlet
                        redirectLink={({
                          groupName,
                          dataConnectorId,
                          folderId,
                        }) =>
                          `${baseLink}/data-connector/${groupName}/${dataConnectorId}/${folderId}`
                        }
                        label={({ folderId }) => folderId}
                      />
                    }
                  >
                    <Route index element={<DataConnectorFiles />} />
                  </Route>
                </Route>
              </Route>
            </Route>

            <Route path="**" element={<Navigate to="/home" replace />} />
          </Routes>
        </BreadCrumpsRoot>
      </Templates.RollScript>
    )
  })

export default KnowledgeSourceExplorer
