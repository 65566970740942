import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import StackGrid from "react-stack-grid"

import { useController, useStore } from "@store"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import NotFound from "@components/ui/NotFound/NotFound"
import LoadingInfo from "@pages/launch-solutions/SolutionTabsContainer/LoadingInfo"
import { useMultiStep } from "@components/ui/MultiStep/MultiStepContext"
import { NumberedMultiStepControl } from "@pages/launch-solutions/SolutionTabsContainer/components/Tabs/ProductComparison/NumberedMultiStep"
import Templates from "@components/ui/Templates"
import Switch from "@components/ui/Switch/Switch"
import { Option } from "@framework/types/utils"
import { ProductThemeType } from "@framework/types/company"
import { stringArrayToOptions } from "@utils/textUtils"
import Loader from "@components/ui/Loader/BarLoader"

import SolutionLoader from "../SolutionLoader"
import ThemeCategory from "./ThemeCategory"

import styles from "./index.module.sass"

type ThemesProps = {}

const Themes: React.FC<ThemesProps> = observer(() => {
  const formStepper = useMultiStep()

  const {
    productComparisonStore: {
      companyThemesStore: { isLoading, companyThemes, categorizedThemes },
      targetCompaniesStore: { selectedCompanyId, findCompanyById },
    },
  } = useStore()

  const {
    productComparisonController: { resetRequirements, fetchCompanyThemes },
  } = useController()

  const [themeType, setThemeType] = React.useState<ProductThemeType>(
    privacyOptions[0].name
  )

  useEffect(() => {
    fetchCompanyThemes(themeType)
  }, [themeType])

  if (companyThemes == null && isLoading) {
    return (
      <SolutionLoader title="Generating Themes">
        <LoadingInfo />
      </SolutionLoader>
    )
  }

  const handleSubmit = () => {
    resetRequirements()
    formStepper.next()
  }

  const handleBack = formStepper.back

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Button onClick={handleBack} variant="outlined" size="medium">
          Back
        </Button>

        <NumberedMultiStepControl />

        <Button onClick={handleSubmit} color="primary" size="medium">
          Next
        </Button>
      </div>

      <div className={styles.body}>
        <Templates.Header
          left={
            <Text variant="h2" weight="bold" align="center">
              {findCompanyById(selectedCompanyId)?.name ?? "Unknown"} Themes
            </Text>
          }
          right={
            <Switch
              disabled={isLoading}
              items={privacyOptions}
              checked={themeType}
              onChange={(name) => setThemeType(name)}
              size="medium"
            />
          }
        />

        <div className={styles.listContainer}>
          {companyThemes?.length === 0 ? (
            isLoading ? (
              <Loader primary size="large" fluid />
            ) : (
              <NotFound>Themes was not found</NotFound>
            )
          ) : (
            companyThemes && (
              <StackGrid columnWidth="33%" gutterWidth={24} gutterHeight={24}>
                {categorizedThemes.map((theme) => (
                  <ThemeCategory
                    categorizedTheme={theme}
                    key={theme.category}
                  />
                ))}
              </StackGrid>
            )
          )}
        </div>
      </div>
    </div>
  )
})

const privacyOptions: Option<ProductThemeType>[] = stringArrayToOptions([
  "public",
  "private",
])

export default Themes
