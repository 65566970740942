import React from "react"
import { observer } from "mobx-react-lite"

import ScrollableContainer from "@components/ui/ScrollableContainer/ScrollableContainer"
import { ExpertQuestion } from "@framework/types/question"
import Text from "@components/ui/Typography/Text"
import InsightCard from "@components/prototypes/InsightCard/InsightCard"
import UserCard from "@components/prototypes/InsightCard/UserCard"
import { useStore } from "@store/index"

import UserNotes from "../UserNotes/UserNotes"
import ContextMenu from "./ContextMenu"

import styles from "./QuestionCard.module.sass"

interface QuestionCardProps {
  data: ExpertQuestion
  alertMessage?: string
  onEdit?: () => void
  isEditing?: boolean
}

const QuestionCard: React.FC<QuestionCardProps> = observer(
  ({ isEditing, onEdit, data, alertMessage, children }) => {
    const { avatarsStore } = useStore()

    const thread = Object.values(data.thread)

    const handleAction = (actionName: string) => {
      if (actionName === "edit" && onEdit) onEdit()
    }

    const showUserAnswer = !isEditing

    const avatar = avatarsStore.getAvatarByName(data.channel)

    const isThread =
      thread.length > 0 ||
      (data.answer != null && showUserAnswer) ||
      data.summary

    const userBasicInfo = {
      name: data.author.name,
      avatarUrl: data.author.avatarUrl,
    }

    return (
      <div className={styles.root}>
        {alertMessage && <div className={styles.alert}>{alertMessage}</div>}

        <div className={styles.body}>
          <Text variant="h2" align="center">
            Q: {`“${data.question}“`}
          </Text>

          <div className={styles.userContainer}>
            {data.author && (
              <UserCard
                prefix="Delegated by"
                {...userBasicInfo}
                lastUpdated={data.lastUpdated}
                controlSocket={children}
              />
            )}
          </div>

          {!!data.userNote && <UserNotes text={data.userNote} />}

          {isThread && (
            <div className={styles.threadContainer}>
              <ScrollableContainer>
                {data.answer != null && showUserAnswer && (
                  <InsightCard
                    isInThread
                    lineClamp={2}
                    headerSocket={
                      <UserCard
                        prefix="Your answer"
                        highlighted
                        controlSocket={<ContextMenu onAction={handleAction} />}
                        lastUpdated={data.lastUpdated ?? "N/A"}
                        {...userBasicInfo}
                      />
                    }
                    answer={data.answer}
                    key={data.id}
                  />
                )}

                {thread.map((answer) => (
                  <InsightCard
                    isInThread
                    answer={answer.answer ?? ""}
                    lineClamp={2}
                    headerSocket={
                      <UserCard
                        lastUpdated={answer.lastUpdated ?? "N/A"}
                        {...userBasicInfo}
                      />
                    }
                    key={answer.answer}
                  />
                ))}
                {data.summary && avatar && (
                  <InsightCard
                    isInThread
                    lineClamp={2}
                    key={data.summary}
                    answer={data.summary}
                    headerSocket={
                      <UserCard
                        highlighted
                        avatarUrl={avatar?.imageUrl}
                        name={avatar?.name}
                        lastUpdated={data.lastUpdated}
                      />
                    }
                  />
                )}
              </ScrollableContainer>
            </div>
          )}
        </div>
      </div>
    )
  }
)

export default QuestionCard
