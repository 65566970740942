import React from "react"
import moment from "moment"
import { observer } from "mobx-react-lite"

import Text from "@components/ui/Typography/Text"
import UsersBubbles from "@components/ui/UsersBubbles/UsersBubbles"
import { ExpertArticleDTO } from "@framework/types/knowledge"

import styles from "./index.module.sass"

interface ArticleMetaProps {
  data: ExpertArticleDTO
}

export const ArticleMeta: React.FC<ArticleMetaProps> = observer(({ data }) => {
  const contributorList = data.contributors ?? []

  const bullets = [
    <>
      {contributorList?.length > 0 && <UsersBubbles users={contributorList} />}
      <Text variant="caption2" inline>
        {data.insightCount ?? 0} Contributions
      </Text>
    </>,
  ]

  if (data.lastUpdatedTime != null)
    bullets.push(
      <Text variant="caption2" inline key="lastUpdate">
        {moment(data.lastUpdatedTime).fromNow()}
      </Text>
    )

  return (
    <div className={styles.meta}>
      {bullets.map((it, idx) => (
        <>
          {idx !== 0 ? " • " : null} {it}
        </>
      ))}
    </div>
  )
})

export default ArticleMeta
