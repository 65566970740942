import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"
import { useAlert } from "react-alert"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import List from "@components/ui/List/List"
import TextInput from "@components/ui/TextInput/TextInput"
import Button from "@components/ui/Button/Button"
import { useController, useStore } from "@store"
import ListItem from "@components/ui/ListItem/ListItem"
import Icon from "@components/ui/Icon/Icon"
import { IconName } from "@components/ui/Icon/IconName"
import { ConnectorDocument } from "@framework/types/content-manager"
import { DataCategoryEntity } from "@framework/types/knowledge"
import Loader from "@components/ui/Loader/BarLoader"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"
import NotFound from "@components/ui/NotFound/NotFound"

import ModalFooterContainer from "../components/ControlFooter/ModalFooterContainer"

import styles from "./SelectContentTypeModal.module.sass"

export type SelectContentTypeModalProps = {
  documents: ConnectorDocument[]
  onAssign: () => void
}
const SelectContentTypeModal: React.FC<SelectContentTypeModalProps> = observer(
  ({ documents, onAssign }) => {
    const [selectedContentType, setContentType] = useState<DataCategoryEntity>()
    const { hideModal } = useModal(ModalsTypes.SELECT_CONTENT_TYPE_MODAL)
    const { allDatatypesStore, contentManagerStore } = useStore()
    const { contentManagerController } = useController()
    const [searchTerm, setSearchTerm] = useState("")
    const alert = useAlert()

    const assignContentType = async () => {
      if (!selectedContentType) return
      const error =
        await contentManagerController.updateContentTypesForDocuments(
          selectedContentType?.id,
          documents
        )
      if (error) {
        alert.error(error)
      } else {
        hideModal()
        onAssign()
      }
    }

    const filteredItems = allDatatypesStore?.state?.data?.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    )

    return (
      <BaseModal
        className={styles.root}
        containerClassName={styles.container}
        title={<ModalTitle titleText="Assign Content Type" />}
      >
        <Templates.RollScript
          gutter="24"
          footerSocket={
            <ModalFooterContainer>
              <Button
                disabled={contentManagerStore.loadingUpdateContentTypes}
                variant="outlined"
                onClick={hideModal}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                color="primary"
                after={
                  contentManagerStore.loadingUpdateContentTypes && <Loader />
                }
                disabled={
                  contentManagerStore.loadingUpdateContentTypes ||
                  !selectedContentType
                }
                onClick={assignContentType}
              >
                Assign
              </Button>
            </ModalFooterContainer>
          }
        >
          <div className={styles.search}>
            <Text variant="body2">Select the content type</Text>
            <TextInput
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <List className={styles.list}>
            {allDatatypesStore.state.isLoading && <Loader size="large" />}
            {!allDatatypesStore.state.isLoading && !filteredItems?.length && (
              <NotFound />
            )}
            {filteredItems?.map((item) => (
              <ListItem
                key={item.id}
                className={clsx(styles.listItem, {
                  [styles.active]: selectedContentType === item,
                })}
                onClick={() => setContentType(item)}
              >
                <Icon name={(item.iconUrl as IconName) || "global"} />
                <div>
                  <Text variant="body1">{item.name}</Text>
                </div>
              </ListItem>
            ))}
          </List>
        </Templates.RollScript>
      </BaseModal>
    )
  }
)

export default SelectContentTypeModal
