import React from "react"
import { observer } from "mobx-react-lite"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import {
  AutoSizer,
  InfiniteLoader,
  List,
  ListRowProps,
} from "react-virtualized"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import {
  DataConnectorContentName,
  DataConnectorSourceName,
} from "@framework/types/upload"
import { useController, useStore } from "@store/index"
import { DocumentIconType } from "@framework/types/utils"
import BackButton from "@components/prototypes/BackButton"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import Templates from "@components/ui/Templates"
import NotFound from "@components/ui/NotFound/NotFound"
import Loader from "@components/ui/Loader/BarLoader"

import FilesTableHeader from "./FilesTableHeader"
import CloudFileRow from "./CloudFileRow"
import CloudFilesCollectionControlBar from "./CloudFilesCollectionControlBar"

import styles from "./CloudFilesPage.module.sass"

const BATCH_SIZE = 24

type Params = {
  folderId: string
  dataConnectorId: string
}

export interface ManualUploadedFilesCollectionsProps {
  sourceName: DataConnectorContentName
  connectorSourceName: DataConnectorSourceName
  defaultFileIcon?: DocumentIconType
}

const CloudFilesPage: React.FC<ManualUploadedFilesCollectionsProps> = observer(
  ({ sourceName, connectorSourceName, defaultFileIcon = "img:file" }) => {
    const navigate = useNavigate()
    const location = useLocation()

    const backPath = location.state?.from ?? -1

    const { folderId, dataConnectorId } = useParams<Params>()

    if (!folderId || !dataConnectorId) {
      navigate(backPath)
      throw new Error("folderId and/or dataConnectorId was not provided")
    }

    const { dataConnectorController } = useController()
    const { uploadStore } = useStore()

    const store = uploadStore.getCloudFilesStore(
      connectorSourceName,
      dataConnectorId,
      folderId
    )

    const connectorDescription =
      getDataSourceNodeContextProps(connectorSourceName)

    const folderDescription = getDataSourceNodeContextProps(
      connectorDescription.contentItemName ?? "folders"
    )

    const contentDescription = getDataSourceNodeContextProps(sourceName)

    const renderRow = ({ index, key, style }: ListRowProps) => {
      return (
        <div style={{ ...style, padding: 2 }} key={key}>
          <CloudFileRow
            index={index}
            connectorSourceName={connectorSourceName}
            dataConnectorId={dataConnectorId}
            folderId={folderId}
            defaultFileIcon={defaultFileIcon}
          />
        </div>
      )
    }

    const collection = store.state

    React.useEffect(() => {
      dataConnectorController.loadFolderPreview(folderId)
    }, [folderId])

    const folderPreview =
      dataConnectorController.folderPreviews.getById(folderId)

    return (
      <MainLayout>
        <div className={styles.root}>
          <section className={styles.header}>
            <Templates.Header
              left={
                <BackButton onClick={() => navigate(backPath)}>
                  {folderDescription.label}
                  {folderPreview != null ? ` / ${folderPreview.name}` : ""}
                </BackButton>
              }
            />
          </section>

          <section className={styles.control}>
            <CloudFilesCollectionControlBar
              connectorSourceName={connectorSourceName}
              dataConnectorId={dataConnectorId}
              folderId={folderId}
            />
          </section>

          <section className={styles.body}>
            <FilesTableHeader sourceName={sourceName} />

            <div className={styles.tableBody}>
              {collection.total ? (
                <InfiniteLoader
                  isRowLoaded={(it) => store.getByIndex(it.index) != null}
                  loadMoreRows={store.load}
                  rowCount={collection.total}
                  minimumBatchSize={BATCH_SIZE}
                  threshold={BATCH_SIZE}
                >
                  {({ onRowsRendered, registerChild }) => (
                    <AutoSizer>
                      {({ width, height }) => (
                        <List
                          rowCount={collection.total}
                          ref={registerChild}
                          onRowsRendered={onRowsRendered}
                          rowHeight={60}
                          rowRenderer={renderRow}
                          height={height}
                          width={width}
                        />
                      )}
                    </AutoSizer>
                  )}
                </InfiniteLoader>
              ) : collection.isLoading ? (
                <Loader size="huge" fluid />
              ) : (
                <NotFound>
                  {store.state.errorMessage ||
                    `No ${contentDescription.label}s found`}
                </NotFound>
              )}
            </div>
          </section>
        </div>
      </MainLayout>
    )
  }
)

export default CloudFilesPage
