import { observer } from "mobx-react-lite"
import React, { PropsWithChildren } from "react"

import styles from "./index.module.sass"

type SolutionLoaderProps = {
  title: string
}

const SolutionLoader: React.FC<PropsWithChildren<SolutionLoaderProps>> =
  observer(({ title, children }) => {
    return (
      <div className={styles.loadingMessages}>
        <div className={styles.loading}>
          <img
            src="/images/nesh_icon.svg"
            alt="Logo icon"
            className={styles.neshIcon}
          />
          <span className={styles.loader}>{title}</span>
        </div>
        {children}
      </div>
    )
  })

export default SolutionLoader
