import React from "react"
import { observer } from "mobx-react-lite"

import Skeleton from "@components/ui/Skeleton/Skeleton"
import { useStore } from "@store/index"
import List from "@components/ui/List/List"
import NotFound from "@components/ui/NotFound/NotFound"
import ExpertStrip from "@components/ui/UserCard/ExpertStrip"

export const TopExpertList: React.FC = observer(() => {
  const {
    usageAnalyticsStore: { activeUsersStore },
  } = useStore()

  const store = activeUsersStore.state

  return (
    <List gutter="16">
      {store.isLoading ? (
        <Skeleton
          count={5}
          lineHeight={60}
          minWidth={100}
          spacing={16}
          rounded
        />
      ) : store.top.length ? (
        store.top.map((userData) => (
          <ExpertStrip
            data={userData}
            rating={userData.totalQueries}
            key={userData.id}
          />
        ))
      ) : (
        <NotFound>No Users Found</NotFound>
      )}
    </List>
  )
})

export default TopExpertList
