import React, { useState } from "react"
import { useFormik } from "formik"
import { observer } from "mobx-react-lite"
import * as yup from "yup"

import { TextAreaInput } from "@components/ui/TextInput/TextInput"
import { FeedbackDTO } from "@framework/types/feedback"
import Loader from "@components/ui/Loader/BarLoader"
import Rating from "@components/ui/Rating/Rating"
import Button from "@components/ui/Button/Button"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"

import styles from "./FeedbackModal.module.sass"

export const validationSchema = yup.object({
  rating: yup.number().required("Need to provide rating").default(0),
  comment: yup.string().default("").trim(),
})

type FormData = yup.InferType<typeof validationSchema>

export interface FeedbackModalProps {
  onSubmit?: (payload: FeedbackDTO) => Promise<void>
  onClose?: () => Promise<void>
  withComment?: boolean
  title?: string
}

/**
 * @deprecated
 */
export const FeedbackModal: React.FC<FeedbackModalProps> = observer(
  ({ withComment, onClose, onSubmit, title = "How was your experience" }) => {
    const { hideModal } = useModal(ModalsTypes.APP_FEEDBACK_MODAL)
    const { showModal: showSuccessMessage } = useModal(
      ModalsTypes.SUCCESS_MESSAGE_MODAL
    )

    const [isLoading, setLoading] = useState(false)

    const handleSubmit = async (data: FormData) => {
      if (onSubmit) {
        setLoading(true)
        await onSubmit(data)
        setLoading(false)
      } else {
        showSuccessMessage({ message: "Thank you for your answer" })
      }
      hideModal()
    }

    const handleRateLater = async () => {
      setLoading(true)
      await onClose?.()
      setLoading(false)
      hideModal()
    }

    const formik = useFormik({
      initialValues: validationSchema.getDefault(),
      validationSchema,
      onSubmit: handleSubmit,
    })

    return (
      <BaseModal
        title={<ModalTitle titleText={`${title}?`} />}
        onClose={hideModal}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className={styles.root}>
            <Rating
              value={formik.values.rating}
              onChange={(value) => formik.setFieldValue("rating", value)}
            />
            {withComment && (
              <TextAreaInput
                placeholder="Enter your note here (optional)"
                name="comment"
                value={formik.values.comment}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            )}
          </div>
          <div className={styles.controls}>
            <Button
              type="submit"
              color="primary"
              disabled={!formik.values.rating || isLoading}
              after={isLoading && <Loader />}
            >
              Submit
            </Button>
            {onClose && (
              <Button
                variant="slim"
                disabled={isLoading}
                onClick={handleRateLater}
              >
                Rate Later
              </Button>
            )}
          </div>
        </form>
      </BaseModal>
    )
  }
)

export default FeedbackModal
