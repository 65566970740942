import React from "react"

import { Point } from "@framework/types/common"
import useEvent from "@components/hooks/useEvent"
import useClickOutside from "@components/hooks/useOutsideClick"
import useEventListener from "@components/hooks/useEventListener"

import ContextMenuBox from "./ContextMenuBox"
import { Menu } from "./types"
import { calcContextMenuPosition } from "./utils"
import { OptionList } from "./OptionList"

const defaultStyle: React.CSSProperties = {
  position: "fixed",
}

export type ContextMenuContextState = {
  handleOpen: (point: Point, menu: Menu) => void
  handleClose: () => void
  handleContextMenu: (e: React.MouseEvent, menu: Menu) => void
}

export const ContextMenuContext =
  React.createContext<ContextMenuContextState | null>(null)

const ContextMenuContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null)

  const [menu, setMenu] = React.useState<Menu | null>()
  const [position, setPosition] = React.useState<Point>({ x: 0, y: 0 })
  const [style, setStyle] = React.useState<React.CSSProperties>(defaultStyle)

  const open = menu != null

  const handleOpen = useEvent((position: Point, menu: Menu) => {
    setMenu(menu)
    setPosition(position)
  })

  const handleClose = useEvent(() => {
    setMenu(null)
  })

  const handleContextMenu = useEvent((e: React.MouseEvent, menu: Menu) => {
    handleOpen({ x: e.pageX, y: e.pageY }, menu)
    e.preventDefault()
  })

  useEventListener({
    type: "scroll",
    disabled: !open,
    options: true,
    listener: handleClose,
  })

  useClickOutside({
    element: containerRef,
    disabled: !open,
    options: true,
    callBack: handleClose,
  })

  React.useLayoutEffect(() => {
    const node = containerRef.current

    if (node == null || !open) {
      setStyle(defaultStyle)
      return
    }

    setStyle({ ...defaultStyle, ...calcContextMenuPosition(position, node) })
  }, [open, position])

  const context = React.useMemo(
    () => ({ handleContextMenu, handleOpen, handleClose }),
    [handleContextMenu, handleOpen, handleClose]
  )

  return (
    <ContextMenuContext.Provider value={context}>
      {children}

      <ContextMenuBox ref={containerRef} style={style} open={open}>
        {menu != null ? (
          <OptionList
            groups={menu?.groups}
            handler={menu?.handler}
            onClose={handleClose}
          />
        ) : null}
      </ContextMenuBox>
    </ContextMenuContext.Provider>
  )
}

export default ContextMenuContextProvider
