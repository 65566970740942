import clsx from "clsx"
import React, { useEffect } from "react"
import { Outlet, useLocation, useParams } from "react-router-dom"
import { observer } from "mobx-react-lite"

import { useController } from "@store"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import Templates from "@components/ui/Templates"
import BackButton from "@components/prototypes/BackButton"
import { SolutionTypes } from "@framework/constants/search-results"

import ProductDetailsContainer from "./components/ProductDetailsContainer"

import styles from "./Product.module.sass"

const ProductPage: React.FC = observer(() => {
  const location = useLocation()
  const { id } = useParams()

  const {
    productComparisonController: { fetchProduct },
  } = useController()

  useEffect(() => {
    if (id) fetchProduct(id)
  }, [])

  // TODO create separate ProductPage for other solutions
  const isComparison =
    location.state?.solution === SolutionTypes.ProductComparison

  return (
    <MainLayout>
      <div className={clsx(styles.root)}>
        <Templates.Header
          className={styles.header}
          left={
            <BackButton variant="text" size="medium">
              Product
            </BackButton>
          }
        />
        <ProductDetailsContainer selectableProduct={isComparison} />
        <Outlet />
      </div>
    </MainLayout>
  )
})

export default ProductPage
