/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import clsx from "clsx"

import styles from "./Header.module.sass"

export interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  left?: React.ReactNode
  leftClassName?: string
  right?: React.ReactNode
  rightClassName?: string
  offsetBottom?: "none" | "small" | "medium" | "middle" | "big"
  offsetTop?: "none" | "small" | "medium" | "middle" | "big"
}

export const Header: React.FC<HeaderProps> = ({
  left,
  leftClassName,
  right,
  rightClassName,
  offsetBottom = "none",
  offsetTop = "none",
  className,
  ...rest
}) => {
  return (
    <header
      className={clsx(
        styles.root,
        styles[`offset-bottom-${offsetBottom}`],
        styles[`offset-top-${offsetTop}`],
        className
      )}
      {...rest}
    >
      <div className={clsx(styles.left, leftClassName)}>{left}</div>
      <div className={clsx(styles.right, rightClassName)}>{right}</div>
    </header>
  )
}

export default Header
