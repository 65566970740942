import React from "react"
import { observer } from "mobx-react-lite"

import { Point } from "@framework/types/common"

import { useMatrixContext } from "./MatrixContext"
import TextEditor from "./TextEditorCell/TextEditor"

type EditorCellProps = {
  cell: Point
}

const EditorCell: React.FC<EditorCellProps> = observer(({ cell }) => {
  const { grid, editManager } = useMatrixContext()

  const position = grid.getCellCoordinates(cell)

  return (
    <div
      style={{
        position: "absolute",
        left: position.x,
        top: position.y,
        zIndex: 99,
      }}
    >
      {editManager.activeCellState != null && (
        <TextEditor control={editManager.activeCellState} />
      )}
    </div>
  )
})

export default EditorCell
