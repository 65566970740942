import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"

import Text from "@components/ui/Typography/Text"
import { randomNumber } from "@utils/numberUtils"

import styles from "./AnswerSummary.module.sass"

const NEXT_MESSAGE_DELAY = 10_000

const LOADING_DATA = [
  {
    title: "What are Avatars?",
    description:
      "Avatars are your GenAI-powered Subject Matter Experts. Our Avatars extend your team with specialized expertise and solutions relevant to your business processes.",
  },
  {
    title: "What are GenAI-powered Solutions?",
    description:
      "GenAI-powered Solutions augment business processes common to your team - so you can focus on strategic decisions that matter.",
  },
]

const LoadingInfo: React.FC = observer(() => {
  const [index, setIndex] = useState(randomNumber(0, LOADING_DATA.length))

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prev) => (prev + 1) % LOADING_DATA.length)
    }, NEXT_MESSAGE_DELAY)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className={styles.info}>
      <Text variant="h3" className="notAvailable">
        {LOADING_DATA[index].title}
      </Text>
      <div>{LOADING_DATA[index].description}</div>
    </div>
  )
})

export default LoadingInfo
