import React from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/BarLoader"
import { useAppConfig } from "@components/theme/AppConfigProvider"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

export const ExternalSignForm: React.FC<{ primary?: boolean }> = observer(
  ({ primary }) => {
    const theme = useAppConfig()
    const isTablet = useMediaQuery(AppMediaQueries.maxTablet)

    const { authStore } = useStore()

    const { isSignInSSOLoading, isLoading } = authStore

    const handleLogin = () => {
      window.location.replace(theme.authorization.signUp.external.redirectUrl)
    }

    const btnLabel = theme.authorization.signUp.external.buttonText

    return (
      <Button
        size={isTablet ? "big" : "large"}
        variant={primary ? "contained" : "outlined"}
        color={primary ? "primary" : "default"}
        disabled={isLoading}
        onClick={handleLogin}
        after={isSignInSSOLoading && <Loader />}
      >
        {btnLabel}
      </Button>
    )
  }
)

export default ExternalSignForm
