import React from "react"
import { observer, useLocalObservable } from "mobx-react-lite"
import { useAlert } from "react-alert"
import throttle from "lodash/throttle"
import {
  AutoSizer,
  InfiniteLoader,
  ListRowProps,
  List as VirtualList,
} from "react-virtualized"

import { useController, useStore } from "@store/index"
import Templates from "@components/ui/Templates"
import List from "@components/ui/List/List"
import DataTypeLink from "@components/prototypes/DataType/DataTypeLink"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import { DataSourceNodeName } from "@framework/types/upload"
import CheckboxCard from "@components/ui/CheckboxCard/CheckboxCard"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import Text from "@components/ui/Typography/Text"
import Button from "@components/ui/Button/Button"
import { countSuffix } from "@utils/numberUtils"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import TextInput from "@components/ui/TextInput/TextInput"
import useSearch from "@components/hooks/useSearch"
import Icon from "@components/ui/Icon/Icon"
import {
  InfiniteSelected,
  InfiniteSelectedContext,
  useInfiniteSelectedContext,
} from "@utils/InfiniteSelected"

import { useSelectDataTypeContext } from "../PreselectDataTypeContext"

import styles from "./ConnectCloudFoldersSidebar.module.sass"

const BATCH_SIZE = 24

export interface ConnectCloudFoldersFormProps {
  title: string
  dataConnectorId: string
  description?: string
  sourceName: DataSourceNodeName
  onClose?: () => void
  onSubmit?: () => void
}

export const ConnectCloudFoldersForm: React.FC<ConnectCloudFoldersFormProps> =
  observer(
    ({
      title,
      description,
      sourceName,
      dataConnectorId,
      onClose,
      onSubmit = onClose,
    }) => {
      const alert = useAlert()
      const { uploadStore } = useStore()
      const { dataConnectorController } = useController()

      const { dataTypeId } = useSelectDataTypeContext()

      const sourceTypeDescription = getDataSourceNodeContextProps(sourceName)

      const store = uploadStore.externalFoldersStores.getStore(dataConnectorId)

      const collection = store.state

      const selectStore = useLocalObservable(
        () => new InfiniteSelected({ total: collection.total })
      )

      const [isLoading, setLoading] = React.useState(false)

      const handleSearch = React.useCallback(throttle(store.search, 500), [
        store.search,
      ])

      const [searchProps, searchHelpers] = useSearch({
        value: store.state.query,
        onChange: handleSearch,
      })

      const handleSubmit = async () => {
        try {
          setLoading(true)

          const foldersToShare = [...selectStore.data].map((id) => {
            const res = store.getById(id)
            if (res == null) {
              selectStore.selectAll(false)
              throw new Error("Store desynchronized")
            }
            return res
          })

          const error = await dataConnectorController.shareFolders(
            dataConnectorId,
            foldersToShare,
            dataTypeId
          )

          if (error != null) {
            alert.error(
              <>
                <Text variant="h3">
                  Failed to connect {sourceTypeDescription.itemName}
                </Text>
                <b />
                <Text variant="caption1" color="text70Color">
                  {error}
                </Text>
              </>
            )
            return
          }

          onSubmit?.()
        } finally {
          setLoading(false)
        }
      }

      const renderRow = ({ index, key, style }: ListRowProps) => {
        return (
          <div style={{ ...style, padding: 2 }} key={key}>
            <CheckboxCardItem index={index} dataConnectorId={dataConnectorId} />
          </div>
        )
      }

      const totalSelected = selectStore.selectedCount
      const isValid = totalSelected > 0

      React.useEffect(() => {
        selectStore.selectAll(false)
        selectStore.setTotal(collection.total)
      }, [collection.total])

      return (
        <InfiniteSelectedContext.Provider value={selectStore}>
          <Templates.RollScript
            gutter="32"
            headerSocket={
              <List gutter="24">
                <ModalTitle titleText={title} subtitleText={description} />
              </List>
            }
            footerSocket={
              <List align="stretch">
                <Button
                  onClick={handleSubmit}
                  disabled={!isValid || collection.isLoading || isLoading}
                  color="primary"
                  after={isLoading && <Loader />}
                >
                  Connect{" "}
                  {totalSelected
                    ? `${totalSelected} ${
                        sourceTypeDescription.itemName
                      }${countSuffix(totalSelected)}`
                    : null}
                </Button>
              </List>
            }
          >
            <Templates.RollScript headerSocket={<DataTypeLink />}>
              <Templates.RollScript
                gutter="16"
                headerSocket={
                  <TextInput
                    {...searchProps}
                    before={<Icon name="search" />}
                    after={
                      !!searchProps.value && (
                        <Icon
                          name="cross"
                          onClick={() => searchHelpers.setQuery("")}
                        />
                      )
                    }
                    placeholder="Search"
                  />
                }
              >
                {collection.totalFound === 0 ? (
                  collection.isLoading ? (
                    <Loader size="large" fluid />
                  ) : (
                    <NotFound>
                      No {sourceTypeDescription.itemName}s found to connect
                    </NotFound>
                  )
                ) : (
                  <div className={styles.container}>
                    <InfiniteLoader
                      isRowLoaded={(it) => store.getByIndex(it.index) != null}
                      loadMoreRows={store.load}
                      rowCount={collection.totalFound}
                      minimumBatchSize={BATCH_SIZE}
                      threshold={BATCH_SIZE}
                    >
                      {({ onRowsRendered, registerChild }) => (
                        <AutoSizer>
                          {({ width, height }) => (
                            <VirtualList
                              rowCount={collection.totalFound}
                              ref={registerChild}
                              onRowsRendered={onRowsRendered}
                              rowHeight={74}
                              rowRenderer={renderRow}
                              height={height}
                              width={width}
                            />
                          )}
                        </AutoSizer>
                      )}
                    </InfiniteLoader>
                  </div>
                )}
              </Templates.RollScript>
            </Templates.RollScript>
          </Templates.RollScript>
        </InfiniteSelectedContext.Provider>
      )
    }
  )

export default ConnectCloudFoldersForm

const CheckboxCardItem: React.FC<{
  index: number
  dataConnectorId: string
}> = observer(({ index, dataConnectorId }) => {
  const { uploadStore } = useStore()

  const selectedContext = useInfiniteSelectedContext()

  const store = uploadStore.externalFoldersStores.getStore(dataConnectorId)

  const item = store.getByIndex(index)

  if (item == null) return <Skeleton lineHeight={70} minWidth={100} rounded />
  return (
    <CheckboxCard
      onCheck={() => selectedContext.toggle(item.id)}
      checked={selectedContext.isSelected(item.id)}
      className={styles.checkbox}
      label={item.name}
      icon="folder"
      key={item.name}
    />
  )
})
