import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Text from "@components/ui/Typography/Text"
import { ExpertArticleDTO } from "@framework/types/knowledge"

import ArticleMeta from "./ArticleMeta"

import styles from "./index.module.sass"

export interface TopicCardProps {
  data: ExpertArticleDTO
  className?: string
}

export const ArticleCard: React.FC<TopicCardProps> = observer(
  ({ data, className }) => {
    return (
      <div className={clsx(styles.root, className)}>
        <div className={styles.withControl}>
          <Text variant="h5" className={styles.title}>
            {data.title}
          </Text>

          {/* <Chip color="red" variant="rounded">
            NEW
          </Chip> */}
        </div>

        <ArticleMeta data={data} />
      </div>
    )
  }
)

export default ArticleCard
