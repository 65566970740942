import { makeAutoObservable } from "mobx"

import { PaginationParams } from "@framework/types/utils"
import defaultErrorHandler from "@store/utils/error-handlers"
import userGroupService, { UserGroupData } from "@services/user-group.service"

import UserGroupsStore from "./user-groups.store"

export class UserGroupsController {
  usersStore: UserGroupsStore

  constructor(injections: { userGroupsStore: UserGroupsStore }) {
    this.usersStore = injections.userGroupsStore
    makeAutoObservable(this)
  }

  loadUserGroupsPage = async (options: PaginationParams) => {
    try {
      this.usersStore.setLoading()
      this.usersStore.setError()

      const response = await userGroupService.getUserGroups(options)

      this.usersStore.update(response.data.data, response.data.meta)
    } catch (error: any) {
      this.usersStore.setError(
        defaultErrorHandler(error, "loading user groups")
      )
    } finally {
      this.usersStore.setLoading(false)
    }
  }

  reload = () => {
    if (this.usersStore.isLoading) return

    const { meta } = this.usersStore

    this.loadUserGroupsPage({
      pageNum: meta.pageNum,
      pageSize: meta.pageSize,
      query: meta.query,
    })
  }

  createUserGroup = async (form: UserGroupData) => {
    try {
      this.usersStore.setLoading()
      this.usersStore.setError()

      await userGroupService.createUserGroup(form)
    } catch (error: any) {
      this.usersStore.setError(defaultErrorHandler(error, "create user group"))
    } finally {
      this.usersStore.setLoading(false)
    }
    return this.usersStore.error
  }

  updateUserGroup = async (groupID: string, form: UserGroupData) => {
    try {
      this.usersStore.setLoading()
      this.usersStore.setError()

      await userGroupService.updateUserGroup(groupID, form)
    } catch (error: any) {
      this.usersStore.setError(defaultErrorHandler(error, "update user group"))
    } finally {
      this.usersStore.setLoading(false)
    }
    return this.usersStore.error
  }

  removeUserGroup = async (groupID: string) => {
    try {
      this.usersStore.setLoading()
      this.usersStore.setError()

      await userGroupService.deleteUserGroup(groupID)
    } catch (error) {
      this.usersStore.setError(
        defaultErrorHandler(error, "deleting user group")
      )
    } finally {
      this.usersStore.setLoading(false)
    }
    return this.usersStore.error
  }

  assignUserToGroup = async (groupID: string, userIDs: string[]) => {
    try {
      this.usersStore.setLoading()
      this.usersStore.setError()

      await userGroupService.assignUsersToGroup(groupID, userIDs)
    } catch (error: any) {
      this.usersStore.setError(
        defaultErrorHandler(error, "assign users to group")
      )
    } finally {
      this.usersStore.setLoading(false)
    }
    return this.usersStore.error
  }

  unassignUserToGroup = async (groupID: string, userIDs: string[]) => {
    try {
      this.usersStore.setLoading()
      this.usersStore.setError()

      await userGroupService.unassignUsersFromGroup(groupID, userIDs)
    } catch (error: any) {
      this.usersStore.setError(
        defaultErrorHandler(error, "assign users to group")
      )
    } finally {
      this.usersStore.setLoading(false)
    }
    return this.usersStore.error
  }
}

export default UserGroupsController
