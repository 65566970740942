import clsx from "clsx"
import React from "react"

import Text from "@components/ui/Typography/Text"
import Label from "@components/ui/Label/Label"

import styles from "./UserNotes.module.sass"

interface UserNotesProps extends React.ComponentProps<"div"> {
  text: string
  className?: string
}

const UserNotes: React.FC<UserNotesProps> = ({ text, className, ...rest }) => (
  <div className={clsx(styles.root, className)} {...rest}>
    <Label label="User notes" id="user_note">
      <Text
        color="text70Color"
        variant="body3"
        dangerouslySetInnerHTML={{ __html: text }}
        className={styles.textBox}
      />
    </Label>
  </div>
)

export default UserNotes
