import React from "react"

const ChatBubbleFin: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 18 16"
      className={className}
      fill="currentColor"
    >
      <path d="M9.3,1.4c-1.5,5.4-3,9.3-8.1,14.9c0,0,11,2.9,17.7-8V1.4H9.3z" />
    </svg>
  )
}

export default ChatBubbleFin
