import { makeAutoObservable } from "mobx"
import without from "lodash/without"

export class UserGroupMembershipStore {
  selectedUsers: string[]

  isEditing: boolean

  constructor() {
    makeAutoObservable(this)

    this.isEditing = false

    this.selectedUsers = []
  }

  handleEdit = (status: boolean) => {
    this.isEditing = status
  }

  updateMembershipData = (id: string) => {
    this.selectedUsers = this.selectedUsers.includes(id)
      ? without(this.selectedUsers, id)
      : [...this.selectedUsers, id]
  }

  resetSelected = () => {
    this.selectedUsers = []
  }
}

export default UserGroupMembershipStore
