import React from "react"
import { observer } from "mobx-react-lite"

import DocumentDropZone from "@components/ui/DocumentDropZone/DocumentDropZone"
import { useController, useStore } from "@store/index"
import Templates from "@components/ui/Templates"
import List from "@components/ui/List/List"
import Button from "@components/ui/Button/Button"
import DataTypeLink from "@components/prototypes/DataType/DataTypeLink"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"

import UploadingFilesList from "./UploadingFilesList"
import { useSelectDataTypeContext } from "../PreselectDataTypeContext"

export interface ManualFilesUploadModalProps {
  title: string
  description?: string
  validExtensions?: string[]
  onClose?: () => void
  onSubmit?: () => void
}

export const ManualFilesUploadForm: React.FC<ManualFilesUploadModalProps> =
  observer(
    ({ title, description, validExtensions, onClose, onSubmit = onClose }) => {
      const { fileUploadingStore } = useStore()
      const { fileUploadingController } = useController()

      const { dataTypeId } = useSelectDataTypeContext()

      const handleClose = () => onSubmit?.()

      const handleUpload = (files: File[]) =>
        fileUploadingController.uploadFiles(files, dataTypeId)

      const totalUploaded = fileUploadingStore.tasks.length

      return (
        <Templates.RollScript
          gutter="32"
          headerSocket={
            <List gutter="24">
              <ModalTitle titleText={title} />

              <List gutter="0">
                <DataTypeLink />

                <DocumentDropZone
                  onDrop={handleUpload}
                  description={description}
                  extensions={validExtensions?.map((it) => `.${it}`)}
                />
              </List>
            </List>
          }
          footerSocket={
            !fileUploadingStore.isAnyInProgress && totalUploaded > 0 ? (
              <ModalFooterContainer>
                <Button color="primary" size="big" onClick={handleClose}>
                  Done
                </Button>
              </ModalFooterContainer>
            ) : null
          }
        >
          <UploadingFilesList />
        </Templates.RollScript>
      )
    }
  )

export default ManualFilesUploadForm
