import clsx from "clsx"
import React from "react"
import ReactDOM from "react-dom"

import { AppMediaQueries } from "@framework/constants/app"
import useMediaQuery from "@components/hooks/useMediaQuery"

import styles from "./Popper.module.scss"

export interface PopperProps extends React.HTMLAttributes<HTMLDivElement> {
  isActive?: boolean
}

export const Popper = React.forwardRef<HTMLDivElement, PopperProps>(
  ({ children, isActive = false, className, ...rest }, ref) => {
    const isMobile = useMediaQuery(AppMediaQueries.maxMobile)

    if (!isActive) return null
    return ReactDOM.createPortal(
      <div
        {...rest}
        className={clsx(styles.root, className)}
        ref={ref}
        style={{
          ...rest.style,
          ...(isMobile
            ? {
                inset: undefined,
                top: undefined,
                bottom: 0,
                left: 0,
                right: 0,
                width: "100vw",
              }
            : null),
        }}
      >
        {children}
      </div>,
      document.body
    )
  }
)

export default Popper
