import React from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { jsPDF as JsPdf } from "jspdf"
import html2canvas from "html2canvas"

import Logo from "@components/prototypes/Logo/Logo"
import Templates from "@components/ui/Templates"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import { useController, useStore } from "@store"
import NotFound from "@components/ui/NotFound/NotFound"
import Loader from "@components/ui/Loader/BarLoader"

import Report from "./Report"

import styles from "./WorkbookReport.module.sass"

export interface SignUpPageProps {}

const WorkbookReportPage: React.FC<SignUpPageProps> = observer(() => {
  const { reportId } = useParams()

  const reportRef = React.useRef<HTMLDivElement>(null)
  const hiddenDivRef = React.useRef<HTMLDivElement>(null)

  const [isDownloading, setIsDownloading] = React.useState(false)

  const {
    unifiedMatrixStore: { reportStore },
  } = useStore()

  const { unifiedMatrixController } = useController()

  React.useEffect(() => {
    if (reportStore.id !== reportId)
      unifiedMatrixController.resetWorkbookReport()

    if (reportId) unifiedMatrixController.getWorkbookReport(reportId)
  }, [reportId])

  const handleDownload = async () => {
    if (!reportRef.current || !hiddenDivRef.current) return

    // Clone the reportRef content into the hidden div
    hiddenDivRef.current.innerHTML = reportRef.current.outerHTML

    // Temporarily expand the hidden div to include the full content
    const originalHeight = hiddenDivRef.current.style.height
    hiddenDivRef.current.style.height = `${hiddenDivRef.current.scrollHeight}px`

    try {
      // Use html2canvas to capture the content
      setIsDownloading(true)
      const canvas = await html2canvas(hiddenDivRef.current, {
        scale: 2, // Higher resolution for better quality
        useCORS: true, // Handle cross-origin images
      })

      // Convert the canvas to an image
      const imgData = canvas.toDataURL("image/png")

      // Create a new jsPDF instance in landscape mode
      const pdf = new JsPdf("l", "pt")

      // Get PDF dimensions
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()

      // Calculate the height ratio to fit content properly
      const contentHeight = canvas.height * (pdfWidth / canvas.width)

      // Start the Y position
      let yPosition = 0

      // Loop to add pages if content height exceeds one page
      while (yPosition < contentHeight) {
        // Calculate the remaining content height
        const remainingHeight = contentHeight - yPosition

        // If the content doesn't fit in one page, add a new page
        if (remainingHeight > pdfHeight) {
          pdf.addImage(
            imgData,
            "PNG",
            0,
            -yPosition,
            pdfWidth,
            canvas.height * (pdfWidth / canvas.width)
          )
          yPosition += pdfHeight // Move the position down by one page height
          pdf.addPage() // Add a new page
        } else {
          // Add the remaining content on the last page
          pdf.addImage(
            imgData,
            "PNG",
            0,
            -yPosition,
            pdfWidth,
            canvas.height * (pdfWidth / canvas.width)
          )
          break
        }
      }

      // Save the PDF
      pdf.save(`${reportStore.report?.title || "Workbook Report"}.pdf`)
    } finally {
      // Restore the original height of the hidden div to avoid DOM clutter
      hiddenDivRef.current.style.height = originalHeight
      hiddenDivRef.current.innerHTML = ""
      setIsDownloading(false)
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.box}>
        <Templates.Header
          left={<Logo height={40} withText />}
          right={
            <Button
              before={<Icon name="download" />}
              size="big"
              color="primary"
              onClick={handleDownload}
              disabled={isDownloading || reportStore.report == null}
              after={isDownloading && <Loader size="small" />}
            >
              {isDownloading ? "Downloading..." : "Download"}
            </Button>
          }
          className={styles.header}
        />

        <div ref={hiddenDivRef} className={styles.hiddenDiv} />

        <div className={styles.body} ref={reportRef}>
          {reportStore.report != null ? (
            <Report data={reportStore.report} />
          ) : reportStore.isLoading ? (
            <Loader size="large" fluid />
          ) : (
            <NotFound fluid />
          )}
        </div>
      </div>
    </div>
  )
})

export default WorkbookReportPage
