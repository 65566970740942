import React, { useMemo } from "react"
import { observer, useLocalStore } from "mobx-react-lite"
import union from "lodash/union"

import {
  AccessType,
  AvatarDetailsData,
  AvatarEditDTO,
} from "@framework/types/avatar"
import { useStore } from "@store/index"
import List from "@components/ui/List/List"
import Switch from "@components/ui/Switch/Switch"
import { Option } from "@framework/types/utils"

import AvatarMembershipStore from "./avatar-membership.store"
import { Datatypes, Users, Solutions } from "./components/Tabs"
import AvatarMembershipContext from "./components/MembershipContext/AvatarMembershipContext"

import { Tabs } from "./index"

const { Private } = AccessType

interface EditAvatarMembershipContainerProps {
  tab: Tabs
  onEditChange?: (isEdit: boolean) => void
  onTabChange: (tab: Tabs) => void
  onSubmit: (payload: Partial<AvatarDetailsData>) => Promise<void>
}

const getCountString = (count?: number) => {
  if (!count) return ""
  return `(${count})`
}

const tabComponents = {
  users: { Component: Users },
  solutions: { Component: Solutions },
  datatypes: { Component: Datatypes },
}

const EditAvatarMembershipContainer: React.FC<EditAvatarMembershipContainerProps> =
  observer(({ tab, onTabChange, onSubmit, onEditChange }) => {
    const {
      avatarDetailsStore: { data: avatar },
    } = useStore()

    const membershipContext = useLocalStore(
      () => new AvatarMembershipStore({ avatar: avatar ?? {} })
    )

    const { isEditing, membershipData } = membershipContext

    const tabs: Option<Tabs>[] = useMemo(() => {
      if (!avatar) return []

      const { accessType, userIds, productSolutions, knowledgeDataTypes } =
        avatar

      return [
        ...(accessType === Private
          ? [
              {
                name: "users",
                value: `Users ${getCountString(userIds?.length)}`,
              } as Option<Tabs>,
            ]
          : []),
        {
          name: "solutions",
          value: `Solutions ${getCountString(productSolutions?.length)}`,
        },
        {
          name: "datatypes",
          value: `Content Types ${getCountString(knowledgeDataTypes?.length)}`,
        },
      ]
    }, [avatar])

    React.useEffect(() => {
      onEditChange?.(isEditing)
    }, [isEditing])

    const TabComponent = tabComponents[tab].Component

    const handleAdd = async () => {
      const { usersToAdd, ...rest } = membershipData
      await onSubmit({
        ...rest,
        userIds: union(avatar?.userIds ?? [], usersToAdd),
      })
    }

    const handleRemove = async (payload: Partial<AvatarEditDTO>) => {
      await onSubmit(payload)
    }

    return (
      <AvatarMembershipContext.Provider value={membershipContext}>
        <List gutter="32">
          {!isEditing && (
            <Switch
              items={tabs}
              onChange={(name: Tabs) => onTabChange(name)}
              checked={tab}
              key="tabs"
            />
          )}
          <TabComponent onAdd={handleAdd} onRemove={handleRemove} />
        </List>
      </AvatarMembershipContext.Provider>
    )
  })

export default EditAvatarMembershipContainer
