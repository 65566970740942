import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"

import ModalProvider from "@components/modals/ModalProvider"
import PrivateOutlet from "@components/security/PrivateOutlet"
import AdminPanelRoot from "@pages/admin/AdminPanelRoot"
import ChangeEmailPage from "@pages/auth/ChangeEmail/ChangeEmail"
import ConfirmEmailPage from "@pages/auth/ConfirmEmail/ConfirmEmail"
import PasswordRestorePage from "@pages/auth/PasswordRestore/PasswordRestore"
import ServiceTermsPage from "@pages/auth/ServiceTerms/ServiceTerms"
import SignInPage from "@pages/auth/SignIn"
import SignOutPlaceholder from "@pages/auth/SignOut"
import SignUpPageSwitcher from "@pages/auth/SignUpSwitcher"
import ExpertsPage from "@pages/expert/ExpertsPage/ExpertsPage"
import AvatarRequiredScope from "@pages/home/AvatarRequiredScope"
import HomeRoot from "@pages/home/HomeRoot"
import ProfilePage from "@pages/profile/Profile"
import QuestionsRoot from "@pages/questions"
import ServiceStatusPage from "@pages/service-status/ServiceStatus"
import UpdatesPage from "@pages/updates/Updates"
import UploadRoot from "@pages/upload"
import { useController, useStore } from "@store/index"
import FileUploadPrompt from "@components/prototypes/FileUploadPrompt"
import ExpertInsightsRoot from "@pages/expert-insights"
import AnalyticsRoot from "@pages/analytics/AnalyticsRoot"
import SSOAuthorize from "@pages/auth/SSOAuthorize"
import { useAppConfig } from "@components/theme/AppConfigProvider"
import GuestLogin from "@pages/auth/GuestLogin"
import PrivacyPolicyPage from "@pages/auth/PrivacyPolicy/PrivacyPolicy"
import ExpertsMenuPage from "@pages/expert/Menu/ExpertsMenuPage"
import ContentManagerPage from "@pages/content-manager/ContentManager"
import WorkbookReportPage from "@pages/launch-solutions/SolutionTabsContainer/components/Tabs/UnifiedMatrix/WorkbookReport"

import ProductSolutionsRoot from "./pages/solutions"
import ieeeUsabillaScript from "./IEEE-usabilla-script"

const MainRouter: React.FC = observer(() => {
  const theme = useAppConfig()

  const { avatarController } = useController()
  const {
    restrictionsStore: access,
    authStore: { isAuthorized },
    updateStore: { isChangeLogLoading, loadChangeLog },
    avatarsStore: { isAvatarsLoading },
    userStore,
  } = useStore()

  useEffect(() => {
    if (!isAvatarsLoading && isAuthorized) avatarController.loadAvatars()
  }, [isAuthorized])

  // TODO review
  useEffect(() => {
    if (!isAuthorized) {
      return
    }

    if (
      userStore.userActions == null &&
      userStore.loadActionsErrorMessage == null &&
      !userStore.isUserActionsLoading
    )
      userStore.loadAllowedUserActions()

    if (!isChangeLogLoading) loadChangeLog()
  }, [isAuthorized])

  useEffect(() => {
    if (access.isIEEEUsabillaScriptAllowed) ieeeUsabillaScript()
  }, [])

  return (
    <Routes>
      {/* /// Public */}
      <Route path="/" element={<Navigate to="/home" replace />} />
      <Route path="guest" element={<GuestLogin />} />
      <Route path="login" element={<SignInPage />} />
      <Route path="logout" element={<SignOutPlaceholder />} />

      {!theme.authorization.signUp.classic.disabled && (
        <Route path="sign-up" element={<SignUpPageSwitcher />} />
      )}

      <Route path="email-change" element={<ChangeEmailPage />} />
      <Route path="change-password" element={<PasswordRestorePage />} />
      <Route path="confirm/:prevStep" element={<ConfirmEmailPage />} />

      <Route path="sso/authorize" element={<SSOAuthorize />} />

      {access.isAppAgreementsStampAvailable && (
        <Route path="terms" element={<ServiceTermsPage />} />
      )}
      {access.isAppAgreementsStampAvailable && (
        <Route path="privacy" element={<PrivacyPolicyPage />} />
      )}

      {/* /// Private */}
      <Route element={<PrivateOutlet />}>
        <Route path="home/*" element={<HomeRoot />} />

        <Route
          path="workbook-report/:reportId"
          element={<WorkbookReportPage />}
        />

        <Route
          path="solutions/*"
          element={
            <AvatarRequiredScope>
              <ProductSolutionsRoot />
            </AvatarRequiredScope>
          }
        />

        <Route path="upload/*" element={<UploadRoot />} />

        <Route path="content-manager" element={<ContentManagerPage />} />

        <Route path="updates" element={<UpdatesPage />} />

        <Route path="profile" element={<ProfilePage />} />

        {access.isAdminPanelAvailable && (
          <Route path="admin/*" element={<AdminPanelRoot />} />
        )}

        <Route path="analytics/*" element={<AnalyticsRoot />} />

        {access.isQuestionsSectionAvailable && (
          <Route path="questions/*" element={<QuestionsRoot />} />
        )}

        {access.isExpertInsightsAvailable && (
          <Route
            path="expert-insights/*"
            element={
              <AvatarRequiredScope>
                <ExpertInsightsRoot />
              </AvatarRequiredScope>
            }
          />
        )}

        {access.isNeshStatusAvailable && (
          <Route path="statusofnesh" element={<ServiceStatusPage />} />
        )}

        <Route
          path="expert"
          element={
            <AvatarRequiredScope>
              <ExpertsPage />
            </AvatarRequiredScope>
          }
        />
        <Route
          path="expert-menu"
          element={
            <AvatarRequiredScope>
              <ExpertsMenuPage />
            </AvatarRequiredScope>
          }
        />

        <Route path="guest" element={<Navigate to="/" replace />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
})

const AppRouter: React.FC = () => (
  <BrowserRouter>
    <FileUploadPrompt>
      <MainRouter />
      <ModalProvider />
    </FileUploadPrompt>
  </BrowserRouter>
)

export default AppRouter
