import React, { useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"

import { useStore } from "@store/index"
import { NotificationBadge } from "@components/ui/Loader/BadgeWrapper/BadgeWrapper"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import useNavigationScope from "@components/layout/NavigationScopeContext/useNavigationScope"
import { Option } from "@framework/types/utils"
import Logo from "@components/prototypes/Logo/Logo"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import { useAppConfig } from "@components/theme/AppConfigProvider"
import Text from "@components/ui/Typography/Text"

import ExpandableNavItem from "../Sidebar/NavItem/ExandableNavItem"
import UserSettingsMenu from "../Sidebar/UserSettingsMenu"

import styles from "./MobileSideMenu.module.sass"

export interface MobileSideMenuProps {
  isOpened?: boolean
  close?: () => void
}

export const MobileSideMenu: React.FC<MobileSideMenuProps> = ({
  isOpened = true,
  close,
}) => {
  const {
    allowedNavOptions: menuOptions,
    allowedProfileOptions: profileOptions,
  } = useNavigationScope()
  const { showModal } = useModal(ModalsTypes.LOGOUT_MODAL)
  const [selectedOption, setSelectedOption] = useState<Option | null>()
  const theme = useAppConfig()

  const handleLogoutClick = () => showModal({})

  return (
    <div className={clsx(styles.root, { [styles.opened]: isOpened })}>
      <div className={styles.container}>
        <div className={styles.logoOpen}>
          <Link to="/home">
            <Logo name={theme.copy.logo} height={24} withText />
          </Link>
          <Button size="tiny" variant="text" onClick={close}>
            <Icon name="cross" rotateAngle={-90} />
          </Button>
        </div>
        <div className={styles.menu}>
          <div className={styles.expandableMenu}>
            {menuOptions.map((item) => (
              <ExpandableNavItem
                active={item === selectedOption}
                key={item.name}
                option={item}
                parentOption={item}
                setSelectedOption={setSelectedOption}
                setSideBarOpen={() => {}}
              />
            ))}
          </div>
        </div>
        <div className={styles.footer}>
          <a
            href={theme.support.supportPageURL}
            target="_blank"
            className={styles.support}
            rel="noreferrer"
          >
            <Icon className={styles.helpButton} name="question-mark" />
            <Text className={styles.name} variant="body2" color="inherit">
              Help Me
            </Text>
          </a>

          <UserSettingsMenu showMore menuOptions={profileOptions} />
        </div>
      </div>
    </div>
  )
}

type NavItemBadgeProps = {
  name: string
}

export const NavItemBadge: React.FC<NavItemBadgeProps> = observer(
  ({ name }) => {
    const {
      updateStore: { hasChanges },
    } = useStore()
    if (name === "/updates" && hasChanges) return <NotificationBadge />
    return null
  }
)

export default MobileSideMenu
