import React from "react"
import { observer } from "mobx-react-lite"
import sortBy from "lodash/sortBy"

import ScrollableContainer from "@components/ui/ScrollableContainer/ScrollableContainer"
import InsightCard from "@components/prototypes/InsightCard/InsightCard"
import UserCard from "@components/prototypes/InsightCard/UserCard"
import { useController, useStore } from "@store/index"
import Loader from "@components/ui/Loader/BarLoader"

import useInsightsCollection from "../hooks/useInsightsCollection"
import ContextMenu, { ActionName } from "./ContextMenu"

import styles from "./index.module.sass"

interface ArticleProps {
  avatarId: string
  articleId: string
  hideInsight?: string
  className?: string
  onEdit?: (insight: string) => void
  onDelete?: (insight: string) => void
  editable?: boolean
}

const InsightsThread: React.FC<ArticleProps> = observer(
  ({ avatarId, articleId, hideInsight, editable, onEdit, onDelete }) => {
    const {
      authStore: { userProfile },
    } = useStore()

    const { expertInsightsController } = useController()

    const insightsCollection = useInsightsCollection(articleId)

    const getActionHandler = (insightId: string) => (action: ActionName) => {
      if (action === "edit") onEdit?.(insightId)
      if (action === "delete") onDelete?.(insightId)
    }

    React.useEffect(() => {
      expertInsightsController.loadArticleInsightList(avatarId, articleId)
    }, [avatarId, articleId])

    const currentUserId = userProfile?.id

    const insights = React.useMemo(
      () =>
        sortBy(insightsCollection.data, (it) => it.lastUpdatedTime).reverse(),
      [insightsCollection.data]
    )

    return !insights.length ? (
      insightsCollection.isLoading ? (
        <Loader size="large" primary />
      ) : null
    ) : (
      <div className={styles.threadContainer}>
        <ScrollableContainer>
          {insights.map((insight) => {
            if (hideInsight === insight.id) return null

            const isCurrentUser = insight.createdBy === currentUserId

            return (
              <InsightCard
                answer={insight.insight ?? ""}
                headerSocket={
                  <UserCard
                    prefix={isCurrentUser ? "Your insight" : null}
                    highlighted={isCurrentUser}
                    name={insight.name}
                    avatarUrl={insight.avatarUrl}
                    lastUpdated={insight.lastUpdatedTime ?? "N/A"}
                    controlSocket={
                      isCurrentUser && editable ? (
                        <ContextMenu onAction={getActionHandler(insight.id)} />
                      ) : null
                    }
                  />
                }
                key={insight.id}
              />
            )
          })}
        </ScrollableContainer>
      </div>
    )
  }
)

export default InsightsThread
