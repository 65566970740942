import { observer } from "mobx-react-lite"
import React, { useEffect, useMemo } from "react"
import differenceBy from "lodash/differenceBy"

import { getDataSourceCategoryDescription } from "@framework/constants/upload"
import { DataCategoryEntity } from "@framework/types/knowledge"
import { useController, useStore } from "@store/index"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"

import useAvatarMembershipContext from "../MembershipContext/useAvatarMembershipContext"
import BaseContainer from "../BaseContainer/BaseContainer"
import Option from "../Option/Option"
import { BasicForm } from "../types"

interface DatatypesProps extends BasicForm {}

const DELETE_MODAL_TITLE = "Remove Content Type"

const WARNING_TEXT =
  "Removing this datatype from the avatar, will also remove associated data from the avatar"

const QUESTION_TEXT = "Are you sure you want to remove Content Type?"

const Datatypes: React.FC<DatatypesProps> = observer(({ onAdd, onRemove }) => {
  const multiformContext = useAvatarMembershipContext()

  const { showModal } = useModal(
    ModalsTypes.DELETE_AVATAR_MEMBERSHIP_ITEM_MODAL
  )

  const {
    initialData: { knowledgeDataTypes },
    membershipData: { dataTypeIdsToAdd },
    updateMembershipData,
    isEditing,
    handleEdit,
  } = multiformContext

  const {
    datatypesStore: { isLoading, data },
  } = useStore()

  const {
    datatypesController: { loadAllDataTypes },
  } = useController()

  useEffect(() => {
    loadAllDataTypes()
  }, [])

  const handleAddDatatypes = (add: string) => {
    updateMembershipData("dataTypeIdsToAdd", add)
  }

  const handleRemoveConfirm = async (id: string) => {
    await onRemove?.({ dataTypeIdsToDelete: [id] })
  }

  const handleItemClick = (id: string) => {
    if (isEditing) {
      handleAddDatatypes(id)
    } else {
      showModal({
        onSubmit: () => handleRemoveConfirm(id),
        warning: WARNING_TEXT,
        question: QUESTION_TEXT,
        title: DELETE_MODAL_TITLE,
      })
    }
  }

  const optionRenderer = (item: DataCategoryEntity) => {
    const desc = getDataSourceCategoryDescription(item.name)
    return (
      <Option
        key={item.id}
        icon={desc.icon}
        label={item.name}
        onClick={() => handleItemClick(item.id)}
        checked={dataTypeIdsToAdd.includes(item.id)}
        isEdit={isEditing}
      />
    )
  }

  const items = useMemo(() => {
    const assignedItems = knowledgeDataTypes ?? []

    if (isEditing) {
      return differenceBy(data, assignedItems, "id")
    }
    return assignedItems
  }, [isEditing, knowledgeDataTypes, data])

  return (
    <BaseContainer<DataCategoryEntity>
      items={items}
      isEditing={isEditing}
      isLoading={isLoading}
      entityKey="Content Types"
      renderer={optionRenderer}
      addLength={dataTypeIdsToAdd.length}
      onSubmit={() => onAdd?.()}
      onEdit={handleEdit}
    />
  )
})

export default Datatypes
