import React from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"
import clsx from "clsx"

import { useStore } from "@store/index"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import Chip from "@components/ui/Chip/Chip"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import UserChip from "@components/prototypes/UserChip"
import AvatarBubbles from "@components/prototypes/AvatarBubbles"
import {
  DataConnectorContentGroupName,
  DataConnectorSourceName,
} from "@framework/types/upload"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import { countSuffix } from "@utils/numberUtils"
import { capitalizeFirstLetter } from "@utils/textUtils"

import { Column, Row } from "./Table"
import ContextMenu from "../components/DocumentUploadCard/ContextMenu"
import FolderStatusBadge from "./FolderStatusBadge"
import useCloudFoldersContextMenu from "../connectors/Sources/hooks/useCloudFoldersContextMenu"

import styles from "./CloudFolderRow.module.sass"

export const CloudFolderRow: React.FC<{
  dataConnectorId: string
  index: number
  // TODO move to context
  connectorSourceName: DataConnectorSourceName
  sourceName: DataConnectorContentGroupName
}> = observer(({ index, connectorSourceName, sourceName, dataConnectorId }) => {
  const {
    allDatatypesStore,
    restrictionsStore: access,
    uploadStore,
    allUserStore,
  } = useStore()

  const store = uploadStore.getCloudFoldersStore(
    connectorSourceName,
    dataConnectorId
  )

  const item = store.getByIndex(index)

  const isReadonly = !access.canEditDataSource

  const itemTypeDescription = getDataSourceNodeContextProps(sourceName)

  const dataType = allDatatypesStore.getById(
    item?.knowledgedatatypes?.[0]?.id ?? null
  )

  const contextMenuProps = useCloudFoldersContextMenu(
    connectorSourceName,
    dataConnectorId,
    dataType?.id,
    "folders",
    isReadonly,
    store.refresh
  )

  if (item == null) return <Skeleton rounded lineHeight={56} minWidth={100} />

  const isRunning = item.status === "loading"
  const isFailed = item.status === "failed"

  const handleOpen = () => contextMenuProps.onContext(item.id, "open")

  const handleAssignDataType =
    isReadonly || isFailed
      ? undefined
      : (e: React.MouseEvent) => {
          e.stopPropagation()
          contextMenuProps.onContext(item.id, "edit_data_type")
        }

  const totalFiles = item.files != null ? Number(item.files) : 0

  return (
    <Row
      className={clsx(styles.row)}
      loading={isRunning}
      onDoubleClick={handleOpen}
    >
      <Column>
        <DocumentIcon
          icon={totalFiles ? "img:folder_full" : "img:folder_empty"}
          height={28}
          width={28}
        />
      </Column>

      <Column title={item.name}>
        <Text variant="inherit" className={styles.text}>
          {item.name}
        </Text>
      </Column>

      <Column>
        {totalFiles}{" "}
        {capitalizeFirstLetter(itemTypeDescription.contentItemName ?? "file")}
        {countSuffix(totalFiles)}
      </Column>

      <Column>
        <FolderStatusBadge status={item.status} />
      </Column>

      <Column>
        <Chip
          color="secondary"
          variant="rounded"
          before={
            dataType != null && <Icon name={dataType.iconUrl ?? "global"} />
          }
          onClick={handleAssignDataType}
          title={dataType != null ? dataType.name : "Not Associated "}
        >
          {dataType != null ? dataType.name : "Not Associated "}
        </Chip>
      </Column>

      <Column>
        <AvatarBubbles avatars={dataType?.channels} />
      </Column>

      <Column>{moment(item.createdAt).format("MMM DD, YYYY")}</Column>

      <Column>
        <UserChip
          data={allUserStore.getById(item.userId ?? null)}
          isLoading={allUserStore.state.isLoading}
        />
      </Column>

      <Column>
        {!!contextMenuProps.options.length && (
          <ContextMenu id={item.id} {...contextMenuProps} />
        )}
      </Column>
    </Row>
  )
})

export default CloudFolderRow
