import React from "react"
import { observer } from "mobx-react-lite"
import debounce from "lodash/debounce"
import type { IndexRange } from "react-virtualized"

import TextInput from "@components/ui/TextInput/TextInput"
import useAvatarWebsitesKnowledge from "@store/avatar-knowledge/useAvatarWebsitesKnowledge"
import DataSourceFileCard from "@pages/upload/components/DocumentUploadCard/UploadingDataSourceCard"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import { useController } from "@store/index"
import useSearchTextInput from "@components/ui/TextInput/useSearchTextInput"
import { DataSourceFile } from "@framework/types/upload"

import ItemsGrid from "../components/ItemsGrid/ItemsGrid"
import useActiveCategoryContext from "../ActiveDataCategoryContext/useActiveCategoryContext"
import BreadCrumpsIndicator from "./BreadCrumpsContext/BreadCrumpsIndicator"

import styles from "./index.module.sass"

const COLUMNS = 4

export const DataSourceWebsites: React.FC = observer(() => {
  const category = useActiveCategoryContext()

  const { collection, resetCollection } = useAvatarWebsitesKnowledge(
    category.id
  )
  const { avatarKnowledgeController } = useController()

  const sourceTypeDescription = getDataSourceNodeContextProps("website")

  const handleSearch = React.useCallback(debounce(resetCollection, 500), [])

  const search = useSearchTextInput({
    onChange: handleSearch,
  })

  const handleLoad = async ({ startIndex, stopIndex }: IndexRange) => {
    const pageSize = 2 * (stopIndex - startIndex)
    await avatarKnowledgeController.loadWebsiteList(category.id, {
      pageNum: Math.floor(startIndex / pageSize),
      pageSize,
      query: search.query,
    })
  }

  const sourceClickHandler = (data: DataSourceFile) => {
    const websiteLink = data.sitemapUrls?.[0] ?? data.crawlingUrls?.[0]
    if (websiteLink) return () => window.open(websiteLink, "_blank")
    return undefined
  }

  return (
    <>
      <div className={styles.navigation}>
        <BreadCrumpsIndicator />
      </div>

      <div className={styles.filter}>
        <TextInput {...search.props} />
      </div>

      <ItemsGrid
        key={collection.id}
        total={collection.total}
        totalLoaded={collection.totalLoaded}
        loading={collection.isLoading}
        unit="website"
        rowHeight={161}
        isRowLoaded={({ index }) => collection.getByIndex(index) != null}
        loadMoreRows={handleLoad}
        columnCount={COLUMNS}
        renderCard={(idx) => {
          const data = collection.getByIndex(idx)
          if (!data) return null
          return (
            <DataSourceFileCard
              item={data}
              defaultIcon={sourceTypeDescription.icon}
              onClick={sourceClickHandler(data)}
              key={data.id}
            />
          )
        }}
      />
    </>
  )
})

export default DataSourceWebsites
