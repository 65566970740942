import React from "react"

import { useStore } from "@store"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import Templates from "@components/ui/Templates"

import ProductCardSelectable from "../ProductCardSelectable/ProductCardSelectable"
import { useProductsContext } from "./ProductsContext"

import styles from "./index.module.sass"

const RecommendedProductsList = () => {
  const {
    productComparisonStore: {
      targetCompaniesStore: { findCompanyById: getCompanyById },
    },
  } = useStore()

  const { recommendedProductsStore } = useProductsContext()

  const recommendedProducts = recommendedProductsStore.state

  return (
    <div className={styles.root}>
      <div className={styles.cardsContainer}>
        <Templates.Header
          offsetBottom="medium"
          left={
            <Text variant="h5" color="goldColor">
              <Icon name="recommended" inline /> Recommended
            </Text>
          }
        />

        {recommendedProducts.data.length ? (
          <div className={styles.productGrid}>
            {recommendedProducts.data.map((product) => {
              const companyName = getCompanyById(product.companyId)?.name

              return (
                <ProductCardSelectable
                  product={product}
                  company={companyName}
                  key={product.id}
                />
              )
            })}
          </div>
        ) : recommendedProducts.isLoading ? (
          <Loader fluid size="large" />
        ) : (
          <NotFound>No products found</NotFound>
        )}
      </div>
    </div>
  )
}

export default RecommendedProductsList
