import set from "lodash/set"
import get from "lodash/get"

import { Formula, Function, Unknown } from "./types"
import { normalizeFuncName } from "./utils"

const validFormula1 = `A12 +  A1 - -sum(b12 , sum( $b$1:b$12, 123 ), -12)`
const validFormula2 = `SUM(A2,B2,B2:C2,SUM(A2:B2,$B$2:$D$2))`
const invalidFormula1 = `(*^asd987asgf a0s-9d0a-sf" 0=-9 0-a9sf =0-)`

export const tokenize = (formula: string) => {
  return (
    formula.match(
      /[(),]|\s*([-+*/]|"[^"]*"?|\$?[A-Za-z]+\$?[0-9]+(:\$?[A-Za-z]+\$?[0-9]+)?|[A-Za-z]+|[0-9]+\.[0-9]+|[0-9]+|.+)\s*/g
    ) || []
  )
}

export type ParseResult = {
  matched: Formula
  rest: Unknown
  refs: string[]
  isValid: boolean
}

export class Parser {
  private index: number = 0

  tokens: string[] = []

  refs: string[] = []

  errors: string[] = []

  parse = (tokens: string[]): ParseResult => {
    this.index = 0
    this.tokens = tokens
    this.refs = []
    this.errors = []

    return {
      matched: this.parseBinaryExpression(),
      rest: this.parseUnknowns(),
      refs: this.refs,
      isValid: this.errors.length === 0,
    }
  }

  parseBinaryExpression = (): Formula => {
    const left = this.parseExpression()

    if (this.index >= this.tokens.length) {
      return left
    }

    const token = this.tokens[this.index]
    const sign = token.trim()

    if (["*", "+", "-", "/"].includes(sign)) {
      // +1 operator
      this.index += 1
      return {
        type: "bin-exp",
        operation: sign as any,
        token,
        left,
        right: this.parseBinaryExpression(),
      }
    }

    return left
  }

  parseExpression = (): Formula => {
    const token = this.tokens[this.index]

    if (token == null) {
      return {
        type: "const",
        value: null,
        token: "",
      }
    }

    const value = token.trim()

    // Unary expression
    if (value === "+" || value === "-") {
      // +1 operator
      this.index += 1
      return {
        type: "un-exp",
        operation: value,
        token,
        value: this.parseExpression(),
      }
    }

    // Range / Reference
    if (value.match(/^\$?[a-zA-Z]+\$?[0-9]+(:\$?[a-zA-Z]+\$?[0-9]+)?$/)) {
      // +1 ref
      this.index += 1

      const ref = value.toUpperCase()

      if (!this.refs.includes(ref)) this.refs.push(ref)

      return {
        type: "ref",
        name: ref,
        token,
      }
    }

    // Number
    if (value.match(/^"[^"]*"?|[0-9]+|[0-9]+\.[0-9]+$/)) {
      // +1 const
      this.index += 1
      return {
        type: "const",
        value,
        token,
      }
    }

    // Function
    if (value.match(/^[A-Za-z]+$/) && this.tokens[1 + this.index] === "(") {
      return this.parseFunction(token)
    }

    return this.parseUnknowns()
  }

  parseUnknowns = (): Unknown => {
    let leftover = ""

    while (this.tokens[this.index]) {
      leftover += this.tokens[this.index]
      this.index += 1
    }

    if (leftover) this.errors.push("Unknown format")

    return {
      type: "unknown",
      token: leftover,
    }
  }

  parseFunction = (funNameToken: string): Function => {
    // +1 funcName +1 open bracket
    this.index += 2

    const open = true

    const args: Formula[] = []

    while (this.tokens[this.index] !== ")") {
      args.push(this.parseBinaryExpression())

      if (this.tokens[this.index] !== ",") break

      // +1 comma
      this.index += 1

      if (this.tokens[this.index] === ")") {
        args.push({ type: "unknown", token: "" })
        this.errors.push("Missing function argument")
      }
    }

    const closed = this.tokens[this.index] === ")"

    // +1 close bracket
    if (closed) this.index += 1

    return {
      type: "func",
      name: normalizeFuncName(funNameToken),
      token: funNameToken,
      open,
      closed,
      arguments: args,
    }
  }
}

/**
 * @returns return path to formula node which focusIndex pointing to
 */
export const traceFocusedNode = (formula: Formula, focusIndex: number) => {
  let startIndex: number = 0

  const trace = (formula: Formula): (string | number)[] | null => {
    if (focusIndex < startIndex) {
      throw new Error("Unexpected statement")
    }

    switch (formula.type) {
      case "func": {
        // funcName
        startIndex += formula.token.length

        // is funcName focused
        if (startIndex >= focusIndex) return []

        // opening bracket
        startIndex += 1

        const total = formula.arguments.length

        for (let i = 0; i < total; i += 1) {
          const pathChunk = trace(formula.arguments[i])

          // is i argument focused
          if (pathChunk != null) return ["arguments", i, ...pathChunk]

          // separating comma
          if (total - 1 > i) startIndex += 1
        }

        // closing bracket
        if (formula.closed) startIndex += 1

        return null
      }

      case "bin-exp": {
        const leftPathChunk = trace(formula.left)

        // is left operand focused
        if (leftPathChunk != null) return ["left", ...leftPathChunk]

        // expression operator
        startIndex += formula.token.length

        const rightPathChunk = trace(formula.right)

        // is right operand focused
        if (rightPathChunk != null) return ["right", ...rightPathChunk]

        return null
      }

      case "un-exp": {
        // expression operator
        startIndex += formula.token.length

        const pathChunk = trace(formula.value)

        // is only operand focused
        if (pathChunk != null) return ["value", ...pathChunk]

        return null
      }

      default: {
        startIndex += formula.token.length

        // is unknown, const or ref focused
        if (startIndex >= focusIndex) return []
      }
    }

    return null
  }

  try {
    return trace(formula)
  } catch (e) {
    return null
  }
}

export const getFocusedNode = (
  formula: Formula,
  path: (string | number)[]
): Formula => {
  if (path.length === 0) return formula

  const res = get(formula, path, null)

  if (res == null) throw new Error("Invalid formula tree path passed")

  return res
}

export const setFocusedNode = (
  formula: Formula,
  path: (string | number)[],
  node: Formula
): Formula => {
  if (path.length === 0) return node
  return set(formula, path, node)
}
