import React, { CSSProperties, useState } from "react"

import { ModalsTypes } from "@components/modals/constants"
import TextInput from "@components/ui/TextInput/TextInput"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import SelectOption from "@components/ui/Dropdown/SelectOption"
import { ConnectorFilter } from "@framework/types/content-manager"
import { useStore } from "@store"
import useClickOutside from "@components/hooks/useOutsideClick"

import useModal from "../useModal"
import BaseModal from "../components/BaseModal/BaseModal"
import FilterOption from "./components/FilterOption"

import styles from "./ContentManagerFiltersModal.module.sass"

export type ContentManagerFiltersModalProps = {
  top: number
  left: number
  filtersList: ConnectorFilter[]
}

const ContentManagerFiltersModal: React.FC<ContentManagerFiltersModalProps> = ({
  top,
  left,
  filtersList,
}) => {
  const { hideModal, isOpen } = useModal(
    ModalsTypes.CONTENT_MANAGER_FILTERS_MODAL
  )
  const [type, setType] = useState("include")
  const { contentManagerStore } = useStore()

  const modalStyle: CSSProperties = {
    position: "absolute",
    top,
    left: left - 300,
  }
  const containerRef = React.useRef<HTMLDivElement | null>(null)

  useClickOutside({
    element: containerRef,
    disabled: !isOpen,
    options: true,
    callBack: hideModal,
  })
  return (
    <BaseModal
      containerClassName={styles.modalContainer}
      className={styles.root}
      style={modalStyle}
    >
      <div ref={containerRef}>
        <div className={styles.header}>
          <Button
            size="tiny"
            before={<Icon name="line-arrow-down" rotateAngle={90} />}
            variant="text"
            onClick={hideModal}
          >
            Filters
          </Button>
          <div className={styles.typeSelect}>
            <SelectOption
              placement="bottom"
              options={["Include", "Exclude"]}
              placeholder="Select content type"
              value={type}
              onChange={setType}
            />
          </div>
        </div>
        <TextInput placeholder="Search" />

        <div className={styles.body}>
          {filtersList.map((option) => (
            <FilterOption option={option} />
          ))}
        </div>
        <div className={styles.footer}>
          <Button
            size="tiny"
            variant="text"
            onClick={contentManagerStore.clearFilters}
            color="secondary"
          >
            Clear all
          </Button>
          <Button color="primary" size="small" onClick={hideModal}>
            Apply
          </Button>
        </div>
      </div>
    </BaseModal>
  )
}

export default ContentManagerFiltersModal
