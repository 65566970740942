import { observer } from "mobx-react-lite"
import React from "react"

import Button from "@components/ui/Button/Button"
import List from "@components/ui/List/List"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import { ProductDocument } from "@framework/types/product"
import NotFound from "@components/ui/NotFound/NotFound"

import styles from "./RelatedDocuments.module.sass"

type RelatedDocumentsProps = {
  documents: ProductDocument[]
}

const RelatedDocuments: React.FC<RelatedDocumentsProps> = observer(
  ({ documents }) => {
    const handleOpenFile = (url: string) => {
      window.open(url, "_blank")
    }

    return (
      <div className={styles.root}>
        <Text variant="h4" weight="bold">
          Related Documents
        </Text>
        <List>
          {documents.length === 0 ? (
            <NotFound>No Documents Available</NotFound>
          ) : (
            documents.map((item) => (
              <Button
                variant="text"
                size="tiny"
                after={<Icon name="arrow-down" rotateAngle={-90} />}
                key={item.id}
                className={styles.button}
                onClick={() => handleOpenFile(item.url)}
              >
                {item.name}
              </Button>
            ))
          )}
        </List>
      </div>
    )
  }
)

export default RelatedDocuments
