import React from "react"

import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import { FileStatus } from "@framework/types/upload"
import List from "@components/ui/List/List"

const FileStatusBadge: React.FC<{
  status?: FileStatus
  progress?: number
  error?: string
}> = ({ status, progress, error }) => {
  if (status == null ? null : status === "failed") {
    const errorMessage = error || "Processing failed"
    return (
      <Text variant="h2">
        <Icon name="alert" color="red" title={errorMessage} />
      </Text>
    )
  }

  if (status === "running") {
    const progressIndicator = progress != null ? `${progress}%` : null
    const progressTitle = `Processing... ${
      progressIndicator ? ` ${progressIndicator}` : ""
    }`

    return (
      <List direction="row" align="center" gutter="8" title={progressTitle}>
        <Text variant="h2">
          <Icon color="gold" name="time" />
        </Text>
        <Text variant="body2">{progressIndicator}</Text>
      </List>
    )
  }

  return (
    <Text variant="h2">
      <Icon
        color="green"
        name="checkbox-circle-fill"
        title="File processed and ready to use"
      />
    </Text>
  )
}

export default FileStatusBadge
