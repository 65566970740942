import React from "react"
import { observer } from "mobx-react-lite"
import { useLocation, useNavigate } from "react-router-dom"

import {
  DataConnectorContentGroupName,
  DataConnectorSourceName,
} from "@framework/types/upload"
import useToggle from "@components/hooks/useToggle"
import useEvent from "@components/hooks/useEvent"

import ConnectDataSourceButton from "../components/UploadHeader/ConnectDataSourceButton"
import { useSelectDataTypeContext } from "../PreselectDataTypeContext"
import ConnectCloudFoldersSidebar from "../ConnectCloudFolders/ConnectCloudFoldersSidebar"

export const ConnectDCExplorerEntitiesButton: React.FC<{
  dataConnectorId: string
  sourceName: DataConnectorContentGroupName
  connectorSourceName: DataConnectorSourceName
}> = observer(({ dataConnectorId, sourceName, connectorSourceName }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const connectSidebar = useToggle()

  const handleUpload = useEvent(() => {
    connectSidebar.setOpened(true)
  })

  const { dataTypeId, selectDataType } = useSelectDataTypeContext()

  React.useEffect(() => {
    if (location.state === "FIRST_TIME" && dataConnectorId) {
      selectDataType({
        onSelect: handleUpload,
      })
      navigate(location.pathname, { replace: true })
    }
  }, [location.state, dataConnectorId])

  return (
    <>
      <ConnectDataSourceButton
        sourceName={sourceName}
        onClick={() =>
          selectDataType({
            autoSelect: true,
            onSelect: handleUpload,
          })
        }
      />

      <ConnectCloudFoldersSidebar
        nested
        open={connectSidebar.isOpened && !!dataTypeId}
        dataConnectorId={dataConnectorId}
        sourceName={sourceName}
        connectorSourceName={connectorSourceName}
        onClose={() => {
          connectSidebar.setOpened(false)
          // TODO request reload
        }}
      />
    </>
  )
})

export default ConnectDCExplorerEntitiesButton
