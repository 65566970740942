/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx"
import React from "react"
import { observer } from "mobx-react-lite"

import { DocumentIconType } from "@framework/types/utils"
import { IconName } from "@components/ui/Icon/IconName"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"

import styles from "./BaseDataSourceCard.module.sass"

export interface BaseDataSourceCardProps {
  icon?: DocumentIconType | IconName
  title: string
  control?: React.ReactNode
  content?: React.ReactNode
  onClick?: React.MouseEventHandler
}

export const BaseDataSourceCard: React.FC<BaseDataSourceCardProps> = observer(
  ({ icon, title: name, content, control, onClick }) => (
    <div
      className={clsx(styles.root, {
        [styles.clickable]: onClick,
      })}
      onClick={onClick}
    >
      <div className={styles.container}>
        <div className={styles.icon}>
          <DocumentIcon icon={icon ?? "global"} />
        </div>

        <h4 className={styles.title} title={name}>
          {name}
        </h4>

        <div className={styles.footer}>{content}</div>

        {!!control && <span className={styles.control}>{control}</span>}
      </div>
    </div>
  )
)

export default BaseDataSourceCard
