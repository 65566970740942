import React from "react"

import Text from "@components/ui/Typography/Text"
import {
  QueryFilterCategory,
  ProductFilterKeys,
  QueryFilterData,
  CategoryInfo,
} from "@framework/types/query-filter"

import { FilterButton } from "./FilterButton"

import styles from "./ProductsSection.module.sass"

interface FilterListProps {
  availableFilters: {
    product: Record<string, QueryFilterCategory>
    data: Record<string, QueryFilterCategory>
  } | null
  setSelectedCategory: React.Dispatch<
    React.SetStateAction<CategoryInfo | undefined>
  >
  handleSubcategorySelect: (filter: QueryFilterData) => void
}

const FilterList: React.FC<FilterListProps> = ({
  availableFilters,
  setSelectedCategory,
  handleSubcategorySelect,
}) => (
  <>
    {!!availableFilters?.product?.products?.count && (
      <>
        <Text variant="body2" className={styles.categoryHeader}>
          Product
        </Text>
        <div className={styles.filterTypes}>
          <FilterButton
            category="product"
            name="products"
            count={availableFilters?.product?.products?.totalCount}
            queryFilterCategory={availableFilters?.product?.products}
            setSelectedCategory={setSelectedCategory}
          />
          {availableFilters?.product?.[
            "categories" as ProductFilterKeys
          ]?.items?.map((item) => (
            <FilterButton
              key={item.name}
              category="product"
              name={item.name}
              count={item.count}
              queryFilterCategory={item as QueryFilterCategory}
              setSelectedCategory={() => handleSubcategorySelect(item)}
            />
          ))}
        </div>
      </>
    )}
    <Text variant="body2" className={styles.categoryHeader}>
      Content
    </Text>
    <div className={styles.filterTypes}>
      {availableFilters?.data &&
        Object.entries(availableFilters.data).map(([key, value]) => (
          <FilterButton
            key={key}
            category="data"
            count={value?.totalCount}
            name={key as ProductFilterKeys}
            queryFilterCategory={value}
            setSelectedCategory={setSelectedCategory}
          />
        ))}
    </div>
  </>
)

export default FilterList
