import * as yup from "yup"

/**
 * @deprecated Intro page and all related code was marked as deprecated. Remove the section in 2025 year
 */

const INTRO_SHOWN_STORAGE_KEY = "INTRO_SHOWN"
const DONT_SHOW_INTRO_AGAIN_STORAGE_KEY = "DONT_SHOW_INTRO_AGAIN"
localStorage.removeItem(DONT_SHOW_INTRO_AGAIN_STORAGE_KEY)
localStorage.getItem(INTRO_SHOWN_STORAGE_KEY)

//-------------------------------------------------------------------------------------------------------------

export const AUTH_REFRESH_TOKEN_STORAGE_KEY = "AUTH_REFRESH_TOKEN_KEY"

export const SOLUTION_STORE_STORAGE_KEY = "SOLUTION_STORE_STORAGE_KEY"

export enum CommentType {
  Length = "length",
  LowerCase = "lowerCase",
  UpperCase = "upperCase",
  Digit = "digit",
  Character = "character",
  Duplicate = "duplicate",
  Required = "required",
}

export const PasswordValidationComment = {
  [CommentType.Required]: "Password is required",
  [CommentType.Length]: "Must be 10 or more chars",
  [CommentType.LowerCase]: "Must contain at least one latin lowercase char",
  [CommentType.UpperCase]: "Must contain at least one latin uppercase char",
  [CommentType.Digit]: "Must contain at least 1 digit",
  [CommentType.Character]: "Must contain at least 1 special char",
  [CommentType.Duplicate]: "Less than 3 duplicate consecutive chars is allowed",
}

export const strongPasswordValidationSchema = yup
  .string()
  .required(CommentType.Required)
  .min(10, CommentType.Length)
  .matches(/[a-z]/, CommentType.LowerCase)
  .matches(/[A-Z]/, CommentType.UpperCase)
  .matches(/\d+/, CommentType.Digit)
  .matches(
    /([\\!|\\@|\\#|\\$|\\%|\\^|\\&|\\*|\\)|\\(|\\+|\\=|\\.|\\<|\\>|\\{|\\}|\\[|\\]|\\:|\\;|\\'|\\"\||\\~|\\`|\\_|\\-]+)/g,
    CommentType.Character
  )
  .test({
    test: (value) => !value?.match(/(.)\1\1+/g),
    message: CommentType.Duplicate,
  })

export const passwordValidationRequiredOptions = [
  CommentType.Length,
  CommentType.LowerCase,
  CommentType.UpperCase,
  CommentType.Digit,
  CommentType.Character,
  CommentType.Duplicate,
]

export default {}
