import { makeAutoObservable } from "mobx"

import { ReportData } from "@framework/types/workbook-report"

export default class WorkbookReportStore {
  id: string | null = null

  report: ReportData | null = null

  isLoading: boolean = false

  error: string | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setReport = (id: string | null, data: ReportData | null) => {
    this.id = id
    this.report = data
  }
}
