import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import { useController } from "@store/index"
import {
  AnswerViewType,
  ExtendedResultType,
  SolutionTypes,
} from "@framework/constants/search-results"
import mainRoutes from "@root/main.routes"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import useEvent from "@components/hooks/useEvent"
import { useSearchBlock } from "@pages/search/SearchContext/SearchResultContext"
import { SearchPassageContext } from "@pages/search/SearchContext/SearchPassageContext"

export const SearchFlowPassageContextProvider: React.FC = observer(
  ({ children }) => {
    const navigate = useNavigate()

    const { searchBlockId, searchEntityBlock, searchEntity } = useSearchBlock(
      SearchSummaryBlockStore
    )

    const { factFinderSolutionController } = useController()

    const upVotePassage = useEvent(
      async (
        passageId: string,
        voted: boolean,
        answerType: AnswerViewType,
        avatarId: string
      ) => {
        const passage =
          searchEntityBlock.searchAnswersData?.getAnswerById(passageId)

        if (passage == null) return null

        const error = await factFinderSolutionController.updateAnswerUpVote(
          searchEntity.id,
          passage,
          voted,
          answerType,
          avatarId
        )

        return error
      }
    )

    const openExtendedView = useEvent(
      (passageId: string, tab: ExtendedResultType = "Original") => {
        navigate(
          mainRoutes.solutionsFlowExtendedPassage(
            SolutionTypes.InsightsFinder,
            searchEntity.id,
            searchBlockId,
            passageId,
            tab
          )
        )
      }
    )

    return (
      <SearchPassageContext.Provider
        value={{
          upVotePassage,
          openExtendedView,
        }}
      >
        {children}
      </SearchPassageContext.Provider>
    )
  }
)

export default SearchFlowPassageContextProvider
