import React from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import Templates from "@components/ui/Templates"
import usePagination from "@components/ui/Pagination/usePagination"
import Pagination from "@components/ui/Pagination/Pagination"
import { renderLargeNumber } from "@utils/numberUtils"
import Collapsable from "@components/ui/Collapsable/Collapsable"
import { useSearchContext } from "@components/prototypes/SearchContext"

import ProductCardSelectable from "../ProductCardSelectable/ProductCardSelectable"
import { useProductsContext } from "./ProductsContext"

import styles from "./index.module.sass"

const OtherProducts: React.FC<{
  pageSize: number
}> = observer(({ pageSize }) => {
  const searchContext = useSearchContext()

  const {
    productComparisonStore: {
      targetCompaniesStore: { findCompanyById: getCompanyById },
    },
  } = useStore()

  const { productListStore, productsViewStore: viewStore } =
    useProductsContext()

  const products = productListStore.state
  const pagination = usePagination({
    totalRows: products.total,
    pageSize,
    initialPage: products.pagination.pageNum,
  })

  React.useEffect(() => {
    if (
      products.pagination.pageNum === pagination.forcePage &&
      products.pagination.pageSize === pageSize &&
      products.query === searchContext.query
    )
      return

    productListStore.update({
      query: searchContext.query,
      pagination: {
        pageNum: pagination.forcePage,
        pageSize,
      },
    })
  }, [pagination.forcePage, pageSize])

  const isOpened = viewStore.isOtherVisible || !viewStore.withRecommended

  return (
    <section className={styles.root}>
      <Collapsable open={isOpened}>
        <Templates.Header
          left={
            !!viewStore.withRecommended && (
              <Text variant="h5">
                Other{" "}
                <Text variant="inherit" color="text50Color" inline>
                  ({renderLargeNumber(products.total)}) products
                </Text>
              </Text>
            )
          }
        />
        <div className={styles.otherSection}>
          <div className={styles.cardsContainer}>
            {products.data.length ? (
              <div className={styles.productGrid}>
                {products.data.slice(0, pageSize).map((product) => {
                  const companyName = getCompanyById(product.companyId)?.name

                  return (
                    <ProductCardSelectable
                      product={product}
                      company={companyName}
                      key={product.id}
                    />
                  )
                })}
              </div>
            ) : products.isLoading ? (
              <Loader size="large" fluid />
            ) : (
              <NotFound>No products found</NotFound>
            )}
          </div>
        </div>
      </Collapsable>

      <Templates.Header
        left={
          !!viewStore.withRecommended &&
          (viewStore.isOtherVisible ? (
            <Button
              variant="outlined"
              size="tiny"
              onClick={viewStore.toggleOthersVisibility}
            >
              Hide Other
            </Button>
          ) : (
            <Button
              variant="outlined"
              size="tiny"
              onClick={viewStore.toggleOthersVisibility}
              after={
                products.isLoading ? (
                  <Loader />
                ) : (
                  <Text variant="inherit" color="text50Color" inline>
                    ({renderLargeNumber(products.total)}) products
                  </Text>
                )
              }
            >
              Show Other
            </Button>
          ))
        }
        right={
          products.total > pageSize &&
          isOpened && <Pagination {...pagination} />
        }
      />
    </section>
  )
})

export default OtherProducts
