import React from "react"

import { ProductProperty } from "@framework/types/product"

const getRecursiveProperties = (property: ProductProperty): React.ReactNode => {
  if (property.type === "string") {
    return (
      <p key={property.id}>
        <strong>{property.name}</strong>: {property.value}
      </p>
    )
  }
  if (property.type === "list")
    return (property.properties ?? []).map((p) => getRecursiveProperties(p))

  return null
}

export default getRecursiveProperties
