import React from "react"

import TextInput from "@components/ui/TextInput/TextInput"
import Icon from "@components/ui/Icon/Icon"
import Templates from "@components/ui/Templates"

import ChatEntityItem from "./ChatItem"
import EntityList from "../components/ItemsCollection/EntityList"

export interface ChatsCollectionProps {}

const ChatsCollection: React.FC<ChatsCollectionProps> = () => {
  const [activeIdx, setActiveIdx] = React.useState<number | undefined>(0)

  return (
    <Templates.RollScript
      gutter="16"
      headerSocket={
        <TextInput
          placeholder="Search"
          before={<Icon name="search" />}
          after={
            <>
              <Icon name="sliders" />
              <Icon name="top-down-arrows" />
            </>
          }
        />
      }
    >
      <EntityList
        total={25}
        renderCard={(idx) => {
          const isActive = activeIdx === idx
          return (
            <ChatEntityItem
              active={isActive}
              onClick={() => setActiveIdx(isActive ? undefined : idx)}
              title="Chat Name"
              messagePreview="I think it would be a good..."
              key={idx}
            />
          )
        }}
        unit="chat"
      />
    </Templates.RollScript>
  )
}

export default ChatsCollection
