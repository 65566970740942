import React, { useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"
import SimpleTable from "@components/ui/SimpleTable/SimpleTable"
import EntityTable from "@components/ui/SimpleTable/EntityTable"
import HTMLText from "@components/ui/Typography/HTMLText"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import TruncatedText from "@components/ui/Typography/TruncatedText"
import Text from "@components/ui/Typography/Text"

import MarkdownWithCitations from "./MarkdownWithCitations"
import AnswerTitle from "./AnswerTitle"

import styles from "./AnswerSection.module.sass"

export interface DefaultAnswerProps {
  title?: string
  className?: string
  onTitleClick?: React.MouseEventHandler
  onReadMoreClick?: React.MouseEventHandler
}

export interface TextAnswerSectionProps extends DefaultAnswerProps {
  content: string
  isExpertAnswer?: boolean
  isLoading?: boolean
  hideHeader?: boolean
  controlSocket?: React.ReactNode
  maxLines?: number
  citationLink?: (value: string) => string
}

export const TextAnswerSection: React.FC<TextAnswerSectionProps> = observer(
  ({
    title,
    content,
    isExpertAnswer = false,
    isLoading = false,
    hideHeader = false,
    className,
    controlSocket,
    maxLines,
    onTitleClick,
    onReadMoreClick,
    citationLink,
  }) => {
    return (
      <div className={clsx(styles.root, className)}>
        {!hideHeader && (
          <div className={styles.header}>
            {title != null && (
              <AnswerTitle title={title} onClick={onTitleClick} />
            )}
            <div className={styles.control}>{controlSocket}</div>
          </div>
        )}

        <Text className={styles.text} variant="body2" color="text70Color">
          <TruncatedText maxLines={maxLines} onReadMoreClick={onReadMoreClick}>
            {isLoading ? (
              <Skeleton count={maxLines ?? 5} />
            ) : isExpertAnswer ? (
              <HTMLText>{content}</HTMLText>
            ) : (
              <MarkdownWithCitations citationLink={citationLink}>
                {content}
              </MarkdownWithCitations>
            )}
          </TruncatedText>
        </Text>
      </div>
    )
  }
)

export interface ImageAnswerSectionProps extends DefaultAnswerProps {
  content: string
}

export const ImageAnswerSection: React.FC<ImageAnswerSectionProps> = ({
  title,
  content,
  className,
  onTitleClick,
}) => {
  const [status, setStatus] = useState<string>("loading")

  const onLoad: React.ReactEventHandler<HTMLImageElement> = (e) => {
    setStatus("loaded")
  }

  const onError = () => {
    setStatus("error")
  }

  const isLoading = status === "loading"
  const isLoaded = status === "loaded"
  const isFailed = status === "error"

  const placeholderNode =
    isLoading || isFailed ? (
      <Icon
        name="landscape"
        color="secondary"
        className={styles.imagePlaceholder}
      />
    ) : null

  const imageStyle = {
    height: isLoaded ? undefined : 0,
    width: isLoaded ? undefined : 0,
  }

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.image}>
        {placeholderNode}

        <img
          src={content}
          alt={content}
          className={styles.img}
          onLoad={onLoad}
          onError={onError}
          onLoadStart={() => setStatus("loading")}
          style={imageStyle}
        />
      </div>
      {title != null && <AnswerTitle title={title} onClick={onTitleClick} />}
    </div>
  )
}

export interface TableAnswerSectionProps extends DefaultAnswerProps {
  content: string[][]
}

export const TableAnswerSection: React.FC<TableAnswerSectionProps> = ({
  title,
  content,
  className,
  onTitleClick,
}) => {
  const hasData = !!(content.length && content[0].length)

  return (
    <div className={clsx(styles.root, className)}>
      {title != null && <AnswerTitle title={title} onClick={onTitleClick} />}
      {hasData ? (
        <SimpleTable data={content} className={clsx(styles.table)} />
      ) : (
        "No data found"
      )}
    </div>
  )
}

export interface EntityAnswerSectionProps extends DefaultAnswerProps {
  content: Record<string, unknown>
}

export const EntityAnswerSection: React.FC<EntityAnswerSectionProps> = ({
  title,
  content = {},
  className,
  onTitleClick,
}) => (
  <div className={clsx(styles.root, className)}>
    {title != null && <AnswerTitle title={title} onClick={onTitleClick} />}
    <EntityTable data={content} />
  </div>
)
