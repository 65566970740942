import { MatrixSnapshot } from "@components/ui/Spreadsheet/types"
import { SolutionTypes } from "@framework/constants/search-results"
import { solutionCategoryOptions } from "@framework/constants/solution-category"

import { Entity } from "./common"
import { QueryFilterData } from "./query-filter"

export interface SolutionSnippetData {
  id: string
  tag: string
  title: string
  description: string
}

export interface SolutionDetails {
  description: string
  category: ProductSolutionCategory
  name: string
  /**
   * @deprecated use workflowType instead
   */
  key: SolutionTypes | string
  workflowType: WorkFlowType
  image: string
  suggestedQuestions?: string[]
  filters?: QueryFilterData[]
  workbookData?: MatrixSnapshot
}

export interface SolutionEntity extends Entity, SolutionDetails {
  createdAt: Date
  lastUpdatedAt: Date
}

export interface SolutionCategory {
  category: ProductSolutionCategory
  heading: string
  description: string
}

export type ProductSolutionCategory = (typeof solutionCategoryOptions)[number]

export enum WorkFlowType {
  SEARCH = "Search",
  WORKBOOK = "Workbook",
  VIEW = "View",
  PRODUCT_COMPARISON = "Product Comparison",
}

export interface SolutionData extends SolutionEntity {}
