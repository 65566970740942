import React from "react"

import Chip from "@components/ui/Chip/Chip"

import styles from "./AvatarsList.module.sass"

interface AvatarsListProps {
  chips?: string[]
}

const AvatarsList: React.FC<AvatarsListProps> = ({ chips: list = [] }) => (
  <div className={styles.root}>
    <h3>Avatars</h3>
    <p>You have access to {list.length} avatars</p>
    <div className={styles.list}>
      {list.map((item) => (
        <Chip variant="rounded" color="blue" key={item}>
          {item}
        </Chip>
      ))}
    </div>
  </div>
)

export default AvatarsList
