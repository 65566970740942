import { makeAutoObservable } from "mobx"
import keyBy from "lodash/keyBy"

import { ListResponse } from "@framework/types/product"
import { Entity } from "@framework/types/common"

export default class SimpleCollectionStore<T extends Entity> {
  data: T[] | null = null

  error: string | null = null

  isLoading = false

  constructor() {
    makeAutoObservable(this)
  }

  get entityMap(): Record<string, T> {
    return keyBy(this.data ?? [], "id")
  }

  setData = (collection: T[]) => {
    this.data = collection
  }
}
