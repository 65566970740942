import clsx from "clsx"
import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import { ExpertQuestion } from "@framework/types/question"
import { useStore } from "@store/index"

import QuestionCard from "../QuestionCard/QuestionCard"

import styles from "./QuestionForm.module.sass"

interface OtherExpertQuestionFormProps {
  className?: string
  questionData: ExpertQuestion
}

const OtherExpertQuestionForm: React.FC<OtherExpertQuestionFormProps> =
  observer(({ className, questionData }) => {
    const alert = useAlert()
    const {
      userSetupStore: { avatarId },
      userStore: { user },
      knowledgeStore: { loadExpertQuestions, reassignQuestionToExpert },
    } = useStore()

    const selectedExperts = useExpertsAssignedToQuestion(questionData)

    const { showModal, hideModal } = useModal(
      ModalsTypes.ADD_EXPERTS_TO_QUESTIONS_MODAL
    )

    const handleSubmit = async (expertIds: string[]) => {
      const questionId = questionData.id
      const error = await reassignQuestionToExpert(questionId, expertIds)

      if (error) {
        alert.error(error)
        return
      }

      const totalExperts = expertIds.length

      alert.success(
        `Question was successfully assigned to new ${
          totalExperts > 1 ? `${totalExperts} experts` : "expert"
        }`
      )
      loadExpertQuestions()
      hideModal()
    }

    const handleAssignToExpert = () => {
      if (!user) return
      showModal({
        title: "Add other experts to this question",
        avatarId: questionData.avatarId ?? avatarId,
        selectedQuestions: selectedExperts.data,
        questions: [questionData],
        onSubmit: handleSubmit,
      })
    }

    const disabled = selectedExperts.isLoading

    return (
      <div className={clsx(styles.root, className)}>
        <QuestionCard data={questionData}>
          <div className={styles.control}>
            <Button
              disabled={disabled}
              onClick={handleAssignToExpert}
              variant="outlined"
              size="medium"
            >
              Add other experts
            </Button>
          </div>
        </QuestionCard>
      </div>
    )
  })

export default OtherExpertQuestionForm

const useExpertsAssignedToQuestion = (question: ExpertQuestion) => {
  const [data, setData] = React.useState<string[]>([])
  const [isLoading, setLoading] = React.useState(false)

  const {
    knowledgeStore: { loadExpertsAssignedToQuestion },
  } = useStore()

  const init = async () => {
    setLoading(true)
    setData([])

    const experts = await loadExpertsAssignedToQuestion(
      question.id,
      question.question
    )

    setData(experts)
    setLoading(false)
  }

  React.useEffect(() => {
    init()
  }, [])

  return { data, isLoading }
}
