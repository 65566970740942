export enum dataSourcesRestrictions {
  showUploadedFiles = "manual",
  showFAQFiles = "faq",
  showEmailFiles = "email",

  showWebsites = "website",

  showSMBConnector = "smb",
  showNetworkDrive = "network-drive",
  showBox = "box",
  showAmazonAwsS3 = "amazon-aws-s3",
  showSharepoint = "sharepoint",
  showSharepointOnline = "sharepoint_online",
  showBlobStorage = "blobstorage",
  showMicrosoftTeams = "microsoft-teams",
  showMicrosoftOnenote = "microsoft-one-note",
  showMicrosoftOnedrive = "microsoft-one-drive",
  showSlack = "slack",
  showGoogleDrive = "google-drive",
  showSalesforce = "salesforce",
  showAtlassianConfluence = "atlassian-confluence",
  showAtlassianConfluenceDataCenter = "atlassian-confluence-data-center",
  showAtlassianJira = "atlassian-jira",
  showZendesk = "zendesk",
}

export default {}
