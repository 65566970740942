import { makeAutoObservable } from "mobx"
import { AxiosProgressEvent } from "axios"

import ContentManagerAPI from "@services/content-manager.service"
import solutionsService from "@services/solutions.service"
import { GeFilterType } from "@framework/types/query-filter"
import RootStore from "@store/RootStore"
import {
  ConnectorDocument,
  CreateContentSourceParams,
  GetConnectorDocumentsParams,
  MANUAL_UPLOAD_FILE_STATUS,
} from "@framework/types/content-manager"
import { getUniqueNameForFile } from "@utils/file"

import ContentManagerStore from "./content-manager.store"

export default class ContentManagerController {
  contentManagerStore: ContentManagerStore

  constructor(injections: RootStore) {
    this.contentManagerStore = injections.contentManagerStore

    makeAutoObservable(this)
  }

  getContentSourceCountsByType = (type: string) => {
    const data = this.contentManagerStore.kibanaConnectorsCount?.find(
      (item) => item.type === type
    )
    const prefix =
      type === "manual" ? "" : `${data?.accountCount || 0} Accounts | `
    return `${prefix}${data?.documentCount || 0} Content`
  }

  loadKibanaConnectorAccounts = async (type: string) => {
    const store = this.contentManagerStore
    store.loadingKibanaConnectorAccounts = true
    store.error = null
    try {
      const response = await ContentManagerAPI.getAvailableContentSources(type)
      store.kibanaConnectorAccounts = response.data.data
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingKibanaConnectorAccounts = false
    }
  }

  loadKibanaConnectorsCount = async () => {
    const store = this.contentManagerStore
    store.loadingKibanaConnectorsCount = true
    store.error = null
    store.kibanaConnectorAccounts = []
    try {
      const response = await ContentManagerAPI.getKibanaConnectorsCount()
      store.kibanaConnectorsCount = response.data
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingKibanaConnectorsCount = false
    }
  }

  createContentSource = async (
    data: CreateContentSourceParams
  ): Promise<boolean> => {
    const store = this.contentManagerStore
    store.loadingCreatedContentSource = true
    store.error = null
    try {
      const response = await ContentManagerAPI.createContentSource(data)
      store.createdContentSource = response.data
      return true
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingCreatedContentSource = false
    }
    return false
  }

  loadDocumentsList = async (params: GetConnectorDocumentsParams) => {
    const store = this.contentManagerStore
    store.loadingDocumentsList = true
    store.error = null
    try {
      const response = await ContentManagerAPI.getDocumentsList(params)
      store.documentsList = response.data.data
      store.documentsMeta = response.data.meta
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingDocumentsList = false
    }
  }

  loadContentTypes = async () => {
    const store = this.contentManagerStore
    const filterType: GeFilterType = {
      page: 1,
      pageSize: 1000,
      type: "datatype",
    }
    const response = await solutionsService.getFilters({
      filters: [filterType],
    })

    store.contentTypes = Array.isArray(response?.data?.data?.dataType?.items)
      ? response.data.data.dataType.items
      : []
  }

  loadContentSources = async () => {
    const store = this.contentManagerStore
    const filterType: GeFilterType = {
      page: 1,
      pageSize: 1000,
      type: "datasource",
    }
    const response = await solutionsService.getFilters({
      filters: [filterType],
    })

    store.contentSources = Array.isArray(
      response?.data?.data?.dataSource?.items
    )
      ? response.data.data.dataSource.items
      : []
  }

  uploadDocument = async (
    file: File,
    contentType?: string
  ): Promise<boolean> => {
    const store = this.contentManagerStore
    const uploadId = getUniqueNameForFile(file)
    store.addUpload(uploadId, {
      fileName: file.name,
      status: MANUAL_UPLOAD_FILE_STATUS.UPLOADING,
      progress: 0,
    })

    const handleProgress = (progressEvent: AxiosProgressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / (progressEvent?.total || 1)
      )
      store.updateUpload(uploadId, {
        progress: percentCompleted,
      })
    }

    try {
      const response = await ContentManagerAPI.uploadDocument(
        file,
        contentType,
        handleProgress
      )
      const { succefulFileUploads, failedUploadedFiles } = response.data

      if (succefulFileUploads.includes(file.name)) {
        store.updateUpload(uploadId, {
          status: MANUAL_UPLOAD_FILE_STATUS.COMPLETED,
          progress: 100,
          error: "",
        })
      } else if (failedUploadedFiles.includes(file.name)) {
        store.updateUpload(uploadId, {
          status: MANUAL_UPLOAD_FILE_STATUS.FAILED,
          progress: 0,
        })
      }
      return true
    } catch (error: any) {
      store.updateUpload(uploadId, {
        status: MANUAL_UPLOAD_FILE_STATUS.FAILED,
        error: error.message,
        progress: 0,
      })
    }
    return false
  }

  loadSyncJobs = async () => {
    const store = this.contentManagerStore
    store.loadingSyncJobs = true
    store.error = null
    try {
      const response = await ContentManagerAPI.getSyncJobs()
      store.syncJobs = response.data.data
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingSyncJobs = false
    }
  }

  updateContentTypesForDocuments = async (
    contentType: string,
    documents: ConnectorDocument[]
  ): Promise<string | null> => {
    const store = this.contentManagerStore
    store.loadingUpdateContentTypes = true
    store.error = null

    try {
      const updatePromises = documents.map(({ connectorId, id: documentId }) =>
        ContentManagerAPI.updateContentTypeForDocument({
          connectorId,
          documentId: encodeURIComponent(documentId),
          contentType,
        })
      )

      await Promise.all(updatePromises)
      return null
    } catch (error: any) {
      store.error = error.message
      return error.message
    } finally {
      store.loadingUpdateContentTypes = false
    }
  }

  refresh = () => {}
}
