import clsx from "clsx"
import React, { CSSProperties } from "react"

import Button from "@components/ui/Button/Button"
import { AppMediaQueries } from "@framework/constants/app"
import useMediaQuery from "@components/hooks/useMediaQuery"
import Icon from "@components/ui/Icon/Icon"
import SwipeableFooter from "@components/ui/SwipeableFooter/SwipeableFooter"

import styles from "./BaseModal.module.sass"

export interface BaseModalProps {
  className?: string
  title?: React.ReactNode
  containerClassName?: string
  headerClassName?: string
  onClose?: () => void
  style?: CSSProperties
}

export const BaseModal: React.FC<BaseModalProps> = ({
  className,
  title,
  containerClassName,
  headerClassName,
  children,
  onClose,
  style,
}) => {
  const isMobile = useMediaQuery(AppMediaQueries.maxMobile)

  return isMobile ? (
    <SwipeableFooter
      title={title}
      defaultValue
      onClose={onClose}
      className={containerClassName}
    >
      {children}
    </SwipeableFooter>
  ) : (
    <div className={clsx(styles.root, className)} style={style}>
      {onClose && (
        <Button
          className={styles.closeButton}
          color="secondary"
          size="big"
          noPadding
          onClick={onClose}
        >
          <Icon name="cross" />
        </Button>
      )}

      {title != null && (
        <div className={clsx(styles.header, headerClassName)}>{title}</div>
      )}

      <div className={clsx(styles.body, containerClassName)}>{children}</div>
    </div>
  )
}

export default BaseModal
