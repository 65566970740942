import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Templates from "@components/ui/Templates"
import { HeaderProps } from "@components/ui/Templates/Header/Header"

import styles from "./CollectionControlBar.module.sass"

const CollectionControlBar: React.FC<HeaderProps> = observer((props) => {
  return (
    <Templates.Header
      {...props}
      className={clsx(styles.root, props.className)}
    />
  )
})

export default CollectionControlBar
