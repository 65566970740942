import React from "react"

import { initArrayByLength } from "@utils/numberUtils"
import Button from "@components/ui/Button/Button"
import List from "@components/ui/List/List"
import Avatar from "@components/ui/Avatar/Avatar"

import styles from "./index.module.sass"

type BaseOption = {
  name: string
  id: string
  imageUrl?: string
}

interface VerticalItemListProps<T> {
  items: T[]
  initialShownCount?: number
  withAvatar?: boolean
}

const VerticalItemList = <T extends BaseOption>({
  items,
  initialShownCount = 3,
  withAvatar,
}: VerticalItemListProps<T>) => {
  const [showMore, setShowMore] = React.useState(false)

  const initLength = React.useMemo(() => {
    if (items.length > initialShownCount) {
      return showMore ? items.length : initialShownCount
    }
    return items.length
  }, [items, showMore])

  const list = initArrayByLength(initLength)

  if (!items.length) return null

  return (
    <div className={styles.root}>
      <List gutter="8" overflow="initial">
        {list.map((index) => {
          const avatar = items[index]?.imageUrl
          return (
            <div key={items[index]?.id} className={styles.info}>
              {withAvatar && <Avatar width={16} height={16} src={avatar} />}
              {items[index]?.name}
            </div>
          )
        })}
      </List>
      {items.length > initialShownCount && (
        <Button
          size="small"
          variant="slim"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? "See Less" : `+${items.length - initialShownCount} More`}
        </Button>
      )}
    </div>
  )
}

export default VerticalItemList
