import React from "react"
import { Field, FieldProps } from "formik"

import OptionsGroup from "@components/prototypes/FilterSidebar/components/OptionGroup/OptionGroup"
import UserSelect from "@components/prototypes/FilterSidebar/components/UserSelect/UserSelect"
import { DD_MM_YYYY_FORMAT } from "@framework/constants/global"
import DateFormField from "@components/prototypes/form-elements/DateFormField"
import { validExtensions } from "@framework/constants/upload"
import { stringArrayToOptions } from "@utils/textUtils"
import DataTypeSelect from "@components/prototypes/FilterSidebar/components/DataTypeSelect/DataTypeSelect"
import { DateRange } from "@framework/types/common"

const extensionOptions = stringArrayToOptions(validExtensions, (x) => `.${x}`)

export type FilterForm = {
  dataTypes?: string[]
  createdByUserIds?: string[]
  updatedByUserIds?: string[]
  extensions?: string[]
  createdAtRange?: DateRange
  lastUpdatedAtRange?: DateRange
}

const FilesFilterForm: React.FC = () => (
  <>
    <Field name="dataTypes" label="Content Type" component={DataTypeSelect} />

    <Field
      name="createdByUserIds"
      label="Created By"
      valueKey="id"
      component={UserSelect}
    />

    <Field
      name="updatedByUserIds"
      label="Updated By"
      valueKey="id"
      component={UserSelect}
    />

    <Field
      name="createdAtRange"
      label="Created At"
      format={DD_MM_YYYY_FORMAT}
      component={DateFormField}
    />

    <Field
      name="lastUpdatedAtRange"
      label="Last Update At"
      format={DD_MM_YYYY_FORMAT}
      component={DateFormField}
    />

    <Field name="extensions">
      {({ field, form }: FieldProps) => (
        <OptionsGroup
          multiple
          label="File Extension"
          options={extensionOptions}
          value={field.value}
          onChange={(value) => form.setFieldValue(field.name, value)}
        />
      )}
    </Field>
  </>
)

export default FilesFilterForm
