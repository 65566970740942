const PRIVACY_POLICY = `
  <h2>Privacy Policy</h2>
  <h3>1. Introduction</h3>
  <p>Hello Nesh (“we,” “us,” or “our”) is committed to protecting the privacy of individuals who use our SaaS platform (“Service”). This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you access and use the Service. By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
  
  <h3>2. Information We Collect</h3>
  <h4>a. Personal Information</h4>
  <p>When you create an account or use our Service, we may collect certain personal information that can be used to identify you (“Personal Information”). This may include:</p>
  <ul>
    <li>Name</li>
    <li>Email address</li>
    <li>Company name</li>
    <li>Job title</li>
    <li>Any other information you provide to us directly through the Service</li>
  </ul>
  
  <h4>b. Usage Data</h4>
  <p>We may collect information on how the Service is accessed and used (“Usage Data”). This may include:</p>
  <ul>
    <li>Your IP address</li>
    <li>The pages you visit on our Service</li>
    <li>The queries performed on our Service</li>
    <li>The time and date of your visit</li>
    <li>The time spent on those pages</li>
    <li>Other diagnostic data</li>
  </ul>
  <br />
  <h3>3. How We Use Your Information</h3>
  <h4>a. To Provide and Maintain the Service</h4>
  <p>We use your information to operate and maintain the Service, process transactions, and manage your account.</p>
  
  <h4>b. To Improve the Service</h4>
  <p>We analyze Usage Data to understand how our Service is used and to make improvements.</p>
  
  <h4>c. To Communicate with You</h4>
  <p>We use your Personal Information to send you service-related communications, including product updates, security updates, and account notifications.</p>
  
  <h4>d. Marketing and Promotional Communications</h4>
  <p>With your consent, we may use your Personal Information to contact you with newsletters, marketing, or promotional materials. You can opt out of these communications at any time by following the unsubscribe link or instructions provided in any email we send.</p>
  
  <h4>e. To Comply with Legal Obligations</h4>
  <p>We may disclose your information if required to do so by law or in response to valid requests by public authorities.</p>
  
  <h3>4. How We Share Your Information</h3>
  <h4>a. Business Transfers</h4>
  <p>If we are involved in a merger, acquisition, or asset sale, your information may be transferred. We will provide notice before your information is transferred and becomes subject to a different Privacy Policy.</p>
  
  <h4>b. Legal Requirements</h4>
  <p>We may disclose your Personal Information in the good faith belief that such action is necessary to:</p>
  <ul>
    <li>Comply with a legal obligation</li>
    <li>Protect and defend our rights or property</li>
    <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
    <li>Protect the personal safety of users of the Service or the public</li>
    <li>Protect against legal liability</li>
  </ul>
  <br />
  <h3>5. Data Security</h3>
  <p>We take the security of your information seriously and implement appropriate technical and organizational measures to protect your personal data. The Data Security terms are further defined in the Software Agreement that we have with your organization.</p>
  
  <h3>6. Data Retention</h3>
  <p>We will retain your Personal Information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>`

export default PRIVACY_POLICY
