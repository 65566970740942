import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"

import { useController, useStore } from "@store"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import Editor from "@components/ui/Editor/Editor"
import LoadingInfo from "@pages/launch-solutions/SolutionTabsContainer/LoadingInfo"
import { useMultiStep } from "@components/ui/MultiStep/MultiStepContext"
import NotFound from "@components/ui/NotFound/NotFound"
import { NumberedMultiStepControl } from "@pages/launch-solutions/SolutionTabsContainer/components/Tabs/ProductComparison/NumberedMultiStep"

import ComparisonTable from "../ComparisonTable"
import SolutionLoader from "../SolutionLoader"

import styles from "./index.module.sass"

type PitchGenerationProps = {}

const PitchGeneration: React.FC<PitchGenerationProps> = observer(() => {
  const formStepper = useMultiStep()

  const { productComparisonStore } = useStore()

  const { productComparisonController } = useController()

  const [edit, setEdit] = useState(false)
  const [comparisonSummary, setComparisonSummary] = useState("")

  const summaryStore = productComparisonStore.summaryStore.state
  const { selectedProductsStore } = productComparisonStore

  useEffect(() => {
    productComparisonController.generateSummary()
  }, [])

  useEffect(() => {
    if (summaryStore.summary) setComparisonSummary(summaryStore.summary)
  }, [summaryStore.summary])

  const handleSubmit = () => {
    productComparisonController.reset()
    formStepper.next()
  }

  const handleBack = formStepper.back

  if (summaryStore.isLoading)
    return (
      <SolutionLoader title="Generating Pitch">
        <LoadingInfo />
      </SolutionLoader>
    )

  if (summaryStore.error)
    return (
      <NotFound
        retryButton={
          <Button
            variant="slim"
            size="small"
            before={<Icon name="retry" />}
            onClick={() => productComparisonController.retrySummaryGeneration()}
          >
            Try Again
          </Button>
        }
      >
        Failed to generate Summary
      </NotFound>
    )

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Button onClick={handleBack} variant="outlined" size="medium">
          Back
        </Button>

        <NumberedMultiStepControl />

        <Button onClick={handleSubmit} variant="outlined" size="medium">
          Start Over
        </Button>
      </div>

      <div className={styles.headingContainer}>
        <Text variant="h4" weight="bold">
          Positioning Summary
        </Text>
        <Button size="medium" color="primary">
          PREVIEW & DOWNLOAD
        </Button>
      </div>

      {comparisonSummary ? (
        <div className={styles.summaryContainer}>
          <Editor
            defaultValue={comparisonSummary}
            readOnly={!edit}
            onChange={setComparisonSummary}
            textAreaClassName={styles.textArea}
          />

          <hr className={styles.horizontal} />
          <Button
            variant="outlined"
            size="small"
            before={<Icon name="edit" />}
            className={styles.editButton}
            onClick={() => setEdit(!edit)}
          >
            {edit ? "Update" : "Edit Summary"}
          </Button>
        </div>
      ) : null}

      {selectedProductsStore.selectedProducts.length > 0 && (
        <div className={styles.tableContainer}>
          <ComparisonTable readonly />
        </div>
      )}
    </div>
  )
})

export default PitchGeneration
