import React from "react"

import { NavigationScopeContext } from "."

export const useNavigationScope = () => {
  const context = React.useContext(NavigationScopeContext)
  if (context == null) throw new Error("Can't find any NavigationScope")
  return context
}

export default useNavigationScope
