import React from "react"
import { observer } from "mobx-react-lite"
import throttle from "lodash/throttle"
import clsx from "clsx"

import Templates from "@components/ui/Templates"
import Text from "@components/ui/Typography/Text"
import { countSuffix } from "@utils/numberUtils"
import { useController } from "@store/index"
import List from "@components/ui/List/List"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import useArticlesCollection from "@pages/expert-insights/Topics/hooks/useArticlesCollection"
import CollapsibleContextProvider from "@components/ui/Collapsable/CollapsibleContext"

import ArticleItem from "./ArticleItem"

import styles from "./ArticleList.module.sass"

export interface ArticleListProps {
  avatarId: string
  subtopicId: string
  className?: string
}

const ArticleList: React.FC<ArticleListProps> = observer(
  ({ avatarId, subtopicId, className }) => {
    const collection = useArticlesCollection(subtopicId)

    const { expertInsightsController } = useController()

    const loadSubtopics = React.useCallback(
      throttle(() => {
        expertInsightsController.loadArticlesList(avatarId, subtopicId, "view")
      }, 1_000),
      [avatarId, subtopicId]
    )

    React.useEffect(() => {
      loadSubtopics()
    }, [loadSubtopics])

    const totalSubtopics = collection.data.length

    return (
      <Templates.RollScript
        className={clsx(styles.root, className)}
        headerSocket={
          <Templates.Header
            offsetBottom="medium"
            left={
              <Text variant="h5">
                {totalSubtopics} {`Article${countSuffix(totalSubtopics)}`}
              </Text>
            }
          />
        }
      >
        <CollapsibleContextProvider singleActive>
          <div className={styles.collection}>
            {!collection.data.length ? (
              collection.isLoading ? (
                <Loader primary size="large" fluid />
              ) : (
                <NotFound>No Articles found</NotFound>
              )
            ) : (
              <List gutter="16" overflow="initial">
                {collection.data.map((article) => {
                  return (
                    <ArticleItem
                      avatarId={avatarId}
                      data={article}
                      key={article.id}
                    />
                  )
                })}
              </List>
            )}
          </div>
        </CollapsibleContextProvider>
      </Templates.RollScript>
    )
  }
)

export default ArticleList
