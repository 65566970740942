import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx"
import { toStream } from "mobx-utils"
import moment from "moment"
import {
  from,
  map,
  ObservableInput,
  retry,
  Subscription,
  switchMap,
  tap,
  timer,
} from "rxjs"

import {
  DD_MM_YYYY_FORMAT,
  YYYY_MM_DD_FORMAT,
} from "@framework/constants/global"
import { APICallsAmountPointData } from "@framework/types/analytics"
import {
  DateRange,
  FailResponse,
  SuccessResponse,
} from "@framework/types/common"
import analyticsService from "@services/analytics.service"

const PULLING_DELAY = 120_000
const RETRY_DELAY = 30_000
const INITIAL_DAYS_NUMBER = 14

type State = {
  isLoading: boolean

  error: string | null

  data: APICallsAmountPointData[]

  period: DateRange // YYYY-MM-DD

  requestedPeriod: DateRange // YYYY-MM-DD

  reloadTrigger: boolean
}

export default class ApiUsageTrendStore {
  state: State

  private loadingStream$?: Subscription

  constructor() {
    const initialPeriod = this.initPeriod()

    this.state = {
      isLoading: false,

      error: null,

      data: [],

      requestedPeriod: { ...initialPeriod },

      period: { ...initialPeriod },

      reloadTrigger: false,
    }

    makeAutoObservable(this)

    onBecomeObserved(this.state, "data", () => {
      this.loadingStream$ = this.initStream()
    })

    onBecomeUnobserved(this.state, "data", () => {
      this.loadingStream$?.unsubscribe()
    })
  }

  initPeriod = (): DateRange => ({
    start: moment()
      .subtract(INITIAL_DAYS_NUMBER, "days")
      .format(YYYY_MM_DD_FORMAT),
    end: moment().format(YYYY_MM_DD_FORMAT),
  })

  initStream = () => {
    this.state.requestedPeriod = this.initPeriod()

    return from(
      toStream(
        () => [this.state.requestedPeriod, this.state.reloadTrigger],
        true
      ) as ObservableInput<[DateRange, boolean]>
    )
      .pipe(
        tap(() =>
          runInAction(() => {
            this.state.isLoading = true
            this.state.error = null
          })
        ),

        switchMap(([period]) =>
          analyticsService
            .loadAPIUsageTrends$({
              start: moment(period.start, YYYY_MM_DD_FORMAT).format(
                DD_MM_YYYY_FORMAT
              ),
              end: moment(period.end, YYYY_MM_DD_FORMAT).format(
                DD_MM_YYYY_FORMAT
              ),
            })
            .pipe(map((response) => ({ data: response.data.data, period })))
        ),

        tap(({ data, period }) =>
          runInAction(() => {
            this.state.data = data

            this.state.period = { ...period }

            this.state.isLoading = false
          })
        ),

        retry({
          delay: () => {
            runInAction(() => {
              this.state.error = "Loading Failed"
              this.state.isLoading = false
              this.state.data = []
            })
            return timer(RETRY_DELAY)
          },
        }),

        switchMap(() => timer(PULLING_DELAY)),

        tap(() => this.refresh())
      )
      .subscribe()
  }

  setPeriod = (period: DateRange) => {
    this.state.requestedPeriod = period
  }

  refresh = () => {
    this.state.reloadTrigger = !this.state.reloadTrigger
  }

  getAPIUsageTrendDataAsBlob = async (): Promise<
    SuccessResponse<{ file: Blob; period: DateRange }> | FailResponse
  > => {
    try {
      const startData = this.state.requestedPeriod.start
      const endData = this.state.requestedPeriod.end

      const response = await analyticsService.downloadAPIUsageTrendReport(
        moment(startData, YYYY_MM_DD_FORMAT).format(DD_MM_YYYY_FORMAT),
        moment(endData, YYYY_MM_DD_FORMAT).format(DD_MM_YYYY_FORMAT)
      )

      return {
        data: {
          file: response.data,
          period: { start: startData, end: endData },
        },
        status: "SUCCESS",
      }
    } catch (e) {
      return {
        message: "Unexpected Error while generating file",
        status: "FAILED",
      }
    }
  }
}
