import React from "react"
import { useFormikContext } from "formik"
import { nanoid } from "nanoid"
import * as yup from "yup"

import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import FormTextInput from "@components/prototypes/form-elements/FormTextInput"
import Templates from "@components/ui/Templates"

import styles from "./SuggestedQuestionsSubForm.module.sass"

const MIN_ROWS = 0
const MAX_ROWS = 4

export const optionValidationSchema = yup.object({
  id: yup
    .string()
    .required()
    .default(() => nanoid()),
  value: yup.string().label("Search Question").trim(),
})

export const validationSchema = yup.object({
  suggestedQuestions: yup
    .array()
    .of(optionValidationSchema)
    .min(MIN_ROWS)
    .max(MAX_ROWS)
    .required()
    .default([]),
})

export type FormData = yup.InferType<typeof validationSchema>

const SuggestedQuestionsSubForm: React.FC = () => {
  const formik = useFormikContext<FormData>()

  const rows = formik.values.suggestedQuestions ?? []

  const addRow = () => {
    formik.setFieldValue(
      "suggestedQuestions",
      [
        ...formik.values.suggestedQuestions,
        optionValidationSchema.getDefault(),
      ],
      true
    )
  }

  const deleteRow = (rowId: string) => () => {
    formik.setFieldValue(
      "suggestedQuestions",
      formik.values.suggestedQuestions.filter((it) => it.id !== rowId),
      true
    )
  }

  return (
    <div className={styles.root}>
      <Templates.Header
        offsetBottom="medium"
        left={<Text variant="h4">Suggested Questions</Text>}
      />

      <div className={styles.body}>
        {rows.map((it, idx) => (
          <FormTextInput
            autoFocus
            name={`suggestedQuestions[${idx}].value`}
            placeholder="Enter question..."
            after={<Icon name="cross" onClick={deleteRow(it.id)} />}
            key={it.id}
          />
        ))}

        {rows.length < MAX_ROWS && (
          <Button
            variant="outlined"
            size="tiny"
            before={<Icon name="plus" />}
            onClick={addRow}
          >
            Add Question
          </Button>
        )}
      </div>
    </div>
  )
}

export default SuggestedQuestionsSubForm
