import flatMap from "lodash/flatMap"

import MatrixStore from "@components/ui/Spreadsheet/state/MatrixStore"
import UnifiedMatrixController from "@store/unified-matrix/unified-matrix.controller"
import {
  defaultOptionalStringValidator,
  defaultStringValidator,
  stringOrArrayOfStrings,
} from "@components/ui/Spreadsheet/validation"
import mainRoutes from "@root/main.routes"

export const initCustomFunctions = (
  matrixStore: MatrixStore,
  unifiedMatrixController: UnifiedMatrixController
) => {
  initThemeFunction("THEME", matrixStore, unifiedMatrixController)

  initAccountPlanFunction("ACCOUNTPLAN", matrixStore, unifiedMatrixController)

  initProductPositioningFunction(
    "PRODUCTPOSITIONING",
    matrixStore,
    unifiedMatrixController
  )

  initDocumentComplianceFunction(
    "DOCUMENTCOMPLIANCE",
    matrixStore,
    unifiedMatrixController
  )
}

export const initThemeFunction = (
  functionName: string,
  matrixStore: MatrixStore,
  unifiedMatrixController: UnifiedMatrixController
) => {
  matrixStore.editManager.functionManager.registerFunction<
    [string, string, string | undefined]
  >({
    name: functionName,
    description: "Generate themes for a Company by Category and Business Unit",
    requiredArgumentsNum: 2,
    maxArgumentsNum: 3,
    arguments: [
      {
        displayName: "Company",
        validator: defaultStringValidator,
      },
      {
        displayName: "Category",
        validator: defaultStringValidator,
      },
      {
        displayName: "Business Unit",
        validator: defaultStringValidator,
      },
    ],
    transformArgs: (args: any[]) => {
      return args as [string, string, string | undefined]
    },
    handler: async (...args) => {
      const response = await unifiedMatrixController.generateThemes(...args)

      if (response.status === "FAILED")
        throw new Error("Failed to generate themes.")

      return response.data
    },
  })
}

export const initAccountPlanFunction = (
  functionName: string,
  matrixStore: MatrixStore,
  unifiedMatrixController: UnifiedMatrixController
) => {
  matrixStore.editManager.functionManager.registerFunction<
    [string, string, string?, string?]
  >({
    name: functionName,
    description: "Generate account plan for a company.",
    requiredArgumentsNum: 2,
    maxArgumentsNum: 4,
    arguments: [
      {
        displayName: "Target Company's Url",
        validator: defaultStringValidator,
      },
      { displayName: "My Company's Url", validator: defaultStringValidator },
      {
        displayName: "Target Company's Business Unit (optional)",
        validator: defaultOptionalStringValidator,
      },
      {
        displayName: "Additional Context (optional)",
        validator: defaultOptionalStringValidator,
      },
    ],
    transformArgs: (args: any[]) => {
      if (args.length < 2) {
        throw new Error(
          `Function ${functionName} expects at least two arguments`
        )
      }

      if (args.length > 4) {
        throw new Error(
          `Function ${functionName} expects at most four arguments`
        )
      }

      if (typeof args[0] !== "string" || !args[0]) {
        throw new Error(
          `Function ${functionName} expects a string for the Target Company's Url argument`
        )
      }
      if (typeof args[1] !== "string" || !args[1]) {
        throw new Error(
          `Function ${functionName} expects a string for the My Company's Url argument`
        )
      }
      if (!(args[2] == null || (typeof args[2] === "string" && !!args[2]))) {
        throw new Error(
          `Function ${functionName} expects a string for the Target Company's Business Unit argument`
        )
      }

      if (!(args[3] == null || (typeof args[3] === "string" && !!args[3]))) {
        throw new Error(
          `Function ${functionName} expects a string for the Additional Context argument`
        )
      }

      return args as [string, string, string?, string?]
    },
    handler: async (...args) => {
      const response = await unifiedMatrixController.generateAccountPlan(
        ...args
      )

      if (response.status === "FAILED")
        throw new Error("Failed to generate account plan.")

      return encodeURI(
        `${window.location.origin}${mainRoutes.workbookReport(
          response.data.id
        )}`
      )
    },
  })
}

export const initProductPositioningFunction = (
  functionName: string,
  matrixStore: MatrixStore,
  unifiedMatrixController: UnifiedMatrixController
) => {
  matrixStore.editManager.functionManager.registerFunction<
    [string, string, string[]]
  >({
    name: functionName,
    description:
      "Generate product positioning report for a target product and one or more alternate products.",
    requiredArgumentsNum: 3,
    maxArgumentsNum: 10,
    arguments: [
      {
        displayName: "Application",
        validator: defaultStringValidator,
      },
      {
        displayName: "Target Product",
        validator: defaultStringValidator,
      },
      {
        displayName: "Alternate Product",
        validator: stringOrArrayOfStrings,
      },
    ],
    transformArgs: (args: any[]) => {
      const application = args[0]

      const target = args[1]

      const altProducts = flatMap(args.slice(2))

      return [application, target, altProducts]
    },
    handler: async (...args) => {
      const response =
        await unifiedMatrixController.generateProductPositioningReport(...args)

      if (response.status === "FAILED")
        throw new Error("Failed to generate product positioning report.")

      return encodeURI(
        `${window.location.origin}${mainRoutes.workbookReport(
          response.data.id
        )}`
      )
    },
  })
}

export const initDocumentComplianceFunction = (
  functionName: string,
  matrixStore: MatrixStore,
  unifiedMatrixController: UnifiedMatrixController
) => {
  matrixStore.editManager.functionManager.registerFunction<[string, string]>({
    name: functionName,
    description:
      "Generate compliance report for an authority document and regulated document.",
    requiredArgumentsNum: 2,
    maxArgumentsNum: 2,
    arguments: [
      {
        displayName: "Authority Document",
        validator: defaultStringValidator,
      },
      {
        displayName: "Regulated Document",
        validator: defaultStringValidator,
      },
    ],
    transformArgs: (args: any[]) => {
      return args as [string, string]
    },
    handler: async (...args) => {
      const response =
        await unifiedMatrixController.generateDocumentComplianceReport(...args)

      if (response.status === "FAILED")
        throw new Error("Failed to generate document compliance report.")

      return encodeURI(
        `${window.location.origin}${mainRoutes.workbookReport(
          response.data.id
        )}`
      )
    },
  })
}
