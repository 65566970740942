import React from "react"
import { observer } from "mobx-react-lite"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"

export const UserGroupsTableHeader: React.FC = observer(() => {
  return (
    <Row>
      <Column as="th" key="name" width="250px">
        Group Name
      </Column>

      <Column as="th" key="actions" width="70px" />

      <Column as="th" key="roles" width="auto">
        Description
      </Column>

      <Column as="th" key="created_date" width="150px">
        Users
      </Column>
    </Row>
  )
})

export default UserGroupsTableHeader
